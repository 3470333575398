import React from 'react';
import {
  BackButtonArrow,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { Box } from '@leagueplatform/genesis-core';
import { useActivityLink } from '../hooks/use-activity-link.hook';

export const HealthActivityError = () => {
  const { returnToEntrypoint } = useActivityLink();
  return (
    <Box
      css={{
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        padding: '$twoAndHalf',
        flexDirection: 'column',
        textAlign: 'center',
        '@mobile': {
          padding: '$oneAndHalf',
        },
      }}
      data-testid="health-activity-error"
    >
      <BackButtonArrow
        fontSize="heading3"
        color="onSurface.text.primary"
        marginRight="one"
        marginY="half"
        justifyContent="initial"
        onClick={returnToEntrypoint}
      />
      <Box
        css={{
          marginTop: '$three',
        }}
      >
        <ErrorState />
      </Box>
    </Box>
  );
};
