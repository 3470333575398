import * as React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import { CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH } from '@leagueplatform/health-journey-common';

interface ConfigurableJourneyPageLayoutProps {
  children: React.ReactNode;
}
export const ConfigurableJourneyPageLayout = ({
  children,
}: ConfigurableJourneyPageLayoutProps) => (
  <Flex
    maxWidth={CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH}
    margin="auto"
    width="100%"
  >
    {children}
  </Flex>
);
