import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { NestedCampaign } from '@leagueplatform/health-journey-api';
import { captureError } from '@leagueplatform/observability';
import { ActivityList } from 'components/activity-list/activity-list.component';
import { RewardProgramGroup } from 'components/reward-program-group/reward-program-group.component';
import { RewardProgramTier } from 'components/reward-program-tier/reward-program-tier.component';
import {
  ANALYTICS_FIELDS,
  ANALYTICS_VALUES,
} from 'constants/analytics.constants';

import React, { useEffect } from 'react';
import { shouldDisplayDate } from 'utils/should-display-date.util';

interface RewardProgramActivitiesProps {
  rewardProgram: NestedCampaign;
  isVisible?: boolean;
}

export function RewardProgramProgressTab({
  rewardProgram,
  isVisible,
}: RewardProgramActivitiesProps) {
  const { userHealthCampaigns, campaign, userHealthActivities } = rewardProgram;

  const { displayGlobalProgress } = campaign.data;

  const showGroups = userHealthCampaigns && displayGlobalProgress;

  const showTiers = userHealthCampaigns && !displayGlobalProgress;

  const showActivities = userHealthActivities;

  useEffect(() => {
    if (!showGroups && !showTiers && !showActivities) {
      captureError(
        new Error(
          `Reward program(id:${rewardProgram.id}) progress data isn't provided`,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        [ANALYTICS_FIELDS.PRODUCT_AREA]:
          ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
        [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
          ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
        [ANALYTICS_FIELDS.SCREEN_NAME]:
          ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_PROGRESS,
        [ANALYTICS_FIELDS.CAMPAIGN_NAME]: campaign.data.name,
        [ANALYTICS_FIELDS.CAMPAIGN_ID]: rewardProgram.campaignId,
        [ANALYTICS_FIELDS.CAMPAIGN_TYPE]: campaign.data.type,
        [ANALYTICS_FIELDS.CAMPAIGN_STATUS]: rewardProgram.status,
      });
    }
  }, [
    campaign.data.name,
    campaign.data.type,
    isVisible,
    rewardProgram.campaignId,
    rewardProgram.status,
  ]);

  return (
    <StackLayout
      css={{ marginTop: '$two' }}
      horizontalAlignment="stretch"
      spacing="$two"
      divider={
        showTiers ? (
          <Box
            css={{
              height: '$one',
              width: '100%',
              background: '$interactiveActionDisabled',
            }}
          />
        ) : undefined
      }
    >
      {showTiers &&
        userHealthCampaigns?.data.map((tier) => (
          <RewardProgramTier
            key={tier.id}
            showDate={shouldDisplayDate(
              campaign.data.startDate,
              campaign.data.endDate,
              tier.campaign.data.startDate,
              tier.campaign.data.endDate,
            )}
            relatedCampaign={tier}
          />
        ))}
      {showGroups &&
        userHealthCampaigns?.data.map((group) => (
          <RewardProgramGroup
            key={group.id}
            showDate={shouldDisplayDate(
              campaign.data.startDate,
              campaign.data.endDate,
              group.campaign.data.startDate,
              group.campaign.data.endDate,
            )}
            relatedCampaign={group}
          />
        ))}

      {showActivities && <ActivityList relatedCampaign={rewardProgram} />}
    </StackLayout>
  );
}
