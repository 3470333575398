import { subscribeToConfig, LeagueConfigError } from '@leagueplatform/config';
import { FetchAsPromise } from './fetch-as-promise';

let fetchAsPromise: FetchAsPromise;
let fetchAsPromiseAnonymous: FetchAsPromise;

export const SocketAsFetch = {
  fetch: async (...args: Parameters<FetchAsPromise['fetch']>) => {
    if (!fetchAsPromise) {
      throw new LeagueConfigError(
        'SocketAsFetch cannot be used without the `core.api.wsUrl` config property being set.',
      );
    }

    return fetchAsPromise.fetch(...args);
  },
};

export const SocketAsFetchAnonymous = {
  fetch: async (...args: Parameters<FetchAsPromise['fetch']>) => {
    if (!fetchAsPromiseAnonymous) {
      throw new LeagueConfigError(
        'SocketAsFetchAnonymous cannot be used without the `core.api.wsUrl` config property being set.',
      );
    }

    return fetchAsPromiseAnonymous.fetch(...args);
  },
};

subscribeToConfig(
  (config) => config.core.api.wsUrl,
  (url) => {
    if (!url) {
      return;
    }
    if (fetchAsPromise) {
      fetchAsPromise.unload();
    }

    if (fetchAsPromiseAnonymous) {
      fetchAsPromiseAnonymous.unload();
    }

    fetchAsPromise = new FetchAsPromise(url);
    fetchAsPromiseAnonymous = new FetchAsPromise(url, {
      anonymous: true,
    });
  },
);
