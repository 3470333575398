import type { ErrorStateProps } from '@leagueplatform/web-common-components';

export enum ERROR_TYPES {
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
}

const defaultTryAgainHandler = () => window.location.reload();

export const useErrorMessage = (errorType: ERROR_TYPES): ErrorStateProps => {
  type ErrorBody = {
    headingId: string;
    bodyId: string;
    buttonId?: string;
    buttonAction?: () => void;
    hideButton?: boolean;
  };

  const ErrorMessages: {
    [ERROR_TYPES.API_ERROR]: ErrorBody;
    [ERROR_TYPES.NO_CONNECTION]: ErrorBody;
  } = {
    [ERROR_TYPES.API_ERROR]: {
      headingId: 'ERROR_HEADING_GENERIC',
      bodyId: 'ERROR_CAPTION_BACKEND',
      buttonAction: defaultTryAgainHandler,
    },
    [ERROR_TYPES.NO_CONNECTION]: {
      headingId: 'ERROR_HEADING_NO_CONNECTION',
      bodyId: 'ERROR_CAPTION_NO_CONNECTION',
      hideButton: true,
    },
  };

  return ErrorMessages[errorType];
};
