import {
  Box,
  Card,
  HeadingText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  NestedCampaign,
  USER_HEALTH_CAMPAIGN_STATUS,
} from '@leagueplatform/health-journey-api';
import {
  ProgramBanner,
  ProgramRichText,
} from '@leagueplatform/health-journey-common';
import { ActivityList } from 'components/activity-list/activity-list.component';
import { RewardProgramGroup } from 'components/reward-program-group/reward-program-group.component';
import { ProgramDate } from 'components/program-date/program-date.component';
import { RewardProgramsProgress } from 'components/reward-programs-progress/reward-programs-progress.component';
import React from 'react';
import { shouldDisplayDate } from 'utils/should-display-date.util';

interface RewardProgramTierProps {
  relatedCampaign: NestedCampaign;
  showDate?: boolean;
}

export function RewardProgramTier({
  relatedCampaign,
  showDate,
}: RewardProgramTierProps) {
  const {
    campaign,
    userHealthCampaigns,
    userHealthActivities,
    campaignProgress,
  } = relatedCampaign;

  const completedComponentAttributes =
    relatedCampaign.completedComponentAttributes?.componentAttributes;

  // const endedComponentAttributes =
  //   relatedCampaign.endedComponentAttributes?.componentAttributes;

  const showCompletionBanner =
    relatedCampaign.status === USER_HEALTH_CAMPAIGN_STATUS.COMPLETED &&
    completedComponentAttributes;

  // const showEndBanner =
  //   relatedCampaign.status === USER_HEALTH_CAMPAIGN_STATUS.ENDED &&
  //   endedComponentAttributes;

  const showProgramDate =
    relatedCampaign.status !== USER_HEALTH_CAMPAIGN_STATUS.COMPLETED &&
    showDate;

  const showActivities = userHealthActivities;

  const showGroups = userHealthCampaigns;

  return (
    <StackLayout horizontalAlignment="stretch" spacing="$one">
      <HeadingText level="2" size="xl">
        {campaign.data.name}
      </HeadingText>
      {showCompletionBanner && (
        <Card.Flat css={{ backgroundColor: '$successBackgroundSubdued' }}>
          <Card.Section>
            <ProgramBanner
              icon={completedComponentAttributes.iconUrl}
              basicText={
                <ProgramRichText
                  richText={completedComponentAttributes.richText}
                />
              }
            />
          </Card.Section>
        </Card.Flat>
      )}
      {/* {showEndBanner && (
        <Card.Flat
          css={{
            backgroundColor: '$surfaceBackgroundTertiary',
          }}
        >
          <Card.Section>
            <StackLayout spacing="$half" horizontalAlignment="center">
              <IconText
                iconProps={{
                  icon: endedComponentAttributes.iconUrl,
                  css: { filter: `saturate(0)` },
                }}
                text={
                  <HeadingText level="4" size="md">
                    {endedComponentAttributes.title}
                  </HeadingText>
                }
              />

              <UtilityText css={{ typography: '$caption' }}>
                <ProgramRichText richText={endedComponentAttributes.richText} />
              </UtilityText>
            </StackLayout>
          </Card.Section>
        </Card.Flat>
      )} */}
      {relatedCampaign.status === USER_HEALTH_CAMPAIGN_STATUS.ACTIVE && (
        <UtilityText css={{ typography: '$bodyOne' }}>
          <ProgramRichText richText={campaign.data.richTextDescription} />
        </UtilityText>
      )}

      <Box css={{ typography: '$bodyTwo' }}>
        <RewardProgramsProgress
          iconUrl={campaignProgress.iconUrl}
          currentValue={campaignProgress.current}
          maxValue={campaignProgress.threshold}
          showProgress
        />
      </Box>
      {showProgramDate && (
        <ProgramDate
          startDate={campaign.data.startDate}
          endDate={campaign.data.endDate}
        />
      )}
      {showGroups &&
        userHealthCampaigns?.data.map((group) => (
          <RewardProgramGroup
            key={group.id}
            showDate={shouldDisplayDate(
              campaign.data.startDate,
              campaign.data.endDate,
              group.campaign.data.startDate,
              group.campaign.data.endDate,
            )}
            relatedCampaign={group}
          />
        ))}
      {showActivities && <ActivityList relatedCampaign={relatedCampaign} />}
    </StackLayout>
  );
}
