import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { NumberInputComponentValidation } from '@leagueplatform/health-journey-api';

interface UseNumberInputOptionsProps {
  validation: NumberInputComponentValidation;
  isInteger?: boolean;
}

const useNumberInputOptions = ({
  validation,
  isInteger = false,
}: UseNumberInputOptionsProps) => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const {
      required,
      minimumValue,
      maximumValue,
      customWarning,
      editable = true,
    } = validation || {};

    const pattern = isInteger
      ? {
          pattern: {
            value: /^[0-9]*$/,
            message:
              customWarning ||
              formatMessage({ id: 'TOOLBOX_VALIDATION_ENTER_WHOLE_NUMBER' }),
          },
        }
      : {};

    return {
      ...pattern,
      disabled: !editable,
      required: {
        value: required,
        message:
          customWarning ||
          formatMessage({ id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD' }),
      },
      min: {
        value: minimumValue,
        message:
          customWarning ||
          formatMessage(
            { id: 'TOOLBOX_VALIDATION_NUMBER_MINIMUM' },
            { value: minimumValue },
          ),
      },
      max: {
        value: maximumValue,
        message:
          customWarning ||
          formatMessage(
            { id: 'TOOLBOX_VALIDATION_NUMBER_MAXIMUM' },
            { value: maximumValue },
          ),
      },
    };
  }, [formatMessage, isInteger, validation]);
};

export { useNumberInputOptions };
