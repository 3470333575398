import * as React from 'react';
import { useMutation } from 'react-query';
import {
  postIdCards,
  IdCardsAttributes,
  Address,
} from '@leagueplatform/wallet-api';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { OrderNewCardFormValues } from '../../types/order-new-card-form.types';
import { useGetUserDefaultAddress } from '../use-get-user-default-address.hook';

export const useSubmitOrderNewCardMutation = () => {
  const {
    address1 = '',
    address2 = '',
    city: defaultCity = '',
    postal: postalCode = '',
    state: defaultState = '',
  } = useGetUserDefaultAddress();
  const { benefitId = '' } = useWalletParams();

  const defaultAddress: Address = React.useMemo(
    () => ({
      streetAddressLine1: address1,
      streetAddressLine2: address2,
      city: defaultCity,
      postalCode,
      province: defaultState,
    }),
    [address1, address2, defaultCity, postalCode, defaultState],
  );

  return useMutation((data: OrderNewCardFormValues) => {
    const {
      addressLine1,
      addressLine2,
      postal,
      state,
      city,
      memberList,
      isDefault,
    } = data;
    const newAddress: Address = {
      streetAddressLine1: addressLine1,
      streetAddressLine2: addressLine2,
      city,
      postalCode: postal,
      province: state,
    };
    // TODO: Fix isDefault being returned as a string boolean instead of true boolean
    // @ts-ignore-next-line
    const isDefaultAddress = isDefault === 'true';
    const address = isDefaultAddress ? defaultAddress : newAddress;

    const submitData: IdCardsAttributes = {
      benefitId,
      address,
      memberList,
    };
    return postIdCards({ data: submitData });
  });
};
