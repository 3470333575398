import React, { useEffect, useState } from 'react';
import { useHistory } from '@leagueplatform/routing';

// Everlong dependencies
import {
  Flex,
  Box,
  HeadingFour,
  BodyTwo,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  splitAtNewlines,
  useDocumentTitle,
  useScrollTopOnLocationChange,
} from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import {
  useApiDelayOverlay,
  ApiDelayOverlay,
  JOURNEY_MANAGER_ENABLED,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';

import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

// Common Components
import {
  ActivityDetailsHeader,
  ActivityDetailsWrapper,
  ActivityFooter,
} from 'components/';

// Hooks
import { useHealthActivity } from 'hooks/use-health-activity.hook';
import { useActivityProgressCounter } from 'hooks/use-activity-progress-counter.hook';
import { useActivityAnalytics } from 'hooks/use-activity-details-analytics.hook';
import { USER_CONNECTED_DEVICE_CAMPAIGN_MODES } from '@leagueplatform/health-journey-api';
import { useVerifiableActivityProgressQuery } from 'hooks/use-verifiable-activity-progress-query.hook';

// Constants
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';
import { ACTIVITY_EXIT_MODAL_TYPES } from './types/exit-modals.type';
import {
  ActivityHelpfulTip,
  ActivityStatusBadge,
  ActivityReferences,
  ProgressTracker,
  ExitModals,
  SyncErrorBanner,
  ActivityCaption,
  ActivityProgressCounter,
  ActivityDetailsDescription,
  ActivitySuggestionBanner,
} from './components';

const { SAVE_PROGRESS } = ACTIVITY_EXIT_MODAL_TYPES;
const { AUTOMATIC } = USER_CONNECTED_DEVICE_CAMPAIGN_MODES;

const ActivityDetailsLayout = genesisStyled(Flex)`
  flex-direction: column;
  height: 100%;
  inset: 0;
  position: absolute;
`;

export const ActivityDetailsPage = () => {
  /* Hooks */
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'PAGE_TITLE_ACTIVITY_DETAILS' }));

  // force scroll to top on mount
  useScrollTopOnLocationChange();

  // Feature flags
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );

  // Activity
  const {
    activityId,
    activityPoints,
    activityTokens,
    activityType,
    campaignInfo,
    campaignMode,
    description,
    disclaimerBanner,
    hasError,
    helpfulTips,
    iconUrl,
    informationBanner,
    name,
    startDate,
    pointsEarned,
    ready,
    redirectLink,
    references,
    richTextDescription,
    status,
    suggestionBanner,
    tagline,
    tokensEarned,
  } = useHealthActivity();
  const { entrypointPath, returnToEntrypoint } = useActivityLink();
  const { delayOverlayActive, setDelayOverlayActive } = useApiDelayOverlay();

  // Counter Activity
  const { promptToCompleteActivity, shouldUseCounter, counterProps } =
    useActivityProgressCounter();

  // Activity Details Analytics
  const activityAnalytics = useActivityAnalytics();

  // Verifiable Activity
  const isActivityVerifiable = campaignMode === AUTOMATIC;
  const {
    isLoading: verifiableProgressIsLoading,
    data: verifiableProgressData,
  } = useVerifiableActivityProgressQuery(activityId, isActivityVerifiable);

  // Verifiable activity error banner props
  const hasSyncError =
    isActivityVerifiable &&
    !verifiableProgressIsLoading &&
    !verifiableProgressData?.receiving_data;
  const verifiableProgressDataType = verifiableProgressData?.data_type;

  const isAssigned = Boolean(campaignInfo?.assigned) && isJourneyManagerEnabled;

  // Assigned activity redirect
  const getIsAssignedInternalProgram = (url) => {
    let redirectBackToActivity = false;
    if (isAssigned && url.includes(JOURNEY_ROUTES.programsHome())) {
      redirectBackToActivity = true;
    }
    return redirectBackToActivity;
  };

  // Local State
  const [isExiting, setIsExiting] = useState(false);
  const [
    isUnavailableDeviceConnectModalOpen,
    setIsUnavailableDeviceConnectModalOpen,
  ] = useState(false);

  // Effects
  useEffect(() => {
    if (ready && activityId) {
      activityAnalytics.sendViewActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, activityId]);

  // Handle Redirects
  if (redirectLink) {
    history.push(redirectLink);
  }

  // Handle Loading
  if (!ready) {
    return <LoadingIndicator />;
  }

  // Handle Error
  if (hasError) {
    return (
      <Flex
        padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
        flexDirection={{ _: 'column', phone: 'row' }}
        margin="0 auto"
        justifyContent="center"
      >
        <BackButtonArrow
          fontSize="heading3"
          color="onSurface.text.primary"
          marginRight="one"
          marginY="half"
          justifyContent="initial"
          onClick={returnToEntrypoint}
        />
        <Box marginTop="three">
          <ErrorState />
        </Box>
      </Flex>
    );
  }

  // Callbacks
  const onBack = () => entrypointPath;

  const handleBackClick = (e) => {
    if (promptToCompleteActivity) {
      e.preventDefault();
      setIsExiting(true);
      return;
    }
    activityAnalytics.sendCloseActivity();
  };

  const controlExitModal = () =>
    isExiting && promptToCompleteActivity ? SAVE_PROGRESS : '';

  const handleSaveProgressModalDismiss = () => setIsExiting(false);

  return (
    <ActivityDetailsLayout>
      {isUnavailableDeviceConnectModalOpen && (
        <UnavailableDeviceConnectModal
          onClose={() => setIsUnavailableDeviceConnectModalOpen(false)}
        />
      )}
      <ActivityDetailsWrapper
        to={onBack}
        onBackClick={handleBackClick}
        flex="1"
      >
        <ActivityDetailsHeader
          title={name}
          overline={tagline}
          iconUrl={iconUrl}
        >
          <ActivityCaption
            activityPoints={activityPoints}
            pointsEarned={pointsEarned}
            status={status}
            startDate={startDate}
            tokensEarned={tokensEarned}
            activityTokens={activityTokens}
            campaignInfo={campaignInfo}
          />
          <ActivityStatusBadge
            marginBottom="two"
            marginTop="oneAndHalf"
            status={status}
          />
          {hasSyncError && verifiableProgressDataType && (
            <SyncErrorBanner
              programDataPoints={[verifiableProgressDataType]}
              onClickFixSyncIssue={() =>
                setIsUnavailableDeviceConnectModalOpen(true)
              }
            />
          )}
          {isActivityVerifiable && <ProgressTracker activityId={activityId} />}
          {suggestionBanner && (
            <ActivitySuggestionBanner
              campaignMode={campaignMode}
              title={suggestionBanner?.title}
              description={suggestionBanner?.description}
              iconUrl={suggestionBanner?.icon_url}
            />
          )}
        </ActivityDetailsHeader>
        <Box>
          <ActivityDetailsDescription
            text={description}
            richText={richTextDescription}
          />
          {informationBanner && (
            <>
              <HeadingFour as="h2" marginBottom="one">
                {informationBanner?.title}
              </HeadingFour>
              {splitAtNewlines(informationBanner?.description).map(
                (partDescription) => (
                  <BodyTwo key={partDescription} marginBottom="oneAndHalf">
                    {partDescription}
                  </BodyTwo>
                ),
              )}
            </>
          )}
          {helpfulTips &&
            helpfulTips?.length &&
            helpfulTips.map(({ id, type, title, url, helpfulTipImage }) => (
              <ActivityHelpfulTip
                key={title}
                id={id}
                type={type}
                title={title}
                url={url}
                activityName={name}
                activityType={activityType}
                image={helpfulTipImage}
                activityId={activityId}
                isAssigned={isAssigned}
                redirectBackToAssigned={getIsAssignedInternalProgram(url)}
              />
            ))}
          {shouldUseCounter && (
            <ActivityProgressCounter
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...counterProps}
            />
          )}
          {disclaimerBanner && (
            <BodyTwo marginTop="oneAndHalf">
              {`${disclaimerBanner?.title}: ${disclaimerBanner?.description}`}
            </BodyTwo>
          )}
          <ActivityReferences
            marginBottom="one"
            marginTop="three"
            references={references}
          />
        </Box>
      </ActivityDetailsWrapper>
      <ExitModals
        activityId={activityId}
        onBack={onBack}
        onDismiss={handleSaveProgressModalDismiss}
        handleActiveView={controlExitModal}
      />
      <React.Suspense fallback={null}>
        {delayOverlayActive && (
          <ApiDelayOverlay onClose={() => setDelayOverlayActive(false)} />
        )}
      </React.Suspense>
      <ActivityFooter />
    </ActivityDetailsLayout>
  );
};

export default ActivityDetailsPage;
