import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';

export const viewAllActivitiesAnalytics = (
  sub_detail: 'standalone' | 'completed' | 'missed',
) =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: SCREEN_NAMES.PROGRESS_PAGE,
    sub_detail,
    detail: 'view all',
  });
