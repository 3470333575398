import React from 'react';
import {
  GDSHeadingLevel,
  GDSIconProps,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  JOURNEY_ROUTES,
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  ComposableActivityCard,
  ACTIVITY_CAMPAIGN_TYPE,
  ActivityCardEyebrow,
} from '@leagueplatform/health-journey-common';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { selectActivityAnalytics } from 'components/configurable-journey-experience/configurable-activity-card/utils/select-activity-analytics.util';
import { PriorityActivityProgress } from './components/priority-activity-progress.component';
import { priorityHeadlineMap } from './components/priority-activity-card-headline.component';
import { PriorityActivityCardFooter } from './components/priority-activity-card-footer.component';
import { MetricType, MetricUnit } from './activity-card-utils.utils';

export interface PriorityActivityCardProps {
  activity: ConfigurableHealthActivityWSCC;
  headingLevel: GDSHeadingLevel;
}

const eyebrowIconMap: Record<
  ACTIVITY_CAMPAIGN_TYPE,
  () => GDSIconProps | null
> = {
  [ACTIVITY_CAMPAIGN_TYPE.CHALLENGE]: () => ({
    icon: 'tinyStar',
    tint: '$warningBorderDefault',
    css: { minWidth: '$threeQuarters' },
    size: '$threeQuarters',
  }),
  [ACTIVITY_CAMPAIGN_TYPE.OPTIN]: () => ({
    icon: 'illustrativeHeartFilled',
    tint: '$decorativeBrandPrimaryDefault',
    css: { minWidth: '$one' },
    size: '$one',
  }),
  [ACTIVITY_CAMPAIGN_TYPE.REWARD]: () => ({
    icon: handleStaticAsset(
      HEALTH_JOURNEY_ASSET_KEYS.REWARDS_PROGRAM_ICON,
    ) as string,
    size: '$one',
  }),
  [ACTIVITY_CAMPAIGN_TYPE.SUGGESTED]: () => null,
};

// TEMPORARY FIX UNTIL BE SENDS US VALUES RESPECTING CONTRACT
type ActivityProgressData = Exclude<
  ConfigurableHealthActivityWSCC['activityProgressData'],
  undefined
>;
type ActivityProgressItem = ActivityProgressData[0];
function checkActivityProgressData(data: ActivityProgressItem) {
  if (
    data.currentProgress !== undefined &&
    data.currentProgress !== null &&
    // @ts-ignore
    data.currentProgress !== '' &&
    data.goalTotal !== undefined &&
    data.goalTotal !== null &&
    // @ts-ignore
    data.goalTotal !== '' &&
    data.dataType !== undefined &&
    data.dataType !== null &&
    // @ts-ignore
    data.dataType !== '' &&
    data.unit !== undefined &&
    data.unit !== null &&
    // @ts-ignore
    data.unit !== ''
  ) {
    return true;
  }

  return false;
}
// END OF TEMPORARY FIX

const ActivityBody = ({
  activity,
}: {
  activity: ConfigurableHealthActivityWSCC;
}) => {
  const { shortDescription, detailLevel, activityProgressData } = activity;
  const displayShortDescription =
    detailLevel === CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION.DETAILED;

  if (activityProgressData?.length) {
    return (
      <StackLayout spacing="$half" horizontalAlignment="stretch">
        {activityProgressData
          .filter(checkActivityProgressData)
          .map((progressData) => (
            <PriorityActivityProgress
              progress={progressData.currentProgress}
              goal={progressData.goalTotal}
              unit={progressData.unit as MetricUnit}
              type={progressData.dataType as MetricType}
            />
          ))}
      </StackLayout>
    );
  }
  if (displayShortDescription) {
    return (
      <ParagraphText emphasis="subtle" size="sm">
        {shortDescription}
      </ParagraphText>
    );
  }
  return null;
};

export const PriorityActivityCard = ({
  activity,
  headingLevel,
}: PriorityActivityCardProps) => {
  const {
    id,
    name,
    activityImage,
    detailLevel,
    priority,
    campaignInfo,
    type,
    tagline,
  } = activity;
  const displayImage =
    detailLevel !== CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION.SIMPLE &&
    activityImage;
  const imageProps = displayImage
    ? { image: activityImage, altText: '' }
    : undefined;
  const displayEyebrow =
    campaignInfo &&
    campaignInfo.campaignType !== ACTIVITY_CAMPAIGN_TYPE.SUGGESTED;
  const handleClickAnalytics = () => selectActivityAnalytics(activity);
  return (
    <ComposableActivityCard
      body={<ActivityBody activity={activity} />}
      cta={JOURNEY_ROUTES.getActivityDetails(id, type)}
      eyebrow={
        displayEyebrow ? (
          <ActivityCardEyebrow
            iconProps={eyebrowIconMap[campaignInfo.campaignType]()}
          >
            {campaignInfo.name}
          </ActivityCardEyebrow>
        ) : null
      }
      footer={<PriorityActivityCardFooter activity={activity} />}
      headline={priorityHeadlineMap[priority](tagline)}
      title={{ text: name, level: headingLevel }}
      image={imageProps}
      onClick={handleClickAnalytics}
    />
  );
};
