import React from 'react';
import { Formik, Form } from 'formik';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  HeadingTwo,
  BodyOneSecondary,
  Flex,
  PrimaryButton,
  ErrorMessage,
  css,
  genesisStyled,
  Spinner,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { CHALLENGE_TYPES } from '@leagueplatform/health-journey-api';
import { ErrorState } from '@leagueplatform/web-common-components';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';
import { useGetAvatars } from 'hooks/use-get-avatars.hook';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import {
  CREATE_TEAM_BUTTON_CONTAINER_MAX_WIDTH,
  CREATE_TEAM_MODAL_HEADING_FONT,
  BUTTON_CONTAINER_MAX_WIDTH,
  CONTENT_MAX_WIDTH,
  PROFILE_SELECT_CONTAINER_FIXED_HEIGHT,
} from 'constants/challenges.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { AvatarRadioOption } from './avatar-radio-option/avatar-radio-option.component';

const AVATAR_SELECT_LABEL = 'selectAvatarLabel';
const AVATAR_SELECT_DESCRIPTION = 'selectAvatarDescription';
const { SELECT_TEAM_AVATAR } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { CONFIRM } = SIGN_UP_ANALYTICS_DETAILS;

export interface SelectAvatarProps {
  challengeType: CHALLENGE_TYPES;
  onSubmit: (photoUrl: string) => void;
  signUpIsLoading: boolean;
}
interface SelectAvatarFormValues {
  selectedAvatar: string;
}

const StyledForm = genesisStyled(Form)`
  width: 100%;
  margin: 0 auto;
`;

export const SelectAvatar = ({
  challengeType,
  onSubmit,
  signUpIsLoading,
}: SelectAvatarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const isChallengeTypeUser = challengeType === CHALLENGE_TYPES.USER;
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const {
    isLoading,
    error,
    data: avatarData,
  } = useGetAvatars(
    isChallengeTypeUser ? CHALLENGE_TYPES.USER : CHALLENGE_TYPES.TEAM,
  );
  const handleSubmitAnalytics = () =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SELECT_TEAM_AVATAR,
      detail: CONFIRM,
    });
  const initialValues: SelectAvatarFormValues = {
    selectedAvatar: '',
  };

  if (isLoading)
    return (
      <Flex
        justifyContent="center"
        color="decorative.brand.primary.default"
        height={PROFILE_SELECT_CONTAINER_FIXED_HEIGHT}
      >
        <Spinner isLoading />
      </Flex>
    );
  if (error || !avatarData?.data?.length)
    return <ErrorState headingId="GET_AVATARS_ERROR" hideButton />;

  return (
    <Flex
      flexDirection="column"
      textAlign={isChallengeTypeUser ? 'center' : 'left'}
      alignItems={isChallengeTypeUser ? 'center' : 'normal'}
    >
      <HeadingTwo
        id={AVATAR_SELECT_LABEL}
        fontSize={
          isChallengeTypeUser ? 'heading2' : CREATE_TEAM_MODAL_HEADING_FONT
        }
      >
        {formatMessage({
          id: 'SELECT_AVATAR',
        })}
      </HeadingTwo>
      <BodyOneSecondary
        marginTop="one"
        marginBottom={isChallengeTypeUser ? 'oneAndHalf' : 'two'}
        id={AVATAR_SELECT_DESCRIPTION}
      >
        {formatMessage({
          id: isChallengeTypeUser
            ? 'PROFILE_PHOTO_DESCRIPTION'
            : 'TEAMS_SELECT_AVATAR_DESCRIPTION',
        })}
      </BodyOneSecondary>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmitAnalytics();
          onSubmit(values.selectedAvatar);
        }}
        validate={(values) => {
          const errors: { selectedAvatar?: string } = {};
          if (values.selectedAvatar === '') {
            errors.selectedAvatar = formatMessage({
              id: 'SELECT_AVATAR_ERROR',
            });
          }
          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors }) => (
          <StyledForm>
            <Flex
              aria-describedby={AVATAR_SELECT_DESCRIPTION}
              aria-labelledby={AVATAR_SELECT_LABEL}
              borderColor="secondary.border.default"
              borderStyle="dashed"
              borderWidth="thin"
              css={css({
                '&:focus-within': {
                  boxShadow: 'focusRing',
                },
              })}
              flexWrap="wrap"
              height={PROFILE_SELECT_CONTAINER_FIXED_HEIGHT}
              justifyContent="space-around"
              marginX="auto"
              maxWidth={CONTENT_MAX_WIDTH}
              overflow="auto"
              padding="one"
              role="radiogroup"
            >
              {avatarData.data.map(({ id, name, url }) => (
                <AvatarRadioOption
                  key={id}
                  image={url}
                  alt={name}
                  value={url}
                  selected={values.selectedAvatar === url}
                  disabled={signUpIsLoading}
                />
              ))}
            </Flex>
            <Flex
              flexDirection="column"
              maxWidth={
                isChallengeTypeUser ? BUTTON_CONTAINER_MAX_WIDTH : 'unset'
              }
              marginLeft="auto"
              marginRight="auto"
            >
              {errors.selectedAvatar && (
                <ErrorMessage
                  display="flex"
                  alignItems="center"
                  alignSelf={isChallengeTypeUser ? 'normal' : 'center'}
                  marginTop="two"
                  role="alert"
                >
                  <ChallengeIcon iconType={ICON_TYPES.ALERT} />
                  <ErrorMessage as="span" marginLeft="one">
                    {errors.selectedAvatar}
                  </ErrorMessage>
                </ErrorMessage>
              )}
              <PrimaryButton
                alignSelf={isChallengeTypeUser ? 'normal' : 'flex-end'}
                marginTop={
                  // Fix this!
                  // eslint-disable-next-line no-nested-ternary
                  isChallengeTypeUser
                    ? errors.selectedAvatar
                      ? 'two'
                      : 'five'
                    : 'oneAndHalf'
                }
                marginBottom={isChallengeTypeUser ? 'oneAndHalf' : 'none'}
                width="100%"
                maxWidth={
                  isChallengeTypeUser
                    ? 'unset'
                    : CREATE_TEAM_BUTTON_CONTAINER_MAX_WIDTH
                }
                type="submit"
                isLoading={signUpIsLoading}
              >
                {formatMessage({
                  id: 'CONFIRM',
                })}
              </PrimaryButton>
            </Flex>
          </StyledForm>
        )}
      </Formik>
    </Flex>
  );
};
