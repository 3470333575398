import { useHistory, useLocation } from '@leagueplatform/routing';
import { openInTab, isAbsoluteUrl } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

import {
  JOURNEY_ROUTES,
  useApiDelayOverlay,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  COMPLETION_METHOD_TYPES,
} from '@leagueplatform/health-journey-common';
import { useActivityAchievementsActions } from './use-activity-achievements-actions.hook';
import { useActivityAnalytics } from './use-activity-details-analytics.hook';
import { useActivityStepsAnalytics } from './use-activity-steps-analytics.hook';
import { useActivityFooterErrorMessage } from './use-activity-footer-error-message.hook';
import { useCompleteUserHealthActivity } from './use-complete-user-health-activity.hook';
import { useDismissUserHealthActivity } from './use-dismiss-user-health-activity.hook';

import { hasActivityMetCompletionCriteria } from '../utils/user-health-activity/user-health-activity.utils';
import { useHealthActivity } from './use-health-activity.hook';
import { useActivityParams } from './use-activity-params.hook';

const { COMPLETED } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { STEP, COUNTER, BASE } = COMPLETION_METHOD_TYPES;

export const useHealthActivityFooter = (isActivityDetailsView) => {
  const history = useHistory();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { userActivityId } = useActivityParams();

  const healthActivity = useHealthActivity();
  const {
    campaignMode,
    canExpire,
    cta,
    endDate,
    startDate,
    status,
    activityType,
  } = healthActivity;

  const { setDelayOverlayActive } = useApiDelayOverlay();

  const activityHasMetCompletionCriteria =
    hasActivityMetCompletionCriteria(healthActivity);

  const { errorMessage, applyErrorMessage } =
    useActivityFooterErrorMessage(healthActivity);
  const activityStepsAnalytics = useActivityStepsAnalytics();
  const activityAnalytics = useActivityAnalytics();

  // Achievements actions
  const { showDeleteActivityModal, toggleDeleteActivityModal } =
    useActivityAchievementsActions(userActivityId);

  // Activity actions
  const dismissActivityMutation = useDismissUserHealthActivity(userActivityId);
  const completeActivityMutation =
    useCompleteUserHealthActivity(userActivityId);

  const isLoadingComplete = completeActivityMutation.isLoading;

  const requestSetUserHealthActivityDelete = () => {
    dismissActivityMutation.mutate();
  };

  const completeActivity = () => {
    if (isLoadingComplete) {
      setDelayOverlayActive(true);
      return;
    }

    const searchParams = new URLSearchParams(location?.search);
    const isFromHome = searchParams.get('b') === '1';

    if (cta?.mark_as_complete) {
      completeActivityMutation.mutate();
      activityAnalytics.sendCompleteActivity();
    }

    if (cta?.url) {
      const { url } = cta;
      if (isAbsoluteUrl(url)) {
        openInTab(url);
      } else {
        let urlWithoutApp = url.replace(/^app|^\/app/, '');
        if (isFromHome) {
          const prefix = urlWithoutApp.includes('?') ? '&' : '?';
          urlWithoutApp = `${urlWithoutApp}${prefix}b=1`;
        }
        history.push(urlWithoutApp);
      }
    }
  };

  const onClose = () => {
    activityAnalytics.sendCloseActivity();
    history.push(location.state?.referrer ?? JOURNEY_ROUTES.journeyHome());
  };

  const primaryAction = () => {
    switch (cta?.completion_method?.type) {
      case STEP:
        if (isActivityDetailsView) {
          activityStepsAnalytics.sendStartActivity();
          return history.push(
            JOURNEY_ROUTES.getActivitySteps(
              userActivityId,
              activityType,
              location,
            ),
          );
        }
        return completeActivity();
      case COUNTER:
        if (!activityHasMetCompletionCriteria) {
          return applyErrorMessage.goalIncomplete();
        }
        return completeActivity();
      case BASE:
      default:
        return completeActivity();
    }
  };

  const secondaryAction = () => {
    activityAnalytics.sendRemoveActivityPrompt();
    toggleDeleteActivityModal(true);
  };

  const primaryCtaText = () => {
    switch (cta?.completion_method?.type) {
      case STEP:
        if (isActivityDetailsView) {
          return status === COMPLETED
            ? formatMessage({ id: 'VIEW_ACTIVITY' })
            : cta?.completion_method?.text;
        }
        return cta?.text;
      case BASE:
      default:
        return cta?.text;
    }
  };

  return {
    activityHasMetCompletionCriteria,
    activityId: userActivityId,
    campaignMode,
    canExpire,
    cta,
    endDate,
    errorMessage,
    isActivityDetailsView,
    isLoadingComplete,
    isLoadingDelete: dismissActivityMutation.isLoading || false,
    onClose,
    primaryAction,
    primaryCtaText,
    requestSetUserHealthActivityDelete,
    secondaryAction,
    showDeleteActivityModal,
    startDate,
    status,
    toggleDeleteActivityModal,
  };
};
