import { useInfiniteQuery } from 'react-query';
import {
  getUserHealthJourneyHistory,
  getUserHealthJourneyHistoryByPaginationLink,
  UserHealthJourneyHistoryQueryParameters,
} from '@leagueplatform/health-journey-api';
import { captureError } from '@leagueplatform/observability';

export const GET_USER_HEALTH_JOURNEY_HISTORY_DATA_KEY =
  'userHealthJourneyHistory';

export const useGetUserHealthJourneyHistory = (
  params?: UserHealthJourneyHistoryQueryParameters,
) =>
  useInfiniteQuery(
    [GET_USER_HEALTH_JOURNEY_HISTORY_DATA_KEY, params],
    ({ pageParam }) => {
      if (pageParam) {
        return getUserHealthJourneyHistoryByPaginationLink(pageParam);
      }
      return getUserHealthJourneyHistory(params);
    },
    {
      getNextPageParam(lastPage) {
        if (!lastPage?.links?.next) {
          return undefined;
        }

        // TODO: Remove this string manipulation after the merge of
        // https://everlong.atlassian.net/browse/WEBPLAT-2249
        // Which will allow for the passing of a full URL to leagueFetch
        const { next } = lastPage.links;
        return next.slice(next.indexOf('/v2/'));
      },
      onError(error) {
        captureError(
          new Error(
            `Unable to fetch paginated user health journey history: ${error}`,
          ),
          {
            context: {
              parameters: {
                api: JSON.stringify(params),
              },
            },
          },
        );
      },
    },
  );
