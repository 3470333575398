/**
 * Attempt to parse any value as JSON without throwing an exception or propagating an error
 * @param value Any value to be parsed as JSON
 * @returns `JSON || undefined`
 */
export const safeJsonParse = (value: any) => {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};
