import { isValidElement } from 'react';
import type { GDSStyleObject } from '../../theme';

export const labelLegendStyles: GDSStyleObject = {
  typography: '$label',
  color: '$onSurfaceTextSubdued',
  marginBottom: '$quarter',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
};

// When you pass a named React element, like <TextInput/>, the type is either an object or a function.
// When it's an HTML tag, like <input type="text"/>, the type is a string.
export const isReactElement = (element: any) =>
  isValidElement(element) &&
  (typeof element.type === 'object' || typeof element.type === 'function');
