import * as React from 'react';
import {
  HeadingText,
  ParagraphText,
  Popover,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import { StackLayout } from '@leagueplatform/web-common-components';

interface DefinitionPopverProps {
  description: string;
  title?: string;
  triggerLabel: string;
}

export const DefinitionPopover = ({
  description: definition,
  title,
  triggerLabel,
}: DefinitionPopverProps) => (
  <Popover.Root>
    <Popover.Trigger
      icon="interfaceQuestionCircle"
      label={triggerLabel}
      size="small"
      hideLabel
      priority="secondary"
      css={{ padding: '0', border: 'none' }}
    />
    <Popover.Content
      side={{ '@initial': 'right', '@mobile': 'bottom' }}
      css={{ maxWidth: '320px', '&:focus': { outline: '$interactiveFocus' } }}
      showCloseButton={false}
    >
      <StackLayout space="$one">
        {title && (
          <HeadingText size="md" level="4">
            {title}
          </HeadingText>
        )}
        <HtmlToReact
          htmlString={definition}
          options={{ p: { component: ParagraphText, props: { size: 'sm' } } }}
        />
      </StackLayout>
    </Popover.Content>
  </Popover.Root>
);
