import React from 'react';
import {
  Card,
  useClickableCard,
  StackLayout,
  Icon,
  Box,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { MasonryNotificationCardContentRenderer } from './masonry-notification-card-content-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export type MasonryNotificationCardNodeProperties = {
  heading: string;
  subheading: string;
  info?: string;
  eyebrow?: string;
};

const clickableCardStyles: GDSStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  gap: '$one',
  padding: '$one',
  paddingRight: '$quarter',
  alignItems: 'center',
};

type MasonryNotificationCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryNotificationCardNode = MasonryEngineNode<
  WidgetType.NOTIFICATION_CARD,
  MasonryNotificationCardNodeProperties,
  MasonryNotificationCardNodeActions
>;

export type MasonryNotificationCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryNotificationCardNode>;

export const MasonryNotificationCardRenderer = ({
  heading,
  subheading,
  info,
  eyebrow,
  onClick,
}: MasonryNotificationCardRendererProps) => {
  const isClickable = Boolean(onClick);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <Card.Elevated backgroundColor="secondary">
      <StackLayout orientation="horizontal" verticalAlignment="stretch">
        {isClickable ? (
          <Card.ClickArea
            css={clickableCardStyles}
            ref={clickAreaRef}
            onCardMouseUp={onCardMouseUp}
            onCardMouseDown={onCardMouseDown}
          >
            <MasonryNotificationCardContentRenderer
              heading={heading}
              subheading={subheading}
              info={info}
              eyebrow={eyebrow}
              onClick={onClick}
              ref={primaryActionRef}
            />
            {isClickable && <Icon icon="interfaceChevronRight" />}
          </Card.ClickArea>
        ) : (
          <Box css={{ padding: '$one' }}>
            <MasonryNotificationCardContentRenderer
              heading={heading}
              subheading={subheading}
              info={info}
              eyebrow={eyebrow}
              onClick={onClick}
              ref={primaryActionRef}
            />
          </Box>
        )}
      </StackLayout>
    </Card.Elevated>
  );
};
