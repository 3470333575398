import * as React from 'react';
import {
  WeeklyBarGraph,
  MonthlyBarGraph,
} from 'components/charts/core/bar-graph';
import { useIntl } from '@leagueplatform/locales';
import { HealthMetricChart } from 'components/charts/types/chart';
import { BarGraph } from 'components/charts/core/bar-graph/types/bar-graph';
import { HealthMetric } from '@leagueplatform/dashboard-api';
import { CHART_VIEWS } from '../constants/chart-views';

type MetricBarChartProps = Omit<HealthMetricChart, 'unit'> &
  Omit<BarGraph<HealthMetric>, 'title' | 'accessors' | 'axisBottomLabel'>;

export function MetricBarChart({
  data,
  view,
  type,
  ...props
}: MetricBarChartProps) {
  const { formatMessage } = useIntl();

  const Chart = view === CHART_VIEWS.WEEKLY ? WeeklyBarGraph : MonthlyBarGraph;
  const axisBottomLabel = formatMessage({
    id: view === CHART_VIEWS.WEEKLY ? 'DAY_OF_THE_WEEK' : 'DAY_OF_THE_MONTH',
  });

  return (
    <Chart
      data={data}
      accessors={{
        xAccessor: (datum) => datum.timestamp,
        yAccessor: (datum) => datum.value as number,
      }}
      title={formatMessage(
        {
          id:
            view === CHART_VIEWS.WEEKLY
              ? 'INSIGHT_CHART_WEEK_TITLE'
              : 'INSIGHT_CHART_MONTH_TITLE',
        },
        {
          type: formatMessage({
            id: `INSIGHT_CARD_HEADER_${type}`.toUpperCase(),
          }),
        },
      )}
      axisBottomLabel={axisBottomLabel}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
