import React, { FC } from 'react';
import { BlockquoteAttributes } from '@leagueplatform/health-journey-api';
import { ToolboxComponentProps } from '../../types/health-activity.types';
import { ActivityToolboxRichText } from './activity-toolbox-rich-text.component';
import { Blockquote } from './activity-toolbox-building-blocks.component';

interface BlockQuoteProps extends ToolboxComponentProps, BlockquoteAttributes {}

export const ActivityToolboxBlockquote: FC<BlockQuoteProps> = ({
  text,
  css: cssOverrides,
  componentId,
}) => (
  <Blockquote css={cssOverrides} data-testid={componentId}>
    <ActivityToolboxRichText richText={text} />
  </Blockquote>
);
