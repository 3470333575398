import React, { FC } from 'react';

// Common Dependencies
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';

// Constants
import { TRANSITIONS } from '../../constants/health-activity-design.constants';

// Types
interface HealthActivityPageProgressProps {
  css: GDSStyleObject;
  totalPages: number;
  currentPage: number;
}

interface PageIndicatorProps {
  isActive: boolean;
}

// Local Components
const progressBarStyles: GDSStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: ' 0 25%',
  marginBottom: '$oneAndHalf',
  gap: '$quarter',
};

const PageIndicator = ({ isActive }: PageIndicatorProps) => (
  <Box
    css={{
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '$interactiveActionDisabled',
      borderRadius: '$medium',
      border: 'none',
      height: '$quarter',
      width: '100%',
      '&:before': {
        content: '',
        position: 'absolute',
        inset: 0,
        transform: `scaleX(${Number(isActive)})`,
        transformOrigin: 'left',
        backgroundColor: '$interactiveActionPrimary',
        transition: `transform ${TRANSITIONS.PAGE}`,
      },
    }}
  />
);

export const HealthActivityPageProgress: FC<
  HealthActivityPageProgressProps
> = ({ css = {}, totalPages, currentPage }) => {
  const pageIndices = [...Array(totalPages).keys()];

  return (
    <Box css={{ ...progressBarStyles, ...css }}>
      {pageIndices.map((pageIndex) => (
        <PageIndicator
          key={`page-progress-${pageIndex}`}
          isActive={currentPage >= pageIndex}
        />
      ))}
    </Box>
  );
};
