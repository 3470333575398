/*
 * Theme fallback values are maintained manually based on additions in the Figma script manifest
 * at https://github.com/EverlongProject/genesis-figma-script/blob/main/src/manifest.json
 */

export const fallbackTokens: { [key: string]: any } = {
  'colors.input.background.default': 'colors.interactive.background.default',
  'colors.input.background.disabled': 'colors.interactive.background.disabled',
  'colors.input.background.hovered': 'colors.interactive.background.hovered',
  'colors.input.background.pressed': 'colors.interactive.background.pressed',
  'colors.input.border.critical': 'colors.interactive.border.critical.default',
  'colors.input.border.default': 'colors.interactive.border.default',
  'colors.input.border.disabled': 'colors.interactive.border.disabled',
  'colors.input.border.hovered': 'colors.interactive.border.hovered',
  'colors.input.border.success': 'colors.success.border.default',
  'colors.input.border.warning': 'colors.warning.border.default',
  'colors.onSurface.skeleton.background': 'colors.input.background.disabled',
  'colors.onSurface.skeleton.highlight':
    'linear-gradient(87deg, rgba(255, 255, 255, 0) 3.38%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0) 94.72%)',
};
