import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  GDSIconProps,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  ActivityCardFooterItem,
  useGetUserProfile,
} from '@leagueplatform/health-journey-common';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { Badge } from '@leagueplatform/web-common-components';

const {
  REQUIRED_ICON,
  ACTIVITY_POINTS_ICON,
  REWARDS_POINTS_ICON,
  REWARDS_PROGRAM_ICON,
  COMPLETE_BY_ICON,
} = HEALTH_JOURNEY_ASSET_KEYS;

enum FOOTER_ICON_OPTIONS {
  REWARD_POINTS,
  REWARD_PROGRAM,
  ACTIVITY_POINTS,
  COMPLETE_BY,
  EXPIRES,
  REQUIRED,
}
const baseIconProps = { css: { minWidth: '$one' }, size: '$one' };

export const footerIconMap: Record<
  FOOTER_ICON_OPTIONS,
  () => GDSIconProps | null
> = {
  [FOOTER_ICON_OPTIONS.ACTIVITY_POINTS]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(ACTIVITY_POINTS_ICON) as string,
  }),
  [FOOTER_ICON_OPTIONS.REWARD_POINTS]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(REWARDS_POINTS_ICON) as string,
  }),
  [FOOTER_ICON_OPTIONS.REWARD_PROGRAM]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(REWARDS_PROGRAM_ICON) as string,
  }),
  [FOOTER_ICON_OPTIONS.COMPLETE_BY]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(COMPLETE_BY_ICON) as string,
  }),
  [FOOTER_ICON_OPTIONS.REQUIRED]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(REQUIRED_ICON) as string,
  }),
  [FOOTER_ICON_OPTIONS.EXPIRES]: () => ({
    ...baseIconProps,
    icon: handleStaticAsset(COMPLETE_BY_ICON) as string,
  }),
};

export const PriorityActivityCardFooter = ({
  activity,
}: {
  activity: ConfigurableHealthActivityWSCC;
}) => {
  const { formatMessage, formatDate } = useIntl();
  const isBelowMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const {
    activityPoints,
    activityRewardInfo,
    canExpire,
    endDate,
    required,
    campaignInfo,
  } = activity;

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  const qualifiesForRewardProgram = campaignInfo?.qualifiesForRewardProgram;
  const displayCompleteBy = canExpire && !!endDate && required;
  const displayExpiryDate = canExpire && !!endDate && !required;
  let rewardsPoints;
  if (activityRewardInfo?.type === 'reward_points') {
    rewardsPoints = activityRewardInfo.available;
  }
  const displayFooter =
    qualifiesForRewardProgram ||
    !!rewardsPoints ||
    !!activityPoints ||
    required ||
    displayCompleteBy ||
    displayExpiryDate;
  if (!displayFooter) return null;

  return (
    <StackLayout
      orientation={isBelowMobile ? 'vertical' : 'horizontal'}
      verticalAlignment="center"
      spacing={isBelowMobile ? '$half' : '$oneAndHalf'}
    >
      {qualifiesForRewardProgram && (
        <Badge css={{ backgroundColor: '$decorativeBrandSecondaryPastel' }}>
          <ActivityCardFooterItem
            iconProps={footerIconMap[FOOTER_ICON_OPTIONS.REWARD_PROGRAM]()}
          >
            {formatMessage({ id: 'REWARDS_PROGRAM_ELIGIBLE' })}
          </ActivityCardFooterItem>
        </Badge>
      )}
      {/* rewards points */}
      {!!rewardsPoints && (
        <ActivityCardFooterItem
          iconProps={footerIconMap[FOOTER_ICON_OPTIONS.REWARD_POINTS]()}
        >
          {formatMessage({ id: 'X_REWARD_POINTS' }, { points: rewardsPoints })}
        </ActivityCardFooterItem>
      )}
      {/* activity points */}
      {!!activityPoints && (
        <ActivityCardFooterItem
          iconProps={footerIconMap[FOOTER_ICON_OPTIONS.ACTIVITY_POINTS]()}
        >
          {formatMessage({ id: 'X_LEAGUE_POINTS' }, { points: activityPoints })}
        </ActivityCardFooterItem>
      )}
      {/* required activities */}
      {required && (
        <ActivityCardFooterItem
          iconProps={footerIconMap[FOOTER_ICON_OPTIONS.REQUIRED]()}
        >
          {formatMessage({ id: 'REQUIRED' })}
        </ActivityCardFooterItem>
      )}
      {/* "complete by" activities */}
      {displayCompleteBy && (
        <ActivityCardFooterItem
          iconProps={footerIconMap[FOOTER_ICON_OPTIONS.COMPLETE_BY]()}
        >
          {formatMessage(
            { id: 'COMPLETE_BY_DATE' },
            {
              date: formatDate(endDate, {
                ...SHORT_DATE_FORMAT,
                timeZone: defaultTimezone,
              }),
            },
          )}
        </ActivityCardFooterItem>
      )}
      {/* expirable activities */}
      {displayExpiryDate && (
        <ActivityCardFooterItem
          iconProps={footerIconMap[FOOTER_ICON_OPTIONS.COMPLETE_BY]()}
        >
          {formatMessage(
            { id: 'ACTIVITY_EXPIRY_DATE' },
            {
              date: formatDate(endDate, {
                ...SHORT_DATE_FORMAT,
                timeZone: defaultTimezone,
              }),
            },
          )}
        </ActivityCardFooterItem>
      )}
    </StackLayout>
  );
};
