import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Overline,
  Image,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import {
  CONFIGURABLE_ACTIVITY_PRIORITY,
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
} from '@leagueplatform/health-journey-common';
import { getPriorityBannerContainerStylingProps } from './utils/get-priority-banner-container-styling-props.util';

interface ActivityCardPriorityBannerProps {
  activityId: string;
  priority: CONFIGURABLE_ACTIVITY_PRIORITY;
  detail: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  tagline?: string;
  hasImage: boolean;
  // display prop should be a responsive object, which differs depending on if banner is being used in the ConfigurableActivityCard or ActivityCardContent component
  display: any;
  isProgressActivity: boolean;
}

const { HIGH_PRIORITY_ICON } = HEALTH_JOURNEY_ASSET_KEYS;
const { LOW, HIGH } = CONFIGURABLE_ACTIVITY_PRIORITY;

export const ActivityCardPriorityBanner = ({
  activityId,
  priority,
  detail,
  tagline,
  hasImage,
  display,
  isProgressActivity,
}: ActivityCardPriorityBannerProps) => {
  const { formatMessage } = useIntl();
  const { boxStylingProps } = getPriorityBannerContainerStylingProps(
    detail,
    priority,
    display,
    hasImage,
    isProgressActivity,
  );

  const highPriorityIcon = handleStaticAsset(HIGH_PRIORITY_ICON);

  if (priority === LOW)
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Box {...boxStylingProps}>
        <VisuallyHidden id={activityId}>
          {formatMessage({ id: 'LOW_PRIORITY_ACTIVITY_ALT_TEXT' })}
        </VisuallyHidden>
      </Box>
    );

  return (
    <Box
      as="header"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxStylingProps}
    >
      {priority === HIGH ? (
        <Image
          src={highPriorityIcon as string}
          alt={formatMessage({
            id: 'HIGH_PRIORITY_ACTIVITY_ALT_TEXT',
          })}
          size="one"
          marginRight="half"
          id={activityId}
        />
      ) : (
        <VisuallyHidden id={activityId}>
          {formatMessage({ id: 'MEDIUM_PRIORITY_ACTIVITY_ALT_TEXT' })}
        </VisuallyHidden>
      )}
      <Overline
        as="p"
        // @ts-ignore TODO: update text colour tokens when design has solution
        color={
          priority === HIGH
            ? 'highlight.background.secondary'
            : 'highlight.icon'
        }
      >
        {tagline}
      </Overline>
    </Box>
  );
};
