import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout, Box } from '@leagueplatform/genesis-core';
import { Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import { SkeletonBox, ErrorState } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  HEALTH_JOURNEY_COMMON_ASSET_KEYS,
  JOURNEY_ROUTES,
  ENABLE_V3_ACTIVITY_CARDS,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { ConfigurableActivityCard } from 'components/configurable-journey-experience/configurable-activity-card/configurable-activity-card.component';
import { getActivityStatusesArray } from 'utils/get-activity-statuses-array.util';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useActivitiesByActivityStatus } from 'hooks/use-activities-by-activity-status.hook';
import { PriorityActivityCard } from 'components/configurable-journey-experience/priority-activity-card/priority-activity-card.component';
import { ConfigurableJourneyEmptyStateCard } from '../configurable-journey-empty-state-card/configurable-journey-empty-state-card';

export const ActivityCardGroup = genesisStyled(Box)`
  width: 100%;
  li:last-of-type {
    margin-bottom: 0;
  }
`;

export const PriorityActivitiesWidget = () => {
  const { formatMessage } = useIntl();
  const { data: isV3ActivityCardsEnabled } = useFeatureFlagQuery(
    ENABLE_V3_ACTIVITY_CARDS,
  );
  const { ACTIVE } = HEALTH_JOURNEY_ACTIVITY_STATUS;
  const activityStatuses = getActivityStatusesArray(ACTIVE);
  const {
    data: activities,
    isLoading,
    isError,
  } = useActivitiesByActivityStatus({
    activityStatuses,
  });

  if (isLoading)
    return (
      <>
        {Array(4)
          .fill('')
          .map((box, index) => (
            <SkeletonBox
              height="160px"
              backgroundColor="interactive.background.disabled"
              marginBottom="one"
              // using index here as these will be the only thing on the page
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
      </>
    );
  if (isError || !activities?.userHealthActivities) return <ErrorState />;

  const activeActivities = activities?.userHealthActivities;

  const { EMPTY_STATE_CONFIGURABLE_ACTIVITIES } =
    HEALTH_JOURNEY_COMMON_ASSET_KEYS;
  const emptyStateImgUrl = handleStaticAsset(
    EMPTY_STATE_CONFIGURABLE_ACTIVITIES,
  ) as string;

  if (!activeActivities.length) {
    const handleAnalyticsEvent = () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.JOURNEY,
        screen_name: SCREEN_NAMES.PROGRESS_PAGE,
        detail: 'explore library',
      });
    return (
      <Flex as="section" alignItems="start">
        <ConfigurableJourneyEmptyStateCard
          eyebrow={formatMessage({
            id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW',
          })}
          heading={formatMessage({
            id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING',
          })}
          headingLevel="2"
          description={formatMessage({
            id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION',
          })}
          imgSrc={emptyStateImgUrl}
          ctaLabel={formatMessage({ id: 'EXPLORE_LIBRARY' })}
          ctaHref={JOURNEY_ROUTES.programsHome()}
          backgroundColor="highlight"
          analyticsOnClick={handleAnalyticsEvent}
        />
      </Flex>
    );
  }
  return (
    <Flex as="section" alignItems="start">
      {isV3ActivityCardsEnabled ? (
        <StackLayout as="ul" spacing="$one" horizontalAlignment="stretch">
          {activeActivities.map((activity) => (
            <Box as="li" key={activity.id}>
              <PriorityActivityCard activity={activity} headingLevel="2" />
            </Box>
          ))}
        </StackLayout>
      ) : (
        <ActivityCardGroup as="ul">
          {activeActivities.map((activity) => (
            <ConfigurableActivityCard
              activity={activity}
              key={activity.id}
              activityHeadingLevel="h2"
            />
          ))}
        </ActivityCardGroup>
      )}
    </Flex>
  );
};
