import { getContentUrl } from '@leagueplatform/league-content-api';
import { getContentfulImageUrl } from '@leagueplatform/web-common';

/**
 * Callback to process a list of helpful tips to ensure images are being fetched from the correct location
 * @param {object} helpfulTip
 * @returns A new helpful tip object
 */
export const processActivityHelpfulTips = (helpfulTip) => {
  // Check contentfulImageAsset for the program image url. If it is not available, get the image from the image content id or imageUrl
  const contentfulImageAssetUrl =
    helpfulTip?.contentful_image_asset?.fields?.file?.url;
  const getHelpfulTipImageUrl = helpfulTip?.image_content_id
    ? getContentUrl(helpfulTip?.image_content_id)
    : helpfulTip?.image_url;

  return {
    ...helpfulTip,
    helpfulTipImage: contentfulImageAssetUrl
      ? getContentfulImageUrl(contentfulImageAssetUrl)
      : getHelpfulTipImageUrl,
  };
};
