import {
  BodyOne,
  Box,
  Flex,
  Image,
  SubtitleOne,
  SecondaryButton,
  FlexProps,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import * as React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import { MetricType } from '@leagueplatform/dashboard-api';
import { dataTypeConfigMap } from 'constants/data-type-config';

type NoDataProps = FlexProps & {
  metric: MetricType;
};

export const NoDataAvailable = ({ metric, ...props }: NoDataProps) => {
  const { formatMessage } = useIntl();
  const connectedDeviceImage = handleStaticAsset(
    DASHBOARD_ASSET_MAP.DASHBOARD_NO_HEALTH_METRIC_DATA,
  ) as string;

  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    React.useState(false);
  const dataTypeConfig = dataTypeConfigMap.get(metric);
  // Sanity check if for whatever reason an unsupported data type is passed in during runtime we ignore it.
  if (!dataTypeConfig) {
    return null;
  }
  return (
    <>
      <Flex
        backgroundColor="surface.background.secondary"
        borderWidth="thin"
        borderColor="onSurface.border.subdued"
        flexDirection="column"
        alignItems="center"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Box marginTop="oneAndHalf" marginBottom="one">
          <Image width={215} src={connectedDeviceImage} alt="altTxt" />
        </Box>
        <Flex flexDirection="column" alignItems="center">
          <SubtitleOne marginBottom="quarter" fontWeight="semibold">
            {formatMessage({
              id: 'NO_DATA_AVAILABLE',
            })}
          </SubtitleOne>
          <BodyOne textAlign="center" marginBottom="oneAndHalf">
            {formatMessage(
              { id: 'CURRENTLY_NOT_SHARING_DATA' },
              {
                metric: formatMessage({
                  id: dataTypeConfig.metric,
                }),
              },
            )}
          </BodyOne>
          <SecondaryButton
            onClick={() => {
              setDisplayUnavailableOnWebModal(true);
            }}
            marginBottom="oneAndHalf"
          >
            {formatMessage(
              { id: 'START_TRACKING_METRIC' },
              {
                metric: formatMessage({
                  id: dataTypeConfig.metric,
                }),
              },
            )}
          </SecondaryButton>
        </Flex>
      </Flex>
      {displayUnavailableOnWebModal ? (
        <UnavailableDeviceConnectModal
          onClose={() => setDisplayUnavailableOnWebModal(false)}
        />
      ) : null}
    </>
  );
};
