import React, { useEffect, useRef, useState } from 'react';
import { Questionnaire } from 'fhir/r4';
import {
  Box,
  Button,
  HeadingText,
  Spinner,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Slide } from 'components/slide.component';
import { DynamicFHIRForm } from '@leagueplatform/fhir-questionnaire';
import { useCreateMessageModalWithFhir } from 'hooks/use-create-message-modal-with-fhir.hook';
import { useGetFirstQuestionnaireIdConfig } from 'hooks/use-get-first-questionnaire-id-config.hook';
import { useSetDraftThread } from 'hooks/use-set-draft-thread.hook';
import { useGoBack } from 'hooks/use-go-back.hook';
import { MessagingQuestionnaireResponse } from '@leagueplatform/messaging-api';
import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { useHistory } from '@leagueplatform/routing';
import {
  MESSAGING_SCREEN_NAMES,
  trackMessagingAnalyticsEvent,
} from 'utils/track-messaging-analytics.util';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { ErrorState } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';

interface LastRequestType {
  fn: (data: any) => void;
  data: any;
}

const Wrapper = styled(Box, {
  backgroundColor: '$surfaceBackgroundTertiary',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  inset: 0,
  overflowX: 'hidden',
});
const Body = styled(Box, {
  flex: 1,
  paddingTop: '$threeQuarters',
});
const Footer = styled(Box, {
  display: 'flex',
  flexGrow: 0,
  justifyContent: 'center',
  paddingTop: '$oneAndHalf',
  paddingBottom: '$oneAndHalf',
});
const Slider = styled(Box, {
  display: 'flex',
  position: 'relative',
  flexGrow: 1,
  height: '100%',
  width: '100%',
  maxWidth: 632,
  margin: '0 auto',
});
const SlideWrap = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: '$surfaceBackgroundPrimary',
  padding: '$two',
  borderRadius: '$large',
  overflowY: 'auto',
});
const ErrorWrapper = styled(SlideWrap, {
  justifyContent: 'center',
  position: 'absolute',
  inset: 0,
  zIndex: 9,
});
const LoadingWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const NewMessagePage = () => {
  const { formatMessage } = useIntl();
  const goBack = useGoBack();

  const [pages, setPages] = useState<Array<any>>([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState('');
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Array<MessagingQuestionnaireResponse>
  >([]);
  const [lastRequest, setLastRequest] = useState<LastRequestType | null>(null);
  const formRefs = useRef<Array<HTMLButtonElement>>([]);
  formRefs.current = [];

  const setDraftThread = useSetDraftThread();

  const history = useHistory();

  useEffect(() => {
    trackMessagingAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      screen_name: MESSAGING_SCREEN_NAMES.NEW_MESSAGE,
    });
  }, []);

  // update currentPage after pages state is updated in nextStep/prevStep
  // move to next slide
  useEffect(() => {
    if (pages.length > 0) {
      setCurrentPage(pages.length - 1);
    }
  }, [pages]);

  const { firstQuestionnaireId = '' } = useGetFirstQuestionnaireIdConfig();

  const { generateQuestionnaireMutation, postNextNodeMutation } =
    useCreateMessageModalWithFhir();

  // add next page
  const nextStep = (data: any) => {
    setPages((current) => [...current, data]);
  };

  const setQuestionnaireData = (data: any, variables: any) => {
    setCurrentQuestionnaireId(variables.questionnaireId);
    nextStep(data);
  };

  const getQuestionnaireData = ({
    questionnaireId,
    answer,
  }: {
    questionnaireId: string;
    answer: MessagingQuestionnaireResponse | null;
  }) => {
    // Save last request to retry if it fails
    setLastRequest({
      fn: getQuestionnaireData,
      data: {
        questionnaireId,
        answer,
      },
    });

    generateQuestionnaireMutation.mutate(
      {
        questionnaireId,
        answers: answer ? [answer] : [],
      },
      {
        onSuccess: (data, variables) => {
          // Clean last request because it succeeded
          setLastRequest(null);
          setQuestionnaireData(data, variables);
        },
      },
    );
  };

  // set the firstQuestionnaireId as the current questionnaire ID
  useEffect(() => {
    if (firstQuestionnaireId) {
      getQuestionnaireData({
        questionnaireId: firstQuestionnaireId,
        answer: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstQuestionnaireId]);

  const nextQuestionnaire = (answer: MessagingQuestionnaireResponse) => {
    // Save last request to retry if it fails
    setLastRequest({
      fn: nextQuestionnaire,
      data: answer,
    });

    setQuestionnaireAnswers((current) => [...current, answer]);

    postNextNodeMutation.mutate(currentQuestionnaireId, {
      onSuccess({ data }) {
        // Clean last request because it succeeded
        setLastRequest(null);

        if (data?.attributes?.completed) {
          const newDraftThread = {
            subject: formatMessage({ id: 'NEW_MESSAGE' }),
            participants: [],
            answers: [...questionnaireAnswers, answer], // Include the new answer
          };

          setDraftThread(newDraftThread);
          history.push(
            `${getModulePath(LEAGUE_MODULE_NAMES.messaging)}/threads`,
          );
          return;
        }
        getQuestionnaireData({
          questionnaireId: data?.attributes?.nodeId,
          answer,
        });
      },
    });
  };

  const addToRefs = (el: HTMLButtonElement) => {
    if (el && !formRefs.current?.includes(el)) {
      formRefs.current.push(el);
    }
  };

  // remove current page from stack and update questionnaire id
  const prevStep = () => {
    // This condition avoids double click on the back button
    if (!isTransitioning) {
      const previousPage = pages[currentPage - 1];
      setIsTransitioning(true);
      setCurrentQuestionnaireId(previousPage?.data?.id);
      setTimeout(() => {
        setPages(pages.slice(0, -1));
        setIsTransitioning(false);
      }, 500);
    }
  };

  const triggerFormSubmit = () => {
    if (formRefs.current[currentPage]) {
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      formRefs.current[currentPage].dispatchEvent(event);
    }
  };

  const isLoading =
    generateQuestionnaireMutation.isLoading || postNextNodeMutation.isLoading;

  const isError =
    generateQuestionnaireMutation.isError || postNextNodeMutation.isError;

  const errorImage = handleStaticAsset(
    MESSAGING_ASSET_KEYS.MESSAGING_ERROR,
  ) as string;

  const retryRequest = () => {
    lastRequest?.fn(lastRequest?.data);
  };

  return (
    <Wrapper>
      <Body>
        <Button
          onClick={goBack}
          priority="secondary"
          icon="tinyClose"
          hideLabel
          quiet
          size="small"
          css={{
            position: 'absolute',
            top: '$threeQuarters',
            right: 40,
            zIndex: 1,
          }}
        >
          {formatMessage({ id: 'CLOSE' })}
        </Button>
        <Slider>
          {isError && (
            <ErrorWrapper>
              <ErrorState hideButton imageSrc={errorImage} />
            </ErrorWrapper>
          )}
          {isLoading && !pages.length && (
            <LoadingWrapper>
              <Spinner loading />
            </LoadingWrapper>
          )}
          {pages.map((page, index) => (
            <Slide
              key={page.data?.id}
              activeIndex={currentPage}
              gap="120%"
              slideIndex={index}
            >
              <SlideWrap>
                <HeadingText level="1" size="xl">
                  {formatMessage({ id: 'MESSAGE_CENTER' })}
                </HeadingText>
                <DynamicFHIRForm
                  FHIRQuestionnaire={
                    pages[index]?.data?.attributes?.response as Questionnaire
                  }
                  setQuestionnaireAnswer={nextQuestionnaire}
                  handleSubmitInParent
                  ref={addToRefs}
                />
              </SlideWrap>
            </Slide>
          ))}
        </Slider>
      </Body>
      <Footer>
        {pages.length > 1 && (
          <Button
            onClick={prevStep}
            quiet
            priority="secondary"
            css={{
              marginRight: '$one',
            }}
          >
            {formatMessage({ id: 'GO_BACK' })}
          </Button>
        )}
        {!isError && (
          <Button onClick={triggerFormSubmit} loading={isLoading}>
            {formatMessage({ id: 'CONTINUE' })}
          </Button>
        )}
        {isError && (
          <Button onClick={retryRequest}>
            {formatMessage({ id: 'RETRY' })}
          </Button>
        )}
      </Footer>
    </Wrapper>
  );
};
