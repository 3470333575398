import React, { PropsWithChildren, memo, useEffect } from 'react';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { LeagueIntlProvider } from '@leagueplatform/locales';
import { ToastManager } from '@leagueplatform/toast-messages';
import { isNodeTestEnv } from '@leagueplatform/app-environment';
import {
  setConfig,
  getConfig,
  LeagueConfigError,
} from '@leagueplatform/config';
import type { LeagueConfig } from '@leagueplatform/config';
import { SessionTimeout } from '@leagueplatform/session-timeout';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { Auth, Implementation } from '@leagueplatform/auth';
import { LeagueThemeProvider } from './league-theme-provider';
import { LeagueRouteComponentProvider } from './league-route-component-provider';
import { ReactQueryProviderWrapper } from './react-query-provider-wrapper';

const NO_CONFIG_ERROR =
  'Config was neither set before mounting `<LeagueProvider>` nor passed to its `config` prop. You must do one or the other!';

/**
 * Everything `LeagueProvider` renders (various providers around the children), without the config logic.
 * Memoized in advance so that when it's rendered in `LeagueProvider` and the latter re-renders due
 * to a change in the `config` prop, none of the internals need to re-render.
 */
const InnerProviders = memo(({ children }: PropsWithChildren<{}>) => {
  const contentsWithoutErrorBoundary = (
    <ReactQueryProviderWrapper>
      <LeagueThemeProvider>
        <LeagueIntlProvider>
          <LeagueRouteComponentProvider>
            {children}
            {!isNodeTestEnv() && <ToastManager />}
            {!isNodeTestEnv() &&
              Auth.initialized &&
              Auth.implementation === Implementation.STANDALONE && (
                <SessionTimeout
                  getIsAuthenticated={() =>
                    StandaloneAuth.client.isAuthenticated()
                  }
                  onSessionExpire={() => StandaloneAuth.client.logout()}
                  onSessionExtend={() =>
                    StandaloneAuth.client.getTokenSilently({
                      cacheMode: 'off',
                    })
                  }
                />
              )}
          </LeagueRouteComponentProvider>
        </LeagueIntlProvider>
      </LeagueThemeProvider>
    </ReactQueryProviderWrapper>
  );

  return isNodeTestEnv() ? (
    contentsWithoutErrorBoundary
  ) : (
    <ObservabilityErrorBoundary>
      {contentsWithoutErrorBoundary}
    </ObservabilityErrorBoundary>
  );
});

type LeagueProviderProps = PropsWithChildren<{
  /**
   * a {@link LeagueConfig `LeagueConfig`} object to set the global League Config.
   */
  config?: LeagueConfig;
  /**
   * Whether to treat the `config` object as an initial value only. Should be set
   * to `true` if you intend to use the `setConfig` function dynamically at a later
   * time, after the initial render of this component
   */
  treatConfigAsInitialOnly?: boolean;
}>;

/**
 * Top-level provider of core league configuration and features. Must be rendered
 * above any routes or components coming from the League SDK.
 */
export const LeagueProvider = ({
  children,
  config,
  treatConfigAsInitialOnly,
}: LeagueProviderProps) => {
  if (!getConfig(false)) {
    // the config has not yet been set.

    if (!config) {
      // AND we were not provided one via the prop.
      throw new LeagueConfigError(NO_CONFIG_ERROR);
    }

    /**
     * Set the config store for the first time immediately, so that descendents that
     * depend on the store having been set can be safely mounted.
     *
     * NOTE: we allow ourselves to mutate the config store as part of this
     * component's render (rather than only as an effect) even though doing so would
     * update the state of any components listening to the store, because
     * we know that by doing it **only if the store has not yet been set** means
     * that no components who listen to the store would currently be mounted.
     */
    setConfig(config);
  }

  useEffect(() => {
    /**
     * On any subsequent change to the `config` prop that happens **after**
     * the store was already set for the first time, we mutate the store as an effect,
     * in order to abide by React rules; specifically, that the state of one
     * component must never be updated as part of the rendering of another component.
     *
     * Note that we'll only do this if the `treatConfigAsInitialOnly` is not set
     * to `true`. If it is, we assume the consumer will not be changing the value
     * of this prop after the first render.
     */
    if (config && !treatConfigAsInitialOnly) {
      setConfig(config);
    }
  }, [config, treatConfigAsInitialOnly]);

  return <InnerProviders>{children}</InnerProviders>;
};
