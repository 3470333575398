import * as React from 'react';
import {
  InsightHighlightCard,
  InsightCardVerticalBarGraph,
} from '@leagueplatform/dashboard';
import { VerticalBarGraph } from '@leagueplatform/dashboard-api';
import { INSIGHT_TYPE } from 'constants/analytics';
import { trackWidgetClickEvent } from '../widgets/utils';

type VerticalBarGraphInsightResponse = {
  body: VerticalBarGraph;
  callout: {
    description: string;
    image: string;
    image_alt_text: string;
  };
  cta: string;
  heading: string;
};

export function VerticalBarGraphInsightNodeRenderer({
  body,
  callout,
  cta,
  heading,
}: VerticalBarGraphInsightResponse) {
  return (
    <InsightHighlightCard
      heading={heading}
      seeMoreLink={cta}
      callout={callout}
      onClick={() => {
        trackWidgetClickEvent(body.metric, INSIGHT_TYPE.VERTICAL_BAR_GRAPH);
      }}
    >
      <InsightCardVerticalBarGraph
        title={body.title}
        description={body.description}
        metric={body.metric}
        unit={body.unit}
        items={body.items}
      />
    </InsightHighlightCard>
  );
}
