import React from 'react';
import { InternalOrExternalLink } from '../internal-or-external-link/internal-or-external-link.component';

interface CTALinkProps {
  children: React.ReactNode;
  href: string;
  ariaLabel?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

export const CTALink = ({
  children,
  ariaLabel,
  href,
  onClick,
}: CTALinkProps) => (
  <InternalOrExternalLink href={href} onClick={onClick} aria-label={ariaLabel}>
    {children}
  </InternalOrExternalLink>
);
