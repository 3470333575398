import * as React from 'react';
import {
  useGetWidgetData,
  ContainerWidgetItem,
} from '@leagueplatform/masonry-api';
import { useIntl } from '@leagueplatform/locales';
import {
  ENABLE_NEW_JOURNEY_PROGRESS,
  HEALTH_JOURNEY_MASONRY_VERSION,
  JOURNEY_ROUTES,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  Button,
  Card,
  HeadingText,
  StackLayout,
  UtilityText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  ProgramsAndChallengesProgressCardProps,
  ProgramsAndChallengesProgressCard,
} from '../programs-and-challenges-progress-card/programs-and-challenges-progress-card.component';

interface ProgramsAndChallengesWidgetProps {
  widget: ContainerWidgetItem;
}

interface ProgramsAndChallengesWidgetAttributes {
  heading: string;
  items: ProgramsAndChallengesProgressCardProps[];
}

interface WidgetContentProps {
  items: ProgramsAndChallengesProgressCardProps[];
}

function WidgetContent({ items }: WidgetContentProps) {
  const { formatMessage } = useIntl();
  const belowMobileLandscape = useMediaQuery(
    queryHelpers.down('mobileLandscape'),
  );
  const isEmptyState = !items?.length;
  const handleAnalyticsEvent = () =>
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: SCREEN_NAMES.PROGRESS_PAGE,
      detail: 'explore library',
    });

  if (isEmptyState) {
    return (
      <Card.Elevated backgroundColor="secondary" css={{ width: '100%' }}>
        <StackLayout orientation="horizontal" verticalAlignment="center">
          <Card.Section>
            <HeadingText level="2" size={belowMobileLandscape ? 'sm' : 'md'}>
              {formatMessage({
                id: 'NO_CHALLENGES_PROGRAMS_EMPTY_STATE_HEADING',
              })}
            </HeadingText>
            <UtilityText
              size="sm"
              as="p"
              css={{ marginTop: '$quarter', marginBottom: '$one' }}
            >
              {formatMessage({
                id: 'NO_CHALLENGES_PROGRAMS_EMPTY_STATE_BODY',
              })}
            </UtilityText>
            <Button
              as="a"
              onClick={handleAnalyticsEvent}
              href={JOURNEY_ROUTES.programsHome()}
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
              css={{
                height: '$twoAndHalf',
                fontSize: '$bodyTwo',
                padding: '$half $one',
              }}
            >
              {formatMessage({ id: 'EXPLORE_LIBRARY' })}
            </Button>
          </Card.Section>
        </StackLayout>
      </Card.Elevated>
    );
  }

  return (
    <StackLayout spacing="$one" horizontalAlignment="stretch">
      {items.map(({ badge, content, cta, heading, percentageProgress }) => (
        <ProgramsAndChallengesProgressCard
          key={cta}
          badge={badge}
          content={content}
          cta={cta}
          heading={heading}
          percentageProgress={percentageProgress}
        />
      ))}
    </StackLayout>
  );
}

export function ProgramsAndChallengesWidget({
  widget,
}: ProgramsAndChallengesWidgetProps) {
  const { data: isNewJourneyProgressEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_PROGRESS,
  );

  const { data, isLoading, error } =
    useGetWidgetData<ProgramsAndChallengesWidgetAttributes>(
      widget,
      HEALTH_JOURNEY_MASONRY_VERSION,
    );

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error || !data || !data?.attributes || !isNewJourneyProgressEnabled) {
    return null;
  }

  const { heading: widgetTitle, items } = data.attributes;

  return (
    <StackLayout spacing="$one" horizontalAlignment="stretch">
      <HeadingText level="2" size="xl">
        {widgetTitle}
      </HeadingText>
      <WidgetContent items={items} />
    </StackLayout>
  );
}
