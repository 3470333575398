export enum UserHealthActivityStatus {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  REMOVED = 'removed',
}

export const HEALTH_JOURNEY_ACTIVITY_STATUS = {
  SUSPENDED: 'suspended',
  COMPLETED_TODAY: 'completed_today',
  MISSED: 'missed', // aggregate of expired + removed
  ...UserHealthActivityStatus,
};

export type CSHealthActivityStatusType =
  | 'active'
  | 'upcoming'
  | 'completed'
  | 'cancelled'
  | 'removed'
  | 'expired'
  | 'suspended'
  | 'undefined'
  | 'received';

export type CSChallengeActivitiesSortType =
  | '-status'
  | 'status'
  | '-endDate'
  | 'endDate'
  | '-startDate'
  | 'startDate';

export enum ComponentTypes {
  Badge = 'badge',
  Banner = 'banner',
  Biometric = 'biometric',
  Blockquote = 'block-quote',
  ChallengeProgress = 'challenge-progress',
  DateInput = 'date-input',
  DecimalInput = 'decimal-input',
  FullPageHighlight = 'full-page-highlight',
  Header = 'header',
  Image = 'image',
  IntegerInput = 'integer-input',
  Link = 'link',
  List = 'list',
  MultipleSelectInput = 'multiple-select-input',
  PersonHeight = 'person-height-input',
  PersonWeight = 'person-weight-input',
  ProgressTracking = 'progress-tracking',
  RichText = 'rich-text',
  StringInput = 'string-input',
  DateTimeInput = 'date-time-input',
}

const {
  DateInput,
  DecimalInput,
  IntegerInput,
  MultipleSelectInput,
  PersonHeight,
  PersonWeight,
  ProgressTracking,
  StringInput,
} = ComponentTypes;

export const ToolboxInteractiveComponentTypes = {
  DateInput,
  DecimalInput,
  IntegerInput,
  MultipleSelectInput,
  PersonHeight,
  PersonWeight,
  ProgressTracking,
  StringInput,
} as const;

export enum ACTIVITY_CAMPAIGN_TYPE {
  OPTIN = 'opt-in',
  CHALLENGE = 'challenge',
  SUGGESTED = 'suggested',
  REWARD = 'reward',
}

export const COMPLETION_METHOD_TYPES = Object.freeze({
  STEP: 'step',
  BASE: 'base',
  COUNTER: 'counter',
});

export const JOURNEY_MANAGER_ENABLED = 'journey_manager';

export enum CONFIGURABLE_ACTIVITY_PRIORITY {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION {
  SIMPLE = 'Simple',
  STANDARD = 'Standard',
  DETAILED = 'Detailed',
}
