import React from 'react';
import { useClickableCard } from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { LargeImageCard } from 'components/common/large-image-card/large-image-card.component';
import { MasonryProgressBarCardContentRenderer } from './masonry-progress-bar-card-content-renderer.component';
import { MasonryProgressBarCardRendererType } from './masonry-progress-bar-card-renderer.type';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

type MasonryProgressBarCardNodeProperties =
  MasonryProgressBarCardRendererType & {
    primaryActionRef?: React.RefObject<HTMLAnchorElement>;
  };

export type MasonryProgressBarCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryProgressBarCardNode = MasonryEngineNode<
  WidgetType.PROGRESS_BAR,
  MasonryProgressBarCardNodeProperties,
  MasonryProgressBarCardNodeActions
>;

export type MasonryNotificationCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryProgressBarCardNode>;

const MasonryProgressBarCardWithoutImage = ({
  eyebrow,
  heading,
  currentText,
  progress,
  suffixText,
  footerText,
  emphasizeCurrentValue,
  onClick,
  primaryActionRef,
}: MasonryNotificationCardRendererProps & {
  primaryActionRef?: React.RefObject<HTMLAnchorElement>;
}) => (
  <MasonryProgressBarCardContentRenderer
    eyebrow={eyebrow}
    heading={heading}
    currentText={currentText}
    progress={progress}
    suffixText={suffixText}
    footerText={footerText}
    linkRefState={primaryActionRef}
    emphasizeCurrentValue={emphasizeCurrentValue}
    onClick={onClick}
  />
);

const MasonryProgressBarCardWithImage = ({
  eyebrow,
  heading,
  image,
  imageAltText = '',
  currentText,
  progress,
  suffixText,
  footerText,
  onClick,
  isChildItem = false,
  emphasizeCurrentValue,
  primaryActionRef,
}: MasonryNotificationCardRendererProps &
  Required<Pick<MasonryNotificationCardRendererProps, 'image'>> & {
    primaryActionRef?: React.RefObject<HTMLAnchorElement>;
  }) => (
  <LargeImageCard
    image={image}
    imageAltText={imageAltText}
    isChildItem={isChildItem}
  >
    <MasonryProgressBarCardContentRenderer
      eyebrow={eyebrow}
      heading={heading}
      currentText={currentText}
      progress={progress}
      suffixText={suffixText}
      footerText={footerText}
      linkRefState={primaryActionRef}
      emphasizeCurrentValue={emphasizeCurrentValue}
      onClick={onClick}
    />
  </LargeImageCard>
);

export const MasonryProgressBarCardRenderer = ({
  ...props
}: MasonryNotificationCardRendererProps) => {
  const { image, action } = props;
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(action)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      imagePosition={{
        '@initial': 'start',
        '@mobile': 'top',
      }}
    >
      {image ? (
        <MasonryProgressBarCardWithImage
          image={image}
          primaryActionRef={primaryActionRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      ) : (
        <MasonryProgressBarCardWithoutImage
          primaryActionRef={primaryActionRef}
          action={action}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
    </ConditionalLinkCardRenderer>
  );
};
