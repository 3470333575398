import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  HeadingFour,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { HEALTH_JOURNEY_ACTIVITY_STATUS } from '@leagueplatform/health-journey-common';

const { EXPIRED, REMOVED, UPCOMING, COMPLETED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;

const mapStatus = {
  [EXPIRED]: {
    backgroundColor: 'onSurface.text.subdued',
    color: 'primary.text.default',
    labelId: 'ACTIVITY_STATUS_EXPIRED',
  },
  [REMOVED]: {
    backgroundColor: 'onSurface.text.primary',
    color: 'primary.text.default',
    labelId: 'ACTIVITY_STATUS_REMOVED',
  },
  [UPCOMING]: {
    backgroundColor: 'decorative.brand.primary.pastel',
    color: 'onSurface.text.primary',
    labelId: 'ACTIVITY_STATUS_UPCOMING',
  },
  [COMPLETED]: {
    backgroundColor: 'success.background.subdued',
    color: 'onSurface.text.primary',
    labelId: 'COMPLETED',
  },
};

export const ActivityStatusBadge = ({ status, ...props }) => {
  const { formatMessage } = useIntl();

  if (!status || !mapStatus[status]) return null;
  const { backgroundColor, color, labelId } = mapStatus[status];

  return (
    <Flex
      alignItems="center"
      backgroundColor={backgroundColor}
      borderRadius="pill"
      justifyContent="flex-start"
      paddingY="quarter"
      paddingX="threeQuarters"
      width="fit-content"
      data-testid="activity-status-badge"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <HeadingFour as="span" color={color}>
        <VisuallyHidden>
          {formatMessage({ id: 'ACTIVITY_STATUS' })}
        </VisuallyHidden>
        {formatMessage({ id: labelId })}
      </HeadingFour>
    </Flex>
  );
};

ActivityStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
