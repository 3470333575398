import { useGetUserHealthActivities } from '../../../hooks/use-get-user-health-activities.hook';

/**
 * @returns Activities grouped by campaign, challenge, and priority with it's ready/error states
 */
export const useHealthJourneyHomePage = () => {
  const {
    data: activitiesData,
    isLoading,
    isError,
  } = useGetUserHealthActivities();

  const activitiesByAssigned = activitiesData?.activitiesByAssigned || [];
  const activitiesByCampaign = activitiesData?.activitiesByCampaign || [];
  const activitiesByChallenge = activitiesData?.activitiesByChallenge || [];
  const activitiesByPriority = activitiesData?.activitiesByPriority || [];

  const hasActivities =
    activitiesByAssigned?.length > 0 ||
    activitiesByCampaign?.length > 0 ||
    activitiesByChallenge?.length > 0 ||
    activitiesByPriority?.length > 0;

  return {
    activitiesByAssigned,
    activitiesByCampaign,
    activitiesByChallenge,
    activitiesByPriority,
    allActivities: activitiesData?.data,
    hasActivities,
    hasError: isError,
    ready: !isLoading,
  };
};
