import React from 'react';
import {
  HeadingText,
  StackLayout,
  UtilityText,
  Icon,
  StackItem,
  Card,
  useClickableCard,
  TextAction,
  GDSImagePosition,
  GDSStackLayoutProps,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { RewardProgramCard } from 'types/configurable-health-program.types';
import { Badge } from '@leagueplatform/web-common-components';

type RewardProgramCardProps = RewardProgramCard & {
  imagePosition?: GDSImagePosition;
  contentTextAlign?: GDSStackLayoutProps['verticalAlignment'];
};

export const RewardsProgramProgressCard = ({
  badge,
  cta,
  heading,
  image,
  imageAltText,
  items,
  imagePosition,
  contentTextAlign = 'center',
}: RewardProgramCardProps) => {
  const { formatMessage } = useIntl();
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const cardImageCss =
    imagePosition === 'top'
      ? undefined
      : {
          '&&': {
            maxWidth: '50%',
          },
          '@mobile': {
            '&&': {
              maxWidth: 'unset',
            },
          },
        };

  return (
    <Card.Elevated css={{ position: 'relative', height: '100%' }}>
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={
          imagePosition
            ? { '@initial': imagePosition }
            : {
                '@initial': 'start',
                '@mobile': 'top',
                '@desktop': 'start',
              }
        }
      >
        <Card.Image image={image} imageAlt={imageAltText} css={cardImageCss} />
        <StackItem
          css={{
            position: 'absolute',
            top: '$one',
            left: '$one',
          }}
        >
          <Badge
            css={{
              backgroundColor: '$decorativeBrandSecondaryBrightest',
              borderRadius: '$small',
            }}
          >
            <>
              <Icon icon={badge.image} size="$one" />
              <UtilityText size="eyebrow" emphasis="emphasized">
                {formatMessage({
                  id: 'REWARD_PROGRAM',
                })}
              </UtilityText>
            </>
          </Badge>
        </StackItem>
        <Card.Section css={{ padding: '$oneAndHalf' }}>
          <StackLayout
            verticalAlignment={contentTextAlign}
            spacing="$threeQuarters"
          >
            <TextAction ref={primaryActionRef} href={cta}>
              <HeadingText level="3" size="lg">
                {heading}
              </HeadingText>
            </TextAction>
            <StackItem>
              <StackLayout orientation="vertical" spacing="$threeQuarters">
                {items.map((item) => (
                  <StackItem>
                    {item.heading && (
                      <StackItem>
                        <HeadingText size="xs" level="4">
                          {item.heading}
                        </HeadingText>
                      </StackItem>
                    )}
                    <StackItem>
                      <StackLayout
                        verticalAlignment="center"
                        orientation="horizontal"
                        spacing="$quarter"
                      >
                        <Icon icon={item.content.image} size="$one" />
                        {item.content.prefixText && (
                          <UtilityText
                            size="sm"
                            emphasis="emphasized"
                            css={{ color: '$onSurfaceTextSuccess' }}
                          >
                            {item.content.prefixText}
                          </UtilityText>
                        )}
                        <UtilityText size="sm">
                          {item.content.currentText}
                        </UtilityText>
                      </StackLayout>
                    </StackItem>
                  </StackItem>
                ))}
              </StackLayout>
            </StackItem>
          </StackLayout>
        </Card.Section>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
