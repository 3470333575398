import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  HeadingTwo,
  Flex,
  Link,
  SubtitleOne,
  Box,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { HealthActivities } from '../health-activities.view';
import { useHealthJourneyHomePage } from './hooks/use-health-journey-home.hook';

const HealthJourneyHomeContainer = ({ children }) => (
  <Box
    paddingY={{ _: 'two', tablet: 'three' }}
    className="health-journey-home-container"
  >
    {children}
  </Box>
);

HealthJourneyHomeContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const HealthJourneyHome = () => {
  const { formatMessage } = useIntl();
  const {
    ready,
    hasError,
    hasActivities,
    activitiesByAssigned,
    activitiesByCampaign,
    activitiesByChallenge,
    activitiesByPriority,
    allActivities,
  } = useHealthJourneyHomePage();

  if (!ready) {
    return (
      <HealthJourneyHomeContainer>
        <SkeletonBox height="200px" role="presentation" />
      </HealthJourneyHomeContainer>
    );
  }

  if (hasError) return null;

  return hasActivities ? (
    <HealthJourneyHomeContainer>
      <Flex justifyContent="space-between" marginBottom="two">
        <HeadingTwo>{formatMessage({ id: 'YOUR_JOURNEY' })}</HeadingTwo>
        <Link
          as={RouterLink}
          to={JOURNEY_ROUTES.journeyHome()}
          aria-label={formatMessage({ id: 'GO_TO_HEALTH_JOURNEY' })}
        >
          <SubtitleOne as="span" color="interactive.action.primary">
            {formatMessage({ id: 'VIEW_ALL' })}
          </SubtitleOne>
        </Link>
      </Flex>
      <HealthActivities
        activitiesByAssigned={activitiesByAssigned}
        activitiesByCampaign={activitiesByCampaign}
        activitiesByChallenge={activitiesByChallenge}
        activitiesByPriority={activitiesByPriority}
        allActivities={allActivities}
      />
    </HealthJourneyHomeContainer>
  ) : null;
};
