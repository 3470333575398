import * as React from 'react';
import { Box, HeadingText } from '@leagueplatform/genesis-core';
import { StackLayout } from '@leagueplatform/web-common-components';
import { CategoriesV2, BalanceV2 } from '@leagueplatform/wallet-api';
import { useIntl } from '@leagueplatform/locales';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { DefinitionPopover } from 'components/definition-popover/definition-popover.component';
import { LiveBalanceItem } from '../live-balance-item/live-balance-item.component';

interface BalanceGroupProps extends CategoriesV2 {
  networkName?: string;
}

export const BalanceGroup = ({
  title: defaultTitle,
  type,
  balances,
  networkName,
  definition,
}: BalanceGroupProps) => {
  const { formatMessage } = useIntl();
  const [member] = useSelectedMember();
  const parseBalanceTitle = () => {
    if (type === 'individual') {
      return member?.name || formatMessage({ id: 'INDIVIDUAL' });
    }

    return defaultTitle;
  };
  const title = parseBalanceTitle();

  return (
    <StackLayout>
      <Box css={{ display: 'flex', gap: '$half', alignItems: 'center' }}>
        <HeadingText size="md" level="4" css={{ textTransform: 'capitalize' }}>
          {title}
        </HeadingText>
        {definition.description && (
          <DefinitionPopover
            description={definition.description}
            title={definition.title}
            triggerLabel={formatMessage(
              { id: 'PLAN_PROGRESS_POP_UP_LABEL' },
              { title: definition.title },
            )}
          />
        )}
      </Box>
      <StackLayout as="ul">
        {balances.map(
          (
            {
              title: balanceName,
              limit,
              remaining_amount,
              paid_amount,
            }: BalanceV2,
            i: number,
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box as="li" key={`accordion-balance-item-${type}-${title}-${i}`}>
              <LiveBalanceItem
                version={2}
                balanceName={balanceName}
                networkName={networkName}
                limit={limit}
                paid_amount={paid_amount}
                remaining_amount={remaining_amount}
                category={type}
                headingLevel="5"
              />
            </Box>
          ),
        )}
      </StackLayout>
    </StackLayout>
  );
};
