import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import { useQueryClient } from 'react-query';
import { GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE } from '@leagueplatform/health-journey-api';
import { useMemo } from 'react';
import { useActivityParams } from './use-activity-params.hook';

const { BUTTON_CLICKED, SCREEN_LOADED } = EVENT_NAME;

// Activity meta data
const meta = {
  product_area: PRODUCT_AREA.JOURNEY,
  screen_name: SCREEN_NAMES.ACTIVITY_DETAILS,
};

export const useActivityAnalytics = () => {
  const queryClient = useQueryClient();
  const {
    userActivityId,
    campaignId,
    // contentActivityIdParam is nullish when retrieved via url param and is used for deep linking purposes
    contentActivityId: contentActivityIdParam,
  } = useActivityParams();

  const activityData = useMemo(
    () =>
      queryClient.getQueryData([
        GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
        userActivityId,
        contentActivityIdParam,
        campaignId,
      ]),
    [campaignId, contentActivityIdParam, queryClient, userActivityId],
  );
  const activity = activityData?.health_activity || {};

  const {
    cta = {},
    goal_id: contentActivityId,
    name: activityName,
    type: activityType,
    status: activityStatus,
    campaign_info: campaignInfo = {},
    activity_verification_progress: activityProgress = {},
    front_of_the_line_activity: isFotl,
  } = activity;
  const counterProgressValue = activityProgress?.count_progress;
  const completionMethod = cta?.completion_method || {};
  const { counter = {}, type: completionType } = completionMethod;

  // Activity static data
  const data = {
    health_campaign_activity_id: contentActivityId,
    health_campaign_activity_name: activityName,
    health_campaign_activity_type: activityType,
    health_campaign_activity_ux_type: completionType || null,
    health_campaign_activity_status: activityStatus,
    health_campaign_type: campaignInfo?.campaign_type || null,
  };

  // Activity stateful data
  const activityState = {
    health_campaign_activity_goal: counter?.goal || null,
    health_campaign_activity_current_progress: counterProgressValue || null,
  };

  return {
    /**
     * View Activity
     * @description Only fire the activity screen view event when the
     * activity id this module is initialized with matches the activity id that api responds with.
     * Without this check, the event is liable to fire for a previously cached activity that the user has visited
     */
    sendViewActivity() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
        ...activityState,
      });
    },

    // Activity Removal Analytics
    sendRemoveActivityPrompt() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        ...activityState,
        detail: 'remove health campaign activity',
      });
    },
    sendViewRemoveActivityModal() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
        ...activityState,
        screen_name: SCREEN_NAMES.REMOVE_MODAL,
      });
    },
    sendConfirmActivityRemove() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.REMOVE_MODAL,
        detail: 'confirm health campaign activity removal',
      });
    },
    sendCancelActivityRemove() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.REMOVE_MODAL,
        detail: 'cancel health campaign activity removal',
      });
    },

    // Complete Activity Analytics
    sendViewCompleteActivityModal() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.COMPLETE_MODAL,
      });
    },
    sendCompleteActivity() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        ...activityState,
        detail: 'complete health campaign activity',
        sub_detail: cta?.text || null,
        is_fotl: isFotl,
      });
    },
    sendCloseActivityComplete() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        screen_name: SCREEN_NAMES.COMPLETE_MODAL,
        detail: 'close',
      });
    },

    // Close Activity Analytics
    sendCloseActivity() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        ...activityState,
        detail: 'close activity',
      });
    },

    // Close an activity or mark as done from the Progress Save modal
    sendViewSaveProgressModal() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.SAVE_PROGRESS_MODAL,
      });
    },
    sendDismissSaveProgressModal() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.SAVE_PROGRESS_MODAL,
        detail: 'dismiss',
      });
    },
    sendSaveProgressModalAction({ isCompletion, ctaText }) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        screen_name: SCREEN_NAMES.SAVE_PROGRESS_MODAL,
        detail: isCompletion ? 'complete health campaign activity' : 'close',
        sub_detail: ctaText || null,
      });
    },

    // Change Activity Progress Value Analytics Event
    sendEditActivityProgress({ inputMethod, newValue, oldValue }) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        ...activityState,
        detail: 'edit activity progress',
        health_campaign_activity_current_progress: newValue,
        health_campaign_activity_updated_progress: oldValue,
        health_campaign_activity_config_options: {
          input_methods: [inputMethod],
        },
      });
    },
    sendOpenEditActivityProgressModal(ctaText) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        ...activityState,
        detail: 'manual entry',
        sub_detail: ctaText,
      });
    },

    /**
     * Select Activity Helpful tip
     * @param {string} type link type (ex. audio, video, article)
     * @param {string} title helpful tip title
     * @param {string} url link-out url
     */
    sendSelectHelpfulTip({ type, title, url }) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        detail: 'select content from activity',
        sub_detail: type,
        content_url: url || null,
        link_name: title || null,
      });
    },
  };
};
