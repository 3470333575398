import * as React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import {
  HighlightCard,
  CalloutBanner,
} from '@leagueplatform/web-common-components';
import { ParagraphText } from '@leagueplatform/genesis-core';

type InsightHighlightCardProps = {
  heading: string;
  seeMoreLink: string;
  callout: {
    description: string;
    image: string;
    image_alt_text: string;
  };
  children: React.ReactNode;
  onClick: () => void;
};

export const InsightHighlightCard = ({
  heading,
  seeMoreLink,
  callout,
  children,
  onClick,
}: InsightHighlightCardProps) => (
  <HighlightCard title={heading} seeMoreLink={seeMoreLink} onClick={onClick}>
    <CalloutBanner icon={callout.image} label={callout.image_alt_text}>
      <ParagraphText>{callout.description}</ParagraphText>
    </CalloutBanner>
    <Box mt="two">{children}</Box>
  </HighlightCard>
);
