import React from 'react';
import { string } from 'prop-types';
import { Box, BodyTwo } from '@leagueplatform/genesis-commons';
import { splitAtNewlines, HtmlToReact } from '@leagueplatform/web-common';
import { ActivityHtmlToReactOptions } from 'common/activity-html-to-react-options';

/**
 * This component will render either text or rich text in a manner consistent with the requirements of the activity details description.
 * It will return rich text if provided, otherwise it will return a text string and will fall back to a fragment if neither are provided
 * @param {string} text – text string
 * @param {string} richText – rich text string
 */
export const ActivityDetailsDescription = ({ text, richText }) => {
  if (richText) {
    return (
      <Box css={{ whiteSpace: 'pre-line' }}>
        <HtmlToReact
          htmlString={richText}
          options={ActivityHtmlToReactOptions}
        />
      </Box>
    );
  }

  if (text) {
    return splitAtNewlines(text).map((line) => (
      <BodyTwo key={line} marginBottom="oneAndHalf">
        {line}
      </BodyTwo>
    ));
  }

  return null;
};

ActivityDetailsDescription.propTypes = {
  text: string,
  richText: string,
};

ActivityDetailsDescription.defaultProps = {
  text: '',
  richText: '',
};
