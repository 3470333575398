import { WALLET_LOCAL_FEATURE_FLAGS } from 'common/feature-flags';
import { getWalletLocalFeatureFlag } from 'utils/get-wallet-local-feature-flag.util';
import { NetworkPlanV2, NetworkPlanV2Raw } from '../types/plan-progress.types';

export const formatPlansArray = (
  plans: NetworkPlanV2Raw[],
): NetworkPlanV2[] => {
  const isNoNetworkEnabled = getWalletLocalFeatureFlag(
    WALLET_LOCAL_FEATURE_FLAGS.IS_NO_NETWORK_LIVE_BALANCES_ENABLED,
  );

  // TODO: Util functionality is being hidden behind a local feature flag.
  if (!isNoNetworkEnabled) {
    return plans.map((plan) => ({ ...plan, network: plan.network ?? [] }));
  }

  return (
    plans
      // Remove plans that contain null networks
      .filter((plan) => plan.network !== null)
      // if network is undefined make it an empty array
      .map((plan) => ({ ...plan, network: plan.network ?? [] }))
  );
};
