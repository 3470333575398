import React, { createContext, useCallback, useContext, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

export type MasonryEngineNodeState = {
  hide: boolean;
  reload: boolean;
  key: string;
  value: any;
};

export type MasonryEngineStateTree = Record<string, MasonryEngineNodeState>;

export type MasonryEngineStateController = {
  state: MasonryEngineStateTree;
  setState: Dispatch<SetStateAction<MasonryEngineStateTree>>;
};

export type MasonryEngineSetNodeState = (
  key: keyof MasonryEngineNodeState,
  nodeId: string,
  value: any,
) => void;

export type MasonryEngineGetNodeState = (
  key: keyof MasonryEngineNodeState,
  nodeId: string,
) => MasonryEngineNodeState | undefined;

export type MasonryEngineGetStateTree = () => MasonryEngineStateTree;

/**
 * A React Context for providing the State Controller Context {@link MasonryEngineStateController `MasonryEngineStateController`}
 * to Node Renderers and to underlying MasonryEngine resolver components.
 */
export const MasonryEngineStateControllerContext =
  createContext<MasonryEngineStateController>({
    state: {},
    setState: () => {},
  });

type MasonryEngineStateControllerProviderProps = {
  children: ReactNode;
};

export const MasonryEngineStateControllerProvider = ({
  children,
}: MasonryEngineStateControllerProviderProps) => {
  const MasonryEngineStateControllerContextProvider =
    MasonryEngineStateControllerContext.Provider;

  const [state, setState] = useState({});

  return (
    <MasonryEngineStateControllerContextProvider value={{ state, setState }}>
      {children}
    </MasonryEngineStateControllerContextProvider>
  );
};

export type MasonryEngineStateControllerCallbacks = {
  getNodeState: MasonryEngineGetNodeState;
  getStateTree: MasonryEngineGetStateTree;
  setNodeState: MasonryEngineSetNodeState;
};

const defaultNodeState: MasonryEngineNodeState = {
  hide: false,
  reload: false,
  key: '',
  value: undefined,
};

/**
 * A hook that returns a getMasonryEngineState and setMasonryEngineState callbacks
 * for {@link MasonryEngineStateController `MasonryEngineStateController`}.
 */

export const useMasonryEngineStateControllerContext =
  (): MasonryEngineStateControllerCallbacks => {
    const { state, setState: setMasonryEngineControllerState } =
      useContext<MasonryEngineStateController>(
        MasonryEngineStateControllerContext,
      );

    /**
     * A callback to to get state of that node
     * @argument key - key of the state object
     * @argument nodeId - Node Identifier
     */
    const getNodeState: MasonryEngineGetNodeState = useCallback(
      (key, nodeId) => {
        const nodeState: MasonryEngineNodeState | undefined = state[nodeId];
        return nodeState ? nodeState[key] : undefined;
      },
      [state],
    );

    /**
     * A callback to to get entire state of all nodes in Masonry Engine instance
     */
    const getStateTree: MasonryEngineGetStateTree = useCallback(
      () => state,
      [state],
    );

    /**  A callback to update state for a luxe node. It requires
     *    @argument key - State to update for the node - HIDE, RELOAD, VALUE
     *    @argument nodeId - Node Identifier
     *    @argument value - Value for the state of the node
     */
    const setNodeState: MasonryEngineSetNodeState = useCallback(
      (key, nodeId, value) => {
        const node: MasonryEngineNodeState = state[nodeId] || defaultNodeState;
        (node[key] as MasonryEngineNodeState[keyof MasonryEngineNodeState]) =
          value;
        setMasonryEngineControllerState({ ...state, [nodeId]: node });
      },
      [setMasonryEngineControllerState, state],
    );

    return {
      getNodeState,
      getStateTree,
      setNodeState,
    };
  };
