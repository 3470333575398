import { StackLayout, TextInput, styled } from '@leagueplatform/genesis-core';
import React, { FC } from 'react';
import { useToolboxDateTimeInput } from 'pages/health-activity/hooks/use-toolbox-date-time-input.hook';
import { ToolboxCommonInputProps } from 'pages/health-activity/types/health-activity.types';
import {
  DateTimeInputAttributes,
  DateTimeInputDisplay,
} from '@leagueplatform/health-journey-api';
import {
  DateField,
  DateInput,
  DateSegment,
  TimeField,
} from 'react-aria-components';
import { parseDate, parseTime, parseDateTime } from '@internationalized/date';
import { ControlledFormField } from '@leagueplatform/web-common';
import {
  ActivityToolboxInputWrapper,
  MapInputValueToProps,
} from '../activity-toolbox-input-wrapper.component';

interface DateTimeInputProps
  extends ToolboxCommonInputProps,
    DateTimeInputAttributes {}

const StyledDateTimeInput = styled(StackLayout, {
  '.react-aria-DateInput': {
    display: 'flex',
  },
  '.react-aria-DateSegment:nth-child(6)': {
    marginRight: '$half',
  },
});

export const ActivityToolboxDateTimeInput: FC<DateTimeInputProps> = (props) => {
  const {
    componentId,
    answer,
    display = DateTimeInputDisplay.full,
    css,
    altText,
  } = props;

  const { handleDateTimeChange } = useToolboxDateTimeInput({
    componentId,
  });

  const displayFull = display === DateTimeInputDisplay.full;
  const displayTimeOnly = display === DateTimeInputDisplay.timeOnly;
  const displayDateOnly = display === DateTimeInputDisplay.dateOnly;

  const mapValueToProps: MapInputValueToProps<string> = (value, component) => {
    Object.assign(component, {
      componentAttributes: {
        ...component.componentAttributes,
        answer: value?.toString(),
      },
    });

    return component;
  };

  const value = answer?.toString() || '';

  const dateDefaultValue = displayDateOnly && value ? parseDate(value) : null;
  const dateTimeDefaultValue =
    displayFull && value ? parseDateTime(value) : null;

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <ControlledFormField
        css={css}
        hideLabel
        id={componentId}
        label={altText}
        name={componentId}
        inputOptions={{}}
        aria-label={altText}
      >
        <TextInput
          leadingContent={
            <StyledDateTimeInput orientation="horizontal">
              {(displayFull || displayDateOnly) && (
                <DateField
                  defaultValue={
                    displayFull ? dateTimeDefaultValue : dateDefaultValue
                  }
                  granularity={displayDateOnly ? 'day' : 'minute'}
                  onChange={handleDateTimeChange}
                >
                  <DateInput>
                    {(segment) => <DateSegment segment={segment} />}
                  </DateInput>
                </DateField>
              )}

              {displayTimeOnly && (
                <TimeField
                  defaultValue={value ? parseTime(value) : null}
                  granularity="minute"
                  onChange={handleDateTimeChange}
                >
                  <DateInput>
                    {(segment) => <DateSegment segment={segment} />}
                  </DateInput>
                </TimeField>
              )}
            </StyledDateTimeInput>
          }
          css={{
            '.GDS-base-input:read-only': {
              backgroundColor: '$surfaceBackgroundPrimary',
              display: 'none',
            },
          }}
          readOnly
        />
      </ControlledFormField>
    </ActivityToolboxInputWrapper>
  );
};
