import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  JOURNEY_MANAGER_ENABLED,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
} from '@leagueplatform/health-journey-common';
import { useHealthActivity } from './use-health-activity.hook';

const { ACTIVE } = HEALTH_JOURNEY_ACTIVITY_STATUS;

/**
 * @function useIsMarkAsDoneEnabled
 * @returns {boolean}
 */
export const useIsMarkAsDoneEnabled = () => {
  const healthActivity = useHealthActivity();
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );

  // Mark-as-done feature de-scoped (CAMP-3304)
  const isMarkAsDoneInUse = false;
  if (!isMarkAsDoneInUse) return true;

  if (!isJourneyManagerEnabled) return true;

  const { status, activityProgress } = healthActivity;

  if (status !== ACTIVE) return false;

  const helpfulTips = activityProgress.helpful_tips || [];

  return helpfulTips.some((tip) => tip.opened === true);
};
