import * as React from 'react';
import { GDSSpace, HeadingText } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';

export interface MasonrySectionHeaderNodeProperties {
  heading: string;
  paddingTop?: GDSSpace;
}

export type MasonrySectionHeaderNode = MasonryEngineNode<
  WidgetType.SECTION_HEADER,
  MasonrySectionHeaderNodeProperties
>;

export type MasonrySectionHeaderRendererProps =
  MasonryEngineNodeRendererProps<MasonrySectionHeaderNode>;

export const MasonrySectionHeaderRenderer = ({
  heading,
  paddingTop = '$one',
}: MasonrySectionHeaderRendererProps) => (
  <HeadingText
    size="xl"
    level="2"
    css={{
      paddingTop,
    }}
  >
    {heading}
  </HeadingText>
);
