import React from 'react';
import {
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import type { Formatter } from '@leagueplatform/locales';
import {
  useLocaleUnitFormatter,
  useLocaleMetricValueFormatter,
  MetricType,
  MetricUnit,
} from '../activity-card-utils.utils';

interface PriorityActivityProgressProps {
  progress: number;
  goal: number;
  unit: MetricUnit;
  type: MetricType;
}

const styledText: Formatter = (msg) => (
  <UtilityText
    emphasis="emphasized"
    css={{
      color: '$onSurfaceTextSuccess',
    }}
  >
    {msg}
  </UtilityText>
);

export const PriorityActivityProgress = ({
  progress,
  goal,
  unit,
  type,
}: PriorityActivityProgressProps) => {
  const { formatMessage } = useIntl();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();
  return (
    <StackLayout spacing="$none">
      <ParagraphText as="span" emphasis="subtle">
        {formatMessage(
          { id: 'X_OF_Y_COMPLETED_WITH_UNIT' },
          {
            progress: formatMetricValue(progress, type),
            goal: formatMetricValue(goal, type),
            unit: formatUnit(type, unit, progress),
            span: styledText,
          },
        )}
      </ParagraphText>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        <StackItem growFactor={1}>
          <ProgressBar
            current={progress}
            total={goal}
            customBarBackgroundColor="interactive.border.disabled"
            customBarFillColor="success.border.default"
          />
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
};
