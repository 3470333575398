import React from 'react';
import {
  Box,
  Image,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import {
  CONFIGURABLE_ACTIVITY_PRIORITY,
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
} from '@leagueplatform/health-journey-common';
import { getActivityImageStylingProps } from './utils/get-activity-image-styling-props.util';

interface ActivityCardImageProps {
  priority: CONFIGURABLE_ACTIVITY_PRIORITY;
  detail: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  activityImage: string;
  // For mobile standard composition cards, activity image used in:
  // - ConfigurableActivityCard component should be 'none'
  // - ActivityCardContent component should be 'block'
  displayMobileStandardComposition: 'none' | 'block';
}

const StyledImage = genesisStyled(Image)(
  css({
    objectFit: 'cover',
    aspectRatio: '4 / 3',
  }),
);

export const ActivityCardImage = ({
  priority,
  detail,
  activityImage,
  displayMobileStandardComposition,
}: ActivityCardImageProps) => {
  const { boxStylingProps, imageStylingProps } = getActivityImageStylingProps(
    detail,
    priority,
    displayMobileStandardComposition,
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxStylingProps}
    >
      <StyledImage
        src={activityImage}
        alt=""
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imageStylingProps}
      />
    </Box>
  );
};
