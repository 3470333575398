import React, { FC } from 'react';

// Common Dependencies
import { Box } from '@leagueplatform/genesis-core';

// Hooks

// Common Components

// Local Components

// Types
interface HealthActivityContentProps {}

export const HealthActivityContent: FC<HealthActivityContentProps> = ({
  children,
}) => (
  <Box
    data-testid="health-activity-content"
    css={{
      display: 'flex',
      backgroundColor: '$surfaceBackgroundPrimary',
      borderRadius: '$large',
      boxShadow: 'dropdown',
      flexDirection: 'column',
      height: '100%',
      minWidth: '100%',
      overflowY: 'auto',
      position: 'relative',
      width: '100%',
      gap: '$one',
    }}
  >
    {children}
  </Box>
);
