import { useCallback, useMemo, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import {
  PersonWeightInputValidation,
  WeightUnits,
} from '@leagueplatform/health-journey-api';
import {
  fromKilogramsToPounds,
  fromPoundsToKilograms,
} from '../utils/unit-converters.util';

interface UseToolboxWeightInputsProps {
  componentId: string;
  outputUnit: string;
  selectedUnit: WeightUnits;
  validation: PersonWeightInputValidation;
}

export const useToolboxPersonWeightInputs = ({
  componentId,
  outputUnit,
  selectedUnit,
  validation,
}: UseToolboxWeightInputsProps) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();

  // Element Refs
  const poundsInputRef = useRef<HTMLInputElement>(null);
  const kilogramsInputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);

  // Helpers
  const getValueAsOutputUnit = useMemo(
    () => ({
      totalPounds: () => {
        if (!poundsInputRef.current?.value) return '';

        const nextValue = Number(poundsInputRef.current?.value);

        return outputUnit === WeightUnits.kilogram
          ? fromPoundsToKilograms(nextValue)
          : nextValue;
      },
      totalKilograms: () => {
        if (!kilogramsInputRef.current?.value) return '';

        const nextValue = Number(kilogramsInputRef.current?.value);

        return outputUnit === WeightUnits.pounds
          ? fromKilogramsToPounds(nextValue)
          : nextValue;
      },
    }),
    [outputUnit],
  );

  // State Representation
  /** Convert the output value's unit to the values represented in each input */
  const getUnitValues = useCallback(
    (currentValue: number | string | null) => {
      if (typeof currentValue !== 'number') return { lbs: '', kg: '' };

      return outputUnit === WeightUnits.pounds
        ? { lbs: currentValue, kg: fromPoundsToKilograms(currentValue) }
        : { lbs: fromKilogramsToPounds(currentValue), kg: currentValue };
    },
    [outputUnit],
  );

  /** Sync all input values from the output unit specified by the backend to the unit represented in each input */
  const syncUnitInputs = useCallback(
    (currentValue: number) => {
      const { lbs, kg } = getUnitValues(currentValue);

      if (selectedUnit === WeightUnits.kilogram && kilogramsInputRef.current) {
        kilogramsInputRef.current.value = kg.toString();
      }
      if (selectedUnit === WeightUnits.pounds && poundsInputRef.current) {
        poundsInputRef.current.value = lbs.toString();
      }
    },
    [getUnitValues, selectedUnit],
  );

  // Validation
  const { maximumValue, minimumValue, customWarning } = validation;

  const getMinMaxValidationMessage = useCallback(
    (messageKey, validationValue) => {
      if (customWarning) return customWarning;

      const unitValues = getUnitValues(validationValue);

      return formatMessage(messageKey, {
        value: unitValues[selectedUnit],
        unit: selectedUnit,
      });
    },
    [customWarning, formatMessage, getUnitValues, selectedUnit],
  );

  const validateMaxValue = useCallback(
    (currentValue) =>
      maximumValue && currentValue > maximumValue
        ? getMinMaxValidationMessage(
            { id: 'TOOLBOX_VALIDATION_WEIGHT_MAXIMUM' },
            maximumValue,
          )
        : undefined,
    [maximumValue, getMinMaxValidationMessage],
  );

  const validateMinValue = useCallback(
    (currentValue) =>
      minimumValue && minimumValue > currentValue
        ? getMinMaxValidationMessage(
            { id: 'TOOLBOX_VALIDATION_WEIGHT_MINIMUM' },
            minimumValue,
          )
        : undefined,
    [getMinMaxValidationMessage, minimumValue],
  );

  // Change Handlers
  const setValueAndValidate = useCallback(
    (newValue: string | number | null) =>
      setValue(componentId, newValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }),
    [componentId, setValue],
  );

  const onInputChange = useCallback(() => {
    const nextValue =
      selectedUnit === WeightUnits.pounds
        ? getValueAsOutputUnit.totalPounds()
        : getValueAsOutputUnit.totalKilograms();

    setValueAndValidate(nextValue);
  }, [getValueAsOutputUnit, selectedUnit, setValueAndValidate]);

  const refs = { poundsInputRef, kilogramsInputRef, fieldRef };
  const validateMethods = { validateMinValue, validateMaxValue };
  const valueHandlers = { onInputChange, getUnitValues, syncUnitInputs };
  return { valueHandlers, validateMethods, refs };
};
