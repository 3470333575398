import { useThemeMediaQueries } from './use-theme-media-queries';
import type { GDSMediaQueryItems } from './types';
import type { GDSBreakpoint } from '../types';

type GDSActiveBreakpoint = GDSBreakpoint | null;

export const useActiveBreakpoint = (media: {
  [key in GDSBreakpoint]: string;
}): GDSActiveBreakpoint => {
  const queries = useThemeMediaQueries(media) as GDSMediaQueryItems;
  const activeBreakpointItem: GDSActiveBreakpoint[] = Object.entries(
    queries,
  ).find(([, query]) => query.active) as unknown as GDSActiveBreakpoint[];

  if (activeBreakpointItem) {
    const [activeBreakpoint] = activeBreakpointItem;
    return activeBreakpoint;
  }

  return null;
};
