import { css } from '@leagueplatform/genesis-commons';

const transitionTempo = '0.2s ease-out';
export const plusMinusDisabledStyles = ({ disabled, theme: { colors } }) =>
  css({
    path: {
      fill: disabled
        ? `${colors.onSurface.text.subdued}`
        : `${colors.primary.text.default}`,
      transition: `fill ${transitionTempo}`,
    },
  });
