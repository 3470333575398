/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, MouseEvent, MouseEventHandler, useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BiometricAttributes,
  BiometricStatusType,
} from '@leagueplatform/health-journey-api';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
import {
  StackLayout,
  HeadingText,
  ParagraphText,
  ImageMessage,
  UtilityText,
  Icon,
  Box,
} from '@leagueplatform/genesis-core';
import { FormatDateOptions } from 'react-intl';
import { normalizeDateToMidnight } from 'pages/health-activity/common/format-date.util';
import { useToolboxLink } from 'pages/health-activity/hooks/use-toolbox-link.hook';
import { ComponentTypes } from '@leagueplatform/health-journey-common';
import { useToolboxComponentContext } from 'pages/health-activity/contexts/toolbox-component-provider.component';

// Types
export namespace ToolboxBiometric {
  type ClickHandler = (
    event: MouseEvent<HTMLAnchorElement>,
    actionAttributes: { url?: string; title?: string },
  ) => void;

  export interface Actions {
    onLinkOut?: ClickHandler;
    onDeepLink?: ClickHandler;
  }

  export interface Props extends ToolboxComponentProps, BiometricAttributes {}
}

const dateFormatOptions: FormatDateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  timeZone: 'UTC',
};

const BiometricStatus = ({
  status,
  statusText,
}: {
  status: BiometricStatusType | undefined;
  statusText: string;
}) => {
  switch (status) {
    case BiometricStatusType.inRange:
      return (
        <HeadingText
          size="md"
          level="4"
          css={{ paddingTop: '$one', color: '$onSurfaceTextSuccess' }}
        >
          {statusText}
        </HeadingText>
      );
    case BiometricStatusType.outOfRange:
      return (
        <HeadingText
          size="md"
          level="4"
          css={{ paddingTop: '$one', color: '$onSurfaceTextCritical' }}
        >
          {statusText}
        </HeadingText>
      );
    case BiometricStatusType.inconclusive:
    case BiometricStatusType.noDataAvailable:
      return (
        <ParagraphText css={{ paddingTop: '$one' }}>{statusText}</ParagraphText>
      );
    default:
      return null;
  }
};

export const ActivityToolboxBiometric: FC<ToolboxBiometric.Props> = ({
  iconUrl = '',
  name,
  value,
  unit,
  collectedOn,
  status,
  statusText,
  ctaUrl = '',
  ctaText,
  css,
  componentId,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const { actions } = useToolboxComponentContext(ComponentTypes.Biometric);
  const { isLinkOut, linkProps } = useToolboxLink(ctaUrl);

  const linkCta = linkProps && ctaUrl?.trim();
  const collectedOnDate = useMemo(
    () =>
      collectedOn &&
      formatMessage(
        { id: 'DATA_COLLECTED' },
        {
          date: formatDate(
            normalizeDateToMidnight(collectedOn),
            dateFormatOptions,
          ),
        },
      ),
    [collectedOn, formatDate, formatMessage],
  );

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (!linkCta) return;
    const actionAttributes = { url: linkCta, title: ctaText };
    if (isLinkOut) {
      actions?.onLinkOut?.(event, actionAttributes);
    } else {
      actions?.onDeepLink?.(event, actionAttributes);
    }
  };

  return (
    <Box css={css}>
      <StackLayout
        {...linkProps}
        as={linkCta ? 'a' : undefined}
        onClick={handleLinkClick}
        css={{
          borderRadius: '$large',
          borderStyle: 'solid',
          borderColor: '$onSurfaceBorderSubdued',
          borderWidth: '$thin',
          padding: '$one $oneAndHalf',
          textDecoration: 'none',
          '&:hover': {
            borderColor: linkCta
              ? '$interactiveBorderHovered'
              : '$onSurfaceBorderSubdued',
          },
        }}
        data-testid={componentId}
        horizontalAlignment="center"
        orientation="vertical"
      >
        <ImageMessage
          imageAlt=""
          imageGap="$none"
          imageHeight={36}
          imageWidth={36}
          css={{
            borderRadius: '$circle',
          }}
          image={iconUrl}
        >
          <HeadingText size="md" level="4" as="h3" css={{ paddingTop: '$one' }}>
            {name}
          </HeadingText>
        </ImageMessage>
        {statusText && (
          <BiometricStatus status={status} statusText={statusText} />
        )}
        {value && (
          <ParagraphText>
            {value} {unit}
          </ParagraphText>
        )}
        {collectedOnDate && (
          <UtilityText
            emphasis="subtle"
            size="xs"
            css={{ paddingTop: '$half' }}
          >
            {collectedOnDate}
          </UtilityText>
        )}

        {ctaUrl && linkProps && (
          <StackLayout
            horizontalAlignment="center"
            orientation="horizontal"
            css={{ gap: '$quarter', paddingTop: '$one' }}
          >
            <ParagraphText
              as="p"
              size="xs"
              css={{
                textTransform: 'uppercase',
                fontWeight: '700',
                color: '$interactiveActionSubdued',
              }}
            >
              {ctaText}
            </ParagraphText>
            <Icon
              icon="tinyExternalLink"
              size="16px"
              tint="$onSurfaceIconPrimary"
            />
          </StackLayout>
        )}
      </StackLayout>
    </Box>
  );
};
