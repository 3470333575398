// Static Assets
import alarmClock from 'assets/clock-alarm.svg';
import alertUser from 'assets/alert-user.png';
import alternativeSuccessStatus from 'assets/checkmark-circle-icon-alternative.svg';
import arrowLeft from 'assets/arrow-right-dark.svg';
import arrowRight from 'assets/arrow-right.svg';
import calendar from 'assets/calendar.svg';
import challengeToken from 'assets/challenge-token.svg';
import challengeTokenSubdued from 'assets/challenge-token-subdued.svg';
import heartRateUp from 'assets/monitor-heart-rate-up.png';
import highPriorityIcon from 'assets/high-priority-icon.svg';
import leagueLogo from 'assets/league-logo.svg';
import leagueLogoSubdued from 'assets/league-logo-subdued.svg';
import requiredIcon from 'assets/requiredIcon.svg';
import completeByIcon from 'assets/completeByIcon.svg';
import rewardsPointsIcon from 'assets/rewardPointsIcon.svg';
import rewardsProgramIcon from 'assets/rewardsProgramIcon.svg';
import activityPointsIcon from 'assets/activityPointsIcon.svg';

// TODO – JRNY – Update tokens to reflect the source-of-truth when they become available
import emptyStateAchievements from 'assets/trophyIcon.png';
import emptyStatePrograms from 'assets/empty-state-programs.svg';
import emptyStateRest from 'assets/empty-state-rest.svg';
import emptyStateProgress from 'assets/emptyStateHJ.png';
import emptyStateHistory from 'assets/emptyStateHistory.png';
import highFive from 'assets/high-five-hands.svg';
import journeyHeaderBackgroundImage from 'assets/journey-header-background-image.png';
import waveGoodbye from 'assets/leave-activity.svg';
import warningIcon from 'assets/warning-icon-orange.svg';

// Asset Components
import { MinusIcon } from '../components/minus-icon';
import { PlusIcon } from '../components/plus-icon';

// Constants
import { HEALTH_JOURNEY_ASSET_KEYS } from '../types/health-journey-asset-keys.types';

// Asset Keys
const {
  ALARM_CLOCK,
  ARROW_LEFT,
  ARROW_RIGHT,
  CALENDAR,
  CHALLENGE_TOKEN,
  CHALLENGE_TOKEN_SUBDUED,
  HEART_RATE_UP,
  HIGH_PRIORITY_ICON,
  JOURNEY_HEADER_BACKGROUND_IMAGE,
  LEAGUE_LOGO,
  LEAGUE_LOGO_SUBDUED,
  MINUS,
  MOBILE_ALERT_USER,
  PLUS,
  SUCCESS_STATUS_ALTERNATIVE,
  REQUIRED_ICON,
  COMPLETE_BY_ICON,
  REWARDS_POINTS_ICON,
  REWARDS_PROGRAM_ICON,
  ACTIVITY_POINTS_ICON,

  // TODO – JRNY – Update tokens to reflect the source-of-truth when they become available
  EMPTY_STATE_ACHIEVEMENTS,
  EMPTY_STATE_PROGRAMS,
  EMPTY_STATE_PROGRESS,
  EMPTY_STATE_REST,
  EMPTY_STATE_HISTORY,
  HIGH_FIVE,
  WARNING_ICON,
  WAVE_GOODBYE,
} = HEALTH_JOURNEY_ASSET_KEYS;

export const HEALTH_JOURNEY_ASSET_MAP = {
  [ALARM_CLOCK]: alarmClock,
  [ARROW_LEFT]: arrowLeft,
  [ARROW_RIGHT]: arrowRight,
  [CALENDAR]: calendar,
  [CHALLENGE_TOKEN]: challengeToken,
  [CHALLENGE_TOKEN_SUBDUED]: challengeTokenSubdued,
  [HEART_RATE_UP]: heartRateUp,
  [HIGH_PRIORITY_ICON]: highPriorityIcon,
  [LEAGUE_LOGO]: leagueLogo,
  [LEAGUE_LOGO_SUBDUED]: leagueLogoSubdued,
  [MINUS]: MinusIcon,
  [MOBILE_ALERT_USER]: alertUser,
  [PLUS]: PlusIcon,
  [SUCCESS_STATUS_ALTERNATIVE]: alternativeSuccessStatus,
  [REQUIRED_ICON]: requiredIcon,
  [COMPLETE_BY_ICON]: completeByIcon,
  [REWARDS_POINTS_ICON]: rewardsPointsIcon,
  [REWARDS_PROGRAM_ICON]: rewardsProgramIcon,
  [ACTIVITY_POINTS_ICON]: activityPointsIcon,

  // TODO – JRNY – Update tokens to reflect the source-of-truth when they become available
  [EMPTY_STATE_ACHIEVEMENTS]: emptyStateAchievements,
  [EMPTY_STATE_PROGRAMS]: emptyStatePrograms,
  [EMPTY_STATE_PROGRESS]: emptyStateProgress,
  [EMPTY_STATE_REST]: emptyStateRest,
  [EMPTY_STATE_HISTORY]: emptyStateHistory,
  [HIGH_FIVE]: highFive,
  [JOURNEY_HEADER_BACKGROUND_IMAGE]: journeyHeaderBackgroundImage,
  [WARNING_ICON]: warningIcon,
  [WAVE_GOODBYE]: waveGoodbye,
};
