import React from 'react';
import {
  Box,
  StackLayout,
  StackItem,
  HeadingText,
  UtilityText,
  ParagraphText,
  useClickableCard,
  GDSColor,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  BadgeList,
  BadgeListProps,
} from 'common/badge-list/badge-list.component';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export type MasonrySmallCardNodeProperties = {
  image: string;
  overline?: string;
  title: string;
  description: string;
  imageAltText: string;
  badges?: BadgeListProps['badges'];
  isChildItem?: boolean;
  badgeBackgroundColor?: GDSColor;
};

type MasonrySmallCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonrySmallCardNode = MasonryEngineNode<
  WidgetType.SMALL_VISUAL_CARD,
  MasonrySmallCardNodeProperties,
  MasonrySmallCardNodeActions
>;

export type MasonrySmallCardRendererProps =
  MasonryEngineNodeRendererProps<MasonrySmallCardNode>;

export const MasonrySmallCardRenderer = ({
  overline,
  title,
  description,
  image,
  badges,
  imageAltText,
  isChildItem = false,
  badgeBackgroundColor,
  onClick,
}: MasonrySmallCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();
  const overlineId = `svc-${title.replace(/\s+/g, '-')}`;

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(onClick?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
    >
      <StackLayout
        orientation={{
          '@initial': 'horizontal',
          '@mobile': 'vertical',
          '@mobileLandscape': 'horizontal',
        }}
        verticalAlignment="center"
        horizontalAlignment="spaceBetween"
        spacing="$threeQuarters"
        css={{
          padding: '$oneAndHalf',
        }}
      >
        <StackItem verticalAlignment="center" css={{ flex: '2' }}>
          <StackItem>
            {overline && (
              <UtilityText id={overlineId} size="eyebrow" as="p">
                {overline}
              </UtilityText>
            )}
            <StackLayout css={{ marginTop: '$quarter' }}>
              <HeadingText level="3" size="sm" aria-describedby={overlineId}>
                <ConditionalLinkRenderer
                  ref={primaryActionRef}
                  url={onClick?.payload.url}
                  onClick={(event: React.MouseEvent) => {
                    if (onClick) {
                      invokeAction({
                        event,
                        ...onClick,
                      });
                    }
                  }}
                >
                  {title}
                </ConditionalLinkRenderer>
              </HeadingText>
              <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
                {description}
              </ParagraphText>
            </StackLayout>
          </StackItem>
          {badges && (
            <Box css={{ marginTop: '$threeQuarters' }}>
              <BadgeList
                badges={badges}
                backgroundColor={badgeBackgroundColor}
              />
            </Box>
          )}
        </StackItem>
        <StackItem>
          <SquareImageContainer
            src={image}
            alt={imageAltText}
            maxWidth="9.8rem"
            maxWidthDesktop={isChildItem ? '9.8rem' : '11.2rem'}
          />
        </StackItem>
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
