import React from 'react';
import { Link, LocationDescriptor } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/rewards-achievements';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_MONTH_FORMAT } from '@leagueplatform/web-common';
import {
  Flex,
  Box,
  BodyTwo,
  Caption,
  HeadingFour,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import {
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  CONFIGURABLE_ACTIVITY_PRIORITY,
} from '@leagueplatform/health-journey-common';

import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { configureActivityCardProgress } from 'utils/configure-activity-card-progress.util';
import { ChallengeTokenIcon, PointsIcon } from '@leagueplatform/rewards-common';
import { Badge } from '@leagueplatform/web-common-components';
import { ActivityCardPriorityBanner } from './activity-card-priority-banner.component';
import { ActivityCardImage } from './activity-card-image.component';
import { ActivityProgress } from './activity-progress.component';
import { EarnedOrMissedRewardCaption } from './earned-or-missed-reward-caption.component';

import {
  getActivityDisplayValues,
  getInactiveActivityStatusBooleans,
  getContentContainerWidth,
  getIsActivityForChallenge,
  getReward,
} from './utils/get-configurable-activity-card-details.utils';
import { selectActivityAnalytics } from './utils/select-activity-analytics.util';
import { ActivityCompletedBadge } from '../activity-completed-badge/activity-completed-badge';

export interface ProgressProps {
  unit: string;
  goal: number;
  progress: number;
  status: string;
  isFrontOfLine: boolean;
}
interface ActivityCardContentProps {
  activity: ConfigurableHealthActivityWSCC;
  activityDetailsPath: LocationDescriptor;
  activityHeadingLevel: string;
}
const StyledLink = genesisStyled(HeadingFour)(
  css({
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },

    '&:focus': {
      outline: 'none',
    },
  }),
);

const { SIMPLE, STANDARD, DETAILED } = CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;

// TODO: break down into smaller sub-components
export const ActivityCardContent = ({
  activity,
  activityDetailsPath,
  activityHeadingLevel,
}: ActivityCardContentProps) => {
  const { formatMessage, formatDate } = useIntl();
  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  const { LOW } = CONFIGURABLE_ACTIVITY_PRIORITY;
  const {
    activityImage,
    shortDescription: description,
    detailLevel: detail,
    id: activityId,
    name,
    priority,
    tagline,
    canExpire,
    endDate,
  } = activity;
  const { isCompleted, isMissed, isCompletedOrMissed } =
    getInactiveActivityStatusBooleans(activity);
  const isChallenge = getIsActivityForChallenge(activity);
  const reward = getReward(activity);
  const { isProgressActivity, progressProps } =
    configureActivityCardProgress(activity);
  const { progress, goal, unit } = progressProps;
  const {
    displayActivityDescription,
    displayActivityProgress,
    displayEarnedOrMissedReward,
    displayFooter,
    displayMobileStandardActivityImage,
    displayPointsReward,
    displayChallengeReward,
    activityCardPriorityBannerDisplayValue,
  } = getActivityDisplayValues(activity, isAchievementsEnabled);

  const expiryDate = canExpire ? endDate : '';
  const formattedExpiryDate = formatDate(expiryDate, SHORT_MONTH_FORMAT);

  const isAdditionalTopPadding = priority === LOW && !isCompletedOrMissed;
  const isContentFullWidth =
    detail === STANDARD && !isProgressActivity && !isCompletedOrMissed;
  return (
    <Box
      width={{ _: '100%', tablet: getContentContainerWidth(activity) }}
      order="2"
    >
      {/* For mobile detailed composition cards' priority banner layout */}
      {detail === DETAILED && (
        <ActivityCardPriorityBanner
          activityId={activityId}
          priority={priority}
          detail={detail}
          tagline={tagline}
          hasImage={!!activityImage}
          display={{
            _: activityCardPriorityBannerDisplayValue,
            tablet: 'none',
          }}
          isProgressActivity={isProgressActivity}
        />
      )}
      <Flex
        flexDirection="column"
        height="100%"
        paddingX="oneAndHalf"
        paddingTop={{
          _: 'oneAndHalf',
          tablet: isAdditionalTopPadding ? 'oneAndHalf' : 'one',
        }}
        paddingBottom="oneAndHalf"
        order={detail === STANDARD ? 1 : 2}
        justifyContent={detail === SIMPLE ? 'normal' : 'space-between'}
      >
        {isCompleted && (
          <Flex
            alignSelf="flex-end"
            marginTop="minusHalf"
            marginRight="minusOne"
          >
            <ActivityCompletedBadge />
          </Flex>
        )}
        <Flex>
          <Box
            width={{
              _: !isContentFullWidth ? '70%' : '100%',
              tablet: '100%',
            }}
          >
            {/* This heading can be anything from an h2 to an h5 depending on the context in which it is used */}
            <HeadingFour as={activityHeadingLevel}>
              <StyledLink
                as={Link}
                to={activityDetailsPath}
                onClick={() => {
                  selectActivityAnalytics(activity);
                }}
                aria-describedby={activityId}
                color={
                  isMissed ? 'onSurface.text.subdued' : 'onSurface.text.primary'
                }
                textDecoration="none"
              >
                {name}
              </StyledLink>
            </HeadingFour>

            {displayActivityDescription && (
              <BodyTwo color="onSurface.text.subdued" marginTop="half">
                {description}
              </BodyTwo>
            )}

            {displayActivityProgress && (
              <ActivityProgress progress={progress} goal={goal} unit={unit} />
            )}

            {displayEarnedOrMissedReward && reward && (
              <EarnedOrMissedRewardCaption
                isCompleted={isCompleted}
                isChallenge={isChallenge}
                reward={reward}
              />
            )}
          </Box>
          {/* For mobile standard composition cards' image layout */}
          {activityImage && displayMobileStandardActivityImage && (
            <Box
              display={{ _: 'block', tablet: 'none' }}
              width="30%"
              marginLeft="one"
            >
              <ActivityCardImage
                priority={priority}
                detail={detail}
                activityImage={activityImage}
                displayMobileStandardComposition="block"
              />
            </Box>
          )}
        </Flex>

        {displayFooter && (
          <Box as="footer" marginTop="quarter">
            {displayPointsReward && reward && (
              <Badge css={{ marginTop: '$one' }}>
                <PointsIcon value={reward} />
              </Badge>
            )}
            {displayChallengeReward && reward && (
              <Badge
                css={{
                  backgroundColor: '$surfaceBackgroundSecondary',
                  marginTop: '$one',
                }}
              >
                <ChallengeTokenIcon value={reward} />
              </Badge>
            )}
            {expiryDate && (
              <Caption
                color="onSurface.text.subdued"
                /*
                  Due to a Genesis bug, responsive object for padding and margin in conjunction with flex doesn't work properly. Instead, for responsive styles to work, we need to pass an array, with each item in the array corresponding to a breakpoint in the order that they occur:
                    index: breakpoint
                    0: base
                    1: phone
                    2: tablet
                    3: laptop
                    4: desktop
                */
                paddingTop={['one', 'one', 'half']}
                marginTop={['one', 'one', 'half']}
                borderTopWidth="thin"
              >
                {formatMessage(
                  { id: 'ACTIVITY_EXPIRY_DATE' },
                  { date: formattedExpiryDate },
                )}
              </Caption>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
};
