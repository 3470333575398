import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { Image } from '@leagueplatform/genesis-commons';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { REWARDS_COMMON_ASSET_KEYS } from '../../asset-map';

export interface PointsIconProps {
  subdued?: boolean;
  size?: string;
  altText?: string;
  value: number;
}

export const PointsIcon = ({
  subdued,
  size = 'threeQuarters',
  altText,
  value,
}: PointsIconProps) => {
  const pointsIcon = handleStaticAsset(
    subdued
      ? REWARDS_COMMON_ASSET_KEYS.REWARDS_POINTS_SUBDUED
      : REWARDS_COMMON_ASSET_KEYS.REWARDS_POINTS,
  ) as string;

  const { formatMessage, formatNumber } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      spacing="$quarter"
      verticalAlignment="center"
    >
      <Image
        size={size}
        src={pointsIcon}
        alt={altText || formatMessage({ id: 'X_POINTS' }, { points: value })}
      />

      <UtilityText
        css={{
          color: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
        }}
        aria-hidden
      >
        {formatNumber(value)}
      </UtilityText>
    </StackLayout>
  );
};
