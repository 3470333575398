/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Badge } from '@leagueplatform/web-common-components';
import {
  BadgeAttributes,
  StyleBackgroundColorType,
  StyleBorderRadiusType,
  StyleHorizontalAlignmentType,
} from '@leagueplatform/health-journey-api';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useGetDynamicComponentStyles } from '../../hooks/use-get-dynamic-component-styles.component';

// Types
export interface ActivityToolboxBadgeProps
  extends ToolboxComponentProps,
    BadgeAttributes {}

export const ActivityToolboxBadge: FC<ActivityToolboxBadgeProps> = ({
  styleModifiers,
  text,
  iconUrl,
  altText,
  css,
  componentId,
}) => {
  const defaultStyleModifier = {
    borderRadius: StyleBorderRadiusType.medium,
    horizontalAlignment: StyleHorizontalAlignmentType.center,
    backgroundColor: StyleBackgroundColorType.decorativeBrandPrimaryPastel,
  };
  const { borderRadiusStyle, horizontalAlignmentStyle, backgroundColorStyle } =
    useGetDynamicComponentStyles(styleModifiers, defaultStyleModifier);

  return (
    <StackLayout
      css={{ flex: 'initial', ...horizontalAlignmentStyle, ...css }}
      data-testid={componentId}
      orientation="horizontal"
    >
      <Badge css={{ ...borderRadiusStyle, ...backgroundColorStyle }}>
        {iconUrl && <img width="12" src={iconUrl} alt={altText} />}
        <UtilityText size="xs" emphasis="base">
          {text}
        </UtilityText>
      </Badge>
    </StackLayout>
  );
};
