import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Overline, Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { noop } from 'lodash';
import { sanitizeValue as _sanitizeValue } from '../currency-input/currency-input.view';
import { TextInput } from '@leagueplatform/genesis-core';

const getLocalizedDecimalSeparator = (locale) =>
  (1.1).toLocaleString(locale).replace(/1/g, '');

/**
 * @function formatCurrency - This function takes in a value, and will format it
 * such that it fits the format of the browser's locale.
 *
 * @param {*} currency
 * @param {*} value The value that will be formatted. can be string or float
 *
 * @returns {(string)} the formatted currency string
 */

export const useFormatCurrency = () => {
  const { formatMoney, locale } = useIntl();
  const decimalSeparator = getLocalizedDecimalSeparator(locale);

  const sanitizeValue = (value) => _sanitizeValue(value, decimalSeparator);

  const formatCurrency = (currency, value) => {
    const formattedValue =
      typeof value === 'string' ? sanitizeValue(value) : value;
    return formattedValue || formattedValue === 0
      ? formatMoney(
          { F: formattedValue, C: currency },
          { minimumFractionDigits: 2 },
        )
      : '';
  };

  return {
    formatCurrency,
    sanitizeValue,
  };
};

const PERIOD_TYPE = 'period-type';

export const CurrencyInputV2 = ({
  name,
  id = name,
  currency,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  onSanitize,
  onSubmit,
  error,
  disabled,
  value,
  periodType,
  isRequired,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { formatCurrency, sanitizeValue } = useFormatCurrency();
  const [sanitizedValue, setSanitizedValue] = useState(value);
  const [formattedValue, setFormattedValue] = useState(
    value ? formatCurrency(currency, value) : '',
  );

  /* Create a placeholder and store it to avoid rebuilding each render */
  const placeholder = useMemo(
    () => formatCurrency(currency, 0),
    [currency, formatCurrency],
  );

  useEffect(() => {
    onSanitize(sanitizedValue ?? 0);
  }, [sanitizedValue, onSanitize]);

  return (
    <Box
      position="relative"
      {...props}
      minWidth={
        periodType
          ? {
              _: '230px',
              laptop: 'none',
              desktop: '230px',
            }
          : null
      }
    >
      <TextInput
        name={name}
        id={id}
        disabled={disabled}
        error={error}
        onChange={(e) => {
          e.persist();
          const newValue = e.target.value;
          const sanitized = sanitizeValue(newValue);
          if (sanitized !== sanitizedValue) {
            setSanitizedValue(sanitized);
          }
          setFormattedValue(newValue);
          onChange(e);
        }}
        onKeyUp={(e) => {
          e.persist();
          if (e.key === 'Enter') {
            onSubmit();
          } else {
            onKeyUp(e);
          }
        }}
        onBlur={(e) => {
          e.persist();
          const newValue = e.target.value;
          const sanitized = sanitizeValue(newValue);
          setFormattedValue(formatCurrency(currency, sanitized));
          onBlur(e);
        }}
        onFocus={(e) => {
          e.persist();
          onFocus(e);
        }}
        value={formattedValue}
        placeholder={formattedValue ? undefined : placeholder}
        aria-labelledby={periodType ? `${id}-${PERIOD_TYPE}` : undefined}
        required={isRequired || undefined}
        inputStatus={error ? 'error' : ''}
      />
      {periodType && (
        <Flex
          backgroundColor="surface.background.secondary"
          alignItems="center"
          paddingX="threeQuarters"
          position="absolute"
          right={1}
          top={1}
          bottom={1}
          margin={1}
          borderRadius="small"
          borderTopLeftRadius="unset"
          borderBottomLeftRadius="unset"
        >
          <Overline
            color="onSurface.text.subdued"
            as="p"
            id={`${id}-${PERIOD_TYPE}`}
          >
            {formatMessage({ id: periodType })}
          </Overline>
        </Flex>
      )}
    </Box>
  );
};

CurrencyInputV2.defaultProps = {
  value: '',
  onChange: noop,
  onKeyUp: noop,
  onSubmit: noop,
  onBlur: noop,
  onFocus: noop,
  onSanitize: noop,
  error: null,
  id: undefined, // this will be set to name if undefined
  periodType: undefined,
  disabled: false,
  isRequired: false,
};

CurrencyInputV2.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onSanitize: PropTypes.func,
  onSubmit: PropTypes.func,
  currency: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  periodType: PropTypes.string,
  isRequired: PropTypes.bool,
};
