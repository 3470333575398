import React, { FC } from 'react';
import { SkeletonBase } from './skeleton-base';
import type { GDSSize } from '../../theme';
import type { GDSResponsiveProp } from '../../types';
import type { GDSSkeletonBaseProps } from './skeleton-base';

export interface GDSSkeletonCircleProps
  extends Omit<GDSSkeletonBaseProps, 'height' | 'borderRadius' | 'width'> {
  size?: GDSResponsiveProp<number | string | GDSSize>;
}

export const SkeletonCircle: FC<GDSSkeletonCircleProps> = ({
  size = '100%',
  css,
  className,
  ...props
}) => (
  <SkeletonBase
    className={['GDS-skeleton-circle', className].join(' ')}
    height={size}
    width={size}
    borderRadius="$circle"
    css={{
      ...css,
    }}
    {...props}
  />
);

SkeletonCircle.displayName = 'SkeletonCircle';
