/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Fieldset,
  HeadingText,
  InputLabel,
  ParagraphText,
  TextInput,
  Select,
  InputStatusMessage,
  styled,
  StackLayout,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  PROVINCE_STATE_OPTIONS,
  useFormContext,
} from '@leagueplatform/web-common';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { ADDRESS_INPUT_IDS } from '../constants/address-input-ids';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';
import { validateIsValidCharacters } from '../utils/validate-valid-characters.util';

const NEW_ADDRESS_HINT_ID = 'new-address-hint';
const CITY_VALIDATION_REGEX = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
const ZIP_CODE_VALIDATION_REGEX = /^[0-9]{5}$/;

const transformedStateOptions = PROVINCE_STATE_OPTIONS.US.map(
  ({ value, text }) => ({
    label: text,
    value,
  }),
);

const StyledInputLabel = styled(InputLabel, {
  marginBottom: '$half',
});
const { addressLine1, addressLine2, city, postal, state } =
  orderNewCardFormInputNames;

export const NewAddressStep = () => {
  const { formatMessage } = useIntl();
  const { register, formState } = useFormContext();

  useWalletDetailScreenLoaded(
    BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_NEW_ADDRESS_STEP,
  );

  const requiredFieldError = (fieldLabelId: string) =>
    formatMessage(
      { id: 'REQUIRED_FIELD' },
      {
        field: formatMessage({
          id: fieldLabelId,
        }),
      },
    );

  // Component Errors
  const addressLine1Error = formState.errors[addressLine1]?.message;
  const addressLine1ErrorId = `${ADDRESS_INPUT_IDS.ADDRESS_LINE_1}-errorMessage`;
  const addressLine2Error = formState.errors[addressLine2]?.message;
  const addressLine2ErrorId = `${ADDRESS_INPUT_IDS.ADDRESS_LINE_2}-errorMessage`;
  const cityError = formState.errors[city]?.message;
  const cityErrorId = `${ADDRESS_INPUT_IDS.CITY}-errorMessage`;
  const stateError = formState.errors[state]?.message;
  const stateErrorId = `${ADDRESS_INPUT_IDS.STATE}-errorMessage`;
  const postalError = formState.errors[postal]?.message;
  const postalErrorId = `${ADDRESS_INPUT_IDS.POSTAL}-errorMessage`;
  const validate = {
    isValidCharacters: (value: string) =>
      validateIsValidCharacters(value) ||
      formatMessage({ id: 'WALLET_INVALID_CHARACTER_ERROR_MESSAGE' }),
  };

  return (
    <Fieldset
      id={NEW_ADDRESS_HINT_ID}
      css={{
        '.GDS-fieldset-legend': {
          display: 'block',
        },
      }}
      legend={
        <HeadingText size="lg" level="3">
          {formatMessage({ id: 'CHOOSE_NEW_ADDRESS' })}
        </HeadingText>
      }
      hint={
        <ParagraphText>
          {formatMessage({ id: 'CHOOSE_NEW_ADDRESS_DESC' })}
        </ParagraphText>
      }
    >
      <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
        <Box>
          <StyledInputLabel htmlFor={ADDRESS_INPUT_IDS.ADDRESS_LINE_1} required>
            {formatMessage({ id: 'ADDRESS_LINE_1' })}
          </StyledInputLabel>
          <TextInput
            id={ADDRESS_INPUT_IDS.ADDRESS_LINE_1}
            maxLength={55}
            autoComplete="address-line1"
            inputStatus={addressLine1Error ? 'error' : undefined}
            aria-describedby={
              addressLine1Error ? addressLine1ErrorId : undefined
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register(addressLine1, {
              required: requiredFieldError('ADDRESS_LINE_1'),
              validate,
            })}
          />
          {addressLine1Error && (
            <InputStatusMessage inputStatus="error" id={addressLine1ErrorId}>
              {addressLine1Error}
            </InputStatusMessage>
          )}
        </Box>

        <Box>
          <StyledInputLabel htmlFor={ADDRESS_INPUT_IDS.ADDRESS_LINE_2}>
            {formatMessage({ id: 'ADDRESS_LINE_2' })}
          </StyledInputLabel>
          <TextInput
            id={ADDRESS_INPUT_IDS.ADDRESS_LINE_2}
            maxLength={55}
            autoComplete="address-line2"
            inputStatus={addressLine2Error ? 'error' : undefined}
            aria-describedby={
              addressLine2Error ? addressLine2ErrorId : undefined
            }
            {...register(orderNewCardFormInputNames.addressLine2, {
              maxLength: {
                value: 55,
                message: formatMessage(
                  { id: 'UNIQUE_IDENTIFIER_MAX_LENGTH_ERROR' },
                  { length: 55 },
                ),
              },
              validate,
            })}
          />
          {addressLine2Error && (
            <InputStatusMessage inputStatus="error" id={addressLine2ErrorId}>
              {addressLine2Error}
            </InputStatusMessage>
          )}
        </Box>

        <Box>
          <StyledInputLabel htmlFor={ADDRESS_INPUT_IDS.CITY} required>
            {formatMessage({ id: 'CITY' })}
          </StyledInputLabel>
          <TextInput
            id={ADDRESS_INPUT_IDS.CITY}
            maxLength={30}
            autoComplete="address-level2"
            aria-describedby={cityError ? cityErrorId : undefined}
            inputStatus={cityError ? 'error' : undefined}
            {...register(city, {
              required: requiredFieldError('CITY'),
              maxLength: 30,
              pattern: {
                value: CITY_VALIDATION_REGEX,
                message: formatMessage({
                  id: 'WALLET_ORDER_NEW_CARD_NO_CITY_NUMBERS_ERROR',
                }),
              },
              validate,
            })}
          />
          {cityError && (
            <InputStatusMessage inputStatus="error" id={cityErrorId}>
              {cityError}
            </InputStatusMessage>
          )}
        </Box>

        <Box>
          <StyledInputLabel htmlFor={ADDRESS_INPUT_IDS.STATE} required>
            {formatMessage({ id: 'STATE' })}
          </StyledInputLabel>
          <Select
            id={ADDRESS_INPUT_IDS.STATE}
            options={transformedStateOptions}
            placeholder={formatMessage({ id: 'SELECT_STATE' })}
            autoComplete="address-level1"
            aria-describedby={stateError ? stateErrorId : undefined}
            inputStatus={stateError ? 'error' : undefined}
            {...register(state, {
              required: requiredFieldError('STATE'),
              validate,
            })}
          />
          {stateError && (
            <InputStatusMessage inputStatus="error" id={stateErrorId}>
              {stateError}
            </InputStatusMessage>
          )}
        </Box>

        <Box>
          <StyledInputLabel htmlFor={ADDRESS_INPUT_IDS.POSTAL} required>
            {formatMessage({ id: 'ZIP_CODE' })}
          </StyledInputLabel>
          <TextInput
            id={ADDRESS_INPUT_IDS.POSTAL}
            autoComplete="postal-code"
            pattern="[0-9]*"
            maxLength={5}
            aria-describedby={postalError ? postalErrorId : undefined}
            inputStatus={postalError ? 'error' : undefined}
            {...register(postal, {
              required: requiredFieldError('ZIP_CODE'),
              maxLength: 5,
              pattern: {
                value: ZIP_CODE_VALIDATION_REGEX,
                message: formatMessage({ id: 'INVALID_ZIP_CODE_NO_FORMAT' }),
              },
              validate,
            })}
          />
          {postalError && (
            <InputStatusMessage inputStatus="error" id={postalErrorId}>
              {postalError}
            </InputStatusMessage>
          )}
        </Box>
      </StackLayout>
    </Fieldset>
  );
};
