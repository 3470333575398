import { useQuery } from 'react-query';
import {
  getUserHealthActivities,
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import camelcaseKeys from 'camelcase-keys';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { normalizeEpochDate } from '../utils/user-health-activities/user-health-activities.utils';

// TODO: Replace this with improved typing when completing:
// https://everlong.atlassian.net/browse/XPNC-2890
export type ActivityStatusOptions =
  | 'active'
  | 'completed'
  | 'expired'
  | 'removed'
  | 'upcoming';
interface UseActivitiesByActivityStatusArgs {
  activityStatuses: ActivityStatusOptions[];
  campaignId?: string;
}

const WS_ENDPOINT_VERSION_NUMBER = 3;

export interface GetUserHealthActivitiesV3Return {
  userHealthActivities: ConfigurableHealthActivityWSCC[];
}

export const useActivitiesByActivityStatus = ({
  activityStatuses,
  campaignId,
}: UseActivitiesByActivityStatusArgs) => {
  const normalizedActiveDateTimestamp = normalizeEpochDate(Date.now());
  return useQuery<GetUserHealthActivitiesV3Return, Error>(
    [
      GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
      normalizedActiveDateTimestamp,
      activityStatuses,
      campaignId,
    ],
    () =>
      getUserHealthActivities({
        date: normalizedActiveDateTimestamp / 1000,
        activityStatuses,
        campaignId,
        version: WS_ENDPOINT_VERSION_NUMBER,
      }),
    {
      enabled: !!activityStatuses.length,
      select: (data) => camelcaseKeys(data, { deep: true }),
    },
  );
};
