import { AdditionalDateOptionUnit } from '@leagueplatform/health-journey-api';

const { day, month, year, week, noAnswer } = AdditionalDateOptionUnit;

type DateParam = Date | string | number;
interface GetRelativeDateParams {
  date?: DateParam;
  value: number;
  unit: string;
}
type GetRelativeDate = ({ unit, value, date }: GetRelativeDateParams) => string;

type GetAbsoluteDateParams = Record<
  Exclude<AdditionalDateOptionUnit, 'no-answer' | 'week'>,
  string | number | undefined
>;
type GetAbsoluteDate = ({
  day,
  month,
  year,
}: GetAbsoluteDateParams) => string | typeof NaN;

/** Normalize a date such that it sets hours, minutes, seconds and milliseconds to midnight for that the date provided */
export const normalizeDateToMidnight = (initialDate: DateParam) => {
  // Create a new date object to get/set values
  const normalizedDate = new Date(initialDate);
  const timezoneOffsetMinutes = normalizedDate.getTimezoneOffset() || 0;

  // We use universal time to normalize any incoming timestamps, we use the timezone offset to avoid any date-shifts resulting from timezone differences
  normalizedDate.setUTCHours(0, timezoneOffsetMinutes, 0, 0);

  return normalizedDate;
};

/** Format a date object, string or timestamp to an ISO date-string */
export const formatDateToISOString = (initialDate: DateParam) =>
  new Date(initialDate).toISOString();

export const formatDateToParts = (date?: DateParam) => {
  if (!date) {
    return {
      day: -1,
      month: -1,
      year: -1,
    };
  }

  const normalizedDate = normalizeDateToMidnight(date);

  return {
    day: normalizedDate.getUTCDate(),
    month: normalizedDate.getUTCMonth(),
    year: normalizedDate.getUTCFullYear(),
  };
};

/**
 * Create a date given a day, month and year normalized to midnight of that day. [Invalid or corrupt dates are represented as `NaN`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Invalid_date#invalid_cases)
 * @returns An `ISO date-time string`, or `NaN` for invalid dates
 */
export const getAbsoluteDate: GetAbsoluteDate = ({
  year: selectedYear,
  month: selectedMonth,
  day: selectedDay,
}) => {
  if (selectedYear && selectedMonth && selectedDay) {
    const normalizedDate = normalizeDateToMidnight(
      new Date(
        Number(selectedYear),
        Number(selectedMonth),
        Number(selectedDay),
      ),
    );
    return formatDateToISOString(normalizedDate);
  }
  return NaN;
};

export const getRelativeDate: GetRelativeDate = ({
  unit,
  value,
  date = Date.now(),
}) => {
  const normalizedDate = normalizeDateToMidnight(date);
  const {
    day: dayValue,
    month: monthValue,
    year: yearValue,
  } = formatDateToParts(normalizedDate);

  switch (unit) {
    case day:
      normalizedDate.setUTCDate(dayValue + value);
      break;
    case week:
      normalizedDate.setUTCDate(dayValue + value * 7);
      break;
    case month:
      normalizedDate.setUTCMonth(monthValue + value);
      break;
    case year:
      normalizedDate.setUTCFullYear(yearValue + value);
      break;
    case noAnswer:
    default:
      return '';
  }

  return formatDateToISOString(normalizedDate);
};
