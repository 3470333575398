import { useMemo, useEffect } from 'react';
import { useFormContext } from '@leagueplatform/web-common';

type ShouldSwitchFocus = boolean;
interface UseToolboxErrorFocusProps {
  name: string;
  focusTarget?: HTMLElement | null | false;
  onValidationError?: () => ShouldSwitchFocus;
}

/**
 * Manage focus-targets for `react-hook-form` validation errors
 * @property name {string} – The registered input's name
 * @property focusTarget {HTMLElement} – The element to focus on error
 * @property onValidationError {function} – Custom focus handling – **return `true` to apply focus on the `focusTarget`**
 */
export const useToolboxErrorFocus = ({
  name,
  focusTarget,
  onValidationError,
}: UseToolboxErrorFocusProps) => {
  const { formState } = useFormContext<Record<string, unknown>>();
  const { isValidating, error } = useMemo(
    () => ({
      isValidating: formState.isValidating,
      error: formState.errors?.[name],
    }),
    [formState, name],
  );
  useEffect(() => {
    if (!isValidating && error) {
      const shouldSwitchFocus = onValidationError?.() ?? true;

      if (shouldSwitchFocus && focusTarget) {
        focusTarget.focus();
      } else if (error?.ref?.focus) {
        error.ref.focus();
      }
    }
  }, [name, focusTarget, isValidating, error, onValidationError]);
};
