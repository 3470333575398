import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

export interface ActivitiesByDateGrouping {
  date: string;
  activities: ConfigurableHealthActivityWSCC[];
}

/**
 * This is a utility function that takes an array of activities, and organizes them so that they are grouped by the date the activities are completed/missed
 * @param {string} statusType - A string matching an activity status of completed or missed
 * @param {ConfigurableHealthActivityWS[]}  activities - An array of activities
 * @returns {ActivitiesByDateGrouping[]} An array of ActivitiesByDateGrouping. Each grouping contains a date (completed or missed date) and an array of activities that were completed or missed on the specified date. The final array is sorted in reverse chronolgoical order.
 */
export const groupActivitiesByDate = (
  statusType: 'completed' | 'missed',
  activities: ConfigurableHealthActivityWSCC[],
) => {
  const groupedByDate = activities.reduce(
    (result: ActivitiesByDateGrouping[], activity) => {
      const date =
        statusType === 'completed'
          ? // Using non-null assertion operator here, as a completed_date must exist for completed activities
            new Date(activity.completedDate!).toDateString()
          : new Date(activity.endDate).toDateString();

      /*
        - If current date grouping does not yet exist in the array,
          create a new ActivitiesByDateGrouping and push to the array
        - Otherwise, find the correct date grouping, and
          push current activity to the activities array for that grouping
      */
      const existingDateGrouping = result.find(
        (grouping: ActivitiesByDateGrouping) => grouping.date === date,
      );

      if (!existingDateGrouping) {
        result.push({ date, activities: [activity] });
      } else {
        existingDateGrouping.activities.push(activity);
      }

      return result;
    },
    // Setting the initial value as an empty array
    [],
  );

  // Sort by reverse chronological order
  groupedByDate.sort(
    (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
  );
  return groupedByDate;
};
