import React, { createElement, useContext } from 'react';

// Types
import { HealthActivityComponent } from '@leagueplatform/health-journey-api';
import { ComponentTypes } from '@leagueplatform/health-journey-common';

// Components
import { UseFormProps } from '@leagueplatform/web-common';
import {
  ActivityToolboxBadge,
  ActivityToolboxBanner,
  ActivityToolboxBlockquote,
  ActivityToolboxFullPageHighlight,
  ActivityToolboxHeader,
  ActivityToolboxImage,
  ActivityToolboxLink,
  ActivityToolboxList,
  ActivityToolboxRichText,
  ActivityToolboxDecimalInput,
  ActivityToolboxIntegerInput,
  ActivityToolboxStringInput,
  ActivityToolboxMultipleSelectInput,
  ActivityToolboxDateInput,
  ActivityToolboxProgressTracking,
  ActivityToolboxBiometric,
  ActivityToolboxPersonHeightInput,
  ActivityToolboxPersonWeightInput,
  ActivityToolboxChallengeProgress,
  ActivityToolboxDateTimeInput,
} from './page-components';

// Styles
import { deriveComponentLayoutStyles } from './styles/activity-toolbox-component-layout.styles';
import { ToolboxComponentContext } from '../contexts/toolbox-component-provider.component';
import { ToolboxInputProvider } from '../contexts/toolbox-input-provider.component';
import { ToolboxOnFormRegister } from '../types/toolbox.types';

// Constants
const {
  Badge,
  Banner,
  Blockquote,
  FullPageHighlight,
  Header,
  Image,
  Link,
  List,
  RichText,
  DecimalInput,
  IntegerInput,
  StringInput,
  MultipleSelectInput,
  DateInput,
  ChallengeProgress,
  ProgressTracking,
  Biometric,
  PersonHeight,
  PersonWeight,
  DateTimeInput,
} = ComponentTypes;

export interface ToolboxComponentResolverProps {
  components?: HealthActivityComponent[];
  isVisible?: boolean;
  baseComponentId: string;
  onFormRegister?: ToolboxOnFormRegister;
  formConfig?: UseFormProps;
}

/**
 * The `ToolboxComponentResolver` receives an array of Toolbox component objects, mapping each to their respective UI element.
 * Each component object's `componentType` determines which React component to resolve to and will pass it's `componentAttributes` as props
 * @property {string} baseComponentId                - Used to generate unique component ids across different activities
 * @property {boolean} isVisible                     - Denotes whether or not the current components being resolved are visible to users or not
 * @property {HealthActivityComponent[]} components  - A list of component objects
 * @returns A list of React components wrapped in a `React.Fragment`
 */
export const ToolboxComponentResolver = ({
  components = [],
  isVisible = true,
  baseComponentId,
  onFormRegister,
  formConfig,
}: ToolboxComponentResolverProps) => {
  const { registerToolboxComponent } = useContext(ToolboxComponentContext);
  return (
    <ToolboxInputProvider
      onFormRegister={onFormRegister}
      formConfig={formConfig}
    >
      {components.map((component, componentIndex) => {
        const { componentType, componentAttributes } = component;

        const componentId = `${baseComponentId}-${componentIndex}`;

        registerToolboxComponent(componentId, component);

        const props = {
          ...componentAttributes,
          componentId,
          isVisible,
          css: deriveComponentLayoutStyles(componentType),
          key: componentId,
        };

        switch (componentType) {
          case Badge:
            return createElement(ActivityToolboxBadge, props);
          case Banner:
            return createElement(ActivityToolboxBanner, props);
          case Blockquote:
            return createElement(ActivityToolboxBlockquote, props);
          case FullPageHighlight:
            return createElement(ActivityToolboxFullPageHighlight, props);
          case Header:
            return createElement(ActivityToolboxHeader, props);
          case Image:
            return createElement(ActivityToolboxImage, props);
          case Link:
            return createElement(ActivityToolboxLink, props);
          case List:
            return createElement(ActivityToolboxList, props);
          case RichText:
            return createElement(ActivityToolboxRichText, props);
          case DecimalInput:
            return createElement(ActivityToolboxDecimalInput, props);
          case IntegerInput:
            return createElement(ActivityToolboxIntegerInput, props);
          case StringInput:
            return createElement(ActivityToolboxStringInput, props);
          case MultipleSelectInput:
            return createElement(ActivityToolboxMultipleSelectInput, props);
          case DateInput:
            return createElement(ActivityToolboxDateInput, props);
          case ProgressTracking:
            return createElement(ActivityToolboxProgressTracking, props);
          case Biometric:
            return createElement(ActivityToolboxBiometric, props);
          case PersonHeight:
            return createElement(ActivityToolboxPersonHeightInput, props);
          case PersonWeight:
            return createElement(ActivityToolboxPersonWeightInput, props);
          case ChallengeProgress:
            return createElement(ActivityToolboxChallengeProgress, props);
          case DateTimeInput:
            return createElement(ActivityToolboxDateTimeInput, props);
          default:
            return null;
        }
      })}
    </ToolboxInputProvider>
  );
};
