import React, { forwardRef, useRef, useMemo } from 'react';
import * as RadixTabsPrimitive from '@radix-ui/react-tabs';
import { Icon, IconSource } from 'components/icon/icon';
import { styled } from '../../theme';
import { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import type { GDSTypographyToken } from '../../theme/utils/typography';
import { useTabElementIsInView } from './use-tab-element-is-in-view';
import { useTablistContext } from './tablist-context';

export interface GDSTabProps
  extends RadixTabsPrimitive.TabsTriggerProps,
    GDSCustomizableComponent {
  icon?: GDSResponsiveProp<IconSource>;
  iconLabel?: string;
  width?: GDSResponsiveProp<number | string>;
}
interface TabStyles {
  [key: string]: string | GDSTypographyToken | {};
}

export const tabStyles: TabStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$onSurfaceTextSubdued',
  padding: '$threeQuarters $half',
  border: 'none',
  borderBottomStyle: 'solid',
  borderBottomColor: 'transparent',
  borderBottomWidth: '$borderWidths$thick',
  backgroundColor: '$surfaceBackgroundPrimary',
  cursor: 'pointer',
  '&[aria-current="page"], &[data-state="active"], &.active': {
    color: '$interactiveActionPrimary',
    borderBottomColor: '$interactiveActionPrimary',
  },
  '&:hover': {
    color: '$interactiveActionHovered',
  },
  '&[class*="GDS"]:focus': {
    outlineOffset: '-$space$half',
  },
  typography: '$buttonTwo',
};

const BaseTab = styled(RadixTabsPrimitive.Trigger, {
  ...tabStyles,
});

export const TabIconLabelWrapper = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '$half',
});

export const Tab = forwardRef<HTMLButtonElement, GDSTabProps>(
  ({ children, className, css, icon, iconLabel, width, ...props }, ref) => {
    const internalRef = useRef<HTMLButtonElement>(null);
    const composedRefs = useComposedRefs(ref, internalRef);
    const tabWidth = useResponsiveProp(width);
    const tabIcon = useResponsiveProp(icon);
    const { elementIsInView, tabScrollOptions } = useTabElementIsInView();
    const { scrollLeftButton, scrollRightButton } = useTablistContext();

    const scrollElementIfNotInView = useMemo(
      () => () => {
        if (
          !elementIsInView(internalRef) &&
          (scrollLeftButton || scrollRightButton)
        ) {
          internalRef.current!.scrollIntoView(tabScrollOptions);
        }
      },
      [elementIsInView, tabScrollOptions, scrollLeftButton, scrollRightButton],
    );

    return (
      <BaseTab
        className={[`GDS-tab`, className].join(' ')}
        css={{
          ...css,
          ...(width && {
            width: `${tabWidth}`,
          }),
        }}
        ref={composedRefs}
        onFocus={() => scrollElementIfNotInView()}
        {...props}
      >
        <TabIconLabelWrapper>
          {tabIcon && <Icon icon={tabIcon} label={iconLabel} />}
          {children}
        </TabIconLabelWrapper>
      </BaseTab>
    );
  },
);
