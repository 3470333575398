/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { debounce } from 'lodash';
import {
  COMPLETION_METHOD_TYPES,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
} from '@leagueplatform/health-journey-common';
import { useHealthActivity } from './use-health-activity.hook';
import { useSetActivityProgress } from './use-set-activity-progress.hook';
import { useActivityAnalytics } from './use-activity-details-analytics.hook';

// Constants
const { COMPLETED } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { COUNTER } = COMPLETION_METHOD_TYPES;
const PROGRESS_WAIT_INTERVAL = 250;

export const useActivityProgressCounter = () => {
  // Hooks
  const activity = useHealthActivity();
  const activityProgressMutation = useSetActivityProgress();
  const activityAnalytics = useActivityAnalytics();

  // Properties
  const ready = !activityProgressMutation.isLoading;
  const hasError = activityProgressMutation.isError;
  const status = activity?.status;
  const cta = activity?.cta ?? {};
  const { count_progress: progress = 0 } = activity?.activityProgress ?? {};
  const { completion_method: completionMethod = {} } = cta;
  const { type, counter } = completionMethod;
  const { goal } = counter ?? {};
  const name = `${activity?.activityId}-${counter?.unit}-counter`;

  // Computed Properties
  const shouldUseCounter = type === COUNTER && counter;
  const promptToCompleteActivity = progress >= goal && status !== COMPLETED;

  /**
   * This function makes an API call to persist the user's progress.
   * It is memoized to avoid render-loops or expensive recalculations.
   * It is debounced to avoid extraneous API calls.
   * @param {number} newValue The progress value to persist on the back end
   */
  const handleChange = debounce(({ newValue, oldValue, inputMethod }) => {
    activityAnalytics.sendEditActivityProgress({
      inputMethod,
      newValue,
      oldValue,
    });
    activityProgressMutation.mutate({ count_progress: newValue });
  }, PROGRESS_WAIT_INTERVAL);

  const counterProps = {
    ready,
    hasError,
    cta,
    status,
    progress,
    handleChange,
    name,
  };

  return { shouldUseCounter, promptToCompleteActivity, counterProps };
};
