import React, { FC } from 'react';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { styled } from '../../theme';
import type {
  GDSCustomizableComponent,
  GDSHorizontalAlignmentValues,
  GDSVerticalAlignmentValues,
  GDSResponsiveProp,
  GDSSpacingValues,
  GDSImagePosition,
} from '../../types';

export interface GDSImageMessageProps extends GDSCustomizableComponent {
  children?: React.ReactNode;
  image: string;
  imageAlt?: string;
  imagePosition?: GDSResponsiveProp<GDSImagePosition>;
  imageHorizontalAlignment?: GDSResponsiveProp<GDSHorizontalAlignmentValues>;
  imageVerticalAlignment?: GDSResponsiveProp<GDSVerticalAlignmentValues>;
  imageGap?: GDSResponsiveProp<GDSSpacingValues>;
  imageWidth?: number;
  imageHeight?: number;
}

const ImageMessageBase = styled('div', {
  display: 'flex',
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  variants: {
    imagePosition: {
      top: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      end: { flexDirection: 'row-reverse' },
      start: { flexDirection: 'row' },
    },
  },
});

const ImageWrapper = styled('div', {
  display: 'flex',
  variants: {
    imagePosition: {
      top: {
        width: '100%',
        marginBottom: '$$imageGap',
      },
      start: {
        marginRight: '$$imageGap',
      },
      end: {
        marginLeft: '$$imageGap',
      },
    },
    imageHorizontalAlignment: {
      center: {
        justifyContent: 'center',
      },
      start: {
        justifyContent: 'start',
      },
      end: {
        justifyContent: 'end',
      },
    },
    imageVerticalAlignment: {
      center: {
        alignItems: 'center',
      },
      top: {
        alignItems: 'start',
      },
      bottom: {
        alignItems: 'end',
      },
    },
  },
});

const ContentWrapper = styled('div', {
  width: '100%',
});

export const ImageMessage: FC<GDSImageMessageProps> = ({
  children,
  image,
  imageGap = '$oneAndHalf',
  imageAlt = '',
  imagePosition = 'top',
  imageHorizontalAlignment = 'center',
  imageVerticalAlignment = 'center',
  imageWidth = 'auto',
  imageHeight = 'auto',
  className,
  css,
  ...props
}) => (
  <ImageMessageBase
    imagePosition={useResponsiveProp(imagePosition)}
    className={['GDS-image-message', className].join(' ')}
    css={css}
    {...props}
  >
    <ImageWrapper
      css={{ $$imageGap: `$space${useResponsiveProp(imageGap)}` }}
      imageHorizontalAlignment={useResponsiveProp(imageHorizontalAlignment)}
      imageVerticalAlignment={useResponsiveProp(imageVerticalAlignment)}
      imagePosition={useResponsiveProp(imagePosition)}
    >
      <img width={imageWidth} height={imageHeight} src={image} alt={imageAlt} />
    </ImageWrapper>
    {children && <ContentWrapper>{children}</ContentWrapper>}
  </ImageMessageBase>
);

ImageMessage.displayName = 'ImageMessage';
