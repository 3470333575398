import { FC, useEffect, useContext, useRef } from 'react';
import { HealthActivityComponent } from '@leagueplatform/health-journey-api';
import { useFormContext } from '@leagueplatform/web-common';
import { ToolboxComponentContext } from '../contexts/toolbox-component-provider.component';

export type MapInputValueToProps<TValue = any> = (
  value: TValue,
  component: HealthActivityComponent,
) => HealthActivityComponent;
/**
 * @prop inputName: unique name for the input being wrapped
 * @prop mapValueToProps: given a value and component object, mutate the component object to apply the value
 */
interface ActivityToolboxInputWrapperProps {
  inputName: string;
  mapValueToProps?: MapInputValueToProps;
  children: JSX.Element;
}

const defaultValueMapper: MapInputValueToProps = (value, component) =>
  Object.assign(component, {
    componentAttributes: { ...component.componentAttributes, answer: value },
  });

export const ActivityToolboxInputWrapper: FC<
  ActivityToolboxInputWrapperProps
> = ({ inputName, mapValueToProps = defaultValueMapper, children }) => {
  const { watch } = useFormContext();
  const { toolboxComponentMap } = useContext(ToolboxComponentContext);
  const { current: component } = useRef(toolboxComponentMap[inputName]);
  const value = watch(inputName);

  useEffect(() => {
    if (component) {
      mapValueToProps(value, component);
    }
  }, [mapValueToProps, component, value]);

  return children;
};
