import React, { useContext, useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';
import {
  Box,
  Button,
  GDSStyleObject,
  HeadingText,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { ACTIVITY_MODAL_MAX_WIDTH } from 'pages/health-activity/constants/health-activity-design.constants';
import { HealthActivityContext } from '../../contexts/health-activity-provider.component';

// Constants
const { WAVE_GOODBYE } = HEALTH_JOURNEY_ASSET_KEYS;

// Types
interface ActivityExitModalProps {
  onClose(): void;
}

// Styles
const modalStyles: GDSStyleObject = {
  '.GDS-modal-content': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '$twoAndHalf',
  },
};

const contentLayoutStyles: GDSStyleObject = {
  display: 'flex',
  gap: '$oneAndHalf',
  alignItems: 'center',
  flexDirection: 'column',
};

const imageContainerStyles: GDSStyleObject = {
  maxWidth: `34%`,
  img: { width: '100%', display: 'block' },
  '@mobile': { maxWidth: `176px` },
};

const copyContainerStyles: GDSStyleObject = {
  '&, > .GDS-heading-text': { width: '100%' },
  textAlign: 'center',
};

const modalFooterStyles: GDSStyleObject = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap-reverse',
  justifyContent: 'flex-end',
  gap: '$oneAndHalf',
  '@mobile': { gap: '$one' },
};

const modalButtonStyles: GDSStyleObject = {
  justifyContent: 'center',
  minWidth: '200px', // Determines the width at which the buttons must wrap
  '@mobile': { flexGrow: 1 },
};

export const ActivityExitModal = ({ onClose }: ActivityExitModalProps) => {
  const { formatMessage } = useIntl();
  const { returnToEntrypoint } = useActivityLink();
  const leaveActivityAsset = useStaticAsset(WAVE_GOODBYE);
  const { currentPage, healthActivityAnalytics } = useContext(
    HealthActivityContext,
  );

  useEffect(() => {
    if (healthActivityAnalytics?.sendViewExitModal) {
      healthActivityAnalytics?.sendViewExitModal(currentPage + 1);
    }
  }, [currentPage, healthActivityAnalytics]);

  const onContinue = () => {
    healthActivityAnalytics?.sendContinueActivity();
    onClose();
  };

  const onExit = () => {
    healthActivityAnalytics?.sendConfirmCloseActivity();
    returnToEntrypoint();
  };

  const formatMessageWithFallback = (messageId: string, defaultValue = '') =>
    formatMessage({ id: messageId }).replace(messageId, defaultValue);

  const copy = {
    title: formatMessageWithFallback('EXIT_ACTIVITY_TITLE'),
    subtitle: formatMessageWithFallback('EXIT_ACTIVITY_BODY'),
    continue: formatMessage({ id: 'KEEP_GOING' }),
    cancel: formatMessage({ id: 'EXIT_ACTIVITY_CTA' }),
  };

  return (
    <Modal.Content
      forceModalFocus={false}
      onInteractOutside={onContinue}
      padding={{ '@initial': '$two', '@mobile': '$one' }}
      width={`min(${ACTIVITY_MODAL_MAX_WIDTH}px, 100%)`}
      css={modalStyles}
    >
      <Box css={contentLayoutStyles}>
        {leaveActivityAsset && typeof leaveActivityAsset === 'string' && (
          <Box css={imageContainerStyles}>
            <img src={leaveActivityAsset} alt="" />
          </Box>
        )}
        <StackLayout
          orientation="vertical"
          spacing="$one"
          verticalAlignment="center"
          css={copyContainerStyles}
        >
          {copy.title && (
            <HeadingText level="2" size="xl">
              {copy.title}
            </HeadingText>
          )}
          {copy.subtitle && <ParagraphText>{copy.subtitle}</ParagraphText>}
        </StackLayout>
      </Box>
      <Box css={modalFooterStyles}>
        <Button
          onClick={onContinue}
          priority="tertiary"
          quiet
          css={modalButtonStyles}
        >
          {copy.continue}
        </Button>
        <Button onClick={onExit} css={modalButtonStyles}>
          {copy.cancel}
        </Button>
      </Box>
    </Modal.Content>
  );
};
