import { ParagraphText, HeadingText } from '@leagueplatform/genesis-core';
import { Box, Flex, SecondaryButton } from '@leagueplatform/genesis-commons';
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { FontAwesomeIcon } from '@leagueplatform/ui-kit';
import { DEPENDENT_RELATIONSHIP_MAP } from '@leagueplatform/web-common';
import { Dependent as _Dependent } from '@leagueplatform/wallet-api';

interface DependentProps {
  dependent: _Dependent;
  viewDependentCard: (dependent: _Dependent) => void;
}

export const Dependent = ({ viewDependentCard, dependent }: DependentProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
  const { email, first_name, last_name, relationship_type } = dependent;
  const { formatMessage } = useIntl();
  const relationshipTypeLocaleId =
    DEPENDENT_RELATIONSHIP_MAP[relationship_type] || relationship_type;
  return (
    <Box
      as="li"
      borderColor="onSurface.border.default"
      borderWidth="thin"
      borderStyle="solid"
      borderRadius="small"
      display="inline-block"
      width={1}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        padding="one"
        minHeight="100%"
      >
        <Box>
          <HeadingText
            size="lg"
            level="3"
            css={{ marginBottom: '$half' }}
            className="dependent-name"
          >
            {first_name} {last_name}
          </HeadingText>
          {email && (
            <ParagraphText
              css={{ marginTop: '$none' }}
              className="dependent-email"
              data-testid="dependent-email"
            >
              {email}
            </ParagraphText>
          )}
          {relationshipTypeLocaleId && (
            <ParagraphText
              css={{ marginTop: '$none' }}
              className="dependent-relationship"
            >
              {formatMessage({ id: relationshipTypeLocaleId })}
            </ParagraphText>
          )}
        </Box>
        <SecondaryButton
          aria-label={formatMessage(
            { id: 'VIEW_CARD_FOR_DEPENDENT' },
            { dependentName: `${first_name} ${last_name}` },
          )}
          onClick={() => viewDependentCard(dependent)}
          paddingX="one"
          paddingY="one"
          textAlign="center"
          width={1}
          marginTop="one"
        >
          {formatMessage({ id: 'VIEW_CARD' })}
          <Box as="span" marginLeft="half">
            <FontAwesomeIcon icon="angle-right" />
          </Box>
        </SecondaryButton>
      </Flex>
    </Box>
  );
};
