import { roundAxisValueUp } from './get-rounded-y-max';

/**
 * Generates n number of ticks after 0 to the `largestNumber`.
 */
export function getAxisTicks(largestNumber: number, numTicks = 4) {
  let tick = largestNumber;

  if (largestNumber < numTicks) {
    tick = numTicks;
  }

  tick = Math.ceil(largestNumber / numTicks);

  if (tick > 10) {
    tick = roundAxisValueUp(tick);
  }

  // Add 1 for the tick 0.
  const ticks = Array(numTicks + 1)
    .fill(0)
    .map((_value, index) => index * tick);

  return ticks;
}
