import React from 'react';
import { SubtitleTwo, Flex, Box } from '@leagueplatform/genesis-commons';
import { arrayToTag } from '@leagueplatform/web-common';
import { ChallengeActivityTokenCaption } from '@leagueplatform/challenges';
import { Icon } from '@leagueplatform/genesis-core';
import {
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  ACTIVITY_CAMPAIGN_TYPE,
} from '@leagueplatform/health-journey-common';
import { useHealthActivityCaption } from '../../../hooks/use-health-activity-captions.hook';
import {
  mapToProps,
  activityPropType,
  activityDefaultProps,
} from '../../../common/health-activity.props';

const { COMPLETED } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

export const ActivityCaption = (activity) => {
  // Static Assets

  const activityAll = mapToProps(activity);
  const { expires, points, assigned } = useHealthActivityCaption(activityAll);

  const { status, tokensEarned, activityTokens, campaignInfo } = activity;
  const fullCaptionText =
    campaignInfo.campaignType === CHALLENGE
      ? arrayToTag([expires], true)
      : arrayToTag([assigned, expires, points], true);

  return (
    <Flex marginBottom="oneAndHalf">
      {(assigned || expires) && (
        <Icon
          icon="illustrativeCalendar"
          size="12px"
          css={{ marginY: 'auto', marginRight: '$half' }}
        />
      )}
      <SubtitleTwo
        as="span"
        color={
          status.toLowerCase() === COMPLETED
            ? 'onSurface.text.subdued'
            : 'inherit'
        }
      >
        {fullCaptionText}
        {campaignInfo.campaignType === CHALLENGE && points && (
          <>
            {fullCaptionText && (
              <Box as="span" marginX="quarter">
                ∙
              </Box>
            )}
            <ChallengeActivityTokenCaption
              tokens={activityTokens || tokensEarned}
              activityDesc={points}
            />
          </>
        )}
      </SubtitleTwo>
    </Flex>
  );
};

ActivityCaption.propTypes = activityPropType;

ActivityCaption.defaultProps = activityDefaultProps;
