/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, MouseEventHandler } from 'react';
import {
  Button,
  GDSButtonProps,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { TRANSITIONS } from '../../constants/health-activity-design.constants';

const { QUARTER_PAGE } = TRANSITIONS;

export interface ActivityCircleButtonProps
  extends Omit<GDSButtonProps<'button'>, 'children'> {
  children?: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const baseStyles: GDSStyleObject = {
  zIndex: `calc($sticky - $default)`,
  borderRadius: 'circle',
  padding: '$threeQuarters',
  transition: `background ${QUARTER_PAGE}, color ${QUARTER_PAGE}`,
};

export const ActivityCircleButton = ({
  children,
  css,
  hideLabel,
  icon,
  ...props
}: ActivityCircleButtonProps) => (
  <Button
    type="button"
    css={{ ...baseStyles, ...css }}
    icon={icon}
    hideLabel={hideLabel || !children}
    {...props}
  >
    {children}
  </Button>
);
