import { GDSStyleObject } from '@leagueplatform/genesis-core';
import { HealthActivityComponent } from '@leagueplatform/health-journey-api';

export type ToolboxComponentMap = Record<string, HealthActivityComponent>;

export interface ToolboxComponentProps {
  componentId?: string;
  css?: GDSStyleObject;
  key?: string;
  isVisible?: boolean;
}

export interface ToolboxCommonInputProps extends ToolboxComponentProps {
  componentId: string;
}

export enum HealthActivityModalTypes {
  Remove = 'remove',
  Exit = 'exit',
  ApiDelay = 'api-delay',
  EditComponentProgress = 'edit-component-progress',
  Warning = 'warning',
  None = '',
}
