import { useState, useEffect } from 'react';
import { useHistory, useLocation } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  JOURNEY_ROUTES,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useJourneyProgressAnalytics } from './use-journey-progress-analytics.hook';

const { ACTIVITY_TIMELINE, PROGRESS_PAGE } = SCREEN_NAMES;
const { journeyHome, progressHome } = JOURNEY_ROUTES;

export const useHealthPills = () => {
  const location = useLocation();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const progressAnalytics = useJourneyProgressAnalytics();

  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      label: formatMessage({ id: 'ACTIVITIES_PILL' }),
      onClick: () => {
        progressAnalytics.sendPillNavigation({
          from: PROGRESS_PAGE,
          to: 'activities',
        });
        history.push(journeyHome());
      },
      index: 0,
      page: 'activities',
      documentTitle: formatMessage({ id: 'HEALTH_JOURNEY' }),
      isCurrentPage: location.pathname.includes(journeyHome()),
    },
    {
      label: formatMessage({ id: 'PROGRESS' }),
      onClick: () => {
        progressAnalytics.sendPillNavigation({
          from: ACTIVITY_TIMELINE,
          to: 'progress',
        });
        history.push(progressHome());
      },
      index: 1,
      page: 'progress',
      documentTitle: formatMessage({ id: 'PROGRESS' }),
      isCurrentPage: location.pathname.includes(progressHome()),
    },
  ];

  const { documentTitle } = tabs[activeIndex];
  useDocumentTitle(documentTitle);

  useEffect(() => {
    const currentIndex = tabs.find((tab) => tab.isCurrentPage)?.index;
    setActiveIndex(currentIndex ?? activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIndexChange = (index) => {
    setActiveIndex(index);
    tabs[index].onClick();
  };

  return {
    tabs,
    activeIndex,
    handleIndexChange,
  };
};
