export enum HEALTH_JOURNEY_ASSET_KEYS {
  ALARM_CLOCK = 'ALARM_CLOCK',
  CALENDAR = 'CALENDAR',
  CHALLENGE_TOKEN = 'CHALLENGE_TOKEN',
  CHALLENGE_TOKEN_SUBDUED = 'CHALLENGE_TOKEN_SUBDUED',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  HEART_RATE_UP = 'HEART_RATE_UP',
  HIGH_PRIORITY_ICON = 'HIGH_PRIORITY_ICON',
  LEAGUE_LOGO = 'LEAGUE_LOGO',
  LEAGUE_LOGO_SUBDUED = 'LEAGUE_LOGO_SUBDUED',
  MINUS = 'MINUS',
  MOBILE_ALERT_USER = 'MOBILE_ALERT_USER',
  PLUS = 'PLUS',
  SUCCESS_STATUS_ALTERNATIVE = 'SUCCESS_STATUS_ALTERNATIVE',
  REQUIRED_ICON = 'REQUIRED_ICON',
  COMPLETE_BY_ICON = 'COMPLETE_BY_ICON',
  REWARDS_POINTS_ICON = 'REWARDS_POINTS_ICON',
  REWARDS_PROGRAM_ICON = 'REWARDS_PROGRAM_ICON',
  ACTIVITY_POINTS_ICON = 'ACTIVITY_POINTS_ICON',

  // TODO – JRNY – Update tokens to reflect the source-of-truth when they become available
  EMPTY_STATE_ACHIEVEMENTS = 'EMPTY_STATE_ACHIEVEMENTS',
  EMPTY_STATE_PROGRAMS = 'EMPTY_STATE_PROGRAMS',
  EMPTY_STATE_PROGRESS = 'EMPTY_STATE_PROGRESS',
  EMPTY_STATE_REST = 'EMPTY_STATE_REST',
  EMPTY_STATE_HISTORY = 'EMPTY_STATE_HISTORY',
  HIGH_FIVE = 'HIGH_FIVE',
  WARNING_ICON = 'WARNING_ICON',
  WAVE_GOODBYE = 'WAVE_GOODBYE',
  JOURNEY_HEADER_BACKGROUND_IMAGE = 'JOURNEY_HEADER_BACKGROUND_IMAGE',
}
