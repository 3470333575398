import {
  HealthActivityComponent,
  ToolboxTransformedComponent,
} from '@leagueplatform/health-journey-api';
import { ComponentTypes } from '@leagueplatform/health-journey-common';

const {
  DateInput,
  DecimalInput,
  IntegerInput,
  MultipleSelectInput,
  StringInput,
  ProgressTracking,
  PersonHeight,
  PersonWeight,
} = ComponentTypes;

/**
 * Transform toolbox components to include only the properties needed for API calls
 * @returns A transformed copy of the component argument
 */
export const toolboxComponentPayloadTransformer = (
  component: HealthActivityComponent,
): ToolboxTransformedComponent => {
  const componentCopy: HealthActivityComponent = JSON.parse(
    JSON.stringify(component),
  );

  const {
    answerOptions,
    answer,
    unit,
    currentProgress,
    additionalDateOptions = [],
    validation,
  } = componentCopy.componentAttributes ?? {};
  const { required } = validation ?? {};

  switch (componentCopy.componentType) {
    case MultipleSelectInput:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: {
          answerOptions: answerOptions
            .filter(({ isSelected }) => Boolean(isSelected))
            .map(
              ({
                id,
                isSelected,
                value,
                text,
                enableFreeText,
                inputType = '',
                freeTextValidation,
              }) => ({
                id,
                isSelected,
                value,
                text,
                enableFreeText,
                inputType: freeTextValidation?.inputType || inputType,
              }),
            ),
          required,
        },
      };
    case DateInput:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: {
          answer,
          required,
          additionalDateOptions: additionalDateOptions
            .filter(({ isSelected }) => Boolean(isSelected))
            .map(
              ({
                id,
                isSelected,
                value,
                unit: dateOptionUnit,
                text,
                code = '',
              }) => ({
                id,
                isSelected,
                value,
                text,
                unit: dateOptionUnit,
                code,
              }),
            ),
        },
      };
    case DecimalInput:
    case IntegerInput:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: { answer, required, unit },
      };
    case StringInput:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: { answer, required },
      };
    case ProgressTracking:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: { unit, currentProgress },
      };
    case PersonHeight:
    case PersonWeight:
      return <ToolboxTransformedComponent>{
        ...componentCopy,
        componentAttributes: { unit, answer },
      };
    default:
      return componentCopy;
  }
};
