import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Card,
  HeadingText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';

type HighlightCardProps = {
  title: string;
  seeMoreLink: string;
  children: React.ReactNode;
  onClick: () => void;
};

export function HighlightCard({
  title,
  seeMoreLink,
  children,
  onClick,
}: HighlightCardProps) {
  const { formatMessage } = useIntl();

  return (
    <Card.Elevated>
      <Card.Section>
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          verticalAlignment="center"
          css={{ marginBottom: '$one' }}
        >
          <HeadingText level="3" size="lg">
            {title}
          </HeadingText>
          <TextAction
            emphasis="subtle"
            css={{
              typography: '$overline',
            }}
            href={seeMoreLink}
            onClick={onClick}
          >
            {formatMessage({ id: 'SEE_MORE' })}
          </TextAction>
        </StackLayout>
        {children}
      </Card.Section>
    </Card.Elevated>
  );
}
