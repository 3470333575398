import { useCallback, useEffect, useState, useMemo } from 'react';
import { GDSStyleObject } from '@leagueplatform/genesis-core';
import {
  QueryObserver,
  QueryObserverOptions,
  useQueryClient,
} from 'react-query';

export const ACTIVITY_TOOLBOX_THEME = 'activity-toolbox-theme';

export const activityToolboxDefaultTheme: GDSStyleObject = {
  backgroundColor: '$surfaceBackgroundSecondary',
};

const queryObserverOptions: QueryObserverOptions<GDSStyleObject, Error> = {
  queryKey: [ACTIVITY_TOOLBOX_THEME],
  initialData: activityToolboxDefaultTheme,
  staleTime: Infinity,
};

export const useActivityToolboxTheme = () => {
  const queryClient = useQueryClient();

  /** Query observer is being used to avoid using a needless query function and to work more consistently in Storybook */
  const observer = useMemo(
    () => new QueryObserver(queryClient, queryObserverOptions),
    [queryClient],
  );

  const [activityToolboxTheme, updateActivityToolboxTheme] = useState(
    observer.getCurrentResult().data,
  );

  // Track global activity theme and apply it to local state
  useEffect(() => {
    const unsubscribe = observer.subscribe((result) =>
      updateActivityToolboxTheme(result.data),
    );
    return unsubscribe;
  }, [observer, updateActivityToolboxTheme]);

  const setActivityToolboxTheme = useCallback(
    (newThemeOptions: GDSStyleObject) =>
      queryClient.setQueryData(
        [ACTIVITY_TOOLBOX_THEME],
        (currentThemeOptions: GDSStyleObject = {}) => ({
          ...currentThemeOptions,
          ...newThemeOptions,
        }),
      ),
    [queryClient],
  );

  return { activityToolboxTheme, setActivityToolboxTheme };
};
