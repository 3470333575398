import * as React from 'react';
import { SubtitleOne, Image, Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { HealthSource } from '@leagueplatform/dashboard-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { dataSourceConfigMap } from 'constants/data-source-config';

type DataSourceLogoIcon = { src: string };

type DataSourceItemProps = {
  dataSource: HealthSource;
};
const SourceLogoIcon = ({ src }: DataSourceLogoIcon) => (
  <Image alt="" width="36px" src={src} />
);

export const DataSourceItem = ({ dataSource }: DataSourceItemProps) => {
  const { formatMessage } = useIntl();
  const dataSourceConfig = dataSourceConfigMap.get(dataSource.device);

  //  check if unsupported data type is passed in, ignore it
  if (!dataSourceConfig) {
    return null;
  }

  const { source, icon } = dataSourceConfig;
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      paddingY="one"
      borderBottomWidth="thin"
      borderColor="onSurface.border.subdued"
    >
      <SourceLogoIcon src={handleStaticAsset(icon) as string} />
      <SubtitleOne paddingLeft="one">
        {formatMessage({
          id: source,
        })}
      </SubtitleOne>
    </Flex>
  );
};
