import React, { FC, useMemo } from 'react';
import { Transition } from 'react-transition-group';

// Common Dependencies
import { Box, StackLayout, css } from '@leagueplatform/genesis-core';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';

// Hooks

// Common Components

// Local Components

// Constants
import {
  TRANSITIONS,
  TRANSITION_DURATION_MS,
} from '../constants/health-activity-design.constants';

// Types
interface ActivitySlideProps {
  gap?: string;
  slideIndex: number;
  activeIndex: number;
  onTransition(isTransitioning: boolean): void;
}

// Styles
const slideTransition = `transform ${TRANSITIONS.PAGE}, opacity ${TRANSITIONS.PAGE}, visibility ${TRANSITIONS.PAGE}`;
const reducedMotionSlideTransition = `opacity ${TRANSITIONS.PAGE}, visibility ${TRANSITIONS.PAGE}`;
const reducedMotionSlideStyles = css({
  left: 0,
  transform: 'none',
  transition: reducedMotionSlideTransition,
});

/**
 * This hook determines each slide's transitionable styles based on the users preference for reduced motion
 * @returns `css()` styles
 */
const useSlideTransitionStyles = ({
  slideIndex,
  gap,
  activeIndex,
}: Omit<ActivitySlideProps, 'onTransition'>) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const slideStyles = useMemo(
    () => ({
      left: `calc(${slideIndex} * ${gap})`,
      transform: `translateX(calc(-${gap} * ${activeIndex}))`,
      transition: slideTransition,
    }),
    [activeIndex, gap, slideIndex],
  );

  return useMemo(
    () => (prefersReducedMotion ? reducedMotionSlideStyles : slideStyles),
    [prefersReducedMotion, slideStyles],
  );
};

export const HealthActivitySlide: FC<ActivitySlideProps> = ({
  children,
  gap = '100%',
  slideIndex,
  activeIndex,
  onTransition,
}) => {
  const slideTransitionStyles = useSlideTransitionStyles({
    gap,
    slideIndex,
    activeIndex,
  });
  const isActive = activeIndex === slideIndex;
  return (
    <Box
      aria-hidden={!isActive}
      css={{
        ...slideTransitionStyles,
        visibility: isActive ? 'visible' : 'hidden',
        height: '100%',
        opacity: Number(isActive),
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <Transition
        in={isActive}
        timeout={TRANSITION_DURATION_MS.XLONG}
        onEnter={() => onTransition?.(true)}
        onExited={() => onTransition?.(false)}
      >
        {children}
      </Transition>
    </Box>
  );
};

export const HealthActivitySlider: FC<{}> = ({ children }) => (
  <StackLayout css={{ height: '100%', width: '100%', position: 'relative' }}>
    {children}
  </StackLayout>
);
