import { useQueryClient, useMutation } from 'react-query';
import {
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
  GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
  dismissUserHealthActivity,
} from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';

export const useDismissUserHealthActivity = (userActivityId) => {
  const queryClient = useQueryClient();
  const { returnToEntrypoint } = useActivityLink();

  return useMutation(() => dismissUserHealthActivity(userActivityId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE);
      queryClient.invalidateQueries(GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE);
      returnToEntrypoint();
      return data;
    },
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_STATE_TITLE',
        contentId: 'ACTIVITY_DISMISS_ERROR',
      });
    },
  });
};
