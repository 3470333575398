import { ToolboxTransformedComponent } from '@leagueplatform/health-journey-api';
import { useRef, useCallback } from 'react';
import {
  ToolboxOnFormRegister,
  ToolboxFormMethods,
  ToolboxGetInputComponentOptions,
} from '../types/toolbox.types';

type ActivityRegisterForm = (pageIndex: number) => ToolboxOnFormRegister;

export const useActivityFormManager = () => {
  const { current: paginatedForms } = useRef<ToolboxFormMethods[]>([]);

  const registerForm: ActivityRegisterForm = useCallback(
    (pageIndex) => (formMethods) => {
      paginatedForms[pageIndex] = formMethods;
    },
    [paginatedForms],
  );

  // Validation
  const validateAllPages = useCallback(
    () =>
      Promise.all(
        paginatedForms.map(
          (formMethods: ToolboxFormMethods) => formMethods?.trigger() ?? true,
        ),
      ).then((pageValidationArr) =>
        pageValidationArr.every((isPageValid) => isPageValid ?? true),
      ),
    [paginatedForms],
  );

  const validatePage = useCallback(
    async (pageIndex: number) =>
      paginatedForms[pageIndex]?.trigger(undefined, {
        shouldFocus: true,
      }) ?? true,
    [paginatedForms],
  );

  const validatePages = useCallback(
    (pageIndices: number[]) =>
      Promise.all(pageIndices.map(validatePage)).then((pageValidationArr) =>
        pageValidationArr.every(Boolean),
      ),
    [validatePage],
  );

  // Getters
  const getQuestionsForPage = useCallback(
    (pageIndex: number, options: ToolboxGetInputComponentOptions = {}) =>
      paginatedForms[pageIndex]?.getInputComponents(options),
    [paginatedForms],
  );

  const getAllQuestions = useCallback(
    (options: ToolboxGetInputComponentOptions = {}) =>
      // Flatten paginated questions
      paginatedForms.flatMap<ToolboxTransformedComponent>((formMethods) =>
        formMethods.getInputComponents(options),
      ),
    [paginatedForms],
  );

  const getQuestionsForPages = useCallback(
    (pageIndices: number[], options: ToolboxGetInputComponentOptions = {}) =>
      // Flatten paginated questions, filter out pages with no questions
      pageIndices
        .flatMap((pageIndex) => getQuestionsForPage(pageIndex, options))
        .filter(Boolean),
    [getQuestionsForPage],
  );

  return {
    paginatedForms,
    registerForm,
    validateAllPages,
    validatePage,
    validatePages,
    getQuestionsForPage,
    getQuestionsForPages,
    getAllQuestions,
  };
};
