export const USER_HEALTH_CAMPAIGNS_PATH = '/v1/user-health-campaigns';

export enum USER_HEALTH_CAMPAIGNS_PARAMS {
  USER_HEALTH_ACTIVITY = 'user-health-activity',
  USER_HEALTH_CAMPAIGN = 'user-health-campaign',
}

export enum USER_HEALTH_CAMPAIGN_STATUS {
  ACTIVE = 'active',
  ENDED = 'ended',
  COMPLETED = 'completed',
  SUSPENDED = 'suspended',
}

export const GET_USER_HEALTH_CAMPAIGN_MESSAGE_TYPE = 'get_user_health_campaign';
