import React, {
  FC,
  useCallback,
  useMemo,
  createContext,
  useRef,
  useContext,
} from 'react';
import { HealthActivityComponent } from '@leagueplatform/health-journey-api';
import { ComponentTypes } from '@leagueplatform/health-journey-common';
import { ToolboxComponentMap } from '../types/health-activity.types';
import { ToolboxComponentActionMap } from '../types/toolbox.types';

// Constants
const ComponentKeys = Object.values(ComponentTypes);
export const DefaultComponentActions = Object.freeze<ToolboxComponentActionMap>(
  Object.fromEntries(ComponentKeys.map((componentKey) => [componentKey])),
);

// Types
type RegisterToolboxComponent = (
  name: string,
  component: HealthActivityComponent,
) => HealthActivityComponent;

export type ToolboxComponentContextProps = {
  actions: ToolboxComponentActionMap;
  registerToolboxComponent: RegisterToolboxComponent;
  toolboxComponentMap: ToolboxComponentMap;
};

export const ToolboxComponentContext =
  createContext<ToolboxComponentContextProps>({
    actions: DefaultComponentActions,
    registerToolboxComponent: null!,
    toolboxComponentMap: {},
  });

export const ToolboxComponentProvider: FC<{
  actions?: Partial<ToolboxComponentActionMap>;
}> = ({ children, actions = DefaultComponentActions }) => {
  const toolboxComponentMapRef = useRef<ToolboxComponentMap>({});

  /**
   * Apply new component relationship if it doesn't exist
   * @argument componentId – the component's unique identifier
   * @argument component – the component's relationship data to set
   */
  const registerToolboxComponent: RegisterToolboxComponent = useCallback(
    (componentId, component) => {
      const currentComponent = toolboxComponentMapRef.current[componentId];

      if (!currentComponent) {
        toolboxComponentMapRef.current[componentId] = component;
        return component;
      }
      return currentComponent;
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      actions: { ...DefaultComponentActions, ...actions },
      registerToolboxComponent,
      toolboxComponentMap: toolboxComponentMapRef.current,
    }),
    [actions, registerToolboxComponent],
  );

  return (
    <ToolboxComponentContext.Provider value={contextValue}>
      {children}
    </ToolboxComponentContext.Provider>
  );
};

/**
 * `useToolboxComponentContext` is intended to be used within Toolbox components.
 * It provides Toolbox components with access to the configuration objects
 * set at the capability-level for a corresponding `componentType`.
 */
export const useToolboxComponentContext = <
  TComponentType extends ComponentTypes,
>(
  componentType: TComponentType,
) => {
  const { actions: actionMap } = useContext(ToolboxComponentContext);
  const actions = actionMap?.[componentType];
  return { actions };
};
