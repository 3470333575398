import {
  number,
  shape,
  string,
  bool,
  oneOf,
  oneOfType,
  arrayOf,
} from 'prop-types';
import { HEALTH_JOURNEY_ACTIVITY_STATUS } from '@leagueplatform/health-journey-common';

const activityStatusArray = Object.values(HEALTH_JOURNEY_ACTIVITY_STATUS);
const timestampType = oneOfType([string, number]);

export const activityShortPropType = {
  activity_points: number.isRequired,
  points_earned: number,
  campaign_info: shape({
    campaign_id: string.isRequired,
    campaign_type: string,
    name: string.isRequired,
    start_date: string.isRequired,
    assigned: bool,
    assigned_by_title: string,
  }).isRequired,
  can_expire: bool.isRequired,
  suggested: bool.isRequired,
  start_date: timestampType.isRequired,
  icon_url: string.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  status: oneOf(activityStatusArray).isRequired,
  end_date: timestampType,
  completed_date: timestampType,
  removed_date: timestampType,
  activity_tokens: number.isRequired,
  tokens_earned: number,
};

export const activityShortDefaultProps = {
  points_earned: 0,
  end_date: '',
  completed_date: '',
  removed_date: '',
  tokens_earned: 0,
};

export const activitiesArrayPropType = arrayOf(shape(activityShortPropType));
export const activitiesGroupPropType = arrayOf(
  shape({ title: string, activities: activitiesArrayPropType }),
);

export const activitiesGroupsProps = {
  activitiesByCampaign: activitiesGroupPropType,
  activitiesByStatus: activitiesGroupPropType,
  activitiesByAssigned: activitiesArrayPropType,
  activitiesByChallenge: activitiesGroupPropType,
  activitiesByPriority: activitiesArrayPropType,
};

export const activitiesGroupsDefaultProps = {
  activitiesByCampaign: undefined,
  activitiesByStatus: undefined,
  activitiesByAssigned: undefined,
  activitiesByChallenge: undefined,
  activitiesByPriority: undefined,
};

export const journeyPageProps = {
  todaysDate: number.isRequired,
};

export const journeyPageEmptyStateProps = {
  button: string,
  image: shape({
    alt: string,
    src: string,
  }),
  text: shape({
    bodyText: string,
    headingText: string,
  }),
};
