import React, { forwardRef, useMemo, ElementType } from 'react';
import { TypographyBase } from 'components/typography/typography-base/typography-base';
import type { GDSTypography } from '../typography';
import type {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../../../types';
import { styled, css as themeCss } from '../../../theme';
import type { GDSVariantProps } from '../../../theme';

export type GDSHeadingSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type GDSHeadingLevel = '1' | '2' | '3' | '4' | '5' | '6' | 'display';

const BaseHeading = styled(TypographyBase, {
  display: 'block',
  variants: {
    size: {
      xxl: {
        typography: '$headingOne',
      },
      xl: {
        typography: '$headingTwo',
      },
      lg: {
        typography: '$headingThree',
      },
      md: {
        typography: '$headingFour',
      },
      sm: {
        typography: '$subtitleOne',
      },
      xs: {
        typography: '$subtitleTwo',
      },
    },
  },
});

const spanStyles = themeCss({
  display: 'block',
});

export type GDSHeadingTextProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      as?: ElementType;
      /**
       * Specify size for the heading text.
       */
      size: GDSVariantProps<typeof BaseHeading>['size'];
      level: GDSHeadingLevel;
      children: React.ReactNode;
    }
  > &
    GDSTypography;

export type GDSHeadingTextComponent = <C extends React.ElementType = 'span'>(
  props: GDSHeadingTextProps<C>,
) => React.ReactElement | null;

const elementByLevel = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
  '5': 'h5',
  '6': 'h6',
  display: 'span',
};

const getElementByLevel = (level: GDSHeadingLevel): IntrinsicElement =>
  elementByLevel[level] as IntrinsicElement;

type IntrinsicElement = keyof JSX.IntrinsicElements;

export const HeadingText: GDSHeadingTextComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    {
      as,
      children,
      className,
      css,
      emphasis = 'base',
      id,
      level,
      size,
      ...props
    }: GDSHeadingTextProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const asSpan = level === 'display';
    const element: keyof JSX.IntrinsicElements = useMemo(
      () => getElementByLevel(level),
      [level],
    );
    return (
      <BaseHeading
        as={as || element}
        className={[
          'GDS-heading-text',
          asSpan ? spanStyles() : undefined,
          className,
        ].join(' ')}
        css={css}
        emphasis={emphasis}
        id={id}
        ref={ref}
        size={size}
        {...props}
      >
        {children}
      </BaseHeading>
    );
  },
);

// @ts-ignore
HeadingText.displayName = 'HeadingText';
