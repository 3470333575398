import { useCallback } from 'react';

import { DateValue, Time } from '@internationalized/date';
import { useFormContext } from '@leagueplatform/web-common';

interface UseToolboxDateTimeInputProps {
  componentId: string;
}

export const useToolboxDateTimeInput = ({
  componentId,
}: UseToolboxDateTimeInputProps) => {
  const { setValue } = useFormContext();

  const setValueAndValidate = useCallback(
    (newValue: string | number | null) => {
      setValue(componentId, String(newValue), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [componentId, setValue],
  );

  const handleDateTimeChange = useCallback(
    (value: DateValue | Time) => {
      setValueAndValidate(value.toString());
    },
    [setValueAndValidate],
  );

  return {
    handleDateTimeChange,
  };
};
