import { useIntl } from '@leagueplatform/locales';

// TEMPORARILY COPYING A BUNCH OF FIELDS FROM ABOUT-ME TO SUPPORT VERIFIABLE ACTIVITY CARDS

export const supportedMetrics = [
  'distance',
  'steps',
  'active_duration',
  // , 'sleep_duration'
  'mindful_duration',
  'floors_climbed',
  // , 'idle_hours_count'
  'energy_burned',
] as const;

export type MetricType = (typeof supportedMetrics)[number];

type DataTypeConfig = {
  metric: string;
  unit?: string;
};

const metricUnits = [
  'count',
  's',
  'm',
  'min',
  'cal',
  'kcal',
  'bmp',
  'km',
  'mg/dl',
] as const;
export type MetricUnit = (typeof metricUnits)[number];

const digits: Record<MetricType, number> = {
  distance: 1,
  steps: 0,
  active_duration: 0,
  mindful_duration: 0,
  floors_climbed: 0,
  energy_burned: 0,
};

const dataTypeConfigMap = new Map<MetricType, DataTypeConfig>([
  [
    'distance',
    {
      metric: 'INSIGHT_CARD_HEADER_DISTANCE',
    },
  ],
  [
    'steps',
    {
      metric: 'INSIGHT_CARD_HEADER_STEPS',
      unit: 'INSIGHT_CARD_UNIT_STEPS',
    },
  ],
  [
    'floors_climbed',
    {
      metric: 'INSIGHT_CARD_HEADER_FLOORS_CLIMBED',
      unit: 'INSIGHT_CARD_UNIT_FLOORS_CLIMBED',
    },
  ],
  [
    'active_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_ACTIVE_DURATION',
    },
  ],
  [
    'energy_burned',
    {
      metric: 'INSIGHT_CARD_HEADER_ENERGY_BURNED',
    },
  ],
  [
    'mindful_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_MINDFUL_DURATION',
    },
  ],
]);

// temporary mapping until Dynamic Dashboard is implemented.
export function useLocaleUnitFormatter() {
  const { formatMessage } = useIntl();

  const formatUnit = (type: MetricType, unit: MetricUnit, value = 0) => {
    const metricConfig = dataTypeConfigMap.get(type);

    if (metricConfig?.unit) {
      return formatMessage({ id: metricConfig.unit }, { count: value });
    }
    if (metricUnits.includes(unit)) {
      return formatMessage({ id: unit.toUpperCase() }, { count: value });
    }
    return `${value} ${unit}`;
  };

  return { formatUnit };
}

export function useLocaleMetricValueFormatter() {
  const { formatNumber } = useIntl();

  function formatMetricValue(
    value: number | null,
    type: MetricType,
  ): string | null {
    const maximumFractionDigits = digits[type];

    if (value === null) {
      return null;
    }

    return formatNumber(value, { maximumFractionDigits });
  }

  return { formatMetricValue };
}
