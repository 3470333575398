import { useQuery } from 'react-query';

import {
  getUserHealthActivity,
  GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';

const getActivityOptions = {
  staleTime: 300,
};

/**
 * @param {string} userActivityId The users activity ID that is generated upon enrolment into that activity
 * @param {string} contentActivityId The generic contentful ID for the activity
 * @param {string} campaignId The campaign ID that the activity belongs to
 */
export const useGetUserHealthActivity = (
  userActivityId = null,
  contentActivityId = null,
  campaignId = null,
) =>
  useQuery(
    [
      GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
      userActivityId,
      contentActivityId,
      campaignId,
    ],
    () => getUserHealthActivity(userActivityId, contentActivityId, campaignId),
    {
      enabled:
        Boolean(userActivityId) || Boolean(contentActivityId && campaignId),
      ...getActivityOptions,
    },
  );
