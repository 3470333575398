/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useIntl } from '@leagueplatform/locales';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';

const { EMPTY_STATE_PROGRAMS, EMPTY_STATE_REST, HIGH_FIVE } =
  HEALTH_JOURNEY_ASSET_KEYS;

export const defaultEmptyStateInfo = {
  diffOfDates: 0,
  hasActivities: false,
  hasCompletedAllActivities: false,
};

/**
 * Build and return content for EmptyStateCard and children, based on member's activities, programs, and the active date
 * @param {object} emptyStateInfo Top-level object containing named parameters:
 ** @param {number} emptyStateInfo.diffOfDates Represents the difference between 'today' and the active date, where 0 = today
 ** @param {boolean} emptyStateInfo.hasActivities Confirm whether member has activities remaining for the day
 ** @param {boolean} emptyStateInfo.hasCompletedAllActivities Confirm whether member has completed the day's activities
 * @returns An object with button, image, and text props to map to components in the EmptyStateCard
 */

export const useEmptyActivitiesState = ({
  diffOfDates,
  hasActivities,
  hasCompletedAllActivities,
} = defaultEmptyStateInfo) => {
  const { formatMessage } = useIntl();

  if (hasCompletedAllActivities) return emptyStateTodayCompleted(formatMessage);
  if (hasActivities) return null;

  if (diffOfDates > 0) return emptyStateFuture(formatMessage);
  if (diffOfDates < 0) return emptyStatePast(formatMessage);

  return emptyStateToday(formatMessage);
};

export const emptyStateFuture = (formatMessage) => {
  // Static Assets
  const emptyStatePrograms = handleStaticAsset(EMPTY_STATE_PROGRAMS);
  return {
    button: null,
    image: {
      alt: formatMessage({ id: 'IMG_ALT_EMPTY_STATE_PROGRAMS' }),
      src: emptyStatePrograms,
    },
    text: {
      bodyText: formatMessage({
        id: 'NO_ACTIVITIES_FUTURE_DESCRIPTION',
      }),
      headingText: formatMessage({
        id: 'NO_ACTIVITIES_HAS_PROGRAMS_TODAY_OR_FUTURE_HEADING',
      }),
    },
  };
};

export const emptyStatePast = (formatMessage) => {
  // Static Assets
  const emptyStateRest = handleStaticAsset(EMPTY_STATE_REST);
  return {
    button: null,
    image: {
      alt: formatMessage({ id: 'IMG_ALT_EMPTY_STATE_REST' }),
      src: emptyStateRest,
    },
    text: {
      bodyText: formatMessage({
        id: 'NO_ACTIVITIES_PAST_DESCRIPTION',
      }),
      headingText: formatMessage({
        id: 'NO_ACTIVITIES_PAST_HEADING',
      }),
    },
  };
};

export const emptyStateToday = (formatMessage) => {
  // Static Assets
  const emptyStatePrograms = handleStaticAsset(EMPTY_STATE_PROGRAMS);
  return {
    button: formatMessage({ id: 'BUTTON_BROWSE_PROGRAMS' }),
    image: {
      alt: formatMessage({ id: 'IMG_ALT_EMPTY_STATE_PROGRAMS' }),
      src: emptyStatePrograms,
    },
    text: {
      bodyText: formatMessage({
        id: 'NO_ACTIVITIES_HAS_PROGRAMS_TODAY_DESCRIPTION',
      }),
      headingText: formatMessage({
        id: 'NO_ACTIVITIES_HAS_PROGRAMS_TODAY_OR_FUTURE_HEADING',
      }),
    },
  };
};

export const emptyStateTodayCompleted = (formatMessage) => {
  // Static Assets
  const highFiveHands = handleStaticAsset(HIGH_FIVE);
  return {
    button: null,
    image: {
      alt: formatMessage({ id: 'IMG_ALT_HIGH_FIVE_HANDS' }),
      src: highFiveHands,
    },
    text: {
      bodyText: formatMessage({
        id: 'NO_ACTIVITIES_COMPLETED_TODAY_DESCRIPTION',
      }),
      headingText: formatMessage({
        id: 'NO_ACTIVITIES_COMPLETED_TODAY_HEADING',
      }),
    },
  };
};
