import React from 'react';
import {
  BackgroundColorSystemProp,
  Box,
  SystemPropType,
} from '@leagueplatform/genesis-commons';
import { ErrorState } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  ERROR_TYPES,
  useErrorMessage,
} from '../../hooks/use-error-message.hook';

export interface ErrorPageProps {
  errorType: ERROR_TYPES;
}

export const FullScreenContainer = ({
  backgroundColor,
  children,
  labelledbyId,
  describedbyId,
}: {
  backgroundColor: SystemPropType<BackgroundColorSystemProp>;
  children: React.ReactNode;
  labelledbyId?: string;
  describedbyId?: string;
}) => (
  <Box
    role="dialog"
    width="100vw"
    height="100vh"
    position="fixed"
    top="0"
    left="0"
    zIndex="100"
    backgroundColor={backgroundColor}
    aria-labelledby={labelledbyId}
    aria-describedby={describedbyId}
  >
    {children}
  </Box>
);

const LabelledbyId = 'full-page-error-heading';
const describedbyId = 'full-page-error-desc';

export const ErrorPage = ({ errorType }: ErrorPageProps) => {
  const { headingId, bodyId, buttonId, onButtonClick, hideButton } =
    useErrorMessage(errorType);
  const imageSrc = handleStaticAsset('ERROR_STATE_COVER') as string;

  return (
    <FullScreenContainer
      labelledbyId={LabelledbyId}
      describedbyId={describedbyId}
      backgroundColor="surface.background.primary"
    >
      <Box
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <ErrorState
          imageSrc={imageSrc}
          headingId={headingId}
          bodyId={bodyId}
          buttonId={buttonId}
          hideButton={hideButton}
          onButtonClick={onButtonClick}
          labelledbyId={LabelledbyId}
          describedbyId={describedbyId}
        />
      </Box>
    </FullScreenContainer>
  );
};
