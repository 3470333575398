import React from 'react';
import {
  ImageMessage,
  GDSImageMessageProps,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';

const ImageAlignMap = {
  left: 'start',
  center: 'center',
  right: 'end',
};

type MasonryImageFooterNodeProperties = {
  imageUrl: string;
  imageAltText: string;
  imageHeight?: number;
  imageAlign?: keyof typeof ImageAlignMap;
};

export type MasonryImageFooterNode = MasonryEngineNode<
  WidgetType.IMAGE_FOOTER,
  MasonryImageFooterNodeProperties
>;

export type MasonryImageFooterRendererProps =
  MasonryEngineNodeRendererProps<MasonryImageFooterNode>;

export const MasonryImageFooterRenderer = ({
  imageUrl,
  imageAltText,
  imageHeight = 50,
  imageAlign = 'center',
}: MasonryImageFooterRendererProps) => (
  <ImageMessage
    image={imageUrl}
    imageAlt={imageAltText}
    imageHeight={imageHeight}
    imageHorizontalAlignment={
      (
        ImageAlignMap as Record<
          string,
          GDSImageMessageProps['imageHorizontalAlignment']
        >
      )[imageAlign]
    }
  />
);
