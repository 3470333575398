import React, { FC } from 'react';
import { UtilityText } from 'components/typography/utility-text/utility-text';
import { StackLayout } from 'components/stack-layout/stack-layout';
import type { GDSCustomizableComponent, GDSStatus } from '../../../types';
import { styled } from '../../../theme';
import {
  backgroundColorByStatus,
  iconTintByStatus,
} from '../status-color-tokens';

export interface GDSStatusBadgeProps extends GDSCustomizableComponent {
  status: GDSStatus;
  label: string;
  showLight?: boolean;
}

const StatusBadgeLight = styled('div', {
  width: 10,
  height: 10,
  borderRadius: '$circle',
  variants: {
    status: {
      info: {
        backgroundColor: iconTintByStatus.info,
      },
      success: {
        backgroundColor: iconTintByStatus.success,
      },
      warning: {
        backgroundColor: iconTintByStatus.warning,
      },
      error: {
        backgroundColor: iconTintByStatus.error,
      },
      brand: {
        backgroundColor: iconTintByStatus.brand,
      },
      neutral: {
        backgroundColor: iconTintByStatus.neutral,
      },
    },
  },
});

const StatusBadgeBase = styled('div', {
  display: 'inline-block',
  borderRadius: '$pill',
  variants: {
    status: {
      info: {
        backgroundColor: backgroundColorByStatus.info,
      },
      success: {
        backgroundColor: backgroundColorByStatus.success,
      },
      warning: {
        backgroundColor: backgroundColorByStatus.warning,
      },
      error: {
        backgroundColor: backgroundColorByStatus.error,
      },
      brand: {
        backgroundColor: backgroundColorByStatus.brand,
      },
      neutral: {
        backgroundColor: backgroundColorByStatus.neutral,
      },
    },
  },
});

export const StatusBadge: FC<GDSStatusBadgeProps> = ({
  status,
  label,
  showLight = true,
  className,
  css,
  ...props
}) => (
  <StatusBadgeBase
    className={['GDS-status-badge', className].join(' ')}
    status={status}
    {...props}
    css={css}
  >
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      spacing="$quarter"
      css={{ paddingLeft: '$half', paddingRight: '$half' }}
    >
      {showLight ? (
        <StatusBadgeLight className="GDS-status-badge-light" status={status} />
      ) : null}
      <UtilityText size="sm" emphasis="emphasized">
        {label}
      </UtilityText>
    </StackLayout>
  </StatusBadgeBase>
);

StatusBadge.displayName = 'StatusBadge';
