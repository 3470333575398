import { useQuery } from 'react-query';
import {
  getUserLeaguePointsRewardsBalance,
  GET_USER_LEAGUE_POINTS_REWARDS_BALANCE_MESSAGE_TYPE,
} from '@leagueplatform/rewards-api';
import { captureError } from '@leagueplatform/observability';

export const useRewardsSidebar = () => {
  const { isLoading, isError, data } = useQuery(
    [GET_USER_LEAGUE_POINTS_REWARDS_BALANCE_MESSAGE_TYPE],
    getUserLeaguePointsRewardsBalance,
    {
      onError: (error) => {
        captureError(
          new Error(`Unable to fetch user's rewards points balance: ${error}`),
        );
      },
    },
  );

  return {
    currentTierRewards: data?.current_tier,
    hasError: isError,
    nextTierRewards: data?.next_tier,
    ready: !isLoading,
    userRewardPoints: data?.league_points,
  };
};
