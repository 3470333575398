import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Flex, Box, SubtitleTwo } from '@leagueplatform/genesis-commons';
import {
  camelcaseCampaign,
  CampaignWithNextActivityWS,
} from 'types/configurable-health-program.types';
import { ConfigurableActivityCard } from '../configurable-activity-card/configurable-activity-card.component';
import { CampaignProgress } from '../campaign-progress/campaign-progress.component';

interface CampaignProgressCardProps {
  campaign: CampaignWithNextActivityWS;
}

export const CampaignProgressCard = ({
  campaign,
}: CampaignProgressCardProps) => {
  const { formatMessage } = useIntl();
  const camelcasedCampaign = camelcaseCampaign(campaign);
  const { program, nextActivity } = camelcasedCampaign;

  return (
    // TODO: switch to elevated card genesis core component
    <Flex
      as="li"
      flexDirection={{ _: 'column', tablet: 'row' }}
      borderRadius="large"
      boxShadow="card"
      padding="oneAndHalf"
      marginBottom="one"
    >
      <Box
        width={{ _: '100%', tablet: '50%', laptop: '40%' }}
        paddingRight={{ _: 'none', tablet: 'oneAndHalf' }}
        borderRightStyle={{ tablet: 'solid' }}
        borderRightColor={{ tablet: 'onSurface.border.subdued' }}
        borderRightWidth={{ tablet: 'thin' }}
      >
        <CampaignProgress program={program} />
      </Box>
      <Box
        width={{ _: '100%', tablet: '50%', laptop: '60%' }}
        paddingLeft={{ _: 'none', tablet: 'oneAndHalf' }}
      >
        <SubtitleTwo
          as="h4"
          color="onSurface.text.subdued"
          fontWeight="semibold"
          marginTop={{ _: 'one', tablet: 'quarter' }}
          marginBottom={{ _: 'half', tablet: 'one' }}
        >
          {formatMessage({ id: 'NEXT_ACTIVITY' })}
        </SubtitleTwo>
        {/*
            In lower environments, nextActivity sometimes gets returned as a null value.
            If we try to render a configurable activity card in that scenario, the page
            errors out, so we are defending against that scenario here.
            nextActivity should always be defined in prod.
        */}
        {nextActivity && (
          <ConfigurableActivityCard
            as="div"
            activity={nextActivity}
            noBottomMargin
            activityHeadingLevel="h5"
          />
        )}
      </Box>
    </Flex>
  );
};
