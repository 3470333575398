import { leagueFetch } from '@leagueplatform/league-fetch';
import { GET_MASONRY_NODE_ENDPOINT } from '../constants/masonry-node-api';
import { MasonryNodeResponse } from '../types/get-masonry-node';

export const getMasonryNode = async (
  nodeId: string,
): Promise<MasonryNodeResponse> => {
  const response = await leagueFetch(`${GET_MASONRY_NODE_ENDPOINT}${nodeId}`, {
    method: 'GET',
    headers: {
      // Prefer: `code=200, example=${nodeId}`,
      'X-League-Client-Platform': 'web',
      'X-League-Client-Time-Zone': ' america/Sao_Paulo',
      'X-League-Client-Version': '1.0',
      'X-League-Client-View-Size': 'desktop',
      // Un-comment this if using mock data
      // Prefer: `code=200, example=${nodeId}`,
    },
  });

  if (!response.ok) {
    throw new Error(`getMasonryNode error: ${response.status}`);
  }

  const responseJson: any = await response.clone().json();
  return responseJson;
};
