import React, { FC, forwardRef } from 'react';
import {
  Card,
  GDSCardElevatedProps,
  GDSCardClickAreaProps,
} from '@leagueplatform/genesis-core';

export interface ElevatedCardWithClickAreaProps extends GDSCardClickAreaProps {}

export interface ConditionalLinkCardRendererProps
  extends ElevatedCardWithClickAreaProps {
  hasLink?: boolean;
}

const ElevatedCardWithClickArea: FC<ElevatedCardWithClickAreaProps> =
  forwardRef(
    (
      {
        css,
        children,
        onCardMouseUp,
        onCardMouseDown,
        imagePosition,
      }: ElevatedCardWithClickAreaProps,
      ref: React.Ref<HTMLDivElement>,
    ) => (
      <Card.Elevated css={css}>
        <Card.ClickArea
          ref={ref}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          imagePosition={imagePosition}
          data-testid="clickable-card"
        >
          {children}
        </Card.ClickArea>
      </Card.Elevated>
    ),
  );

const ElevatedCard: FC<GDSCardElevatedProps> = forwardRef(
  (
    { css, imagePosition, children }: GDSCardElevatedProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <Card.Elevated ref={ref} imagePosition={imagePosition} css={css}>
      {children}
    </Card.Elevated>
  ),
);

export const ConditionalLinkCardRenderer: FC<ConditionalLinkCardRendererProps> =
  forwardRef(
    (
      {
        css,
        children,
        hasLink,
        onCardMouseUp,
        onCardMouseDown,
        imagePosition,
      }: ConditionalLinkCardRendererProps,
      ref: React.Ref<HTMLDivElement>,
    ) => {
      const ConditionalLinkCard = hasLink
        ? ElevatedCardWithClickArea
        : ElevatedCard;

      return (
        <ConditionalLinkCard
          ref={ref}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          css={css}
          imagePosition={imagePosition}
        >
          {children}
        </ConditionalLinkCard>
      );
    },
  );
