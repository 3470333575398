/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { useQuery } from 'react-query';
import {
  getUserHealthActivities,
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import {
  ACTIVITY_CAMPAIGN_TYPE,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  JOURNEY_MANAGER_ENABLED,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  deriveActivityStatusFromDate,
  normalizeEpochDate,
  sortByDate,
} from '../utils/user-health-activities/user-health-activities.utils';

const { ACTIVE, UPCOMING, COMPLETED, MISSED, EXPIRED, REMOVED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;

const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

// Activity filtering
const isMissed = ({ status }) => status === EXPIRED || status === REMOVED;
const isCompleted = ({ status }) => status === COMPLETED;
const isActiveUpcoming = ({ status }) =>
  status === ACTIVE || status === UPCOMING;
const isFrontOfLine = ({ front_of_the_line_activity, status }) =>
  isActiveUpcoming({ status }) && front_of_the_line_activity;
const isAssigned = ({ campaign_info, status }) =>
  isActiveUpcoming({ status }) && campaign_info.assigned === true;

const isActiveUpcomingChallengeActivity = ({ campaign_info, status }) =>
  isActiveUpcoming({ status }) && campaign_info?.campaign_type === CHALLENGE;
const filterByCampaign = (activity) =>
  isActiveUpcoming(activity) &&
  !isFrontOfLine(activity) &&
  !isActiveUpcomingChallengeActivity(activity) &&
  !isAssigned(activity);

// Activity sorting
/**
 * Sort an array of health activities by their `name` property
 * @returns An array of health activities sorted by their `name` property in ascending order
 */
const sortByActivityName = (a, b) => a?.name.localeCompare(b?.name);

/**
 * Sort an array of health activities by their `sort_order` property
 * @returns An array of health activities sorted by their `sort_order` property in ascending order
 */
// eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
const sortByActivitySortOrder = (a, b) => a?.sort_order - b?.sort_order;
const sortByCampaignStartDate = (
  { activities: [{ campaign_info: campaignInfoA }] },
  { activities: [{ campaign_info: campaignInfoB }] },
) => new Date(campaignInfoA.start_date) - new Date(campaignInfoB.start_date);

// Activity data-structure formatting
/**
 * Format an activity group object such that it can be sorted easily and is ready to be rendered without further processing
 * @param {Object} activityGroup An object whose keys represent the title for the activity group to be rendered, and whose values are a list of activities to be rendered
 * @returns An array of objects with the following structure `{title: string, activities: array}`
 */
const formatActivityGroup = (activityGroup) =>
  Object.entries(activityGroup).map(([title, activities]) => ({
    title,
    activities,
  }));

export const useGetUserHealthActivities = (
  activeDate = Date.now(),
  todaysDate = Date.now(),
) => {
  const normalizedActiveDateTimestamp = normalizeEpochDate(activeDate);
  const normalizedTodaysDate = normalizeEpochDate(todaysDate);
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );

  return useQuery(
    [GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE, normalizedActiveDateTimestamp],
    () =>
      getUserHealthActivities({
        date: normalizedActiveDateTimestamp / 1000,
        activityStatuses: deriveActivityStatusFromDate(
          normalizedActiveDateTimestamp,
        ),
      }),
    {
      select: (data) => {
        const { user_health_activities: allActivities } = data;

        let activitiesByAssigned = [];

        if (isJourneyManagerEnabled) {
          /**
           * Process assigned activities such that they are sorted and ready to be rendered
           */
          activitiesByAssigned = allActivities
            .filter(isAssigned)
            .sort(sortByActivitySortOrder);
        }

        /**
         * Process completed/missed activities such that activities for a given status are sorted and ready to be rendered
         */
        const activitiesByStatus = formatActivityGroup({
          [COMPLETED]: sortByDate(
            allActivities.filter(isCompleted),
            'completed_date',
          ),
          [MISSED]: allActivities.filter(isMissed).sort(sortByActivityName),
        });

        /**
         * Process front-of-the-line (FOTL) activities such that they are sorted and ready to be rendered
         */
        const activitiesByPriority = allActivities
          .filter(isFrontOfLine)
          .sort(sortByActivitySortOrder);

        const activitiesByChallenge = formatActivityGroup(
          allActivities
            .filter(isActiveUpcomingChallengeActivity)
            .sort(sortByActivitySortOrder)
            .reduce((challenges, activity) => {
              const { campaign_info } = activity;
              const { name: campaignName } = campaign_info;

              const challengeActivities = challenges?.[campaignName] ?? [];
              return {
                ...challenges,
                [campaignName]: [...challengeActivities, activity],
              };
            }, {}),
        );

        /**
         * Process active/upcoming activities such that both the campaigns and the activities within those campaigns are sorted and ready to be rendered
         */
        const activitiesByCampaign = formatActivityGroup(
          allActivities
            .filter(filterByCampaign)
            .sort(sortByActivitySortOrder)
            .reduce((campaigns, activity) => {
              const { campaign_info } = activity;
              const { name: campaignName } = campaign_info;

              const campaignActivities = campaigns?.[campaignName] ?? [];
              return {
                ...campaigns,
                [campaignName]: [...campaignActivities, activity],
              };
            }, {}),
        ).sort(sortByCampaignStartDate);
        const activitiesAllCompletedToday = () =>
          normalizedTodaysDate === normalizedActiveDateTimestamp &&
          allActivities.length > 0 &&
          allActivities.every(
            ({ status }) => status !== ACTIVE && status !== UPCOMING,
          );
        return {
          data: allActivities,
          activitiesByStatus,
          activitiesByAssigned,
          activitiesByCampaign,
          activitiesByPriority,
          activitiesByChallenge,
          hasCompletedAllActivities: activitiesAllCompletedToday(),
        };
      },
    },
  );
};
