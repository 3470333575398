import { useMemo } from 'react';
import { processActivityHelpfulTips } from 'utils/process-activity-helpful-tips.util';
import { useGetUserHealthActivity } from './use-get-user-health-activity.hook';
import { HealthActivityProps } from '../types/activity-details-props.types';
import { useActivityParams } from './use-activity-params.hook';

export const useHealthActivity = (): HealthActivityProps => {
  // Location Hooks
  const { userActivityId, campaignId, contentActivityId } = useActivityParams();

  // Data Hooks
  const {
    data: activityData = {},
    isLoading,
    isError,
  } = useGetUserHealthActivity(userActivityId, contentActivityId, campaignId);

  // API Properties
  const activity = useMemo(
    () => activityData?.health_activity || {},
    [activityData?.health_activity],
  );
  const redirectLink = activityData?.redirect_link;
  const ready = !isLoading;
  const hasError = isError;

  // Computed Properties
  const helpfulTips = useMemo(
    () => activity?.helpful_tips?.map(processActivityHelpfulTips),
    [activity?.helpful_tips],
  );

  return useMemo(
    () => ({
      activityId: activity?.id,
      contentActivityId: activity?.goal_id,
      activityPoints: activity?.activity_points,
      activityProgress: activity?.activity_verification_progress ?? {},
      activityTokens: activity?.activity_tokens,
      activityType: activity?.type,
      campaignInfo: activity?.campaign_info,
      campaignMode: activity?.custom_fields?.campaign_mode,
      cta: activity?.cta,
      description: activity?.description,
      disclaimerBanner: activity?.disclaimer_banner,
      endDate: activity?.end_date,
      hasError,
      helpfulTips,
      iconUrl: activity?.icon_url,
      informationBanner: activity?.information_banner,
      name: activity?.name,
      pointsEarned: activity?.points_earned,
      ready,
      redirectLink,
      references: activity?.references || '',
      richTextDescription: activity?.rich_text_description,
      startDate: activity?.start_date,
      status: activity?.status,
      suggestionBanner: activity?.suggestion_banner,
      tagline: activity?.tagline,
      tokensEarned: activity?.tokens_earned,
    }),
    [activity, hasError, helpfulTips, ready, redirectLink],
  );
};
