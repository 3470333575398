import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

export const selectActivityAnalytics = (
  activity: ConfigurableHealthActivityWSCC,
) => {
  const {
    campaignInfo,
    id,
    name,
    type,
    status,
    priority,
    detailLevel,
    activityProgressData,
  } = activity;

  const activityProgressDataGoalTotal =
    (activityProgressData?.length && activityProgressData[0].goalTotal) || 0;
  const activityProgressDataCurrentProgress =
    (activityProgressData?.length && activityProgressData[0].currentProgress) ||
    0;
  return trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: SCREEN_NAMES.ACTIVITY_TIMELINE,
    detail: 'view health campaign activity',
    health_campaign_name: campaignInfo?.name,
    health_campaign_id: campaignInfo?.campaignId,
    health_campaign_type: campaignInfo?.campaignType,
    health_campaign_activity_id: id,
    health_campaign_activity_name: name,
    health_campaign_activity_type: type,
    health_campaign_activity_status: status,
    health_campaign_activity_priority: priority,
    health_campaign_activity_composition: detailLevel,
    health_campaign_activity_goal: activityProgressDataGoalTotal,
    health_campaign_activity_current_progress:
      activityProgressDataCurrentProgress,
  });
};
