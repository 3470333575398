import * as React from 'react';
import { Switch, Route } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ENABLE_NEW_JOURNEY_EXPERIENCE } from '@leagueplatform/health-journey-common';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { HealthProgramsRoutes } from '@leagueplatform/health-programs';
import { ConfigurableJourneyExperience } from 'components/configurable-journey-experience/configurable-journey-experience.component';
import { HealthJourneyProgressSubroutes } from './health-progress-subroutes';
import { HealthJourneySubroutes } from './health-journey-subroutes';

export const HealthJourneyRoutes = () => {
  // New Journey Experience Feature Flag
  const {
    data: isNewJourneyExperienceEnabled,
    isLoading: isNewJourneyExperienceEnabledLoading,
  } = useFeatureFlagQuery(ENABLE_NEW_JOURNEY_EXPERIENCE);

  if (isNewJourneyExperienceEnabledLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <Switch>
        {/*
          If the new configurable journey experience is enabled for the user (e.g. isConfigurableJourneyExperience is true), we want to render the new
          configurable journey experience when matching the following exact routes:
            - /health-journey
            - /health-progress
            - /health-programs

          Any subroutes can be added in their respective routes files below
        */}
        {isNewJourneyExperienceEnabled && (
          // each of these paths correspond to a tab in the new journey experience
          <Route
            path={[
              getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
              getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
              getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
              getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
            ]}
            exact
          >
            <ConfigurableJourneyExperience />
          </Route>
        )}
        <Route path={getModulePath(LEAGUE_MODULE_NAMES.healthPrograms)}>
          <HealthProgramsRoutes />
        </Route>
        <Route path={getModulePath(LEAGUE_MODULE_NAMES.healthProgress)}>
          <HealthJourneyProgressSubroutes />
        </Route>
        <Route path={getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}>
          <HealthJourneySubroutes />
        </Route>
      </Switch>
    </React.Suspense>
  );
};
