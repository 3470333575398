import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

// Removes non-numerics, and characters after the decimal separator
export const sanitizeValue = (value, decimalSeparator, isNegativeAllowed) => {
  if (isNil(value)) return value;
  let sanitizedValue = `${value}`;
  let centsValue;
  let dollarsValue;

  // Check if there is a decimal in the number
  if (sanitizedValue.indexOf(decimalSeparator) !== -1) {
    // if there IS a decimal, separate the string into two parts, the dollars (pre decimal)
    // and the cents (decimal and onward) then strip out non-numerical characters
    centsValue = sanitizedValue
      .substr(sanitizedValue.indexOf(decimalSeparator))
      .replace(/\D/g, '');
    dollarsValue = sanitizedValue
      .substring(0, sanitizedValue.indexOf(decimalSeparator))
      .replace(/\D/g, '');
  } else {
    // strip non-numerical characters
    dollarsValue = sanitizedValue.replace(/\D/g, '');
  }

  sanitizedValue = centsValue ? `${dollarsValue}.${centsValue}` : dollarsValue;
  return sanitizedValue.length
    ? parseFloat(
        isNegativeAllowed && value < 0 ? -sanitizedValue : sanitizedValue,
      )
    : null;
};
