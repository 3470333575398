import React, { FC } from 'react';
import { SkeletonBase } from './skeleton-base';
import type { GDSSkeletonBaseProps } from './skeleton-base';
import { styled } from '../../theme';
import type { GDSResponsiveProp } from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';

export interface GDSSkeletonTextProps
  extends Omit<GDSSkeletonBaseProps, 'height'> {
  lines?: number;
  header?: boolean;
  alignment?: GDSResponsiveProp<'left' | 'right' | 'center'>;
}

const LINE_WIDTHS = [100, 93, 81, 55, 66, 99, 88];
const LAST_LINE = 35;

type GDSGenerateLines = Omit<GDSSkeletonTextProps, 'alignment'>;

const generateLines = ({
  lines = 1,
  header,
  width,
  borderRadius,
  css,
}: GDSGenerateLines) => {
  const result = [];
  for (let index = 0; index < lines; index += 1) {
    const lineWidth =
      width ||
      (lines > 1 && index === lines - 1
        ? LAST_LINE
        : LINE_WIDTHS[index % LINE_WIDTHS.length]);

    result.push(
      <SkeletonBase
        css={css}
        key={index}
        height={header ? '$two' : '$one'}
        width={width || `${lineWidth}%`}
        borderRadius={borderRadius}
      />,
    );
  }
  return result;
};

const SkeletonTextWrapper = styled('div', {
  textAlign: '$$alignment',
  display: 'flex',
  gap: '$half',
  width: '100%',
  flexDirection: 'column',
  variants: {
    alignment: {
      left: { alignItems: 'start' },
      right: { alignItems: 'end' },
      center: { alignItems: 'center' },
    },
  },
});

export const SkeletonText: FC<GDSSkeletonTextProps> = ({
  lines = 1,
  header = false,
  alignment = 'left',
  borderRadius,
  width,
  css,
  ...props
}) => (
  <SkeletonTextWrapper alignment={useResponsiveProp(alignment)} {...props}>
    {generateLines({
      lines,
      header,
      width: useResponsiveProp(width),
      borderRadius,
      css,
    })}
  </SkeletonTextWrapper>
);

SkeletonText.displayName = 'SkeletonText';
