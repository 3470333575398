import {
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  CONFIGURABLE_ACTIVITY_PRIORITY,
} from '@leagueplatform/health-journey-common';
import { BoxProps } from '@leagueplatform/genesis-commons';

const { STANDARD, DETAILED } = CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
const { LOW } = CONFIGURABLE_ACTIVITY_PRIORITY;

const getMarginTop = (
  detail: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  priority: CONFIGURABLE_ACTIVITY_PRIORITY,
) => {
  if (detail === STANDARD) {
    if (priority === LOW) return 'oneAndHalf';
    return 'minusOne';
  }

  if (detail === DETAILED) {
    if (priority === LOW) return 'minusHalf';
    return 'minusTwoAndHalf';
  }

  return 'none';
};

export const getActivityImageStylingProps = (
  detail: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  priority: CONFIGURABLE_ACTIVITY_PRIORITY,
  displayMobileStandardComposition: 'none' | 'block',
) => {
  const boxStylingProps = {
    display: {
      _: detail === STANDARD ? displayMobileStandardComposition : 'block',
      tablet: 'block',
    },
    width: {
      _: '100%',
      tablet: detail === STANDARD ? '40%' : '60%',
    },
    marginTop: { _: 'none', tablet: getMarginTop(detail, priority) },
    marginBottom: detail === STANDARD ? 'oneAndHalf' : 'none',
    marginRight: detail === STANDARD ? 'oneAndHalf' : 'none',
    order: detail === STANDARD ? 2 : 1,
    textAlign: detail === STANDARD ? 'right' : 'auto',
  } as BoxProps;

  const imageStylingProps = {
    width: '100%',
    maxWidth: {
      _: 'auto',
      tablet: detail === STANDARD ? '300px' : 'auto',
    },
    height: '100%',
    maxHeight: {
      _: 'auto',
      tablet: detail === STANDARD ? '187px' : 'auto',
    },
    borderTopLeftRadius: 'large',
    borderBottomLeftRadius: {
      _: detail === STANDARD ? 'large' : 'none',
      tablet: 'large',
    },
    borderTopRightRadius: {
      _: 'large',
      tablet: detail === DETAILED ? 'none' : 'large',
    },
    borderBottomRightRadius: {
      _: detail === STANDARD ? 'large' : 'none',
      tablet: detail === DETAILED ? 'none' : 'large',
    },
  };

  return {
    boxStylingProps,
    imageStylingProps,
  };
};
