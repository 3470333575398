import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import {
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/rewards-achievements';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

import { configureActivityCardProgress } from 'utils/configure-activity-card-progress.util';
import { getActivityDisplayValues } from './utils/get-configurable-activity-card-details.utils';
import { ActivityCardPriorityBanner } from './activity-card-priority-banner.component';
import { ActivityCardContent } from './activity-card-content.component';
import { ActivityCardImage } from './activity-card-image.component';
import { CardContainer } from '../card-container/card-container';

export interface ConfigurableActivityCardProps {
  as?: string;
  activity: ConfigurableHealthActivityWSCC;
  noBottomMargin?: boolean;
  height?: string;
  activityHeadingLevel: 'h2' | 'h3' | 'h4' | 'h5';
}

const { SIMPLE, DETAILED } = CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;

export const ConfigurableActivityCard = ({
  as = 'li',
  activity,
  noBottomMargin,
  height = 'auto',
  activityHeadingLevel,
}: ConfigurableActivityCardProps) => {
  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);
  const {
    activityImage,
    detailLevel: detail,
    id: activityId,
    priority,
    tagline,
    type,
  } = activity;
  const { isProgressActivity } = configureActivityCardProgress(activity);
  const { displayActivityImage, activityCardPriorityBannerDisplayValue } =
    getActivityDisplayValues(activity, isAchievementsEnabled);

  const activityDetailsPath = JOURNEY_ROUTES.getActivityDetails(
    activityId,
    type,
  );

  return (
    <CardContainer
      as={as}
      position="relative"
      flexDirection="column"
      marginBottom={noBottomMargin ? 'none' : 'one'}
      height={height}
    >
      <ActivityCardPriorityBanner
        activityId={activityId}
        priority={priority}
        detail={detail}
        tagline={tagline}
        hasImage={!!activityImage}
        display={{
          _:
            detail === DETAILED
              ? 'none'
              : activityCardPriorityBannerDisplayValue,
          tablet: activityCardPriorityBannerDisplayValue,
        }}
        isProgressActivity={isProgressActivity}
      />
      <Flex
        flexDirection={{
          _: 'column',
          tablet: detail === SIMPLE ? 'column' : 'row',
        }}
      >
        <ActivityCardContent
          activity={activity}
          activityDetailsPath={activityDetailsPath}
          activityHeadingLevel={activityHeadingLevel}
        />
        {activityImage && displayActivityImage && (
          <ActivityCardImage
            priority={priority}
            detail={detail}
            activityImage={activityImage}
            displayMobileStandardComposition="none"
          />
        )}
      </Flex>
    </CardContainer>
  );
};
