import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { ChallengeTypes } from '@leagueplatform/health-journey-api';
import { ChallengesHealthProgramsCardData } from 'types/health-programs-response-data.type';
import { AllChallengesList } from 'components/all-challenges-list.component';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';

interface ChallengeByTypeContentProps {
  challengeType: ChallengeTypes;
  programs: ChallengesHealthProgramsCardData[];
}
export const ChallengeByTypeContent = ({
  challengeType,
  programs,
}: ChallengeByTypeContentProps) => {
  const title = `VIEW_ALL_${challengeType.toUpperCase()}_CHALLENGES_HEADING`;
  const description = `VIEW_ALL_CHALLENGES_DESCRIPTION_${challengeType.toUpperCase()}`;
  const { formatMessage } = useIntl();
  return (
    <>
      <HeadingText
        size="xxl"
        emphasis="base"
        level="2"
        css={{ paddingBottom: '$half' }}
      >
        {formatMessage({
          id: title,
        })}
      </HeadingText>
      <ParagraphText size="base" emphasis="base">
        {formatMessage({
          id: description,
        })}
      </ParagraphText>
      <AllChallengesList programs={programs} />
    </>
  );
};
