import { useQuery } from 'react-query';
import {
  getUserStandaloneActivities,
  GET_USER_STANDALONE_ACTIVITIES_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import camelcaseKeys from 'camelcase-keys';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

export interface GetUserStandaloneActivitiesReturn {
  userHealthActivities: ConfigurableHealthActivityWSCC[];
}
export const useGetUserStandaloneActivities = ({ limit }: { limit: number }) =>
  useQuery<GetUserStandaloneActivitiesReturn>(
    [GET_USER_STANDALONE_ACTIVITIES_MESSAGE_TYPE, limit],
    () => getUserStandaloneActivities(limit),
    {
      select: (data) => camelcaseKeys(data, { deep: true }),
    },
  );
