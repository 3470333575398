import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import {
  BackButtonArrow,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  Box,
  HeadingText,
  SkeletonRectangle,
  SkeletonText,
  StackItem,
  StackLayout,
  Tabs,
} from '@leagueplatform/genesis-core';
import {
  CHALLENGE_TYPE_GROUP,
  CHALLENGE_TYPE_SOLO,
  CHALLENGE_TYPES,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';
import { useGetChallengesByType } from 'hooks/use-get-challenges-by-type.hook';
import { AllChallengesByTypeTabs } from './all-challenges-by-type-tabs.component';
import { ChallengeByTypeContent } from './challenge-by-type-content.component';

const noopFunction = () => {};
export interface BasePageProps {
  children: React.ReactChild;
}
const pageContainerStyles = {
  padding: '$one',
  maxWidth: '1205px',
  width: '100%',
  margin: '0 auto',
};
const BasePage = ({ children }: BasePageProps) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="center"
      css={pageContainerStyles}
    >
      <StackItem>
        <BackButtonArrow
          as={Link}
          marginRight="one"
          marginTop="two"
          onClick={noopFunction}
          to={JOURNEY_ROUTES.programsHome}
        />
      </StackItem>
      <StackItem css={{ width: '100%' }}>
        <StackLayout
          css={{
            marginTop: '$two',
            marginBottom: '$oneAndHalf',
          }}
          orientation="horizontal"
        >
          <HeadingText size="xl" level="1" emphasis="base">
            {formatMessage({
              id: `CHALLENGES`,
            })}
          </HeadingText>
        </StackLayout>
        {children}
      </StackItem>
    </StackLayout>
  );
};

export const AllChallengesByType = () => {
  const {
    challenges: soloChallenges,
    isLoading: soloChallengesLoading,
    isError: soloChallengesError,
  } = useGetChallengesByType(CHALLENGE_TYPE_SOLO);

  const {
    challenges: groupChallenges,
    isLoading: groupChallengesLoading,
    isError: groupChallengesError,
  } = useGetChallengesByType(CHALLENGE_TYPE_GROUP);

  const skeletonCardsList = Array.from({ length: 3 }, (_, index) => (
    <Box
      key={index}
      as="li"
      css={{
        margin: '$one 0',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        padding: '$two 0',
        '@mobile': {
          width: '100%',
        },
        '@mobileLandscape': { width: 'calc(50% - 13px)' },
        '@tablet': { width: 'calc(50% - 13px)' },
        '@laptop': { width: 'calc(33% - 13px)' },
        '@desktop': { width: 'calc(33% - 13px)' },
        '&>div': { width: '100%' },
      }}
    >
      <SkeletonRectangle
        height="235px"
        css={{
          '@mobile': { height: '111px' },
          '@laptop': {
            width: '384px',
            height: '190px',
          },
          marginBottom: '$half',
        }}
      />
      <SkeletonText lines={4} />
    </Box>
  ));

  if (groupChallengesLoading || soloChallengesLoading) {
    return (
      <div data-testid="skeleton-loader">
        <BasePage>
          <StackItem
            css={{
              width: '100%',
            }}
          >
            <StackLayout
              css={{
                marginTop: '$quarter',
                marginBottom: '$oneAndHalf',
              }}
              orientation="vertical"
              spacing="$one"
            >
              <SkeletonText header width="200px" />
              <SkeletonText width="70%" />
              <StackLayout
                css={{
                  flexWrap: 'wrap',
                  width: '100%',
                }}
                as="ul"
                orientation="horizontal"
                horizontalAlignment="start"
                spacing="$oneAndHalf"
              >
                {skeletonCardsList}
              </StackLayout>
            </StackLayout>
          </StackItem>
        </BasePage>
      </div>
    );
  }
  const isErrorState = soloChallengesError || groupChallengesError;
  if (isErrorState)
    return (
      <StackLayout
        horizontalAlignment="stretch"
        verticalAlignment="spaceAround"
        css={{ width: '100%', marginTop: '$three' }}
        data-testid="error-state"
      >
        <ErrorState />
      </StackLayout>
    );
  const showTabs = !!soloChallenges?.length && !!groupChallenges?.length;
  const singleViewPageChallengeType = soloChallenges?.length
    ? CHALLENGE_TYPE_SOLO
    : CHALLENGE_TYPE_GROUP;
  return (
    <BasePage>
      {showTabs ? (
        <Tabs.Root defaultValue={CHALLENGE_TYPES[0]}>
          <AllChallengesByTypeTabs />
          {CHALLENGE_TYPES.map((challengeType) => (
            <Tabs.TabPanel
              key={`${challengeType}_tab_panel`}
              value={challengeType}
            >
              <ChallengeByTypeContent
                programs={
                  challengeType === CHALLENGE_TYPE_SOLO
                    ? soloChallenges
                    : groupChallenges
                }
                challengeType={challengeType}
              />
            </Tabs.TabPanel>
          ))}
        </Tabs.Root>
      ) : (
        <ChallengeByTypeContent
          programs={
            singleViewPageChallengeType === CHALLENGE_TYPE_SOLO
              ? soloChallenges
              : groupChallenges
          }
          challengeType={singleViewPageChallengeType}
        />
      )}
    </BasePage>
  );
};
