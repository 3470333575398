import React, {
  FC,
  useMemo,
  useCallback,
  Suspense,
  useRef,
  useEffect,
} from 'react';
import {
  Button,
  GDSStyleObject,
  StackLayout,
  InputStatusMessage,
  useTheme,
} from '@leagueplatform/genesis-core';
import { ConfettiAnimation, LottieAnimationItem } from '@leagueplatform/lottie';
import { ProgressTrackingAttributes } from '@leagueplatform/health-journey-api';
import { ProgressTrackerChart } from 'components/progress-tracker-chart.component';
import {
  HealthActivityModalTypes,
  ToolboxCommonInputProps,
} from 'pages/health-activity/types/health-activity.types';
import { useControlledInput, useFormContext } from '@leagueplatform/web-common';
import { useActivityModals } from 'pages/health-activity/hooks/use-activity-modals.hook';
import { EditProgressCountModalProps } from 'components/edit-progress-count-modal.component';
import {
  TRANSITIONS,
  TRANSITION_DURATION_MS,
} from 'pages/health-activity/constants/health-activity-design.constants';
import { useIntl } from '@leagueplatform/locales';
import { useToolboxErrorFocus } from 'pages/health-activity/hooks/use-toolbox-error-focus.hook';
import { ActivityToolboxInputWrapper } from '../activity-toolbox-input-wrapper.component';

const { EditComponentProgress } = HealthActivityModalTypes;

const progressTrackerChartCss: GDSStyleObject = {
  $$spacing: '0',
  path: { transition: `stroke-dashoffset ${TRANSITIONS.PAGE}` },
};

export interface ProgressTrackingProps
  extends ToolboxCommonInputProps,
    ProgressTrackingAttributes {}

const useProgressTracking = ({
  componentId,
  currentProgress: initialProgress,
  goal,
  unit,
  validation,
}: ProgressTrackingProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { watch } = useFormContext();
  const { currentProgressLimit, customWarning, editable = true } = validation;

  const inputOptions = useMemo(
    () => ({
      disabled: !editable,
      max: {
        value: currentProgressLimit,
        message:
          customWarning ||
          formatMessage(
            { id: 'TOOLBOX_VALIDATION_NUMBER_MAXIMUM' },
            { value: formatNumber(currentProgressLimit) },
          ),
      },
    }),
    [
      currentProgressLimit,
      customWarning,
      editable,
      formatMessage,
      formatNumber,
    ],
  );

  const { field, inputValidationState } = useControlledInput({
    name: componentId,
    defaultValue: initialProgress,
    rules: inputOptions,
  });

  const currentProgress = watch(componentId, initialProgress);
  const progressPercentage = useMemo(
    () => Math.min(Math.floor((currentProgress / goal) * 100), 100),
    [currentProgress, goal],
  );
  const onSave = useCallback((value) => field.onChange(value), [field]);

  const editCountModalProps: Omit<EditProgressCountModalProps, 'onClose'> =
    useMemo(
      () => ({
        altText: formatMessage({ id: 'ACTIVITY_COUNT_EDIT' }, { unit }),
        heading: unit,
        initialValue: currentProgress,
        name: componentId,
        onSave,
        inputOptions,
        subtitle: formatMessage({ id: 'ACTIVITY_COUNT_GOAL' }, { goal, unit }),
      }),
      [
        componentId,
        currentProgress,
        formatMessage,
        goal,
        unit,
        onSave,
        inputOptions,
      ],
    );

  return {
    currentProgress,
    progressPercentage,
    editCountModalProps,
    inputValidationState,
  };
};

export const ActivityToolboxProgressTracking: FC<ProgressTrackingProps> = (
  props,
) => {
  const { colors } = useTheme();
  const { activeModal, setActiveModal } = useActivityModals();
  const { componentId, unit, goal, editButtonLabel, css, validation } = props;
  const { editable = true } = validation;
  const {
    currentProgress,
    progressPercentage,
    editCountModalProps,
    inputValidationState,
  } = useProgressTracking(props);
  const { inputStatus, statusMessage } = inputValidationState;

  // Refs
  const celebrationAnimationRef = useRef<LottieAnimationItem>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  // Flags
  const hasValidationError = inputStatus && statusMessage;
  const isGoalReached = currentProgress >= goal;
  const isEditing = activeModal?.type === EditComponentProgress;
  const shouldPlayCelebration = useMemo(() => {
    const { current: animation } = celebrationAnimationRef;

    // Reset the play count if the animation has loaded and the user drops below the goal
    if (!isGoalReached && animation) {
      animation.playCount = 0;
    }

    return (
      animation && // The animation has loaded
      isGoalReached && // The goal has been reached
      !animation?.playCount && // The animation has not yet played
      editable && // Input is not disabled
      !hasValidationError && // There is no validation error
      !isEditing // User is not currently editing
    );
  }, [editable, hasValidationError, isEditing, isGoalReached]);

  // Handlers
  const handleClickEdit = () =>
    setActiveModal(EditComponentProgress, editCountModalProps);

  // Effects
  useToolboxErrorFocus({
    name: componentId,
    focusTarget: editButtonRef.current,
  });

  const mapValueToProps = useCallback(
    (value, component) => {
      Object.assign(component.componentAttributes, {
        currentProgress,
        progressPercentage,
      });
      return component;
    },
    [currentProgress, progressPercentage],
  );

  useEffect(() => {
    const { current: animation } = celebrationAnimationRef;
    if (shouldPlayCelebration && animation) {
      setTimeout(() => {
        animation.goToAndPlay(0);
        animation.playCount += 1;
      }, TRANSITION_DURATION_MS.XLONG);
    }
  }, [shouldPlayCelebration]);

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <StackLayout
        horizontalAlignment="center"
        spacing="$one"
        css={{ position: 'relative', ...css }}
        data-testid={componentId}
      >
        <Suspense fallback={null}>
          <ConfettiAnimation
            animationRef={celebrationAnimationRef}
            css={{
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pointerEvents: 'none',
              svg: {
                width: '900px',
                path: {
                  '&[fill="rgb(220,210,246)"]': {
                    fill: colors.decorativeBrandSecondaryDefault.value,
                    stroke: colors.decorativeBrandSecondaryDefault.value,
                  },
                  '&[fill="rgb(185,164,237)"]': {
                    fill: colors.decorativeBrandPrimaryDefault.value,
                    stroke: colors.decorativeBrandPrimaryDefault.value,
                  },
                  '&[fill="rgb(229,207,115)"]': {
                    fill: colors.decorativeBrandSecondaryBrightest.value,
                    stroke: colors.decorativeBrandSecondaryBrightest.value,
                  },
                },
                opacity: Number(shouldPlayCelebration),
                transition: `opacity 0.2s ease-out ${TRANSITION_DURATION_MS.XLONG}ms`, // NOTE:  delay the animation to sync it with the setTimeout,
              },
            }}
          />
        </Suspense>
        <ProgressTrackerChart
          unit={unit}
          goal={goal}
          currentProgress={currentProgress}
          progressPercentage={progressPercentage}
          css={progressTrackerChartCss}
        />
        <Button
          size="small"
          priority="primary"
          onClick={handleClickEdit}
          css={{ textTransform: 'uppercase' }}
          ref={editButtonRef}
          disabled={!editable}
        >
          {editButtonLabel}
        </Button>
        {hasValidationError && (
          <InputStatusMessage
            id={`${componentId}-input-status`}
            inputStatus={inputStatus}
          >
            {statusMessage}
          </InputStatusMessage>
        )}
      </StackLayout>
    </ActivityToolboxInputWrapper>
  );
};
