import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Common Dependencies
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { HealthActivityDeserializedData } from '@leagueplatform/health-journey-api';
import { UserHealthActivityStatus } from '@leagueplatform/health-journey-common';
import { isProduction } from '@leagueplatform/app-environment';

// Hooks
import { useCompleteUserHealthActivity } from 'hooks/use-complete-user-health-activity.hook';
import { useDismissUserHealthActivity } from 'hooks/use-dismiss-user-health-activity.hook';
import { useActivityModals } from './hooks/use-activity-modals.hook';
import { useActivityLink } from './hooks/use-activity-link.hook';
import { useSetToolboxInteractionHub } from './hooks/use-set-toolbox-interaction-hub';
import {
  OnNextPage,
  useActivityNavigation,
} from './hooks/use-activity-navigation.hook';

// Common Components

// Local Components
import { HealthActivityLayout } from './components/health-activity-layout.component';
import {
  HealthActivitySlide,
  HealthActivitySlider,
} from './components/health-activity-slider.component';
import { HealthActivityContent } from './components/health-activity-content.component';
import { HealthActivityFooter } from './components/activity-footer/health-activity-footer.component';
import { ToolboxComponentResolver } from './components/toolbox-component-resolver.component';
import { HealthActivityModalController } from './components/activity-modal-controller.component';
import { ActivityCloseButton } from './components/buttons/activity-close-button.component';
import { ActivityRemoveButton } from './components/buttons/activity-remove-button.component';
import { ActivityToolboxStatusOverlay } from './components/activity-toolbox-status-overlay.component';

// Types
import { HealthActivityModalTypes } from './types/health-activity.types';
import { HealthActivityContext } from './contexts/health-activity-provider.component';

interface HealthActivityProps {
  healthActivityData: HealthActivityDeserializedData;
}

// Constants
const { Exit, ApiDelay, Warning, Remove, None } = HealthActivityModalTypes;
const { ACTIVE, UPCOMING } = UserHealthActivityStatus;

export const HealthActivity: FC<HealthActivityProps> = ({
  healthActivityData,
}) => {
  /* Contexts */
  const { healthActivityAnalytics, healthAssessmentManager } = useContext(
    HealthActivityContext,
  );

  /* Hooks */
  const { formatMessage } = useIntl();
  const [isPageTransitioning, setIsPageTransitioning] = useState(false);

  // Activity
  const {
    pages = [],
    id: userActivityId,
    status,
    removable,
    warning,
    name: activityName,
    completeOverlay: apiDelayConfig,
  } = healthActivityData;

  // Activity Functionality
  const {
    currentPage,
    pageHistory,
    skippedPages,
    isLastPage,
    navigateActivity,
  } = useActivityNavigation({
    healthActivityData,
  });
  const {
    mutateAsync: completeActivity,
    isLoading: isLoadingCompleteActivity,
  } = useCompleteUserHealthActivity(healthActivityData?.id, apiDelayConfig);
  const { mutate: removeActivity } = useDismissUserHealthActivity(
    healthActivityData?.id,
  );
  const { setActiveModal } = useActivityModals();
  const { followLink, returnToEntrypoint } = useActivityLink();

  // Assessments
  const {
    registerForm,
    validatePages,
    validatePage,
    getQuestionsForPages,
    getQuestionsForPage,
  } = healthAssessmentManager;

  const saveToolboxResponsesWithHub = useSetToolboxInteractionHub(
    userActivityId,
    healthActivityData?.userHealthCampaign?.data?.id, // userHealthCampaignId
  );
  const saveInputMutationConfig = {
    onError() {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_STATE_TITLE',
        contentId: 'ANSWERS_NOT_SAVED',
      });
    },
  };

  // Effects
  useEffect(() => {
    healthActivityAnalytics?.sendViewPage(currentPage + 1);

    return () => {
      setActiveModal(None);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Computed Properties
  const totalPages = pages.length;
  const { callToAction } = pages[currentPage] ?? {};
  const { text: ctaText, url } = callToAction ?? {};
  const ctaUrl = url?.trim();
  const { isActive, isRemovable, isInteractionDisabled } = useMemo(
    () => ({
      isActive: status === ACTIVE,
      isRemovable: status === ACTIVE && removable,
      isInteractionDisabled: status === UPCOMING,
    }),
    [status, removable],
  );
  const isProd = isProduction();
  const deriveCtaText = () =>
    isLastPage && !isActive ? formatMessage({ id: 'CLOSE' }) : ctaText;

  useDocumentTitle(
    `${activityName} - ${formatMessage(
      { id: 'PAGE_OF_TOTAL' },
      { current: currentPage + 1, total: totalPages },
    )}`,
  );

  // Handlers
  const handleNextPage: OnNextPage = async ({ nextPage, goToNextPage }) => {
    if (isInteractionDisabled || isPageTransitioning) return;

    if (ctaUrl) {
      followLink(ctaUrl);
      return;
    }

    // Prevent further action if any of the user's inputs are invalid...
    const isCurrentPageValid = await validatePage(currentPage);
    if (!isCurrentPageValid) return;

    const currentPageQuestions = getQuestionsForPage(currentPage);

    if (isCurrentPageValid) {
      if (!isProd) {
        // eslint-disable-next-line no-console
        console.log({ isCurrentPageValid, currentPageQuestions });
      }

      if (currentPageQuestions?.length) {
        saveToolboxResponsesWithHub.mutate({
          components: currentPageQuestions,
        });
      }

      goToNextPage();
      healthActivityAnalytics?.sendNextPage(nextPage);
    }
  };

  const handleLastPage = async () => {
    // Prevent further action if any of the user's inputs are invalid...
    const areVisitedPagesValid = await validatePages(pageHistory);
    if (!areVisitedPagesValid) return;

    const answeredQuestions = getQuestionsForPages(pageHistory, {
      includeCleanFields: isActive,
    });

    const skippedQuestions = getQuestionsForPages(skippedPages, {
      includeCleanFields: true,
    })
      .flatMap((component) => component?.componentStorageSources)
      .filter(Boolean);

    // Wait to persist the user's valid inputs before further action...
    if (answeredQuestions?.length) {
      if (!isProd) {
        // eslint-disable-next-line no-console
        console.log({
          areVisitedPagesValid,
          answeredQuestions,
          skippedQuestions,
        });
      }

      await saveToolboxResponsesWithHub.mutateAsync(
        {
          components: answeredQuestions,
          skippedComponents: skippedQuestions,
          completedActivity: true,
        },
        saveInputMutationConfig,
      );
    }

    // If the activity is active, we can now mark it as complete.
    if (isActive) {
      // Handle pages with no questions on it
      // @ts-ignore
      await completeActivity({ ctaUrl });
      healthActivityAnalytics?.sendCompleteActivity(deriveCtaText());
    } else {
      returnToEntrypoint();
    }
  };

  const onNext = () => {
    if (isLoadingCompleteActivity) {
      setActiveModal(ApiDelay);
    } else {
      navigateActivity.next({
        onNextPage: handleNextPage,
        onLastPage: handleLastPage,
      });
    }
  };

  const onPrev = () => {
    const prevPageIndex = navigateActivity.previous();
    healthActivityAnalytics?.sendPreviousPage(prevPageIndex);
  };

  const onActivityClose = useCallback(() => {
    healthActivityAnalytics?.sendCloseActivity?.(currentPage + 1);

    if (pages?.length <= 1 || !isActive) {
      returnToEntrypoint();
    } else if (warning) {
      setActiveModal(Warning, warning);
    } else {
      setActiveModal(Exit);
    }
  }, [
    currentPage,
    healthActivityAnalytics,
    isActive,
    pages?.length,
    returnToEntrypoint,
    setActiveModal,
    warning,
  ]);

  const onActivityRemove = () => {
    const props = {
      activityId: userActivityId,
      requestSetUserHealthActivityDelete: removeActivity,
    };
    setActiveModal(Remove, props);
  };

  return (
    <>
      <ActivityCloseButton onClick={onActivityClose} />
      {isRemovable && <ActivityRemoveButton onClick={onActivityRemove} />}
      <HealthActivityLayout>
        {/* Page Slider */}
        <HealthActivitySlider>
          {pages?.map(({ components }, pageIndex: number) => (
            <HealthActivitySlide
              // eslint-disable-next-line react/no-array-index-key
              key={`health-activity-page-${pageIndex}`}
              activeIndex={currentPage}
              gap="120%"
              slideIndex={pageIndex}
              onTransition={setIsPageTransitioning}
            >
              {/* Activity Page Content */}
              <HealthActivityContent>
                <ToolboxComponentResolver
                  components={components}
                  isVisible={currentPage === pageIndex}
                  baseComponentId={`${userActivityId}-${pageIndex}`}
                  onFormRegister={registerForm(pageIndex)}
                />
              </HealthActivityContent>
            </HealthActivitySlide>
          ))}
        </HealthActivitySlider>

        {/* Activity Footer */}
        <HealthActivityFooter
          totalPages={totalPages}
          currentPage={currentPage}
          isLastPage={isLastPage}
          ctaText={deriveCtaText()}
          onNext={onNext}
          onPrev={onPrev}
          disabled={isInteractionDisabled}
        />

        {isInteractionDisabled && (
          <ActivityToolboxStatusOverlay status={status} />
        )}
      </HealthActivityLayout>
      <HealthActivityModalController />
    </>
  );
};
