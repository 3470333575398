import React from 'react';
import { Card, useClickableCard } from '@leagueplatform/genesis-core';
import { WidgetType } from '@leagueplatform/masonry-api';
import {
  type MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { MasonryLargeCardContentRenderer } from './masonry-large-card-content-renderer.component';
import type { MasonryLargeCardContentProps } from './masonry-large-card-content-renderer.component';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

type MasonryLargeCardNodeProperties = MasonryLargeCardContentProps & {
  image: string;
  imageAltText?: string;
  isChildItem?: boolean;
};

export type MasonryLargeCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryLargeCardNode = MasonryEngineNode<
  WidgetType.LARGE_VISUAL_CARD,
  MasonryLargeCardNodeProperties,
  MasonryLargeCardNodeActions
>;

export type MasonryLargeCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryLargeCardNode>;

export const MasonryLargeCardRenderer = ({
  isChildItem,
  image,
  imageAltText,
  onClick,
  ...props
}: MasonryLargeCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(onClick?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      imagePosition={
        isChildItem
          ? 'top'
          : {
              '@initial': 'end',
              '@mobile': 'top',
            }
      }
      css={{
        height: isChildItem ? '100%' : 'auto',
      }}
    >
      <Card.Image
        image={image}
        imageAlt={imageAltText}
        css={{ '@mobile': { height: '176px' } }}
      />
      <Card.Section>
        <MasonryLargeCardContentRenderer
          linkRefState={primaryActionRef}
          onClick={onClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Card.Section>
    </ConditionalLinkCardRenderer>
  );
};
