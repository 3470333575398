import React, { FC, useMemo } from 'react';

// Common Dependencies
import {
  Box,
  Button,
  GDSButtonProps,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { isString } from 'lodash';

// Common Components
import { ViewTransitionController } from '@leagueplatform/health-journey-common';

// Constants
import { ActivityLocation } from 'pages/health-activity/hooks/use-activity-navigation.hook';
import {
  TRANSITIONS,
  TRANSITION_DURATION_MS,
} from '../../constants/health-activity-design.constants';
import { ActivityCircleButton } from '../buttons/activity-circle-button.component';

const { SHORT } = TRANSITION_DURATION_MS;
const { QUARTER_PAGE } = TRANSITIONS;

// Types
enum FooterButtonTypes {
  PrimaryCta = 'PrimaryCta',
  SecondaryCta = 'SecondaryCta',
  Check = 'Check',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}
const { PrimaryCta, SecondaryCta, Check, ArrowLeft, ArrowRight } =
  FooterButtonTypes;

interface ButtonProps
  extends Omit<GDSButtonProps<'button'>, 'children'>,
    Partial<ActivityLocation> {}

interface TransitionView {
  view: typeof Button | typeof ActivityCircleButton;
  props?: ButtonProps;
}

type TransitionViews = Partial<Record<FooterButtonTypes, TransitionView>>;

interface ButtonTransitionProps {
  views: TransitionViews;
  handleActiveView(): FooterButtonTypes | '';
  css?: GDSStyleObject;
}

// Static Styles
const BUTTON_TRANSITION = `background ${QUARTER_PAGE}, color ${QUARTER_PAGE}, border ${QUARTER_PAGE}`;
const buttonProps: Record<string, ButtonProps> = {
  primary: { priority: 'primary', css: { transition: BUTTON_TRANSITION } },
  secondary: { priority: 'secondary', css: { transition: BUTTON_TRANSITION } },
};

const ButtonTransition: FC<ButtonTransitionProps> = ({
  views,
  handleActiveView,
  css,
}) => (
  // TODO – ACTV-X: Refactor ViewTransitionController and remove the `Box` wrapping this component
  <Box css={css}>
    <ViewTransitionController
      views={views}
      handleActiveView={handleActiveView}
      duration={SHORT}
      transitionType="fade"
    />
  </Box>
);

export const LeftButton: FC<ButtonProps> = ({
  'aria-label': ariaLabel,
  currentPage,
  disabled,
  onClick,
  css,
}) => {
  const buttons: TransitionViews = {
    [ArrowLeft]: {
      view: ActivityCircleButton,
      props: {
        ...buttonProps.secondary,
        quiet: true,
        icon: 'interfaceArrowLeft',
        'aria-label': ariaLabel,
        disabled,
        onClick,
      },
    },
  };

  const handleActiveButton = () => {
    if (currentPage) return ArrowLeft;
    return '';
  };

  return (
    <ButtonTransition
      views={buttons}
      handleActiveView={handleActiveButton}
      css={css}
    />
  );
};

export const RightButton: FC<ButtonProps> = ({
  'aria-label': defaultAriaLabel,
  disabled,
  onClick,
  isLastPage,
  children,
  css,
}) => {
  const buttons: TransitionViews = useMemo(() => {
    const { primary, secondary } = buttonProps;
    const ariaLabel = [children, defaultAriaLabel].filter(isString).join(' - ');
    const commonProps = { onClick, disabled, 'aria-label': ariaLabel };
    const ctaProps = { ...commonProps, children };
    const iconProps = { ...commonProps, quiet: true };

    return {
      [PrimaryCta]: {
        view: Button,
        props: { ...primary, ...ctaProps },
      },
      [SecondaryCta]: {
        view: Button,
        props: { ...secondary, ...ctaProps },
      },
      [ArrowRight]: {
        view: ActivityCircleButton,
        props: { ...iconProps, ...secondary, icon: 'interfaceArrowRight' },
      },
      [Check]: {
        view: ActivityCircleButton,
        props: { ...iconProps, ...primary, icon: 'interfaceCheckmark' },
      },
    };
  }, [children, defaultAriaLabel, disabled, onClick]);

  const handleActiveButton = () => {
    if (children) return isLastPage ? PrimaryCta : SecondaryCta;
    if (isLastPage) return Check;
    return ArrowRight;
  };

  return (
    <ButtonTransition
      views={buttons}
      handleActiveView={handleActiveButton}
      css={css}
    />
  );
};
