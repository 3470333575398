/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEventHandler } from 'react';
import {
  styled,
  UtilityText,
  GDSUtilityTextProps,
  TextAction,
  GDSTextActionProps,
} from '@leagueplatform/genesis-core';
import { ComponentTypes } from '@leagueplatform/health-journey-common';
import { useToolboxComponentContext } from 'pages/health-activity/contexts/toolbox-component-provider.component';

export const Mark = ({
  as = 'mark',
  css,
  ...props
}: GDSUtilityTextProps<'mark'>) => (
  <UtilityText
    as={as}
    emphasis="emphasized"
    css={{ backgroundColor: '$decorativeBrandPrimaryPastel', ...css }}
    {...props}
  />
);

export const Bold = ({ as = 'b', ...props }: GDSUtilityTextProps<'b'>) => (
  <UtilityText as={as} emphasis="emphasized" {...props} />
);

export const Italic = ({
  as = 'i',
  css,
  ...props
}: GDSUtilityTextProps<'i'>) => (
  <UtilityText as={as} css={{ fontStyle: 'italic', ...css }} {...props} />
);

export const Link = ({ onClick, ...props }: GDSTextActionProps<'a'>) => {
  const { actions } = useToolboxComponentContext(ComponentTypes.RichText);
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    onClick?.(event);
    actions?.onLinkOut?.(event);
  };
  return (
    <TextAction
      {...props}
      css={{ fontSize: 'inherit' }}
      target="_blank"
      onClick={handleClick}
    />
  );
};

export const Blockquote = styled('blockquote', {
  display: 'flex',
  typography: '$bodyOne',
  '&::before': {
    flexShrink: 0,
    marginRight: '$oneAndHalf',
    content: '',
    width: '10px',
    borderRadius: '$extraLarge',
    backgroundColor: '$decorativeBrandPrimaryBrightest',
  },
});

export const Image = styled('img', {
  maxWidth: '100%',
  maxHeight: '66vh',
  width: 'fit-content',
});
