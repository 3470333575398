import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from '@leagueplatform/routing';
import {
  genesisStyled,
  Image,
  Link,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';

const ActivityLink = genesisStyled(Link)(
  ({ theme: { shadows, space, radii, colors } }) => `
  &:after {
    border: solid 1px transparent;
    border-radius: ${radii.large}px;
    bottom: -${space.half}px;
    box-shadow: none;
    content: "";
    display: block;
    left: -${space.half}px;
    position: absolute;
    right: -${space.half}px;
    top: -${space.quarter}px;
    transition: border-color 150ms, box-shadow 150ms;
    z-index: -1;
  }
  &:hover {
    text-decoration: none;
    &:after {
      border-color: ${colors.onSurface.border.subdued};
      box-shadow: ${shadows.card};
    }
  }
`,
);

export const SuggestedActivitiesCard = ({ name, image, path, onClick }) => (
  <ActivityLink
    as={RouterLink}
    display="block"
    height="100%"
    paddingY="quarter"
    position="relative"
    to={path}
    onClick={onClick}
  >
    <Image
      alt=""
      borderRadius="medium"
      marginBottom="half"
      maxWidth="100%"
      src={image}
    />
    <SubtitleOne as="p">{name}</SubtitleOne>
  </ActivityLink>
);

SuggestedActivitiesCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

SuggestedActivitiesCard.defaultProps = {
  name: '',
  path: '',
  image: '',
  onClick: null,
};
