import { useEffect, useMemo, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useQuery, useQueryClient } from 'react-query';

const ACTIVITY_FOOTER_ERROR_MESSAGE_TYPE = 'activity_footer_error_message';
const queryFnStub = () => undefined;

export const useActivityFooterErrorMessage = (healthActivity) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { data: errorMessage } = useQuery(
    [ACTIVITY_FOOTER_ERROR_MESSAGE_TYPE],
    queryFnStub,
  );

  const applyMessage = useCallback(
    (messageKey) => {
      const message = messageKey
        ? formatMessage({ id: messageKey })
        : undefined;
      queryClient.setQueryData([ACTIVITY_FOOTER_ERROR_MESSAGE_TYPE], message);
      return message;
    },
    [formatMessage, queryClient],
  );

  const applyErrorMessage = useMemo(
    () => ({
      goalIncomplete: () => applyMessage('GOAL_INCOMPLETE'),
      activityCompleteError: () => applyMessage('ACTIVITY_COMPLETE_ERROR'),
      none: () => applyMessage(undefined),
    }),
    [applyMessage],
  );

  // Reset error message when activity progress changes and on unmount
  useEffect(() => {
    applyErrorMessage.none();
    return () => applyErrorMessage.none();
  }, [healthActivity?.activityProgress, applyErrorMessage]);

  return { errorMessage, applyErrorMessage };
};
