import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedHTMLMessage } from '@leagueplatform/locales';
import {
  breakpoints,
  vars,
  Heading,
  Flex,
  Icon,
  ICONS,
  Box,
} from '@leagueplatform/ui-kit';
import { TOAST_STATUS } from './toast-message.constants';
import { ICON_TYPES, ToastIcon } from './icons/icon.component';
import { Toast } from '../toast.types';
import { FormattedMessage } from 'react-intl';

interface ToastMessageProps extends Toast {
  dismiss(messageId: Toast['messageId']): void;
  toastMessageMouseEntered(): void;
  toastMessageMouseLeft(): void;
}

interface StyleProps {
  type: Toast['type'];
  content?: string;
}

interface MessageStyleReturn {
  primary: string;
  secondary: string;
  icon: any;
}

const {
  greyLighter,
  greyLight,
  opal,
  red,
  lightGreen,
  lightRose,
  lightOrange,
  orange,
  lightYellow,
  darkYellow,
  purpleLightest,
  purpleLight,
  purpleDark,
  white,
  greyLightest,
  mediumGrey,
  black,
  blue,
  blueLightest,
  darkGrey,
} = vars.colour;

const { WARNING, INFORMATION } = ICONS;

const getMessageStyle = (type: Toast['type']): MessageStyleReturn => {
  switch (type) {
    case TOAST_STATUS.SUCCESS:
      return {
        primary: opal,
        secondary: lightGreen,
        icon: ICON_TYPES.SUCCESS,
      };
    case TOAST_STATUS.ERROR:
      return {
        primary: red,
        secondary: lightRose,
        icon: ICON_TYPES.ERROR,
      };
    case TOAST_STATUS.WARNING:
      return {
        primary: orange,
        secondary: lightOrange,
        icon: WARNING,
      };
    case TOAST_STATUS.NOTE:
      return {
        primary: purpleLight,
        secondary: purpleLightest,
        icon: INFORMATION,
      };
    case TOAST_STATUS.ATTENTION:
      return {
        primary: darkYellow,
        secondary: lightYellow,
        icon: WARNING,
      };
    case TOAST_STATUS.INFO:
      return {
        primary: blue,
        secondary: blueLightest,
        icon: ICON_TYPES.INFO,
      };
    case TOAST_STATUS.INVERTED:
      return {
        primary: white,
        secondary: purpleDark,
        icon: INFORMATION,
      };

    default:
      return {
        primary: mediumGrey,
        secondary: greyLightest,
        icon: INFORMATION,
      };
  }
};

const MessageWrapper = styled(Flex)`
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  border: 1px solid ${greyLighter};
  align-items: center;
  width: 40rem;
  pointer-events: auto;
  cursor: pointer;
  border-left: 8px solid
    ${(props: StyleProps) => getMessageStyle(props.type).primary};
  background-color: ${(props: StyleProps) =>
    getMessageStyle(props.type).secondary};
  color: ${(props: StyleProps) =>
    props.type === TOAST_STATUS.INVERTED
      ? getMessageStyle(props.type).primary
      : ''};
  margin-right: 5.5rem;
  @media ${breakpoints.belowLap} {
    margin: 0 auto;
  }
`;

const MessageIcon = styled(Flex)`
  background-color: ${(props: StyleProps) =>
    getMessageStyle(props.type).primary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem;

  > svg path {
    fill: ${(props: StyleProps) => getMessageStyle(props.type).secondary};
  }
`;

const ToastMessageIcon = styled(Flex)`
  margin-right: 1.2rem;
  flex-shrink: 0;
`;

const MessageHeading = styled(Heading)`
  margin: 0;
  line-height: 2.2rem;
  text-align: left;
`;

const MessageCloseButtonWrapper = styled.button<any>`
  position: absolute;
  top: ${(props: StyleProps) => (props.content ? '1rem' : '50%')};
  transform: translateY(
    ${(props: StyleProps) => (props.content ? 'none' : '-50%')}
  );
  right: 0.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  > svg path {
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus {
    > svg path {
      fill: ${(props: StyleProps) =>
        props.type === TOAST_STATUS.INVERTED ? greyLight : black};
    }
  }
`;

const MessageSubHead = styled.p`
  line-height: 2.2rem;
  font-size: 1.6rem;
`;

const renderIcon = (type: Toast['type']) => {
  // We have different icons for success, error and info
  switch (type) {
    case TOAST_STATUS.SUCCESS:
    case TOAST_STATUS.ERROR:
    case TOAST_STATUS.INFO:
      return (
        <ToastMessageIcon>
          <ToastIcon iconType={getMessageStyle(type).icon} />
        </ToastMessageIcon>
      );
    // return <img src={getMessageStyle(type).icon} alt="" />;
    default:
      return (
        <MessageIcon type={type}>
          <Icon icon={getMessageStyle(type).icon} size="1.2rem" />
        </MessageIcon>
      );
  }
};

export const ToastMessage = ({
  messageId,
  text,
  textId,
  type,
  values,
  content,
  contentId,
  dismiss,
  toastMessageMouseEntered,
  toastMessageMouseLeft,
}: ToastMessageProps) => {
  const { formatMessage } = useIntl();
  const flexProps = {
    justifyContent: 'flex-end',
    mt: 1,
    onClick: () => dismiss(messageId),
    onMouseEnter: toastMessageMouseEntered,
    onMouseLeave: toastMessageMouseLeft,
  };
  const messageWrapperProps = {
    type: type,
    p: 2,
  };
  const boxProps = {
    pl: 2,
    pr: [0, 0, 3],
  };

  return (
    <Flex {...flexProps}>
      <MessageWrapper {...messageWrapperProps}>
        {renderIcon(type)}
        <Box {...boxProps} aria-live="polite">
          {text || textId ? (
            <MessageHeading level={5}>
              {textId ? <FormattedMessage id={textId} values={values} /> : text}
            </MessageHeading>
          ) : null}
          {content || contentId ? (
            <MessageSubHead>
              {contentId ? (
                <FormattedHTMLMessage id={contentId} values={values} />
              ) : (
                content
              )}
            </MessageSubHead>
          ) : null}
        </Box>
        <MessageCloseButtonWrapper
          type={type}
          onClick={() => dismiss(messageId)}
          aria-label={formatMessage({ id: 'DISMISS_TOAST' })}
          content={content}
        >
          <Icon
            icon={ICONS.X}
            size="2rem"
            colour={
              type === TOAST_STATUS.INVERTED
                ? getMessageStyle(type).primary
                : darkGrey
            }
          />
        </MessageCloseButtonWrapper>
      </MessageWrapper>
    </Flex>
  );
};
