import { GDSStyleObject } from '@leagueplatform/genesis-core';
import { ComponentTypes } from '@leagueplatform/health-journey-common';

// Constants
const {
  FullPageHighlight,
  Header,
  IntegerInput,
  StringInput,
  MultipleSelectInput,
  DateInput,
  DecimalInput,
  ProgressTracking,
  Badge,
} = ComponentTypes;

// The `first-child` selector can cause issues with SSR and content-shifting. It also pollutes the console with warnings.
const safeFirstChildSelector = '&:first-of-type:not(:first-of-type ~ *)';

export const wrappedComponentLayout: GDSStyleObject = {
  paddingLeft: '$two',
  paddingRight: '$two',
  [safeFirstChildSelector]: {
    paddingTop: '$two',
  },
  '&:last-child': {
    paddingBottom: '$two',
  },
};

export const wrappedInputComponentStyles: GDSStyleObject = {
  ...wrappedComponentLayout,
  marginTop: '$two',
  // Remove margin top for the next input if it's the same type of element.
  '& + &': { marginTop: '0' },
};

export const wrappedBadgeComponentStyles: GDSStyleObject = {
  ...wrappedComponentLayout,
  [safeFirstChildSelector]: {
    paddingTop: '$two',
  },
};

export const fullBleedComponentLayout: GDSStyleObject = {
  padding: 'initial',
};

export const deriveComponentLayoutStyles = (componentType: ComponentTypes) => {
  switch (componentType) {
    case FullPageHighlight:
    case Header:
      return fullBleedComponentLayout;
    case IntegerInput:
    case StringInput:
    case MultipleSelectInput:
    case DateInput:
    case DecimalInput:
    case ProgressTracking:
      return wrappedInputComponentStyles;
    case Badge:
      return wrappedBadgeComponentStyles;
    default:
      return wrappedComponentLayout;
  }
};
