import React from 'react';
import { ProgressBar } from '@leagueplatform/web-common-components';
import {
  StackLayout,
  UtilityText,
  Icon,
  StackItem,
} from '@leagueplatform/genesis-core';

type ProgramProgressProps = {
  currentProgress?: number;
  currentText: string;
  progressImage?: string;
  pointsIcon?: string;
  suffixText?: string;
};

export const ProgramProgress = ({
  currentProgress,
  currentText,
  progressImage,
  pointsIcon,
  suffixText,
}: ProgramProgressProps) => {
  const showProgressBar = currentProgress !== undefined;

  // in the case where there is a suffix text prop supplied, the current text becomes styled as a 'prefix', and the suffix text retains the initial style.
  const StyledPrefix = (
    <>
      <UtilityText
        size="sm"
        emphasis="emphasized"
        css={{ color: '$onSurfaceTextSuccess' }}
      >
        {currentText}
      </UtilityText>
      <UtilityText size="sm">{suffixText}</UtilityText>
    </>
  );

  const StandardText = <UtilityText size="sm">{currentText}</UtilityText>;

  const displayText = suffixText ? StyledPrefix : StandardText;
  return (
    <>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing="$quarter"
      >
        {pointsIcon ? (
          <StackItem horizontalAlignment="center" verticalAlignment="center">
            <Icon icon={pointsIcon} size="$one" />
          </StackItem>
        ) : null}
        {displayText}
      </StackLayout>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="stretch"
        verticalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        {showProgressBar ? (
          <>
            <StackItem growFactor={1}>
              <ProgressBar
                total={100}
                current={currentProgress}
                customBarFillColor="success.border.default"
                customBarBackgroundColor="interactive.border.disabled"
              />
            </StackItem>
            {progressImage ? (
              <StackItem growFactor={0} baseWidth="$one">
                <Icon icon={progressImage} size="$one" />
              </StackItem>
            ) : null}
          </>
        ) : null}
      </StackLayout>
    </>
  );
};
