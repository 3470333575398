import {
  DEFAULT_NAMESPACE,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { getAsyncNode } from './get-async-node-and-format-to-masonry-node';

export type GetMasonryRootNodeConfigType = {
  rootNodeId: string;
};

type GetMasonryRootNodeType = (
  config: GetMasonryRootNodeConfigType,
) => MasonryEngineNode;

export const getMasonryRootNode: GetMasonryRootNodeType = ({ rootNodeId }) => ({
  id: rootNodeId,
  type: 'containerSpinner', // Replace this with spinner renderer
  properties: {},
  sections: {},
  namespace: DEFAULT_NAMESPACE,
  getAsyncSelf: async () => getAsyncNode(rootNodeId),
});
