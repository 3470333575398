import React, { FC } from 'react';
import { ImageAttributes } from '@leagueplatform/health-journey-api';
import {
  ParagraphText,
  ImageMessage,
  Box,
  GDSBorderRadii,
} from '@leagueplatform/genesis-core';
import { ToolboxComponentProps } from '../../types/health-activity.types';

interface ActivityToolboxImageProps
  extends ToolboxComponentProps,
    ImageAttributes {
  height?: string | number;
  width?: string | number;
  borderRadius?: GDSBorderRadii;
}
export const ActivityToolboxImage: FC<ActivityToolboxImageProps> = ({
  imageUrl,
  alt = '',
  caption,
  height = 'auto',
  width = '100%',
  borderRadius = '$large',
  css,
  componentId,
}) => (
  <Box as="figure" css={{ display: 'flex', ...css }}>
    <ImageMessage
      image={imageUrl}
      imageAlt={alt}
      imageGap="$none"
      imagePosition="top"
      css={{
        img: { borderRadius, height, width },
      }}
      data-testid={componentId}
    >
      {caption && (
        <ParagraphText
          size="xs"
          emphasis="subtle"
          as="figcaption"
          css={{ marginTop: '$one' }}
        >
          {caption}
        </ParagraphText>
      )}
    </ImageMessage>
  </Box>
);
