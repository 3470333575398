import React, { useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  BodyTwo,
  HeadingTwo,
  HeadingThree,
  SubtitleOne,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { HealthActivityModal } from '@leagueplatform/health-journey-common';
import { BadgeProgress } from '@leagueplatform/rewards-achievements';
import { useHealthActivity } from 'hooks/use-health-activity.hook';
import { ChallengeTokenIcon } from '@leagueplatform/rewards-common';
import { DynamicCoverImage } from './dynamic-image.component';
import { useActivityAnalytics } from '../hooks/use-activity-details-analytics.hook';

export const CompleteActivityModal = React.memo(
  ({ onClose, achievementsUpdates, completionData }) => {
    const { formatMessage } = useIntl();
    const { activityId } = useHealthActivity();
    const activityAnalytics = useActivityAnalytics();

    // Achievements Properties
    const {
      isAchievementsFeatureFlagOn,
      completedAchievements,
      inProgressAchievements,
      hasCompletedAchievements,
    } = achievementsUpdates ?? {};

    const achievementsProgress = [
      ...(completedAchievements || []),
      ...(inProgressAchievements || []),
    ];

    // Completion Modal Properties
    const {
      description_one: descriptionOne,
      description_two: descriptionTwo,
      eyebrow_headline: heading,
      image,
      rewards_message: pointsText,
      title,
      activity_reward_tokens: tokens,
    } = completionData?.completion_screen ?? {};

    const shouldDisplayAchievements =
      isAchievementsFeatureFlagOn && inProgressAchievements.length;
    const modalLabel = shouldDisplayAchievements
      ? formatMessage({ id: 'ACTIVITY_COMPLETE' })
      : title;

    useEffect(() => {
      if (activityId) {
        activityAnalytics.sendViewCompleteActivityModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
    }, [activityId]);

    const AchievementsProgress = achievementsProgress?.map((achievement) => {
      if (achievement.name) {
        return (
          <BadgeProgress
            contentUrl={achievement?.image?.small}
            title={achievement.name}
            key={achievement.name}
            isLoading={false}
            currentActivity={achievement?.progress?.criteria?.current}
            totalActivities={achievement?.progress?.criteria?.threshold}
            progressTitle={achievement?.progress?.title}
          />
        );
      }
      return null;
    });

    const AchievementsContent = (
      <Flex flexDirection="column" alignItems="center">
        <HeadingThree as="h1" marginY="quarter">
          {modalLabel}
        </HeadingThree>

        {pointsText && tokens <= 0 && (
          <Flex marginBottom="two">
            <SubtitleOne as="span" color="secondary.text.default">
              {pointsText}
            </SubtitleOne>
          </Flex>
        )}
        {tokens > 0 && (
          <Flex marginBottom="two" alignItems="center">
            <BodyTwo marginRight={5}>
              {formatMessage({ id: 'YOU_EARNED' })}
            </BodyTwo>
            <ChallengeTokenIcon value={tokens} />
          </Flex>
        )}
        <Box marginLeft="minusTwoAndHalf">{AchievementsProgress}</Box>
      </Flex>
    );

    const JourneyContent = (
      <Flex flexDirection="column" alignItems="center">
        <DynamicCoverImage src={image} size={80} marginBottom="one" />
        {heading && <BodyTwo>{heading}</BodyTwo>}
        {modalLabel && (
          <HeadingTwo as="h1" marginY="quarter">
            {modalLabel}
          </HeadingTwo>
        )}
        {pointsText && (
          <SubtitleOne
            as="h2"
            color="decorative.brand.default"
            fontWeight="medium"
          >
            {pointsText}
          </SubtitleOne>
        )}
        {descriptionOne && <BodyTwo marginTop="one">{descriptionOne}</BodyTwo>}
        {descriptionTwo && <BodyTwo marginTop="one">{descriptionTwo}</BodyTwo>}
      </Flex>
    );

    return (
      <HealthActivityModal
        onClose={onClose}
        aria-label={modalLabel}
        isNarrow={isAchievementsFeatureFlagOn}
      >
        {shouldDisplayAchievements ? AchievementsContent : JourneyContent}
        <Flex flexDirection="column" alignItems="center">
          <PrimaryButton onClick={onClose} marginTop="oneAndHalf">
            {formatMessage({
              id: hasCompletedAchievements ? 'CONTINUE' : 'BACK_TO_JOURNEY',
            })}
          </PrimaryButton>
        </Flex>
      </HealthActivityModal>
    );
  },
  (prevProps, nextProps) => {
    const nextPropsContainsValidData =
      nextProps.completionData || nextProps.achievementsUpdates;

    const prevPropsContainsValidData =
      prevProps.completionData || prevProps.achievementsUpdates;

    // Only rerender if the previous or next props contains valid data to render this modal.
    return nextPropsContainsValidData || prevPropsContainsValidData;
  },
);

CompleteActivityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  completionData: PropTypes.shape({ completion_screen: PropTypes.shape({}) }),
  achievementsUpdates: PropTypes.shape({}),
};

CompleteActivityModal.defaultProps = {
  completionData: undefined,
  achievementsUpdates: undefined,
};
