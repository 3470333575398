import React, { Suspense, useEffect, useState, useRef } from 'react';
import { func } from 'prop-types';
import {
  Box,
  Flex,
  HeadingOne,
  HeadingThree,
  Overline,
  PrimaryButton,
  SubtitleOne,
  genesisStyled,
  SecondaryButton,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { CompoundProgressBar } from '@leagueplatform/ui-kit';
import { ConfettiAnimation } from '@leagueplatform/lottie';
import { ActivityErrorMessage } from 'components/activity-error-message.component';
import { useActivityAnalytics } from 'hooks/use-activity-details-analytics.hook';
import {
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  ViewTransitionController,
} from '@leagueplatform/health-journey-common';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import {
  activityDefaultProps,
  activityPropType,
} from '../../../common/health-activity.props';
import { EditProgressCountModal } from '../../../components/edit-progress-count-modal.component';
import { normalizeNumberFromString } from '../../../utils/normalize-number-from-string.util';

// Constants
const COUNT_ACTIVITY_INPUT_METHODS = Object.freeze({
  SINGLE: 'single',
  MULTI: 'multi',
});
const { ACTIVE } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { SINGLE, MULTI } = COUNT_ACTIVITY_INPUT_METHODS;
const { MINUS, PLUS } = HEALTH_JOURNEY_ASSET_KEYS;
const acceptedInputMethods = Object.values(COUNT_ACTIVITY_INPUT_METHODS);
const transitionTempo = '0.2s ease-out';
const maxValue = 99999; // Prevent users from manually incrementing the input past the max accepted value or character limit

// Components
const MinusIcon = ({ disabled }) =>
  handleStaticAsset(MINUS, {
    isComponent: true,
    props: { disabled, display: 'block' },
  });
const PlusIcon = ({ disabled }) =>
  handleStaticAsset(PLUS, {
    isComponent: true,
    props: { disabled, display: 'block' },
  });
const CircleButton = genesisStyled(PrimaryButton)`
  border-radius: 50%;
  padding: ${({ theme: { space } }) => space.threeQuarters}px;
  transition: background ${transitionTempo};
  &:disabled, &:disabled:hover {
    background-color: ${({ theme: { colors } }) => colors.surface.card.disabled}
  };
`;
const ConfettiAnimationContainer = genesisStyled(Flex)`
  align-items: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  position: relative;
  transition: opacity 0.2s ease-out;
  width: 100%;
  `;

// Event Handlers
export const ActivityProgressCounter = ({
  hasError,
  cta,
  status,
  handleChange,
  name,
  progress = 0,
}) => {
  const activityAnalytics = useActivityAnalytics();
  // Props
  const { completion_method: completionMethod } = cta;
  const { text: title, counter } = completionMethod;
  const { goal, unit, inputMethods } = counter;

  // Hooks
  const { colors } = useTheme();
  const { formatMessage, formatNumber } = useIntl();
  const celebrationAnimationRef = useRef(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [count, setCount] = useState(progress);
  const [inputMethodUsed, setInputMethodUsed] = useState(null);

  useEffect(() => {
    // When the local count changes, make an API call to persist that count
    if (count !== progress && inputMethodUsed) {
      handleChange({
        newValue: count,
        oldValue: progress,
        inputMethod: inputMethodUsed,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, progress, inputMethodUsed]);

  // Computed Properties
  const progressPercent = (count / goal) * 100;
  const progressBarConfig = [
    {
      key: 'activity-progress-count',
      color: colors.highlight.icon,
      percent: progressPercent,
      transition: `width 0.5s ease-out`,
    },
  ];

  // Localized Copy
  const subtitle = formatMessage({ id: 'ACTIVITY_COUNT_GOAL' }, { goal, unit });
  const editLabel = `${formatMessage({ id: 'EDIT' })} ${unit}`;
  const incrementButtonLabel = `${formatMessage({ id: 'ADD' })} 1`;
  const decrementButtonLabel = `${formatMessage({ id: 'REMOVE' })} 1`;

  // Flags and Handlers
  const isActivityActive = status === ACTIVE;
  const isGoalReached = count >= goal;
  const shouldPlayCelebration = isActivityActive && isGoalReached;
  const disableCountChange = status !== HEALTH_JOURNEY_ACTIVITY_STATUS.ACTIVE;
  const disableCountDecrement = disableCountChange || count === 0;
  const disableCountIncrement = disableCountChange || count === maxValue;
  // if there is at least 1 valid inputMethod, do not use the default
  const inferDefaultInputMethod = !inputMethods?.some((inputMethod) =>
    acceptedInputMethods.includes(inputMethod),
  );

  const handleIncrementDecrement = (value) => () => {
    if (value <= maxValue) {
      setInputMethodUsed(SINGLE);
      setCount(normalizeNumberFromString({ value }));
    }
  };

  const handleEditCount = (value) => {
    setInputMethodUsed(MULTI);
    setCount(normalizeNumberFromString({ value }));
  };

  const handleEditModalClose = () => setIsEditModalOpen(false);

  const modals = {
    [MULTI]: {
      view: EditProgressCountModal,
      props: {
        name,
        heading: unit,
        subtitle,
        initialValue: count,
        altText: editLabel,
        disabled: disableCountChange,
        onClose: handleEditModalClose,
        onSave: handleEditCount,
      },
    },
  };

  const handleActiveModal = () => {
    if (isEditModalOpen) {
      activityAnalytics.sendOpenEditActivityProgressModal(editLabel);
      return MULTI;
    }
    return '';
  };

  useEffect(() => {
    const { current: animation } = celebrationAnimationRef;
    if (shouldPlayCelebration) {
      animation?.goToAndPlay(0);
    }
  }, [shouldPlayCelebration]);

  return (
    <Box marginTop="oneAndHalf" maxWidth="408px">
      {title && (
        <HeadingThree
          as="h2"
          marginBottom="quarter"
          display="flex"
          alignItems="center"
        >
          {title}
        </HeadingThree>
      )}
      {subtitle && (
        <Overline as="p" color="onSurface.text.subdued" fontWeight="medium">
          {subtitle}
        </Overline>
      )}

      {/* Progress Bar Container */}
      <Box marginTop="one">
        <Flex justifyContent="space-between" marginBottom="one">
          {/* Count Container */}
          <Flex alignItems="flex-end">
            <HeadingOne as="p" data-testid="count-text">
              {formatNumber(count)}
              <SubtitleOne
                as="span"
                color="onSurface.text.subdued"
                marginLeft="quarter"
              >
                {unit}
              </SubtitleOne>
            </HeadingOne>
          </Flex>

          {/* +/- Button Container */}
          {inputMethods?.includes(SINGLE) && (
            <Flex>
              <CircleButton
                data-testid="increment-btn"
                aria-label={decrementButtonLabel}
                disabled={disableCountDecrement}
                onClick={handleIncrementDecrement(count - 1)}
              >
                <MinusIcon disabled={disableCountDecrement} />
              </CircleButton>
              <CircleButton
                data-testid="increment-btn"
                aria-label={incrementButtonLabel}
                disabled={disableCountIncrement}
                marginLeft="one"
                onClick={handleIncrementDecrement(count + 1)}
              >
                <PlusIcon disabled={disableCountIncrement} />
              </CircleButton>
            </Flex>
          )}
        </Flex>
        <ConfettiAnimationContainer isVisible={shouldPlayCelebration}>
          <Suspense fallback={null}>
            <ConfettiAnimation
              animationRef={celebrationAnimationRef}
              lottieConfig={{ autoplay: shouldPlayCelebration }}
            />
          </Suspense>
        </ConfettiAnimationContainer>
        <CompoundProgressBar segments={progressBarConfig} />
        {hasError && (
          <ActivityErrorMessage marginTop="one" justifyContent="flex-start">
            {formatMessage({ id: 'PROGRESS_RECORD_ERROR' })}
          </ActivityErrorMessage>
        )}
      </Box>

      {/* Edit Button Container */}
      {(inputMethods?.includes(MULTI) || inferDefaultInputMethod) && (
        <Box marginTop="three">
          <SecondaryButton
            data-testid="edit-btn"
            disabled={disableCountChange}
            onClick={() => setIsEditModalOpen(true)}
            transition={`color ${transitionTempo}, background ${transitionTempo}`}
          >
            {editLabel}
          </SecondaryButton>
        </Box>
      )}
      <ViewTransitionController
        views={modals}
        handleActiveView={handleActiveModal}
      />
    </Box>
  );
};

ActivityProgressCounter.propTypes = {
  ...activityPropType,
  handleChange: func.isRequired,
};

ActivityProgressCounter.defaultProps = activityDefaultProps;
