import React, { PropsWithChildren, createContext } from 'react';
import { ThemeContainer } from './theme-container';

interface GDSThemeProviderProps {
  theme: any;
  children: React.ReactNode;
}

export const ThemeContext = createContext<any>(undefined);

export const GDSThemeProvider: React.FunctionComponent<
  PropsWithChildren<GDSThemeProviderProps>
> = ({ theme, children }) => {
  if (theme === undefined) {
    throw new Error('GDSThemeProvider requires a theme object.');
  }

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeContainer theme={theme}>{children}</ThemeContainer>
    </ThemeContext.Provider>
  );
};
