import React from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Box } from '@leagueplatform/genesis-core';
import { ConfigurableJourneyPageLayout } from '../configurable-journey-page-layout.component';

interface ConfigurableJourneyExperienceTabsProps {
  tabRoutes: string[];
  tabDisplayNames: string[];
}

// TODO: eventually want to move to tab injection approach once available on web (https://everlong.atlassian.net/wiki/spaces/TECH/pages/3522987403/ADR-2022-10-11+Front+End+Tab+Injection+from+other+Modules)
export const ConfigurableJourneyExperienceTabs = ({
  tabRoutes,
  tabDisplayNames,
}: ConfigurableJourneyExperienceTabsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      css={{
        marginBottom: '$oneAndHalf',
        '.GDS-tab-list-wrapper': {
          width: '100%',
        },
        marginTop: '$half',
        '@mobile': {
          marginTop: '$one',
        },
        '@tablet': {
          marginTop: '$oneAndHalf',
        },
      }}
    >
      <Tabs.TabList css={{ width: '100%' }} divider>
        <ConfigurableJourneyPageLayout>
          {tabDisplayNames.map((tab, index) => (
            <Tabs.Tab
              key={tab}
              css={{ padding: '$threeQuarters $oneAndHalf' }}
              value={tabRoutes[index]}
            >
              {formatMessage({ id: tab.toUpperCase() })}
            </Tabs.Tab>
          ))}
        </ConfigurableJourneyPageLayout>
      </Tabs.TabList>
    </Box>
  );
};
