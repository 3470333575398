import { useEffect, useMemo, useCallback, useState } from 'react';

export const useIsToolboxComponentVisible = (
  options: IntersectionObserverInit,
) => {
  const [ref, setRef] = useState<Element | null>(null);
  const componentRef = useCallback(setRef, [setRef]);
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsVisible(entry.isIntersecting),
        {
          threshold: 0.6,
          root: ref?.parentElement,
          ...options,
        },
      ),
    [options, ref],
  );

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
    }
    return () => observer.disconnect();
  }, [observer, ref]);

  return { isVisible, componentRef };
};
