import React from 'react';
import { number } from 'prop-types';
import {
  activitiesGroupsDefaultProps,
  activitiesGroupsProps,
  journeyPageEmptyStateProps,
} from 'common/health-activities.props';
import { ChallengeActivities } from 'components/challenge-activities.view';
import { JourneyTimelineEmptyState } from './journey-timeline-empty-state.component';

export const EmptyStateWithChallenges = ({
  emptyStateContent,
  activeDate,
  allActivities,
}) => (
  <>
    <JourneyTimelineEmptyState
      marginBottom="four"
      emptyStateContent={emptyStateContent}
    />
    <ChallengeActivities
      activeDate={activeDate}
      allActivities={allActivities}
    />
  </>
);

EmptyStateWithChallenges.propTypes = {
  ...activitiesGroupsProps,
  ...journeyPageEmptyStateProps,
  activeDate: number.isRequired,
};
EmptyStateWithChallenges.defaultProps = {
  ...activitiesGroupsDefaultProps,
};
