const DECIMAL_PRECISION = 2;
const INCH_CM_RATIO = 0.393700787;
const POUNDS_KG_RATIO = 0.45359237;

const round = (value: number, places: number) => Number(value.toFixed(places));

// TODO TypeScript-fy all these functions
export const fromInchesToCentimeters = (
  inches: number,
  precision = DECIMAL_PRECISION,
) => round(inches / INCH_CM_RATIO, precision);

export const fromCentimetersToInches = (
  centimeters: number,
  precision = DECIMAL_PRECISION,
) => round(centimeters * INCH_CM_RATIO, precision);

export const fromFeetToInches = (feet: number, precision = DECIMAL_PRECISION) =>
  round(feet * 12, precision);

export const fromInchesToFeetAndInches = (
  initialInches: number,
  // precision = DECIMAL_PRECISION,
) => {
  const feet = Math.floor(initialInches / 12);
  const inches = Math.round(initialInches % 12);
  const extraFoot = inches === 12;
  if (extraFoot) {
    // Inches cannot be 12
    return { feet: feet + 1, inches: 0 };
  }
  return { feet, inches };
};

export const fromFeetAndInchesToInches = (
  initialFeet: number,
  initialInches: number,
  // precision = DECIMAL_PRECISION,
) => {
  const inches = Math.floor(initialFeet * 12) + initialInches;
  return inches;
};

export const fromCentimetersToFeetAndInches = (
  centimeters: number,
  // precision = DECIMAL_PRECISION,
) => {
  const inches = fromCentimetersToInches(centimeters);
  return fromInchesToFeetAndInches(inches);
};

export const fromPoundsToKilograms = (
  pounds: number,
  precision = DECIMAL_PRECISION,
) => round(pounds * POUNDS_KG_RATIO, precision);

export const fromKilogramsToPounds = (
  kilograms: number,
  precision = DECIMAL_PRECISION,
) => round(kilograms / POUNDS_KG_RATIO, precision);
