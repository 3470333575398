import React, { ReactNode } from 'react';
import { styled } from '../../../theme';
import { useThemeStrings } from '../../../hooks/use-theme';

const OptionalFlag = styled('span', {
  typography: '$caption',
  color: '$onSurfaceTextSubdued',
  marginLeft: '$quarter',
});

export const LabelText = ({
  children,
  required,
}: {
  children: ReactNode;
  required?: boolean;
}) => {
  const { optional } = useThemeStrings();
  return (
    <>
      {children}
      {!required && <OptionalFlag>{`(${optional})`}</OptionalFlag>}
    </>
  );
};
