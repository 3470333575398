import React, { FC, useEffect, useMemo } from 'react';
import { Box, HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { FullPageHighlightAttributes } from '@leagueplatform/health-journey-api';
import { WaveAnimation } from '@leagueplatform/health-journey-common';
import { useIsToolboxComponentVisible } from '../../hooks/use-is-toolbox-component-visible.hook';
import {
  useActivityToolboxTheme,
  activityToolboxDefaultTheme,
} from '../../hooks/use-activity-toolbox-theme.hook';
import { ToolboxComponentProps } from '../../types/health-activity.types';
import { ActivityToolboxRichText } from './activity-toolbox-rich-text.component';

export interface FullPageHighlightProps
  extends ToolboxComponentProps,
    FullPageHighlightAttributes {}

export const ActivityToolboxFullPageHighlight: FC<FullPageHighlightProps> = ({
  title,
  subtitle,
  highlightImage,
  highlightText,
  backgroundColor,
  componentId,
  isVisible,
  css,
}) => {
  const derivedBackgroundColor =
    backgroundColor || '$decorativeBrandSecondaryPastel';

  const { setActivityToolboxTheme } = useActivityToolboxTheme();
  const { componentRef, isVisible: isInFrame } = useIsToolboxComponentVisible({
    threshold: 0.75,
  });

  const shouldApplyTheme = useMemo(
    () => isVisible && isInFrame,
    [isInFrame, isVisible],
  );

  useEffect(() => {
    if (shouldApplyTheme) {
      setActivityToolboxTheme({ backgroundColor: derivedBackgroundColor });
    } else {
      // Only reset the theme if the component is neither visible or within the frame
      setActivityToolboxTheme(activityToolboxDefaultTheme);
    }
  }, [derivedBackgroundColor, setActivityToolboxTheme, shouldApplyTheme]);

  return (
    <StackLayout
      ref={componentRef}
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{
        backgroundColor: derivedBackgroundColor,
        position: 'relative',
        minHeight: '100%',
        ...css,
      }}
      data-testid={componentId}
    >
      {/* animation */}
      <React.Suspense fallback={null}>
        <WaveAnimation />
      </React.Suspense>
      {/* inner content */}
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        spacing="$threeQuarters"
        css={{
          padding: '0 $oneAndHalf',
          textAlign: 'center',
          zIndex: '$sticky',
        }}
      >
        {highlightImage && (
          <Box
            as="img"
            src={highlightImage}
            alt=""
            css={{ maxWidth: '120px', maxHeight: '120px' }}
          />
        )}
        {title && (
          <HeadingText level="2" size="xxl">
            {title}
          </HeadingText>
        )}
        {subtitle && (
          <HeadingText level="display" size="md" as="p">
            {subtitle}
          </HeadingText>
        )}
        {highlightText && <ActivityToolboxRichText richText={highlightText} />}
      </StackLayout>
    </StackLayout>
  );
};
export default ActivityToolboxFullPageHighlight;
