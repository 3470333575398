import React, { FC, forwardRef, isValidElement } from 'react';
import { generateInputHint } from 'components/forms/component-generators';
import type { GDSSelectableProps } from '../../types';
import { RadioIndicator } from './radio-indicator';
import { styled } from '../../../../theme';
import { focusOutlineInner } from '../../../../theme/utils/focus-outline';

const SelectableInputLabel = styled('label', {
  typography: '$bodyOne',
  color: '$onSurfaceTextPrimary',
  gap: '$one',
  borderRadius: '$medium',
  padding: '$half',
  '&:hover': {
    backgroundColor: '$inputBackgroundHovered',
  },
  '&:active': {
    backgroundColor: '$inputBackgroundPressed',
  },
  variants: {
    layout: {
      inline: {
        display: 'inline-flex',
        '&:focus-within': {
          ...focusOutlineInner,
          input: {
            '&[class*="GDS"]': {
              outline: 'none',
            },
          },
        },
      },
      row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:focus-within:has(input:focus)': {
          ...focusOutlineInner,
          input: {
            '&[class*="GDS"]': {
              outline: 'none',
            },
          },
        },
      },
    },
    disabled: {
      false: {},
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    'aria-disabled': {
      false: {},
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const RadioLabelText = styled('span', {
  variants: {
    disabled: {
      false: {},
      true: { color: '$onSurfaceTextSubdued' },
    },
    'aria-disabled': {
      false: {},
      true: { color: '$onSurfaceTextSubdued' },
    },
  },
});

// styled divs for the following elements to pass the stitches css object
const RadioWrapper = styled('div');

const StyledRadioHint = styled('span', { display: 'block' });

export const Radio: FC<GDSSelectableProps> = forwardRef(
  (
    {
      label,
      layout = 'inline',
      css,
      id,
      name,
      required,
      hint,
      checked,
      inputStatus,
      disabled,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      'aria-readonly': ariaReadonly,
      value,
      onClick,
      onChange,
      onBlur,
      onFocus,
      className,
      loading,
      ...props
    }: GDSSelectableProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const hintId = `${id}-hint`;
    return layout === 'inline' ? (
      <RadioWrapper className="GDS-radio-inline" css={css}>
        <SelectableInputLabel
          htmlFor={id}
          className="GDS-radio-label"
          layout={layout}
          disabled={disabled}
          aria-disabled={ariaDisabled}
        >
          <RadioIndicator
            value={value}
            name={name}
            id={id}
            inputStatus={inputStatus}
            checked={checked}
            loading={loading}
            disabled={disabled}
            aria-disabled={ariaDisabled}
            aria-describedby={`${hintId}${
              ariaDescribedby ? ` ${ariaDescribedby}` : ''
            }`}
            aria-readonly={ariaReadonly}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}
            {...props}
          />
          {isValidElement(label) ? (
            label
          ) : (
            <RadioLabelText disabled={disabled} aria-disabled={ariaDisabled}>
              {label}
            </RadioLabelText>
          )}
        </SelectableInputLabel>
        {hint && (
          <StyledRadioHint
            className="GDS-radio-hint"
            css={{
              // calc hint margin to include input size, padding and spacing values
              marginLeft: `calc($one + $oneAndHalf + $half)`,
            }}
          >
            {generateInputHint({ hint, hintId })}
          </StyledRadioHint>
        )}
      </RadioWrapper>
    ) : (
      <RadioWrapper className="GDS-radio-row" css={css}>
        <SelectableInputLabel
          htmlFor={id}
          className="GDS-radio-label-hint"
          layout={layout}
          disabled={disabled}
          aria-disabled={ariaDisabled}
        >
          <span>
            {isValidElement(label) ? (
              label
            ) : (
              <RadioLabelText
                disabled={disabled}
                aria-disabled={ariaDisabled}
                className="GDS-radio-label"
              >
                {label}
              </RadioLabelText>
            )}
            {hint && (
              <StyledRadioHint
                className="GDS-radio-hint"
                css={{ marginTop: '$quarter' }}
              >
                {generateInputHint({ hint, hintId })}
              </StyledRadioHint>
            )}
          </span>

          <RadioIndicator
            value={value}
            name={name}
            id={id}
            inputStatus={inputStatus}
            checked={checked}
            loading={loading}
            disabled={disabled}
            aria-disabled={ariaDisabled}
            aria-describedby={ariaDescribedby}
            aria-readonly={ariaReadonly}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}
            {...props}
          />
        </SelectableInputLabel>
      </RadioWrapper>
    );
  },
);

Radio.displayName = 'Radio';
