import React from 'react';
import { Image, PrimaryButton } from '@leagueplatform/genesis-commons';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';

import { EmptyStateCard } from 'components/empty-state-card.component';

import { Link } from '@leagueplatform/routing';
import { journeyPageEmptyStateProps } from 'common/health-activities.props';

export const JourneyTimelineEmptyState = ({ emptyStateContent, ...props }) => (
  <EmptyStateCard
    graphic={
      <Image
        src={emptyStateContent?.image?.src}
        alt={emptyStateContent?.image?.alt}
      />
    }
    bodyText={emptyStateContent?.text?.bodyText}
    headingText={emptyStateContent?.text?.headingText}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    {emptyStateContent?.button && (
      <PrimaryButton
        as={Link}
        textDecoration="none"
        to={JOURNEY_ROUTES.programsHome}
      >
        {emptyStateContent.button}
      </PrimaryButton>
    )}
  </EmptyStateCard>
);
JourneyTimelineEmptyState.propTypes = journeyPageEmptyStateProps;
JourneyTimelineEmptyState.defaultProps = {
  button: null,
};
