import { isNodeTestEnv } from '@leagueplatform/app-environment';
import { leagueRoutesMap } from '@leagueplatform/league-routes';
import { subscribeToConfig } from '@leagueplatform/config';
import type {
  Span,
  SpanContext,
  Transaction,
  TransactionContext,
} from '@sentry/types';
import { Auth } from '@leagueplatform/auth';
import stringify from 'json-stringify-safe';
import SentryImplementation, { setGlobalContext } from './sentry/sentry';
import type {
  SentryConfig,
  ErrorContext,
  MessageContext,
} from './sentry/sentry.types';
import { TARGET_HUB } from './sentry/sentry.types';
import { isObservabilityEnabled } from './observability.utils';

export interface ObservabilityConfig extends SentryConfig {
  enabled: boolean;
}

export interface ObservabilityMetadata {
  moduleVersion?: string;
  appId: string;
  tenantAppName?: string;
  tenantAppVersion?: string;
}

interface EventsQueue {
  errors: {
    error: Error;
    context?: ErrorContext;
    targetHub?: TARGET_HUB;
  }[];
  messages: {
    message: string;
    context?: MessageContext;
    targetHub?: TARGET_HUB;
  }[];
}

// Used to store events captured during observability initialization
const eventsQueue: EventsQueue = { errors: [], messages: [] };

/**
 * This config subscription monitors the entire config
 * in order to provide Sentry context with the latest values
 */
let configSubscription: () => void;
function updateConfigContext() {
  if (typeof configSubscription !== 'undefined') return;
  configSubscription = subscribeToConfig(
    (config) => {
      const { core, ...restOfConfig } = config;

      /**
       * Omit `ui` from the `core` property, as it results in a very
       * verbose output and provides little value in observability
       */
      const { ui, ...restOfCore } = core;
      const simplifiedConfig = {
        core: restOfCore,
        ...restOfConfig,
      };

      return Object.fromEntries(
        Object.entries(simplifiedConfig).map(([key, value]) => [
          key,
          stringify(value),
        ]),
      );
    },
    (selection) => {
      if (!selection) return;

      setGlobalContext('Config', selection);
    },
  );
}

const memoizedGetModuleNameFromUrl = () => {
  let cache: { [key: string]: string };
  return () => {
    /**
     * Memoized function that returns module name if path hasn't changed, otherwise:
     * .filter to find if URL includes a League module path
     * .reduce to get the longest path in case of multiple matches
     * cache path, and leagueRoutesMap key as the module name
     * return module name
     */
    if (cache?.path === window.location.pathname) return cache.moduleName;
    const moduleName = Object.entries(leagueRoutesMap)
      .filter(([, value]) => window.location.pathname.includes(String(value)))
      .reduce(
        (longestValue: [string, string], [key, value]) =>
          value.length > longestValue[1].length ? [key, value] : longestValue,
        ['', ''],
      )[0];

    cache = { moduleName, path: window.location.pathname };
    return moduleName;
  };
};
const getModuleNameFromUrl = memoizedGetModuleNameFromUrl();

const captureQueuedEvents = () => {
  // Capture queued errors
  eventsQueue.errors.forEach((queuedError) => {
    SentryImplementation.captureError(queuedError.error, {
      context: {
        ...queuedError.context,
      },
      targetHub: queuedError.targetHub,
    });
  });
  eventsQueue.errors = [];

  // Capture queued messages
  eventsQueue.messages.forEach((queuedMessage) => {
    SentryImplementation.captureMessage(queuedMessage.message, {
      context: {
        ...queuedMessage.context,
      },
      targetHub: queuedMessage.targetHub,
    });
  });
  eventsQueue.messages = [];
};

/**
 * Performance Monitoring
 */
export const startTransaction = (transactionContext: TransactionContext) =>
  SentryImplementation.startCustomTransaction(transactionContext);

export const finishTransaction = (transaction: Transaction | null) => {
  transaction?.finish();
};

export const startSpan = (transaction: Transaction, spanContext: SpanContext) =>
  transaction?.startChild(spanContext);

export const startSpanOnActiveTransaction = (spanContext: SpanContext) => {
  const activeTransaction = SentryImplementation.getActiveTransaction();
  return activeTransaction?.startChild(spanContext);
};

export const finishSpan = (span: Span) => {
  span.finish();
};

type InitConfig = {
  metadata: ObservabilityMetadata;
  config: ObservabilityConfig;
  integrateWithLeagueRouting?: boolean;
  isAppSentryConfig?: boolean;
};

/**
 * Set for keeping track of whether there are any `initObservability` calls
 * that have not yet resolved.
 */
const activeInitializations = new Set<Symbol>();
/**
 * We create a symbol and add it to `activeInitializations`. So long as
 * `activeInitializations` has anything in it, we know that observability is currently
 * being initialized and that we should push any errors or messages being captured
 * to the queue, so that when initialization is done, the queue can be purged
 * and errors/messages can actually be sent to Sentry.
 */
const initializationSymbol = Symbol('o11y initialization');
if (!isNodeTestEnv) activeInitializations.add(initializationSymbol);

/**
 * Initialized observability tool(s)
 */
export const initObservability = async ({
  metadata,
  config,
  isAppSentryConfig,
}: InitConfig) => {
  activeInitializations.add(initializationSymbol);

  // Only initialize observability tooling when not in development environment,
  // or the observability dev flag is enabled.
  // This also avoids initializing Observability in testing and storybook.
  if (isObservabilityEnabled(metadata.appId)) {
    if (!config.enabled) {
      activeInitializations.delete(initializationSymbol);
      return;
    }

    SentryImplementation.initHub(metadata, {
      ...config,
    });

    if (isAppSentryConfig)
      SentryImplementation.initSDK({
        ...config,
      });

    activeInitializations.delete(initializationSymbol);
    captureQueuedEvents();
    updateConfigContext();
  }
};

const captureSentryError = async (
  error: Error,
  targetHub: TARGET_HUB,
  context?: ErrorContext,
) => {
  if (Auth.initialized) {
    try {
      const userId = await Auth.getUserId();
      SentryImplementation.setUserId(userId);
    } catch (ex) {
      // Ignore errors - we don't want auth issues to interfere with error capture
    }
  }

  const moduleName = targetHub === TARGET_HUB.SDK ? getModuleNameFromUrl() : '';
  // Add error to queue, if observability is initializing (retrieving config from backend)
  if (activeInitializations.size) {
    eventsQueue.errors.push({
      error,
      context: { ...context, tags: { ...context?.tags, moduleName } },
      targetHub,
    });
  } else {
    SentryImplementation.captureError(error, {
      context: { ...context, tags: { ...context?.tags, moduleName } },
      targetHub,
    });
  }
};

const captureSentryMessage = async (
  message: string,
  targetHub: TARGET_HUB,
  context?: MessageContext | undefined,
) => {
  if (Auth.initialized) {
    try {
      const userId = await Auth.getUserId();
      SentryImplementation.setUserId(userId);
    } catch (ex) {
      // Ignore errors - we don't want auth issues to interfere with error capture
    }
  }

  const moduleName =
    targetHub === TARGET_HUB.SDK ? getModuleNameFromUrl() : undefined;

  // Add message to queue, if observability is initializing (retrieving config from backend)
  if (activeInitializations.size) {
    eventsQueue.messages.push({
      message,
      context: { ...context, tags: { ...context?.tags, moduleName } },
      targetHub,
    });
  } else {
    SentryImplementation.captureMessage(message, {
      context: { ...context, tags: { ...context?.tags, moduleName } },
      targetHub,
    });
  }
};

/**
 * Sends the captured error to observability tool(s) with
 * optional context parameters.
 *
 * @param error {Error}
 * @param context {Object?}
 */
export const captureError = async (error: Error, context?: ErrorContext) => {
  if (!isNodeTestEnv()) {
    await captureSentryError(error, TARGET_HUB.SDK, context);
  }
};

/**
 * Captures and sends a message to observability tool(s)
 *
 *
 * @param message {string}
 * @param context {Object?}
 */
export const captureMessage = async (
  message: string,
  context?: MessageContext | undefined,
) => {
  if (!isNodeTestEnv()) {
    await captureSentryMessage(message, TARGET_HUB.SDK, context);
  }
};

/**
 * Sends the captured error to consumer application initialized observability tool(s) with
 * optional context parameters.
 *
 *
 * @param error {Error}
 * @param context {Object?}
 */
export const captureAppError = async (error: Error, context?: ErrorContext) => {
  if (!isNodeTestEnv()) {
    await captureSentryError(error, TARGET_HUB.APP, context);
  }
};

/**
 * Captures and sends a message to consumer application initialized observability tool(s)
 *
 *
 * @param message {string}
 * @param context {Object?}
 */
export const captureAppMessage = async (
  message: string,
  context?: MessageContext | undefined,
) => {
  if (!isNodeTestEnv()) {
    await captureSentryMessage(message, TARGET_HUB.APP, context);
  }
};
