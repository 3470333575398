import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { JOURNEY_MANAGER_ENABLED } from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ActivitiesGroupSection } from './activities-group.view';
import { ChallengeActivities } from './challenge-activities.view';
import {
  activitiesGroupsProps,
  activitiesGroupsDefaultProps,
} from '../common/health-activities.props';

// utils
import { HealthActivityCardList } from './health-activity-card-list/health-activity-card-list.view';

export const HealthActivities = ({
  activitiesByStatus,
  activitiesByAssigned,
  activitiesByCampaign,
  activitiesByPriority,
  activitiesByChallenge,
  allActivities,
  activeDate,
}) => {
  const { formatMessage } = useIntl();
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );
  const priorityTitle = formatMessage({ id: 'SUGGESTED_FOR_YOU' });
  const assignedTitle = formatMessage({ id: 'ASSIGNED' });

  const hasActivitiesByCampaign =
    activitiesByCampaign && activitiesByCampaign.length > 0;
  const hasActivitiesByStatus =
    activitiesByStatus &&
    activitiesByStatus.some(({ activities }) => activities.length > 0);

  return (
    <>
      {isJourneyManagerEnabled && (
        <HealthActivityCardList
          activityCategoryLabel={assignedTitle}
          activityArray={activitiesByAssigned}
          activeDate={activeDate}
        />
      )}
      <HealthActivityCardList
        activityCategoryLabel={priorityTitle}
        activityArray={activitiesByPriority}
        activeDate={activeDate}
      />
      <ChallengeActivities
        activitiesByChallenge={activitiesByChallenge}
        activeDate={activeDate}
        allActivities={allActivities}
      />
      {hasActivitiesByCampaign && (
        <ActivitiesGroupSection>
          {activitiesByCampaign.map(({ title, activities }) => (
            <HealthActivityCardList
              key={`activity-list-group-${title}`}
              activityCategoryLabel={title}
              activityArray={activities}
              activeDate={activeDate}
            />
          ))}
        </ActivitiesGroupSection>
      )}
      {hasActivitiesByStatus && (
        <ActivitiesGroupSection>
          {activitiesByStatus.map(({ title, activities }) => (
            <HealthActivityCardList
              key={`activity-list-group-${title}`}
              activityCategoryLabel={formatMessage({
                id: title.toUpperCase(),
              })}
              activityArray={activities}
              activeDate={activeDate}
            />
          ))}
        </ActivitiesGroupSection>
      )}
    </>
  );
};

HealthActivities.propTypes = activitiesGroupsProps;

HealthActivities.defaultProps = activitiesGroupsDefaultProps;
