import React from 'react';
import {
  Box,
  HeadingFour,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

export interface EmptyActivityStateProps {
  activityDescriptionId: string;
}

export const EmptyActivityState = ({
  activityDescriptionId,
}: EmptyActivityStateProps) => {
  const { formatMessage } = useIntl();
  return (
    <Box
      backgroundColor="surface.background.secondary"
      borderColor="onSurface.border.subdued"
      borderRadius="medium"
      borderWidth="thin"
      marginBottom="oneAndHalf"
      paddingX="one"
      paddingY="twoAndHalf"
      textAlign="center"
    >
      <HeadingFour as="h2">
        {formatMessage({ id: 'NO_ACTIVITY_TITLE' })}
      </HeadingFour>
      <BodyOneSecondary marginTop="half">
        {formatMessage({ id: activityDescriptionId })}
      </BodyOneSecondary>
    </Box>
  );
};
