import * as React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import {
  ChallengeRoutes,
  useIsChallengesEnabled,
} from '@leagueplatform/challenges';
import {
  HEALTH_ASSESSMENT_REPORT,
  JOURNEY_WELLNESS_AND_REWARDS,
  REWARDS_PROGRAMS_ENABLED,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { RewardProgramLandingPage } from '@leagueplatform/rewards-programs';
import { HealthAssessmentReport } from 'pages/health-assessment-report/health-assessment-report-page';
import { HealthJourneyPage } from './pages/main/health-journey-page.component';
import { SuggestedActivitiesPage } from './pages/suggested-activities-page/suggested-activities-page.component';
import { HealthActivityPage as ToolboxActivityPage } from './pages/health-activity/health-activity-page.component';
import { ActivityStepsPage } from './pages/activity-steps/activity-steps.component';
import { ActivityDetailsPage } from './pages/activity-details/activity-details.component';

export const HealthJourneySubroutes = () => {
  const { path } = useRouteMatch();
  // Challenges Feature Flag
  const { isChallengesEnabled } = useIsChallengesEnabled();

  const { data: isJourneyWellnessAndRewardsEnabled } = useFeatureFlagQuery(
    JOURNEY_WELLNESS_AND_REWARDS,
  );

  const { data: isRewardProgramsEnabled } = useFeatureFlagQuery(
    REWARDS_PROGRAMS_ENABLED,
  );

  return (
    <Switch>
      {isJourneyWellnessAndRewardsEnabled && (
        <Route
          exact
          path={`${path}/${HEALTH_ASSESSMENT_REPORT}`}
          component={HealthAssessmentReport}
        />
      )}
      <Route
        exact
        path={`${path}/campaign/:campaignId/activity/:activityId`}
        component={ActivityDetailsPage}
      />
      <Route
        exact
        path={`${path}/campaign/:campaignId/activity/:activityId/toolbox`}
        component={ToolboxActivityPage}
      />
      <Route
        exact
        path={`${path}/activity/:userActivityId/toolbox`}
        component={ToolboxActivityPage}
      />
      <Route
        exact
        path={`${path}/:userActivityId/steps`}
        component={ActivityStepsPage}
      />
      <Route
        exact
        path={`${path}/:userActivityId`}
        component={ActivityDetailsPage}
      />
      <Route
        exact
        path={`${path}/activity/:userActivityId/steps`}
        component={ActivityStepsPage}
      />
      <Route
        exact
        path={`${path}/activity/:userActivityId`}
        component={ActivityDetailsPage}
      />
      <Route
        path={`${path}/suggested/:groupId`}
        component={SuggestedActivitiesPage}
      />
      {isChallengesEnabled && (
        <Route path={`${path}/challenges`} component={ChallengeRoutes} />
      )}
      {/*
        If the new configurable journey experience is not enabled for the user (e.g. isConfigurableJourneyExperience is a falsy value)
        we fall back to rendering the old health journey page when we reach the /health-journey route.

        This route should be deleted when we deprecate the old Journey Experience
      */}

      {isRewardProgramsEnabled && (
        <Route
          path={`${path}/reward-program/:userHealthCampaignId`}
          component={RewardProgramLandingPage}
        />
      )}

      <Route exact>
        <HealthJourneyPage />
      </Route>
    </Switch>
  );
};
