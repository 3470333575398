import {
  DEFAULT_NAMESPACE,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { deserialise } from 'kitsu-core';
import {
  MasonryNodeResponse,
  MasonrySectionResponseData,
} from 'types/get-masonry-node';
import { getMasonryNode } from 'utils/get-masonry-node';

type MasonryDeserializeSection = Pick<
  MasonrySectionResponseData,
  'id' | 'name'
> & {
  nodes: { data: Array<MasonryNodeResponse['data']> };
};

/**
 * Function to make an API call if the node is an async node.
 * This function requires argument {@nodeId string}
 * */
export const getAsyncNode = async (
  nodeId: string,
): Promise<MasonryEngineNode> => {
  const data = await getMasonryNode(nodeId);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return formatMasonryNode(data);
};

/**
 *
 * Function formats sections and included key from the API response
 * into sections for a {@link MasonryEngineNode}
 * @param sectionsData after deserializing the JSON:API response
 * @returns
 */
export const formatSectionsToNode = (
  sectionsData: MasonryDeserializeSection[],
) =>
  sectionsData.reduce(
    (
      sections: Record<
        MasonryDeserializeSection['name'],
        Array<MasonryEngineNode>
      >,
      activeSection: MasonryDeserializeSection,
    ) => {
      const sectionsList = sections;
      const {
        name: sectionName,
        nodes: { data: sectionData },
      } = activeSection;
      sectionsList[sectionName] = sectionData.map(
        (nodeInSection: MasonryNodeResponse['data']) =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          formatMasonryNode({
            data: nodeInSection,
            included: [],
          }),
      );
      return sectionsList;
    },
    {},
  );

/**
 * Function to format Masonry Engine API response to {@link MasonryEngineNode}
 * @param data - Response data from API
 * @returns  {@link MasonryEngineNode}
 */
export const formatMasonryNode = (
  data: MasonryNodeResponse,
): MasonryEngineNode => {
  const formattedData = deserialise(data);

  const {
    node: { actions, properties, type, namespace },
    sections,
    id,
  } = formattedData.data;

  const nodeValue = {
    id,
    type,
    properties: properties || {},
    namespace: namespace || DEFAULT_NAMESPACE,
    actions: actions || {},
    sections: sections ? formatSectionsToNode(sections.data) : {},
    getAsyncSelf: !properties ? () => getAsyncNode(id) : undefined,
  };

  return nodeValue;
};
