import { Fragment } from 'react';

import {
  Bold,
  Italic,
  Mark,
  Blockquote,
  Image,
  Link,
} from '../components/page-components/activity-toolbox-building-blocks.component';

export const activityToolboxRichTextOptions = {
  blockquote: { component: Blockquote },
  br: { component: Fragment },
  b: { component: Bold },
  i: { component: Italic },
  mark: { component: Mark },
  img: { component: Image },
  a: { component: Link },
};
