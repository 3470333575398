/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import { Link } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { string, instanceOf, number } from 'prop-types';
import {
  Flex,
  Box,
  Overline,
  HeadingFour,
  Caption,
  Image,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { genesisFocus } from '@leagueplatform/ui-kit';
import { arrayToTag, truncateWithEllipses } from '@leagueplatform/web-common';
import { ChallengeActivityTokenCaption } from '@leagueplatform/challenges';
import {
  JOURNEY_ROUTES,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  ACTIVITY_CAMPAIGN_TYPE,
} from '@leagueplatform/health-journey-common';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';

import { normalizeEpochDate } from '../../utils/user-health-activities/user-health-activities.utils';
import { DynamicCoverImage } from '../dynamic-image.component';
import { ActivityCardProgress } from './health-activity-card-progress.component';
import { useActivitiesTimelineAnalytics } from '../../hooks/use-activities-timeline-analytics/use-activities-timeline-analytics.hook';
import { useHealthActivityCaption } from '../../hooks/use-health-activity-captions.hook';
import { configureActivityCardProgress } from '../../utils/configure-activity-card-progress.util';
import {
  activityShortDefaultProps,
  activityShortPropType,
} from '../../common/health-activities.props';

const { ACTIVE, UPCOMING } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;
const { ALARM_CLOCK } = HEALTH_JOURNEY_ASSET_KEYS;

const ActivityCardCaption = ({ todaysDate, ...activity }) => {
  // Static Assets
  const clockIcon = handleStaticAsset(ALARM_CLOCK);
  const { expires, points, assigned } = useHealthActivityCaption({
    todaysDate,
    ...activity,
  });
  const {
    tokens_earned: tokensEarned,
    activity_tokens: activityTokens,
    campaign_info: { campaign_type: campaignType } = {},
  } = activity;
  const fullCaptionText =
    campaignType === CHALLENGE
      ? arrayToTag([expires], true)
      : arrayToTag([assigned, expires, points], true);

  return (
    <Flex marginTop="one">
      {(assigned || expires) && (
        <Image src={clockIcon} alt="" width="auto" marginRight="quarter" />
      )}
      <Caption as="span">
        {fullCaptionText}
        {campaignType === CHALLENGE && points && (
          <>
            {fullCaptionText && (
              <Box as="span" marginX="quarter">
                ∙
              </Box>
            )}
            <ChallengeActivityTokenCaption
              tokens={activityTokens || tokensEarned}
              activityDesc={points}
            />
          </>
        )}
      </Caption>
    </Flex>
  );
};

ActivityCardCaption.propTypes = {
  ...activityShortPropType,
  todaysDate: instanceOf(Date),
};

ActivityCardCaption.defaultProps = {
  ...activityShortDefaultProps,
  todaysDate: new Date(),
};

export const HealthActivityCard = ({
  todaysDate,
  activeDate,
  suggestedActivityGroupName, // for segment analytics
  ...activity
}) => {
  const { formatMessage } = useIntl();
  const {
    campaign_info,
    name,
    status,
    type,
    id: activityId,
    icon_url: image,
    front_of_the_line_activity: isFrontOfLine,
  } = activity;

  // Hooks
  const { selectActivity } = useActivitiesTimelineAnalytics(activeDate);

  const { isProgressActivity, progressProps } =
    configureActivityCardProgress(activity);
  const isActiveUpcoming = status === UPCOMING || status === ACTIVE;

  // Only display program name for completed, expired, or removed activities
  const programName =
    status !== ACTIVE && status !== UPCOMING && campaign_info.name;

  const activityPath = JOURNEY_ROUTES.getActivityDetails(activityId, type);
  const ActivityCardContainer = genesisStyled(Flex)`
    &:last-of-type {
      margin-bottom: 0;
    }
  `;

  const activityCardStyles = () => {
    const styles = {
      textDecoration: 'none',
      alignItems: 'center',
      padding: 'oneAndHalf',
      marginBottom: 'oneAndHalf',
      borderRadius: 'medium',
      borderColor: 'onSurface.border.subdued',
      borderWidth: 'thin',
      width: '100%',
      backgroundColor: 'surface.background.secondary',
      transition: 'background-color 150ms',
    };

    if (isActiveUpcoming) {
      styles.backgroundColor = isFrontOfLine
        ? 'highlight.background.subdued'
        : 'surface.background.primary';
    }

    return styles;
  };

  return (
    <ActivityCardContainer
      as={Link}
      to={activityPath}
      onClick={() => {
        selectActivity(activity, suggestedActivityGroupName);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...activityCardStyles()}
      focusStyle={{
        ...genesisFocus,
        backgroundColor: 'interactive.background.disabled',
      }}
      hoverStyle={{
        textDecoration: 'none',
        backgroundColor: 'interactive.background.disabled',
      }}
    >
      <DynamicCoverImage
        alt={formatMessage({ id: status.toUpperCase() })}
        backgroundColor="surface.background.primary"
        borderColor="onSurface.border.subdued"
        borderRadius="large"
        marginRight="oneAndHalf"
        minWidth="64px"
        size={64}
        src={image}
      />
      <Flex flexDirection="column" flexGrow={1}>
        {programName && (
          <Box marginBottom="half">
            <Overline as="p" color="onSurface.text.subdued">
              {truncateWithEllipses(programName, 50)}
            </Overline>
          </Box>
        )}
        {name && (
          <Box>
            <HeadingFour
              as="p"
              fontSize={{ _: 'subtitle1', phone: 'heading4' }}
              color="onSurface.text.primary"
            >
              {truncateWithEllipses(name, 70)}
            </HeadingFour>
          </Box>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        {isProgressActivity && <ActivityCardProgress {...progressProps} />}
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <ActivityCardCaption {...activity} todaysDate={todaysDate} />
      </Flex>
    </ActivityCardContainer>
  );
};

HealthActivityCard.propTypes = {
  ...activityShortPropType,
  todaysDate: instanceOf(Date),
  activeDate: number,
  suggestedActivityGroupName: string, // for segment analytics
};

HealthActivityCard.defaultProps = {
  ...activityShortDefaultProps,
  todaysDate: new Date(),
  activeDate: normalizeEpochDate(Date.now()),
  suggestedActivityGroupName: '', // for segment analytics
};
