import React from 'react';
import {
  Box,
  HeadingText,
  ParagraphText,
  Tabs,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  PillTab,
  PillTabList,
  PillTabs,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  NUMERIC_YEAR_MONTH_DAY,
  SHORT_DATE_AND_TIME_FORMAT,
} from '@leagueplatform/web-common';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { ProviderServiceV2 } from '@leagueplatform/wallet-api';
import { WALLET_LOCAL_FEATURE_FLAGS } from 'common/feature-flags';
import { getWalletLocalFeatureFlag } from 'utils/get-wallet-local-feature-flag.util';
import { usePlanProgress } from './hooks/use-plan-progress.hook';

import { MemberDropDown } from '../member-dropdown/member-dropdown.component';
import { useMemberDropdown } from './hooks/use-member-dropdown.hook';
import { BalanceCategories } from './components/balance-categories/balance-categories.component';

const ACCUMULATION_PERIOD_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  ...NUMERIC_YEAR_MONTH_DAY,
  timeZone: 'UTC',
};

export const PlanProgress = () => {
  const { formatDate, formatMessage } = useIntl();
  const { userId, benefitId } = useWalletParams();

  const {
    plans,
    accumulationPeriod,
    lastUpdated,
    carrierName,
    onTabClickEvent: handleTabClickEvent,
    onAccordionToggleEvent: handleAccordionToggleEvent,
    isLoading,
    isError,
    refetch,
    isNoNetworkUser,
  } = usePlanProgress(userId, benefitId!, 2);

  const { showMemberSelect } = useMemberDropdown();

  const isNoNetworkEnabled = getWalletLocalFeatureFlag(
    WALLET_LOCAL_FEATURE_FLAGS.IS_NO_NETWORK_LIVE_BALANCES_ENABLED,
  );

  const isNoTabPlan =
    (isNoNetworkEnabled && plans.length === 1) || isNoNetworkUser;
  const isAccumulationPeriodVisible =
    !isLoading && !isError && accumulationPeriod;

  return (
    <StackLayout spacing="$oneAndHalf" as="section">
      {showMemberSelect && <MemberDropDown />}
      <StackLayout spacing="$one">
        <HeadingText as="h2" level="2" size="xl">
          {formatMessage({ id: 'PLAN_PROGRESS' })}
        </HeadingText>
        {isAccumulationPeriodVisible && (
          <ParagraphText size="sm">
            {formatMessage(
              { id: 'ACCUMULATION_PERIOD' },
              {
                start: formatDate(
                  accumulationPeriod.start,
                  ACCUMULATION_PERIOD_DATE_FORMAT,
                ),
                end: formatDate(
                  accumulationPeriod.end,
                  ACCUMULATION_PERIOD_DATE_FORMAT,
                ),
              },
            )}
          </ParagraphText>
        )}
      </StackLayout>
      {isLoading && (
        <Box css={{ width: '100%' }}>
          <LoadingIndicator inline />
        </Box>
      )}
      {isError && !isLoading && (
        <Box
          as="section"
          css={{
            backgroundColor: '$surfaceBackgroundTertiary',
            padding: '$one',
            minHeight: '286px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <ErrorState hideImage onButtonClick={refetch} />
        </Box>
      )}
      {!isLoading && !isError && (
        <StackLayout css={{ width: '100%' }} spacing="$oneAndHalf">
          {!isNoTabPlan && (
            <PillTabs
              css={{ width: '100%' }}
              onValueChange={(value) => {
                handleTabClickEvent(
                  plans[plans.findIndex((plan) => plan.id === value)]
                    .analyticsId,
                );
              }}
              defaultValue={plans[0]?.id}
            >
              <StackLayout css={{ width: '100%' }} spacing="$oneAndHalf">
                <Box css={{ width: 'fit-content' }}>
                  <PillTabList>
                    {plans.map((tab) => (
                      <PillTab key={tab.id} value={tab.id}>
                        {formatMessage({ id: tab.id })}
                      </PillTab>
                    ))}
                  </PillTabList>
                </Box>
                {plans.map((plan) => {
                  const LAST_UPDATED = formatMessage(
                    { id: 'LAST_UPDATED_CARRIER_DATE' },
                    {
                      date: formatDate(lastUpdated, SHORT_DATE_AND_TIME_FORMAT),
                      carrier: carrierName,
                    },
                  );
                  return (
                    <Tabs.TabPanel
                      css={{ padding: '$none', width: '100%' }}
                      key={plan.id}
                      value={plan.id}
                    >
                      {plan.network?.length === 0 && (
                        <Box css={{ display: 'flex' }} as="section">
                          <ParagraphText>
                            {formatMessage(
                              { id: 'NETWORK_MISSING_COVERAGE' },
                              { network: plan.networkName },
                            )}
                          </ParagraphText>
                        </Box>
                      )}

                      {plan?.network?.length > 0 && (
                        <StackLayout spacing="$oneAndHalf">
                          {plan.network.map(
                            (balanceGroup: ProviderServiceV2, i: number) => (
                              <BalanceCategories
                                // eslint-disable-next-line react/no-array-index-key
                                key={`balance-group-${plan.networkName}-${i}`}
                                title={balanceGroup.title}
                                description={balanceGroup.description}
                                categories={balanceGroup.categories}
                                lastUpdated={LAST_UPDATED}
                                networkName={plan.networkName}
                                onProviderToggle={handleAccordionToggleEvent}
                              />
                            ),
                          )}
                        </StackLayout>
                      )}
                    </Tabs.TabPanel>
                  );
                })}
              </StackLayout>
            </PillTabs>
          )}
          {isNoTabPlan &&
            plans.map((plan) => {
              const LAST_UPDATED = formatMessage(
                { id: 'LAST_UPDATED_CARRIER_DATE' },
                {
                  date: formatDate(lastUpdated, SHORT_DATE_AND_TIME_FORMAT),
                  carrier: carrierName,
                },
              );

              return plan.network.map(
                (balanceGroup: ProviderServiceV2, i: number) => (
                  <BalanceCategories
                    // eslint-disable-next-line react/no-array-index-key
                    key={`balance-group-${plan}-${i}`}
                    title={balanceGroup.title}
                    description={balanceGroup.description}
                    categories={balanceGroup.categories}
                    lastUpdated={LAST_UPDATED}
                    onProviderToggle={handleAccordionToggleEvent}
                    networkName={plan.networkName}
                  />
                ),
              );
            })}
        </StackLayout>
      )}
    </StackLayout>
  );
};
