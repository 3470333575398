import React from 'react';
import {
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ProgressBar } from '@leagueplatform/web-common-components';
import {
  ComposableActivityCard,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';

type ContentProps = {
  currentText: string;
  suffixText?: string;
};

type BadgeProps = {
  body: string;
  image: string;
  type: 'health_program' | 'challenge';
};

export interface ProgramsAndChallengesProgressCardProps {
  badge: BadgeProps;
  content: ContentProps;
  cta: string;
  heading: string;
  percentageProgress: number;
}

const getAnalyticsValuesFromCardProps = ({
  badge,
  cta,
}: {
  badge: BadgeProps;
  cta: string;
}) => {
  if (badge.type === 'health_program') {
    return {
      healthCampaignId: cta.replace('/health-programs/', ''),
      healthCampaignType: 'opt-in',
    };
  }
  if (badge.type === 'challenge') {
    return {
      healthCampaignId: cta.replace('/health-journey/challenges/', ''),
      healthCampaignType: 'challenge',
    };
  }
  return {
    healthCampaignId: undefined,
    healthCampaignType: undefined,
  };
};

export function ProgramsAndChallengesProgressCard({
  badge,
  content,
  cta,
  heading,
  percentageProgress,
}: ProgramsAndChallengesProgressCardProps) {
  const handleClickAnalytics = () => {
    const { healthCampaignId, healthCampaignType } =
      getAnalyticsValuesFromCardProps({ badge, cta });
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: SCREEN_NAMES.PROGRESS_PAGE,
      detail: 'view health campaign',
      health_campaign_name: heading,
      health_campaign_id: healthCampaignId,
      health_campaign_type: healthCampaignType,
    });
  };
  return (
    <ComposableActivityCard
      title={{ text: heading, level: '3', size: 'sm' }}
      badge={{
        body: badge.body,
        image: badge.image,
        imageTint:
          badge.type === 'challenge'
            ? '$warningBorderDefault'
            : '$decorativeBrandPrimaryDark',
        backgroundColor:
          badge.type === 'challenge'
            ? '$warningBackgroundSubdued'
            : '$decorativeBrandPrimaryPastel',
      }}
      body={
        <>
          <StackLayout orientation="horizontal" spacing="$quarter">
            <UtilityText
              emphasis="emphasized"
              css={{
                color: '$onSurfaceTextSuccess',
              }}
            >
              {content.currentText}
            </UtilityText>
            <ParagraphText as="span" emphasis="subtle">
              {content.suffixText}
            </ParagraphText>
          </StackLayout>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="stretch"
            verticalAlignment="center"
            css={{
              width: '100%',
            }}
          >
            <StackItem growFactor={1}>
              <ProgressBar
                current={percentageProgress}
                total={100}
                customBarBackgroundColor="interactive.border.disabled"
                customBarFillColor="success.border.default"
              />
            </StackItem>
          </StackLayout>
        </>
      }
      cta={cta}
      onClick={handleClickAnalytics}
    />
  );
}
