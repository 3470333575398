import React from 'react';

import { Link, VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  Box,
  Divider,
  Icon,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { Link as RouterLink } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import type { UserDropdownItemProps } from './user-dropdown.types';

const NotificationDot = styled(Box, {
  borderRadius: '$circle',
  backgroundColor: '$criticalIcon',
  width: '$half',
  height: '$half',
});

/**
 * @param {string} to - Path to go to for RouterLink
 * @param {string} text - Display text
 * @param {string} onClick - function to invoke when item is clicked
 * @param {string} ref - To control the focus state, this is forwarded from the parent
 * @param {boolean} hasNotification - Test for League Family notification dots
 */
export const UserDropdownItem = React.forwardRef(
  (
    {
      to,
      text,
      isMobile,
      onClick = () => {},
      target,
      hasNotification,
      linkProps = {},
      icon,
      isLastElementInSection,
    }: UserDropdownItemProps,
    ref,
  ) => {
    const { formatMessage } = useIntl();

    const isExternalLink = to && /^[^(/|..)]/.test(to);

    const Component = (() => {
      if (!to) return 'button';
      if (isExternalLink) return Link;
      return RouterLink;
    })();

    return (
      <Box as="li">
        <Link
          as={Component}
          to={!isExternalLink ? to : undefined}
          href={isExternalLink ? to : undefined}
          ref={ref}
          hoverStyle={{
            textDecoration: 'none !important',
            backgroundColor: 'interactive.background.hovered',
          }}
          fontSize="body2"
          lineHeight="body2"
          fontWeight={isMobile ? 'bold' : 'regular'}
          color={
            isMobile ? 'interactive.action.subdued' : 'onSurface.text.primary'
          }
          display="block"
          padding="half"
          marginBottom="quarter"
          border="none"
          backgroundColor="transparent"
          width="100%"
          textAlign="left"
          onClick={onClick}
          target={target}
          aria-describedby={
            hasNotification ? 'dependent-notification-message' : ''
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...linkProps}
        >
          <StackLayout
            orientation="horizontal"
            verticalAlignment="center"
            spacing="$half"
          >
            {formatMessage({ id: text })}
            {icon && (
              <Icon
                icon={icon}
                css={{
                  height: '$one',
                  width: '$one',
                }}
              />
            )}
            {/* Testing a notification dot for League Family */}
            {hasNotification && (
              <NotificationDot
                aria-hidden
                id="dependent-notification-message"
                aria-label={formatMessage({
                  id: 'DEPENDENT_NOTIFICATION_MESSAGE',
                })}
              />
            )}
            {target === '_blank' && (
              <VisuallyHidden data-testid="new-tab-msg">
                {formatMessage({ id: 'OPENS_IN_A_NEW_TAB' })}
              </VisuallyHidden>
            )}
          </StackLayout>
        </Link>
        {isLastElementInSection && (
          <Divider
            css={{
              marginLeft: '$half',
              marginRight: '$half',
              width: 'unset',
              borderWidth: '1px',
              ...(isMobile && { marginTop: '$oneAndHalf' }),
            }}
          />
        )}
      </Box>
    );
  },
);
