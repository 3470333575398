import React, { forwardRef, HTMLAttributes } from 'react';
import {
  ScrollableWrapper,
  ScrollableItemsWrapper,
  ScrollLeft,
  ScrollRight,
} from 'components/tabs/scroll-buttons';
import { TabListContextProvider } from 'components/tabs/tablist-context';
import { useTabsScroll } from 'components/tabs/use-tabs-scroll';
import { styled } from '../../theme';
import { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { Link } from './nav-link';
import { RouterLink } from './router-link';

export interface GDSNavListProps
  extends GDSCustomizableComponent,
    HTMLAttributes<HTMLElement> {
  divider?: boolean;
  tabDistribution?: GDSResponsiveProp<'distributeEvenly' | 'hugContents'>;
  children?: React.ReactNode;
}

const BaseNav = styled('nav', {
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0px',
    background: 'transparent' /* make scrollbar transparent */,
  },
});

const BaseNavList = styled('ul', {
  display: 'flex',
  gap: '$half',
  margin: 0,
  padding: 0,

  variants: {
    tabDistribution: {
      distributeEvenly: {
        justifyContent: 'stretch',
        '> .GDS-nav-item': {
          flex: '1 0 auto',
        },
      },
      hugContents: {
        justifyContent: 'flex-start',
      },
    },
  },
});

export const List = forwardRef<HTMLUListElement, GDSNavListProps>(
  (
    {
      children,
      className,
      css,
      divider,
      tabDistribution = 'distributeEvenly',
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      ...props
    },
    ref: React.Ref<HTMLUListElement>,
  ) => {
    const responsiveTabDistribution = useResponsiveProp(tabDistribution);
    const responsiveDivider = useResponsiveProp(divider);
    const {
      scrollLeft,
      scrollRight,
      tabsList,
      showScrollLeft,
      showScrollRight,
    } = useTabsScroll();
    const composedRefs = useComposedRefs<HTMLUListElement>(
      ref,
      tabsList as React.Ref<HTMLUListElement>,
    );
    const tabListWrapperRef = React.useRef(null);
    const scrollLeftButtonRef = React.useRef(null);
    const scrollRightButtonRef = React.useRef(null);

    const tabListElements = {
      tabListWrapper: tabListWrapperRef.current,
      scrollLeftButton: scrollLeftButtonRef.current,
      scrollRightButton: scrollRightButtonRef.current,
    };

    return (
      <TabListContextProvider tabListElements={tabListElements}>
        <ScrollableWrapper>
          {showScrollLeft && (
            <ScrollLeft
              ref={scrollLeftButtonRef}
              onClick={() => scrollLeft()}
            />
          )}
          <ScrollableItemsWrapper
            ref={tabListWrapperRef}
            className="GDS-nav-list-wrapper"
          >
            <BaseNav
              className="GDS-nav"
              aria-label={ariaLabel}
              aria-labelledby={ariaLabelledBy}
              ref={composedRefs}
            >
              <BaseNavList
                className={[`GDS-nav-list`, className].join(' ')}
                tabDistribution={responsiveTabDistribution}
                tabIndex={0}
                css={{
                  ...css,
                  ...(responsiveDivider && {
                    borderBlockEnd:
                      '$borderWidths$thin solid $onSurfaceBorderSubdued',
                  }),
                }}
                {...props}
              >
                {children}
              </BaseNavList>
            </BaseNav>
          </ScrollableItemsWrapper>
          {showScrollRight && (
            <ScrollRight
              ref={scrollRightButtonRef}
              onClick={() => scrollRight()}
            />
          )}
        </ScrollableWrapper>
      </TabListContextProvider>
    );
  },
);

export const NavList = {
  List,
  Link,
  RouterLink,
};

NavList.List.displayName = 'NavList.List';
NavList.Link.displayName = 'NavList.Link';
NavList.RouterLink.displayName = 'NavList.RouterLink';
