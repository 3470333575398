import { BodyTwo, css, genesisStyled } from '@leagueplatform/genesis-commons';
import { DynamicImage } from '../components/dynamic-image.component';

const StyledListItem = genesisStyled(BodyTwo)(
  css({
    marginTop: 'half',
    marginBottom: 'half',
    '> p, > ul': {
      marginBottom: 0,
    },
  }),
);

export const ActivityHtmlToReactOptions = {
  h1: {
    props: {
      marginBottom: 'oneAndHalf',
    },
  },
  h2: {
    props: { marginBottom: 'one' },
  },
  p: {
    component: BodyTwo,
    props: {
      marginBottom: 'oneAndHalf',
    },
  },
  img: {
    component: DynamicImage,
    props: {
      maxWidth: '100%',
      maxHeight: '75vh',
      width: 'auto',
      display: 'block',
      marginBottom: 'twoAndHalf',
    },
  },
  a: {
    props: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      target: '_blank',
    },
  },
  li: {
    component: StyledListItem,
    props: {
      as: 'li',
    },
  },
  sup: {
    props: {
      marginBottom: '$one',
    },
  },
};
