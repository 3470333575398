import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MultipleSelectValidationOptions } from '@leagueplatform/health-journey-api';

interface UseMultiSelectInputValidation
  extends Omit<MultipleSelectValidationOptions, 'required'> {}
export const useMultiSelectInputValidation = ({
  minimumSelectionRequired,
  maximumSelectionRequired,
  customWarning,
}: UseMultiSelectInputValidation) => {
  const { formatMessage } = useIntl();

  const selectedOverMaxOptions = useCallback(
    (value) => {
      const message =
        customWarning ||
        formatMessage(
          { id: 'TOOLBOX_VALIDATION_SELECT_MAX_SELECTION' },
          { integer: maximumSelectionRequired },
        );
      return value?.length > maximumSelectionRequired ? message : undefined;
    },
    [customWarning, formatMessage, maximumSelectionRequired],
  );

  const selectedUnderMinOptions = useCallback(
    (value) => {
      const message =
        customWarning ||
        formatMessage(
          { id: 'TOOLBOX_VALIDATION_SELECT_OPTIONS_TO_CONTINUE' },
          { integer: minimumSelectionRequired },
        );
      return value?.length < minimumSelectionRequired ? message : undefined;
    },
    [customWarning, formatMessage, minimumSelectionRequired],
  );

  return {
    selectedOverMaxOptions,
    selectedUnderMinOptions,
  };
};
