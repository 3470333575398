import React from 'react';
import Masonry from '@leagueplatform/masonry';
import { PRODUCT_AREA } from '@leagueplatform/analytics';
import {
  SCREEN_NAMES,
  HEALTH_JOURNEY_MASONRY_VERSION,
  HEALTH_JOURNEY_MODULE_KEY,
  JOURNEY_TO_DO_TAB_APP_ID,
  PRIORITY_ACTIVITIES_LIST_WIDGET,
} from '@leagueplatform/health-journey-common';
import { PriorityActivitiesWidget } from 'components/configurable-journey-experience/priority-activities-widget/priority-activities-widget.component';
import { CustomWidgetContainer } from '../custom-widget-container.component';

const PriorityActivitiesTabContent = Masonry.init({
  // TODO: update const name of first tab app id once we have an official name
  appId: JOURNEY_TO_DO_TAB_APP_ID,
  apiVersion: HEALTH_JOURNEY_MASONRY_VERSION,
  analytics: {
    productArea: PRODUCT_AREA.JOURNEY,
    screenName: SCREEN_NAMES.ACTIVITY_TIMELINE,
  },
  // Add custom widget configs here
  customWidgetModules: [
    {
      moduleKey: HEALTH_JOURNEY_MODULE_KEY,
      customWidgetsUI: [
        {
          type: PRIORITY_ACTIVITIES_LIST_WIDGET,
          widget: () => (
            <CustomWidgetContainer>
              <PriorityActivitiesWidget />
            </CustomWidgetContainer>
          ),
        },
      ],
    },
  ],
});

export const PriorityActivitiesTab = () => (
  <PriorityActivitiesTabContent.Provider />
);
