import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  FormField,
  StackLayout,
  TextInput,
} from '@leagueplatform/genesis-core';
import {
  PersonWeightInputAttributes,
  WeightUnits,
} from '@leagueplatform/health-journey-api';
import {
  UseControllerProps,
  useControlledInput,
} from '@leagueplatform/web-common';
import { useToolboxPersonWeightInputs } from 'pages/health-activity/hooks/use-toolbox-person-weight-inputs.hook';
import { useToolboxErrorFocus } from 'pages/health-activity/hooks/use-toolbox-error-focus.hook';
import { ToolboxCommonInputProps } from '../../../types/health-activity.types';
import {
  ActivityToolboxInputWrapper,
  MapInputValueToProps,
} from '../../activity-toolbox-input-wrapper.component';
import { SegmentedControls } from '../../segmented-controls.component';

interface PersonWeightInputProps
  extends ToolboxCommonInputProps,
    PersonWeightInputAttributes {}

export const ActivityToolboxPersonWeightInput: FC<PersonWeightInputProps> = (
  props,
) => {
  const { formatMessage } = useIntl();
  const {
    altText,
    componentId,
    css,
    validation,
    answer,
    unit: outputUnit,
  } = props;
  const { customWarning, required, editable = true } = validation;

  const poundsString = formatMessage({ id: 'LB' });
  const kilogramsString = formatMessage({ id: 'KG' });

  const measurementTypes = useMemo(
    () => [
      { unit: WeightUnits.pounds, label: poundsString },
      { unit: WeightUnits.kilogram, label: kilogramsString },
    ],
    [poundsString, kilogramsString],
  );

  // The initial segmented control selected
  const initialControlIndex = useMemo(
    () => measurementTypes.findIndex(({ unit }) => unit === outputUnit),
    [outputUnit, measurementTypes],
  );

  const [selectedMeasurement, setSelectedMeasurement] = useState(
    measurementTypes[initialControlIndex],
  );
  const { unit: selectedUnit } = selectedMeasurement;

  // Input Refs, Validation Methods, Handlers
  const { valueHandlers, validateMethods, refs } = useToolboxPersonWeightInputs(
    { componentId, outputUnit, selectedUnit, validation },
  );
  const { onInputChange, syncUnitInputs, getUnitValues } = valueHandlers;
  const { poundsInputRef, kilogramsInputRef, fieldRef } = refs;

  // The default values needed to pre-populate each unit's input
  const defaultDisplayValues = useMemo(
    () => getUnitValues(answer),
    [answer, getUnitValues],
  );

  // Input Config
  const inputOptions: UseControllerProps = {
    name: componentId,
    defaultValue: answer,
    rules: {
      // @ts-expect-error
      disabled: !editable, // Disables validation without disabling the inputs themselves
      required: {
        value: required,
        message:
          customWarning ||
          formatMessage({ id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD' }),
      },
      validate: validateMethods,
    },
  };

  const { field, fieldState, inputValidationState } =
    useControlledInput(inputOptions);
  const { inputStatus, statusMessage } = inputValidationState;

  const findFocusableInput = useCallback(
    () =>
      selectedUnit === WeightUnits.pounds
        ? poundsInputRef.current
        : kilogramsInputRef.current,
    [kilogramsInputRef, poundsInputRef, selectedUnit],
  );

  useToolboxErrorFocus({
    name: componentId,
    focusTarget: findFocusableInput(),
    onValidationError: () =>
      !fieldRef.current?.contains(document.activeElement),
  });

  // Trigger validation when the field is invalid and the selected unit changes
  useEffect(() => {
    if (selectedUnit && fieldState.invalid) {
      field.onChange(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  const mapValueToProps: MapInputValueToProps<string> = (value, component) => {
    Object.assign(component, {
      componentAttributes: {
        ...component.componentAttributes,
        answer: value ?? answer,
      },
    });

    return component;
  };

  const onTabChange = (newTabIndex: number) => {
    const nextMeasurement = measurementTypes[newTabIndex];
    setSelectedMeasurement(nextMeasurement);
  };

  useEffect(() => {
    syncUnitInputs(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncUnitInputs]);

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <Box css={css} ref={fieldRef} data-testid={componentId}>
        <SegmentedControls
          controlTabOnChange={(value) =>
            onTabChange(
              measurementTypes.findIndex((_, index) => `${index}` === value),
            )
          }
          controlTabLabels={measurementTypes}
          defaultIndex={initialControlIndex}
          controlTabContents={[
            {
              contentKey: `${componentId}-lb`,
              content: (
                <FormField
                  id={componentId}
                  label={altText}
                  hideLabel
                  inputStatus={inputStatus}
                  statusMessage={statusMessage}
                >
                  <StackLayout
                    orientation="horizontal"
                    spacing="$one"
                    css={{
                      '.GDS-text-input-group': { flex: '1 1 0' },
                      '.GDS-base-input': { width: 0 },
                    }}
                  >
                    <TextInput
                      aria-label={formatMessage({
                        id: 'ENTER_YOUR_WEIGHT_IN_POUNDS',
                      })}
                      type="number"
                      step={0.01}
                      ref={poundsInputRef}
                      addOnEnd={poundsString}
                      min="0"
                      disabled={!editable}
                      defaultValue={defaultDisplayValues.lbs}
                      data-testid={`${componentId}-lb`}
                      onChange={onInputChange}
                      inputStatus={inputStatus}
                    />
                  </StackLayout>
                </FormField>
              ),
            },
            {
              contentKey: `${componentId}-kg`,
              content: (
                <FormField
                  id={componentId}
                  label={altText}
                  hideLabel
                  inputStatus={inputStatus}
                  statusMessage={statusMessage}
                >
                  <StackLayout
                    orientation="horizontal"
                    spacing="$one"
                    css={{
                      '.GDS-text-input-group': { flex: '1 1 0' },
                      '.GDS-base-input': { width: 0 },
                    }}
                  >
                    <TextInput
                      aria-label={formatMessage({
                        id: 'ENTER_YOUR_WEIGHT_IN_KILOGRAMS',
                      })}
                      type="number"
                      step={0.01}
                      addOnEnd={kilogramsString}
                      ref={kilogramsInputRef}
                      min="0"
                      disabled={!editable}
                      defaultValue={defaultDisplayValues.kg}
                      data-testid={`${componentId}-kg`}
                      onChange={onInputChange}
                      inputStatus={inputStatus}
                    />
                  </StackLayout>
                </FormField>
              ),
            },
          ]}
        />
      </Box>
    </ActivityToolboxInputWrapper>
  );
};
