import * as React from 'react';
import { useState } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Link,
  CoverImage,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import {
  ProgramLimitModal,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';

import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { useHealthJourneyProgress } from '../hooks/use-health-journey-progress.hook';
import { useJourneyProgressAnalytics } from '../hooks/use-journey-progress-analytics.hook';

const { HEART_RATE_UP, MOBILE_ALERT_USER } = HEALTH_JOURNEY_ASSET_KEYS;

export const CampaignLimitBanner = () => {
  // Static Assets
  const availablePrograms = handleStaticAsset(HEART_RATE_UP);
  const noAvailablePrograms = handleStaticAsset(MOBILE_ALERT_USER);

  // Hooks
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [showProgramLimitModel, setShowProgramLimitModel] = useState(false);

  const {
    isProgramLimitReached,
    numberOfAvailablePrograms,
    programLimitModal,
  } = useHealthJourneyProgress();
  const progressAnalytics = useJourneyProgressAnalytics();

  const onClickActions = (e) => {
    e.preventDefault();

    if (!programLimitModal) return;

    if (isProgramLimitReached) {
      progressAnalytics.sendOpenProgramLimitModal();
      setShowProgramLimitModel(true);
    } else {
      progressAnalytics.sendSelectStartProgramsBanner('Browse Programs');
      history.push(JOURNEY_ROUTES.programsHome());
    }
  };

  const handleProgramLimitModalClose = () => {
    progressAnalytics.sendCloseProgramLimitModal();
    setShowProgramLimitModel(false);
  };

  return (
    <>
      <Flex
        as={Link}
        href="#"
        cursor="pointer"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderRadius="medium"
        borderWidth="thin"
        borderColor="highlight.background.subdued"
        backgroundColor="highlight.background.subdued"
        padding="oneAndHalf"
        marginTop="oneAndHalf"
        onClick={onClickActions}
        hoverStyle={{
          textDecoration: 'none',
        }}
      >
        <Flex marginRight="one">
          <CoverImage
            src={
              isProgramLimitReached ? noAvailablePrograms : availablePrograms
            }
            alt=""
            size={25}
          />
          <SubtitleOne marginLeft="one">
            {isProgramLimitReached
              ? formatMessage({ id: 'YOUVE_REACHED_PROGRAM_LIMIT' })
              : formatMessage(
                  { id: 'START_X_MORE_PROGRAMS' },
                  { duration: numberOfAvailablePrograms },
                )}
          </SubtitleOne>
        </Flex>
        <SubtitleOne color="interactive.action.primary">
          {isProgramLimitReached
            ? formatMessage({ id: 'LEARN_MORE' })
            : formatMessage({ id: 'BROWSE_PROGRAMS' })}
        </SubtitleOne>
      </Flex>
      {showProgramLimitModel && (
        <ProgramLimitModal
          title={programLimitModal.title}
          description={programLimitModal.description}
          onClose={handleProgramLimitModalClose}
        />
      )}
    </>
  );
};
