import React, { FC } from 'react';
import {
  PillTabs,
  PillTabList,
  PillTab,
} from '@leagueplatform/web-common-components';
import { Card, Tabs } from '@leagueplatform/genesis-core';

// types
type ControlTabLabelsType = {
  label: string;
};

type ControlTabContentsType = {
  content: React.ReactNode;
  contentKey: string;
};

// interface
export interface SegmentedControlsProps {
  controlTabLabels?: ControlTabLabelsType[];
  controlTabContents?: ControlTabContentsType[];
  controlTabOnChange?: (selectedTabIndex: string) => void;
  defaultIndex?: number;
}

export const SegmentedControls: FC<SegmentedControlsProps> = ({
  controlTabLabels,
  controlTabContents,
  controlTabOnChange,
  defaultIndex = 0,
}) => (
  <Card.Flat
    css={{
      borderColor: '$onSurfaceBorderSubdued',
      borderRadius: '$medium',
      borderWidth: '$thin',
      borderStyle: 'solid',
      boxShadow: '$card',
    }}
  >
    <Card.Section padding="$oneAndHalf $one">
      <PillTabs
        defaultValue={`${defaultIndex}`}
        onValueChange={controlTabOnChange}
      >
        <PillTabList
          css={{
            borderWidth: 'none',
            boxShadow: 'none',
            width: 'fit-content',
            margin: 'auto',
            marginBottom: '$one',
            '& .GDS-tab-list': {
              borderRadius: '$extraLarge',
              backgroundColor: '$interactiveBackgroundDisabled',
              padding: '6px',
            },
          }}
        >
          {controlTabLabels?.map(({ label }, index) => (
            <PillTab
              key={label}
              value={`${index}`}
              css={{
                borderRadius: '$large',
                marginRight: '$none',
                minWidth: '75px',
                minHeight: '35px',
              }}
            >
              {label}
            </PillTab>
          ))}
        </PillTabList>

        {controlTabContents?.map(({ content, contentKey }, index) => (
          <Tabs.TabPanel key={contentKey} value={`${index}`}>
            {content}
          </Tabs.TabPanel>
        ))}
      </PillTabs>
    </Card.Section>
  </Card.Flat>
);
