import { useMemo } from 'react';
import { isValidUrl, normalizeUrl } from '@leagueplatform/web-common';
import { Link as RouterLink } from '@leagueplatform/routing';

// Utilities
const cleanURL = (url: string) =>
  normalizeUrl(url?.trim().replace(/^app|^\/app/, ''));

// Constants
const StaticDeepLinkProps = Object.freeze({
  target: '_blank',
  rel: 'noopener noreferrer',
});

export const useToolboxLink = (url: string = '') => {
  const linkUrl = url?.trim();
  const isLinkOut = isValidUrl(linkUrl, { protocol: 'http' });

  const linkProps = useMemo(() => {
    if (!linkUrl) return undefined;
    return isLinkOut
      ? { ...StaticDeepLinkProps, as: 'a', href: linkUrl }
      : { as: RouterLink, to: cleanURL(linkUrl) };
  }, [isLinkOut, linkUrl]);

  return { isLinkOut, linkProps };
};
