import { useQueryClient, useMutation } from 'react-query';
import {
  CHALLENGES_PATH,
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
  GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
  completeUserHealthActivity,
} from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useApiDelayController } from '@leagueplatform/health-journey-common';
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';
import { useActivityCompletionData } from './use-activity-completion-data.hook';
import { useActivityFooterErrorMessage } from './use-activity-footer-error-message.hook';
import { GET_USER_HEALTH_ACTIVITY_DATA_KEY } from '../pages/health-activity/hooks/use-get-user-health-activity-by-id.hook';

export const useCompleteUserHealthActivity = (
  userActivityId,
  apiDelayConfig,
) => {
  const queryClient = useQueryClient();
  const { followLink, returnToEntrypoint } = useActivityLink();
  const activityCompletionController = useActivityCompletionData();
  const apiDelayController = useApiDelayController(apiDelayConfig);
  const { applyErrorMessage } = useActivityFooterErrorMessage();

  return useMutation(() => completeUserHealthActivity(userActivityId), {
    onMutate: () => {
      applyErrorMessage.none();
      apiDelayController.init();
    },
    onSuccess: async (data, variables = {}) => {
      const { ctaUrl = '' } = variables;
      await apiDelayController.finish();

      queryClient.invalidateQueries(GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE);
      queryClient.invalidateQueries(GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE);
      queryClient.invalidateQueries(CHALLENGES_PATH);
      activityCompletionController.setCompletionData(data);

      if (ctaUrl) {
        followLink(ctaUrl);
      } else {
        returnToEntrypoint();
      }

      queryClient.invalidateQueries([
        GET_USER_HEALTH_ACTIVITY_DATA_KEY,
        userActivityId,
      ]);

      return data;
    },
    onError: async () => {
      await apiDelayController.finish();
      applyErrorMessage.activityCompleteError();
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_STATE_TITLE',
        contentId: 'ACTIVITY_COMPLETE_ERROR',
      });
    },
  });
};
