import React, { FC } from 'react';
import { ListAttributes } from '@leagueplatform/health-journey-api';
import { Box, Icon, StackLayout } from '@leagueplatform/genesis-core';
import { ToolboxComponentProps } from '../../types/health-activity.types';
import { ActivityToolboxRichText } from './activity-toolbox-rich-text.component';

interface ActivityToolboxListProps
  extends ToolboxComponentProps,
    ListAttributes {}

export const ActivityToolboxList: FC<ActivityToolboxListProps> = ({
  list,
  css,
  componentId,
}) => (
  <StackLayout
    as="ul"
    orientation="vertical"
    spacing="$one"
    css={{ flexGrow: 0, ...css }}
    data-testid={componentId}
  >
    {list?.map((item, index) => {
      const key = `${componentId}-${index}`;
      return (
        <Box as="li" key={key} css={{ display: 'flex', gap: '$one' }}>
          <Icon
            icon="statusSuccessStatusFilled"
            size="24px"
            css={{ color: '$onSurfaceTextPrimary', flexShrink: 0 }}
          />
          <ActivityToolboxRichText richText={item} />
        </Box>
      );
    })}
  </StackLayout>
);
