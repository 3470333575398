import { useQuery, useQueryClient } from 'react-query';
import { COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE } from '@leagueplatform/health-journey-api';

const queryFunctionStub = () => undefined;
const activityCompletionQueryKey = [COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE];

/**
 * Activity completion data controller. Get, set and reset activity completion data.
 * @returns Object containing the current completion data, a method to set the completion data, and a method to reset the completion data
 * ```javascript
 * const activityCompletionData = {
 *    completionData: Object // Cached completion data
 *    setCompletionData: Function // Set completion data to cache
 *    resetCompletionData: Function // Clear cached completion data
 * }
 * ```
 */
export const useActivityCompletionData = () => {
  const queryClient = useQueryClient();

  // Observe any changes to the completion data cache.
  const { data: completionData } = useQuery(
    activityCompletionQueryKey,
    queryFunctionStub,
    {
      staleTime: Infinity,
    },
  );

  const setCompletionDataToCache = (updatedCompletionData) =>
    queryClient.setQueryData(
      [COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE],
      updatedCompletionData,
    );

  const resetCompletionData = () =>
    queryClient.resetQueries(activityCompletionQueryKey);

  return {
    completionData,
    resetCompletionData,
    setCompletionData: setCompletionDataToCache,
  };
};
