import {
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  CONFIGURABLE_ACTIVITY_PRIORITY,
} from '@leagueplatform/health-journey-common';
import { BoxProps } from '@leagueplatform/genesis-commons';

const { DETAILED } = CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
const { HIGH, LOW } = CONFIGURABLE_ACTIVITY_PRIORITY;

const getBackgroundColor = (
  activityPriority: CONFIGURABLE_ACTIVITY_PRIORITY,
) => {
  switch (activityPriority) {
    case HIGH:
      return 'decorative.brand.primary.dark';

    case LOW:
      return 'decorative.brand.primary.brightest';

    default:
      return 'decorative.brand.primary.brightest';
  }
};

export const getPriorityBannerContainerStylingProps = (
  detail: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  priority: CONFIGURABLE_ACTIVITY_PRIORITY,
  display: any,
  hasImage: boolean,
  isProgressActivity: boolean,
) => {
  const boxStylingProps = {
    display,
    paddingY: priority === LOW ? 'none' : 'threeQuarters',
    paddingX: priority === LOW ? 'none' : 'oneAndHalf',
    width: {
      _: '100%',
      tablet:
        detail === DETAILED && hasImage && !isProgressActivity ? '40%' : '100%',
    },
    // Min height to accommodate for if there is no tagline text but banner UI should still be there
    minHeight: priority === LOW ? 'half' : 'twoAndHalf',
    alignSelf: detail === DETAILED ? 'flex-end' : 'auto',
    borderTopRightRadius: {
      _: detail === DETAILED ? 'none' : 'large',
      tablet: 'large',
    },
    borderTopLeftRadius:
      detail === DETAILED && !isProgressActivity && hasImage ? 'none' : 'large',
    backgroundColor: getBackgroundColor(priority),
  } as BoxProps;

  return {
    boxStylingProps,
  };
};
