import { MetricType, MetricUnit } from '@leagueplatform/dashboard-api';
import { useIntl } from '@leagueplatform/locales';
import { dataTypeConfigMap } from 'constants/data-type-config';

export function useLocaleUnitFormatter() {
  const { formatMessage } = useIntl();

  const formatUnit = (type: MetricType, unit: MetricUnit, value = 0) => {
    const metricConfig = dataTypeConfigMap.get(type);

    return metricConfig?.unit
      ? formatMessage({ id: metricConfig.unit }, { count: value })
      : formatMessage({ id: unit.toUpperCase() }, { count: value });
  };

  return { formatUnit };
}
