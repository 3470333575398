import React, { forwardRef, ReactNode, Ref } from 'react';
import {
  GDSStyleObject,
  TextAction,
  styled,
} from '@leagueplatform/genesis-core';
import { normalizeInternalUrl } from '@leagueplatform/web-common';
import { InternalOrExternalLinkButton } from '@leagueplatform/web-common-components';

export type ConditionalLinkRendererProps = {
  children: ReactNode;
  url?: string;
  onClick?: (e: React.MouseEvent) => void;
  css?: GDSStyleObject;
};

const StyledTextAction = styled(TextAction, {
  '&&': {
    all: 'inherit',
  },
});

export const ConditionalLinkRenderer = forwardRef(
  (
    {
      children,
      url,
      onClick = () => {},
      css,
      ...linkProps
    }: ConditionalLinkRendererProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const href = normalizeInternalUrl(url);

    if (url)
      return (
        <InternalOrExternalLinkButton
          baseComponent={StyledTextAction}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
          ref={ref}
          href={href}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...linkProps}
        >
          {children}
        </InternalOrExternalLinkButton>
      );
    return <span>{children}</span>;
  },
);
