import * as React from 'react';
import Masonry from '@leagueplatform/masonry';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';

const MasonryComposer = Masonry.init({
  appId: 'dashboard-metrics',
  apiVersion: 2,
  analytics: {
    productArea: PRODUCT_AREA.DASHBOARD,
    screenName: SCREEN_NAMES.ABOUT_ME_METRICS,
  },
});

export function AllMetricCards() {
  return <MasonryComposer.Provider />;
}
