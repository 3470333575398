import React, { useRef, forwardRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link as LinkDOM } from '@leagueplatform/routing';
import {
  SubtitleOne,
  BodyTwo,
  BodyOneSecondary,
  Overline,
  CoverImage,
  Box,
} from '@leagueplatform/genesis-commons';
import { IntlFormatters } from 'react-intl';
import { TextAction } from '@leagueplatform/genesis-core';
import {
  isBeforeEnrollmentPeriod,
  isEnrollmentOpen,
} from '@leagueplatform/challenges';
import { ConditionalLinkCard } from '@leagueplatform/web-common-components';
import {
  arrayToTag,
  SHORT_DATE_FORMAT,
  useGetUserProfile,
} from '@leagueplatform/web-common';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  ChallengesHealthProgramsCardData,
  HealthProgramsCardData,
} from 'types/health-programs-response-data.type';
import { useProgramLibraryAnalytics } from '../hooks/use-program-library-analytics.hook';

const getChallengeContents = (
  program: ChallengesHealthProgramsCardData,
  formatMessage: IntlFormatters['formatMessage'],
  formatDate: IntlFormatters['formatDate'],
  defaultTimezone?: string,
) => {
  const {
    total_activities_count: totalActivities,
    challenge_info: {
      challenge_type: challengeType,
      start_enroll: startEnroll,
      end_enroll: endEnroll,
      start_date: startDate,
      end_date: endDate,
    },
  } = program;
  const isGroupChallenge = challengeType !== 'solo';
  let signUpText = formatMessage({ id: 'SIGN_UP_DATE_PASSED' });
  // dates in the all programs section come in UTC without a timeoffset
  // eg. 2024-01-01T00:00:00Z
  // dates in the all challenges section come with an offset based on the
  // user's profile location (defaultTimezone).
  // e.g. 2024-01-01T00:00:00-05:00
  // When rendering cards in the all programs section, we need to use UTC
  // as the timezone to render the correct date, while in the all challenges
  // section we need to use the default timezone coming from user's profile
  const isAllProgramsCard = startDate.charAt(startDate.length - 1) === 'Z';
  const dateFormatOptions = {
    ...SHORT_DATE_FORMAT,
    timeZone: isAllProgramsCard ? 'UTC' : defaultTimezone,
  };
  // startEnroll is returning with an undefined value in the all programs section which is causing app crashes. Checking it is defined before calculating enrollment period
  if (startEnroll && isBeforeEnrollmentPeriod(startEnroll, defaultTimezone)) {
    signUpText = formatMessage(
      { id: 'SIGN_UP_BEGINS_DATE' },
      {
        date: formatDate(startEnroll, dateFormatOptions),
      },
    );
  }
  // startEnroll is returning with an undefined value in the all programs section which is causing app crashes. Checking it is defined before calculating enrollment period
  if (
    startEnroll &&
    isEnrollmentOpen(startEnroll, endEnroll, defaultTimezone)
  ) {
    signUpText = formatMessage(
      { id: 'SIGN_UP_BY' },
      {
        date: formatDate(endEnroll, dateFormatOptions),
      },
    );
  }
  const caption = isGroupChallenge
    ? signUpText
    : `${totalActivities} ${formatMessage(
        { id: 'ACTIVITIES_PLURALIZATION' },
        { count: totalActivities },
      ).toLowerCase()}`;
  const description =
    isGroupChallenge &&
    formatMessage(
      { id: 'START_AND_END_DATES' },
      {
        startDate: formatDate(startDate, dateFormatOptions),
        endDate: formatDate(endDate, dateFormatOptions),
      },
    );
  return {
    caption,
    description,
  };
};
const getHealthContents = (
  program: HealthProgramsCardData,
  formatMessage: IntlFormatters['formatMessage'],
  formatNumber: IntlFormatters['formatNumber'],
) => {
  const {
    description,
    available_points: availablePoints,
    total_activities_count: total,
  } = program;

  const caption = arrayToTag([
    `${total} ${formatMessage(
      { id: 'ACTIVITIES_PLURALIZATION' },
      { count: total },
    ).toLowerCase()}`,
    availablePoints
      ? formatMessage(
          { id: 'VAL_POINTS' },
          {
            points: formatNumber(availablePoints, { style: 'decimal' }),
          },
        )
      : null,
  ]);

  return {
    caption,
    description,
  };
};
function isProgramAChallenge(
  program: ChallengesHealthProgramsCardData | HealthProgramsCardData,
): program is ChallengesHealthProgramsCardData {
  return (
    (program as ChallengesHealthProgramsCardData).challenge_info !==
      undefined &&
    (program as ChallengesHealthProgramsCardData).challenge_info !== null
  );
}
export interface HealthProgramsCardProps {
  tag?: string | null;
  analyticsContext?: {
    pageContext?: string;
    programCategory: string | null;
    carouselName: string | null;
    carouselIndex: number | null;
    metricType?: string;
  };
  program: ChallengesHealthProgramsCardData | HealthProgramsCardData;
}
export const HealthProgramsCard = forwardRef(
  (
    {
      tag = null,
      analyticsContext = {
        carouselName: null,
        carouselIndex: null,
        programCategory: null,
        pageContext: undefined,
      },
      program,
    }: HealthProgramsCardProps,
    ref,
  ) => {
    const { formatDate, formatMessage, formatNumber } = useIntl();
    const programLibraryAnalytics = useProgramLibraryAnalytics();
    const linkRef = useRef(null);
    const { data: userData } = useGetUserProfile({ staleTime: Infinity });
    const { defaultTimezone } = userData || {};
    const { name, program_id: programId, image_url: imageUrl } = program;
    const isChallenge = isProgramAChallenge(program);
    const { description, caption } = isChallenge
      ? getChallengeContents(
          program,
          formatMessage,
          formatDate,
          defaultTimezone,
        )
      : getHealthContents(program, formatMessage, formatNumber);

    const getLinkPath = () =>
      isChallenge
        ? JOURNEY_ROUTES.getChallengeDetails(programId)
        : JOURNEY_ROUTES.getProgramDetails(programId);
    return (
      <ConditionalLinkCard
        hasLink
        linkRef={linkRef}
        cardStyle={{
          // adding ts-ignore since we need to override the original boxShadow value for ConditionalLinkCard and ShadowPropsType doesn't include 'none'
          // @ts-ignore
          boxShadow: 'none',
        }}
      >
        <Box
          ref={ref}
          position="relative"
          flexDirection="column"
          justifyContent="center"
          paddingY="half"
          width={{ _: 155, phone: 299, laptop: 384 }}
          maxWidth="100%"
          marginTop="one"
          marginBottom="two"
        >
          <CoverImage
            src={imageUrl}
            alt=""
            width="100%"
            maxWidth="100%"
            height={{ _: 111, phone: 190, laptop: 235 }}
            borderRadius="medium"
            marginBottom="one"
            backgroundColor="surface.background.secondary"
            position="relative"
          />
          {tag && (
            <Overline as="p" color="onSurface.text.subdued">
              {tag}
            </Overline>
          )}
          <SubtitleOne as="h3">
            <TextAction
              ref={linkRef}
              as={LinkDOM}
              to={getLinkPath()}
              css={{
                color: '$onSurfaceTextPrimary',
                fontWeight: 'inherit',
                fontSize: 'inherit',
                '&&:focus': {
                  outline: 'none',
                },
              }}
              onClick={() =>
                programLibraryAnalytics.sendSelectProgram({
                  ...analyticsContext,
                  isChallenge,
                  program,
                })
              }
            >
              {name}
            </TextAction>
          </SubtitleOne>
          <BodyTwo color="onSurface.text.primary" paddingBottom="half">
            {caption}
          </BodyTwo>
          <BodyOneSecondary
            marginTop="quarter"
            display={{ _: 'none', phone: 'block' }}
          >
            {description}
          </BodyOneSecondary>
        </Box>
      </ConditionalLinkCard>
    );
  },
);

export default HealthProgramsCard;
