import * as React from 'react';
import { node, string } from 'prop-types';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  HeadingOne,
  Overline,
} from '@leagueplatform/genesis-commons';
import { DynamicCoverImage } from './dynamic-image.component';

const ActivityDetailsHeaderGroup = genesisStyled(Flex)(
  css({
    '&&': {
      borderBottomColor: 'onSurface.border.subdued',
      borderBottomStyle: 'solid',
      borderBottomWidth: 'thin',
    },
  }),
);

export const ActivityDetailsHeader = (props) => {
  const { overline, title, iconUrl, children, ...rest } = props;
  return (
    <ActivityDetailsHeaderGroup
      flexDirection={{ _: 'column-reverse', phone: 'row' }}
      justifyContent="space-between"
      marginTop="half"
      marginBottom="oneAndHalf"
      width="100%"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...rest}
    >
      <Box>
        {overline && (
          <Overline as="p" marginBottom="half" color="onSurface.text.subdued">
            {overline}
          </Overline>
        )}
        <HeadingOne marginBottom="one">{title}</HeadingOne>
        {children}
      </Box>
      {iconUrl && (
        <DynamicCoverImage
          alt=""
          backgroundColor="surface.background.primary"
          borderRadius="circle"
          marginBottom={{ _: 'one', phone: 'none' }}
          minWidth={{ _: '48px', phone: '96px' }}
          size={{ _: 48, phone: 96 }}
          src={iconUrl}
        />
      )}
    </ActivityDetailsHeaderGroup>
  );
};

ActivityDetailsHeader.propTypes = {
  overline: string,
  title: string,
  iconUrl: string,
  children: node,
};

ActivityDetailsHeader.defaultProps = {
  overline: '',
  title: '',
  iconUrl: '',
  children: [],
};
