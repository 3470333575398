import * as React from 'react';
import {
  PillTabs,
  PillTabList,
  PillTab,
} from '@leagueplatform/web-common-components';
import { Tabs, Box, StackLayout } from '@leagueplatform/genesis-core';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import {
  ProviderServiceNetworks,
  ProviderServiceNetwork,
} from 'pages/wallet-detail/types/medical-network.types';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { WALLET_LOCAL_FEATURE_FLAGS } from 'common/feature-flags';
import { getWalletLocalFeatureFlag } from 'utils/get-wallet-local-feature-flag.util';
import { NetworkPanel } from './network-panel.component';

interface NetworksCoverageProps {
  networks: ProviderServiceNetworks;
}

interface NoNetworkCoverageProps {
  services: ProviderServiceNetwork;
}

export const NetworksCoverage = ({ networks }: NetworksCoverageProps) => {
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics({
    screen_name: BENEFIT_SCREEN_NAMES.COVERAGE_SUMMARY,
  });
  const isNoNetworkFlagEnabled = getWalletLocalFeatureFlag(
    WALLET_LOCAL_FEATURE_FLAGS.IS_NO_NETWORK_LIVE_BALANCES_ENABLED,
  );

  const onTabClickEvent = React.useCallback(
    (index) => {
      const tabName = networks[index].analyticsId;
      sendBenefitAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
        detail: tabName,
      });
    },
    [sendBenefitAnalyticsEvent, networks],
  );

  const onAccordionToggleEvent = React.useCallback(
    (serviceName: string, state: 'expand' | 'collapse') => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `toggle ${serviceName} accordion`,
        sub_detail: state,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  if (!networks.length) {
    return null;
  }

  if (networks.length === 1 && isNoNetworkFlagEnabled) {
    return (
      <StackLayout spacing="$oneAndHalf">
        {networks.map((network) => (
          <NetworkPanel
            key={`network-panel-${network.analyticsId}`}
            services={network.services}
            networkName={network.label}
            onToggle={onAccordionToggleEvent}
          />
        ))}
      </StackLayout>
    );
  }

  return (
    <PillTabs
      onValueChange={(value) =>
        onTabClickEvent(
          networks.findIndex((network) => network.label === value),
        )
      }
      defaultValue={networks[0].label}
    >
      <StackLayout spacing="$oneAndHalf">
        <Box css={{ width: 'fit-content' }}>
          <PillTabList>
            {networks.map((network) => (
              <PillTab key={`pill-tab-${network.label}`} value={network.label}>
                {network.label}
              </PillTab>
            ))}
          </PillTabList>
        </Box>
        {networks.map((network) => (
          <Tabs.TabPanel
            key={`tab-panel-${network.label}`}
            value={network.label}
            css={{ width: '100%', padding: '$none' }}
          >
            <NetworkPanel
              services={network.services}
              networkName={network.label}
              onToggle={onAccordionToggleEvent}
            />
          </Tabs.TabPanel>
        ))}
      </StackLayout>
    </PillTabs>
  );
};

export const NoNetworkCoverage = ({
  services: { services },
}: NoNetworkCoverageProps) => {
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics({
    screen_name: BENEFIT_SCREEN_NAMES.COVERAGE_SUMMARY,
  });

  const onAccordionToggleEvent = React.useCallback(
    (serviceName: string, state: 'expand' | 'collapse') => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `toggle ${serviceName} accordion`,
        sub_detail: state,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  return (
    <Box css={{ width: '100%' }}>
      <NetworkPanel services={services} onToggle={onAccordionToggleEvent} />
    </Box>
  );
};
