import React, { FC } from 'react';
// modules
import {
  UtilityText,
  GDSColor,
  ParagraphText,
  Box,
  Icon,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  BannerAttributes,
  BannerTypes,
} from '@leagueplatform/health-journey-api';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
// types

// components
import { ActivityToolboxRichText } from './activity-toolbox-rich-text.component';

// interface
interface ActivityToolboxBannerProps
  extends ToolboxComponentProps,
    BannerAttributes {}

type BannerStyles = {
  [key in BannerTypes]: {
    background: GDSColor;
    titleColor: GDSColor;
  };
};

const BannerText: FC<{}> = ({ children, ...props }) => (
  <ParagraphText css={{ ...props }}>{children}</ParagraphText>
);

const options = {
  [BannerTypes.primary]: {
    p: {
      component: BannerText,
      props: {
        color: '$onSurfaceTextPrimary',
        textAlign: 'center',
        typography: '$subtitleOne',
      },
    },
  },
  [BannerTypes.secondary]: {
    p: {
      component: BannerText,
      props: {
        color: '$onSurfaceTextPrimary',
        textAlign: 'center',
        typography: '$subtitleOne',
      },
    },
  },
  [BannerTypes.subtle]: {
    p: {
      component: BannerText,
      props: {
        color: '$onSurfaceTextSubdued',
        textAlign: 'left',
        typography: '$caption',
      },
    },
  },
};

const backgroundColorContainer: BannerStyles = {
  [BannerTypes.primary]: {
    background: '$decorativeBrandPrimaryPastel',
    titleColor: '$onSurfaceTextPrimary',
  },
  [BannerTypes.secondary]: {
    background: '$decorativeBrandSecondaryPastel',
    titleColor: '$onSurfaceTextPrimary',
  },
  [BannerTypes.subtle]: {
    background: '$surfaceBackgroundSecondary',
    titleColor: '$onSurfaceTextSubdued',
  },
};

export const ActivityToolboxBanner: FC<ActivityToolboxBannerProps> = ({
  title,
  type,
  iconUrl,
  richText,
  css,
  componentId,
}) => {
  const isVisible = !!(richText || title);
  const emphasisType =
    type === BannerTypes.primary || type === BannerTypes.secondary;

  const { background, titleColor } =
    backgroundColorContainer[type] ??
    backgroundColorContainer[BannerTypes.subtle];

  if (!isVisible) {
    return null;
  }
  return (
    <Box css={css} data-testid={componentId}>
      <StackLayout
        horizontalAlignment={emphasisType ? 'center' : 'start'}
        css={{
          backgroundColor: background,
          borderRadius: '$medium',
          padding: '$one',
        }}
      >
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          horizontalAlignment={emphasisType ? 'center' : 'start'}
          spacing="$one"
          css={{
            paddingBottom: '$one',
          }}
        >
          {iconUrl ? (
            <Box
              as="img"
              alt=""
              src={iconUrl}
              css={{ width: '$one', height: '$one' }}
            />
          ) : (
            <Icon
              icon="interfaceQuestionCircleFilled"
              size="16px"
              tint="$onSurfaceIconPrimary"
            />
          )}
          {title && (
            <UtilityText css={{ color: titleColor }} size="eyebrow">
              {title}
            </UtilityText>
          )}
        </StackLayout>
        {richText && (
          <ActivityToolboxRichText
            richText={richText}
            options={options[type]}
          />
        )}
      </StackLayout>
    </Box>
  );
};
