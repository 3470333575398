import React, { FC } from 'react';

// Common Dependencies
import { Box, StackLayout, styled } from '@leagueplatform/genesis-core';

// Hooks

// Common Components

// Types

// Constants
import {
  ACTIVITY_CONTENT_BASE_WIDTH,
  TRANSITIONS,
} from '../constants/health-activity-design.constants';
import { useActivityToolboxTheme } from '../hooks/use-activity-toolbox-theme.hook';

// Local Types
interface HealthActivityLayoutProps {}

// Local Components
const FullPageHeight = styled(Box, {
  height: '100%',
  inset: 0,
  position: 'absolute',
});

const ContentPosition = styled(StackLayout, {
  padding: '$oneAndHalf 0',
  transition: `all ${TRANSITIONS.PAGE}`,
  overflowX: 'hidden',
  overflowY: 'auto',
});
ContentPosition.defaultProps = { horizontalAlignment: 'center' };

const ContentWrapper = styled(StackLayout, {
  position: 'relative',
  minHeight: ACTIVITY_CONTENT_BASE_WIDTH,
  width: ACTIVITY_CONTENT_BASE_WIDTH,
  maxWidth: '100%',
});
ContentWrapper.defaultProps = {
  orientation: 'vertical',
  horizontalAlignment: 'center',
};

// TODO – JRNY-X – remove unneeded props when API hook is implemented within this component
export const HealthActivityLayout: FC<HealthActivityLayoutProps> = ({
  children,
}) => {
  const { activityToolboxTheme = {} } = useActivityToolboxTheme();
  const { backgroundColor = '$surfaceBackgroundSecondary' } =
    activityToolboxTheme;
  return (
    <FullPageHeight data-testid="health-activity-page" aria-live="polite">
      <ContentPosition
        css={{ backgroundColor }}
        data-testid="health-activity-layout"
      >
        <ContentWrapper data-testid="health-activity-content-wrapper">
          {children}
        </ContentWrapper>
      </ContentPosition>
    </FullPageHeight>
  );
};
