import React, { FC, forwardRef } from 'react';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { styled } from '../../theme';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSImagePosition,
} from '../../types';
import { CardSection } from './card-section';
import { CardImage } from './card-image';
import { CardClickArea, ImagePositionWrapper } from './card-click-area';

export interface GDSCardProps extends GDSCustomizableComponent {
  children: React.ReactNode;
  imagePosition?: GDSResponsiveProp<GDSImagePosition>;
  backgroundImage?: string;
  backgroundOverlay?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

export type GDSCardBackgroundColor = 'primary' | 'secondary' | 'highlight';

export interface GDSCardElevatedProps extends GDSCardProps {
  backgroundColor?: GDSCardBackgroundColor;
}

export interface GDSCardFlatProps extends GDSCardProps {
  backgroundColor?: 'transparent' | GDSCardBackgroundColor;
}

const overlayStyles = {
  backgroundOverlay: {
    true: {
      '&:before': {
        content: '',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: '40%',
      },
    },
  },
};

const BaseCardContainer = styled(ImagePositionWrapper, {
  position: 'relative',
});

const ElevatedCardBase = styled(BaseCardContainer, {
  border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
  boxShadow: '$card',
  '&:has(.GDS-card-click-area)': {
    border: 'none',
    boxShadow: 'none',
  },
  variants: {
    ...overlayStyles,
    backgroundColor: {
      primary: {
        backgroundColor: '$surfaceCardPrimary',
      },
      secondary: {
        backgroundColor: '$surfaceCardSecondary',
      },
      highlight: {
        backgroundColor: '$surfaceBackgroundTertiary',
      },
    },
  },
  compoundVariants: [
    {
      backgroundColor: 'primary',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceCardPrimary',
        },
      },
    },
    {
      backgroundColor: 'secondary',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceCardSecondary',
        },
      },
    },
    {
      backgroundColor: 'highlight',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceBackgroundTertiary',
        },
      },
    },
  ],
});

const FlatCardBase = styled(BaseCardContainer, {
  variants: {
    ...overlayStyles,
    backgroundColor: {
      transparent: {
        backgroundColor: 'transparent',
      },
      primary: {
        backgroundColor: '$surfaceBackgroundPrimary',
      },
      secondary: {
        backgroundColor: '$surfaceBackgroundSecondary',
      },
      highlight: {
        backgroundColor: '$surfaceBackgroundTertiary',
      },
    },
  },
  compoundVariants: [
    {
      backgroundColor: 'primary',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceBackgroundPrimary',
        },
      },
    },
    {
      backgroundColor: 'secondary',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceBackgroundSecondary',
        },
      },
    },
    {
      backgroundColor: 'highlight',
      backgroundOverlay: 'true',
      css: {
        '&:before': {
          backgroundColor: '$surfaceBackgroundTertiary',
        },
      },
    },
  ],
});

const ElevatedCardContainer: FC<GDSCardElevatedProps> = forwardRef(
  (
    {
      backgroundColor = 'primary',
      backgroundImage,
      backgroundOverlay,
      children,
      className,
      css,
      imagePosition = 'top',
      ...props
    }: GDSCardElevatedProps,
    ref,
  ) => {
    const cardImagePosition = useResponsiveProp(imagePosition);
    return (
      <ElevatedCardBase
        backgroundColor={backgroundColor}
        backgroundOverlay={backgroundOverlay}
        className={['GDS-elevated-card', className].join(' ')}
        css={{
          ...css,
          ...(backgroundImage && {
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }),
        }}
        imagePosition={cardImagePosition}
        ref={ref}
        {...props}
      >
        {children}
      </ElevatedCardBase>
    );
  },
);

const FlatCardContainer: FC<GDSCardFlatProps> = forwardRef(
  (
    {
      backgroundColor = 'primary',
      backgroundImage,
      backgroundOverlay,
      children,
      css,
      className,
      imagePosition = 'top',
      ...props
    }: GDSCardFlatProps,
    ref,
  ) => {
    const cardImagePosition = useResponsiveProp(imagePosition);
    return (
      <FlatCardBase
        backgroundColor={backgroundColor}
        backgroundOverlay={backgroundOverlay}
        className={['GDS-flat-card', className].join(' ')}
        css={{
          ...css,
          ...(backgroundImage && {
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }),
        }}
        imagePosition={cardImagePosition}
        ref={ref}
        {...props}
      >
        {children}
      </FlatCardBase>
    );
  },
);

export const Card = {
  Flat: FlatCardContainer,
  Elevated: ElevatedCardContainer,
  Section: CardSection,
  Image: CardImage,
  ClickArea: CardClickArea,
};

Card.Flat.displayName = 'Card.Flat';
Card.Elevated.displayName = 'Card.Elevated';
Card.Section.displayName = 'Card.Section';
Card.Image.displayName = 'Card.Image';
Card.ClickArea.displayName = 'Card.ClickArea';
