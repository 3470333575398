import React from 'react';
import PropTypes, { shape, string } from 'prop-types';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useIntl } from '@leagueplatform/locales';
import { uniqueId, get, find } from 'lodash';
import { arrayToTag } from '@leagueplatform/web-common';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { Carousel } from '@leagueplatform/web-common-components';
import {
  CHALLENGE,
  ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
} from '@leagueplatform/health-journey-common';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { HealthProgramsCard } from './health-programs-card.component';
import { programsListPropType } from '../utils/health-programs-page.props';

export const HealthProgramsList = ({
  data,
  title,
  description,
  sortKey,
  id,
  hideTag,
  categories,
  showProgramDescription,
  analyticsContext,
  linkCta,
  linkUrl,
}) => {
  const { formatMessage } = useIntl();
  const { data: isChallengesWellnessAndRewardsEnabled } = useFeatureFlagQuery(
    ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
  );
  // This will sort a list by sortKey ASC, if sortKey is null the order won't change
  const sortCallback = (a, b) => a[sortKey] - b[sortKey];

  const RightNavButtonAnalyticsFn = () => {
    sendAnalyticsEvent({
      category: analyticsContext?.pageContext,
      action: 'Scroll Carousel',
      params: {
        carousel_name: analyticsContext?.carouselName,
      },
    });
  };

  return (
    data.length > 0 && (
      <Flex
        id={id}
        marginBottom="one"
        maxWidth={{
          _: '345px',
          phone: '640px',
          laptop: '895px',
          desktop: '1210px',
        }}
      >
        <Carousel
          heading={title}
          description={description}
          name="health-programs-list"
          RightNavButtonAnalyticsFn={RightNavButtonAnalyticsFn}
          linkCta={linkCta}
          linkUrl={linkUrl}
        >
          {data.sort(sortCallback).map((item, index) => {
            // Match category tag IDs to translated category names
            // if the program has no 'tag' ID field, use the 'categories' field instead
            const tags = (item?.tag || item?.categories) ?? [];
            const categoryTag = tags.map((tag) => {
              const programCategory = find(
                categories,
                (category) => category.id === tag,
              );
              return get(programCategory, 'name', '');
            });
            const showChallengeTag =
              item.type === CHALLENGE &&
              item.challenge_info.challenge_type &&
              isChallengesWellnessAndRewardsEnabled;
            return (
              <Box
                marginRight="half"
                key={uniqueId('health-program-card-', item.programId)}
              >
                <HealthProgramsCard
                  program={item}
                  analyticsContext={{
                    ...analyticsContext,
                    carouselIndex: index,
                  }}
                  tag={
                    showChallengeTag
                      ? formatMessage({
                          id: `${item.challenge_info.challenge_type.toUpperCase()}_CHALLENGE`,
                        })
                      : !hideTag && arrayToTag(categoryTag)
                  }
                  description={showProgramDescription ? item.description : null}
                />
              </Box>
            );
          })}
        </Carousel>
      </Flex>
    )
  );
};

HealthProgramsList.propTypes = {
  data: programsListPropType,
  title: PropTypes.node,
  description: PropTypes.node,
  sortKey: PropTypes.string,
  id: PropTypes.string,
  hideTag: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      sortOrder: PropTypes.number.isRequired,
    }),
  ),
  showProgramDescription: PropTypes.bool,
  analyticsContext: shape({ carouselName: string, pageContext: string }),
  linkCta: PropTypes.string,
  linkUrl: PropTypes.string,
};

HealthProgramsList.defaultProps = {
  data: [],
  sortKey: null,
  id: '',
  hideTag: false,
  title: '',
  description: '',
  categories: [],
  showProgramDescription: true,
  analyticsContext: {
    pageContext: undefined,
    carouselName: '',
  },
  linkCta: '',
  linkUrl: '',
};

export default HealthProgramsList;
