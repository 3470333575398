import React, { forwardRef } from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import {
  Button,
  TextAction,
  GDSButtonProps,
  GDSTextActionProps,
} from '@leagueplatform/genesis-core';
import { isExternalUrl } from '@leagueplatform/web-common';
import { useConfigProperty } from '@leagueplatform/core';

export type InternalOrExternalLinkButtonProps = Partial<
  GDSButtonProps<'button'>
> &
  Partial<GDSTextActionProps<'a'>> & {
    href: string;
    children: React.ReactNode;
    isExternalUrl?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    baseComponent?: typeof TextAction | typeof Button | React.ComponentType;
  };

// A component that will render a genesis link/genesis button for external urls or a react router link for internal urls.
export const InternalOrExternalLinkButton = forwardRef<
  HTMLAnchorElement | null,
  InternalOrExternalLinkButtonProps
>(({ href, children, baseComponent, onClick, ...linkProps }, ref) => {
  const { isExternalUrl: isExternalLink = isExternalUrl(href) } = linkProps;
  const isExternalUrlOrPhoneNumber = isExternalLink || href.includes('tel:');
  const Component = baseComponent ?? Button;

  const handleLink = useConfigProperty('core.customMethods.handleLink');

  return (
    <Component
      ref={ref}
      as={isExternalUrlOrPhoneNumber ? 'a' : RouterLink}
      // @ts-ignore-next-line
      to={isExternalUrlOrPhoneNumber ? undefined : href} // for Router Link
      href={isExternalUrlOrPhoneNumber ? href : undefined} // for Genesis Link
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
      onClick={(e: React.MouseEvent) => {
        if (isExternalLink) {
          e.preventDefault();
          handleLink(href);
        }
        if (onClick) {
          onClick(e);
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
    >
      {children}
    </Component>
  );
});
