import React from 'react';
import { FeedbackCard } from '@leagueplatform/web-common-components';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useUserProfileQuery } from 'hooks/queries/use-user-profile-query.hook';
import { getTypeformUrl } from '@leagueplatform/web-common';
import { useWalletConfig } from 'wallet.config';
import { getCardNameFromType } from './utils/get-card-name-from-type.util';
import { useWalletDetailAnalytics } from '../../hooks/use-wallet-detail-analytics.hook';
import { useWalletDetailQuery } from '../../hooks/queries/use-wallet-detail-query.hook';
import { getBenefitTranslationIdForType } from '../../utils/get-benefit-translation-id.util';

export const Feedback = () => {
  const { typeformIds } = useWalletConfig();
  const typeformData = typeformIds ? getTypeformUrl(typeformIds) : null;
  const { userId: urlUserId, benefitId } = useWalletParams();
  const { formatMessage } = useIntl();
  const { data: benefitCard } = useWalletDetailQuery(urlUserId, benefitId);
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  // if urlUserId is `undefined`, fetch it from the user profile
  const { data: userId } = useUserProfileQuery(urlUserId, {
    select: (data) => data.user_id,
    enabled: !!typeformData,
  });

  const groupName = benefitCard?.provider_name;
  const benefitName = benefitCard
    ? benefitCard.short_name ||
      benefitCard.full_name ||
      getCardNameFromType(benefitCard.type)
    : '';

  if (!typeformData) {
    // if typeform IDs are not set, don't render feedback component
    return null;
  }

  const feedbackUrl = `${typeformData.url}?member_id=${userId}&group_name=${groupName}&benefit_name=${benefitName}&platform=web&prefilled_answer=`;

  const nameTranslationId = benefitCard
    ? getBenefitTranslationIdForType(benefitCard.product_type)
    : '';

  const subtitle = benefitCard?.full_name
    ? formatMessage(
        { id: 'WALLET_FEEDBACK_SUBTITLE_BENEFIT' },
        {
          benefitName: benefitCard.full_name,
        },
      )
    : formatMessage(
        { id: 'WALLET_FEEDBACK_SUBTITLE_OTHER' },
        {
          cardName: formatMessage({ id: nameTranslationId }),
        },
      );

  return (
    <FeedbackCard
      title={formatMessage({ id: 'WALLET_FEEDBACK_TITLE' })}
      subtitle={subtitle}
      feedbackUrl={feedbackUrl}
      onClick={(number: number) => {
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          detail: 'likert scale response',
          sub_detail: number,
          form_id: typeformData.id,
        });
      }}
    />
  );
};
