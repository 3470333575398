import React from 'react';
import {
  LoadingIndicator,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { HeadingText, Tabs } from '@leagueplatform/genesis-core';
import { NavigationSidebar } from './components/navigation-sidebar/navigation-sidebar.component';
import { NavigationGrid } from './components/navigation-grid/navigation-grid.component';
import { useNavigationSections } from './hooks/use-navigation-sections';

interface WalletItemNavigationSectionsProps {
  userId?: string;
  userRole?: string;
  layout?: 'grid' | 'sidebar';
}

export const WalletItemNavigationSections = ({
  userId,
  userRole,
  layout = 'grid',
}: WalletItemNavigationSectionsProps) => {
  const { isLoading, itemSectioned, benefitSections, cardStatuses, heading } =
    useNavigationSections();

  const { formatMessage } = useIntl();

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  if (layout === 'sidebar') {
    return (
      <NavigationSidebar
        userId={userId}
        userRole={userRole}
        navigationSections={itemSectioned}
      />
    );
  }

  if (cardStatuses.length === 1) {
    const singleBenefitSection = benefitSections[cardStatuses[0]];

    return (
      <StackLayout>
        {singleBenefitSection &&
          singleBenefitSection.map((statuses) => (
            <React.Fragment key={statuses.section_id}>
              {statuses.sections.map((section) => (
                <NavigationGrid
                  key={`${statuses.section_id}-${section.section_id}`}
                  navigationSections={section}
                />
              ))}
            </React.Fragment>
          ))}
      </StackLayout>
    );
  }

  return (
    <StackLayout>
      <HeadingText size="xl" level="2">
        {heading}
      </HeadingText>
      <Tabs.Root defaultValue={'active-tab' || `${cardStatuses[0]}-tab`}>
        <StackLayout space="$three">
          <Tabs.TabList tabDistribution="hugContents" divider>
            {cardStatuses.map((status) => (
              <Tabs.Tab value={`${status}-tab`} key={`${status}-tab`}>
                {formatMessage({
                  id: `WALLET_BENEFIT_STATUS_${status.toUpperCase()}`,
                })}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>

          {/* For each status create a tab-panel */}
          {cardStatuses.map((status) => (
            <Tabs.TabPanel value={`${status}-tab`} key={`${status}-tab-panel`}>
              <StackLayout>
                {benefitSections[status]?.map((statuses) => (
                  <React.Fragment key={statuses.section_id}>
                    {statuses.sections.map((section) => (
                      <NavigationGrid
                        key={`${statuses.section_id}-${section.section_id}`}
                        navigationSections={section}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </StackLayout>
            </Tabs.TabPanel>
          ))}
        </StackLayout>
      </Tabs.Root>
    </StackLayout>
  );
};
