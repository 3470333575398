import { getConfig } from '@leagueplatform/config';
import {
  captureError,
  combineErrorContexts,
} from '@leagueplatform/observability';
import type { ErrorContext } from '@leagueplatform/observability';
import {
  combineHeaders,
  getLeagueFetchOptions,
  getAcceptLanguageHeader,
} from './utils';

interface FetchOptions extends RequestInit {
  errorContext?: ErrorContext;
}

export const leagueFetch = async (path: string, options: FetchOptions = {}) => {
  const leagueFetchOptions = await getLeagueFetchOptions();
  const getCurrentLocale = getAcceptLanguageHeader();

  // Note - Enable this if you want to work with mock server
  // const url = path.match(/^http/)
  //   ? path
  //   : `${getConfig().core.api.url}${path.replace(/^\/?/, '/')}`;
  const url = `${getConfig().core.api.url}${path.replace(/^\/?/, '/')}`;

  const { errorContext: optionalErrorContext = {}, ...fetchOptions } = options;

  const promise = fetch(url, {
    ...fetchOptions,
    ...leagueFetchOptions,
    headers: combineHeaders(fetchOptions, leagueFetchOptions, getCurrentLocale),
  });

  const response = await promise;

  if (!response.ok) {
    const body = await response.clone().json();
    const errorDetail = body.errors?.[0]?.detail ?? 'An error has occurred';
    const fetchErrorContext = combineErrorContexts([
      {
        errorName: 'Network Fetch Error',
        tags: { requestUrl: response.url },
        context: {
          Errors: {
            ...body.errors,
          },
          Response: {
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            type: response.type,
            url: response.url,
            method: fetchOptions.method ?? 'GET',
          },
        },
      },
      optionalErrorContext,
    ]);
    captureError(new Error(errorDetail), fetchErrorContext);
  }

  return promise;
};
