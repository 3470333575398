import {
  boolean,
  object,
  partial,
  optional,
  string,
  any,
  record,
} from 'superstruct';

export const claimsConfigSchema = object({
  filterableProperties: optional(record(string(), any())),
  featureFlags: optional(
    partial(
      object({
        shouldShowClaimDetails: boolean(),
        shouldShowDownloadClaims: boolean(),
        shouldShowFilterChips: boolean(),
      }),
    ),
  ),
  supportLink: optional(string()),
  showTotalCostOverviewAndAdditionalResources: optional(boolean()),
  showClaimStatusDefinitions: optional(boolean()),
  showClaimsSummaryBackButton: optional(boolean()),
  enableDynamicFilters: optional(boolean()),
});
