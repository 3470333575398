import React from 'react';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import {
  css,
  genesisStyled,
  Flex,
  BackgroundColorSystemProp,
  StyleProps,
} from '@leagueplatform/genesis-commons';

import { InternalOrExternalLink } from '../internal-or-external-link/internal-or-external-link.component';
import { StylesProp } from '../../types/styles-prop.types';
import { OpensInANewTabMessage } from '../opens-in-a-new-tab-message/opens-in-a-new-tab-message.component';

const StaticWrapper = genesisStyled(Flex)<StyleProps>(
  ({ backgroundColor, theme, styles }) =>
    css({
      flex: 1,
      flexDirection: 'column-reverse',
      backgroundColor: backgroundColor || 'surface.card.primary',
      borderStyle: 'solid',
      borderColor: 'onSurface.border.subdued',
      borderWidth: 'thin',
      borderRadius: 'large',
      overflow: 'hidden',
      [`@media screen and (min-width: ${theme.breakpoints.phone})`]: {
        minHeight: '200px',
        flexDirection: 'row',
      },
      ...styles,
    }),
);

const LinkableWrapper = genesisStyled(StaticWrapper)(({ backgroundColor }) =>
  css({
    '&::before': {
      borderRadius: 'large',
    },
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: !backgroundColor && 'interactive.background.hovered',
      borderColor: 'interactive.border.hovered',
    },
    '&:active': {
      backgroundColor: !backgroundColor && 'interactive.background.pressed',
    },
  }),
);

const StyledChildren = genesisStyled(Flex)(
  css({
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 'oneAndHalf',
  }),
);

const StyledImageContainer = genesisStyled(Flex)<StyleProps>(
  ({ styles, theme, src }) =>
    css({
      [`@media screen and (min-width: ${theme.breakpoints.phone})`]: {
        maxWidth: '280px',
        flexBasis: '100%',
        flexShrink: '0',
        borderLeftStyle: 'solid',
        borderColor: 'onSurface.border.subdued',
        borderWidth: 'thin',
      },
      width: '100%',
      minHeight: '200px',
      background: `url(${src}) center no-repeat`,
      backgroundSize: 'cover',
      ...styles,
    }),
);

interface CardBannerProp {
  image: string;
  children: React.ReactNode;
  url?: string;
  backgroundColor?: BackgroundColorSystemProp;
  styles?: StylesProp<'imageContainer' | 'wrapper'>;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const CardBanner = ({
  url,
  image,
  children,
  backgroundColor,
  styles,
  onClick,
}: CardBannerProp) => {
  if (url) {
    const isExternalUrl = isAbsoluteUrl(url);
    return (
      <LinkableWrapper
        href={url}
        onClick={onClick}
        isExternalUrl={isExternalUrl}
        as={InternalOrExternalLink}
        backgroundColor={backgroundColor}
        styles={styles?.wrapper}
      >
        <StyledChildren>
          {children}
          {isExternalUrl && <OpensInANewTabMessage />}
        </StyledChildren>
        <StyledImageContainer styles={styles?.imageContainer} src={image} />
      </LinkableWrapper>
    );
  }

  return (
    <StaticWrapper backgroundColor={backgroundColor} styles={styles?.wrapper}>
      <StyledChildren>{children}</StyledChildren>
      <StyledImageContainer styles={styles?.imageContainer} src={image} />
    </StaticWrapper>
  );
};
