import React, { FC, ReactNode } from 'react';
import { styled } from '../../../theme';
import { generateLegend, generateInputHint } from '../component-generators';
import { InputStatusMessage } from '../form-field-elements/input-status-message';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSInputStatus,
} from '../../../types';

export interface GDSFieldsetProps extends GDSCustomizableComponent {
  children: ReactNode;
  id?: string;
  legend: ReactNode;
  hideLegend?: GDSResponsiveProp<boolean>;
  hint?: ReactNode;
  inputStatus?: GDSInputStatus;
  statusMessage?: string;
  required?: boolean;
  tooltip?: ReactNode;
}

const FieldsetBase = styled('fieldset', {
  border: 0,
  margin: 0,
  padding: 0,
  width: '100%',
});

export const Fieldset: FC<GDSFieldsetProps> = ({
  children,
  className,
  css,
  hideLegend = false,
  hint,
  id,
  inputStatus,
  legend,
  required = true,
  statusMessage,
  tooltip,
  ...props
}: GDSFieldsetProps) => {
  const statusMessageId = id ? `${id}-status-message` : '';
  const hintId = id ? `${id}-hint` : '';

  // Warn that hint and status message cannot be used without the id
  if ((hint || statusMessage) && !id)
    console.warn(
      'To use hint or statusMessage, you must also pass the id to Fieldset.',
    );

  return (
    <FieldsetBase
      className={['GDS-fieldset', className].join(' ')}
      css={css}
      {...(id && { 'aria-describedby': `${statusMessageId} ${hintId}` })}
      {...props}
    >
      {legend &&
        generateLegend({
          legend,
          hideLegend,
          required,
          tooltip,
        })}
      {hint &&
        id &&
        generateInputHint({ hint, hintId, css: { marginBottom: '$quarter' } })}
      {children}
      {inputStatus && statusMessage && id && (
        <InputStatusMessage id={statusMessageId} inputStatus={inputStatus}>
          {statusMessage}
        </InputStatusMessage>
      )}
    </FieldsetBase>
  );
};

Fieldset.displayName = 'Fieldset';
