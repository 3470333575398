import {
  ACTIVITY_CAMPAIGN_TYPE,
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
} from '@leagueplatform/health-journey-common';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { configureActivityCardProgress } from 'utils/configure-activity-card-progress.util';

export const getInactiveActivityStatusBooleans = (
  activity: ConfigurableHealthActivityWSCC,
) => {
  const { status } = activity;
  const { COMPLETED, EXPIRED, REMOVED } = HEALTH_JOURNEY_ACTIVITY_STATUS;
  const isCompleted = status === COMPLETED;
  const isMissed = status === EXPIRED || status === REMOVED;
  const isCompletedOrMissed = isCompleted || isMissed;
  return {
    isCompleted,
    isMissed,
    isCompletedOrMissed,
  };
};

export const getIsActivityForChallenge = (
  activity: ConfigurableHealthActivityWSCC,
) => {
  const { campaignInfo, activityTokens } = activity;
  const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;
  return campaignInfo?.campaignType === CHALLENGE || activityTokens > 0;
};

export const getReward = (activity: ConfigurableHealthActivityWSCC) => {
  const { tokensEarned, pointsEarned, activityPoints, activityTokens } =
    activity;
  const { isCompleted } = getInactiveActivityStatusBooleans(activity);
  const isChallenge = getIsActivityForChallenge(activity);

  if (isChallenge) {
    return isCompleted ? tokensEarned : activityTokens;
  }
  return isCompleted ? pointsEarned : activityPoints;
};

export const getContentContainerWidth = (
  activity: ConfigurableHealthActivityWSCC,
) => {
  const { STANDARD, DETAILED } = CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  const { activityImage, detailLevel: detail } = activity;
  const { isCompletedOrMissed } = getInactiveActivityStatusBooleans(activity);
  const { isProgressActivity } = configureActivityCardProgress(activity);
  const canDisplayImage =
    !!activityImage && !isProgressActivity && !isCompletedOrMissed;

  if (detail === DETAILED && canDisplayImage) return '40%';
  if (detail === STANDARD && canDisplayImage) return '60%';
  return '100%';
};

export const getActivityDisplayValues = (
  activity: ConfigurableHealthActivityWSCC,
  isAchievementsEnabled?: boolean,
) => {
  const { SIMPLE, STANDARD, DETAILED } =
    CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  const {
    activityImage,
    shortDescription: description,
    detailLevel: detail,
  } = activity;
  const { isCompletedOrMissed } = getInactiveActivityStatusBooleans(activity);
  const isChallenge = getIsActivityForChallenge(activity);
  const reward = getReward(activity);
  const { isProgressActivity } = configureActivityCardProgress(activity);

  const isActiveNonProgressActivity =
    !isProgressActivity && !isCompletedOrMissed;
  const canDisplayImage = !!activityImage && isActiveNonProgressActivity;

  // TODO: Come back and review this in a follow up ticket
  const displayActivityImage = detail !== SIMPLE && canDisplayImage;
  const displayMobileStandardActivityImage =
    detail === STANDARD && canDisplayImage;
  const displayActivityDescription =
    description && detail === DETAILED && isActiveNonProgressActivity;
  const displayActivityProgress = isProgressActivity && !isCompletedOrMissed;
  const displayReward = !!reward && reward > 0;
  const displayEarnedOrMissedReward = isCompletedOrMissed && displayReward;
  const displayFooter = !isCompletedOrMissed;
  const displayPointsReward =
    displayReward && !isChallenge && isAchievementsEnabled;
  const displayChallengeReward = displayReward && isChallenge;

  return {
    displayActivityDescription,
    displayActivityImage,
    displayActivityProgress,
    displayEarnedOrMissedReward,
    displayFooter,
    displayMobileStandardActivityImage,
    displayPointsReward,
    displayChallengeReward,
    activityCardPriorityBannerDisplayValue: isCompletedOrMissed
      ? 'none'
      : 'flex',
  };
};
