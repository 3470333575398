import { createStore } from 'zustand/vanilla';
import { shallow } from 'zustand/vanilla/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { produce } from 'immer';
import type {
  Paths,
  Get,
  Primitive,
  LiteralToPrimitive,
  LiteralUnion,
} from 'type-fest';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import { LeagueConfigError } from './league-config-error';
import {
  type LeagueConfig,
  type ServerConfig,
  type LeagueCombinedConfig,
  leagueConfigSchema,
  KnownLeagueCombinedConfig,
  UnknownLeagueConfig,
} from './league-config-schema';
import { assertConfigSchema } from './assert-config-schema';

const GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE =
  'Trying to use config getters, but League config has not been set! Are you sure you called `setConfig` at least once, or that a config object was passed to `<LeagueProvider>` at the top of your app?';

const SET_CONFIG_UPDATER_WITHOUT_CONFIG_ERROR_MESSAGE =
  'Trying to use `setConfig` with an updater function, but League config has not been initialized! Are you sure you called `setConfig` at least once, or that a config object was passed to `<LeagueProvider>` at the top of your app?';

/**
 * The shape of the config store. Includes a `config` property holding
 * a {@link LeagueConfig `LeagueConfig`} object (or `undefined` if never set),
 * a `setConfig` property holding a {@link ServerConfig `ServerConfig`}
 * object (or `undefined` if never set), and a setter function for each.
 */
type ConfigStoreState = {
  serverConfig: ServerConfig | undefined;
  config: LeagueConfig | undefined;
  setConfig: (config: LeagueConfig) => void;
  setServerConfig: (serverConfig: ServerConfig) => void;
};

/**
 * A store for League config values.
 */
const configStore = createStore<ConfigStoreState>((set) => ({
  serverConfig: undefined,
  config: undefined,
  setConfig: (config) => set(() => ({ config })),
  setServerConfig: (serverConfig) => set(() => ({ serverConfig })),
}));

type ConfigProducerRecipe = (draft: LeagueConfig) => LeagueConfig | void;

/**
 * Updates the {@link LeagueConfig `LeagueConfig`} store. Can be given either a
 * new `LeagueConfig` object, or a callback which will be passed a draft of
 * the current config and can mutate it. Note that the latter can only be used
 * after the config was set at least once, and will throw an error otherwise.
 *
 * Throws a {@link LeagueConfigError `LeagueConfigError`} if the input does not
 * meet validation against the known `LeagueConfig` schema.
 */
export function setConfig(config: LeagueConfig): void;
export function setConfig(recipe: ConfigProducerRecipe): void;
export function setConfig(configOrRecipe: LeagueConfig | ConfigProducerRecipe) {
  const state = configStore.getState();
  let newConfig: LeagueConfig;

  if (typeof configOrRecipe === 'function') {
    if (!state.config) {
      throw new LeagueConfigError(
        SET_CONFIG_UPDATER_WITHOUT_CONFIG_ERROR_MESSAGE,
      );
    }
    newConfig = produce(state.config, configOrRecipe);
  } else {
    newConfig = configOrRecipe;
  }

  assertConfigSchema(newConfig, leagueConfigSchema);

  configStore.getState().setConfig(newConfig);
}

/**
 * Updates the {@link ServerConfig `ServerConfig`} store.
 * NOTE: this function SHOULD NOT be exposed as an export of this module. It is
 * only to be used by the server-driven config logic.
 */
export function setServerConfig(serverConfig: ServerConfig) {
  configStore.getState().setServerConfig(serverConfig);
}

/**
 * Given a complete `ConfigStoreState`, will merge its `config` and `serverConfig`
 * objects and return the result.
 *
 * If `config` (the client-set config object) is not yet set, this function will
 * always return `undefined`. We do this because we know that `serverConfig`
 * can/should only be set with backend values which were requested with client
 * config values to begin with, and so, an unset client `config` should mean
 * "we have nothing yet" to the caller.
 */
function getMergedConfigFromState(
  state: ConfigStoreState,
): LeagueCombinedConfig | undefined {
  const { config, serverConfig } = state;

  if (!config) {
    return undefined;
  }

  return {
    ...serverConfig,
    ...config,
  };
}

/**
 * Returns the current {@link LeagueCombinedConfig `LeagueCombinedConfig`}. By default, throws
 * an error if called before the config is first set. Takes a boolean `assertConfigSet`
 * parameter which, if set to false, will prevent an error from being thrown if the
 * config is not set and will instead return `undefined`.
 */
export function getConfig(): LeagueCombinedConfig;
export function getConfig(assertLeagueConfigSet: true): LeagueCombinedConfig;

export function getConfig(
  assertLeagueConfigSet: false,
): LeagueCombinedConfig | undefined;
export function getConfig(assertLeagueConfigSet = true) {
  const config = getMergedConfigFromState(configStore.getState());

  if (assertLeagueConfigSet && !config) {
    throw new LeagueConfigError(GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE);
  }

  return config;
}

/**
 * A function that takes {@link LeagueCombinedConfig `LeagueCombinedConfig`} and returns some
 * selection against it.
 */
type Selector<Selection> = (config: LeagueCombinedConfig) => Selection;

/**
 * Options to customize the behavior of the `subscribeToConfig` function.
 */
type SubscribeOptions = {
  /**
   * Wait before calling the listener until client config is initialized (if `set`)
   * or until server config is settled (if `asyncSet`).
   */
  waitForAsyncConfig?: boolean;
};

/**
 * Provided a {@link Selector `Selector`} and a listener, will call the listener
 * with the selection of the {@link LeagueCombinedConfig `LeagueCombinedConfig`} returned
 * by the selector whenever its value changes, as well as once immediately
 * after subscribing, or `undefined` if the config has not yet been set.
 *
 * Takes an `options` object with optional properties:
 *
 *  `waitForAsyncConfig`: if set to `true`, then the listener will only be called
 *  once the async/server config fetch is settled.
 */
export function subscribeToConfig<Selection>(
  selector: Selector<Selection>,
  listener: (
    selection: Selection | undefined,
    prevSelection: Selection | undefined,
  ) => void,
  options?: SubscribeOptions,
) {
  const initialState = configStore.getState();

  let firstSelection: Selection | undefined;

  // call the listener immediately (conditioned on the options mentioned above)
  if (!options?.waitForAsyncConfig || !!initialState.serverConfig) {
    const firstConfig = getMergedConfigFromState(initialState);
    firstSelection = firstConfig ? selector(firstConfig) : undefined;
    listener(firstSelection, undefined);
  }

  /**
   * Keep a record of the previous selection result, so that we can compare it
   * to the new one when the store updates and refrain from calling the listener
   * if the selection hasn't changed (zustand vanilla stores` `subscribe()` method
   * doesn't support selectors natively).
   */
  let prevSelection = firstSelection;

  const unsubscribe = configStore.subscribe((state) => {
    if (options?.waitForAsyncConfig && !state.serverConfig) {
      return;
    }
    const config = getMergedConfigFromState(state);
    const selection = config ? selector(config) : undefined;

    if (!shallow(prevSelection, selection)) {
      /**
       * Make sure prevSelection is updated before we invoke the listener.
       * This prevents a potential infinite loop where the listener logic
       * is updating the config, triggering the subscription before updating prevSelection.
       */
      const originalPrevSelection = prevSelection;
      prevSelection = selection;
      listener(selection, originalPrevSelection);
    }
  });

  return unsubscribe;
}

/**
 * React hook for subscribing to a selection over {@link LeagueCombinedConfig `LeagueCombinedConfig`}.
 * Takes a {@link Selector `Selector`} callback, which receives the entire config
 * and returns a selection over it, such that changes to the value of that selection will
 * cause a re-render to the calling component.
 *
 * Optionally, takes a default value as a second argument, which will be merged
 * with the current value of the config selection and returned.
 *
 * @example useConfigSelection((config) => config.core.appId, 'defaultAppId')
 *
 * Throws an error if called when the config is not yet set.
 */
export function useConfigSelection<Selection>(
  selector: Selector<Selection>,
): Selection;
export function useConfigSelection<Selection, DefaultValue extends Selection>(
  selector: Selector<Selection>,
  defaultValue: DefaultValue,
): DefaultValue & Selection;
export function useConfigSelection<Selection, DefaultValue extends Selection>(
  selector: Selector<Selection>,
  defaultValue?: DefaultValue,
) {
  return useStoreWithEqualityFn(
    configStore,
    (state) => {
      const config = getMergedConfigFromState(state);
      if (!config) {
        throw new LeagueConfigError(GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE);
      }

      const selection = selector(config);

      if (typeof defaultValue === 'object') {
        /**
         * we have been given a `defaultValue`, and it is a non-primitive value.
         * That means we can expect the config property we're getting to be, itself,
         * a non-primitive value, and thus we must merge it with `defaultValue`
         * so that partial config values still take precedence over ones from `defaultValue`.
         *
         * Note: we allow array values to OVERRIDE default values instead of being
         * merged with them.
         */
        return mergeWith({}, defaultValue, selection, (_, b) =>
          Array.isArray(b) ? b : undefined,
        );
      }

      /**
       * Either `defaultValue` was not provided, or it was provided but it and the
       * config property we're getting are primitive. Either way, we will return
       * the config property's value, or if it is nullish, the `defaultValue`.
       */
      return selection ?? defaultValue;
    },
    shallow,
  );
}

/**
 * All valid dot.separated.paths to properties in the `LeagueCombinedConfig` object.
 *
 * @example 'core.api.wsUrl'
 */
type LeagueConfigPaths = Paths<{
  [key in LiteralUnion<
    keyof KnownLeagueCombinedConfig,
    keyof UnknownLeagueConfig
  >]: LeagueConfig[key];
}>;

/**
 * React hook for subscribing to a specific deep property of the config object,
 * by a string path to that property.
 *
 * Optionally, takes a default value as a second argument, which will be merged
 * with the current value of the config property and returned.
 *
 * Throws an error if called when the config is not yet set.
 *
 * @example useConfigProperty('core.api.url', 'default-api-url');
 */
export function useConfigProperty<
  /**
   * ensure the `path` argument is a valid dot.sparated.path to a property in
   * the `LeagueCombinedConfig` object.
   */
  Path extends LeagueConfigPaths,
>(path: Path): Get<LeagueCombinedConfig, Path>;
export function useConfigProperty<
  Path extends LeagueConfigPaths,
  /**
   * Accept a default value which must extend the type of the config property
   * at the path
   */
  DefaultValue extends Get<LeagueCombinedConfig, Path>,
>(
  path: Path,
  defaultValue: DefaultValue,
): DefaultValue extends Primitive
  ? /**
     * If the property we're providing a default value for is a primitive,
     * then when we take in a default value we still assert that the return type
     * is that primitive and not *the literal type* of the default value.
     */
    LiteralToPrimitive<DefaultValue>
  : DefaultValue & Get<LeagueCombinedConfig, Path>;
export function useConfigProperty<
  Path extends LeagueConfigPaths,
  DefaultValue extends Get<LeagueCombinedConfig, Path>,
>(path: Path, defaultValue?: DefaultValue) {
  return useConfigSelection(
    (config) => get(config, path),
    defaultValue as DefaultValue,
  );
}

/**
 * (FOR TESTING PURPOSES ONLY) This allows direct manipulation of the config store.
 * DO NOT RE-EXPORT THIS FROM THE MODULE'S `index` FILE.
 */
// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
export function __setStoreDirectly(
  ...args: Parameters<typeof configStore.setState>
) {
  configStore.setState(...args);
}
