/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { useHealthActivity } from './use-health-activity.hook';
import { useSetActivityProgress } from './use-set-activity-progress.hook';

export const useActivityMarkAsDone = (id) => {
  // Hooks
  const activity = useHealthActivity();
  const { isLoading, mutate, isError, isDone } = useSetActivityProgress();
  const { helpful_tips: helpfulTips = [] } = activity?.activityProgress ?? {};

  const newHelpFulTips = new Array(helpfulTips);
  const index = newHelpFulTips?.findIndex(
    (tip) => tip.id?.toLowerCase() === id?.toLowerCase(),
  );
  if (index >= 0) {
    newHelpFulTips[index].opened = true;
  } else {
    newHelpFulTips.push({ id, opened: true });
  }

  // Properties
  const markAsDone = () => mutate({ helpful_tips: newHelpFulTips });

  return { isDone, isLoading, isError, markAsDone };
};
