import { useCallback, useState, useEffect, useMemo } from 'react';
import {
  useQueryClient,
  QueryObserver,
  QueryObserverOptions,
} from 'react-query';
import { HealthActivityModalTypes } from '../types/health-activity.types';

export const SET_ACTIVITY_MODAL_QUERY = 'set-activity-modal';

type ActiveModal = {
  type: HealthActivityModalTypes;
  props: Record<string, any>;
};

const setActivityModalQueryKey = [SET_ACTIVITY_MODAL_QUERY];
const queryObserverOptions: QueryObserverOptions<ActiveModal, Error> = {
  queryKey: setActivityModalQueryKey,
  initialData: { type: HealthActivityModalTypes.None, props: {} },
  enabled: false,
};

export const useActivityModals = () => {
  const queryClient = useQueryClient();

  /** Query observer is being used to avoid using a needless query function and to work more consistently in Storybook */
  const observer = useMemo(
    () => new QueryObserver(queryClient, queryObserverOptions),
    [queryClient],
  );

  const [activeModal, updateActiveModal] = useState(
    observer.getCurrentResult().data,
  );

  // Track global activity modals and apply it to local state
  useEffect(() => {
    const unsubscribe = observer.subscribe((result) =>
      updateActiveModal(result.data),
    );
    return unsubscribe;
  }, [observer, updateActiveModal]);

  const setActiveModal = useCallback(
    (type: HealthActivityModalTypes, props = {}) =>
      queryClient.setQueryData(setActivityModalQueryKey, { type, props }),
    [queryClient],
  );

  return { activeModal, setActiveModal };
};
