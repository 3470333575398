import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  HeadingThree,
  genesisStyled,
  BodyOne,
  PrimaryButton,
  QuietButton,
  css,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { func, string } from 'prop-types';
import { Link } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { HealthActivityModal } from '@leagueplatform/health-journey-common';
import { useActivityAnalytics } from '../../../hooks/use-activity-details-analytics.hook';
import { useCompleteUserHealthActivity } from '../../../hooks/use-complete-user-health-activity.hook';

const HeaderLine = genesisStyled(Box)(({ theme: { space } }) =>
  css({
    '&:after': {
      content: '""',
      display: 'block',
      width: `calc(100% + ${space.two * 2}px )`,
      borderBottomColor: 'onSurface.border.subdued',
      borderBottomStyle: 'solid',
      borderBottomWidth: 'thin',
      marginLeft: 'minusTwo',
      marginBottom: 'oneAndHalf',
    },
  }),
);

export const SaveProgressModal = (props) => {
  const { onDismiss, onBack, activityId: userActivityId } = props;
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const completeActivityMutation =
    useCompleteUserHealthActivity(userActivityId);
  const activityAnalytics = useActivityAnalytics();
  const copy = {
    heading: formatMessage({ id: 'GOAL_REACHED' }),
    completeNow: formatMessage({ id: 'ACTIVITY_COMPLETE_PROMPT' }),
    completeLater: formatMessage({ id: 'ACTIVITY_COMPLETE_LATER_PROMPT' }),
    close: formatMessage({ id: 'BACK_TO_JOURNEY' }),
    complete: formatMessage({ id: 'MARK_AS_DONE' }),
  };

  useEffect(() => {
    activityAnalytics.sendViewSaveProgressModal();
  }, [activityAnalytics]);

  const onComplete = () => {
    completeActivityMutation.mutate();
    activityAnalytics.sendSaveProgressModalAction({
      isCompletion: true,
      ctaText: copy.complete,
    });
    onDismiss();
  };

  const onClose = () => {
    activityAnalytics.sendDismissSaveProgressModal();
    onDismiss();
  };

  return (
    <HealthActivityModal onClose={onClose} aria-label={copy.heading} isNarrow>
      <HeaderLine theme={theme}>
        <HeadingThree marginBottom="oneAndHalf">{copy.heading}</HeadingThree>
      </HeaderLine>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        borderBottomColor="onSurface.border.subdued"
        borderBottomWidth="thin"
        borderBottomStyle="solid"
      />
      <BodyOne>{copy.completeNow}</BodyOne>
      <BodyOne marginTop="one">{copy.completeLater}</BodyOne>
      <Flex justifyContent="flex-end" marginTop="two">
        <QuietButton
          as={Link}
          to={onBack}
          onClick={() =>
            activityAnalytics.sendSaveProgressModalAction({
              isCompletion: false,
            })
          }
        >
          {copy.close}
        </QuietButton>
        <PrimaryButton marginLeft="threeQuarters" onClick={onComplete}>
          {copy.complete}
        </PrimaryButton>
      </Flex>
    </HealthActivityModal>
  );
};

SaveProgressModal.propTypes = {
  activityId: string.isRequired,
  onDismiss: func.isRequired,
  onBack: func.isRequired,
};
