import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import {
  BackButtonArrow,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { DATE_FORMAT } from '@leagueplatform/web-common';
import {
  Flex,
  HeadingThree,
  Box,
  BodyTwo,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { StackLayout, Box as CoreBox } from '@leagueplatform/genesis-core';
import {
  CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH,
  ENABLE_V3_ACTIVITY_CARDS,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useAllActivitiesByActivityStatus } from 'hooks/use-all-activities-by-activity-status.hook';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { PriorityActivityCard } from '../priority-activity-card/priority-activity-card.component';
import { ConfigurableActivityCard } from '../configurable-activity-card/configurable-activity-card.component';
import {
  ActivitiesByDateGrouping,
  groupActivitiesByDate,
} from './group-activities-by-date.util';
import { ActivitiesByDateList } from './activities-by-date-list.component';

interface AllActivitiesByStatusProps {
  statusType: 'completed' | 'missed' | 'standalone'; // I don't actually know if standalone is the real statustype we should use, but it can be updated when integrating with the new hook
}

const noopFunction = () => {};

// Extracted this component in order to avoid a nested ternary. Soon we'll be removing the ConfigurableActivityCard
// completely, along with the "View all completed" and "View all missed" states, and this can be cleaned up at that time
const StandaloneActivityCard = ({
  activity,
}: {
  activity: ConfigurableHealthActivityWSCC;
}) => {
  const { data: isV3ActivityCardsEnabled } = useFeatureFlagQuery(
    ENABLE_V3_ACTIVITY_CARDS,
  );
  if (isV3ActivityCardsEnabled) {
    return (
      <CoreBox as="li">
        <PriorityActivityCard activity={activity} headingLevel="2" />
      </CoreBox>
    );
  }
  return (
    <ConfigurableActivityCard
      activity={activity}
      key={activity.id}
      activityHeadingLevel="h2"
    />
  );
};

const AllActivitiesByStatusContents = ({
  statusType,
}: AllActivitiesByStatusProps) => {
  const { formatMessage } = useIntl();
  const { activities, isLoading, isError } =
    useAllActivitiesByActivityStatus(statusType);

  if (isLoading) {
    return (
      <Box marginTop="two">
        {Array(4)
          .fill('')
          .map((el, idx) => (
            <SkeletonBox
              backgroundColor="interactive.background.disabled"
              height="100px"
              marginBottom="one"
              // using index here as these will be the only thing on the page
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
            />
          ))}
      </Box>
    );
  }
  const isStandalone = statusType === 'standalone';
  const errorDescription = formatMessage({
    id: `${statusType.toUpperCase()}_ACTIVITIES_EMPTY_STATE`,
  });

  if (isError || !activities?.length) {
    return (
      <Flex
        backgroundColor="surface.background.secondary"
        borderRadius="large"
        justifyContent="center"
        marginTop="two"
        paddingX="one"
        /*
          Due to a Genesis bug, responsive object for padding and margin in conjunction with flex doesn't work properly. Instead, for responsive styles to work, we need to pass an array, with each item in the array corresponding to a breakpoint in the order that they occur:
            index: breakpoint
            0: base
            1: phone
            2: tablet
            3: laptop
            4: desktop
        */
        paddingY={['oneAndHalf', 'three', 'four']}
        textAlign="center"
      >
        <BodyOneSecondary>{errorDescription}</BodyOneSecondary>
      </Flex>
    );
  }

  let activitiesGroupedByDate: ActivitiesByDateGrouping[] = [];
  if (!isStandalone) {
    activitiesGroupedByDate = groupActivitiesByDate(statusType, activities);
  }
  return isStandalone ? (
    <StackLayout as="ul" spacing="$one" horizontalAlignment="stretch">
      {activities.map((activity) => (
        <StandaloneActivityCard key={activity.id} activity={activity} />
      ))}
    </StackLayout>
  ) : (
    <Box as="ul" width="100%">
      {activitiesGroupedByDate.map((grouping) => (
        <ActivitiesByDateList grouping={grouping} key={grouping.date} />
      ))}
    </Box>
  );
};

export const AllActivitiesByStatus = ({
  statusType,
}: AllActivitiesByStatusProps) => {
  const { formatMessage, formatDate } = useIntl();
  const sectionTitle = formatMessage({
    id: `${statusType.toUpperCase()}_ACTIVITIES`,
  });
  const lastYear = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1),
  );
  const isStandalone = statusType === 'standalone';
  return (
    <Flex
      width="100%"
      maxWidth={CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH}
      margin="auto"
      paddingX="one"
      paddingTop={{ _: 'two', laptop: 'four' }}
      paddingBottom="one"
    >
      <BackButtonArrow
        as={Link}
        marginRight="one"
        marginTop="quarter"
        onClick={noopFunction}
        to={JOURNEY_ROUTES.progressHome}
      />
      <StackLayout spacing="$two" css={{ width: '100%' }}>
        <StackLayout spacing="$half">
          <HeadingThree as="h1">{sectionTitle}</HeadingThree>
          {!isStandalone && (
            <BodyTwo>
              {formatMessage(
                {
                  id: 'DISPLAYING_ACTIVITIES_FROM_DATE',
                },
                {
                  date: formatDate(lastYear, DATE_FORMAT),
                },
              )}
            </BodyTwo>
          )}
        </StackLayout>
        <AllActivitiesByStatusContents statusType={statusType} />
      </StackLayout>
    </Flex>
  );
};
