import * as React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { HealthMetricDeserialisedDataV3 } from '@leagueplatform/dashboard-api';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import {
  SHORT_DATE_FORMAT,
  SHORT_MONTH_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { WeekMonthViewSwitch } from './week-month-view-switch';
import { PaginationControls } from './pagination-controls';
import { AverageValuePanel } from './average-value-panel';
import { TodaysMeasurementPanel } from './todays-measurement-panel';
import { Chart } from './chart';

const ariaButtonLabelMap: Record<
  CHART_VIEW,
  { previous: string; next: string }
> = {
  [CHART_VIEWS.WEEKLY]: {
    previous: 'PREVIOUS_WEEK',
    next: 'NEXT_WEEK',
  },
  [CHART_VIEWS.MONTHLY]: {
    previous: 'PREVIOUS_30_DAYS',
    next: 'NEXT_30_DAYS',
  },
};

type HealthMetricsChartProps = {
  chartData: HealthMetricDeserialisedDataV3['data'][number];
  chartView: CHART_VIEW;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onViewChange: (view: CHART_VIEW) => void;
};

export function HealthMetricsChart({
  chartData,
  chartView,
  hasPreviousPage,
  hasNextPage,
  onPreviousPage,
  onNextPage,
  onViewChange,
}: HealthMetricsChartProps) {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  const { values: healthMetrics } = chartData;

  const dateRangeStart =
    healthMetrics.data[healthMetrics.data.length - 1]?.timestamp;
  const dateRangeEnd = healthMetrics.data[0]?.timestamp;

  return (
    <>
      <WeekMonthViewSwitch
        onChange={(view) => onViewChange(view as CHART_VIEW)}
      />
      <Flex flexDirection="column" alignItems="center" marginBottom="two">
        <PaginationControls
          title={`${formatDateWithUserProfileLocation(
            dateRangeStart,
            SHORT_MONTH_FORMAT,
          )} - ${formatDateWithUserProfileLocation(
            dateRangeEnd,
            SHORT_DATE_FORMAT,
          )}`}
          previous={{
            label: formatMessage({
              id: ariaButtonLabelMap[chartView].previous,
            }),
            enabled: hasPreviousPage,
            onClick: onPreviousPage,
          }}
          next={{
            label: formatMessage({
              id: ariaButtonLabelMap[chartView].next,
            }),
            enabled: hasNextPage,
            onClick: onNextPage,
          }}
        />
        <AverageValuePanel
          averages={chartData.averages.data}
          chartType={chartData.chartType}
        />
        <Flex width="100%" height="500px" marginBottom="twoAndHalf">
          <Chart chartView={chartView} chartData={chartData} />
        </Flex>
        <TodaysMeasurementPanel todaysValues={chartData.todaysValues.data} />
      </Flex>
    </>
  );
}
