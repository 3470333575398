import {
  COMPLETION_METHOD_TYPES,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
} from '@leagueplatform/health-journey-common';

const { ACTIVE } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { COUNTER } = COMPLETION_METHOD_TYPES;

/**
 * Given a healthActivity, this function outputs whether or not that activity has met the
 * completion criteria. Activities that should not be tracked, such as those without an `active` status will return true accordingly
 * @param {healthActivity} healthActivity
 * @returns `bool`
 */
export const hasActivityMetCompletionCriteria = (healthActivity) => {
  const { status, cta, activityProgress: progress } = healthActivity;

  if (status !== ACTIVE) return true;

  switch (cta?.completion_method?.type) {
    case COUNTER:
      // return true if the users count is greater or equal to the goal
      return progress?.count_progress >= cta?.completion_method?.counter?.goal;
    default:
      return true;
  }
};

export const isOptionValueInCurrentValue = (currentValue, value) => {
  if (Array.isArray(currentValue)) {
    return currentValue?.includes(value);
  }
  return currentValue === value;
};
