import React from 'react';
import PropTypes from 'prop-types';
import { ViewTransitionController } from '@leagueplatform/health-journey-common';
// eslint-disable-next-line import/no-cycle
import { SaveProgressModal } from '.';
import { ACTIVITY_EXIT_MODAL_TYPES } from '../types/exit-modals.type';

const { SAVE_PROGRESS } = ACTIVITY_EXIT_MODAL_TYPES;

export const ExitModals = ({
  handleActiveView,
  activityId,
  onBack,
  onDismiss,
}) => {
  const modals = {
    [SAVE_PROGRESS]: {
      view: SaveProgressModal,
      props: {
        activityId,
        onBack,
        onDismiss,
      },
    },
  };

  return (
    <ViewTransitionController
      views={modals}
      handleActiveView={handleActiveView}
    />
  );
};

ExitModals.propTypes = {
  handleActiveView: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
