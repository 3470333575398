import { ChangeEvent, ChangeEventHandler, useCallback, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import {
  DateInputAdditionalDateOption,
  DateInputValidationOptions,
} from '@leagueplatform/health-journey-api';
import {
  getAbsoluteDate,
  getRelativeDate,
  normalizeDateToMidnight,
} from '../common/format-date.util';

interface UseToolboxDateInputsProps {
  componentId: string;
  showDayInput: boolean;
  validation: DateInputValidationOptions;
}
export const useToolboxDateInputs = ({
  componentId,
  showDayInput,
  validation,
}: UseToolboxDateInputsProps) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();

  // Element Refs
  const dayInputRef = useRef<HTMLSelectElement>(null);
  const monthInputRef = useRef<HTMLSelectElement>(null);
  const yearInputRef = useRef<HTMLSelectElement>(null);
  const radioInputsRef = useRef<HTMLInputElement[]>([]);
  const fieldRef = useRef<HTMLDivElement>(null);

  // Helpers
  const getDateSelectValues = useCallback(
    () => ({
      day: dayInputRef.current?.value,
      month: monthInputRef.current?.value,
      year: yearInputRef.current?.value,
    }),
    [],
  );

  /** is empty if all rendered select elements (those with values not `undefined`) have an empty value (`''`) */
  const isDateSelectEmpty = useCallback(() => {
    const { day, month, year } = getDateSelectValues();
    return [day, month, year]
      .filter((selectValue) => selectValue !== undefined)
      .every((selectValue) => selectValue === '');
  }, [getDateSelectValues]);

  const isRadioSelected = useCallback(
    () => radioInputsRef.current?.some((input) => input.checked),
    [],
  );

  const clearRadioInputs = useCallback(() => {
    if (radioInputsRef.current.length) {
      radioInputsRef.current.forEach((item) => {
        const radio = item;
        radio.checked = false;
      });
    }
  }, []);

  const clearSelectInputs = useCallback(() => {
    if (dayInputRef.current) dayInputRef.current.value = '';
    if (monthInputRef.current) monthInputRef.current.value = '';
    if (yearInputRef.current) yearInputRef.current.value = '';
  }, []);

  // Validation
  const { maximumDate, minimumDate, required, customWarning } = validation;
  /** Ensure all date select inputs are either empty or populated
   * @details [invalid or corrupt dates are represented as `NaN`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Invalid_date#invalid_cases)
   */
  const validatePartialDateSelect = useCallback(
    (value) =>
      Number.isNaN(value) || value === 'NaN'
        ? formatMessage({
            id: showDayInput
              ? 'TOOLBOX_VALIDATION_DAY_MONTH_YEAR'
              : 'TOOLBOX_VALIDATION_MONTH_YEAR',
          })
        : undefined,
    [formatMessage, showDayInput],
  );

  const validateMaxDate = useCallback(
    (value) => {
      if (!maximumDate || !value) return undefined;
      const selectedDate = normalizeDateToMidnight(value);
      const maxDate = normalizeDateToMidnight(maximumDate);
      if (!showDayInput) {
        maxDate.setDate(1);
      }
      const message =
        customWarning ||
        formatMessage(
          { id: 'TOOLBOX_VALIDATION_SELECT_DATE_EARLIER' },
          { date: maxDate },
        );
      return selectedDate > maxDate ? message : undefined;
    },
    [customWarning, formatMessage, showDayInput, maximumDate],
  );

  const validateMinDate = useCallback(
    (value) => {
      if (!minimumDate || !value) return undefined;
      const selectedDate = normalizeDateToMidnight(value);
      const minDate = normalizeDateToMidnight(minimumDate);
      if (!showDayInput) {
        minDate.setDate(1);
      }

      const message =
        customWarning ||
        formatMessage(
          { id: 'TOOLBOX_VALIDATION_SELECT_DATE_LATER' },
          { date: minDate },
        );
      return minDate > selectedDate ? message : undefined;
    },
    [minimumDate, showDayInput, customWarning, formatMessage],
  );

  const validateRequired = useCallback(() => {
    if (!required) return undefined;
    const message =
      customWarning ||
      formatMessage({ id: 'TOOLBOX_VALIDATION_DATE_INPUT_REQUIRED' });
    return isDateSelectEmpty() && !isRadioSelected() ? message : undefined;
  }, [
    customWarning,
    formatMessage,
    isDateSelectEmpty,
    isRadioSelected,
    required,
  ]);

  // Change Handlers
  const setValueAndValidate = useCallback(
    (newValue: string | number | null) => {
      setValue(componentId, String(newValue), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [componentId, setValue],
  );

  const onAdditionalOptionChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      option: DateInputAdditionalDateOption,
    ) => {
      const newDate = getRelativeDate(option);
      clearSelectInputs();
      setValueAndValidate(newDate);
    },
    [clearSelectInputs, setValueAndValidate],
  );

  const onDateSelectChange: ChangeEventHandler<HTMLSelectElement> =
    useCallback(() => {
      const { day, month, year } = getDateSelectValues();

      clearRadioInputs();

      if (isDateSelectEmpty()) return setValueAndValidate(null);

      // Default the day to 1 for a month/year picker, which won't have a day set
      const newDate = getAbsoluteDate({ day: day ?? 1, month, year });
      return setValueAndValidate(newDate);
    }, [
      clearRadioInputs,
      getDateSelectValues,
      isDateSelectEmpty,
      setValueAndValidate,
    ]);

  const refs = {
    dayInputRef,
    monthInputRef,
    yearInputRef,
    radioInputsRef,
    fieldRef,
  };

  const validateMethods = {
    validatePartialDateSelect,
    validateMinDate,
    validateMaxDate,
    validateRequired,
  };

  const handlers = { onAdditionalOptionChange, onDateSelectChange };

  return { handlers, validateMethods, refs };
};
