import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  HeadingThree,
  BodyOne,
  SubtitleTwo,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  ACTIVITY_CAMPAIGN_TYPE,
  JOURNEY_ROUTES,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  GenesisRouterLink,
  ProgressBar,
} from '@leagueplatform/web-common-components';
import { ConfigurableHealthProgram } from 'types/configurable-health-program.types';

const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

type CampaignProgressProps = {
  program: ConfigurableHealthProgram;
};

export function CampaignProgress({ program }: CampaignProgressProps) {
  const {
    activityStatusCounts: { completed: completedActivitiesCount },
    campaignType,
    completedActivityProgressPercentage,
    programId,
    name: programName,
    totalActivitiesCount,
  } = program;
  const { formatMessage } = useIntl();
  const VIEW_DETAILS_ID = `${programId}_view_details`;
  const VIEW_DETAILS_LABELLEDBY = `${VIEW_DETAILS_ID} ${programId}`;
  const viewDetailsOnClick = () =>
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: SCREEN_NAMES.PROGRESS_PAGE,
      detail: 'view health campaign',
      health_campaign_name: programName,
      health_campaign_id: programId,
      health_campaign_type: campaignType,
    });
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom="half"
      >
        <HeadingThree marginRight="one" id={programId}>
          {programName}
        </HeadingThree>
        <SubtitleTwo
          as={GenesisRouterLink}
          to={
            campaignType === CHALLENGE
              ? JOURNEY_ROUTES.getChallengeDetails(programId)
              : JOURNEY_ROUTES.getProgramDetails(programId)
          }
          id={VIEW_DETAILS_ID}
          aria-labelledby={VIEW_DETAILS_LABELLEDBY}
          color="interactive.action.primary"
          minWidth="fit-content"
          onClick={viewDetailsOnClick}
        >
          {/* TODO: update if BE ends up handling translations */}
          {formatMessage({ id: 'VIEW_DETAILS' })}
        </SubtitleTwo>
      </Flex>
      <BodyOne
        as="span"
        color="onSurface.text.success"
        fontWeight="bold"
        marginRight="quarter"
      >
        {formatMessage(
          { id: 'X_PERCENTAGE' },
          { percent: completedActivityProgressPercentage },
        )}
      </BodyOne>
      <BodyOneSecondary as="span" marginBottom="quarter">
        {formatMessage(
          { id: 'X_OF_Y_ACTIVITIES' },
          {
            completed: completedActivitiesCount,
            total: totalActivitiesCount,
          },
        )}
      </BodyOneSecondary>
      <ProgressBar
        current={completedActivitiesCount}
        total={totalActivitiesCount}
        customBarBackgroundColor="success.background.subdued"
        customBarFillColor="success.icon"
      />
    </>
  );
}
