import React from 'react';
import { usePageViewAnalytics, PRODUCT_AREA } from '@leagueplatform/analytics';
import { Box, SubtitleTwo } from '@leagueplatform/genesis-commons';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useSuggestedActivities } from './use-suggested-activities.hook';
import { ActivityDetailsWrapper } from '../../components/activity-details-wrapper.component';
import { ActivityDetailsHeader } from '../../components/activity-details-header.component';
import { HealthActivityCardList } from '../../components/health-activity-card-list/health-activity-card-list.view';

export const SuggestedActivitiesPage = (props) => {
  const { ready, hasError, activityArray, suggestedGroup } =
    useSuggestedActivities();

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const SuggestedActivitiesWrapper = (wrapperProps) => (
    <ActivityDetailsWrapper
      to={JOURNEY_ROUTES.journeyHome()}
      data-testid="suggested-activities-page-wrapper"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...wrapperProps}
    />
  );

  usePageViewAnalytics(
    {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: 'health journey activities suggested group',
      suggested_activity_group_name: suggestedGroup?.suggestedActivityGroupId,
    },
    [suggestedGroup.suggestedActivityGroupId],
  );

  if (!ready) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      <SuggestedActivitiesWrapper {...props}>
        <LoadingIndicator />
      </SuggestedActivitiesWrapper>
    );
  }

  if (hasError) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      <SuggestedActivitiesWrapper {...props}>
        <Box marginTop="three">
          <ErrorState />
        </Box>
      </SuggestedActivitiesWrapper>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <SuggestedActivitiesWrapper {...props}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ActivityDetailsHeader {...suggestedGroup} iconUrl="">
        <SubtitleTwo
          as="h2"
          color="onSurface.text.subdued"
          fontWeight="regular"
          marginBottom="oneAndHalf"
        >
          {suggestedGroup?.description}
        </SubtitleTwo>
      </ActivityDetailsHeader>
      <HealthActivityCardList
        activityArray={activityArray}
        suggestedActivityGroupName={suggestedGroup?.title} // for segment analytics
      />
    </SuggestedActivitiesWrapper>
  );
};
