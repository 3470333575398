import * as React from 'react';
import {
  GDSButtonProps,
  Tabs as T,
  GDSTabProps,
  GDSTabsProps,
  GDSTabListProps,
  Button,
} from '@leagueplatform/genesis-core';

export function PillTabList({ children, css, ...props }: GDSTabListProps) {
  return (
    <T.TabList
      css={{
        '[role=tablist]': {
          backgroundColor: '$surfaceBackgroundPrimary',
          borderColor: '$highlightBackgroundSubdued',
          borderRadius: '$pill',
          borderWidth: '$thin',
          boxShadow: '$card',
          padding: '$half $quarter $half $half',
          ...css,
        },
      }}
      tabDistribution="hugContents"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </T.TabList>
  );
}

export function PillTab({
  value,
  children,
  css,
  ...props
}: GDSTabProps & GDSButtonProps<'button'>) {
  const [isTabSelected, setTabSelectedState] = React.useState<boolean>(false);
  const tabRef = React.useCallback((node) => {
    if (node !== null) {
      setTabSelectedState(node.getAttribute('data-state') === 'active');
    }
  }, []);

  return (
    <Button
      as={T.Tab}
      value={value}
      css={{
        borderRadius: '$pill',
        fontSize: '$subtitleTwo',
        marginRight: '$quarter',
        padding: '$quarter $threeQuarters',
        '&[class*="GDS"]:focus': {
          outlineOffset: '$borderWidths$outerFocus',
        },
        '&[data-state=active]': {
          color: '$primaryTextDefault',
          borderBottomColor: 'transparent',
        },
        '&[data-state=inactive]': {
          '&:hover': {
            backgroundColor: '$decorativeBrandPrimaryPastel',
            color: '$onSurfaceTextPrimary',
          },
        },
        ...css,
      }}
      size="small"
      ref={tabRef}
      quiet={!isTabSelected}
      priority={isTabSelected ? 'primary' : 'secondary'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Button>
  );
}

export function PillTabs({ children, ...props }: GDSTabsProps) {
  return (
    <T.Root
      activationMode="manual"
      css={{
        '& .GDS-tab-list-wrapper': {
          overflow: 'unset',
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </T.Root>
  );
}
