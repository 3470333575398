import * as React from 'react';
import { useRouteMatch, Link } from '@leagueplatform/routing';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameYear from 'date-fns/isSameYear';
import {
  Caption,
  Flex,
  HeadingTwo,
  SubtitleTwo,
  Image,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { genesisFocus } from '@leagueplatform/ui-kit';
import {
  SHORT_MONTH_FORMAT,
  LONG_WEEKDAY,
  SHORT_MONTH_YEAR,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { HealthMetric } from '@leagueplatform/dashboard-api';
import { dataTypeConfigMap } from 'constants/data-type-config';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';

import { InsightTrend } from './insight-trend';

const useGetTimestamp = (timestamp: string) => {
  const { formatMessage, formatTime } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const convertedTimestamp = new Date(timestamp);
  switch (true) {
    case isToday(convertedTimestamp):
      return formatTime(convertedTimestamp);
    case isYesterday(convertedTimestamp):
      return formatMessage({ id: 'YESTERDAY' });
    case differenceInCalendarDays(new Date(), convertedTimestamp) <= 6:
      return formatDateWithUserProfileLocation(
        convertedTimestamp,
        LONG_WEEKDAY,
      );
    case differenceInCalendarDays(new Date(), convertedTimestamp) >= 7 &&
      isSameYear(new Date(), convertedTimestamp):
      return formatDateWithUserProfileLocation(
        convertedTimestamp,
        SHORT_MONTH_FORMAT,
      );
    default:
      return formatDateWithUserProfileLocation(
        convertedTimestamp,
        SHORT_MONTH_YEAR,
      );
  }
};

type InsightCardIconProps = { src: string };

const InsightCardIcon = ({ src }: InsightCardIconProps) => (
  <Image alt="" width="36px" src={src} />
);

export type InsightsCardProps = Omit<HealthMetric, 'source'> & {
  onClick: () => void;
  // These two props aren't used within the initial release, but will be in the near future.
  measurementSecondary?: number;
  unitsSecondary?: string;
  /**
   * An insight card can appear within the All Insights page or
   * within a component that's embedded within another module.
   * When embedded, we need to append `/insights` onto the modules path, but if
   * we are within All Insights Page the path already contains `/insights` and
   * don't want to append it again.
   */
  embedded?: boolean;
};

export function InsightsCard({
  type,
  trend,
  timestamp,
  onClick,
  unit,
  value,
  measurementSecondary,
  unitsSecondary,
  embedded = true,
}: InsightsCardProps) {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const { formatMessage, formatNumber } = useIntl();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();
  const formattedTimestamp = useGetTimestamp(timestamp);
  const metricConfig = dataTypeConfigMap.get(type);

  // Sanity check if for whatever reason an unsupported data type is passed in during runtime we ignore it.
  if (!metricConfig || value === null) {
    return null;
  }

  return (
    <Flex
      as={Link}
      textDecoration="none"
      alignItems="center"
      padding="one"
      borderRadius="medium"
      boxShadow="card"
      width="100%"
      minWidth="325px"
      focusStyle={genesisFocus}
      hoverStyle={{
        boxShadow: `0 0 0 ${theme.borderWidths.thin}px ${theme.colors.interactive.action.primary}`,
      }}
      activeStyle={{
        boxShadow: 'focusOutline',
      }}
      onClick={onClick}
      to={
        embedded
          ? `${path}/insights/${type}/details`
          : `${path}/${type}/details`
      }
    >
      <Flex height="100%" width={1} justifyContent="space-between">
        <Flex flexDirection="column" alignItems="baseline">
          <SubtitleTwo paddingBottom="threeQuarters">
            {formatMessage({
              id: metricConfig.metric,
            })}
          </SubtitleTwo>
          <Flex alignItems="center">
            <InsightCardIcon
              src={handleStaticAsset(metricConfig.icon) as string}
            />
            <Flex alignItems="baseline">
              <HeadingTwo paddingLeft="half">
                {formatMetricValue(value, type)}
              </HeadingTwo>
              <Caption paddingLeft="quarter" color="onSurface.text.subdued">
                {formatUnit(type, unit, value)}
              </Caption>
              {unitsSecondary &&
                measurementSecondary && ( // for now, secondary units will only exist if the metric is sleep
                  <>
                    <HeadingTwo paddingLeft="quarter">
                      {formatNumber(measurementSecondary)}
                    </HeadingTwo>{' '}
                    <Caption
                      paddingLeft="quarter"
                      color="onSurface.text.subdued"
                    >
                      {unitsSecondary}
                    </Caption>
                  </>
                )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
          paddingRight="one"
          alignItems="flex-end"
        >
          <InsightTrend trend={trend} />
          <Caption color="onSurface.text.subdued">{formattedTimestamp}</Caption>
        </Flex>
      </Flex>
      <Image
        role="presentation"
        height="one"
        src={
          handleStaticAsset(
            DASHBOARD_ASSET_MAP.DASHBOARD_CHEVRON_RIGHT,
          ) as string
        }
      />
    </Flex>
  );
}
