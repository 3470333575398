import {
  BadgeStyleModifiersType,
  StyleBackgroundColorType,
  StyleBorderRadiusType,
  StyleHorizontalAlignmentType,
} from '@leagueplatform/health-journey-api';
import { GDSStyleObject } from '@leagueplatform/genesis-core';

type GetDynamicComponentStyleReturn = {
  borderRadiusStyle?: GDSStyleObject;
  horizontalAlignmentStyle?: GDSStyleObject;
  backgroundColorStyle?: GDSStyleObject;
};

export const useGetDynamicComponentStyles = (
  style?: BadgeStyleModifiersType,
  defaultStyle?: BadgeStyleModifiersType,
): GetDynamicComponentStyleReturn => {
  const {
    borderRadius = defaultStyle?.borderRadius ?? StyleBorderRadiusType.medium,
    horizontalAlignment = defaultStyle?.horizontalAlignment ??
      StyleHorizontalAlignmentType.center,
    backgroundColor = defaultStyle?.backgroundColor ??
      StyleBackgroundColorType.decorativeBrandPrimaryPastel,
  } = style ?? {};

  const borderRadiusStyles = {
    [StyleBorderRadiusType.pill]: { borderRadius: '$pill' },
    [StyleBorderRadiusType.medium]: { borderRadius: '$medium' },
  };
  const borderRadiusStyle = borderRadiusStyles?.[borderRadius];

  const horizontalAlignmentStyles = {
    [StyleHorizontalAlignmentType.start]: {
      justifyContent: 'start',
    },
    [StyleHorizontalAlignmentType.center]: {
      justifyContent: 'center',
    },
    [StyleHorizontalAlignmentType.end]: {
      justifyContent: 'end',
    },
  };
  const horizontalAlignmentStyle =
    horizontalAlignmentStyles?.[horizontalAlignment];

  const backgroundColorStyles = {
    [StyleBackgroundColorType.decorativeBrandPrimaryPastel]: {
      backgroundColor: '$decorativeBrandPrimaryPastel',
    },
    [StyleBackgroundColorType.decorativeBrandSecondaryPastel]: {
      backgroundColor: '$decorativeBrandSecondaryPastel',
    },
    [StyleBackgroundColorType.decorativeBrandTertiaryPastel]: {
      backgroundColor: '$decorativeBrandTertiaryPastel',
    },
  };
  const backgroundColorStyle = backgroundColorStyles?.[backgroundColor];

  return { borderRadiusStyle, horizontalAlignmentStyle, backgroundColorStyle };
};
