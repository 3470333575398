import React from 'react';

import { Box, Link, genesisStyled } from '@leagueplatform/genesis-commons';
import { HighlightBanner } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';

const { WARNING_ICON } = HEALTH_JOURNEY_ASSET_KEYS;

interface SyncErrorBannerProps {
  programDataPoints: string[];
  onClickFixSyncIssue: () => void;
}

const ButtonLink = genesisStyled(Link)`
  background: none;
  border: none;
`;

export const SyncErrorBanner = ({
  programDataPoints,
  onClickFixSyncIssue,
}: SyncErrorBannerProps) => {
  // Static Assets
  const warningOrangeIcon = handleStaticAsset(WARNING_ICON) as string;

  // Hooks
  const { formatMessage, formatList } = useIntl();

  return (
    <HighlightBanner
      title={formatMessage({ id: 'SYNC_ISSUE' })}
      icon={warningOrangeIcon}
      backgroundColor="warning.background.subdued"
    >
      {formatMessage(
        { id: 'DATAPOINT_SYNC_ISSUE' },
        {
          dataPoint: formatList(
            programDataPoints.map((dataPoint) =>
              formatMessage({ id: dataPoint.toUpperCase() }),
            ),
          ),
        },
      )}
      <Box>
        <ButtonLink
          as="button"
          fontSize="subtitle2"
          marginTop="half"
          onClick={onClickFixSyncIssue}
        >
          {formatMessage({ id: 'FIX_SYNC_ISSUE' })}
        </ButtonLink>
      </Box>
    </HighlightBanner>
  );
};
