import * as React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, HeadingOne } from '@leagueplatform/genesis-commons';
import {
  PageHeaderBackground,
  SIDEBAR_MIN_WIDTH,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { HealthRewardsSidebar } from './health-rewards-sidebar.component';

const PAGE_LAYOUT_SPACING = {
  _: 'oneAndHalf',
  phone: 'oneAndHalf',
  tablet: 'three',
  laptop: 'five',
  desktop: '120px',
};

export const JourneyPageLayout = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <Flex maxWidth="1400px">
      <PageHeaderBackground />
      <Flex flexDirection={{ _: 'column', laptop: 'row' }} width={1}>
        <Box
          width={1}
          marginBottom={{ _: '230px', phone: '140px' }}
          paddingTop="twoAndHalf"
          paddingX={PAGE_LAYOUT_SPACING}
          paddingRight={{ laptop: 'twoAndHalf', desktop: 'twoAndHalf' }}
        >
          <HeadingOne
            marginBottom="twoAndHalf"
            textAlign={{ _: 'center', phone: 'initial' }}
          >
            {formatMessage({ id: 'HEALTH_JOURNEY' })}
          </HeadingOne>
          {children}
        </Box>
        <Box
          height={1}
          marginRight={PAGE_LAYOUT_SPACING}
          minWidth={SIDEBAR_MIN_WIDTH}
          paddingY="twoAndHalf"
          flex="0"
        >
          <HealthRewardsSidebar showAchievements isAddProgramButtonShown />
        </Box>
      </Flex>
    </Flex>
  );
};

JourneyPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
