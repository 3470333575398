import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Box, Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  SingleProgressBar,
  Icon,
  ICONS as Icons,
} from '@leagueplatform/ui-kit';
import {
  HtmlToReact,
  useOnEscape,
  easeOutCubic,
} from '@leagueplatform/web-common';
import {
  AssessmentExitModal,
  VeryQuietButton,
} from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';

// Components
import { ActivityFooter } from 'components/';

// Hooks
import { useHealthActivity } from 'hooks/use-health-activity.hook';
import { useActivityStepsAnalytics } from 'hooks/use-activity-steps-analytics.hook';

// Constants
import {
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  useApiDelayOverlay,
  ApiDelayOverlay,
} from '@leagueplatform/health-journey-common';
import { ActivityHtmlToReactOptions } from 'common/activity-html-to-react-options';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';
import { ArrowButton } from './components/arrow-button.component';
import { LEFT, RIGHT } from './constants/activity-steps.constants';

const { CLOSE } = Icons;
const { COMPLETED } = HEALTH_JOURNEY_ACTIVITY_STATUS;
const { WAVE_GOODBYE } = HEALTH_JOURNEY_ASSET_KEYS;
const SLIDE_TRANSITION_DUR = 500;

// Local Components
const HideableBox = genesisStyled(Flex)(({ hidden }) =>
  css({
    visibility: hidden ? 'hidden' : 'visible',
  }),
);

const ExitModalWrapper = ({ showExitModal, children }) => (
  <Transition in={showExitModal} unmountOnExit timeout={250}>
    {(state) => (
      <Box
        backgroundColor="surface.background.primary"
        opacity={state === 'entered' ? 1 : 0}
        transition="opacity 200ms ease-out"
        position="fixed"
        width="100%"
        height="100%"
        zIndex="99"
      >
        {children}
      </Box>
    )}
  </Transition>
);

ExitModalWrapper.propTypes = {
  showExitModal: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const BtnContainer = ({ ...props }) => (
  <Flex
    maxHeight="100vh"
    alignSelf="stretch"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    position="sticky"
    top="0"
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <HideableBox justifyContent="center" position="relative" {...props} />
  </Flex>
);

export const ActivityStepsPage = () => {
  // Static Assets
  const leaveActivityAsset = handleStaticAsset(WAVE_GOODBYE);

  /** Hooks */
  const { formatMessage } = useIntl();
  const { returnToEntrypoint } = useActivityLink();

  // Activity
  const { ready, cta, status } = useHealthActivity();

  // Stepped Activity Analytics
  const activityStepsAnalytics = useActivityStepsAnalytics();

  // Api Delay Overlay
  const { delayOverlayActive, setDelayOverlayActive } = useApiDelayOverlay();

  // Local State
  const [currentStep, setCurrentStep] = useState(0);
  const [showExitModal, setShowExitModal] = useState(false);

  // Step Properties
  const { steps = [] } = cta?.completion_method ?? {};
  const stepCount = steps?.length;
  const isFinalStep = !steps.length || currentStep === steps.length - 1;

  const onExit = () => {
    activityStepsAnalytics.sendConfirmCloseActivity();
    returnToEntrypoint();
  };

  const toggleExitModal = (isOpen) => {
    if (isOpen) {
      // Steps are 0-indexed, so we add 1 to normalize the current step
      activityStepsAnalytics.sendCloseActivity(currentStep + 1);
    } else {
      activityStepsAnalytics.sendContinueActivity();
    }
    setShowExitModal(isOpen);
  };

  const closeButtonAction = () => {
    switch (status) {
      case COMPLETED:
        return onExit();
      default:
        return toggleExitModal(true);
    }
  };

  useOnEscape(() => closeButtonAction());
  const focusRef = (node) => node?.focus?.();

  useEffect(
    () => {
      if (ready) {
        activityStepsAnalytics.sendViewStep(currentStep + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, ready],
  );

  // redirect the user to the health activity details page if the activity does not have a stepped activity
  if (ready && !steps.length) {
    onExit();
    return null;
  }

  const onNext = () => {
    setCurrentStep((step) => {
      const newStep = (step + 1) % stepCount;
      activityStepsAnalytics.sendNextStep(newStep);
      return newStep;
    });
  };

  const onPrev = () => {
    setCurrentStep((step) => {
      const newStep = (stepCount + (step - 1)) % stepCount;
      activityStepsAnalytics.sendPreviousStep(newStep + 2);
      return newStep;
    });
  };

  const progressBarColor = 'decorative.brand.primary.dark';

  return (
    <Box height="100%" aria-live="assertive">
      <ExitModalWrapper showExitModal={showExitModal}>
        <AssessmentExitModal
          backgroundColor="surface.background.primary"
          exitScreen={{
            title: formatMessage({ id: 'EXIT_ACTIVITY_TITLE' }),
            description: formatMessage({ id: 'EXIT_ACTIVITY_BODY' }),
            buttonOk: formatMessage({ id: 'EXIT_ACTIVITY_CTA' }),
            buttonNo: formatMessage({ id: 'KEEP_GOING' }),
            imageId: '',
            image: leaveActivityAsset,
          }}
          setShowExit={toggleExitModal}
          ctaHandler={onExit}
          sendPageAnalytics={() =>
            activityStepsAnalytics.sendViewExitModal(currentStep + 1)
          }
        />
      </ExitModalWrapper>

      <Flex minHeight="100%" width="100%" paddingX={`${(174 / 1440) * 100}%`}>
        {/* Left Button */}
        <BtnContainer hidden={currentStep <= 0}>
          <ArrowButton
            orientation={LEFT}
            disabled={currentStep <= 0}
            onClick={onPrev}
          />
        </BtnContainer>

        {/* Content */}
        <Flex
          overflowX="hidden"
          paddingTop="four"
          paddingBottom="five"
          marginX="five"
          width="100%"
          flexWrap="wrap"
        >
          <Box marginBottom="three" width="100%">
            <SingleProgressBar
              active
              showContentTooltip={false}
              name={formatMessage(
                { id: 'X_OF_Y' },
                { current: currentStep + 1, total: steps.length },
              )}
              color={progressBarColor}
              totalSteps={steps.length}
              completedSteps={currentStep + 1}
            />
          </Box>

          {/* Slider */}
          <Flex
            justifyContent="flex-start"
            alignItems="flex-start"
            width="100%"
            transition={`transform ${SLIDE_TRANSITION_DUR}ms ${easeOutCubic}`}
            transform={`translateX(-${currentStep * 115}%)`}
          >
            {steps.map((step, index) => (
              <Box
                minWidth="100%"
                paddingBottom={isFinalStep ? 'five' : 'none'}
                marginRight="15%"
                minHeight="20vmin"
                opacity={index === currentStep ? 1 : 0}
                transition={`opacity ${SLIDE_TRANSITION_DUR}ms ease-in-out`}
              >
                <HtmlToReact
                  htmlString={step}
                  options={ActivityHtmlToReactOptions}
                />
              </Box>
            ))}
          </Flex>
        </Flex>

        {/* Right Button */}
        <BtnContainer hidden={isFinalStep}>
          <ArrowButton
            orientation={RIGHT}
            disabled={isFinalStep}
            onClick={onNext}
            focusRef={focusRef}
          />
        </BtnContainer>
      </Flex>

      {/* Close Button */}
      <Box zIndex="1" position="fixed" top="four" right="four">
        <VeryQuietButton
          onClick={() => closeButtonAction()}
          aria-label={formatMessage({ id: 'CLOSE' })}
          hideUnderline
        >
          <Icon icon={CLOSE} size="medium" />
        </VeryQuietButton>
      </Box>

      {isFinalStep && (
        <ActivityFooter isRemovable={false} isActivityDetailsView={false} />
      )}
      <React.Suspense fallback={null}>
        {delayOverlayActive && (
          <ApiDelayOverlay onClose={() => setDelayOverlayActive(false)} />
        )}
      </React.Suspense>
    </Box>
  );
};

export default ActivityStepsPage;
