import { useQuery } from 'react-query';
import {
  getUserHealthGoalPrograms,
  GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';

export const useHealthJourneyProgress = () => {
  const {
    data: userHealthGoalProgramsData,
    isError: userHealthGoalProgramsIsError,
    isLoading: userHealthGoalProgramsIsLoading,
  } = useQuery(
    [GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE],
    getUserHealthGoalPrograms,
    { staleTime: 3000 },
  );

  const activePrograms = userHealthGoalProgramsData?.programs || [];
  const numberOfAvailablePrograms =
    userHealthGoalProgramsData?.number_of_available_programs || 0;

  return {
    hasError: userHealthGoalProgramsIsError,
    activePrograms,
    isProgramLimitReached: numberOfAvailablePrograms === 0,
    isProgramsEmpty: userHealthGoalProgramsData?.programs?.length === 0,
    numberOfAvailablePrograms,
    programLimitModal: userHealthGoalProgramsData?.program_limit_modal,
    ready: !userHealthGoalProgramsIsLoading,
  };
};
