import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { arrayToTag } from '@leagueplatform/web-common';
import { GenesisRouterLink } from '@leagueplatform/web-common-components';
import {
  Flex,
  HeadingFour,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { CompoundProgressBar } from '@leagueplatform/ui-kit';
import { programProgressBarSegmentFactory } from '@leagueplatform/health-programs';
import {
  JOURNEY_ROUTES,
  ACTIVITY_CAMPAIGN_TYPE,
} from '@leagueplatform/health-journey-common';

import { DynamicCoverImage } from './dynamic-image.component';
import { useJourneyProgressAnalytics } from '../hooks/use-journey-progress-analytics.hook';

const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

export const ProgressActiveProgram = ({
  id,
  image,
  title,
  progressPercentages,
  activityStatusCounts,
  total,
  campaignType,
}) => {
  const { formatMessage } = useIntl();
  const progressAnalytics = useJourneyProgressAnalytics();
  const { completed, expired, removed } = activityStatusCounts ?? {};
  const missed = expired + removed;

  const progressSegments =
    programProgressBarSegmentFactory(progressPercentages);

  const progressCaption = arrayToTag([
    completed !== undefined
      ? formatMessage({ id: 'X_OF_Y_COMPLETE' }, { completed, total })
      : null,
    missed ? formatMessage({ id: 'X_MISSED' }, { missed }) : null,
  ]);

  const progressCaptionId = `progress-caption-${id}`;

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const ProgressSubtitle = (props) => (
    <SubtitleOne
      id={progressCaptionId}
      color="onSurface.text.subdued"
      marginTop="half"
      as="p"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const ProgressCardContent = () => (
    <>
      <CompoundProgressBar
        role="progressbar"
        aria-labelledby={progressCaptionId}
        segments={progressSegments}
        height="threeQuarters"
        marginTop={{ _: 'one', phone: 'oneAndHalf' }}
      />
      <ProgressSubtitle>{progressCaption}</ProgressSubtitle>
    </>
  );

  const onClickActions = () =>
    progressAnalytics.sendSelectActiveProgram({
      campaignName: title,
      campaignId: id,
      campaignType,
    });

  const getLinkPath = () =>
    campaignType === CHALLENGE
      ? JOURNEY_ROUTES.getChallengeDetails(id)
      : `${JOURNEY_ROUTES.getProgramDetails(id)}?b=progress`;

  return (
    <GenesisRouterLink
      hoverStyle={{
        backgroundColor: 'surface.background.secondary',
        textDecoration: 'none !important',
      }}
      onClick={() => onClickActions()}
      width="100%"
      to={getLinkPath()}
    >
      <Flex
        flexDirection={{ _: 'column', phone: 'row' }}
        alignItems={{ _: 'unset', phone: 'center' }}
        paddingY="oneAndHalf"
      >
        {image && (
          <DynamicCoverImage
            src={image}
            alt=""
            width={{ _: 110, phone: 206 }}
            height={{ _: 70, phone: 131 }}
            marginRight={{ _: '0', phone: 'oneAndHalf' }}
            marginBottom={{ _: 'one', phone: '0' }}
            borderRadius="medium"
          />
        )}
        <Flex flexDirection="column" flexGrow="2">
          {title && (
            <HeadingFour
              as="p"
              fontSize={{ _: 'heading4', tablet: 'heading3' }}
            >
              {title}
            </HeadingFour>
          )}
          <ProgressCardContent />
        </Flex>
      </Flex>
    </GenesisRouterLink>
  );
};

ProgressActiveProgram.propTypes = {
  total: PropTypes.number,
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  progressPercentages: PropTypes.shape({
    missed: PropTypes.number,
    completed: PropTypes.number,
  }),
  activityStatusCounts: PropTypes.shape({
    active: PropTypes.number,
    upcoming: PropTypes.number,
    completed: PropTypes.number,
    expired: PropTypes.number,
    removed: PropTypes.number,
  }),
  campaignType: PropTypes.oneOf(['opt-in', 'challenge']),
};

ProgressActiveProgram.defaultProps = {
  total: 0,
  id: '',
  image: '',
  title: '',
  progressPercentages: {
    completed: 0,
    missed: 0,
  },
  activityStatusCounts: {
    active: 0,
    upcoming: 0,
    completed: 0,
    expired: 0,
    removed: 0,
  },
  campaignType: 'opt-in',
};
