import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from '@leagueplatform/routing';
import { isValidUrl } from '@leagueplatform/web-common';
import {
  LocationStateWithReferrer,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';

const cleanURL = (url: string) => url?.trim().replace(/^app|^\/app/, '');

export const useActivityLink = () => {
  const history = useHistory();
  const location = useLocation<LocationStateWithReferrer>();
  const { state: locationState } = location;
  const { referrerStack = [] } = locationState || {};
  const entrypointPath = useMemo(() => {
    const [url = JOURNEY_ROUTES.journeyHome()] = referrerStack;
    return cleanURL(url);
  }, [referrerStack]);

  const returnToEntrypoint = useCallback(() => {
    if (!referrerStack) {
      history.push(cleanURL(JOURNEY_ROUTES.journeyHome()));
    } else {
      history.go(-(referrerStack?.length || 1));
    }
  }, [history, referrerStack]);

  const followDeepLink = useCallback(
    (pathname: string) => {
      const pathnameWithoutApp = cleanURL(pathname);
      history.push(pathnameWithoutApp, {
        ...location.state,
        referrerStack: [...referrerStack, pathname],
      });
    },
    [history, location.state, referrerStack],
  );

  /** If the string provided is a valid URL, the link opens in a new tab. Otherwise it is treated as a deeplink */
  const followLink = useCallback(
    (link: string) =>
      isValidUrl(link) ? window.open(link, '_blank') : followDeepLink(link),
    [followDeepLink],
  );

  return { followLink, returnToEntrypoint, entrypointPath };
};
