import { useLeagueWSQuery } from '@leagueplatform/api';
import { ActivityProgress } from 'types/activity-progress.type';

export function useVerifiableActivityProgressQuery(
  activityId: string,
  isVerifiableActivity = true,
) {
  const messageType = 'get_verifiable_activity_progress';
  const info = {
    activity_id: activityId,
  };

  return useLeagueWSQuery<ActivityProgress>(
    [messageType, activityId],
    {
      message_type: messageType,
      info,
    },
    { enabled: isVerifiableActivity },
  );
}
