import * as React from 'react';
import { Box, CoverImage } from '@leagueplatform/genesis-commons';
import {
  ErrorState,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  RecentAchievements,
  AchievementStats,
  useAchievementsDetail,
} from '@leagueplatform/rewards-achievements';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/health-journey-common';

import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { useJourneyProgressAnalytics } from 'hooks/use-journey-progress-analytics.hook';
import { ActiveCampaigns } from './active-campaigns.component';
import { EmptyStateCard } from './empty-state-card.component';
import { useHealthJourneyProgress } from '../hooks/use-health-journey-progress.hook';
import { CampaignLimitBanner } from './campaign-limit-banner.component';

const { EMPTY_STATE_PROGRESS } = HEALTH_JOURNEY_ASSET_KEYS;

const EmptyStateProgress = () =>
  handleStaticAsset(EMPTY_STATE_PROGRESS, {
    isComponent: true,
    props: {
      as: CoverImage,
      backgroundSize: 'contain',
      borderRadius: 'large',
      marginBottom: 'minusOne',
      size: 96,
    },
  });

const NoActivePrograms = () => {
  const { formatMessage } = useIntl();
  return (
    <EmptyStateCard
      bodyText={formatMessage({ id: 'TAP_ADD_PROGRAM_BUTTON' })}
      graphic={<EmptyStateProgress />}
      headingText={formatMessage({ id: 'YOU_HAVENT_STARTED_PROGRAMS' })}
    />
  );
};

const RecentAchievementsView = () => {
  const { completedAchievementStats, recentAchievements } =
    useAchievementsDetail();
  return (
    <Box display={{ _: 'block', laptop: 'none' }}>
      <AchievementStats completedAchievements={completedAchievementStats} />
      <RecentAchievements achievements={recentAchievements} />
    </Box>
  );
};

export const CampaignProgressContent = () => {
  const { activePrograms, hasError, isProgramsEmpty, ready } =
    useHealthJourneyProgress();
  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);
  const progressAnalytics = useJourneyProgressAnalytics();

  React.useEffect(() => {
    if (ready) {
      progressAnalytics.sendViewProgressPage();
    }
  }, [progressAnalytics, ready]);

  if (!ready)
    return (
      <Box
        position="relative"
        minHeight="30vh"
        height="auto"
        width="100%"
        data-testid="loading-indicator"
      >
        <LoadingIndicator />
      </Box>
    );

  if (hasError) return <ErrorState />;

  return isProgramsEmpty ? (
    <>
      {isAchievementsEnabled && <RecentAchievementsView />}
      <NoActivePrograms />
    </>
  ) : (
    <>
      {isAchievementsEnabled && <RecentAchievementsView />}
      <ActiveCampaigns programs={activePrograms} />
      <CampaignLimitBanner />
    </>
  );
};
