import React, { FC } from 'react';
import { TextInput } from '@leagueplatform/genesis-core';
import { IntegerInputAttributes } from '@leagueplatform/health-journey-api';
import { ControlledFormField } from '@leagueplatform/web-common';
import { useNumberInputOptions } from 'pages/health-activity/hooks/use-number-input-options.hook';
import { ToolboxCommonInputProps } from '../../types/health-activity.types';
import {
  ActivityToolboxInputWrapper,
  MapInputValueToProps,
} from '../activity-toolbox-input-wrapper.component';

interface IntegerInputProps
  extends ToolboxCommonInputProps,
    IntegerInputAttributes {}

export const ActivityToolboxIntegerInput: FC<IntegerInputProps> = ({
  answer,
  altText,
  suffix,
  prefix,
  css,
  placeholder,
  validation,
  componentId,
}) => {
  const inputOptions = useNumberInputOptions({
    validation,
    isInteger: true,
  });

  const mapValueToProps: MapInputValueToProps<string> = (value, component) => {
    Object.assign(component, {
      componentAttributes: {
        ...component.componentAttributes,
        answer: Number(value),
      },
    });

    return component;
  };

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <ControlledFormField
        css={css}
        hideLabel
        id={componentId}
        label={altText}
        name={componentId}
        inputOptions={inputOptions}
      >
        <TextInput
          aria-label={altText}
          type="number"
          addOnStart={prefix || null}
          addOnEnd={suffix || null}
          placeholder={placeholder}
          defaultValue={answer}
          data-testid={componentId}
        />
      </ControlledFormField>
    </ActivityToolboxInputWrapper>
  );
};
