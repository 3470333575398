import { useMutation, useQueryClient } from 'react-query';
import {
  GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
  setActivityProgress,
  SET_ACTIVITY_PROGRESS_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import { useActivityParams } from './use-activity-params.hook';

export const useSetActivityProgress = () => {
  const queryClient = useQueryClient();
  const { userActivityId, campaignId, contentActivityId } = useActivityParams();
  const mutation = useMutation(
    (activityProgress) => setActivityProgress(userActivityId, activityProgress),

    {
      mutationKey: [SET_ACTIVITY_PROGRESS_MESSAGE_TYPE, userActivityId],
      onSettled(data, error, activityProgress) {
        queryClient.setQueryData(
          [
            GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
            userActivityId,
            contentActivityId,
            campaignId,
          ],
          (prevActivityData) => {
            // If there is no existing activity cache to update, return undefined
            if (!prevActivityData || !prevActivityData?.health_activity)
              return prevActivityData;

            // Compose new activity cache data object from the existing cache including the updated progress response
            const newActivityData = Object.assign(prevActivityData, {
              health_activity: {
                ...prevActivityData.health_activity,
                activity_verification_progress: {
                  ...prevActivityData.health_activity
                    .activity_verification_progress,
                  ...activityProgress,
                },
              },
            });
            return newActivityData;
          },
        );
      },
    },
  );

  return mutation;
};
