import { useQuery } from 'react-query';
import {
  getUserCampaigns,
  GET_USER_CAMPAIGNS_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import {
  ACTIVITY_CAMPAIGN_TYPE,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
} from '@leagueplatform/health-journey-common';

const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;
const { ACTIVE, UPCOMING } = HEALTH_JOURNEY_ACTIVITY_STATUS;

export const useUserCampaigns = () => {
  const campaignTypes = [CHALLENGE];
  const campaignStatuses = [ACTIVE, UPCOMING];

  const {
    data: userCampaignsData = [],
    isError: userCampaignsIsError,
    isLoading: userCampaignsIsLoading,
  } = useQuery([GET_USER_CAMPAIGNS_MESSAGE_TYPE], () =>
    getUserCampaigns(campaignTypes, campaignStatuses),
  );

  const filterCampaigns = (data, filter) =>
    data?.campaigns?.filter(({ status }) => status === filter) ?? [];

  return {
    campaigns: {
      ready: !userCampaignsIsLoading,
      hasError: userCampaignsIsError,
      data: userCampaignsData?.campaigns,
      activeCampaigns: filterCampaigns(userCampaignsData, ACTIVE),
      upcomingCampaigns: filterCampaigns(userCampaignsData, UPCOMING),
    },
  };
};
