import React from 'react';
import {
  HeadingThree,
  Box,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { SkeletonBox, Carousel } from '@leagueplatform/web-common-components';
import { CAROUSEL_CONTENT_CARD_WIDTH } from '@leagueplatform/masonry-widgets';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { useActivitiesByActivityStatus } from 'hooks/use-activities-by-activity-status.hook';
import { viewAllActivitiesAnalytics } from 'utils/view-all-activities-analytics.util';
import { getActivityStatusesArray } from 'utils/get-activity-statuses-array.util';
import { ConfigurableActivityCard } from '../configurable-activity-card/configurable-activity-card.component';

interface ActivitiesByStatusProps {
  statusType: 'completed' | 'missed';
}
// Carousel Wrapper is used to specifically update the `li` styles
// within, otherwise cards will not resize appropriately
const CarouselWrapper = genesisStyled(Box)`
  li {
    width: 100%;
    min-width: ${CAROUSEL_CONTENT_CARD_WIDTH};
    width: ${CAROUSEL_CONTENT_CARD_WIDTH};
  }
`;

export const ActivitiesByStatus = ({ statusType }: ActivitiesByStatusProps) => {
  const { formatMessage } = useIntl();
  const activityStatuses = getActivityStatusesArray(statusType);
  const {
    data: activities,
    isLoading,
    isError,
  } = useActivitiesByActivityStatus({ activityStatuses });
  // TODO: update isLoading and error states in XPNC-2724
  if (isLoading)
    return (
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height="200px"
        marginBottom="one"
      />
    );
  const activitiesByStatus = activities?.userHealthActivities;
  const sectionTitle = formatMessage({
    id: `${statusType.toUpperCase()}_ACTIVITIES`,
  });
  // in case of errors or no activities, return nothing
  if (isError || !activitiesByStatus?.length) {
    return null;
  }
  const linkUrl =
    statusType === 'completed'
      ? JOURNEY_ROUTES.getCompletedActivityHistory()
      : JOURNEY_ROUTES.getMissedActivityHistory();
  // We should only display the see more link if we have more than 3
  // activities in the carousel
  const displaySeeMoreLink = activitiesByStatus.length > 3;
  return (
    <CarouselWrapper>
      <Carousel
        heading={<HeadingThree as="h2">{sectionTitle}</HeadingThree>}
        name={sectionTitle}
        spaceBetweenCards="oneAndHalf"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(displaySeeMoreLink && {
          linkAriaLabel: formatMessage({
            id: `SEE_MORE_${statusType.toUpperCase()}_ACTIVITIES_ARIA_LABEL`,
          }),
          linkCta: formatMessage({ id: 'SEE_MORE_ACTIVITIES' }),
          linkUrl,
          linkOnClick: () => viewAllActivitiesAnalytics(statusType),
        })}
      >
        {activitiesByStatus.slice(0, 3).map((activity) => (
          <ConfigurableActivityCard
            as="div"
            activity={activity}
            key={activity.id}
            height="100%"
            activityHeadingLevel="h3"
          />
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};
