import React, { forwardRef } from 'react';
import * as RadixTabsPrimitive from '@radix-ui/react-tabs';
import { Box } from 'components/box/box';
import { styled } from '../../theme';
import { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import { useTabsScroll } from './use-tabs-scroll';
import {
  ScrollableWrapper,
  ScrollableItemsWrapper,
  ScrollLeft,
  ScrollRight,
} from './scroll-buttons';
import { TabListContextProvider } from './tablist-context';

export interface GDSTabListProps
  extends RadixTabsPrimitive.TabsListProps,
    GDSCustomizableComponent {
  divider?: boolean;
  tabDistribution?: GDSResponsiveProp<'distributeEvenly' | 'hugContents'>;
}

const BaseTablist = styled(RadixTabsPrimitive.List, {
  display: 'flex',
  gap: '$half',
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0px',
    background: 'transparent' /* make scrollbar transparent */,
  },
  variants: {
    tabDistribution: {
      distributeEvenly: {
        justifyContent: 'stretch',
        '> .GDS-tab': {
          flex: '1 0 auto',
        },
      },
      hugContents: {
        justifyContent: 'flex-start',
      },
    },
  },
});

export const TabList = forwardRef<HTMLDivElement, GDSTabListProps>(
  (
    {
      children,
      className,
      css,
      divider,
      loop = false,
      tabDistribution = 'distributeEvenly',
      ...props
    },
    ref,
  ) => {
    const responsiveTabDistribution = useResponsiveProp(tabDistribution);
    const responsiveDivider = useResponsiveProp(divider);
    const {
      scrollLeft,
      scrollRight,
      tabsList,
      showScrollLeft,
      showScrollRight,
    } = useTabsScroll();
    const composedRefs = useComposedRefs(
      ref,
      tabsList as React.Ref<HTMLDivElement>,
    );
    const tabListWrapperRef = React.useRef(null);
    const scrollLeftButtonRef = React.useRef(null);
    const scrollRightButtonRef = React.useRef(null);

    const tabListElements = {
      tabListWrapper: tabListWrapperRef.current,
      scrollLeftButton: scrollLeftButtonRef.current,
      scrollRightButton: scrollRightButtonRef.current,
    };

    return (
      <TabListContextProvider tabListElements={tabListElements}>
        <Box css={css}>
          <ScrollableWrapper>
            {showScrollLeft && (
              <ScrollLeft
                ref={scrollLeftButtonRef}
                onClick={() => scrollLeft()}
              />
            )}
            <ScrollableItemsWrapper
              className="GDS-tab-list-wrapper"
              ref={tabListWrapperRef}
              css={{
                ...(responsiveDivider && {
                  borderBlockEnd:
                    '$borderWidths$thin solid $onSurfaceBorderSubdued',
                }),
              }}
            >
              <BaseTablist
                ref={composedRefs}
                className={[`GDS-tab-list`, className].join(' ')}
                tabDistribution={responsiveTabDistribution}
                loop={loop}
                {...props}
              >
                {children}
              </BaseTablist>
            </ScrollableItemsWrapper>
            {showScrollRight && (
              <ScrollRight
                ref={scrollRightButtonRef}
                onClick={() => scrollRight()}
              />
            )}
          </ScrollableWrapper>
        </Box>
      </TabListContextProvider>
    );
  },
);
