import React, { FC } from 'react';
import {
  Icon,
  ParagraphText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';

import { MultipleSelectAnswerOption } from '@leagueplatform/health-journey-api';

export interface ActivityToolboxOptionLabelProps
  extends Pick<MultipleSelectAnswerOption, 'text' | 'subText' | 'iconUrl'> {
  ariaLabel?: string;
}

export const ActivityToolboxOptionLabel: FC<
  ActivityToolboxOptionLabelProps
> = ({ text, subText, iconUrl, ariaLabel }) => (
  <StackLayout
    orientation="horizontal"
    horizontalAlignment="center"
    verticalAlignment="center"
    spacing="$one"
    aria-label={[text, subText, ariaLabel].filter(Boolean).join('; ')}
  >
    {iconUrl && <Icon icon={iconUrl} aria-hidden />}
    <StackLayout spacing="$quarter">
      <UtilityText>{text}</UtilityText>
      {subText && (
        <ParagraphText
          size="xs"
          css={{ typography: '$caption', color: '$onSurfaceTextSubdued' }}
        >
          {subText}
        </ParagraphText>
      )}
    </StackLayout>
  </StackLayout>
);
