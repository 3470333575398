import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, HeadingThree } from '@leagueplatform/genesis-commons';
import {
  HEALTH_JOURNEY_COMMON_ASSET_KEYS,
  HEALTH_JOURNEY_MASONRY_VERSION,
  JOURNEY_ROUTES,
  ENABLE_NEW_JOURNEY_PROGRESS,
} from '@leagueplatform/health-journey-common';
import {
  useGetWidgetData,
  ContainerWidgetItem,
} from '@leagueplatform/masonry-api';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { CampaignWithNextActivityWS } from 'types/configurable-health-program.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { CampaignProgressCard } from '../campaign-progress-card/campaign-progress-card.component';
import { ConfigurableJourneyEmptyStateCard } from '../configurable-journey-empty-state-card/configurable-journey-empty-state-card';

interface CampaignsListWidgetProps {
  widget: ContainerWidgetItem;
  campaignType: 'challenge' | 'opt-in';
}

// @TODO change this wrt to the attributes recieved by this custom component.
interface CampaignCustomWidgetAttributes {
  items?: CampaignWithNextActivityWS[];
}

export const CampaignsListWidget = ({
  widget,
  campaignType,
}: CampaignsListWidgetProps) => {
  const { formatMessage } = useIntl();
  const { isLoading, error, data } =
    useGetWidgetData<CampaignCustomWidgetAttributes>(
      widget,
      HEALTH_JOURNEY_MASONRY_VERSION,
    );

  const { EMPTY_STATE_CONFIGURABLE_ACTIVITIES } =
    HEALTH_JOURNEY_COMMON_ASSET_KEYS;
  const emptyStateImgUrl = handleStaticAsset(
    EMPTY_STATE_CONFIGURABLE_ACTIVITIES,
  ) as string;

  const { data: isNewJourneyProgressEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_PROGRESS,
  );

  if (isNewJourneyProgressEnabled) {
    return null;
  }

  if (isLoading)
    return (
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height="200px"
        marginBottom="one"
        data-testid="skeleton-loader"
      />
    );

  // TODO: log error when we get to observability tickets
  const isErrorState =
    error ||
    !data?.attributes ||
    // If there are no challenges, don't display anything. Don't want to display empty state, since there may be no challenges to sign up for.
    (campaignType === 'challenge' && !data?.attributes?.items?.length);
  if (isErrorState) return null;

  const { items: campaignList } = data.attributes;
  const isEmptyState = campaignType === 'opt-in' && !campaignList?.length;
  if (isEmptyState)
    return (
      <ConfigurableJourneyEmptyStateCard
        eyebrow={formatMessage({
          id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW',
        })}
        heading={formatMessage({
          id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING',
        })}
        headingLevel="2"
        description={formatMessage({
          id: 'NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION',
        })}
        imgSrc={emptyStateImgUrl}
        ctaLabel={formatMessage({ id: 'EXPLORE_LIBRARY' })}
        ctaHref={JOURNEY_ROUTES.programsHome()}
        backgroundColor="highlight"
      />
    );

  return (
    <>
      {/* TODO: update and pull from heading field in BE response if BE ends up handling translations */}
      <HeadingThree as="h2">
        {formatMessage({
          id: campaignType === 'challenge' ? 'CHALLENGES' : 'PROGRAMS',
        })}
      </HeadingThree>
      <Box as="ul" paddingTop="threeQuarters">
        {campaignList?.map((campaign) => (
          <CampaignProgressCard
            campaign={campaign}
            key={campaign.program.program_id}
          />
        ))}
      </Box>
    </>
  );
};
