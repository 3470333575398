import { createStitches, defaultThemeMap } from '@stitches/react';
import type * as Stitches from '@stitches/react';
import { emptyThemeDefinition } from './theme-files/empty-theme-definition';
import { typography } from './utils/typography';
import { marginX, marginY } from './utils/margins';
import { paddingX, paddingY } from './utils/padding';
import { breakpoints } from '../breakpoints/constants';

const themeDefinition = {
  theme: emptyThemeDefinition, // Empty theme to be overwritten with a client theme
  media: {
    mobile: `(max-width:${breakpoints.mobileLandscape - 1}px)`,
    mobileLandscape: `(min-width:${
      breakpoints.mobileLandscape
    }px) and (max-width:${breakpoints.tablet - 1}px)`,
    tablet: `(min-width:${breakpoints.tablet}px) and (max-width:${
      breakpoints.laptop - 1
    }px)`,
    laptop: `(min-width:${breakpoints.laptop}px) and (max-width:${
      breakpoints.desktop - 1
    }px)`,
    desktop: `(min-width:${breakpoints.desktop}px)`,
  },
  utils: {
    typography,
    marginX,
    marginY,
    paddingX,
    paddingY,
  },
  themeMap: {
    ...defaultThemeMap,
    textTransform: 'textTransforms',
    textDecoration: 'textDecorations',
    marginX: 'space',
    marginY: 'space',
    paddingX: 'space',
    paddingY: 'space',
    borderStartStartRadius: 'radii',
    borderStartEndRadius: 'radii',
    borderEndStartRadius: 'radii',
    borderEndEndRadius: 'radii',
  },
  prefix: 'GDS',
};

export const { styled, theme, createTheme, config, css, keyframes, globalCss } =
  createStitches(themeDefinition);

export type GDSStyleObject = Stitches.CSS<typeof config>;
export type GDSVariantProps<
  T extends { [key: string]: any; [key: symbol]: any },
> = Stitches.VariantProps<T>;

export type Prefixed<K extends string, T> = `${K}${Extract<T, string>}`;
export type GDSColor = Prefixed<'$', keyof typeof config.theme.colors>;
export type GDSSpace = Prefixed<'$', keyof typeof config.theme.space>;
export type GDSSize = Prefixed<'$', keyof typeof config.theme.sizes>;
export type GDSBorderRadius = Prefixed<'$', keyof typeof config.theme.radii>;

export type GDSStitchesTheme = typeof theme;
