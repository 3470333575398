import React from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { Box, HeadingThree } from '@leagueplatform/genesis-commons';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { ChallengeLink } from 'components/challenge-link/challenge-link.component';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { EmptyActivityState } from 'components/empty-activity-state/empty-activity-state.component';
import { TLPCard } from '../tlp-card/tlp-card.component';
import { TLPProgress } from './tlp-progress/tlp-progress.component';
import { InviteFriendsCTA } from './invite-friends-cta/invite-friends-cta.component';
import { TeamLeaderboard } from './team-leaderboard.component';

export interface TLPSidebarProps {
  challengeName: string;
  teamName: string;
  challengeStatus: string;
  enrollmentOpen: boolean;
  spotsAvailable: number;
  userIsOnCurrentTeam: boolean;
}

export const TLPSidebar = ({
  challengeName,
  teamName,
  challengeStatus,
  enrollmentOpen,
  spotsAvailable,
  userIsOnCurrentTeam,
}: TLPSidebarProps) => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();

  const canInviteFriends =
    userIsOnCurrentTeam && spotsAvailable > 0 && enrollmentOpen;

  return (
    <Box width={{ _: '100%', tablet: '50%', laptop: '40%' }}>
      <ChallengeLink
        to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
        title={formatMessage({ id: 'CHALLENGE' })}
        description={challengeName}
      />
      <TLPProgress />
      <Box marginBottom="one">
        <TLPCard>
          <HeadingThree as="h2" marginBottom="oneAndHalf">
            {formatMessage({ id: 'LEADERBOARD' })}
          </HeadingThree>

          {challengeStatus === CHALLENGE_STATUS.UPCOMING ? (
            <EmptyActivityState activityDescriptionId="NO_LEADERBOARD_ACTIVITY_DESCRIPTION" />
          ) : (
            <TeamLeaderboard />
          )}
        </TLPCard>
      </Box>
      {canInviteFriends && <InviteFriendsCTA teamName={teamName} />}
    </Box>
  );
};
