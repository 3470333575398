import React, { useMemo } from 'react';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { NodeResolver } from './components/node-resolver.component';
import { getMasonryEngineConfigContext } from './masonry-engine-config-context';
import type {
  MasonryEngineNode,
  MasonryEngineNodeAction,
} from './types/masonry-engine-node.types';
import { getMasonryEngineObservabilityContext } from './utils/masonry-engine-observability-content';
import { logError } from './utils/log';
import { MasonryEngineStateControllerProvider } from './masonry-engine-state-controller-context';
import type { MasonryEngineDriver } from './types/masonry-engine-driver.types';

type MasonryEngineProps<
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
> = {
  driver: MasonryEngineDriver<Node, Action>;
};

/**
 * The entry point to rendering a MasonryEngine flow. takes a {@link MasonryEngineDriver `MasonryEngineDriver`} and communicates
 * with the driver to render the correct screen.
 */
export const MasonryEngine = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>({
  driver: Driver,
}: MasonryEngineProps<Node, Action>) => {
  const memoized = useMemo(() => {
    const MasonryEngineConfigProvider = getMasonryEngineConfigContext<
      Node,
      Action
    >().Provider;

    return (
      <ObservabilityErrorBoundary
        errorContext={getMasonryEngineObservabilityContext({
          tags: {
            errorName: `MasonryEngine - Error from Masonry Engine`,
          },
        })}
        onError={(err: Error) => {
          logError(`Error from Masonry Engine: ${err.message}`);
        }}
      >
        <Driver>
          {(node, nodeRenderers, actionController) => (
            <MasonryEngineConfigProvider
              value={{
                nodeRenderers,
                actionController,
              }}
            >
              <MasonryEngineStateControllerProvider>
                <NodeResolver node={node} />
              </MasonryEngineStateControllerProvider>
            </MasonryEngineConfigProvider>
          )}
        </Driver>
      </ObservabilityErrorBoundary>
    );
  }, [Driver]);
  return memoized;
};
