import React, { useEffect } from 'react';
import { useParams } from '@leagueplatform/routing';
import {
  Flex,
  Box,
  QuietButton,
  VisuallyHidden,
  HeadingThree,
  Spinner,
  css,
  genesisStyled,
  StyleProps,
} from '@leagueplatform/genesis-commons';
import { Modal, ICONS, Icon } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { ErrorState } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { getParticipantFirstNames } from 'pages/utils/get-participant-firstnames.util';
import { ParticipantsAvatar } from 'components/participants-avatar/participants-avatar';
import { useMessageThreadDetails } from 'hooks/use-message-thread-details.hook';
import { useRemoveCurrentUser } from 'hooks/use-remove-current-user.hook';
import {
  MESSAGING_SCREEN_NAMES,
  trackMessagingAnalyticsEvent,
} from 'utils/track-messaging-analytics.util';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { SidebarMembersList } from './sidebar-members-list/sidebar-members-list.component';

export interface DetailsSidebarProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_SIDEBAR_WIDTH = '484px';
const OVERLAY_PADDING_TOP = '100px';

const DetailsSidebarHeading = genesisStyled(HeadingThree)<StyleProps>(
  ({ theme }) =>
    css({
      [`@media screen and (max-width: ${theme.breakpoints.phone})`]: {
        hyphens: 'auto',
      },
    }),
);

const DetailsSidebarContents = () => {
  const { threadId } = useParams<{ threadId: string }>();
  const {
    data: threadData,
    isLoading,
    error,
  } = useMessageThreadDetails(threadId);

  const filteredParticipants = useRemoveCurrentUser(
    threadData?.data?.participants?.data,
  );

  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        color="decorative.brand.primary.default"
        height="80%"
      >
        <Spinner isLoading />
      </Flex>
    );
  }
  if (error || !threadData?.data) {
    const errorImage = handleStaticAsset(
      MESSAGING_ASSET_KEYS.MESSAGING_ERROR,
    ) as string;
    return (
      <Flex alignItems="center" justifyContent="center" height="80%">
        <Box>
          <ErrorState
            imageSrc={errorImage}
            hideButton
            headingId="GENERIC_MESSAGING_ERROR"
          />
        </Box>
      </Flex>
    );
  }
  const {
    subject,
    participants: { data: participantsData },
  } = threadData.data;
  const userNames = getParticipantFirstNames(participantsData);

  return (
    <>
      <Flex flexDirection="column" alignItems="center" width="100%">
        <ParticipantsAvatar participants={filteredParticipants} />
        <DetailsSidebarHeading
          padding="half"
          paddingBottom="oneAndHalf"
          textAlign="center"
        >
          {subject || userNames.join(', ')}
        </DetailsSidebarHeading>
      </Flex>
      <Box
        overflowY="auto"
        flexGrow={1}
        padding={{ _: 'half', phone: 'oneAndHalf' }}
      >
        <SidebarMembersList participants={participantsData} />
      </Box>
    </>
  );
};

export function DetailsSidebar({ onClose }: DetailsSidebarProps) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    trackMessagingAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      screen_name: MESSAGING_SCREEN_NAMES.THREAD_DETAILS,
    });
  }, []);
  return (
    <Modal.Wrapper options={{}} onClose={onClose}>
      <Flex
        position="absolute"
        right="0"
        left={{ _: '0', phone: 'unset' }}
        flexDirection="column"
        maxWidth={MAX_SIDEBAR_WIDTH}
        width="100%"
        aria-labelledby="details-sidebar"
        backgroundColor="surface.background.primary"
        height="100vh"
      >
        <Flex
          justifyContent="flex-end"
          position="relative"
          paddingTop={OVERLAY_PADDING_TOP}
        >
          <QuietButton
            lineHeight="caption"
            top="half"
            right={{ _: 'half', phone: 'one' }}
            paddingX="half"
            paddingY="half"
            onClick={() => onClose(false)}
            position="absolute"
          >
            <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
          </QuietButton>
        </Flex>
        <DetailsSidebarContents />
      </Flex>
    </Modal.Wrapper>
  );
}
