import React from 'react';
import { Image } from '@leagueplatform/genesis-commons';
import {
  Button,
  Card,
  GDSCardElevatedProps,
  GDSHeadingLevel,
  HeadingText,
  ParagraphText,
  queryHelpers,
  Responsive,
  StackLayout,
  useMediaQuery,
  UtilityText,
} from '@leagueplatform/genesis-core';

interface ConfigurableJourneyEmptyStateCardProps {
  eyebrow?: string;
  heading: string;
  description: string;
  imgSrc: string;
  ctaLabel?: string;
  ctaHref?: string;
  backgroundColor?: GDSCardElevatedProps['backgroundColor'];
  headingLevel: GDSHeadingLevel;
  analyticsOnClick?: () => void;
}

const EMPTY_STATE_IMAGE_SIZE = '116px';

export const ConfigurableJourneyEmptyStateCard = ({
  eyebrow,
  heading,
  description,
  imgSrc,
  ctaLabel,
  ctaHref,
  backgroundColor,
  headingLevel,
  analyticsOnClick,
}: ConfigurableJourneyEmptyStateCardProps) => {
  const belowMobileLandscape = useMediaQuery(
    queryHelpers.down('mobileLandscape'),
  );
  return (
    <Card.Elevated
      backgroundColor={backgroundColor ?? 'primary'}
      css={{ width: '100%' }}
    >
      <StackLayout orientation="horizontal" verticalAlignment="center">
        <Card.Section>
          <UtilityText size="eyebrow">{eyebrow}</UtilityText>
          <HeadingText
            level={headingLevel}
            size={belowMobileLandscape ? 'sm' : 'lg'}
            css={{
              marginTop: '$half',
            }}
          >
            {heading}
          </HeadingText>
          <ParagraphText
            size="sm"
            css={{ marginTop: '$quarter', marginBottom: '$one' }}
          >
            {description}
          </ParagraphText>
          {ctaLabel && ctaHref && (
            <Button
              as="a"
              href={ctaHref}
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
              css={{
                height: '$twoAndHalf',
                fontSize: '$bodyTwo',
              }}
              onClick={analyticsOnClick}
            >
              {ctaLabel}
            </Button>
          )}
        </Card.Section>
        <Responsive up="mobileLandscape">
          <Card.Section css={{ maxWidth: 'fit-content' }}>
            {imgSrc !== ' ' && (
              <Image src={imgSrc} alt="" size={EMPTY_STATE_IMAGE_SIZE} />
            )}
          </Card.Section>
        </Responsive>
      </StackLayout>
    </Card.Elevated>
  );
};
