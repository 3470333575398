import {
  type,
  intersection,
  record,
  string,
  unknown,
  optional,
  object,
} from 'superstruct';
import type { Infer } from 'superstruct';

import { coreConfigSchema } from './module-config-schemas/core';
import { assetConfigConfigSchema } from './module-config-schemas/asset-config';
import { exampleModuleConfigSchema } from './module-config-schemas/example-module';
import { careCollectionsConfigSchema } from './module-config-schemas/care-collections';
import { aboutMeConfigSchema } from './module-config-schemas/about-me';
import { claimsConfigSchema } from './module-config-schemas/claims';
import { customerSupportConfigSchema } from './module-config-schemas/customer-support';
import { healthProfileConfigSchema } from './module-config-schemas/health-profile';
import { careDiscoveryConfigSchema } from './module-config-schemas/care-discovery';
import { messagingConfigSchema } from './module-config-schemas/messaging';
import { walletConfigSchema } from './module-config-schemas/wallet';
import { liveChatConfigSchema } from './module-config-schemas/live-chat';
import { healthJourneyConfigSchema } from './module-config-schemas/health-journey';
import {
  type KnownServerConfig,
  type ServerConfig,
} from './module-config-schemas/server-driven';

export const knownLeagueConfigSchema = type({
  core: coreConfigSchema,
  assets: optional(assetConfigConfigSchema),
  customerSupport: optional(customerSupportConfigSchema),
  healthProfile: optional(healthProfileConfigSchema),
  healthJourney: optional(healthJourneyConfigSchema),
  careCollections: optional(careCollectionsConfigSchema),
  careDiscovery: optional(careDiscoveryConfigSchema),
  messaging: optional(messagingConfigSchema),
  aboutMe: optional(aboutMeConfigSchema),
  wallet: optional(walletConfigSchema),
  claims: optional(claimsConfigSchema),
  liveChat: optional(liveChatConfigSchema),
  exampleModule: optional(exampleModuleConfigSchema),
  __internal: optional(object()),
});

export type KnownLeagueConfig = Infer<typeof knownLeagueConfigSchema>;

export const unknownLeagueConfigSchema = record(string(), unknown());

export type UnknownLeagueConfig = Infer<typeof unknownLeagueConfigSchema>;

export type KnownLeagueCombinedConfig = KnownServerConfig & KnownLeagueConfig;

export type LeagueCombinedConfig = ServerConfig &
  KnownLeagueConfig &
  UnknownLeagueConfig;

/**
 * A Superstruct schema representing the entire config object. Includes the Core
 * config as a required property, as well as all SDK module configs as optional properties. Finally,
 * allows also for other arbitrary keys, but knows nothing about their types (this
 * will allow anyone to "plug into" the Config API, albeit without automatic
 * runtime validation).
 */
export const leagueConfigSchema = intersection([
  knownLeagueConfigSchema,
  unknownLeagueConfigSchema,
]);

export type LeagueConfig = Infer<typeof leagueConfigSchema>;

export type { ServerConfig };
