import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';

const days = Array(31)
  .fill(null)
  .map((_, d) => {
    const day = (d + 1).toString();
    return {
      key: day,
      label: day,
      value: day,
    };
  });

// TODO replace by the dates sent from the backend
const years = Array(100)
  .fill(null)
  .map((_, y) => {
    const currentYear = new Date().getFullYear();
    const year = (currentYear - y).toString();
    return {
      key: year,
      label: year,
      value: year,
    };
  });

export function useDateHelpers() {
  const { formatDate, formatMessage } = useIntl();

  const months = useMemo(
    () =>
      Array(12)
        .fill(null)
        .map((_, m) => ({
          label: formatDate(new Date(0, m, 10), {
            month: 'long',
          }),
          value: String(m),
        })),
    [formatDate],
  );

  const daySelectOptions = useMemo(
    () => [
      {
        key: '',
        label: formatMessage({ id: 'DAY' }),
        value: '',
      },
      ...days,
    ],
    [formatMessage],
  );

  const monthSelectOptions = useMemo(
    () => [
      {
        key: '',
        label: formatMessage({ id: 'MONTH' }),
        value: '',
      },
      ...months,
    ],
    [formatMessage, months],
  );

  const yearSelectOptions = useMemo(
    () => [
      {
        key: '',
        label: formatMessage({ id: 'YEAR' }),
        value: '',
      },
      ...years,
    ],
    [formatMessage],
  );

  return {
    daySelectOptions,
    monthSelectOptions,
    yearSelectOptions,
  };
}
