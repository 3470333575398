import { boolean, object, optional, string } from 'superstruct';
import { claimsConfigSchema } from './claims';

export const customerSupportConfigSchema = object({
  faqLink: string(),
  enableActivityOverride: optional(boolean()),
  enableChallengesInquiry: optional(boolean()),
  enableWalletInquiry: optional(boolean()),
  enableClaimsInquiry: optional(boolean()),
  claimsInquiry: optional(claimsConfigSchema),
});
