import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  MetricType,
  MetricUnit,
  metricUnits,
} from 'types/activity-details-props.types';
import type { Formatter } from '@leagueplatform/locales';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { isActiveActivity } from '../utils/activity-status-predicates';

interface PriorityActivityProgressProps {
  progress: number;
  goal: number;
  unit: MetricUnit;
  type: MetricType;
}
type DataTypeConfig = {
  metric: string;
  unit?: string;
};
const digits: Record<MetricType, number> = {
  distance: 1,
  steps: 0,
  active_duration: 0,
  mindful_duration: 0,
  floors_climbed: 0,
  energy_burned: 0,
};
const dataTypeConfigMap = new Map<MetricType, DataTypeConfig>([
  [
    'distance',
    {
      metric: 'INSIGHT_CARD_HEADER_DISTANCE',
    },
  ],
  [
    'steps',
    {
      metric: 'INSIGHT_CARD_HEADER_STEPS',
      unit: 'INSIGHT_CARD_UNIT_STEPS',
    },
  ],
  [
    'floors_climbed',
    {
      metric: 'INSIGHT_CARD_HEADER_FLOORS_CLIMBED',
      unit: 'INSIGHT_CARD_UNIT_FLOORS_CLIMBED',
    },
  ],
  [
    'active_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_ACTIVE_DURATION',
    },
  ],
  [
    'energy_burned',
    {
      metric: 'INSIGHT_CARD_HEADER_ENERGY_BURNED',
    },
  ],
  [
    'mindful_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_MINDFUL_DURATION',
    },
  ],
]);

const styledText: Formatter = (msg) => (
  <UtilityText
    emphasis="emphasized"
    css={{
      color: '$onSurfaceTextSuccess',
    }}
  >
    {msg}
  </UtilityText>
);

// temporary mapping until Dynamic Dashboard is implemented.
function useLocaleUnitFormatter() {
  const { formatMessage } = useIntl();

  const formatUnit = (type: MetricType, unit: MetricUnit, value = 0) => {
    const metricConfig = dataTypeConfigMap.get(type);

    if (metricConfig?.unit) {
      return formatMessage({ id: metricConfig.unit }, { count: value });
    }
    if (metricUnits.includes(unit)) {
      return formatMessage({ id: unit.toUpperCase() }, { count: value });
    }
    return `${value} ${unit}`;
  };

  return { formatUnit };
}

function useLocaleMetricValueFormatter() {
  const { formatNumber } = useIntl();

  function formatMetricValue(
    value: number | null,
    type: MetricType,
  ): string | null {
    const maximumFractionDigits = digits[type];

    if (value === null) {
      return null;
    }

    return formatNumber(value, { maximumFractionDigits });
  }

  return { formatMetricValue };
}

export function VerifiableActivityProgress({
  progress,
  goal,
  unit,
  type,
}: PriorityActivityProgressProps) {
  const { formatMessage } = useIntl();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();
  if (!isActiveActivity) {
    return null;
  }
  return (
    <StackLayout spacing="$none">
      <ParagraphText as="span" emphasis="subtle">
        {formatMessage(
          { id: 'X_OF_Y_COMPLETED_WITH_UNIT' },
          {
            progress: formatMetricValue(progress, type),
            goal: formatMetricValue(goal, type),
            unit: formatUnit(type, unit, progress),
            span: styledText,
          },
        )}
      </ParagraphText>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        <StackItem growFactor={1}>
          <ProgressBar
            current={progress}
            total={goal}
            customBarBackgroundColor="interactive.border.disabled"
            customBarFillColor="success.border.default"
          />
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
}
