import {
  type MasonryEngineNodeRenderer,
  type MasonryEngineNode,
  type MasonryEngineNamespacedNodeRendererMap,
  DEFAULT_NAMESPACE,
} from '@leagueplatform/masonry-engine';
import { merge } from 'lodash';
import { MasonryDriverNode } from '@leagueplatform/masonry-widgets';
import { MasonryDriverDefaultNodeRendererMap } from './masonry-default-node-renderers-map';

type ArbitraryMasonryEngineNode = MasonryEngineNode<string, any>;

const masonryDriverNodeRendererMap: MasonryEngineNamespacedNodeRendererMap<ArbitraryMasonryEngineNode> =
  {
    [DEFAULT_NAMESPACE]: MasonryDriverDefaultNodeRendererMap,
  };

const masonryDriverNodeRendererOverrideMap: Partial<
  MasonryEngineNamespacedNodeRendererMap<ArbitraryMasonryEngineNode>
> = {};

export const MasonryDriverNodeRenderersRegistry = {
  /**
   *
   * @method registerRenderer use to register renderers per namespace
   * along with the namespace name to be used for {@link getMasonryDriver}.
   * This function expects namespace as first argument and Node type to
   * Renderer map as second argument.
   */
  registerRenderer<Type extends string>(
    namespace: string,
    type: Type,
    renderer: MasonryEngineNodeRenderer<
      Type extends MasonryDriverNode['type']
        ? Extract<MasonryDriverNode, Record<'type', Type>>
        : MasonryEngineNode<string, any>
    >,
  ) {
    masonryDriverNodeRendererMap[namespace] =
      masonryDriverNodeRendererMap[namespace] || {};
    if (masonryDriverNodeRendererMap[namespace]![type]) {
      throw new Error(
        'Attempting to register a renderer for a type at a namespace where a renderer was already registered for that type!',
      );
    }
    masonryDriverNodeRendererMap[namespace]![type] = renderer;
  },

  /**
   * @method overrideRenderer use by app developers to override single renderer for an App
   * for {@link getMasonryDriver}.
   * To override default renderers for a driver, please use namespace "default"
   * Note: All renderers map are driver specific.
   * */
  overrideRenderer<Type extends string>(
    namespace: string,
    type: string,
    renderer: MasonryEngineNodeRenderer<
      Type extends MasonryDriverNode['type']
        ? Extract<MasonryDriverNode, Record<'type', Type>>
        : MasonryEngineNode<string, any>
    >,
  ) {
    masonryDriverNodeRendererOverrideMap[namespace] =
      masonryDriverNodeRendererOverrideMap[namespace] || {};
    masonryDriverNodeRendererOverrideMap[namespace]![type] = renderer;
  },

  getNodeRenderersMap() {
    const resolvedRendererMap = merge(
      {},
      masonryDriverNodeRendererMap,
      masonryDriverNodeRendererOverrideMap,
    );
    // eslint-disable-next-line no-console
    console.log('[Masonry Driver Renderer Map] : ', resolvedRendererMap);
    return resolvedRendererMap;
  },
};
