import React, { forwardRef, Ref } from 'react';
import {
  Card,
  ParagraphText,
  StackLayout,
  StackItem,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import type { MasonryNotificationBannerRendererProps } from './masonry-notification-banner-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

interface MasonryNotificationContentProps
  extends Omit<MasonryNotificationBannerRendererProps, 'dismissible'> {
  onClick?: MasonryDeeplinkAction;
}

export const MasonryNotificationBannerContentRenderer = forwardRef(
  (
    {
      image,
      imageAltText,
      heading,
      body,
      onClick,
    }: MasonryNotificationContentProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const invokeAction = useMasonryEngineActionEmitter();
    return (
      <>
        {image && (
          <StackItem verticalAlignment="center">
            <Card.Image
              image={image}
              imageAlt={imageAltText}
              css={{
                width: '$twoAndHalf',
                height: '$twoAndHalf',
              }}
            />
          </StackItem>
        )}
        <StackLayout spacing="$quarter">
          <HeadingText size="sm" level="2">
            <ConditionalLinkRenderer
              ref={ref}
              url={onClick?.payload.url}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>
          <ParagraphText
            emphasis="subtle"
            htmlStringOptions={BodyTextHtmlTagOptions}
          >
            {body}
          </ParagraphText>
        </StackLayout>
      </>
    );
  },
);
