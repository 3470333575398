import React from 'react';
import {
  Box,
  HeadingTwo,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { ProgressBar } from '@leagueplatform/web-common-components';

interface ActivityProgressProps {
  progress: number;
  goal: number;
  unit: string;
}

export const ActivityProgress = ({
  progress,
  goal,
  unit,
}: ActivityProgressProps) => (
  <Box marginTop="half" marginBottom="oneAndHalf">
    {/* TODO: figure out how to set up a single localization string for translation with custom styling */}
    {/* @ts-ignore TODO: update text colour tokens when design has solution */}
    <HeadingTwo as="span" color="highlight.icon">
      {progress}
    </HeadingTwo>
    <HeadingTwo as="span" color="onSurface.text.subdued" fontWeight="regular">
      {' '}
      / {goal}
    </HeadingTwo>
    <BodyTwoSecondary as="span"> {unit}</BodyTwoSecondary>

    {/* Progress bar */}
    <Box marginTop="half">
      <ProgressBar
        current={progress}
        total={goal}
        customBarBackgroundColor="interactive.background.disabled"
        customBarFillColor="highlight.icon"
      />
    </Box>
  </Box>
);
