import React, { forwardRef } from 'react';
import { VerticalStackLayout } from './vertical-stack-layout/vertical-stack-layout';
import { HorizontalStackLayout } from './horizontal-stack-layout/horizontal-stack-layout';
import type {
  GDSStackLayoutProps,
  GDSVerticalStackLayoutProps,
  GDSHorizontalStackLayoutProps,
} from './stack-layout.types';
import type { GDSOrientationValues } from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';

export const StackLayout = forwardRef<HTMLDivElement, GDSStackLayoutProps>(
  ({ orientation = 'vertical', ...props }, ref) => {
    const responsiveOrientation =
      useResponsiveProp<GDSOrientationValues>(orientation);

    return responsiveOrientation === 'horizontal' ? (
      <HorizontalStackLayout
        ref={ref}
        {...(props as GDSHorizontalStackLayoutProps)}
      />
    ) : (
      <VerticalStackLayout
        ref={ref}
        {...(props as GDSVerticalStackLayoutProps)}
      />
    );
  },
);

StackLayout.displayName = 'StackLayout';
