import React from 'react';
import { useHistory } from '@leagueplatform/routing';
import PropTypes from 'prop-types';
import { Box, Flex, PrimaryButton } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  AchievementStats,
  RecentAchievements,
  useAchievementsDetail,
  ACHIEVEMENTS_ENABLED,
  RewardsSidebarCard,
} from '@leagueplatform/rewards-achievements';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  Sidebar,
  addSidebarSpacing,
} from '@leagueplatform/web-common-components';
import { ActivityCompletionController } from './activity-completion-controller.component';

export const rewardsSideBarMinWidth = '282px';

const AchievementsSidebarView = () => {
  const { completedAchievementStats, recentAchievements } =
    useAchievementsDetail();

  return (
    <Box
      as={Flex}
      display={{ _: 'none', laptop: 'flex' }}
      css={addSidebarSpacing}
    >
      <AchievementStats completedAchievements={completedAchievementStats} />
      <RecentAchievements achievements={recentAchievements} />
    </Box>
  );
};

export const HealthRewardsSidebar = ({
  children,
  isAddProgramButtonShown,
  showAchievements,
}) => {
  const { formatMessage } = useIntl();
  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  const history = useHistory();

  const screenName = window.location.href.includes('health-progress')
    ? 'health journey progress'
    : 'health journey activities';

  return (
    <Sidebar>
      <Flex
        flexGrow="1"
        marginRight={{ phone: 'oneAndHalf', laptop: 'initial' }}
        css={addSidebarSpacing}
      >
        {isAchievementsEnabled && showAchievements && (
          <AchievementsSidebarView />
        )}
        <RewardsSidebarCard />
      </Flex>
      {isAddProgramButtonShown && (
        <PrimaryButton
          marginTop={{ _: 'half', phone: 'initial', laptop: 'none' }}
          height="three"
          onClick={() => {
            history.push(JOURNEY_ROUTES.programsHome());
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: PRODUCT_AREA.JOURNEY,
              screen_name: screenName,
              detail: 'navigate to health campaign library',
              sub_detail: formatMessage({ id: 'ADD_PROGRAM' }),
            });
          }}
        >
          {formatMessage({ id: 'ADD_PROGRAM' })}
        </PrimaryButton>
      )}
      <ActivityCompletionController />
      {children}
    </Sidebar>
  );
};

HealthRewardsSidebar.propTypes = {
  isAddProgramButtonShown: PropTypes.bool,
  showAchievements: PropTypes.bool,
  children: PropTypes.node,
};

HealthRewardsSidebar.defaultProps = {
  isAddProgramButtonShown: false,
  showAchievements: false,
  children: null,
};
