import { object, optional, boolean, string } from 'superstruct';

export const walletConfigSchema = object({
  showBenefitDetailFeedbackComponent: optional(boolean()),
  showLandingPageHeaderBranding: optional(boolean()),
  showBenefitStatusBannerCreated: optional(boolean()),
  showBenefitStatusBannerPending: optional(boolean()),
  showBenefitStatusBannerExpired: optional(boolean()),
  showLandingPageClaimsEntry: optional(boolean()),
  typeformIds: optional(
    object({
      en: string(),
      es: string(),
      fr: string(),
    }),
  ),
});
