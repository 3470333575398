import { useCallback, useMemo, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import {
  PersonHeightInputValidation,
  HeightUnits,
} from '@leagueplatform/health-journey-api';
import {
  fromCentimetersToFeetAndInches,
  fromCentimetersToInches,
  fromInchesToCentimeters,
  fromInchesToFeetAndInches,
} from '../utils/unit-converters.util';

interface UseToolboxHeightInputsProps {
  componentId: string;
  outputUnit: string;
  selectedUnit: HeightUnits;
  validation: PersonHeightInputValidation;
}

export const useToolboxPersonHeightInputs = ({
  componentId,
  outputUnit,
  selectedUnit,
  validation,
}: UseToolboxHeightInputsProps) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();

  // Element Refs
  const feetInputRef = useRef<HTMLInputElement>(null);
  const inchesInputRef = useRef<HTMLInputElement>(null);
  const centimetersInputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);

  // Helpers
  const getValueAsOutputUnit = useMemo(
    () => ({
      totalInches: () => {
        if (!feetInputRef.current?.value && !inchesInputRef.current?.value)
          return '';

        const nextValue =
          Number(feetInputRef.current?.value) * 12 +
          Number(inchesInputRef.current?.value);

        return outputUnit === HeightUnits.centimeter
          ? fromInchesToCentimeters(nextValue)
          : nextValue;
      },
      totalCentimeters: () => {
        if (!centimetersInputRef.current?.value) return '';

        const nextValue = Number(centimetersInputRef.current?.value);

        return outputUnit === HeightUnits.inches
          ? fromCentimetersToInches(nextValue)
          : nextValue;
      },
    }),
    [outputUnit],
  );

  // State Representation
  /** Convert the output value's unit to the values represented in each input */
  const getUnitValues = useCallback(
    (currentValue: number | string | null) => {
      if (typeof currentValue !== 'number')
        return { feet: '', inches: '', centimeters: '' };

      if (outputUnit === HeightUnits.inches) {
        const { feet, inches } = fromInchesToFeetAndInches(currentValue);
        const centimeters = fromInchesToCentimeters(currentValue);
        return { feet, inches, centimeters };
      }

      const { feet, inches } = fromCentimetersToFeetAndInches(currentValue);
      return { feet, inches, centimeters: currentValue };
    },
    [outputUnit],
  );

  /** Sync all input values from the output unit specified by the backend to the unit represented in each input */
  const syncUnitInputs = useCallback(
    (currentValue: number) => {
      const { feet, centimeters, inches } = getUnitValues(currentValue);

      if (
        selectedUnit === HeightUnits.centimeter &&
        centimetersInputRef.current
      ) {
        centimetersInputRef.current.value = centimeters.toString();
      }

      if (
        selectedUnit === HeightUnits.inches &&
        inchesInputRef.current &&
        feetInputRef.current
      ) {
        feetInputRef.current.value = feet.toString();
        inchesInputRef.current.value = inches.toString();
      }
    },
    [getUnitValues, selectedUnit],
  );

  // Validation
  const { customWarning, maximumValue, minimumValue } = validation;

  const getMinMaxValidationMessage = useCallback(
    (messageKey, validationValue) => {
      if (customWarning) return customWarning;

      const { feet, inches, centimeters } = getUnitValues(validationValue);
      if (selectedUnit === HeightUnits.inches) {
        return formatMessage(messageKey, {
          value: `${feet}’ ${inches}”`,
          unit: '',
        });
      }
      return formatMessage(messageKey, {
        value: centimeters,
        unit: HeightUnits.centimeter,
      });
    },
    [customWarning, formatMessage, getUnitValues, selectedUnit],
  );

  const validateMaxValue = useCallback(
    (currentValue) =>
      maximumValue && currentValue > maximumValue
        ? getMinMaxValidationMessage(
            { id: 'TOOLBOX_VALIDATION_HEIGHT_MAXIMUM' },
            maximumValue,
          )
        : undefined,
    [maximumValue, getMinMaxValidationMessage],
  );

  const validateMinValue = useCallback(
    (currentValue) =>
      minimumValue && minimumValue > currentValue
        ? getMinMaxValidationMessage(
            { id: 'TOOLBOX_VALIDATION_HEIGHT_MINIMUM' },
            minimumValue,
          )
        : undefined,
    [getMinMaxValidationMessage, minimumValue],
  );

  // Change Handlers
  const setValueAndValidate = useCallback(
    (newValue: string | number | null) =>
      setValue(componentId, newValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }),
    [componentId, setValue],
  );

  /** Update the field's source-of-truth value from the selected unit to the output unit requested by the backend */
  const onInputChange = useCallback(() => {
    const nextValue =
      selectedUnit === HeightUnits.inches
        ? getValueAsOutputUnit.totalInches()
        : getValueAsOutputUnit.totalCentimeters();

    setValueAndValidate(nextValue);
  }, [getValueAsOutputUnit, selectedUnit, setValueAndValidate]);

  const refs = { feetInputRef, inchesInputRef, centimetersInputRef, fieldRef };
  const validateMethods = { validateMinValue, validateMaxValue };
  const valueHandlers = { onInputChange, getUnitValues, syncUnitInputs };
  return { valueHandlers, validateMethods, refs };
};
