import React, { FC, MouseEvent, useMemo } from 'react';
import { HtmlToReact, HTMLTagOptions } from '@leagueplatform/web-common';
import { RichTextAttributes } from '@leagueplatform/health-journey-api';
import { wrapOrphanTextNode } from 'utils/wrap-orphan-text-node.util';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
import { Box } from '@leagueplatform/genesis-core';
import { activityToolboxRichTextOptions } from '../../common/activity-toolbox-rich-text-options';

export namespace ToolboxRichText {
  type ClickHandler = (event: MouseEvent<HTMLAnchorElement>) => void;

  export interface Actions {
    onLinkOut?: ClickHandler;
  }

  export interface Props extends ToolboxComponentProps, RichTextAttributes {
    options?: HTMLTagOptions;
    as?: React.ElementType<any>;
  }
}

export const ActivityToolboxRichText: FC<ToolboxRichText.Props> = ({
  as,
  componentId,
  css: cssOverrides = {},
  options = {},
  richText,
}) => {
  const formattedRichText = useMemo(
    () => wrapOrphanTextNode(richText.trim()),
    [richText],
  );

  const consolidatedOptions = {
    ...activityToolboxRichTextOptions,
    ...options,
  };

  return (
    <Box
      as={as}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$one',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-wrap',
        ...cssOverrides,
      }}
      data-testid={componentId}
    >
      <HtmlToReact
        htmlString={formattedRichText}
        options={consolidatedOptions}
      />
    </Box>
  );
};
