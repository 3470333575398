import React from 'react';
import { BadgeList } from 'common/badge-list/badge-list.component';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  UtilityText,
  Box,
  GDSColor,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { BadgeItem } from 'common/badge-list/badge-item.type';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export interface MasonryLargeCardContentProps {
  title: string;
  description: string;
  overline?: string;
  badges?: BadgeItem[];
  linkRefState?: React.RefObject<HTMLAnchorElement>;
  badgeBackgroundColor?: GDSColor;
  onClick?: MasonryDeeplinkAction;
}

export const MasonryLargeCardContentRenderer = ({
  overline,
  linkRefState,
  title,
  description,
  badges,
  badgeBackgroundColor,
  onClick,
}: MasonryLargeCardContentProps) => {
  const overlineId = `lvc-${title.replace(/\s+/g, '-')}`;
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout spacing="$half" orientation="vertical">
      {overline && (
        <UtilityText id={overlineId} size="eyebrow" as="p">
          {overline}
        </UtilityText>
      )}
      <StackLayout spacing="$quarter" orientation="vertical">
        <HeadingText level="3" size="sm" aria-describedby={overlineId}>
          <ConditionalLinkRenderer
            ref={linkRefState}
            url={onClick?.payload.url}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            {title}
          </ConditionalLinkRenderer>
        </HeadingText>

        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {description}
        </ParagraphText>
      </StackLayout>
      {badges && (
        <Box css={{ marginTop: '$threeQuarters' }}>
          <BadgeList badges={badges} backgroundColor={badgeBackgroundColor} />
        </Box>
      )}
    </StackLayout>
  );
};
