import React from 'react';
import {
  ActivityCardHeadline,
  CONFIGURABLE_ACTIVITY_PRIORITY,
} from '@leagueplatform/health-journey-common';

type PriorityHeadlineProps = {
  tagline?: string;
};
const MediumPriorityHeadline = ({ tagline }: PriorityHeadlineProps) => (
  <ActivityCardHeadline
    color="$onSurfaceTextPrimary"
    backgroundColor="$decorativeBrandPrimaryPastel"
  >
    {tagline}
  </ActivityCardHeadline>
);

const HighPriorityHeadline = ({ tagline }: PriorityHeadlineProps) => (
  <ActivityCardHeadline
    color="$onSurfaceTextReversed"
    backgroundColor="$decorativeBrandPrimaryDark"
  >
    {tagline}
  </ActivityCardHeadline>
);

export const priorityHeadlineMap: Record<
  CONFIGURABLE_ACTIVITY_PRIORITY,
  (tagline?: string) => React.ReactElement | null
> = {
  [CONFIGURABLE_ACTIVITY_PRIORITY.LOW]: () => null,
  [CONFIGURABLE_ACTIVITY_PRIORITY.MEDIUM]: (tagline) => (
    <MediumPriorityHeadline tagline={tagline} />
  ),
  [CONFIGURABLE_ACTIVITY_PRIORITY.HIGH]: (tagline) => (
    <HighPriorityHeadline tagline={tagline} />
  ),
};
