import React, { useState, useMemo, FC } from 'react';
import {
  HeadingText,
  TextInput,
  Box,
  StackLayout,
  Button,
  styled,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { usePageViewAnalytics, PRODUCT_AREA } from '@leagueplatform/analytics';
import { HealthActivityModal } from '@leagueplatform/health-journey-common';
import {
  ControlledFormField,
  FormProvider,
  RegisterOptions,
  useForm,
} from '@leagueplatform/web-common';
import { normalizeNumberFromString } from 'utils/normalize-number-from-string.util';

const StyledTextInput = styled(TextInput, {
  border: 'none',
  borderBottomWidth: '$thick',
  borderBottomStyle: 'solid',
  borderBottomColor: '$onSurfaceBorderSubdued',
  borderRadius: 'initial',
  transition: 'width 0.1s ease-out',
  fontSize: '$headingOne',
  lineHeight: '$headingOne',
  textAlign: 'center',
  width: '100%',
  maxWidth: '160px',
  ':focus': {
    borderBottomWidth: 'initial',
  },
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

export interface EditProgressCountModalProps {
  heading: string;
  subtitle: string;
  altText?: string;
  initialValue: number;
  name: string;
  onSave?: (value: number) => void | Boolean | Promise<boolean>;
  onClose: () => void;
  inputOptions: RegisterOptions;
}

const ariaLabelledById = 'edit-count-modal-label';

export const EditProgressCountModal: FC<EditProgressCountModalProps> = ({
  altText,
  heading,
  subtitle,
  initialValue,
  name,
  onClose,
  onSave,
  inputOptions = {},
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const formMethods = useForm({ mode: 'onChange' });
  const [workingValue, setWorkingValue] = useState(initialValue);

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: 'health journey activities goal progress',
  });

  const options = useMemo(
    () => ({
      ...inputOptions,
      setValueAs(value: string) {
        const normalizedValue = normalizeNumberFromString({
          value,
          maxChars: 6,
        });
        setWorkingValue(normalizedValue);
        return normalizedValue;
      },
    }),
    [inputOptions],
  );

  return (
    <HealthActivityModal onClose={onClose} aria-labelledby={ariaLabelledById}>
      <VisuallyHidden>
        <HeadingText level="display" size="xl" id={ariaLabelledById}>
          {altText}
        </HeadingText>
      </VisuallyHidden>
      <FormProvider
        includesFormElement
        formMethods={formMethods}
        onSubmit={(data) => {
          const value = data[name];
          onSave?.(value);
          onClose();
        }}
      >
        <StackLayout
          spacing="$two"
          horizontalAlignment="center"
          css={{ maxWidth: '100%' }}
        >
          {/* Headings */}
          <StackLayout
            orientation="vertical"
            spacing="$half"
            horizontalAlignment="start"
            css={{ width: '100%' }}
          >
            <HeadingText
              level="2"
              size="xl"
              css={{ textTransform: 'capitalize' }}
            >
              {heading}
            </HeadingText>
            <UtilityText size="eyebrow" emphasis="emphasized">
              {subtitle}
            </UtilityText>
          </StackLayout>

          {/* Input */}

          <Box css={{ maxWidth: '100%' }}>
            <ControlledFormField
              inputOptions={options}
              id={name}
              name={name}
              label={altText}
              hideLabel
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '$quarter',
              }}
            >
              <StyledTextInput type="text" value={formatNumber(workingValue)} />
            </ControlledFormField>
          </Box>

          {/* Footer */}
          <Box css={{ textAlign: 'right', width: '100%' }}>
            <Button type="submit">{formatMessage({ id: 'SAVE' })}</Button>
          </Box>
        </StackLayout>
      </FormProvider>
    </HealthActivityModal>
  );
};
