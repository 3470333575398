import { Units } from '@formatjs/intl-relativetimeformat';

const ranges: Record<Exclude<Units, 'quarters'>, number> = {
  years: 3600 * 24 * 365,
  months: 3600 * 24 * 30,
  weeks: 3600 * 24 * 7,
  days: 3600 * 24,
  hours: 3600,
  minutes: 60,
  seconds: 1,
};

export function timeAgoFromNow(date: string): {
  time: number;
  suffix: Units;
} {
  const secondsElapsed = (new Date(date).getTime() - Date.now()) / 1000;

  const range = Object.entries(ranges).find(
    ([, t]) => t < Math.abs(secondsElapsed),
  );

  if (range) {
    const time = secondsElapsed / range[1];
    return { time: Math.round(time), suffix: range[0] as Units };
  }

  return { time: 0, suffix: 'seconds' };
}
