import React from 'react';
import { Link } from '@leagueplatform/routing';
import {
  HeadingThree,
  Box,
  SubtitleOne,
  Flex,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { arrayOf, shape, string, bool, number } from 'prop-types';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { normalizeEpochDate } from '../../utils/user-health-activities/user-health-activities.utils';
import { activityShortPropType } from '../../common/health-activities.props';
import { HealthActivityCard } from './health-activity-card.view';

export const categoryLabelToTestId = (categoryLabel) =>
  `activity-list-${categoryLabel.replaceAll(' ', '-').toLowerCase()}`;

const ActivityCardGroup = genesisStyled(Box)`
    &:last-of-type {
      margin-bottom: 0;
    }
  `;

export const HealthActivityCardList = React.memo(
  ({
    activityArray,
    activityCategoryLabel,
    hasViewAll,
    activeDate,
    suggestedActivityGroupName, // for segment analytics
  }) => {
    const { formatMessage } = useIntl();

    if (!activityArray?.length) return null;

    return (
      <>
        <Flex justifyContent="space-between" marginBottom="two">
          {activityCategoryLabel && (
            <HeadingThree>{activityCategoryLabel}</HeadingThree>
          )}
          {hasViewAll && (
            <Link
              to={JOURNEY_ROUTES.journeyHome}
              aria-label={formatMessage({ id: 'GO_TO_HEALTH_JOURNEY' })}
            >
              <SubtitleOne as="p" color="interactive.action.primary">
                {formatMessage({ id: 'VIEW_ALL' })}
              </SubtitleOne>
            </Link>
          )}
        </Flex>
        <ActivityCardGroup
          marginBottom="four"
          data-testid={categoryLabelToTestId(activityCategoryLabel)}
        >
          {activityArray.map((activity) => (
            <HealthActivityCard
              key={activity?.id}
              activeDate={activeDate}
              suggestedActivityGroupName={suggestedActivityGroupName}
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...activity}
            />
          ))}
        </ActivityCardGroup>
      </>
    );
  },
  (prevProps, nextProps) => {
    const isActiveDateSame = prevProps.activeDate === nextProps.activeDate;
    const isSameActivityLength =
      prevProps?.activityArray?.length === nextProps?.activityArray?.length;
    return isActiveDateSame && isSameActivityLength;
  },
);

HealthActivityCardList.propTypes = {
  activityArray: arrayOf(shape(activityShortPropType)),
  activityCategoryLabel: string,
  hasViewAll: bool,
  activeDate: number,
  suggestedActivityGroupName: string, // for segment analytics
};

HealthActivityCardList.defaultProps = {
  activityArray: [],
  activityCategoryLabel: '',
  hasViewAll: false,
  activeDate: normalizeEpochDate(Date.now()),
  suggestedActivityGroupName: '', // for segment analytics
};
