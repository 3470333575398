import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useLocation, useHistory } from '@leagueplatform/routing';
import { Sidebar } from '@leagueplatform/web-common-components';
import {
  AddAppsDevicesLink,
  HealthProfilePageWrapper,
  MoreOptionsCard,
} from '@leagueplatform/health-profile-common';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import {
  ManageUnitsLink,
  AllInsights,
  useIsDashboardEnabled,
  ErrorPanel,
} from '@leagueplatform/dashboard';
import {
  RewardsSidebarCard,
  useAchievementsPage,
  UserAchievements,
} from '@leagueplatform/rewards-achievements';
import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { AccountBanner } from 'components/account-banner/account-banner';
import { Tabs } from '@leagueplatform/genesis-core';
import {
  sendAnalyticsPageView,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics';
import {
  LEAGUE_MODULE_NAMES,
  getModulePath,
} from '@leagueplatform/league-routes';
import { AboutMeOverview } from './sections/about-me-overview';
import { useAboutMeConfig } from '../../about-me.config';

const StyledTab = genesisStyled(Tabs.Tab)(
  css({
    '&[data-selected]': {
      '& span': {
        color: 'interactive.action.primary',
        fontWeight: 'bold',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    textAlign: 'center',
    width: '120px',
    paddingX: 'four',
  }),
);

const tabs = ['overview', 'metrics', 'achievements'];

function NavigationTabs() {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = React.useState<string>('overview');
  const {
    isFeatureFlagOn: isAchievementsEnabled,
    refetch,
    userAchievementsIsRefetching,
  } = useAchievementsPage();
  const { isDashboardEnabled } = useIsDashboardEnabled();
  const tabsVisible = isDashboardEnabled || isAchievementsEnabled;
  const history = useHistory();

  const view = new URLSearchParams(document.location.search).get('view');

  React.useEffect(() => {
    if (view && tabs.includes(view)) {
      setCurrentTab(view);
    } else {
      setCurrentTab('overview');
    }
  }, [view]);

  React.useEffect(() => {
    sendAnalyticsPageView({
      product_area: PRODUCT_AREA.ABOUT_ME,
      screen_name: `${PRODUCT_AREA.ABOUT_ME} ${currentTab}`,
    });
  }, [currentTab]);

  const handleOnChange = (tabName: string) => {
    if (tabs.includes(tabName)) {
      setCurrentTab(tabName);
      history.push({
        search: `view=${tabName}`,
      });
      trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
        product_area: PRODUCT_AREA.ABOUT_ME,
        screen_name: SCREEN_NAME.ABOUT_ME,
        detail: tabName,
      });
    }
  };

  if (!tabsVisible) {
    return (
      <Box paddingTop="two" paddingBottom="two">
        <AboutMeOverview />
      </Box>
    );
  }

  return (
    <Box paddingTop="two" paddingBottom="two" width="100%">
      <Tabs.Root
        defaultValue="overview"
        onValueChange={handleOnChange}
        value={currentTab || 'overview'}
      >
        <Tabs.TabList tabDistribution="hugContents" divider>
          {tabs.map((tab, index) => {
            if (!isDashboardEnabled && tabs[index] === 'metrics') {
              return null;
            }
            if (!isAchievementsEnabled && tabs[index] === 'achievements') {
              return null;
            }
            return (
              <StyledTab value={tab} name={tab}>
                {formatMessage({ id: tab.toUpperCase() })}
              </StyledTab>
            );
          })}
        </Tabs.TabList>
        <Tabs.TabPanel value="overview">
          <AboutMeOverview />
        </Tabs.TabPanel>
        {isDashboardEnabled && (
          <Tabs.TabPanel value="metrics">
            <AllInsights />
          </Tabs.TabPanel>
        )}
        {isAchievementsEnabled && (
          <Tabs.TabPanel value="achievements">
            <UserAchievements
              errorComponent={
                <ErrorPanel
                  isRefetching={userAchievementsIsRefetching}
                  onRetry={() => refetch()}
                />
              }
            />
          </Tabs.TabPanel>
        )}
      </Tabs.Root>
    </Box>
  );
}

export function AboutMeLandingPage() {
  const { formatMessage } = useIntl();

  // `showAppsAndDevicesModal` will be `true` when the insight empty state card is presented and the CTA clicked.
  const { state } = useLocation<{ showAppsAndDevicesModal?: boolean }>();
  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    React.useState(state?.showAppsAndDevicesModal ?? false);

  const { showAccountBanner, showOptionsMenu, showLandingPageBackButton } =
    useAboutMeConfig();

  const backButtonRoute = showLandingPageBackButton
    ? getModulePath(LEAGUE_MODULE_NAMES.healthJourney)
    : undefined;

  return (
    <>
      <HealthProfilePageWrapper
        hideBackButton={!showLandingPageBackButton}
        backButtonRoute={backButtonRoute}
        title={formatMessage({
          id: 'ABOUT_ME',
        })}
        sidebar={
          <Sidebar>
            <RewardsSidebarCard />
            {showOptionsMenu ? (
              <MoreOptionsCard>
                <AddAppsDevicesLink
                  onClick={() =>
                    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                      product_area: PRODUCT_AREA.ABOUT_ME,
                      screen_name: SCREEN_NAME.MORE_OPTIONS_MENU,
                      detail: 'view apps and devices',
                    })
                  }
                />
                <ManageUnitsLink
                  onClick={() =>
                    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                      product_area: PRODUCT_AREA.ABOUT_ME,
                      screen_name: SCREEN_NAME.MORE_OPTIONS_MENU,
                      detail: 'change units of measurement',
                    })
                  }
                  modalScreenLoadedEvent={() =>
                    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
                      product_area: PRODUCT_AREA.ABOUT_ME,
                      screen_name: SCREEN_NAME.CHANGE_UNITS_OF_MEASUREMENT,
                    })
                  }
                />
              </MoreOptionsCard>
            ) : null}
          </Sidebar>
        }
      >
        {showAccountBanner ? <AccountBanner /> : null}
        <NavigationTabs />
      </HealthProfilePageWrapper>
      {displayUnavailableOnWebModal ? (
        <UnavailableDeviceConnectModal
          onClose={() => setDisplayUnavailableOnWebModal(false)}
        />
      ) : null}
    </>
  );
}
