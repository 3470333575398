import React from 'react';
import PropTypes from 'prop-types';

// Modules
import { Flex } from '@leagueplatform/genesis-commons';
import {
  PillTabs,
  PillTab,
  PillTabList,
} from '@leagueplatform/web-common-components';
import { Tabs } from '@leagueplatform/genesis-core';

// Hooks
import {
  useLeaveProgramModal,
  LeaveProgramPulseCheckModal,
} from '@leagueplatform/health-programs';
import { useHealthPills } from 'hooks/use-health-pills.hook';

// Utils
import { normalizeEpochDate } from 'utils/user-health-activities/user-health-activities.utils';

// Components
import { JourneyPageContent } from './components/journey-page-content.component';
import { JourneyPageLayout, CampaignProgressContent } from '../../components';

export const HealthJourneyPage = ({ todaysDate }) => {
  const { activeIndex, handleIndexChange, tabs } = useHealthPills();

  const {
    showLeaveProgramPulseCheck,
    setShowLeaveProgramPulseCheck,
    leaveProgramPulseCheckState,
  } = useLeaveProgramModal();

  const tabContent = {
    activities: <JourneyPageContent todaysDate={todaysDate} />,
    progress: <CampaignProgressContent />,
  };

  return (
    <>
      <JourneyPageLayout>
        <PillTabs
          defaultValue={tabs[activeIndex].label}
          onValueChange={(value) =>
            handleIndexChange(tabs.findIndex((tab) => tab.label === value))
          }
        >
          <Flex
            marginBottom="twoAndHalf"
            justifyContent={{ _: 'center', phone: 'initial' }}
          >
            <PillTabList>
              {tabs.map((tab) => (
                <PillTab key={tab.index} value={tab.label}>
                  {tab.label}
                </PillTab>
              ))}
            </PillTabList>
          </Flex>
          {tabs.map((tab) => (
            <Tabs.TabPanel key={tab.index} value={tab.label}>
              {tabContent[tab.page]}
            </Tabs.TabPanel>
          ))}
        </PillTabs>
      </JourneyPageLayout>
      {showLeaveProgramPulseCheck && (
        <LeaveProgramPulseCheckModal
          onClose={() => setShowLeaveProgramPulseCheck(false)}
          programId={leaveProgramPulseCheckState?.programId}
        />
      )}
    </>
  );
};

HealthJourneyPage.propTypes = {
  todaysDate: PropTypes.number,
};

HealthJourneyPage.defaultProps = {
  todaysDate: normalizeEpochDate(Date.now()),
};

export default HealthJourneyPage;
