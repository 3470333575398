import { useCallback, useMemo } from 'react';
import { useParams, useLocation } from '@leagueplatform/routing';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from '@leagueplatform/locales';
import {
  JOURNEY_ROUTES,
  useHelpCenterLinks,
} from '@leagueplatform/health-journey-common';
import {
  getParameterByName,
  getContentfulImageUrl,
} from '@leagueplatform/web-common';
import { getContentUrl } from '@leagueplatform/league-content-api';

// data hooks
import { useGetHealthProgram } from './use-get-health-program.hook';
import { useStartHealthProgram } from './use-start-health-program.hook';
import { useQuitHealthProgram } from './use-quit-health-program.hook';
import { useUserHasSufficientWearablePermissions } from './use-user-has-sufficient-wearable-permissions.hook';

const mapParamToPath = {
  home: JOURNEY_ROUTES.memberHome,
  progress: JOURNEY_ROUTES.progressHome,
};

// Setting up a defensive approach for determining if a program is a user device connectable program.
// We can confirm the program is a user device connectable program if the program has:
//    1. a contentConfig
//    2. the contentConfig has a dataFields array
//    3. the dataFields array has at least 1 element
// Otherwise, it is not.
// See note [here](https://github.com/EverlongProject/league-web/pull/6856#issue-1172850478) for more details
const getIsUserDeviceConnectableProgram = (contentConfig) => {
  if (!contentConfig) return null;
  return (
    Array.isArray(contentConfig?.dataFields) &&
    contentConfig.dataFields.length > 0
  );
};

// If the query param 'b' is set and the back button in the web app is clicked,
// it should go back to the path defined in the mapParamToPath object.
// Otherwise, it should return the user to the health programs page.
const getGoBackPath = (queryString) => {
  const route = !isEmpty(queryString) && getParameterByName('b', queryString);
  return Object.prototype.hasOwnProperty.call(mapParamToPath, route)
    ? mapParamToPath[route]
    : JOURNEY_ROUTES.programsHome;
};

const getSyncableDevicesString = (contentConfig, formatMessage) => {
  if (!getIsUserDeviceConnectableProgram(contentConfig)) return null;
  return contentConfig?.dataFields
    .map((field) => formatMessage({ id: field.toUpperCase() }))
    .join(` ${formatMessage({ id: 'AND' })} `);
};

const getProgressPercentages = ({
  completed_activity_progress_percentage: completed,
  missed_activity_progress_percentage: missed,
}) => ({ completed, missed });

const getActivityStatusCounts = ({
  total_activities_count: total = 0,
  activity_status_counts: counts = {},
}) => ({ total, ...counts });

export const useHealthProgramsProgramDetails = () => {
  const { formatMessage } = useIntl();
  const { programId } = useParams();
  const location = useLocation();

  const {
    data: programData,
    isError,
    isLoading: isLoadingGetProgram,
  } = useGetHealthProgram(programId);
  const program = programData?.program || {};
  const {
    campaign_content_config: contentConfig,
    user_program_id: userProgramId,
  } = program;

  const isUserDeviceConnectableProgram =
    getIsUserDeviceConnectableProgram(contentConfig);

  const { mutate: requestLeaveHealthProgram } =
    useQuitHealthProgram(userProgramId);

  const { mutate: requestStartHealthProgram, data: startProgramData } =
    useStartHealthProgram(programId, isUserDeviceConnectableProgram);

  const {
    userHasSufficientWearablePermissions,
    isLoading: isLoadingWearablePermissions,
  } = useUserHasSufficientWearablePermissions(contentConfig);

  const constructContentForUsersState = useCallback(() => {
    const contentfulImageAssetUrl =
      startProgramData?.content_for_users_state?.contentful_image_asset?.fields
        ?.file?.url;
    const imageIdUrl = startProgramData?.content_for_users_state?.image_id;

    return {
      ...startProgramData?.content_for_users_state,
      imageUrl: contentfulImageAssetUrl
        ? getContentfulImageUrl(contentfulImageAssetUrl)
        : getContentUrl(imageIdUrl),
    };
  }, [startProgramData]);

  const startProgramMaxEnrolmentModalContent = useMemo(
    () =>
      startProgramData?.content_for_users_state
        ? constructContentForUsersState()
        : null,
    [constructContentForUsersState, startProgramData?.content_for_users_state],
  );

  const { whatAreConnectedHealthProgramsLink } = useHelpCenterLinks();

  return {
    achievementImage: program?.achievement_image_url,
    activityStatusCounts: getActivityStatusCounts(program),
    availablePoints: program?.available_points,
    description: program?.long_description,
    goals: program?.goals,
    goBackPath: getGoBackPath(location?.search),
    hasError: isError,
    image: program?.image_url,
    isUserDeviceConnectableProgram,
    userHasSufficientWearablePermissions,
    programDataPoints: contentConfig?.dataFields,
    name: program?.name,
    programId,
    progressPercentages: getProgressPercentages(program),
    ready: !isLoadingGetProgram && !isLoadingWearablePermissions,
    requestLeaveHealthProgram,
    requestStartHealthProgram,
    startProgramMaxEnrolmentModalContent,
    status: program?.status,
    syncableDevicesString: getSyncableDevicesString(
      contentConfig,
      formatMessage,
    ),
    whatAreConnectedHealthProgramsLink,
  };
};
