import * as React from 'react';
import PropTypes from 'prop-types';
import {
  BodyOneSecondary,
  Box,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';

export const EmptyStateCard = ({
  bodyText,
  children,
  graphic,
  headingText,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Box textAlign="center" maxWidth="60rem" marginX="auto" {...props}>
    {graphic && (
      <Box maxWidth="18.5rem" marginX="auto" marginBottom="twoAndHalf">
        {graphic}
      </Box>
    )}
    {headingText && (
      <HeadingTwo fontSize={{ _: 'heading3', phone: 'heading2' }}>
        {headingText}
      </HeadingTwo>
    )}
    {bodyText && (
      <BodyOneSecondary marginTop="one">{bodyText}</BodyOneSecondary>
    )}
    {children && <Box marginTop="oneAndHalf">{children}</Box>}
  </Box>
);

EmptyStateCard.propTypes = {
  /** Text content to appear under the heading, in the body text */
  bodyText: PropTypes.string,
  /** Child text, HTML, or components passed will render below the image, heading, and body text */
  children: PropTypes.node,
  /** Pass an <Image /> component to be rendered above the heading */
  graphic: PropTypes.element,
  /** Text content to appear within the heading element */
  headingText: PropTypes.string,
};

EmptyStateCard.defaultProps = {
  bodyText: '',
  children: null,
  graphic: null,
  headingText: '',
};
