import React from 'react';
import {
  css,
  genesisStyled,
  StyleProps,
} from '@leagueplatform/genesis-commons';
import { GenesisRouterLink } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { useConfigProperty } from '@leagueplatform/core';

const Card = genesisStyled(GenesisRouterLink)<StyleProps>(
  ({ backgroundColor, theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: '260px',
      height: '132px',
      paddingX: 'oneAndHalf',
      fontSize: 'heading3',
      backgroundColor,
      borderStyle: 'solid',
      borderColor: 'onSurface.border.subdued',
      borderWidth: 'thin',
      borderRadius: 'large',
      fontFamily: theme.fonts.headings,
      '&::before': {
        borderRadius: 'large',
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'interactive.background.hovered',
        borderColor: 'interactive.border.hovered',
      },
      '&:active': {
        backgroundColor: 'interactive.background.pressed',
      },
      [`@media screen and (min-width: ${theme.breakpoints.phone})`]: {
        paddingX: 'twoAndHalf',
      },
    }),
);

export interface CarouselCardProps {
  title: string;
  url: string;
}

export const CarouselCard = ({ url, title }: CarouselCardProps) => {
  const { formatMessage } = useIntl();

  // TODO Remove once color tokens are fixed for all tenants
  const backgroundColor = useConfigProperty(
    '__care__adjustForCardColorThemeDiscrepancy',
  )
    ? 'surface.card.primary'
    : 'surface.card.secondary';

  return (
    <Card
      to={url}
      onClick={() => {
        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.GET_CARE,
          sub_product_area: SCREEN_NAMES.CARE_COLLECTIONS_MENU,
          screen_name: formatMessage({
            id: 'CARE_COLLECTIONS',
          }).toLowerCase(),
          detail: SCREEN_NAMES.CARE_COLLECTION,
          sub_detail: title.toLowerCase(),
        });
      }}
      backgroundColor={backgroundColor}
    >
      {title}
    </Card>
  );
};
