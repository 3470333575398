/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  HeadingTwo,
  BodyOneSecondary,
  Box,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { SuggestedActivitiesCard } from './suggested-activities-card.component';
import { useActivitiesTimelineAnalytics } from '../hooks/use-activities-timeline-analytics/use-activities-timeline-analytics.hook';

const ActivityContainer = genesisStyled(Box)(
  ({ theme: { space, breakpoints } }) => `
  display: grid;
  grid-column-gap: ${space.two}px;
  grid-row-gap: ${space.oneAndHalf}px;
  grid-template-columns: repeat(2, minmax(0, 42rem));
  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-column-gap: ${space.oneAndHalf}px;
  }
  @media screen and (max-width: ${breakpoints.phone}) {
    grid-column-gap: ${space.one}px;
  }
`,
);

export const SuggestedActivities = ({
  description,
  suggestedActivities,
  title,
  activeDate,
  ...props
}) => {
  const { selectSuggestedActivityGroup } =
    useActivitiesTimelineAnalytics(activeDate);
  const orderedActivities =
    suggestedActivities?.sort((a, b) =>
      a?.activity_count > b?.activity_count ? -1 : 1,
    ) || [];

  return (
    <Box
      borderTopColor="onSurface.border.subdued"
      borderTopWidth="thick"
      paddingTop="twoAndHalf"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      {title && <HeadingTwo>{title}</HeadingTwo>}
      {description && (
        <BodyOneSecondary marginTop="half">{description}</BodyOneSecondary>
      )}
      {orderedActivities.length > 0 && (
        <ActivityContainer as="ul" marginY="two">
          {orderedActivities?.map(
            ({ name, icon_url, suggested_activity_group_id }, index) => (
              <li key={suggested_activity_group_id}>
                <SuggestedActivitiesCard
                  name={name}
                  image={icon_url}
                  path={JOURNEY_ROUTES.getSuggestedActivity(
                    suggested_activity_group_id,
                  )}
                  onClick={() => selectSuggestedActivityGroup(index + 1, name)}
                />
              </li>
            ),
          )}
        </ActivityContainer>
      )}
    </Box>
  );
};

SuggestedActivities.propTypes = {
  description: PropTypes.string,
  suggestedActivities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon_url: PropTypes.string,
      suggested_activity_group_id: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  activeDate: PropTypes.number.isRequired,
};

SuggestedActivities.defaultProps = {
  description: '',
  suggestedActivities: [],
  title: '',
};
