import { ErrorContext } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { ApiParams } from '../types/api-params';

const GET_WIDGET_CONTAINER: string = 'get_widget_container';

export const getWidgetContainer = async (
  appId: string,
  version: number,
  apiParams?: ApiParams,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_WIDGET_CONTAINER,
      info: {
        type: appId,
        version,
        ...(apiParams && { api_params: apiParams }),
      },
    },
    {
      errorContext,
    },
  );
