import * as React from 'react';
import {
  InsightHighlightCard,
  InsightCardHorizontalBarGraph,
} from '@leagueplatform/dashboard';
import { MetricType, MetricUnit } from '@leagueplatform/dashboard-api';
import { INSIGHT_TYPE } from 'constants/analytics';
import { trackWidgetClickEvent } from '../widgets/utils';

type HorizontalBarGraphInsightResponse = {
  body: {
    description: string;
    items: { timeframelabel: string; value: number }[];
    metric: MetricType;
    title: string;
    unit: MetricUnit;
  };
  callout: {
    description: string;
    image: string;
    image_alt_text: string;
  };
  cta: string;
  heading: string;
};
export function HorizontalBarGraphNodeRenderer({
  body,
  callout,
  cta,
  heading,
}: HorizontalBarGraphInsightResponse) {
  return (
    <InsightHighlightCard
      heading={heading}
      seeMoreLink={cta}
      callout={callout}
      onClick={() => {
        trackWidgetClickEvent(body.metric, INSIGHT_TYPE.HORIZONTAL_BAR_GRAPH);
      }}
    >
      <InsightCardHorizontalBarGraph
        title={body.title}
        description={body.description}
        type={body.metric}
        unit={body.unit}
        items={body.items}
      />
    </InsightHighlightCard>
  );
}
