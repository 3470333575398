import React, { forwardRef } from 'react';
import {
  HeadingText,
  StackLayout,
  UtilityText,
  TextAction,
} from '@leagueplatform/genesis-core';
import {
  HealthActivityDeserializedData,
  NestedCampaign,
  USER_HEALTH_CAMPAIGN_STATUS,
} from '@leagueplatform/health-journey-api';
import { useIntl } from '@leagueplatform/locales';
import { IconText } from '@leagueplatform/web-common-components';
import { ActivityDate } from 'components/activity-date/activity.date.component';
import { GetActivityGroup } from 'utils/get-activity-group';

export interface ActivityCardContentProps {
  activity: HealthActivityDeserializedData;
  showDate?: boolean;
  relatedCampaign: NestedCampaign;
  clickAction: () => void;
}

export const ActivityCardContent = forwardRef(
  (
    {
      activity,
      showDate,
      relatedCampaign,
      clickAction,
    }: ActivityCardContentProps,
    ref: React.ForwardedRef<any>,
  ) => {
    const { formatMessage } = useIntl();
    const { linkedEntity } = activity;

    const { isCompleted, isMissed, isActive } = GetActivityGroup(
      activity.status,
    );

    const hidePoints =
      relatedCampaign.status === USER_HEALTH_CAMPAIGN_STATUS.COMPLETED &&
      isActive;

    const showRequired = activity.required && !isCompleted;

    return (
      <StackLayout
        spacing="$half"
        orientation="vertical"
        verticalAlignment="top"
      >
        <HeadingText level="6" size="xs">
          <TextAction
            onClick={clickAction}
            ref={ref}
            as="button"
            css={{
              all: 'inherit',
              '&:hover': {
                textDecoration: 'none',
              },
            }}
          >
            {activity.name}
          </TextAction>
        </HeadingText>
        {!hidePoints && (
          <IconText
            iconProps={{
              icon: activity.reward?.iconUrl || '',
              css: { filter: `saturate(${isMissed ? 0 : 1})` },
            }}
            text={
              <UtilityText
                css={{
                  typography: '$bodyTwo',
                  color: isMissed ? '$onSurfaceTextSubdued' : 'inherit',
                }}
              >
                <UtilityText
                  css={{
                    paddingRight: '$quarter',
                    typography: '$bodyTwo',
                    color: isCompleted ? '$onSurfaceTextSuccess' : 'inherit',
                    fontWeight: isCompleted ? 'bold' : 'inherit',
                  }}
                >
                  {isCompleted
                    ? activity.reward?.earnedValue
                    : activity.reward?.availableValue}
                </UtilityText>
                {formatMessage({
                  id:
                    (isCompleted && 'REWARD_POINTS_EARNED') ||
                    (isMissed && 'REWARD_POINTS_MISSED') ||
                    'REWARD_POINTS',
                })}
              </UtilityText>
            }
          />
        )}

        {showRequired && (
          <IconText
            iconProps={{
              icon: activity.iconUrl || '',
              css: { filter: `saturate(${isMissed ? 0 : 1})` },
            }}
            text={
              <UtilityText
                css={{
                  typography: '$bodyTwo',
                  color: isMissed ? '$onSurfaceTextSubdued' : 'inherit',
                }}
              >
                {formatMessage({ id: 'REQUIRED' })}
              </UtilityText>
            }
          />
        )}

        <ActivityDate
          showDate={showDate}
          status={activity.status}
          startDate={activity.startDate}
          endDate={activity.endDate}
        />

        {linkedEntity?.text && (
          <IconText
            iconProps={{
              icon: linkedEntity.iconUrl,
              css: { filter: `saturate(${isMissed ? 0 : 1})` },
            }}
            text={
              <UtilityText
                css={{
                  typography: '$bodyTwo',
                  color: isMissed ? '$onSurfaceTextSubdued' : 'inherit',
                }}
              >
                {linkedEntity.text}
              </UtilityText>
            }
          />
        )}
      </StackLayout>
    );
  },
);
