import React, { FC } from 'react';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
import {
  Card,
  HeadingText,
  StackLayout,
  styled,
  useClickableCard,
  Box,
} from '@leagueplatform/genesis-core';
import { ChallengeProgressAttributes } from '@leagueplatform/health-journey-api';
import { ProgramProgress } from 'components/configurable-journey-experience/program-progress/program-progress.component';

const TitleLink = styled('a', {
  textDecoration: 'none',
});

export interface ActivityToolboxChallengeProgressProps
  extends ChallengeProgressAttributes,
    ToolboxComponentProps {}

export const ActivityToolboxChallengeProgress: FC<
  ActivityToolboxChallengeProgressProps
> = ({
  componentId,
  css,
  title,
  unit,
  goal,
  currentProgress,
  progressPercentage,
  deeplink,
}) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <Box css={css} data-testid={componentId}>
      <Card.Elevated>
        <Card.ClickArea
          ref={clickAreaRef}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
        >
          <Card.Section padding="$one $oneAndHalf">
            <StackLayout orientation="vertical" spacing="$half">
              <TitleLink href={deeplink} ref={primaryActionRef}>
                <HeadingText size="sm" level="3">
                  {title}
                </HeadingText>
              </TitleLink>
              <ProgramProgress
                currentProgress={progressPercentage}
                currentText={currentProgress.toLocaleString()}
                suffixText={`/ ${goal.toLocaleString()} ${unit}`}
              />
            </StackLayout>
          </Card.Section>
        </Card.ClickArea>
      </Card.Elevated>
    </Box>
  );
};
