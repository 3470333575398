import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import { BalanceCategory } from '@leagueplatform/wallet-api';
import {
  Box,
  HeadingText,
  ParagraphText,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { Coverage } from '../../types/plan-progress.types';

interface LiveBalanceItemProps extends Coverage {
  networkName?: string;
  balanceName: string;
  category?: BalanceCategory;
  headingLevel?: '3' | '4' | '5';
  version?: 1 | 2;
}

export const LiveBalanceItem = ({
  networkName,
  balanceName,
  paid_amount: paidAmount,
  remaining_amount: remainingAmount,
  limit,
  headingLevel = '4',
  category,
  version = 1,
}: LiveBalanceItemProps) => {
  const { formatMoney, formatMessage } = useIntl();
  const percentage = `${(paidAmount.M / limit.M) * 100}%`;
  const [member] = useSelectedMember();

  const parseCategory = React.useCallback(() => {
    if (!category) {
      return undefined;
    }
    if (version === 2) {
      return balanceName;
    }
    if (category === 'individual' && version === 1) {
      return formatMessage(
        { id: 'INDIVIDUAL_BALANCE' },
        {
          membersFullName: member?.name || formatMessage({ id: 'INDIVIDUAL' }),
          balance: balanceName,
        },
      );
    }

    return formatMessage(
      { id: 'FAMILY_BALANCE' },
      {
        balance: balanceName,
      },
    );
  }, [balanceName, category, formatMessage, member?.name, version]);

  const title = parseCategory();

  return (
    <StackLayout
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
        borderColor: '$onSurfaceBorderDefault',
        borderRadius: '$small',
        borderStyle: 'solid',
        borderWidth: '$thin',
        padding: '$one',
      }}
    >
      {title && (
        <HeadingText
          size={headingLevel === '4' ? 'md' : 'lg'}
          level={headingLevel}
        >
          {networkName && (
            <UtilityText
              emphasis="emphasized"
              size="eyebrow"
              css={{ marginBottom: '$quarter', display: 'block' }}
            >
              {networkName}
            </UtilityText>
          )}
          {title}
        </HeadingText>
      )}
      <ParagraphText>
        {formatMessage(
          { id: 'LIVE_BALANCE_SPEND' },
          {
            spentAmount: (
              <HeadingText
                as="span"
                size="lg"
                level="3"
                css={{ display: 'inline-block' }}
              >
                {formatMoney(paidAmount)}
              </HeadingText>
            ),
            totalAmount: formatMoney(limit, {
              minimumFractionDigits: 0,
            }),
          },
        )}
      </ParagraphText>
      <Box
        css={{
          backgroundColor: '$successBackgroundSubdued',
          borderRadius: '$pill',
          height: '$half',
          maxWidth: '400px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <VisuallyHidden>
          {formatMessage({ id: 'PERCENTAGE_USED' }, { percentage })}
        </VisuallyHidden>
        <Box
          css={{
            backgroundColor: '$successBorderDefault',
            borderRadius: '$pill',
            height: '100%',
            minWidth: '$half',
            width: percentage,
          }}
        />
      </Box>
      <UtilityText as="p" emphasis="emphasized">
        {formatMessage(
          { id: 'LIVE_BALANCE_REMAINING' },
          { amount: formatMoney(remainingAmount) },
        )}
      </UtilityText>
    </StackLayout>
  );
};
