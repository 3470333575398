import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  ENABLE_JOURNEY_POINTS_COUNTER_BUTTON,
  ENABLE_JOURNEY_VIEW_MY_PROFILE_BUTTON,
  ENABLE_JOURNEY_VIEW_MY_PROGRESS_BUTTON,
} from '@leagueplatform/health-journey-common';

export enum HEALTH_JOURNEY_BUTTON {
  NONE,
  POINTS_COUNTER,
  VIEW_MY_PROGRESS,
  VIEW_MY_PROFILE,
}

export const useConfigurableJourneyExperienceButton = () => {
  const { data: isPointsCounterButtonEnabled } = useFeatureFlagQuery(
    ENABLE_JOURNEY_POINTS_COUNTER_BUTTON,
  );

  const { data: isViewMyProgressButtonEnabled } = useFeatureFlagQuery(
    ENABLE_JOURNEY_VIEW_MY_PROGRESS_BUTTON,
  );

  const { data: isViewMyProfileButtonEnabled } = useFeatureFlagQuery(
    ENABLE_JOURNEY_VIEW_MY_PROFILE_BUTTON,
  );

  if (isPointsCounterButtonEnabled) {
    return HEALTH_JOURNEY_BUTTON.POINTS_COUNTER;
  }
  if (isViewMyProgressButtonEnabled) {
    return HEALTH_JOURNEY_BUTTON.VIEW_MY_PROGRESS;
  }
  if (isViewMyProfileButtonEnabled) {
    return HEALTH_JOURNEY_BUTTON.VIEW_MY_PROFILE;
  }
  return HEALTH_JOURNEY_BUTTON.NONE;
};
