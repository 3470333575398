import { getActivityStatusesArray } from 'utils/get-activity-statuses-array.util';
import { STANDALONE_ACTIVITIES_LIMIT_VIEW_ALL } from 'constants/configurable-journey-experience.constants';
import { useActivitiesByActivityStatus } from './use-activities-by-activity-status.hook';
import { useGetUserStandaloneActivities } from './use-get-user-standalone-activities.hook';

// TODO: Replace statusType with improved typing when completing:
// https://everlong.atlassian.net/browse/XPNC-2890
export const useAllActivitiesByActivityStatus = (statusType: string) => {
  const isStandalone = statusType === 'standalone';
  const activityStatuses = getActivityStatusesArray(statusType);

  const allActivitiesQuery = useActivitiesByActivityStatus({
    activityStatuses,
  });

  // limit of 50 is set for the standalone activities displayed in view all page
  const standaloneActivitiesQuery = useGetUserStandaloneActivities({
    limit: STANDALONE_ACTIVITIES_LIMIT_VIEW_ALL,
  });

  if (isStandalone) {
    return {
      ...standaloneActivitiesQuery,
      activities: standaloneActivitiesQuery?.data?.userHealthActivities,
    };
  }
  return {
    ...allActivitiesQuery,
    activities: allActivitiesQuery?.data?.userHealthActivities,
  };
};
