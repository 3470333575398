import React, { FC } from 'react';

// Hooks
import { useHealthActivityToolbox } from './hooks/use-health-activity-toolbox.hook';
import { useHealthActivityAnalytics } from './hooks/use-health-activity-analytics.hook';

// Contexts
import { HealthActivityProvider } from './contexts/health-activity-provider.component';

// Local Components
import { HealthActivityError, HealthActivityLoading } from './components';
import { HealthActivity } from './health-activity.component';

// Types
export interface HealthActivityPageProps {
  initialPageIndex: number;
}

export const HealthActivityPage: FC<HealthActivityPageProps> = ({
  initialPageIndex = 0,
}) => {
  const { hasError, ready, healthActivityData } = useHealthActivityToolbox();

  // Analytics
  const healthActivityAnalytics = useHealthActivityAnalytics();

  // Handle Loading
  if (!ready) return <HealthActivityLoading />;

  // Handle Error
  if (hasError || !healthActivityData) return <HealthActivityError />;

  return (
    <HealthActivityProvider
      healthActivityAnalytics={healthActivityAnalytics}
      currentPage={initialPageIndex}
    >
      <HealthActivity healthActivityData={healthActivityData} />
    </HealthActivityProvider>
  );
};

export default HealthActivityPage;
