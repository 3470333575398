import React, { createElement, FC, forwardRef } from 'react';
import {
  Box,
  GDSStyleObject,
  Icon,
  TextInput,
} from '@leagueplatform/genesis-core';
import {
  StringInputAttributes,
  StringInputMethodType,
} from '@leagueplatform/health-journey-api';
import {
  ControlledFormField,
  useFormContext,
} from '@leagueplatform/web-common';
import { useStringInputOptions } from 'pages/health-activity/hooks/use-string-input-options.hook';
import { ToolboxCommonInputProps } from '../../types/health-activity.types';
import { ActivityToolboxInputWrapper } from '../activity-toolbox-input-wrapper.component';

interface StringInputProps
  extends ToolboxCommonInputProps,
    StringInputAttributes {}

// Fixes odd overflow behaviour
const commonStyles = {
  '> *': {
    flexShrink: '1',
    width: 'auto',
  },
};

const textareaStyles: GDSStyleObject = {
  resize: 'vertical',
  padding: '$space$threeQuarters $space$one',
  minHeight: '$five',
  border: 'none',
  background: 'inherit',
  paddingLeft: '$quarter',
};

const Textarea = forwardRef((props, ref) =>
  createElement(Box, { ref, as: 'textarea', css: textareaStyles, ...props }),
);

export const ActivityToolboxStringInput: FC<StringInputProps> = ({
  answer,
  altText,
  suffix,
  prefix,
  css,
  placeholder,
  validation,
  inputMethod,
  componentId,
}) => {
  const { setValue } = useFormContext();
  const isTextarea = inputMethod === StringInputMethodType.box;
  const renderAs = isTextarea ? Textarea : null;

  const conditionalStyles: GDSStyleObject = isTextarea
    ? {
        ...commonStyles,
        '.GDS-text-input-wrapper': {
          padding: '0',
          paddingLeft: '$one',
          'textarea:focus': {
            boxShadow: 'none',
          },
        },
      }
    : commonStyles;

  const inputOptions = useStringInputOptions(validation);

  return (
    <ActivityToolboxInputWrapper inputName={componentId}>
      <ControlledFormField
        name={componentId}
        css={css}
        label={altText}
        hideLabel
        id={componentId}
        inputOptions={inputOptions}
      >
        <TextInput
          // @ts-ignore
          as={renderAs}
          aria-label={altText}
          addOnStart={prefix || null}
          addOnEnd={suffix || null}
          placeholder={placeholder}
          defaultValue={answer}
          data-testid={componentId}
          clearable={!isTextarea}
          onClear={() => {
            setValue(componentId, '');
          }}
          leadingContent={<Icon size={20} icon="interfaceEdit" />}
          css={conditionalStyles}
        />
      </ControlledFormField>
    </ActivityToolboxInputWrapper>
  );
};
