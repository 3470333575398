import React from 'react';
import { GDSColor, StackLayout } from '@leagueplatform/genesis-core';

export interface BadgePillProps {
  children: React.ReactNode;
  backgroundColor?: GDSColor;
}

export const BadgePill = ({
  children,
  backgroundColor = '$decorativeBrandSecondaryBright',
}: BadgePillProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    spacing="$quarter"
    css={{
      flex: '0 0 auto',
      borderStyle: '$solid',
      borderRadius: '$pill',
      paddingInline: '$half',
      paddingBlock: '$quarter',
      width: 'fit-content',
      backgroundColor,
    }}
  >
    {children}
  </StackLayout>
);
