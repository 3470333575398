import { InferProps } from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_MONTH_FORMAT } from '@leagueplatform/web-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  JOURNEY_MANAGER_ENABLED,
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  ACTIVITY_CAMPAIGN_TYPE,
  useGetUserProfile,
} from '@leagueplatform/health-journey-common';
import { activityShortPropType } from '../common/health-activities.props';

const { ACTIVE, UPCOMING, EXPIRED, REMOVED, COMPLETED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;
const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

type ActivityShortType = InferProps<typeof activityShortPropType>;
interface ActivityType extends ActivityShortType {
  todaysDate: Date;
}

export const useHealthActivityCaption = ({
  status,
  end_date,
  todaysDate,
  activity_points: points,
  points_earned: pointsEarned,
  tokens_earned: tokensEarned,
  activity_tokens: activityTokens,
  campaign_info: {
    assigned,
    campaign_type: campaignType,
    assigned_by_title: assignedByTitle,
  },
  can_expire: canExpire,
  start_date: startDate,
}: ActivityType) => {
  const { formatMessage, formatNumber, formatDate } = useIntl();
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  const endDate = end_date || '';
  const formatPoints = (pts: number | bigint) =>
    formatNumber(pts, { style: 'decimal' });
  const isAssignedShown = assigned && isJourneyManagerEnabled;

  const isExpiringToday =
    new Date(todaysDate).toLocaleDateString('en-US', {
      timeZone: defaultTimezone,
    }) ===
    new Date(endDate).toLocaleDateString('en-US', {
      timeZone: defaultTimezone,
    });

  const isAssignedToday =
    new Date(todaysDate).toLocaleDateString('en-US', {
      timeZone: defaultTimezone,
    }) ===
    new Date(startDate).toLocaleDateString('en-US', {
      timeZone: defaultTimezone,
    });

  const expiresCaption = canExpire
    ? formatMessage(
        { id: isExpiringToday ? 'EXPIRES_TODAY' : 'EXPIRES_ON_DATE' },
        {
          date: formatDate(endDate, {
            ...SHORT_MONTH_FORMAT,
            timeZone: defaultTimezone,
          }),
        },
      )
    : null;

  const assignedOnCaption = isAssignedShown
    ? formatMessage(
        { id: isAssignedToday ? 'ASSIGNED_TODAY' : 'ASSIGNED_ON' },
        {
          date: formatDate(startDate, {
            ...SHORT_MONTH_FORMAT,
            timeZone: defaultTimezone,
          }),
        },
      )
    : '';

  const assignedByTitleCaption = isAssignedShown ? assignedByTitle : '';

  const assignedText = assignedByTitleCaption
    ? `${assignedByTitleCaption} ${assignedOnCaption}`
    : null;

  const pointsCaption = (() => {
    let pointsText = null;
    switch (status.toLowerCase()) {
      case ACTIVE:
      case UPCOMING:
        if (campaignType === CHALLENGE && activityTokens) {
          pointsText = formatMessage({ id: 'EARN' });
        } else if (points) {
          pointsText = formatMessage(
            { id: 'EARN_NUMBER_POINTS_FULL' },
            { points: formatPoints(points) },
          );
        }
        break;
      case COMPLETED:
        if (campaignType === CHALLENGE && tokensEarned) {
          pointsText = formatMessage({ id: 'YOU_EARNED' });
        } else if (pointsEarned) {
          pointsText = formatMessage(
            { id: 'YOU_EARNED_X_POINTS' },
            { points: formatPoints(pointsEarned) },
          );
        }
        break;
      case EXPIRED:
      case REMOVED:
      default:
        break;
    }
    return pointsText;
  })();

  const captions = {
    expires: expiresCaption,
    points: pointsCaption,
    assigned: assignedText,
  };

  return captions;
};
