import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import { useLocation } from '@leagueplatform/routing';
import { normalizeEpochDate } from '../../utils/user-health-activities/user-health-activities.utils';

export const useActivitiesTimelineAnalytics = (currentSelectedDate) => {
  const location = useLocation();
  const baseParams = {
    product_area: PRODUCT_AREA.JOURNEY,
    current_date: normalizeEpochDate(Date.now()),
  };

  const getScreenNameAnalytics = (activity) => {
    if (activity?.suggested && !activity?.front_of_the_line_activity) {
      return SCREEN_NAMES.SUGGESTED_GROUP;
    }
    if (location.pathname.endsWith('/home')) {
      return SCREEN_NAMES.HOME_PAGE;
    }
    return SCREEN_NAMES.ACTIVITY_TIMELINE;
  };

  return {
    // User selects a card from activities list.
    selectActivity: (activity, suggestedActivityGroupName) => {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseParams,
        screen_name: getScreenNameAnalytics(activity),
        current_selected_date: currentSelectedDate,
        detail: 'view health campaign activity',
        is_fotl: activity?.front_of_the_line_activity,
        health_campaign_activity_composition: activity?.detail_level,
        health_campaign_activity_id: activity?.goal_id,
        health_campaign_activity_name: activity?.name,
        health_campaign_activity_priority: activity?.priority,
        health_campaign_activity_type: activity?.type,
        health_campaign_activity_status: activity?.status,
        health_campaign_type: activity?.campaign_info?.campaign_type || null,
        health_campaign_activity_goal:
          activity?.cta?.completion_method?.counter?.goal ?? null,
        suggested_activity_group_name: suggestedActivityGroupName || null,
      });
    },

    // User selects a card from suggested activity groups list.
    selectSuggestedActivityGroup: (groupIndex, groupName) => {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseParams,
        screen_name: SCREEN_NAMES.ACTIVITY_TIMELINE,
        current_selected_date: currentSelectedDate,
        detail: 'view suggested activity group',
        sub_detail: groupName,
        carousel_name: 'Suggested Activity Group',
        carousel_index: groupIndex,
      });
    },

    // User selects back or forward date navigation.
    // Selected date has not been stored as activeDate at time of event,
    // so it must be manually passed in this instance.
    navigateToDate: (selectedDate, subdetail) => {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseParams,
        current_selected_date: selectedDate,
        detail: 'select date',
        sub_detail: subdetail,
      });
    },

    // User selects "back to today" action.
    navigateToToday: () => {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseParams,
        detail: "navigate to today's date",
      });
    },
  };
};
