import React, { FC, MouseEvent, MouseEventHandler } from 'react';
import {
  Icon,
  styled,
  Box,
  GDSColor,
  ImageMessage,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useToolboxComponentContext } from 'pages/health-activity/contexts/toolbox-component-provider.component';
import { ComponentTypes } from '@leagueplatform/health-journey-common';
import { LinkAttributes } from '@leagueplatform/health-journey-api';
import { useToolboxLink } from 'pages/health-activity/hooks/use-toolbox-link.hook';
import { useToolboxLinkState } from '../../hooks/use-read-link.hook';
import { ToolboxComponentProps } from '../../types/health-activity.types';

export namespace ToolboxLink {
  type ClickHandler = (
    event: MouseEvent<HTMLAnchorElement>,
    actionAttributes: { url?: string; title?: string },
  ) => void;

  export interface Actions {
    onLinkOut?: ClickHandler;
    onDeepLink?: ClickHandler;
  }

  export interface Props extends LinkAttributes, ToolboxComponentProps {}
}

interface LinkCtaProps {
  copy: string;
  iconColor: '$onSurfaceTextSuccess' | '$secondaryTextDefault';
  textColor: GDSColor;
  iconToken: 'interfaceCheckmark' | 'tinyExternalLink';
}

const CardContainer = styled('a', {
  display: 'flex',
  textDecoration: 'none',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$half',
  borderColor: '$onSurfaceBorderSubdued',
  borderRadius: '$large',
  backgroundColor: '$surfaceBackgroundPrimary',
  borderStyle: 'solid',
  borderWidth: '$thin',
  padding: '$one $oneAndHalf',
  transition: 'box-shadow 0.2s ease-out',
  '&:hover': {
    borderColor: '$interactiveBorderHovered',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 $borderWidths$thick  $colors$interactiveBorderHovered',
  },
});

const LinkCta: FC<LinkCtaProps> = ({
  copy,
  iconColor,
  textColor,
  iconToken,
}) => (
  <>
    <Icon icon={iconToken} size="16px" tint={iconColor} />
    <ParagraphText
      as="p"
      size="xs"
      css={{ textTransform: 'uppercase', color: textColor, fontWeight: '700' }}
    >
      {copy}
    </ParagraphText>
  </>
);

export const ActivityToolboxLink: FC<ToolboxLink.Props> = ({
  url,
  iconUrl = '',
  title,
  css,
  componentId,
}) => {
  const { formatMessage } = useIntl();
  const { isLinkRead, setIsLinkRead } = useToolboxLinkState(componentId);
  const { isLinkOut, linkProps } = useToolboxLink(url);

  const { actions } = useToolboxComponentContext(ComponentTypes.Link);

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    const actionAttributes = { title, url };
    if (isLinkOut) {
      actions?.onLinkOut?.(event, actionAttributes);
    } else {
      actions?.onDeepLink?.(event, actionAttributes);
    }

    setIsLinkRead(true);
  };

  return (
    <Box css={css}>
      <CardContainer
        onClick={handleLinkClick}
        data-testid={componentId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...linkProps}
      >
        <ImageMessage
          imageAlt=""
          imageGap="$none"
          css={{ borderRadius: '$circle', img: { width: '$four' } }}
          image={iconUrl}
        >
          <ParagraphText css={{ typography: '$headingFour' }}>
            {title}
          </ParagraphText>
        </ImageMessage>
        <Box css={{ display: 'flex', gap: '$quarter' }}>
          {isLinkRead ? (
            <LinkCta
              copy={formatMessage({ id: 'COMPLETED_STATE_TITLE' })}
              iconColor="$onSurfaceTextSuccess"
              textColor="$onSurfaceTextSuccess"
              iconToken="interfaceCheckmark"
            />
          ) : (
            <LinkCta
              copy={formatMessage({ id: 'LINK_OUT_MESSAGE' })}
              iconColor="$secondaryTextDefault"
              textColor="$secondaryTextDefault"
              iconToken="tinyExternalLink"
            />
          )}
        </Box>
      </CardContainer>
    </Box>
  );
};
