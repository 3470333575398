const inlineTextElements = [
  'a',
  'b',
  'em',
  'i',
  'mark',
  's',
  'span',
  'strong',
  'sub',
  'sup',
  'u',
  '#text',
];

/**
 * Updates an element such that any orphan text and inline elements are grouped within a paragraph
 */
const groupInlineTextNodes = (container: Element) => {
  container.childNodes.forEach((node) => {
    // Node properties
    const { parentElement, nodeName, previousSibling, textContent } = node;
    const precedingNodeTag = previousSibling?.nodeName.toLowerCase();
    const currentNodeTag = nodeName.toLowerCase();

    const isWhitespace = !textContent?.trim();
    const isOrphanText =
      inlineTextElements.includes(currentNodeTag) &&
      parentElement === container;
    const isNotInlineAnchor =
      currentNodeTag === 'a' && previousSibling?.textContent?.endsWith('\n');

    // Exit conditions - leave nodes untouched
    if (isWhitespace || !isOrphanText || isNotInlineAnchor) return;

    const isNewParagraph =
      precedingNodeTag !== 'p' || textContent?.startsWith('\n');

    if (isNewParagraph) {
      // Wrap orphan text in a <p> and rerun function
      const wrapper = document.createElement('p');
      node.replaceWith(wrapper);
      wrapper.append(node);
      groupInlineTextNodes(container);
    } else if (previousSibling instanceof Element) {
      // Append remaining inline text to the previous element
      previousSibling?.append(node);
      groupInlineTextNodes(container);
    }
  });
};

/**
 * This function wraps any floating text nodes in a `<p>` with any subsequent inline text elements.
 * This is useful in cases where we cannot guarantee that our rich text is formatted
 * @param htmlString {string} A rich text string
 * @returns A rich text string
 */
export const wrapOrphanTextNode = (htmlString: string) => {
  const richTextContainer = document.createElement('div');
  richTextContainer.innerHTML = htmlString;

  groupInlineTextNodes(richTextContainer);

  return richTextContainer.innerHTML;
};
