import camelcaseKeys from 'camelcase-keys';
import { ConfigurableHealthActivityWS } from './activity-details-props.types';

export interface ConfigurableHealthProgramWS {
  activities?: ConfigurableHealthActivityWS[];
  activity_status_counts: {
    completed: number;
  };
  available_points?: number;
  campaign_type: string;
  completed_activity_progress_percentage: number;
  description?: string;
  end_date?: string;
  image_url?: string;
  name: string;
  program_id: string;
  quit_date?: string;
  start_date?: string;
  status: string;
  total_activities_count: number;
}

export interface CampaignWithNextActivityWS {
  program: ConfigurableHealthProgramWS;
  next_activity: ConfigurableHealthActivityWS;
}

export function camelcaseCampaign(campaign: CampaignWithNextActivityWS) {
  return camelcaseKeys(campaign, { deep: true });
}

export type CampaignWithNextActivity = ReturnType<typeof camelcaseCampaign>;
export type ConfigurableHealthProgram = CampaignWithNextActivity['program'];

export interface RewardProgramCard {
  badge: BadgeAndTrophyItem;
  cta: string;
  heading: string;
  image: string;
  imageAltText: string;
  items: RewardProgramCardItem[];
}

export type RewardProgramCardItem = {
  content: {
    currentText: string;
    prefixText?: string;
    image: string;
  };
  heading: string;
};

export interface BadgeAndTrophyItem {
  body: string;
  image: string;
  imageAltText: string;
}

export type RewardProgramCarousel = {
  heading: string;
  items: {
    attributes: RewardProgramCard;
  }[];
};
