import React from 'react';
import { BadgeImage } from '@leagueplatform/rewards-achievements';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Caption,
  Box,
  HeadingThree,
  SubtitleTwo,
  BodyOne,
  HeadingFour,
} from '@leagueplatform/genesis-commons';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  HEALTH_JOURNEY_MASONRY_VERSION,
  JOURNEY_ROUTES,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  useGetWidgetData,
  ContainerWidgetItem,
} from '@leagueplatform/masonry-api';
import {
  GenesisRouterLink,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { ConfigurableJourneyEmptyStateCard } from '../configurable-journey-empty-state-card/configurable-journey-empty-state-card';

interface AchievementsWidgetProps {
  widget: ContainerWidgetItem;
}
interface AchievementItem {
  title: string;
  description: string;
  image: string;
  image_alt_text: string;
}
interface AchievementsWidgetAttributes {
  items: AchievementItem[];
}
export const AchievementsWidget = ({ widget }: AchievementsWidgetProps) => {
  const { formatMessage } = useIntl();
  const {
    data: achievementsData,
    isLoading,
    error,
  } = useGetWidgetData<AchievementsWidgetAttributes>(
    widget,
    HEALTH_JOURNEY_MASONRY_VERSION,
  );
  const { EMPTY_STATE_ACHIEVEMENTS } = HEALTH_JOURNEY_ASSET_KEYS;
  const emptyStateImgUrl = handleStaticAsset(
    EMPTY_STATE_ACHIEVEMENTS,
  ) as string;
  if (isLoading) {
    return (
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height="100px"
        data-testid="skeleton-loader"
      />
    );
  }
  // TODO: log error when we get to observability tickets
  const isError = error || !achievementsData?.attributes?.items;
  if (isError) return null;
  const { items: recentAchievements } = achievementsData.attributes;
  const multipleAchievements = recentAchievements.length > 1;
  const isEmptyState = !recentAchievements.length;
  if (isEmptyState)
    return (
      <ConfigurableJourneyEmptyStateCard
        heading={formatMessage({
          id: 'ACHIEVEMENTS_EMPTY_STATE_HEADER',
        })}
        description={formatMessage({
          id: 'ACHIEVEMENTS_EMPTY_STATE_DESCRIPTION',
        })}
        imgSrc={emptyStateImgUrl}
        backgroundColor="secondary"
        headingLevel="2"
      />
    );
  const viewAllAchievementsOnClick = () =>
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.REWARDS,
      sub_product_area: SUB_PRODUCT_AREA.ACHIEVEMENTS,
      screen_name: SCREEN_NAMES.PROGRESS_PAGE,
      detail: 'view all achievements',
    });
  return (
    <>
      <Flex justifyContent="space-between">
        {/* TODO: update if BE is handling translations */}
        <HeadingThree as="h2" marginBottom="one">
          {formatMessage({
            id: 'RECENT_ACHIEVEMENTS',
          })}
        </HeadingThree>
        <SubtitleTwo
          as={GenesisRouterLink}
          to={JOURNEY_ROUTES.achievementsHome()}
          onClick={viewAllAchievementsOnClick}
          color="interactive.action.primary"
          /*
          Due to a Genesis bug, responsive object doesn't work properly. Instead, for responsive styles to work, we need to pass an array, with each item in the array corresponding to a breakpoint in the order that they occur:
            index: breakpoint
              0: base
              1: phone
              2: tablet
              3: laptop
              4: desktop
                */
          display={['flex', 'flex', 'none']}
          aria-label={formatMessage({ id: 'VIEW_ALL_ACHIEVEMENTS' })}
        >
          {formatMessage({
            id: 'VIEW_ALL',
          })}
        </SubtitleTwo>
      </Flex>
      <Flex
        borderRadius="large"
        boxShadow="card"
        marginBottom="one"
        width="100%"
      >
        <Flex
          alignItems="center"
          textAlign="center"
          justifyContent="space-evenly"
          padding={{
            phone: 'none',
            tablet: 'oneAndHalf',
          }}
          minWidth="50%"
          flexGrow={1}
        >
          <Flex
            backgroundColor="highlight.background.subdued"
            borderRadius="large"
            height="100%"
            width="100%"
            padding="one"
            flexDirection={{ _: 'column', tablet: 'row' }}
            justifyContent="center"
            alignItems="center"
          >
            <SubtitleTwo
              as="h3"
              width="100%"
              color="onSurface.text.primary"
              display={{ _: 'block', tablet: 'none' }}
              justifyContent="center"
              marginBottom="one"
            >
              {formatMessage({
                id: 'ACHIEVEMENTS_BANNER_HEADER',
              })}
            </SubtitleTwo>
            <StackLayout
              orientation="horizontal"
              horizontalAlignment="stretch"
              spacing="$oneAndHalf"
            >
              {recentAchievements.map((recentAchievement) => (
                <Box key={recentAchievement.title}>
                  <BadgeImage
                    imageUrl={recentAchievement.image}
                    isDisabled={false}
                    label={recentAchievement.image_alt_text}
                  />
                  <Caption
                    color="onSurface.text.primary"
                    alignSelf="flex-start"
                    width="100%"
                  >
                    {recentAchievement.title}
                  </Caption>
                </Box>
              ))}
            </StackLayout>
          </Flex>
        </Flex>
        <Box
          minWidth="50%"
          display={{ _: 'none', tablet: 'block' }}
          padding={{ phone: 'none', tablet: 'oneAndHalf' }}
        >
          <HeadingFour as="h3" color="onSurface.text.primary">
            {formatMessage({
              id: 'ACHIEVEMENTS_BANNER_HEADER',
            })}
          </HeadingFour>
          <BodyOne
            marginTop="half"
            marginBottom="oneAndHalf"
            color="onSurface.text.subdued"
          >
            {formatMessage({
              id: multipleAchievements
                ? 'MULTIPLE_ACHIEVEMENT_DESCRIPTION'
                : 'SINGLE_ACHIEVEMENT_DESCRIPTION',
            })}
          </BodyOne>
          <SubtitleTwo
            as={GenesisRouterLink}
            to={JOURNEY_ROUTES.achievementsHome()}
            color="interactive.action.primary"
            minWidth="fit-content"
            onClick={viewAllAchievementsOnClick}
            aria-label={formatMessage({ id: 'VIEW_ALL_ACHIEVEMENTS' })}
          >
            {formatMessage({
              id: 'VIEW_ALL_ACHIEVEMENTS',
            })}
          </SubtitleTwo>
        </Box>
      </Flex>
    </>
  );
};
