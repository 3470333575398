import React from 'react';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
  useClickableCard,
  styled,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export type MasonryTwoUpCardNodeProperties = {
  heading: string;
  body?: string;
  imageAltText: string;
  image: string;
};

export type MasonryTwoUpCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryTwoUpCardNode = MasonryEngineNode<
  WidgetType.TWO_UP_CARD,
  MasonryTwoUpCardNodeProperties,
  MasonryTwoUpCardNodeActions
>;

export type MasonryTwoUpCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryTwoUpCardNode>;

const StyledImage = styled('img', {
  width: '4.2rem',
  height: '4.2rem',
});

export const MasonryTwoUpCardRenderer = ({
  heading,
  body,
  imageAltText,
  image,
  onClick,
}: MasonryTwoUpCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(onClick?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      css={{
        width: '100%',
      }}
    >
      <StackLayout spacing="$half" css={{ padding: '$oneAndHalf' }}>
        <StyledImage src={image} alt={imageAltText} />
        <StackLayout spacing="$quarter">
          <HeadingText size="sm" level="3">
            <ConditionalLinkRenderer
              ref={primaryActionRef}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
              url={onClick?.payload.url}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>

          {body && (
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {body}
            </ParagraphText>
          )}
        </StackLayout>
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
