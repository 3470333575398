import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { useActiveCampaignInfo } from '@leagueplatform/health-programs';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';

const { BUTTON_CLICKED, SCREEN_LOADED, NAVIGATION_TAB_CLICKED } = EVENT_NAME;
const { PROGRESS_PAGE, PROGRAM_LIMIT_MODAL } = SCREEN_NAMES;

export const useJourneyProgressAnalytics = () => {
  const { data: campaignLimitInfo = {} } = useActiveCampaignInfo();
  const {
    number_of_enrolled_programs: activeCampaigns,
    max_programs_limit: campaignLimit,
  } = campaignLimitInfo;

  const meta = {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: PROGRESS_PAGE,
  };

  const data = {
    num_health_campaign_active: activeCampaigns ?? null,
    num_health_campaign_limit: campaignLimit ?? null,
  };

  return {
    sendViewProgressPage() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
      });
    },
    sendPillNavigation({ from, to }) {
      trackAnalyticsEvent(NAVIGATION_TAB_CLICKED, {
        ...meta,
        ...data,
        screen_name: from,
        detail: to,
      });
    },
    sendSelectActiveProgram({
      campaignName = null,
      campaignId = null,
      campaignType = null,
    }) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        detail: 'view health campaign',
        health_campaign_name: campaignName,
        health_campaign_id: campaignId,
        health_campaign_type: campaignType,
        is_active_health_campaign: true,
      });
    },
    sendSelectStartProgramsBanner(ctaText) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        detail: 'start more health campaigns',
        sub_detail: ctaText,
      });
    },
    sendOpenProgramLimitModal() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        detail: 'view health campaign limit banner',
      });
    },
    sendCloseProgramLimitModal() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        screen_name: PROGRAM_LIMIT_MODAL,
        detail: 'close',
      });
    },
  };
};
