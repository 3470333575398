import React from 'react';
import { bool, number } from 'prop-types';
import {
  activitiesGroupsDefaultProps,
  activitiesGroupsProps,
  journeyPageEmptyStateProps,
} from 'common/health-activities.props';
import { HealthActivities } from 'components/health-activities.view';
import { JourneyTimelineEmptyState } from './journey-timeline-empty-state.component';

export const JourneyTimelineActivitiesList = ({
  hasCompletedAllActivities,
  emptyStateContent,
  activitiesByCampaign,
  activitiesByStatus,
  activitiesByAssigned,
  activitiesByPriority,
  activitiesByChallenge,
  allActivities,
  activeDate,
}) => (
  <>
    {hasCompletedAllActivities && emptyStateContent && (
      <JourneyTimelineEmptyState
        marginBottom="four"
        emptyStateContent={emptyStateContent}
      />
    )}
    <HealthActivities
      activitiesByCampaign={activitiesByCampaign}
      activitiesByStatus={activitiesByStatus}
      activitiesByAssigned={activitiesByAssigned}
      activitiesByPriority={activitiesByPriority}
      activitiesByChallenge={activitiesByChallenge}
      allActivities={allActivities}
      activeDate={activeDate}
    />
  </>
);

JourneyTimelineActivitiesList.propTypes = {
  ...activitiesGroupsProps,
  ...journeyPageEmptyStateProps,
  hasCompletedAllActivities: bool.isRequired,
  activeDate: number.isRequired,
};
JourneyTimelineActivitiesList.defaultProps = {
  ...activitiesGroupsDefaultProps,
};
