import {
  ReactElement,
  ComponentProps,
  createElement,
  JSXElementConstructor,
} from 'react';
import type { GDSEmoji, GDSIcon } from './types';
import type { GDSIconProps } from './components/icon/icon';

export const isIcon = (
  icon?: ReactElement<GDSIcon | GDSEmoji | any>,
): icon is ReactElement<GDSIcon> => {
  const iconProps = icon?.props as unknown as GDSIconProps;
  return iconProps?.icon !== undefined;
};

// util from https://samuelkraft.com/blog/radix-ui-styling-with-css
export const withClassName = <
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(
  component: T,
  ...classes: string[]
) => {
  const name = `${typeof component === 'string' ? component : ''}_${
    classes[0]
  }`;

  return {
    [name](props: ComponentProps<T>) {
      return createElement(component, {
        ...props,
        className: `${props.className} ${classes}`,
      });
    },
  }[name];
};
