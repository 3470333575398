import {
  bool,
  string,
  number,
  func,
  arrayOf,
  shape,
  oneOf,
  node,
} from 'prop-types';
import { COMPLETION_METHOD_TYPES } from '@leagueplatform/health-journey-common';

const { BASE } = COMPLETION_METHOD_TYPES;
export const activityPropType = {
  hasError: bool,
  startDate: string,
  campaignInfo: shape({
    campaign_type: string,
    assigned: bool,
    assigned_by_title: string,
  }),
  ready: bool,
  activityType: string,
  tagline: string,
  name: string,
  activityPoints: number,
  suggestionDescription: string,
  iconUrl: string,
  description: string,
  richTextDescription: string,
  informationTitle: string,
  informationDescription: string,
  helpfulTips: arrayOf(
    shape({
      type: string,
      title: string,
      url: string,
      helpfulTipImage: string,
    }),
  ),
  disclaimerTitle: string,
  disclaimerDescription: string,
  status: string,
  cta: shape({
    mark_as_complete: bool,
    text: string,
    url: string,
    completion_method: shape({
      type: oneOf(Object.values(COMPLETION_METHOD_TYPES)),
      text: string,
      steps: arrayOf(string),
    }),
  }),
  activityId: string,
  campaignMode: string,
  references: string,
  activityTokens: number,
  tokensEarned: number,
};

export const activityDefaultProps = {
  hasError: false,
  startDate: '',
  campaignInfo: {
    campaign_type: '',
    assigned: false,
    assigned_by_title: '',
  },
  ready: true,
  activityPoints: 0,
  activityType: '',
  suggestionDescription: '',
  tagline: '',
  name: '',
  iconUrl: '',
  description: '',
  richTextDescription: '',
  informationTitle: '',
  informationDescription: '',
  helpfulTips: [],
  disclaimerTitle: '',
  disclaimerDescription: '',
  status: '',
  cta: {
    mark_as_complete: false,
    text: '',
    url: '',
    completion_method: {
      type: BASE,
      text: '',
    },
  },
  activityId: '',
  campaignMode: null,
  references: '',
  tokensEarned: 0,
};

export const achievementPropType = shape({
  id: string,
  name: string,
  description: string,
  image: shape({
    small: string,
    medium: string,
    large: string,
  }),
  progress: shape({
    overall: number,
    title: string,
    criteria: shape({
      action: string,
      message: string,
      threshold: number,
      current: number,
      is_completed: bool,
    }),
  }),
  description_earned: string,
  description_title: string,
  celebration_subtitle: string,
  completions: number,
});

export const achievementArrayPropType = arrayOf(achievementPropType);

export const activityFooterPropType = {
  ...activityPropType,
  requestSetUserHealthActivityComplete: func.isRequired,
  primaryAction: func.isRequired,
  primaryCtaText: func.isRequired,
  onCloseCompleteActivityModal: func.isRequired,
  showCompleteActivityModal: bool,
  showDeleteActivityModal: bool,
  toggleDeleteActivityModal: func.isRequired,
  requestSetUserHealthActivityDelete: func.isRequired,
  onClose: func.isRequired,
  isRemovable: bool,
  completionData: shape({
    eyebrow_headline: string,
    title: string,
    description_one: string,
    description_two: string,
    image: string,
    rewards_message: string,
  }),
  isActivityDetailsView: bool,
  activityHasMetCompletionCriteria: bool.isRequired,
  startDate: string,
  endDate: string,
  canExpire: bool.isRequired,
  showAchievements: bool,
  inProgressProgramAchievements: achievementArrayPropType,
  inProgressWeeklyStreakAchievements: achievementArrayPropType,
  inProgressActivityAchievements: achievementArrayPropType,
  completedTotalAchievements: achievementArrayPropType,
  showCelebrationAchievementModal: bool,
  toggleCelebrationAchievementModal: func.isRequired,
  errorMsg: node,
};

export const activityFooterDefaultProps = {
  ...activityDefaultProps,
  showCompleteActivityModal: false,
  showDeleteActivityModal: false,
  isRemovable: true,
  completionData: {
    eyebrow_headline: '',
    title: '',
    description_one: '',
    description_two: '',
    image: '',
    rewards_message: '',
  },
  isActivityDetailsView: true,
  startDate: '',
  endDate: '',
  showAchievements: false,
  inProgressProgramAchievements: [],
  inProgressWeeklyStreakAchievements: [],
  inProgressActivityAchievements: [],
  completedTotalAchievements: [],
  showCelebrationAchievementModal: false,
  errorMsg: null,
};

export const mapToProps = (activity) => {
  const {
    status,
    activityPoints,
    pointsEarned,
    tokensEarned,
    activityTokens,
    campaignInfo,
    startDate,
    ...activityShort
  } = activity;

  return {
    status,
    activity_points: activityPoints,
    points_earned: pointsEarned,
    tokens_earned: tokensEarned,
    activity_tokens: activityTokens,
    campaign_info: campaignInfo,
    start_date: startDate,
    ...activityShort,
  };
};
