import React, { FC, forwardRef, ReactElement, useState } from 'react';
import { Icon, IconSource, isGDSIconToken } from 'components/icon/icon';
import { Spinner } from 'components/spinner/spinner';
import { styled } from '../../../theme';
import type {
  GDSCustomizableComponent,
  GDSInputStatus,
  GDSIcon,
} from '../../../types';
import type { GDSHTMLAutocomplete } from '../types';
import { inputBorderStyles, disabledInputStyles } from '../input-styles';
import { useThemeIcons } from '../../../hooks/use-theme';
import { isIcon } from '../../../utilities';
import { focusOutlineInner } from '../../../theme/utils/focus-outline';

interface SelectOption {
  key?: string;
  value: string;
  disabled?: boolean;
  label?: string | number | boolean;
}

interface SelectOptionsGroup {
  key?: string;
  label: string;
  options: SelectOption[];
}

interface LeadingIconProps {
  icon?: IconSource | ReactElement<GDSIcon>;
}

export type GDSSelectProps = GDSCustomizableComponent &
  Omit<React.HTMLProps<HTMLSelectElement>, 'children'> & {
    id?: string;
    value?: string;
    required?: boolean;
    leadingIcon?: IconSource | ReactElement<GDSIcon>;
    loading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    options?: (SelectOption | SelectOptionsGroup)[];
    children?: ReactElement<HTMLOptionElement | HTMLOptGroupElement>[];
    'aria-describedby'?: string;
    inputStatus?: GDSInputStatus;
    /**
     * Identify the purpose of the input through the autocomplete attribute. This allows users to easily fill form using information stored in their browsers. It should be [a valid HTML5 input autocomplete value](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete), review the complete list understand the purpose of each of the values before applying it to a field.
     *
     */
    autoComplete?: GDSHTMLAutocomplete;
    onClick?: (event: React.SyntheticEvent) => void;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    ref?: React.Ref<HTMLSelectElement>;
  };

const BaseSelect = styled('select', {
  appearance: 'none',
  ...inputBorderStyles,
  typography: '$bodyTwo',
  width: '100%',
  paddingTop: '$threeQuarters',
  paddingBottom: '$threeQuarters',
  paddingRight: 'calc($one + $oneAndHalf)',
  backgroundColor: '$inputBackgroundDefault',
  '&:hover': {
    borderColor: '$inputBorderHovered',
    backgroundColor: '$inputBackgroundHovered',
  },
  '&[class*="GDS"]:focus, &:active': {
    borderColor: 'transparent',
    ...focusOutlineInner,
  },
  '&:disabled, &[aria-disabled="true"]': {
    ...disabledInputStyles,
  },
  variants: {
    inputStatus: {
      success: {
        borderColor: '$inputBorderSuccess',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderSuccess',
        },
      },
      warning: {
        borderColor: '$inputBorderWarning',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderWarning',
        },
      },
      error: {
        borderColor: '$inputBorderCritical',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderCritical',
        },
      },
    },
  },
});

const SelectWrapper = styled('div', {
  position: 'relative',
});

const sharedIconWrapperStyles = {
  display: 'flex',
  position: 'absolute',
  pointerEvents: 'none',
  transformOrigin: 'center',
  transform: 'translateY(-50%)',
  top: '50%',
};

const LeadingIconWrapper = styled('div', {
  ...sharedIconWrapperStyles,
  left: '$one',
});

const TrailingIconWrapper = styled('div', {
  ...sharedIconWrapperStyles,
  right: '$one',
});

const generateOptionElement = ({
  key,
  value,
  label,
  disabled,
}: SelectOption) => (
  <option key={key || value} value={value} disabled={disabled}>
    {label || value}
  </option>
);

const LeadingIcon = ({ icon }: LeadingIconProps) => {
  const icons = useThemeIcons();

  // If the developer has passed an icon token
  if (isGDSIconToken(icon as IconSource, icons))
    return <Icon icon={icon as IconSource} tint="$onSurfaceIconPrimary" />;

  // leadingIcon an `Icon`
  if (React.isValidElement(icon)) {
    // If the user has passed an `<Icon />` component
    if (isIcon(icon)) {
      return icon;
    }
  }

  return null;
};

export const Select: FC<GDSSelectProps> = forwardRef(
  (
    {
      id,
      value,
      defaultValue = '',
      className,
      leadingIcon,
      loading,
      disabled,
      placeholder,
      options,
      children,
      inputStatus,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      autoComplete,
      css,
      onClick,
      onChange: onChangeProp = (e) => e,
      onBlur,
      onFocus,
      ...props
    }: GDSSelectProps,
    ref: React.Ref<HTMLSelectElement>,
  ) => {
    const [hasValue, setHasValue] = useState(Boolean(value || defaultValue));

    const onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const eventValue = evt?.currentTarget?.value;
      setHasValue(Boolean(eventValue)); // will set to false if undefined
      onChangeProp(evt);
    };

    if (options && children) {
      console.warn(
        'The Select component accepts either the options props or child option elements, not both.',
      );
    }

    return (
      <SelectWrapper className={['GDS-select', className].join(' ')} css={css}>
        {leadingIcon && (
          <LeadingIconWrapper className="GDS-select-leading-icon">
            <LeadingIcon icon={leadingIcon} />
          </LeadingIconWrapper>
        )}
        <BaseSelect
          id={id}
          value={value}
          defaultValue={defaultValue}
          className="GDS-select-menu"
          disabled={disabled}
          autoComplete={autoComplete}
          inputStatus={inputStatus}
          {...(inputStatus === 'error' && { 'aria-invalid': true })}
          aria-disabled={ariaDisabled}
          aria-describedby={ariaDescribedby}
          css={{
            ...(leadingIcon
              ? { paddingLeft: 'calc($one + $oneAndHalf + $half)' }
              : { paddingLeft: '$one' }),
            ...(hasValue
              ? { color: '$onSurfaceTextPrimary' }
              : { color: '$onSurfaceTextSubdued' }),
          }}
          ref={ref}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options?.length
            ? options.map((option) => {
                if ('value' in option) return generateOptionElement(option);
                if ('options' in option)
                  return (
                    <optgroup
                      key={option.key || option.label}
                      label={option.label}
                    >
                      {option.options.map((element) =>
                        generateOptionElement(element),
                      )}
                    </optgroup>
                  );
                console.warn(
                  'Incorrectly formatted options array. Please refer to Genesis Core Select documentation for correct formatting.',
                );
                return null;
              })
            : children}
        </BaseSelect>
        <TrailingIconWrapper className="GDS-select-trailing-icon">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <Icon icon="interfaceChevronDown" tint="$onSurfaceIconPrimary" />
          )}
        </TrailingIconWrapper>
      </SelectWrapper>
    );
  },
);

Select.displayName = 'Select';
