/* eslint-disable no-console */
import { setServerConfig } from './store';
import type { ServerConfig } from './league-config-schema';
import { assertConfigSchema } from './assert-config-schema';
import { serverConfigSchema } from './module-config-schemas/server-driven';

function getCacheKey(appId: string, environment: string) {
  return `server-config-${appId}-${environment}`;
}

function cacheServerConfig(
  appId: string,
  environment: string,
  config: ServerConfig,
) {
  localStorage.setItem(getCacheKey(appId, environment), JSON.stringify(config));
}

function getCachedServerConfig(appId: string, environment: string) {
  const serverConfigString = localStorage.getItem(
    getCacheKey(appId, environment),
  );
  const serverConfig = serverConfigString
    ? JSON.parse(serverConfigString)
    : undefined;

  if (serverConfig) setServerConfig(serverConfig);
}

/**
 * At this point, we respond to ANY issue in the process of fetching server config -
 * whether it's a network error, bad response, or data that does not match the schema -
 * by simply setting the server config to an empty object.
 *
 * This will change in the future.
 */
const setEmptyServerConfigOnError = () => setServerConfig({});

/**
 * Retrieves application config from the server, caches it, and stores it in the
 * config store, making it available for SDKs to access
 * @param appId string
 * @param environment string
 */
export async function updateServerConfig(appId: string, environment: string) {
  let response: Response;

  const serverConfigPath = `https://sdk-configs.league.com/v1/${appId}-${environment}.json`;

  // Fetch cached config, and set it in config store if exists
  getCachedServerConfig(appId, environment);

  // Fetch server driven config
  try {
    response = await fetch(serverConfigPath);
  } catch (fetchError) {
    console.error(`Fetch server driven config failure`, fetchError);
    setEmptyServerConfigOnError();
    throw Error(`Fetch server driven config failure: ${fetchError}`);
  }

  // Check response status
  if (!response.ok) {
    console.error(
      `Fetch server driven config error response`,
      response.status,
      response.statusText,
    );
    setEmptyServerConfigOnError();
    throw Error(
      `Fetch server driven config error response: ${response.statusText}`,
    );
  }

  // Parse JSON response payload
  let responseData: ServerConfig;
  try {
    responseData = await response.json();
  } catch (parseError) {
    console.error(`Error parsing server response as JSON`, parseError);
    setEmptyServerConfigOnError();
    throw Error(`Error parsing server response as JSON: ${parseError}`);
  }

  try {
    assertConfigSchema(responseData, serverConfigSchema);
  } catch (schemaError) {
    console.error(`Server response does not match schema`, schemaError);
    setEmptyServerConfigOnError();
    throw schemaError;
  }

  setServerConfig(responseData);
  cacheServerConfig(appId, environment, responseData);
}
