import React, { FC, forwardRef, ReactElement } from 'react';
import { UtilityText } from 'components/typography/utility-text/utility-text';
import { StackLayout } from 'components/stack-layout/stack-layout';
import { StackItem } from 'components/stack-layout/stack-item/stack-item';
import { styled } from '../../../theme';
import { useThemeIcons, useThemeStrings } from '../../../hooks/use-theme';
import type {
  GDSCustomizableComponent,
  GDSComponent,
  GDSStatus,
  GDSIcon,
  GDSEmoji,
} from '../../../types';
import type { GDSIcons } from '../../../theme/icons/get-theme-icons';
import { Icon, IconSource, isGDSIconToken } from '../../icon/icon';
import type { GDSIconProps } from '../../icon/icon';
import { Button } from '../../button/button';
import {
  backgroundColorByStatus,
  iconTintByStatus,
} from '../status-color-tokens';
import { isIcon } from '../../../utilities';

export interface GDSStatusBannerProps
  extends GDSCustomizableComponent,
    GDSComponent {
  status: GDSStatus;
  title?: React.ReactNode;
  showIcon?: boolean;
  onDismissClick?: (event: React.MouseEvent) => void;
  icon?: IconSource | ReactElement<GDSIcon | GDSEmoji>;
  children?: React.ReactNode;
}

const iconByStatus: Record<GDSStatus, IconSource> = {
  success: 'statusSuccessStatusFilled',
  error: 'statusErrorStatusFilled',
  info: 'statusInformationCircleFill',
  warning: 'statusAlertFill',
  neutral: 'statusInformationCircleFill',
  brand: 'illustrativePerson',
};

const StatusBannerBase = styled(StackLayout, {
  borderRadius: '$small',
  padding: '$one',
  variants: {
    status: {
      info: {
        backgroundColor: backgroundColorByStatus.info,
      },
      success: {
        backgroundColor: backgroundColorByStatus.success,
      },
      warning: {
        backgroundColor: backgroundColorByStatus.warning,
      },
      error: {
        backgroundColor: backgroundColorByStatus.error,
      },
      brand: {
        backgroundColor: backgroundColorByStatus.brand,
      },
      neutral: {
        backgroundColor: backgroundColorByStatus.neutral,
      },
    },
  },
});

interface StatusBannerIconProps {
  className?: string;
  status: GDSStatus;
  icon?: IconSource | ReactElement<GDSIcon | GDSEmoji | any>;
  icons: GDSIcons;
}

const StatusBannerIcon = ({
  icons,
  icon,
  status,
  className,
}: StatusBannerIconProps) => {
  // There are some default icons defined based on kind
  const defaultTint = iconTintByStatus[status] as GDSIconProps['tint'];
  const defaultIcon = iconByStatus[status];
  if (!icon)
    return <Icon icon={defaultIcon} tint={defaultTint} className={className} />;

  // If the developer has passed an icon token
  if (isGDSIconToken(icon as IconSource, icons as GDSIcons))
    return <Icon icon={icon as IconSource} tint={defaultTint} />;

  // icon is either an `Icon` or a some valid react element
  if (React.isValidElement(icon)) {
    // If the user has passed an `<Icon />` component
    if (isIcon(icon)) {
      const iconProps = icon?.props as unknown as GDSIconProps;
      // set the icon tint to either the passed icon's prop or default to default tint by status
      const iconTint = iconProps.tint ?? defaultTint;
      return React.cloneElement(icon as ReactElement<any>, {
        ...iconProps,
        className,
        tint: iconTint,
      });
    }

    // any other valid react element, we still want to pass on the className
    return React.cloneElement(icon as ReactElement<any>, {
      className,
    });
  }

  return null;
};

export const StatusBanner: FC<GDSStatusBannerProps> = forwardRef(
  (
    {
      status,
      title,
      showIcon = true,
      icon,
      onDismissClick,
      css,
      children,
      className,
      ...props
    }: GDSStatusBannerProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const hasChildren = Boolean(children);
    const strings = useThemeStrings();
    const icons = useThemeIcons();
    return (
      <StatusBannerBase
        className={['GDS-status-banner', className].join(' ')}
        orientation="horizontal"
        spacing="$half"
        verticalAlignment={hasChildren ? undefined : 'center'}
        status={status}
        ref={ref}
        css={css}
        {...props}
      >
        {showIcon ? (
          <StackItem>
            <StatusBannerIcon
              icons={icons}
              icon={icon}
              status={status}
              className="GDS-status-banner-icon"
            />
          </StackItem>
        ) : null}
        <StackItem growFactor={1}>
          <StackLayout>
            {title ? (
              <StackItem>
                {React.isValidElement(title) ? (
                  title
                ) : (
                  <UtilityText
                    emphasis="emphasized"
                    className="GDS-status-banner-title"
                    as="p"
                  >
                    {title}
                  </UtilityText>
                )}
              </StackItem>
            ) : null}
            {children ? <StackItem>{children}</StackItem> : null}
          </StackLayout>
        </StackItem>
        {onDismissClick ? (
          <StackItem>
            <Button
              className="GDS-status-banner-dismiss-button"
              onClick={onDismissClick}
              priority="secondary"
              quiet
              hideLabel
              icon="tinyClose"
              size="small"
            >
              {strings?.dismiss}
            </Button>
          </StackItem>
        ) : null}
      </StatusBannerBase>
    );
  },
);

StatusBanner.displayName = 'StatusBanner';
