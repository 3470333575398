import React, { FC, useCallback, useEffect, useRef } from 'react';
import {
  ApiDelayOverlay,
  useApiDelayOverlay,
  ViewTransitionController,
} from '@leagueplatform/health-journey-common';
import { EditProgressCountModal } from 'components/edit-progress-count-modal.component';
import { Modal } from '@leagueplatform/genesis-core';
import { DeleteActivityModal } from 'components/delete-activity-modal.component';
import { useActivityModals } from '../hooks/use-activity-modals.hook';
import { HealthActivityModalTypes } from '../types/health-activity.types';
import { ActivityExitModal } from './modals/activity-exit-modal.component';
import { ActivityWarningModal } from './modals/activity-warning-modal.component';

// Constants
const { ApiDelay, Exit, EditComponentProgress, Warning, Remove, None } =
  HealthActivityModalTypes;

export const HealthActivityModalController: FC<{}> = () => {
  const { activeModal, setActiveModal } = useActivityModals();
  const { delayOverlayActive } = useApiDelayOverlay();
  const modalTrigger = useRef<HTMLElement | null>(null);
  const onClose = useCallback(() => setActiveModal(None), [setActiveModal]);
  const baseProps = { onClose, ...activeModal?.props };
  const modals = {
    [Exit]: {
      view: ActivityExitModal,
      props: baseProps,
    },
    [EditComponentProgress]: {
      view: EditProgressCountModal,
      props: baseProps,
    },
    [Warning]: {
      view: ActivityWarningModal,
      props: baseProps,
    },
    [ApiDelay]: {
      view: ApiDelayOverlay,
      props: baseProps,
    },
    [Remove]: {
      view: DeleteActivityModal,
      props: baseProps,
    },
  };

  useEffect(() => {
    if (activeModal?.type && document.activeElement instanceof HTMLElement) {
      modalTrigger.current = document.activeElement;
    } else {
      modalTrigger.current = modalTrigger.current?.focus() ?? null;
    }
  }, [activeModal?.type]);

  useEffect(() => {
    if (delayOverlayActive) {
      setActiveModal(ApiDelay);
    }
  }, [delayOverlayActive, setActiveModal]);

  return (
    <React.Suspense fallback={null}>
      <Modal.Root
        open={Boolean(activeModal?.type)}
        onOpenChange={(isOpen) => !isOpen && onClose()}
      >
        <ViewTransitionController
          duration={300}
          handleActiveView={() => activeModal?.type}
          transitionType="fade"
          views={modals}
        />
      </Modal.Root>
    </React.Suspense>
  );
};
