import { MetricType } from '@leagueplatform/dashboard-api';
import type { DataCategory } from 'types/data-type';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';

// Temporary type used in transition to dynamic dashboard
export type UnitMetricTypes =
  | MetricType
  | 'body_height'
  | 'body_weight'
  | 'waist_circumference';

type DataTypeConfig = {
  metric: string;
  unit?: string;
  icon: string;
};

export const dataTypeConfigMap = new Map<UnitMetricTypes, DataTypeConfig>([
  [
    'distance',
    {
      metric: 'INSIGHT_CARD_HEADER_DISTANCE',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'steps',
    {
      metric: 'INSIGHT_CARD_HEADER_STEPS',
      unit: 'INSIGHT_CARD_UNIT_STEPS',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'floors_climbed',
    {
      metric: 'INSIGHT_CARD_HEADER_FLOORS_CLIMBED',
      unit: 'INSIGHT_CARD_UNIT_FLOORS_CLIMBED',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'active_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_ACTIVE_DURATION',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'energy_burned',
    {
      metric: 'INSIGHT_CARD_HEADER_ENERGY_BURNED',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'mindful_duration',
    {
      metric: 'INSIGHT_CARD_HEADER_MINDFUL_DURATION',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_MINDFULNESS_ICON,
    },
  ],
  [
    'body_weight',
    {
      metric: 'INSIGHT_CARD_HEADER_WEIGHT',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
    },
  ],
  [
    'body_height',
    {
      metric: 'INSIGHT_CARD_HEADER_HEIGHT',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
    },
  ],
  [
    'waist_circumference',
    {
      metric: 'INSIGHT_CARD_HEADER_WAIST_CIRCUMFERENCE',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
    },
  ],
  // These data points aren't currently implemented yet.
  // [
  //   'sleep_duration',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_SLEEP_DURATION',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_SLEEP_ICON,
  //   },
  // ],
  // [
  //   'heart_rate',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_HEART_RATE',
  //
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_HEART_ICON,
  //   },
  // ],
  // [
  //   'blood_glucose',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_BLOOD_GLUCOSE',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  //   },
  // ],
  // [
  //   'blood_pressure',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_BLOOD_PRESSURE',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  //   },
  // ],
  // [
  //   'blood_oxygen',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_BLOOD_OXYGEN',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  //   },
  // ],
  // [
  //   'body_temperature',
  //   {
  //     metric: 'INSIGHT_CARD_HEADER_BODY_TEMPERATURE',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
  //   },
]);

type DataTypeCategoryConfig = {
  metric: string;
  icon: string;
};

export const dataTypeCategoryConfigMap = new Map<
  DataCategory,
  DataTypeCategoryConfig
>([
  [
    'activity',
    {
      metric: 'DATAPOINT_CATEGORY_ACTIVITY',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
    },
  ],
  [
    'body',
    {
      metric: 'DATAPOINT_CATEGORY_BODY',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
    },
  ],
  // TODO: add these back in when we add more units that can be updated that fall into these categories yet.
  // [
  //   'blood',
  //   {
  //     metric: 'DATAPOINT_CATEGORY_BLOOD',
  //     icon: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  //   },
  // ],
]);
