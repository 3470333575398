import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { HealthJourneyHome } from './components';

const NAMESPACE_HEALTH_JOURNEY = 'health_journey';
const NODE_TYPE_HEALTH_JOURNEY = 'health_journey';

export const initMasonryRegistry = () => {
  // Registering health journey node type to Masonry WS Driver when this module is initialized
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    NAMESPACE_HEALTH_JOURNEY,
    NODE_TYPE_HEALTH_JOURNEY,
    HealthJourneyHome,
  );
};
