import React, { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import type {
  MasonryEngineOrigin,
  MasonryEngineNodeOrigin,
} from './types/masonry-engine-node.types';

/**
 * A React Context for providing the current Luxe entity's
 * {@link MasonryEngineOrigin `MasonryEngineOrigin`} to any of its descending components.
 */
export const MasonryEngineOriginContext = createContext<
  MasonryEngineOrigin | undefined
>(undefined);

type MasonryEngineOriginProviderProps = {
  children: ReactNode;
} & { nodeId: MasonryEngineNodeOrigin['nodeId'] };

/**
 * A provider of {@link MasonryEngineOriginContext `MasonryEngineOriginContext`}, which is re-provided
 * by each layer in the Luxe flow, to recursively
 * construct a {@link MasonryEngineOrigin `MasonryEngineOrigin`} object uniquely identifying all members
 * in the hierarchy.
 */
export const MasonryEngineOriginProvider = ({
  children,
  ...origin
}: MasonryEngineOriginProviderProps) => {
  const upperOrigin = useContext(MasonryEngineOriginContext);
  const nodeId = 'nodeId' in origin ? origin.nodeId : undefined;

  const lowerOrigin = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
    const _lowerOrigin = upperOrigin ? { ...upperOrigin } : {};

    if (nodeId) {
      (_lowerOrigin as MasonryEngineNodeOrigin).nodeId = nodeId;
    }

    return _lowerOrigin as MasonryEngineNodeOrigin;
  }, [nodeId, upperOrigin]);

  return (
    <MasonryEngineOriginContext.Provider value={lowerOrigin}>
      {children}
    </MasonryEngineOriginContext.Provider>
  );
};
