import { useTablistContext } from './tablist-context';

export const useTabElementIsInView = () => {
  const { tabListWrapper, scrollLeftButton, scrollRightButton } =
    useTablistContext();

  const tabScrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  };

  const elementIsInView = (tabElement: any) => {
    const scrollButtonWidth = (
      scrollLeftButton || scrollRightButton
    )?.getBoundingClientRect().width;

    let leftOffset;
    let tablistWrapperWidth;

    const leftEdge = tabListWrapper?.getBoundingClientRect();

    if (scrollLeftButton && scrollButtonWidth) {
      leftOffset = leftEdge!.left + scrollButtonWidth;
    } else {
      leftOffset = leftEdge!.left;
    }

    if (scrollRightButton && tabListWrapper && scrollButtonWidth) {
      tablistWrapperWidth = tabListWrapper.offsetWidth - scrollButtonWidth;
    } else {
      tablistWrapperWidth = tabListWrapper?.offsetWidth;
    }

    const { left, right } = tabElement.current.getBoundingClientRect();

    return left >= leftOffset && right <= tablistWrapperWidth!;
  };

  return {
    elementIsInView,
    tabScrollOptions,
  };
};
