export enum LEAGUE_MODULE_NAMES {
  assessment = 'assessment',
  careCollections = 'care-collections',
  careDiscovery = 'care-discovery',
  careDocuments = 'care-documents',
  wallet = 'wallet',
  healthJourney = 'health-journey',
  healthJourneyHistory = 'health-journey-history',
  healthNeedPages = 'health-need-pages',
  healthProfile = 'health-profile',
  healthPrograms = 'health-programs',
  healthProgress = 'health-progress',
  messaging = 'messaging',
  onboarding = 'onboarding',
  rewardsAchievements = 'achievements',
  familyExperience = 'family-experience',
  aboutMe = 'about-me',
  claims = 'claims',
  customerSupport = 'customer-support',
  liveChat = 'live-chat',
}

export type RoutesMap = {
  [key in LEAGUE_MODULE_NAMES]: string;
};

export const leagueRoutesMap: RoutesMap = {
  [LEAGUE_MODULE_NAMES.assessment]: '/health-profile/questions',
  [LEAGUE_MODULE_NAMES.careCollections]: '/care-collections',
  [LEAGUE_MODULE_NAMES.careDiscovery]: '/get-care',
  [LEAGUE_MODULE_NAMES.careDocuments]: '/care-documents',
  [LEAGUE_MODULE_NAMES.wallet]: '/wallet',
  [LEAGUE_MODULE_NAMES.healthJourney]: '/health-journey',
  [LEAGUE_MODULE_NAMES.healthJourneyHistory]: '/health-history',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/health-need',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/health-profile',
  [LEAGUE_MODULE_NAMES.healthPrograms]: '/health-programs',
  [LEAGUE_MODULE_NAMES.healthProgress]: '/health-progress',
  [LEAGUE_MODULE_NAMES.messaging]: '/messaging',
  [LEAGUE_MODULE_NAMES.onboarding]: '/onboarding',
  [LEAGUE_MODULE_NAMES.rewardsAchievements]: '/achievements',
  [LEAGUE_MODULE_NAMES.familyExperience]: '/personal-care-team',
  [LEAGUE_MODULE_NAMES.aboutMe]: '/about-me',
  [LEAGUE_MODULE_NAMES.claims]: '/claims',
  [LEAGUE_MODULE_NAMES.customerSupport]: '/customer-support',
  [LEAGUE_MODULE_NAMES.liveChat]: '/live-chat',
};
