import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ActivityCircleButton,
  ActivityCircleButtonProps,
} from './activity-circle-button.component';

export const ActivityCloseButton = ({
  css,
  icon = 'tinyClose',
  size = 'small',
  quiet = true,
  priority = 'tertiary',
  ...props
}: ActivityCircleButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <ActivityCircleButton
      size={size}
      quiet={quiet}
      priority={priority}
      icon={icon}
      css={{
        position: 'absolute',
        top: '$oneAndHalf',
        right: '$oneAndHalf',
        padding: '$one',
        ...css,
      }}
      hideLabel
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {formatMessage({ id: 'CLOSE_ACTIVITY' })}
    </ActivityCircleButton>
  );
};
