import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export const IS_TOOLBOX_LINK_READ_QUERY = 'is-toolbox-link-read';

export const useToolboxLinkState = (linkId: string = '') => {
  const queryClient = useQueryClient();
  const { data: isLinkRead } = useQuery(
    [IS_TOOLBOX_LINK_READ_QUERY, linkId],
    () => undefined,
    {
      initialData: false,
      staleTime: Infinity,
    },
  );
  const setIsLinkRead = useCallback(
    (isRead = false) =>
      queryClient.setQueryData([IS_TOOLBOX_LINK_READ_QUERY, linkId], isRead),
    [queryClient, linkId],
  );
  return { isLinkRead, setIsLinkRead };
};
