import React from 'react';
import { type Traits, WidgetType } from '@leagueplatform/masonry-api';
import { PrimaryButton, genesisStyled } from '@leagueplatform/genesis-commons';
import {
  HeadingText,
  UtilityText,
  ParagraphText,
  queryHelpers,
  StackLayout,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import {
  InternalOrExternalLink,
  ConditionalLinkCard,
} from '@leagueplatform/web-common-components';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface TextAndCTACardWidgetProps {
  title: string;
  eyebrow?: string;
  description: string;
  buttonText: string;
  linkUrl: string;
  onCardClick?: (e: React.MouseEvent) => void;
  image?: string;
  imageAltText?: string;
  isChildItem?: Boolean;
  isThinWidth?: Boolean;
  traits?: Traits;
}

export type TextAndCTACardWSNode = MasonryEngineNode<
  WidgetType.TEXT_WITH_CTA_CARD,
  TextAndCTACardWidgetProps
>;

const StyledButton = genesisStyled(PrimaryButton)`
  text-decoration: none !important;
  text-align: center;
`;

export const TextAndCTACardWidget = ({
  title,
  description,
  buttonText,
  linkUrl = '',
  image,
  imageAltText = '',
  isThinWidth = false,
  isChildItem = false,
  onCardClick = () => {},
  eyebrow,
  traits,
}: TextAndCTACardWidgetProps) => {
  const isExternalUrl = isAbsoluteUrl(linkUrl);

  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const showSmallerCard = isMobile || isChildItem || isThinWidth;
  // TODO: Traits will be removed in Masonry 2.0
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <ConditionalLinkCard
      cardStyle={{
        padding: 'oneAndHalf',
        flexDirection: 'column',
        backgroundColor:
          traitItems?.backgroundColor || 'surface.background.tertiary',
      }}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing={{
          '@mobile': '$threeQuarters',
          '@initial': showSmallerCard ? '$threeQuarters' : '$oneAndHalf',
        }}
      >
        <StackLayout orientation="vertical" spacing="$quarter">
          {eyebrow && <UtilityText size="eyebrow">{eyebrow}</UtilityText>}
          <HeadingText level="3" size="sm">
            {title}
          </HeadingText>
          <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
            {description}
          </ParagraphText>
          {!showSmallerCard && (
            <StyledButton
              paddingY="half"
              as={InternalOrExternalLink}
              isExternalUrl={isExternalUrl}
              href={linkUrl}
              marginTop="one"
              onClick={onCardClick}
            >
              {buttonText}
            </StyledButton>
          )}
        </StackLayout>
        {image && (
          <SquareImageContainer
            src={image}
            alt={imageAltText}
            maxWidth="8rem"
            maxWidthDesktop={isChildItem ? '8rem' : '11.2rem'}
          />
        )}
      </StackLayout>
      {showSmallerCard && (
        <StyledButton
          width="100%"
          paddingY="half"
          as={InternalOrExternalLink}
          isExternalUrl={isExternalUrl}
          href={linkUrl}
          marginTop="one"
          onClick={onCardClick}
        >
          {buttonText}
        </StyledButton>
      )}
    </ConditionalLinkCard>
  );
};
