import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { HealthActivityWarning } from '@leagueplatform/health-journey-api';
import {
  Box,
  Button,
  HeadingBar,
  HeadingText,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useActivityLink } from 'pages/health-activity/hooks/use-activity-link.hook';
import { ACTIVITY_MODAL_MAX_WIDTH } from 'pages/health-activity/constants/health-activity-design.constants';
import { HealthActivityContext } from '../../contexts/health-activity-provider.component';

interface ActivityWarningModalProps extends HealthActivityWarning {}

export const ActivityWarningModal: FC<ActivityWarningModalProps> = ({
  title,
  subTitle,
  ctaText,
}) => {
  const { returnToEntrypoint } = useActivityLink();
  const { formatMessage } = useIntl();
  const { healthActivityAnalytics, currentPage } = useContext(
    HealthActivityContext,
  );
  const copy = {
    title: title ?? formatMessage({ id: 'EXIT_ACTIVITY_TITLE' }),
    description: subTitle ?? formatMessage({ id: 'EXIT_ACTIVITY_BODY' }),
    buttonOk: ctaText ?? formatMessage({ id: 'EXIT_ACTIVITY_CTA' }),
    buttonNo: formatMessage({ id: 'CANCEL' }),
  };

  const onConfirm = useCallback(() => {
    healthActivityAnalytics?.sendContinueActivity?.();
    returnToEntrypoint();
  }, [healthActivityAnalytics, returnToEntrypoint]);

  const onContinue = useCallback(() => {
    healthActivityAnalytics?.sendConfirmCloseActivity?.();
  }, [healthActivityAnalytics]);

  useEffect(() => {
    if (healthActivityAnalytics?.sendViewExitModal) {
      healthActivityAnalytics?.sendViewExitModal?.(currentPage + 1);
    }
  }, [healthActivityAnalytics, currentPage]);

  return (
    <Modal.Content
      showCloseButton={false}
      forceModalFocus={false}
      padding={0}
      width={`min(${ACTIVITY_MODAL_MAX_WIDTH}px, 100%)`}
      onInteractOutside={onContinue}
      css={{ '.GDS-modal': { maxWidth: '100%', '@mobile': { width: 'auto' } } }}
    >
      {/* Accessibility */}
      <Modal.Title>{copy.title}</Modal.Title>
      <Modal.Description>{copy.description}</Modal.Description>

      {/* Close Button */}
      <HeadingBar
        css={{ '.GDS-heading-bar-base': { padding: '$half' } }}
        rightAction={
          <Modal.Close>
            <Button
              priority="tertiary"
              quiet
              icon="tinyClose"
              hideLabel
              size="small"
              onClick={onContinue}
            >
              {copy.buttonNo}
            </Button>
          </Modal.Close>
        }
      />

      <Box css={{ padding: '0 $two $three' }}>
        {/* Content */}
        <StackLayout orientation="vertical" spacing="$half">
          <HeadingText level="2" size="xl">
            {copy.title}
          </HeadingText>
          <ParagraphText>{copy.description}</ParagraphText>
        </StackLayout>

        {/* Footer Controls */}
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="end"
          spacing="$oneAndHalf"
          css={{ marginTop: '$oneAndHalf' }}
        >
          <Modal.Close>
            <Button priority="tertiary" quiet onClick={onContinue}>
              {copy.buttonNo}
            </Button>
          </Modal.Close>
          <Button priority="primary" destructive onClick={onConfirm}>
            {copy.buttonOk}
          </Button>
        </StackLayout>
      </Box>
    </Modal.Content>
  );
};
