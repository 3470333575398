import React from 'react';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  BodyOne,
  Box,
  HeadingTwo,
  PrimaryButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { Link, useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import { useGetUserProfilePhotoUrl } from 'hooks/use-get-get-user-profile-photo-url.hook';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { BUTTON_CONTAINER_MAX_WIDTH } from 'constants/challenges.constants';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

const { SIGN_UP_SUCCESS } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { JOIN_TEAM, VIEW_DETAILS, NOT_NOW, GO_TO_JOURNEY } =
  SIGN_UP_ANALYTICS_DETAILS;

interface SignUpModalProps {
  onClose: () => void;
  userCanJoinTeam: boolean;
}

export const SignUpSuccess = ({
  onClose,
  userCanJoinTeam,
}: SignUpModalProps) => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  const userPhotoUrl = useGetUserProfilePhotoUrl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const highFive = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_HIGH_FIVE,
  ) as string;

  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SIGN_UP_SUCCESS,
      detail,
      is_critical_event: true,
    });
  return (
    <ModalContentWithImage
      image={{
        src: userPhotoUrl ?? highFive,
        alt: '',
      }}
      isSuccessScreen
    >
      <Box textAlign={{ _: 'center', tablet: 'left' }}>
        <HeadingTwo as="h3">
          {formatMessage({
            id: 'YOURE_GOOD_TO_GO',
          })}
        </HeadingTwo>
        <BodyOne
          paddingTop={{ _: 'one', tablet: 'oneAndHalf' }}
          paddingBottom={{ _: 'one', tablet: 'oneAndHalf' }}
        >
          {formatMessage({
            id: userCanJoinTeam
              ? 'CHALLENGE_LETS_START_TEAMS_ENABLED'
              : 'CHECK_CHALLENGE_DETAILS',
          })}
        </BodyOne>
        <PrimaryButton
          onClick={() => {
            handleAnalyticsClick(userCanJoinTeam ? JOIN_TEAM : VIEW_DETAILS);
            onClose();
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(userCanJoinTeam && {
            as: Link,
            to: JOURNEY_ROUTES.getChallengeJoinTeam(challengeId),
          })}
          alignSelf="flex-start"
          width="100%"
          maxWidth={BUTTON_CONTAINER_MAX_WIDTH}
          marginTop="one"
          textDecoration="none"
        >
          {formatMessage({
            id: userCanJoinTeam
              ? 'JOIN_OR_CREATE_TEAM'
              : 'VIEW_CHALLENGE_DETAILS',
          })}
        </PrimaryButton>
        {/* change to genesis core styles */}
        <SecondaryButton
          onClick={() => {
            handleAnalyticsClick(userCanJoinTeam ? NOT_NOW : GO_TO_JOURNEY);
            onClose();
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(!userCanJoinTeam && {
            as: Link,
            to: JOURNEY_ROUTES.journeyHome(),
            textDecoration: 'none',
          })}
          marginTop="oneAndHalf"
          width="100%"
          maxWidth={BUTTON_CONTAINER_MAX_WIDTH}
        >
          {formatMessage({
            id: userCanJoinTeam ? 'NOT_NOW' : 'GO_TO_JOURNEY',
          })}
        </SecondaryButton>
      </Box>
    </ModalContentWithImage>
  );
};
