import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StringInputComponentValidation } from '@leagueplatform/health-journey-api';

const useStringInputOptions = (validation: StringInputComponentValidation) => {
  const { formatMessage } = useIntl();
  const {
    required,
    characterLimit,
    customWarning,
    editable = true,
  } = validation || {};

  return useMemo(
    () => ({
      disabled: !editable,
      required: {
        value: required,
        message:
          customWarning ||
          formatMessage({ id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD' }),
      },
      maxLength: {
        value: characterLimit,
        message:
          customWarning ||
          formatMessage(
            { id: 'TOOLBOX_VALIDATION_MAXIMUM_CHARACTERS' },
            { limit: characterLimit },
          ),
      },
    }),
    [editable, required, customWarning, formatMessage, characterLimit],
  );
};

export { useStringInputOptions };
