import React, { FC, forwardRef, isValidElement } from 'react';
import { generateInputHint } from 'components/forms/component-generators';
import { Icon } from 'components/icon/icon';
import { Spinner } from 'components/spinner/spinner';
import { visuallyHiddenStyle } from 'components/visually-hidden/visually-hidden';
import type { GDSSelectableProps, GDSSelectableInputType } from '../../types';
import { styled, GDSColor } from '../../../../theme';
import {
  focusOutlineInner,
  focusOutlineOuter,
} from '../../../../theme/utils/focus-outline';

export interface GDSChoiceButtonProps
  extends Omit<GDSSelectableProps, 'layout'> {
  inputType: GDSSelectableInputType;
}

const ChoiceButtonWrapper = styled('div');

const getStatusCheckedStyle = (token: GDSColor) => ({
  '&:checked + label': {
    backgroundColor: token,
    borderColor: 'transparent',
    transition: 'all $defaultTime ease-in-out, border $defaultTime ease-in-out',
    '&:hover': { backgroundColor: token },
    '&:active': { backgroundColor: token },
  },
});

const disabledStyle = {
  backgroundColor: '$interactiveActionDisabled',
  border: 'transparent',
  color: '$onSurfaceTextSubdued',
  pointerEvents: 'none',
};

const InvisibleInput = styled('input', {
  ...visuallyHiddenStyle,
  // Style the label and Icon as siblings of input
  '&:checked + label': {
    color: '$onSurfaceTextReversed',
    backgroundColor: '$interactiveActionPrimary',
    borderColor: 'transparent',
    transition: 'all $defaultTime ease-in-out, border $defaultTime ease-in-out',
    '&:hover': { backgroundColor: '$interactiveActionHovered' },
    '&:active': { backgroundColor: '$interactiveActionPressed' },
    '.internal-icon': {
      opacity: 1,
      transition:
        'opacity $defaultTime ease-in-out, border $defaultTime ease-in-out',
    },
  },
  '&:disabled + label': disabledStyle,
  '&[aria-disabled="true"] + label': disabledStyle,
  '&[aria-readonly="true"] + label': {
    backgroundColor: '$interactiveActionDisabled',
    border: 'transparent',
    color: '$onSurfaceTextReversed',
  },
  '&:focus + label': {
    borderColor: 'transparent',
    ...focusOutlineInner,
  },
  '&:checked:focus + label': {
    borderColor: 'transparent',
    ...focusOutlineOuter,
    boxShadow: 'none',
  },
  variants: {
    inputStatus: {
      success: getStatusCheckedStyle('$successIcon'),
      warning: getStatusCheckedStyle('$warningIcon'),
      error: getStatusCheckedStyle('$criticalIcon'),
    },
  },
});

const Label = styled('label', {
  tabIndex: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '$threeQuarters',
  paddingBottom: '$threeQuarters',
  paddingRight: '$one',
  paddingLeft: '$one',
  borderRadius: '$inputField',
  border: '$borderWidths$thin solid $inputBorderDefault',
  backgroundColor: '$inputBackgroundDefault',
  color: '$onSurfaceTextPrimary',
  '&:hover': { backgroundColor: '$inputBackgroundHovered' },
  '&:active': { backgroundColor: '$inputBackgroundPressed' },
  variants: {
    inputStatus: {
      success: { borderColor: '$inputBorderSuccess' },
      warning: { borderColor: '$inputBorderWarning' },
      error: { borderColor: '$inputBorderCritical' },
    },
    disabled: {
      false: {},
      true: disabledStyle,
    },
    'aria-disabled': {
      false: {},
      true: disabledStyle,
    },
  },
});

const LabelText = styled('span', {
  typography: '$buttonTwo',
});

export const ChoiceButton: FC<GDSChoiceButtonProps> = forwardRef(
  (
    {
      label,
      id,
      name,
      inputType,
      checked,
      required,
      hint,
      inputStatus,
      disabled,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      value,
      onClick,
      onChange,
      onBlur,
      onFocus,
      loading,
      css,
      ...props
    }: GDSChoiceButtonProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    if (!inputType) {
      console.warn(
        'No "inputType" prop detected. Please pass either "checkbox" or "radio".',
      );
      return null;
    }

    const hintId = `${id}-hint`;
    const ariaDescribedbyArray: string[] = [];
    if (hint) ariaDescribedbyArray.push(hintId);
    if (ariaDescribedby) ariaDescribedbyArray.push(ariaDescribedby);

    return (
      <ChoiceButtonWrapper className="GDS-choicebutton" css={css}>
        <InvisibleInput
          type={inputType}
          id={id}
          name={name}
          checked={checked}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          {...(ariaDescribedbyArray.length > 0 && {
            'aria-describedby': ariaDescribedbyArray.join(' '),
          })}
          inputStatus={inputStatus}
          value={value}
          {...(inputStatus === 'error' && { 'aria-invalid': true })}
          ref={ref}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        />
        <Label
          className="GDS-choicebutton-label"
          htmlFor={id}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          inputStatus={inputStatus}
        >
          {isValidElement(label) ? (
            label
          ) : (
            <LabelText className="GDS-choicebutton-label-text">
              {label}
            </LabelText>
          )}
          {loading ? (
            <Spinner loading={loading} css={{ marginLeft: '$one' }} />
          ) : (
            <Icon
              className="internal-icon"
              icon="interfaceCheckmark"
              size={20}
              css={{ opacity: 0, marginLeft: '$half', minWidth: 20 }}
            />
          )}
        </Label>
        {hint &&
          generateInputHint({ hint, css: { marginTop: '$half' }, hintId })}
      </ChoiceButtonWrapper>
    );
  },
);

ChoiceButton.displayName = 'ChoiceButton';
