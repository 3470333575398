import {
  getMasonryRootNode,
  type GetMasonryRootNodeConfigType,
} from '@leagueplatform/masonry-api';
import {
  type MasonryEngineDriver,
  type MasonryEngineNode,
  createMasonryEngineActionController,
} from '@leagueplatform/masonry-engine';
import type { MasonryDriverAction } from '@leagueplatform/masonry-widgets';
import { MasonryDriverNodeRenderersRegistry } from './masonry-driver-node-renderers-registry';
import { masonryDriverActionHandlerMap } from './masonry-driver-action-interceptors-map';

type ArbitraryMasonryEngineNode = MasonryEngineNode<string, any, any>;

export const MasonryDriverActionController =
  createMasonryEngineActionController<MasonryDriverAction>(
    masonryDriverActionHandlerMap,
  );

export const getMasonryDriver =
  ({
    rootNodeId,
  }: GetMasonryRootNodeConfigType): MasonryEngineDriver<
    ArbitraryMasonryEngineNode,
    MasonryDriverAction
  > =>
  ({ children }) =>
    children(
      getMasonryRootNode({ rootNodeId }),
      MasonryDriverNodeRenderersRegistry.getNodeRenderersMap(),
      MasonryDriverActionController,
    );
