import { HEALTH_JOURNEY_MASONRY_VERSION } from '@leagueplatform/health-journey-common';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { Carousel, SkeletonBox } from '@leagueplatform/web-common-components';
import React from 'react';
import { RewardProgramCarousel } from 'types/configurable-health-program.types';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { RewardsProgramProgressCard } from '../rewards-program-card/rewards-program-card.component';

type RewardProgramCardProps = {
  widget: ContainerWidgetItem;
};

export const RewardsProgramCardCarouselWidget = ({
  widget,
}: RewardProgramCardProps) => {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<RewardProgramCarousel>(
    widget,
    HEALTH_JOURNEY_MASONRY_VERSION,
  );

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error || !widgetData || !widgetData?.attributes) {
    return null;
  }

  const { items, heading } = widgetData.attributes;

  const WidgetHeader = (
    <HeadingText
      level="2"
      size={{
        '@initial': 'xl',
        '@mobile': 'lg',
      }}
    >
      {heading}
    </HeadingText>
  );

  return (
    <>
      {items.length >= 2 && (
        <Carousel
          heading={WidgetHeader}
          name={heading}
          spaceBetweenCards="oneAndHalf"
          titleMarginBottom="one"
        >
          {items.map(
            ({
              attributes: {
                badge,
                cta,
                image,
                imageAltText,
                items: cardItems,
                heading: cardHeading,
              },
            }) => (
              <StackLayout
                css={{
                  width: '384px',
                  '@mobile': {
                    width: '320px',
                  },
                }}
              >
                <RewardsProgramProgressCard
                  contentTextAlign="top"
                  imagePosition="top"
                  badge={badge}
                  cta={cta}
                  heading={cardHeading}
                  image={image}
                  imageAltText={imageAltText}
                  items={cardItems}
                />
              </StackLayout>
            ),
          )}
        </Carousel>
      )}
      {items.length === 1 && (
        <StackLayout spacing="$one">
          {WidgetHeader}
          {items.map((item) => (
            <RewardsProgramProgressCard
              badge={item.attributes.badge}
              cta={item.attributes.cta}
              heading={item.attributes.heading}
              image={item.attributes.image}
              imageAltText={item.attributes.imageAltText}
              items={item.attributes.items}
            />
          ))}
        </StackLayout>
      )}
    </>
  );
};
