/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef } from 'react';
import {
  ObservabilityErrorBoundary,
  captureError,
} from '@leagueplatform/observability';
import { useQuery } from 'react-query';
import { useMasonryEngineConfig } from '../masonry-engine-config-context';
import type {
  MasonryEngineNode,
  MasonryEngineNodeAction,
  MasonryEngineNodeRenderer,
  MasonryEngineNodeRendererProps,
} from '../types/masonry-engine-node.types';
import { logError } from '../utils/log';
import { getMasonryEngineObservabilityContext } from '../utils/masonry-engine-observability-content';
import { MasonryEngineOriginProvider } from '../masonry-engine-origin-context';
import { MasonryEngineStateControllerContext } from '../masonry-engine-state-controller-context';
import { useMasonryEngineActionEmitter } from '../masonry-engine-action-controller';
import { MasonryEngineLevelActionNames } from '../constants/constants';

const QUERY_KEY_GET_NODE_ASYNC_SELF = 'MASONRY_ENGINE_GET_NODE_ASYNC_SELF';

type NodeResolverProps<Node extends MasonryEngineNode> = {
  node: Node;
};

/**
 * Given a {@link MasonryEngineNode `MasonryEngineNode`} and a {@link MasonryEngineRendererMap `MasonryEngineRendererMap`}, executes the node's
 * data query and passes the results to the appropriate renderer when ready.
 */
export const NodeResolver = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>({
  node: originalNode,
}: NodeResolverProps<Node>) => {
  const { nodeRenderers } = useMasonryEngineConfig<Node, Action>();
  const { state } = useContext(MasonryEngineStateControllerContext);
  const invokeAction = useMasonryEngineActionEmitter();

  const { hide } = state?.[originalNode?.id] || {};
  const {
    isSuccess,
    error,
    data: node,
  } = useQuery(
    [QUERY_KEY_GET_NODE_ASYNC_SELF, originalNode.id],
    async () => originalNode.getAsyncSelf?.() || originalNode,
    {
      initialData: originalNode,
    },
  );

  const hasInvokedEngineActionsRef = useRef(false);

  const actions = node?.actions as
    | Record<string, MasonryEngineNodeAction>
    | undefined;

  useEffect(() => {
    if (!hasInvokedEngineActionsRef.current && actions?.onLoad) {
      invokeAction(actions.onLoad);
      hasInvokedEngineActionsRef.current = true;
    }
  }, [actions?.onLoad, invokeAction]);

  if (error) {
    logError(
      `Error while making api call for node id ${originalNode.id} ${error}`,
    );
    captureError(
      new Error(
        `MasonryEngine - Error while making api call for node id ${originalNode.id} ${error}`,
      ),
      getMasonryEngineObservabilityContext({}),
    );
    return null;
  }

  if (!isSuccess || hide) return null;

  const nodeNamespace = node.namespace || 'default';

  const nodeRendererMapForNamespace = nodeRenderers[nodeNamespace];

  const NodeRenderer = nodeRendererMapForNamespace?.[
    node.type as keyof typeof nodeRendererMapForNamespace
  ] as MasonryEngineNodeRenderer<typeof node> | undefined;

  if (!NodeRenderer) {
    logError(
      `Could not find renderer for namespace - ${nodeNamespace} & node type - ${node.type} !`,
    );
    captureError(
      new Error(
        `MasonryEngine - Could not find renderer for namespace - ${nodeNamespace} & node type - ${node.type} !`,
      ),
      getMasonryEngineObservabilityContext({}),
    );
    return null;
  }

  const resolvedSections = node.sections
    ? Object.fromEntries(
        Object.entries(node.sections).map(([section, subnodes]) => [
          section,
          (subnodes as Array<MasonryEngineNode>).map((subnode) => (
            <NodeResolver key={subnode.id} node={subnode} />
          )),
        ]),
      )
    : {};

  /* Filter out Masonry Engine specific actions and pass rest of
     the actions to the node renderer. */
  const filteredNodeActions = node.actions
    ? Object.fromEntries(
        Object.entries(node.actions).filter(
          ([key]) => !MasonryEngineLevelActionNames.includes(key),
        ),
      )
    : {};

  // Needed to merge properties from original node and properties from api
  // response of node in order to share properties passed by the parent node
  const nodeRendererProps: MasonryEngineNodeRendererProps<typeof node> = {
    ...originalNode?.properties,
    ...node?.properties,
    ...resolvedSections,
    ...filteredNodeActions,
  };

  return (
    <MasonryEngineOriginProvider nodeId={node.id || originalNode.id}>
      <ObservabilityErrorBoundary
        errorContext={getMasonryEngineObservabilityContext({
          tags: {
            errorName: `MasonryEngine - Error while rendering Masonry Engine Node id: ${originalNode.id}`,
          },
        })}
        onError={(err: Error) => {
          logError(
            `Error while rendering Masonry Engine Node id: ${originalNode.id} ${err.message}`,
          );
        }}
      >
        <NodeRenderer {...nodeRendererProps} />
      </ObservabilityErrorBoundary>
    </MasonryEngineOriginProvider>
  );
};
