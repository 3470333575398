import { focusOutlineInner } from '../../theme/utils/focus-outline';

export const inputBorderStyles = {
  border: '$borderWidths$thin solid $interactiveBorderDefault',
  borderStartStartRadius: '$medium',
  borderEndStartRadius: '$medium',
  borderStartEndRadius: '$medium',
  borderEndEndRadius: '$medium',
};
export const inputStyles = {
  ...inputBorderStyles,
  padding: '$threeQuarters $one',
  flex: '1 0 auto',
  '&:focus, &[class*="GDS"]:focus': {
    borderColor: '$interactiveFocusInner',
    ...focusOutlineInner,
  },
};

export const affixedStyles = {
  '&.GDS-affixed': {
    ...inputStyles,
    '&:hover': {
      borderColor: '$inputBorderHovered',
      backgroundColor: '$inputBackgroundHovered',
      input: {
        borderColor: '$inputBorderHovered',
        backgroundColor: '$inputBackgroundHovered',
      },
    },
    input: {
      border: 'none',
      padding: '0',
    },
    '[class*="GDS"]:not(button):focus, &:focus-within:has(button:focus)': {
      outline: 'none',
      boxShadow: 'none',
      borderColor: '$interactiveBorderDefault',
    },
    '&:focus-within': {
      borderColor: '$interactiveFocusInner',
      ...focusOutlineInner,
    },
    // override nested .GDS-affixed styles when BaseInput is rendered within TextInput or any other wrapper that uses .GDS-affixed styles
    '& .GDS-affixed-base': {
      border: 'none',
      padding: '0',
      input: {
        '&:focus': {
          outline: 'none',
          boxShadow: 'none',
        },
      },
      '&:focus-within, &:focus': {
        borderColor: '$interactiveFocusInner',
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
};

export const affixedBaseInputStyles = {
  '&.GDS-affixed-base': {
    ...inputStyles,
    '&:hover': {
      borderColor: '$inputBorderHovered',
      backgroundColor: '$inputBackgroundHovered',
      input: {
        borderColor: '$inputBorderHovered',
        backgroundColor: '$inputBackgroundHovered',
      },
    },
    input: {
      border: 'none',
      padding: '0',
    },
    '[class*="GDS"]:not(button):focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    '&:focus-within': {
      borderColor: '$interactiveFocusInner',
      ...focusOutlineInner,
    },
    '&:focus-within:has(button:focus)': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
};

export const disabledInputStyles = {
  backgroundColor: '$inputBackgroundDisabled',
  color: '$onSurfaceTextSubdued',
  borderColor: '$onSurfaceTextSubdued',
};

export const readOnlyInputStyles = {
  backgroundColor: '$inputBackgroundDisabled',
};
