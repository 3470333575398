import { WidgetType } from '@leagueplatform/masonry-api';
import { MasonryEngineNodeRendererMap } from '@leagueplatform/masonry-engine';
import {
  LayoutWSRenderer,
  MasonryCarouselRenderer,
  MasonryColumnLayoutRenderer,
  MasonryCTACardRenderer,
  MasonryDriverNode,
  MasonryExtraSmallSkeletonRenderer,
  MasonryHeroBannerRenderer,
  MasonryHeroBannerSkeletonRenderer,
  MasonryImageFooterRenderer,
  MasonryLargeCardRenderer,
  MasonryLargeSkeletonRenderer,
  MasonryMetricCardRenderer,
  MasonryNotificationBannerRenderer,
  MasonryNotificationCardRenderer,
  MasonryProgressBarCardRenderer,
  MasonryProgressRingCardRenderer,
  MasonrySectionHeaderRenderer,
  MasonrySmallCardRenderer,
  MasonrySmallSkeletonRenderer,
  MasonryXSCardRenderer,
} from '@leagueplatform/masonry-widgets';
import {
  MasonrySpinner,
  MasonrySpinnerNode,
} from 'components/spinner/spinner.component';

export const MasonryDriverDefaultNodeRendererMap: MasonryEngineNodeRendererMap<
  MasonryDriverNode | MasonrySpinnerNode
> = {
  [WidgetType.TEXT_WITH_CTA_CARD]: MasonryCTACardRenderer,
  [WidgetType.LARGE_VISUAL_CARD]: MasonryLargeCardRenderer,
  [WidgetType.SMALL_VISUAL_CARD]: MasonrySmallCardRenderer,
  // @TODO replace with new Masonry Carousel Renderer
  [WidgetType.GENERIC_CAROUSEL]: MasonryCarouselRenderer,
  // @TODO replace with new Stack Renderers
  // @ts-expect-error
  [WidgetType.STACK]: LayoutWSRenderer,
  [WidgetType.HERO_BANNER]: MasonryHeroBannerRenderer,
  [WidgetType.XS_VISUAL_CARD]: MasonryXSCardRenderer,
  [WidgetType.NOTIFICATION_BANNER]: MasonryNotificationBannerRenderer,
  [WidgetType.NOTIFICATION_CARD]: MasonryNotificationCardRenderer,
  [WidgetType.SECTION_HEADER]: MasonrySectionHeaderRenderer,
  [WidgetType.PROGRESS_RING]: MasonryProgressRingCardRenderer,
  [WidgetType.PROGRESS_BAR]: MasonryProgressBarCardRenderer,
  // @TODO integrate 2 up card with new type
  // [WidgetType.TWO_UP_CARD]: TwoUpCardWSRenderer,
  [WidgetType.IMAGE_FOOTER]: MasonryImageFooterRenderer,
  [WidgetType.METRIC_CARD]: MasonryMetricCardRenderer,
  [WidgetType.HIDDEN]: () => null,
  // @TODO fix naming of column layout type
  column_layout: MasonryColumnLayoutRenderer,
  loadingSmall: MasonrySmallSkeletonRenderer,
  loadingLarge: MasonryLargeSkeletonRenderer,
  loadingExtraSmall: MasonryExtraSmallSkeletonRenderer,
  loadingHeroBanner: MasonryHeroBannerSkeletonRenderer,
  containerSpinner: MasonrySpinner,
};
