import React from 'react';
import { Link } from '@leagueplatform/routing';
import { node, func, string, oneOfType } from 'prop-types';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { BackButtonArrow } from '@leagueplatform/web-common-components';

export const ActivityDetailsWrapper = ({
  to,
  onBackClick,
  children,
  ...rest
}) => (
  <Flex
    flexDirection={{ _: 'column', phone: 'row' }}
    marginX="auto"
    marginTop="oneAndHalf"
    paddingBottom={{ _: '150px', phone: '120px' }}
    width={{ _: '90%', tablet: '70%' }}
    justifyContent={{ _: 'unset', phone: 'center' }}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...rest}
  >
    <Box>
      <BackButtonArrow
        as={Link}
        to={to}
        onClick={onBackClick}
        marginY="threeQuarters"
        marginRight="two"
      />
    </Box>
    <Box width="100%">{children}</Box>
  </Flex>
);

ActivityDetailsWrapper.propTypes = {
  to: oneOfType([func, string]).isRequired,
  onBackClick: func,
  children: node,
};

ActivityDetailsWrapper.defaultProps = {
  children: [],
  onBackClick: () => undefined,
};
