import React, { FC } from 'react';
import { ToolboxComponentProps } from 'pages/health-activity/types/health-activity.types';
import { HeaderAttributes } from '@leagueplatform/health-journey-api';
import { styled, Box, UtilityText } from '@leagueplatform/genesis-core';
import { CircleProgress } from '../progress/circle-progress.component';

interface ActivityToolboxHeaderProps
  extends HeaderAttributes,
    ToolboxComponentProps {}

const ImageContainer = styled(Box, {
  '& + *': {
    zIndex: 1,
    backgroundColor: '$surfaceBackgroundPrimary !important',
    borderTopLeftRadius: '$large !important',
    borderTopRightRadius: '$large !important',
    marginTop: '$minusTwoAndHalf !important',
    paddingTop: '$two !important',
  },
});

const HeaderImage = styled(Box, {
  position: 'relative',
  padding: '$two',
  '&:after': {
    content: '',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,.8) , rgba(0,0,0,0))',
  },
});

export const ActivityToolboxHeader: FC<ActivityToolboxHeaderProps> = ({
  imageUrl,
  alt = '',
  campaignName,
  progressPercentage,
  componentId,
  css,
}) => (
  <ImageContainer data-testid={componentId} css={{ css }}>
    <HeaderImage
      role="img"
      aria-label={alt}
      css={{
        ariaLabel: alt,
        background: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% center',
        aspectRatio: ' 480 / 212',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$one',
          paddingBottom: '$threeQuarters',
          zIndex: '1',
        }}
      >
        {progressPercentage >= 0 && progressPercentage !== null && (
          <CircleProgress size={50} percent={progressPercentage}>
            <UtilityText
              css={{
                typography: '$overline',
                position: 'absolute',
                color: '$onSurfaceTextReversed',
              }}
            >
              {progressPercentage}%
            </UtilityText>
          </CircleProgress>
        )}
        {campaignName && (
          <UtilityText
            css={{
              typography: '$overline',
              textTransform: 'uppercase',
              color: '$onSurfaceTextReversed',
            }}
          >
            {campaignName}
          </UtilityText>
        )}
      </Box>
    </HeaderImage>
  </ImageContainer>
);
