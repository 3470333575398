import React, { FC } from 'react';
import { keyframes } from '@stitches/react';
import { styled } from '../../theme';
import type { GDSSize, GDSBorderRadius } from '../../theme';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSHTMLAttributes,
} from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';

export interface GDSSkeletonBaseProps
  extends GDSCustomizableComponent,
    GDSHTMLAttributes {
  height?: GDSResponsiveProp<number | string | GDSSize>;
  width?: GDSResponsiveProp<number | string | GDSSize>;
  borderRadius?: GDSBorderRadius;
}

const slide = keyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

const BaseSkeleton = styled('div', {
  backgroundColor: '#E7E7E7', // $onSurfaceShimmerBackground
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',

  '&:after': {
    content: '',
    top: 0,
    transform: 'translateX(100%)',
    width: ' 100%',
    height: '100%',
    position: 'absolute',
    animation: `${slide} 2s infinite`,
    backgroundImage:
      'linear-gradient( to right, transparent 0%, rgba(255, 255, 255, 0.9) 50%, transparent 99%, transparent 100%)', // $onSurfaceShimmerHighlight
  },
});

export const SkeletonBase: FC<GDSSkeletonBaseProps> = ({
  height = '100%',
  width,
  borderRadius = '$small',
  css,
  ...props
}) => {
  const responsiveWidth = useResponsiveProp(width);
  const responsiveHeight = useResponsiveProp(height);

  return (
    <BaseSkeleton
      aria-hidden="true"
      css={{
        width: responsiveWidth,
        height: responsiveHeight,
        borderRadius,
        ...css,
      }}
      {...props}
    />
  );
};
