import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useState,
} from 'react';
import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormRegister,
  FormProvider,
} from '@leagueplatform/web-common';
import { GDSStyleObject } from '@leagueplatform/genesis-core';
import { ToolboxComponentMap } from '../types/health-activity.types';
import { toolboxComponentPayloadTransformer } from '../utils/toolbox-component-payload-transformer.utils';
import { ToolboxComponentContext } from './toolbox-component-provider.component';
import {
  ToolboxGetInputComponents,
  ToolboxOnFormRegister,
} from '../types/toolbox.types';

// Types
export interface ToolboxInputProviderProps {
  onFormRegister?: ToolboxOnFormRegister;
  formConfig?: UseFormProps;
}

// Constants
const defaultFormConfig: UseFormProps = {
  mode: 'onChange',
  shouldFocusError: true,
};

const formStyles: GDSStyleObject = {
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '$one',
};

export const ToolboxInputProvider: FC<ToolboxInputProviderProps> = ({
  children,
  onFormRegister,
  formConfig = defaultFormConfig,
}) => {
  const { toolboxComponentMap } = useContext(ToolboxComponentContext);
  const { register, control, ...formMethods } = useForm(formConfig);
  const {
    getValues,
    formState: { dirtyFields },
  } = formMethods;

  const inputMap: ToolboxComponentMap = useMemo(() => ({}), []);
  const [includesFormElement, setIncludesFormElement] = useState<boolean>();

  const registerWrapper: UseFormRegister<FieldValues> = useCallback(
    (name, options = {}) => {
      inputMap[name] = toolboxComponentMap[name];
      return register(name, options);
    },
    [inputMap, toolboxComponentMap, register],
  );

  useEffect(() => {
    const hasInputs = Boolean(Object.keys(inputMap).length);
    setIncludesFormElement(hasInputs);
  }, [inputMap]);

  /** Construct an object of only the dirty fields and their value */
  const getDirtyFieldValues = useCallback(
    () =>
      Object.keys(dirtyFields).reduce(
        (fields, fieldName) => ({
          ...fields,
          [fieldName]: getValues(fieldName),
        }),
        {},
      ),
    [dirtyFields, getValues],
  );

  const getInputComponents: ToolboxGetInputComponents = useCallback(
    ({ includeCleanFields } = {}) => {
      const formValues = includeCleanFields
        ? getValues()
        : getDirtyFieldValues();

      /** Get input components from answers field name/value pairs. Consider components with _non-null_ values as valid */
      const inputs = Object.entries(formValues)
        .filter(
          ([inputName, inputValue]) =>
            inputValue !== null && inputMap[inputName],
        )
        .map(([inputName]) =>
          toolboxComponentPayloadTransformer(inputMap[inputName]),
        );

      return inputs;
    },
    [getDirtyFieldValues, getValues, inputMap],
  );

  useEffect(() => {
    if (onFormRegister && includesFormElement) {
      onFormRegister({
        ...formMethods,
        register: registerWrapper,
        control: { ...control, register: registerWrapper },
        getInputComponents,
      });
    }
  }, [
    formMethods,
    getInputComponents,
    onFormRegister,
    registerWrapper,
    includesFormElement,
    control,
  ]);

  return (
    <FormProvider
      formMethods={{
        ...formMethods,
        register: registerWrapper,
        control: { ...control, register: registerWrapper },
      }}
      includesFormElement={includesFormElement}
      css={formStyles}
    >
      {children}
    </FormProvider>
  );
};
