import React from 'react';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import { Activity, UnitTypes } from 'constants/types';
import {
  PointsIcon,
  ChallengeTokenIcon,
  RewardPointsIcon,
} from '@leagueplatform/rewards-common';
import { CloseButton } from 'components/close-button/close-button.view';
import { Badge } from '@leagueplatform/web-common-components';
import {
  Box,
  UtilityText,
  keyframes,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface ToastPointsProps {
  title?: string;
  points: number;
  unit: string;
  activities: Activity[];
  onlyPoints: boolean;
  onClose: Function;
}

const slideLeftAnimation = keyframes({
  from: {
    transform: 'translateX(-50px)',
    opacity: 0,
  },
  to: {
    transform: 'translateX(0px)',
    opacity: 1,
  },
});

const PointsWrapper = styled(Box, {
  display: 'flex',
  height: '28px',
  animationTimingFunction: 'ease-out',
  animationDuration: '0.45s',
  animationDelay: '0.45s',
  animationFillMode: 'forwards',

  variants: {
    animationKey: {
      reduceMotion: {
        opacity: 1,
        animationName: 'none',
      },
      slideLeft: {
        opacity: 0,
        animationName: `${slideLeftAnimation}`,
      },
    },
  },
});

const Flex = styled(Box, {
  display: 'flex',
});

export const ToastPoints = ({
  onlyPoints,
  title,
  points,
  onClose,
  activities,
  unit,
}: ToastPointsProps) => {
  const preferredReducedMotion = usePrefersReducedMotion();
  const { formatMessage } = useIntl();

  return (
    <Flex
      css={{
        borderRadius: onlyPoints ? '$large' : '$large $large $none $none',
        backgroundColor: '$decorativeBrandSecondaryBrightest',
        width: '330px',
        minHeight: '48px',
        boxShadow: '$card',
        padding: '$half $one',
      }}
    >
      <Flex
        css={{
          gap: '$half',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          flexGrow: 1,
        }}
      >
        <UtilityText
          size="sm"
          emphasis="emphasized"
          css={{ color: '$onSurfaceTextPrimary' }}
        >
          {title}
        </UtilityText>
        {Boolean(points) && (
          <PointsWrapper
            animationKey={preferredReducedMotion ? 'reduceMotion' : 'slideLeft'}
            css={{ animationFillMode: 'forwards' }}
          >
            <Badge css={{ backgroundColor: '$decorativeBrandSecondaryPastel' }}>
              {unit === UnitTypes.LEAGUE_POINTS && (
                <PointsIcon value={points} />
              )}
              {unit === UnitTypes.TOKENS && (
                <ChallengeTokenIcon value={points} />
              )}
              {unit === UnitTypes.REWARD_POINTS && (
                <RewardPointsIcon
                  value={points}
                  pointsText={formatMessage({ id: 'REWARD_POINTS' })}
                />
              )}
            </Badge>
          </PointsWrapper>
        )}
      </Flex>
      <CloseButton onClose={onClose} activitiesLength={activities.length} />
    </Flex>
  );
};
