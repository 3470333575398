import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Tabs } from '@leagueplatform/genesis-core';
import { CHALLENGE_TYPES } from '@leagueplatform/health-journey-common';

export const AllChallengesByTypeTabs = () => {
  const { formatMessage } = useIntl();

  return (
    <Tabs.TabList divider tabDistribution="hugContents">
      {CHALLENGE_TYPES.map((challengeType) => (
        <Tabs.Tab
          key={`${challengeType}_tab`}
          value={challengeType}
          css={{ padding: '$threeQuarters $oneAndHalf' }}
        >
          {formatMessage({
            id: `CHALLENGE_TYPE_${challengeType.toUpperCase()}_TAG`,
          })}
        </Tabs.Tab>
      ))}
    </Tabs.TabList>
  );
};
