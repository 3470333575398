import * as React from 'react';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { NETWORK_ANALYTICS_ID } from 'pages/wallet-detail/types/network-analytics-ids';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { useGetUserBenefitLiveBalancesQuery } from './queries/use-get-user-benefit-live-balances-query.hook';
import { NETWORK_PLAN_ID, NetworkPlanV2 } from '../types/plan-progress.types';
import { formatPlansArray } from '../utils/format-plans-array.util';

export const usePlanProgress = (
  userId: string | undefined,
  benefitId: string,
  version?: number,
) => {
  const { data: benefitName = '' } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: (data) => data.full_name,
    },
  );

  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  const { formatMessage } = useIntl();

  const [member] = useSelectedMember();

  const {
    data: {
      accumulation_period: accumulationPeriod,
      carrier_name: carrierName,
      in_network: inNetwork,
      last_updated: lastUpdated,
      no_network: noNetwork,
      out_of_network: outOfNetwork,
    } = {},
    isError,
    isLoading,
    refetch,
  } = useGetUserBenefitLiveBalancesQuery(
    userId,
    benefitId,
    benefitName,
    member?.id,
    version,
  );
  const isNoNetworkUser = !!noNetwork;
  // No network plan returns an array of 1 element
  // so that we can use the same display logic
  const noNetworkPlan: NetworkPlanV2[] = React.useMemo(
    () =>
      formatPlansArray([
        {
          id: NETWORK_PLAN_ID.NO_NETWORK,
          analyticsId: NETWORK_ANALYTICS_ID.NO_NETWORK,
          network: noNetwork,
        },
      ]),
    [noNetwork],
  );

  // Network plans have a set order of: in network, out of network
  const networkPlans: NetworkPlanV2[] = React.useMemo(
    () =>
      formatPlansArray([
        {
          id: NETWORK_PLAN_ID.IN_NETWORK,
          analyticsId: NETWORK_ANALYTICS_ID.IN_NETWORK,
          network: inNetwork,
          networkName: formatMessage({ id: NETWORK_PLAN_ID.IN_NETWORK }),
        },
        {
          analyticsId: NETWORK_ANALYTICS_ID.OUT_OF_NETWORK,
          id: NETWORK_PLAN_ID.OUT_OF_NETWORK,
          network: outOfNetwork,
          networkName: formatMessage({ id: NETWORK_PLAN_ID.OUT_OF_NETWORK }),
        },
      ]),
    [formatMessage, inNetwork, outOfNetwork],
  );

  const onTabClickEvent = React.useCallback(
    (tabName: NETWORK_ANALYTICS_ID) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
        detail: tabName,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  const onAccordionToggleEvent = React.useCallback(
    (
      accordionName: string,
      state: 'expand' | 'collapse',
      category?: string,
    ) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `toggle ${accordionName} accordion`,
        sub_detail: state,
        text_detail: category,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  return {
    accumulationPeriod,
    carrierName,
    isError,
    isLoading,
    isNoNetworkUser,
    lastUpdated,
    onAccordionToggleEvent,
    onTabClickEvent,
    plans: isNoNetworkUser ? noNetworkPlan : networkPlans,
    refetch,
  };
};
