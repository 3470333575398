import { HealthDevice } from '@leagueplatform/dashboard-api';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';

type DataSourceConfig = {
  source: string;
  icon: string;
};

export const dataSourceConfigMap = new Map<HealthDevice, DataSourceConfig>([
  // ['biotel_care', { source: 'BIOTEL_CARE', icon: DASHBOARD_ASSET_MAP.DASHBOARD_BIO_TEL_CARE }],
  // ['iglucose', { source: 'IGLUCOSE', icon: DASHBOARD_ASSET_MAP.DASHBOARD_I_GLUCOSE }],
  // ['lose_it', { source: 'LOSE_IT', icon: DASHBOARD_ASSET_MAP.DASHBOARD_LOSE_IT }],
  // ['misfit', { source: 'MISFIT', icon: DASHBOARD_ASSET_MAP.DASHBOARD_MISFIT }],
  [
    'apple_health',
    {
      source: 'APPLE_HEALTH',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_APPLE_HEALTH,
    },
  ],
  // ['bluetooth', { source: 'BLUETOOTH', icon: (DASHBOARD_ASSET_MAP.DASHBOARD_BLUETOOTH},
  [
    'fitbit',
    {
      source: 'FITBIT',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_FITBIT,
    },
  ],
  [
    'garmin',
    {
      source: 'GARMIN',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_GARMIN,
    },
  ],
  [
    'google_fit_sdk',
    {
      source: 'GOOGLE_FIT',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_GOOGLE_FIT,
    },
  ],
  [
    'ihealth',
    {
      source: 'IHEALTH',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_I_HEALTH,
    },
  ],
  [
    'omron',
    {
      source: 'OMRON',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_OMRON,
    },
  ],
  [
    'polar',
    {
      source: 'POLAR',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_POLAR,
    },
  ],
  [
    'samsung_health',
    {
      source: 'SAMSUNG_HEALTH',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_SAMSUNG_HEALTH,
    },
  ],
  [
    'strava',
    {
      source: 'STRAVA',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_STRAVA,
    },
  ],
  // ['vital_snap', { source: 'VITAL_SNAP', icon: DASHBOARD_ASSET_MAP.DASHBOARD_VITAL_SNAP }],
  [
    'withings',
    {
      source: 'WITHINGS',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_WITHINGS,
    },
  ],
  [
    'health_plan',
    {
      source: 'HEALTH_PLAN',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_HEALTH_PLAN,
    },
  ],
  [
    'no_source_data',
    {
      source: 'NO_DATA_SOURCES',
      icon: DASHBOARD_ASSET_MAP.DASHBOARD_NO_DATA_SOURCE,
    },
  ],
  // ['underarmour', { source: 'UNDERARMOUR', icon: DASHBOARD_ASSET_MAP.DASHBOARD_UNDER_ARMOUR }],
  // ['yoo_fitness', { source: 'YOO_FITNESS', icon: DASHBOARD_ASSET_MAP.DASHBOARD_YOO }],
]);
