import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  HeadingFour,
  HeadingOne,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import { VeryQuietButton } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { ICONS, Icon } from '@leagueplatform/ui-kit';
import { getDiffOfDates } from '../utils/user-health-activities/user-health-activities.utils';

const todayUnix = Date.now();

const buttonStyles = {
  borderRadius: 'circle',
  height: '2em',
  paddingLeft: '0',
  paddingRight: '0',
  width: '2em',
};

const iconBoxStyles = {
  as: 'span',
  display: 'block',
  marginTop: '-0.225em',
};

export const NavigateByDate = ({
  currentDate,
  disableNext,
  disablePrev,
  onChangeDate,
  todaysDate,
  ...props
}) => {
  const { formatDate, formatMessage } = useIntl();
  const todaysDateObj = new Date(todaysDate);
  const currentDateObj = new Date(currentDate || todaysDate);

  const isNotToday = getDiffOfDates(todaysDateObj, currentDateObj) !== 0;

  const formatDateMonthAndDay = () =>
    formatDate(currentDateObj, {
      month: 'long',
      day: 'numeric',
    });

  const formatDateWeekday = () => {
    switch (getDiffOfDates(todaysDateObj, currentDateObj)) {
      case 0:
        return formatMessage({ id: 'TODAY' });
      case 1:
        return formatMessage({ id: 'TOMORROW' });
      case -1:
        return formatMessage({ id: 'YESTERDAY' });
      default:
        return formatDate(currentDateObj, {
          weekday: 'long',
        });
    }
  };

  const selectDate = (incr = 0) => {
    const changeDatePayload = {
      date: todaysDate,
      incr,
    };

    if (incr) {
      const requestDate = new Date(currentDate || todaysDate);
      requestDate.setDate(currentDateObj.getDate() + incr);
      changeDatePayload.date = requestDate.getTime();
    }

    onChangeDate(changeDatePayload);
  };

  return (
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      justifyContent="space-between"
      marginX="auto"
      maxWidth="40rem"
      textAlign="center"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <Box
        as="header"
        flexGrow="1"
        flexShrink="0"
        marginX="oneAndHalf"
        order="2"
      >
        <HeadingFour as="p" color="onSurface.text.subdued" marginBottom="half">
          {formatDateWeekday().toLocaleUpperCase()}
        </HeadingFour>
        <HeadingOne as="h2">{formatDateMonthAndDay()}</HeadingOne>
      </Box>
      <Box order="1" marginBottom="quarter">
        <SubtleButton
          aria-label={formatMessage({ id: 'PREVIOUS_DAY' })}
          disabled={disablePrev}
          onClick={() => selectDate(-1)}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...buttonStyles}
        >
          <Box
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...iconBoxStyles}
          >
            <Icon icon={ICONS.LEFT_CHEVRON} />
          </Box>
        </SubtleButton>
      </Box>
      <Box order="3" marginBottom="quarter">
        <SubtleButton
          aria-label={formatMessage({ id: 'NEXT_DAY' })}
          disabled={disableNext}
          onClick={() => selectDate(1)}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...buttonStyles}
        >
          <Box
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...iconBoxStyles}
          >
            <Icon icon={ICONS.RIGHT_CHEVRON} />
          </Box>
        </SubtleButton>
      </Box>
      {isNotToday && (
        <Box flexBasis="100%" order="4">
          <VeryQuietButton
            onClick={() => selectDate()}
            marginTop="quarter"
            textDecoration="none"
            darkenBackground
            hideUnderline
          >
            {formatMessage({ id: 'BACK_TO_TODAY' })}
          </VeryQuietButton>
        </Box>
      )}
    </Flex>
  );
};

NavigateByDate.propTypes = {
  /** Number representing the UNIX epoch time of the currently displayed date */
  currentDate: PropTypes.number,
  /** Disable the 'Next day' navigation button */
  disableNext: PropTypes.bool,
  /** Disable the 'Previous day' navigation button */
  disablePrev: PropTypes.bool,
  /** Returns an object containing the requested date and increment for each navigation button */
  onChangeDate: PropTypes.func,
  /** Override the reference for "today" for testing and snapshot purposes */
  todaysDate: PropTypes.number,
};

NavigateByDate.defaultProps = {
  currentDate: undefined,
  disableNext: false,
  disablePrev: false,
  onChangeDate: () => {},
  todaysDate: todayUnix,
};
