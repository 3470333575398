/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import { node, number, string } from 'prop-types';
import {
  BodyOne,
  Box,
  Flex,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { GenesisRouterLink } from '@leagueplatform/web-common-components';

import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  JOURNEY_ROUTES,
  ACTIVITY_CAMPAIGN_TYPE,
} from '@leagueplatform/health-journey-common';
import { useUserCampaigns } from '../hooks/use-user-campaigns.hook';
import {
  getDiffOfDates,
  normalizeEpochDate,
} from '../utils/user-health-activities/user-health-activities.utils';
import {
  activitiesArrayPropType,
  activitiesGroupPropType,
} from '../common/health-activities.props';
import { HealthActivityCardList } from './health-activity-card-list/health-activity-card-list.view';

const { CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

const EmptyStateCard = ({ children, name, ...props }) => (
  <Box marginBottom="four">
    <HeadingThree marginBottom="two">{name}</HeadingThree>
    <Flex
      flexDirection="column"
      paddingY="four"
      paddingX="100px"
      backgroundColor="decorative.brand.primary.pastel"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      {children}
    </Flex>
  </Box>
);
EmptyStateCard.propTypes = {
  children: node.isRequired,
  name: string.isRequired,
};

const RenderCampaigns = ({ activeDate, allActivities }) => {
  const { campaigns } = useUserCampaigns();
  const { formatMessage, formatDate } = useIntl();
  const hasAnyChallengeActivities =
    allActivities?.filter(
      ({ campaign_info }) => campaign_info?.campaign_type === CHALLENGE,
    )?.length > 0 ?? false;
  const currentDate = normalizeEpochDate(Date.now());

  const strong = (msg) => <strong>{msg}</strong>;

  const { activeCampaigns, upcomingCampaigns } = campaigns;
  const hasActiveCampaigns = activeCampaigns?.length > 0 ?? false;
  const hasUpcomingCampaigns = upcomingCampaigns?.length > 0 ?? false;

  const handleViewChallengeDetails = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: 'Health Journey Activities',
      detail: 'View Challenge Details',
      current_date: currentDate,
      current_selected_date: activeDate,
    });
  };

  const UpcomingCampaigns = () =>
    hasUpcomingCampaigns &&
    upcomingCampaigns?.map(({ name, start_date, campaign_id }) => (
      <EmptyStateCard
        key={campaign_id}
        name={name}
        backgroundColor="surface.background.secondary"
      >
        <BodyOne fontWeight="bold">
          {formatMessage({ id: 'CHALLENGE_ABOUT_TO_START' })}
        </BodyOne>
        <BodyOne>
          {formatMessage(
            { id: 'ACTIVITIES_APPEAR_HERE_WHEN_CHALLENGE_STARTS' },
            {
              date: formatDate(start_date, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }),
              strong,
            },
          )}
        </BodyOne>
        <GenesisRouterLink
          to={JOURNEY_ROUTES.getChallengeDetails(campaign_id)}
          marginTop="threeQuarters"
          onClick={() => handleViewChallengeDetails()}
        >
          {formatMessage({ id: 'VIEW_CHALLENGE_DETAILS' })}
        </GenesisRouterLink>
      </EmptyStateCard>
    ));

  const CampaignInProgressStatus = () => {
    // Display day off card when the user has no challenge activities at all, but is currently enrolled in a challenge campaign
    if (hasActiveCampaigns && !hasAnyChallengeActivities) {
      return activeCampaigns?.map(({ name, campaign_id }) => (
        <EmptyStateCard key={campaign_id} name={name}>
          <BodyOne>
            {formatMessage({ id: 'NO_CHALLENGE_ACTIVITIES_TODAY' })}
          </BodyOne>
        </EmptyStateCard>
      ));
    }
    return null;
  };

  return (
    <>
      <CampaignInProgressStatus />
      <UpcomingCampaigns />
    </>
  );
};

RenderCampaigns.propTypes = {
  activeDate: number,
  allActivities: activitiesArrayPropType,
};
RenderCampaigns.defaultProps = {
  activeDate: normalizeEpochDate(Date.now()),
  allActivities: undefined,
};

export const ChallengeActivities = ({
  todaysDate,
  activitiesByChallenge,
  activeDate,
  allActivities,
}) => {
  const diffOfDates = getDiffOfDates(
    new Date(todaysDate),
    new Date(activeDate),
  );

  const activeDateIsToday = diffOfDates === 0;

  return (
    <>
      {/* First, render any challenges activities that we have */}
      {activitiesByChallenge &&
        activitiesByChallenge?.map(({ title, activities }) => (
          <HealthActivityCardList
            key={`activity-list-group-${title}`}
            activityCategoryLabel={title}
            activityArray={activities}
            activeDate={activeDate}
          />
        ))}
      {/*
        then, if we are looking at "today",
        we want to render a couple of things:
          - If there is an upcoming challenge, we display the "Nothing to see here yet message"
          - If there is an active challenge, but we've completed all the challenge activities, we display the "Take the day off, no more activities" message
      */}
      {activeDateIsToday && (
        <RenderCampaigns
          allActivities={allActivities}
          activeDate={activeDate}
        />
      )}
    </>
  );
};

ChallengeActivities.propTypes = {
  activitiesByChallenge: activitiesGroupPropType,
  todaysDate: number,
  activeDate: number,
  allActivities: activitiesArrayPropType,
};
ChallengeActivities.defaultProps = {
  activitiesByChallenge: null,
  todaysDate: normalizeEpochDate(Date.now()),
  activeDate: normalizeEpochDate(Date.now()),
  allActivities: undefined,
};
