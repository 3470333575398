import React from 'react';
import type { AppBarHeaderProps } from './app-nav.header.component';
import { AppBarHeader } from './app-nav.header.component';
import { AppBarNav } from './app-nav.nav.component';
import { AppBarNavList } from './app-nav.nav-list.component';

/**
 * An app nav bar that stays at the top of the screen.
 * @param {object} props
 * @prop {string} props.children - child components that are to be displayed in the app bar
 * @prop {number} [props.navHeight] - the height of the nav bar
 * @prop {boolean} [props.enableSkipToContent] - enabling this adds the skip to main content item to the nav bar
 */
export const AppBarComponent = ({
  children,
  navHeight,
  enableSkipToContent,
}: AppBarHeaderProps) => (
  <AppBarHeader navHeight={navHeight} enableSkipToContent={enableSkipToContent}>
    <AppBarNav>
      <AppBarNavList>{children}</AppBarNavList>
    </AppBarNav>
  </AppBarHeader>
);
