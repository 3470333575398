import { leagueFetch } from '@leagueplatform/league-fetch';
import { CARE_COLLECTIONS_PATH } from '../constants/care-collections.constants';
import {
  CareCollectionsQueryResponse,
  CARE_COLLECTIONS_DATA_TYPE,
} from '../types/care-collections.types';

export const getCareCollections = async (
  include?: CARE_COLLECTIONS_DATA_TYPE,
): Promise<CareCollectionsQueryResponse> => {
  let path = CARE_COLLECTIONS_PATH;

  if (include) {
    path = `${CARE_COLLECTIONS_PATH}?include=${include}`;
  }

  const response = await leagueFetch(path);

  return response.json();
};
