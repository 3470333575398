import React, { FC, forwardRef, useCallback, useRef } from 'react';
import { IconSource } from 'components/icon/icon';
import type { GDSBaseInputProps } from '../base-input/base-input';
import { BaseInput } from '../base-input/base-input';
import {
  affixedStyles,
  disabledInputStyles,
  readOnlyInputStyles,
} from '../input-styles';
import type { GDSCustomizableComponent } from '../../../types';
import { styled } from '../../../theme';
import { focusOutlineInner } from '../../../theme/utils/focus-outline';
import { InputElements } from '../input-elements/input-elements';

export interface GDSTextInputProps
  extends GDSBaseInputProps<'input'>,
    GDSCustomizableComponent {
  placeholder?: string;
  addOnStart?: React.ReactNode;
  addOnStartIcon?: IconSource;
  addOnStartIconLabel?: string;
  addOnEnd?: React.ReactNode;
  addOnEndIcon?: IconSource;
  addOnEndIconLabel?: string;
  leadingContent?: React.ReactNode;
  trailingContent?: React.ReactNode;
}

const TextInputGroup = styled('div', {
  display: 'flex',
  '[class*="GDS"]:focus': {
    ...focusOutlineInner,
  },
});

const TextInputWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  width: '100%',
  // Styles for for inputs with leading or trailing content
  ...affixedStyles,
  '> .GDS-base-input-wrapper.GDS-affixed-base': {
    flex: 'initial',
  },
  // Styles for input with add ons
  // Border style when input is not the first child, i.e. it has an AddOn at the start
  '&:where(.GDS-text-input-wrapper:not(:first-child)) > .GDS-base-input-wrapper > input':
    {
      borderStartStartRadius: '0',
      borderEndStartRadius: '0',
    },
  // Border style when input is not the last child, i.e. it has an AddOn at the end
  '&:where(.GDS-text-input-wrapper:not(:last-child)) > .GDS-base-input-wrapper > input':
    {
      borderStartStartRadius: '$medium',
      borderEndStartRadius: '$medium',
      borderStartEndRadius: '0',
      borderEndEndRadius: '0',
    },
  // Border style when input is neither the first nor the last child, i.e. it has an AddOn on each end
  '&:where(.GDS-text-input-wrapper:not(:first-child):not(:last-child)) > .GDS-base-input-wrapper > input':
    {
      borderStartStartRadius: '0',
      borderEndStartRadius: '0',
      borderStartEndRadius: '0',
      borderEndEndRadius: '0',
    },
  // Border style when input has a affixed content and it is not the first child,
  // i.e. it has an AddOn at the start with trailing and/or leading content
  '&:is(.GDS-affixed:not(:first-child))': {
    borderStartStartRadius: '0',
    borderEndStartRadius: '0',
  },
  // Border style when input has a affixed content and it is not the last child,
  // i.e. it has an AddOn at the end with trailing and/or leading content
  '&:is(.GDS-affixed:not(:last-child))': {
    borderStartStartRadius: '$medium',
    borderEndStartRadius: '$medium',
    borderStartEndRadius: '0',
    borderEndEndRadius: '0',
  },
  // Border style when input is neither the first nor the last child,
  // i.e. it has an AddOn on each end with trailing and/or leading content
  '&:is(.GDS-affixed:not(:first-child):not(:last-child))': {
    borderStartStartRadius: '0',
    borderEndStartRadius: '0',
    borderStartEndRadius: '0',
    borderEndEndRadius: '0',
    flex: 'initial',
  },
  '&:is(.GDS-affixed.GDS-input-status-success)': {
    borderColor: '$inputBorderSuccess',
  },
  '&:is(.GDS-affixed.GDS-input-status-warning)': {
    borderColor: '$inputBorderWarning',
  },
  '&:is(.GDS-affixed.GDS-input-status-error)': {
    borderColor: '$inputBorderCritical',
  },
  '&.GDS-readonly-input': {
    ...readOnlyInputStyles,
  },
  '&.GDS-disabled-input': {
    ...disabledInputStyles,
    // accounting for hover on disabled inputs when .GDS-disabled-input gets nested in TextInput from BaseInput
    '&:hover': {
      '.GDS-disabled-input': {
        backgroundColor: '$inputBackgroundHovered',
      },
    },
  },
  '&:is(.GDS-affixed):has(input:disabled)': {
    ...disabledInputStyles,
    // accounting for hover on disabled inputs when .GDS-disabled-input gets nested in TextInput from BaseInput
    '&:hover': {
      backgroundColor: '$inputBackgroundHovered',
    },
  },
  '&:is(.GDS-affixed):has(input:read-only)': {
    ...readOnlyInputStyles,
    // accounting for hover on disabled inputs when .GDS-disabled-input gets nested in TextInput from BaseInput
    '&:hover': {
      backgroundColor: '$inputBackgroundHovered',
    },
  },
});

export const TextInput: FC<GDSTextInputProps> = forwardRef(
  (
    {
      id,
      name,
      value,
      placeholder,
      autoComplete,
      disabled,
      readOnly,
      inputStatus,
      ariaDescribedby,
      enterkeyhint,
      clearable,
      onClear,
      clearLabel,
      leadingContent,
      trailingContent,
      addOnStart,
      addOnEnd,
      addOnStartIcon,
      addOnEndIcon,
      addOnStartIconLabel,
      addOnEndIconLabel,
      css,
      ...props
    }: GDSTextInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const textInputGroup = useRef<HTMLDivElement>(null);
    const isAffixed = leadingContent || trailingContent || clearable;

    // set focus to input when affixed input wrapper is clicked
    const setInputFocus = useCallback(() => {
      textInputGroup.current?.querySelector('input')?.focus();
    }, []);

    return (
      <TextInputGroup className="GDS-text-input-group" css={css}>
        {/* string or custom content for add on start */}
        {typeof addOnStart === 'string' ? (
          <InputElements.AddOn position="start">
            {addOnStart}
          </InputElements.AddOn>
        ) : (
          addOnStart
        )}
        {/* render start icon with addon styles */}
        {addOnStartIcon && (
          <InputElements.AddOn
            position="start"
            icon={addOnStartIcon}
            iconLabel={addOnStartIconLabel}
          />
        )}
        <TextInputWrapper
          className={`GDS-text-input-wrapper
          ${isAffixed ? 'GDS-affixed' : ''}
          ${disabled && clearable ? 'GDS-disabled-input' : ''}
          ${readOnly && clearable ? 'GDS-readonly-input' : ''}
          ${isAffixed && inputStatus ? `GDS-input-status-${inputStatus}` : ''}`}
          onClick={setInputFocus}
          ref={textInputGroup}
        >
          {/* leading content on input */}
          {leadingContent && (
            <InputElements.AffixContent position="leading">
              {leadingContent}
            </InputElements.AffixContent>
          )}

          <BaseInput
            type="text"
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            inputStatus={inputStatus}
            ariaDescribedby={ariaDescribedby}
            clearable={clearable}
            clearLabel={clearLabel}
            onClear={onClear}
            enterkeyhint={enterkeyhint}
            readOnly={readOnly}
            ref={ref}
            {...props}
          />
          {/* trailing content on input */}
          {trailingContent && (
            <InputElements.AffixContent position="trailing">
              {trailingContent}
            </InputElements.AffixContent>
          )}
        </TextInputWrapper>
        {/* string or custom content for add on end */}
        {typeof addOnEnd === 'string' ? (
          <InputElements.AddOn position="end">{addOnEnd}</InputElements.AddOn>
        ) : (
          addOnEnd
        )}
        {/* render end icon with addon styles */}
        {addOnEndIcon && (
          <InputElements.AddOn
            position="end"
            icon={addOnEndIcon}
            iconLabel={addOnEndIconLabel}
          />
        )}
      </TextInputGroup>
    );
  },
);

TextInput.displayName = 'TextInput';
