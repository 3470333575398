import { HEALTH_JOURNEY_ACTIVITY_STATUS } from '@leagueplatform/health-journey-common';
import camelcaseKeys from 'camelcase-keys';

const { UPCOMING } = HEALTH_JOURNEY_ACTIVITY_STATUS;
export const configureActivityCardProgress = (activity) => {
  const camelcasedActivity = camelcaseKeys(activity, { deep: true });
  const {
    completionCounter = {},
    activityVerificationProgress = {},
    status,
    frontOfTheLineActivity: isFrontOfLine = false,
  } = camelcasedActivity;
  const { countProgress: progress = 0 } = activityVerificationProgress;
  const { unit, goal } = completionCounter;

  const isProgressActivity = Boolean(unit && goal && status !== UPCOMING);
  const progressProps = {
    unit,
    goal,
    progress,
    status,
    isFrontOfLine,
  };

  return { isProgressActivity, progressProps };
};
