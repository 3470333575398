import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';
import {
  breakpoints,
  DottedTooltipModal,
  TooltipHeader,
  Responsive,
} from '@leagueplatform/ui-kit';
import {
  Label,
  LabelText,
  Box,
  ErrorMessage,
  Flex,
} from '@leagueplatform/genesis-commons';

export const FieldWrapper = ({
  label,
  tooltip,
  tooltipValues,
  children,
  htmlFor,
  error,
  labelId,
  groupedField,
}) => (
  <Label
    htmlFor={htmlFor}
    id={labelId}
    as={groupedField ? 'fieldset' : 'label'}
  >
    <LabelText
      as={groupedField ? 'legend' : undefined}
      justifyContent="space-between"
      alignItems="flex-end"
      width={1}
      marginBottom="quarter"
    >
      <Flex>
        <Box flexGrow={1}>{label}</Box>
        {tooltip && (
          <>
            <Responsive breakpoint={breakpoints.lapAndAbove}>
              <TooltipHeader
                tooltipLink={
                  <Box fontWeight="regular">
                    <FormattedMessage id="WHY_DO_YOU_ASK" />
                  </Box>
                }
                tooltipText={
                  <Box
                    minWidth={350}
                    fontSize="body2"
                    fontWeight="regular"
                    color="onSurface.text.primary"
                  >
                    <FormattedHTMLMessage id={tooltip} values={tooltipValues} />
                  </Box>
                }
                verticalPosition="bottom: 3rem;"
                pointerPosition={50}
                inline
              />
            </Responsive>
            <Responsive breakpoint={breakpoints.belowLap}>
              <DottedTooltipModal
                label={<FormattedMessage id="WHY_DO_YOU_ASK" />}
                content={
                  <FormattedHTMLMessage id={tooltip} values={tooltipValues} />
                }
                marginTop="minusThreeQuarters"
                inline
              />
            </Responsive>
          </>
        )}
      </Flex>
    </LabelText>
    <Box>{children}</Box>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Label>
);

FieldWrapper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipValues: PropTypes.objectOf(PropTypes.any),
  labelId: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  ]),
  groupedField: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  tooltip: null,
  tooltipValues: {},
  error: null,
  labelId: undefined,
  groupedField: false,
};
