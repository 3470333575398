import React, { FC, useMemo, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  MultipleSelectInputAttributes,
  MultipleSelectAnswerOption,
} from '@leagueplatform/health-journey-api';
import { Fieldset, StackLayout } from '@leagueplatform/genesis-core';
import {
  UseControllerProps,
  useControlledInput,
  useFormContext,
} from '@leagueplatform/web-common';
import { useMultiSelectInputValidation } from '../../../hooks/use-multi-select-input-validation.hook';
import {
  CheckboxOption,
  RadioOption,
} from './activity-toolbox-multi-select-option.component';
import { ToolboxCommonInputProps } from '../../../types/health-activity.types';
import {
  ActivityToolboxInputWrapper,
  MapInputValueToProps,
} from '../../activity-toolbox-input-wrapper.component';
import { ActivityToolboxMultiSelectOptionResolver } from './activity-toolbox-multiple-select-option-resolver.component';

// Types
export interface MultipleSelectInputProps
  extends ToolboxCommonInputProps,
    MultipleSelectInputAttributes {}

export const ActivityToolboxMultipleSelectInput: FC<
  MultipleSelectInputProps
> = (props) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const { altText, css, componentId, validation, answerOptions } = props;
  const {
    customWarning,
    editable = true,
    maximumSelectionRequired,
    minimumSelectionRequired,
    required,
  } = validation;
  const { selectedOverMaxOptions, selectedUnderMinOptions } =
    useMultiSelectInputValidation({
      maximumSelectionRequired,
      minimumSelectionRequired,
      customWarning,
    });
  const isCheckbox = !maximumSelectionRequired || maximumSelectionRequired > 1;
  const OptionElement = useMemo(
    () => (isCheckbox ? CheckboxOption : RadioOption),
    [isCheckbox],
  );

  /**
   * Get the value of only the selected options, returning the corresponding values for radio/checkboxes and free-text inputs
   */
  const getSelectedAnswerOptionValues = useCallback(
    (options: MultipleSelectAnswerOption[] = answerOptions) =>
      options
        .map(
          ({ isSelected, enableFreeText, value, text }) =>
            isSelected && (enableFreeText ? text : value),
        )
        .filter((optionValue) => optionValue !== false),
    [answerOptions],
  );

  /**
   * Update each answerOption's `isSelected` state contingent on whether the input is a radio or checkbox, and exclusivity parameters
   */
  const setSelectedAnswerOptions = useCallback(
    (changedOption?: MultipleSelectAnswerOption) =>
      answerOptions.map((option) => {
        let { isSelected } = option;

        // Treat the option as exclusive if it's a radio input or either the current/changed option is exclusive
        const treatAsExclusive =
          !isCheckbox || changedOption?.exclusive || option?.exclusive;

        const isChangedOptionSelected =
          option.id === changedOption?.id && changedOption?.isSelected;

        if (treatAsExclusive && !isChangedOptionSelected) {
          isSelected = false;
        }

        return Object.assign(option, { isSelected });
      }),
    [answerOptions, isCheckbox],
  );

  /** Derive different input options for radio and checkbox inputs */
  const inputOptions: UseControllerProps = useMemo(
    () => ({
      defaultValue: getSelectedAnswerOptionValues(),
      name: componentId,
      rules: {
        disabled: !editable,
        onChange: (event) => {
          const { target } = event;
          const { value: option } = target;

          const updatedOptions = setSelectedAnswerOptions(option);
          const nextValues = getSelectedAnswerOptionValues(updatedOptions);

          setValue(componentId, nextValues, {
            shouldValidate: true,
            shouldDirty: true,
          });
        },
        required: {
          value: required,
          message:
            customWarning ||
            formatMessage({
              id: 'TOOLBOX_VALIDATION_MULTI_SELECT_REQUIRED',
            }),
        },
        validate: {
          selectedUnderMinOptions,
          selectedOverMaxOptions,
        },
      },
    }),
    [
      getSelectedAnswerOptionValues,
      componentId,
      editable,
      required,
      customWarning,
      formatMessage,
      selectedUnderMinOptions,
      selectedOverMaxOptions,
      setSelectedAnswerOptions,
      setValue,
    ],
  );

  /** Update the component to match the currently selected value(s) */
  const mapValueToProps: MapInputValueToProps = useCallback(
    (currentValue: string[], component) => {
      if (currentValue) {
        const { componentAttributes } = component;
        Object.assign(componentAttributes, {
          ...componentAttributes,
          answerOptions,
        });
      }

      return component;
    },
    [answerOptions],
  );

  /** From the answerOptions array, compute a new list of options, including the input type and a unique id for each option */
  const optionProps = useMemo(
    () =>
      answerOptions.map((option, index) => ({
        option,
        optionId: `${componentId}-${index}`,
      })),
    [answerOptions, componentId],
  );

  const {
    field,
    inputValidationState: { inputStatus, statusMessage },
  } = useControlledInput(inputOptions);

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <Fieldset
        id={componentId}
        legend={altText}
        hideLegend
        css={css}
        inputStatus={inputStatus}
        statusMessage={statusMessage}
        data-testid={componentId}
      >
        <StackLayout spacing="$one" horizontalAlignment="stretch">
          {optionProps?.map(({ option, optionId }) => (
            <ActivityToolboxMultiSelectOptionResolver
              key={optionId}
              option={option}
              optionId={optionId}
              optionType={OptionElement}
              disabled={!editable}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
          ))}
        </StackLayout>
      </Fieldset>
    </ActivityToolboxInputWrapper>
  );
};
