import * as React from 'react';
import { HealthMetricDeserialisedDataV2 } from '@leagueplatform/dashboard-api';
import { LineGraph } from 'components/charts/core/line-graph';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { RangeGraph } from 'components/charts/core/range-graph';
import { useHealthMetricValues } from 'components/charts/core/range-graph/utils/use-health-metric-values';
import { shapes } from 'components/charts/core/xychart/primitives/Shape';
import { useIntl } from '@leagueplatform/locales';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { BarGraph } from 'components/charts/core/bar-graph';
import { MultipleMeasurementsTooltip } from 'components/charts/core/chart-tooltip/multiple-measurements-tooltip';
import { getHealthMetricUnitDisplayValue } from 'utils/get-health-metric-unit-display-value';
import { SingleMeasurementTooltip } from 'components/charts/core/chart-tooltip/v2/single-measurement-tooltip';

// Example:
// Range: <May 31, 2023>, Systolic <90>-<100> <millimeters of mercury>
// Single: <May 31, 2023>, Systolic 90 <systolic>
function useDatumLabel() {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  return (datum: ReturnType<typeof useHealthMetricValues>[number]) => {
    if (datum.value === null) {
      return '';
    }

    const metricName = datum.metric.data.name;
    const date = formatDateWithUserProfileLocation(
      new Date(datum.timestamp),
      DATE_FORMAT,
    );
    const value = Array.isArray(datum.value)
      ? datum.value.join('-')
      : datum.value;
    const unitName = getHealthMetricUnitDisplayValue(
      datum.value,
      datum.metric.data.unit,
    );

    return `${date}, ${metricName} ${value} ${unitName}`;
  };
}

type ChartProps = {
  chartView: CHART_VIEW;
  chartData: HealthMetricDeserialisedDataV2['data'][number];
};

export function Chart({ chartView, chartData }: ChartProps) {
  const { formatMessage } = useIntl();
  const { screenReaderTitle, screenReaderDescription } = chartData;
  const axisLeftLabel = chartData.axisLeftLabel.toLocaleUpperCase();
  const axisBottomLabel = formatMessage({
    id:
      chartView === CHART_VIEWS.WEEKLY ? 'DAY_OF_THE_WEEK' : 'DAY_OF_THE_MONTH',
  });

  const generateDatumLabel = useDatumLabel();

  const data = useHealthMetricValues(chartData.values.data);

  switch (chartData.chartType) {
    case 'bar': {
      return (
        <BarGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) => datum.timestamp,
            yAccessor: (datum) => datum.value as number,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          renderTooltip={(datum) => <SingleMeasurementTooltip datum={datum} />}
          yMaxFromEnums={0}
        />
      );
    }
    case 'line': {
      return (
        <LineGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) => datum.timestamp,
            yAccessor: (datum) => datum.value as number,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          renderTooltip={(datum) => <SingleMeasurementTooltip datum={datum} />}
          yMaxFromEnums={0}
        />
      );
    }
    case 'range': {
      return (
        <RangeGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) => datum.timestamp,
            yAccessor: (datum) => datum.value,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          getShape={(datum) => shapes[datum.metric.data.order]}
          renderTooltip={(datum) => (
            <MultipleMeasurementsTooltip data={datum} />
          )}
          yMaxFromEnums={0}
        />
      );
    }
    default:
      return null;
  }
}
