import React, { forwardRef } from 'react';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  UtilityText,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import type { MasonryNotificationCardRendererProps } from './masonry-notification-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

const eyebrowStyles: GDSStyleObject = {
  borderRadius: '$medium',
  backgroundColor: '$surfaceBackgroundTertiary',
  padding: '$quarter $half',
  marginBottom: '$half',
};

interface MasonryNotificationCardContentProps
  extends MasonryNotificationCardRendererProps {
  onClick?: MasonryDeeplinkAction;
}

export const MasonryNotificationCardContentRenderer = forwardRef<
  HTMLAnchorElement,
  MasonryNotificationCardContentProps
>(({ heading, subheading, info, eyebrow, onClick }, ref) => {
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout>
      {eyebrow && (
        <UtilityText css={eyebrowStyles} size="eyebrow" emphasis="emphasized">
          {eyebrow}
        </UtilityText>
      )}

      {heading && (
        <HeadingText size="sm" level="2" css={{ cursor: 'inherit' }}>
          <ConditionalLinkRenderer
            ref={ref}
            url={onClick?.payload.url}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            {heading}
          </ConditionalLinkRenderer>
        </HeadingText>
      )}

      {subheading && (
        <ParagraphText
          css={{ '&:not(:last-child)': { marginBlockEnd: 0 } }}
          htmlStringOptions={BodyTextHtmlTagOptions}
        >
          {subheading}
        </ParagraphText>
      )}

      {info && (
        <ParagraphText
          emphasis="subtle"
          size="sm"
          htmlStringOptions={BodyTextHtmlTagOptions}
        >
          {info}
        </ParagraphText>
      )}
    </StackLayout>
  );
});
