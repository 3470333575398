import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
  CelebrationAchievementsModal,
  useAchievementsUpdates,
} from '@leagueplatform/rewards-achievements';
import { useActivityCompletionData } from 'hooks/use-activity-completion-data.hook';
import { useHistory, useLocation } from '@leagueplatform/routing';
import { useActivityAnalytics } from 'hooks/use-activity-details-analytics.hook';
import {
  JOURNEY_ROUTES,
  ViewTransitionController,
} from '@leagueplatform/health-journey-common';
import { REWARDS_EVENTS_UPDATES } from '@leagueplatform/rewards-events';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { CompleteActivityModal } from './complete-activity-modal.component';

const COMPLETION_MODAL_TYPES = Object.freeze({
  ACTIVITY_COMPLETE: 'activity_complete',
  ACHIEVEMENT_COMPLETE: 'achievement_complete',
  NONE: '',
});

const { ACTIVITY_COMPLETE, ACHIEVEMENT_COMPLETE, NONE } =
  COMPLETION_MODAL_TYPES;

export const ActivityCompletionController = () => {
  const history = useHistory();
  const location = useLocation();
  const { completionData, resetCompletionData } = useActivityCompletionData();
  const achievementsUpdates = useAchievementsUpdates();
  const activityAnalytics = useActivityAnalytics();
  const [activeModal, setActiveModal] = useState(NONE);

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_UPDATES,
  );

  const [isAchievementsFetched, setIsAchievementsFetched] = useState(false);

  const {
    isAchievementUpdatesReady,
    refetchAchievements,
    hasCompletedAchievements,
    inProgressAchievements,
    isAchievementsFeatureFlagOn,
    clearAchievements,
  } = achievementsUpdates;

  // Computed Properties
  const hasCompletionData = Boolean(completionData);
  const hasInProgressAchievements = Boolean(inProgressAchievements.length);

  // Handlers
  const onActivityCompleteModalClose = useCallback(async () => {
    activityAnalytics.sendCloseActivityComplete();
    resetCompletionData();
    // If there are completed achievements we don't redirect the user to the journey page
    if (!hasCompletedAchievements) {
      if (isAchievementsFeatureFlagOn) {
        clearAchievements();
      }
      setActiveModal(NONE);
      history.push(JOURNEY_ROUTES.journeyHome());
      return;
    }
    setActiveModal(ACHIEVEMENT_COMPLETE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompletedAchievements, isAchievementsFeatureFlagOn]);

  const onAchievementCompleteModalClose = useCallback(async () => {
    if (isAchievementsFeatureFlagOn) {
      clearAchievements();
    }
    setActiveModal(NONE);
    history.push(JOURNEY_ROUTES.achievementsHome());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAchievementsFeatureFlagOn]);

  // Instantiate modals.
  const modals = useMemo(
    () => ({
      [ACTIVITY_COMPLETE]: {
        view: CompleteActivityModal,
        props: {
          onClose: onActivityCompleteModalClose,
          achievementsUpdates,
          completionData,
        },
      },
      [ACHIEVEMENT_COMPLETE]: {
        view: CelebrationAchievementsModal,
        props: {
          onClose: onAchievementCompleteModalClose,
          open: activeModal === ACHIEVEMENT_COMPLETE,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inProgressAchievements, hasCompletedAchievements, completionData],
  );

  // call refetch achievements with a delay to account for extra time needed for call to BE to resolve
  useEffect(() => {
    let timeoutId;
    if (
      location.pathname === JOURNEY_ROUTES.journeyHome() &&
      isAchievementsFeatureFlagOn &&
      !isRewardsLayerEnabled
    ) {
      timeoutId = setTimeout(
        () => refetchAchievements().then(setIsAchievementsFetched(true)),
        500,
      );
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    isAchievementsFeatureFlagOn,
    isRewardsLayerEnabled,
    location.pathname,
    refetchAchievements,
  ]);

  useEffect(() => {
    if (
      !activeModal &&
      !isRewardsLayerEnabled &&
      (!isAchievementsFeatureFlagOn ||
        (isAchievementsFetched && isAchievementUpdatesReady))
    ) {
      if (hasCompletionData || hasInProgressAchievements) {
        setActiveModal(ACTIVITY_COMPLETE);
      } else if (hasCompletedAchievements) {
        setActiveModal(ACHIEVEMENT_COMPLETE);
      } else {
        setActiveModal(NONE);
      }
    }
  }, [
    isAchievementUpdatesReady,
    isAchievementsFeatureFlagOn,
    activeModal,
    hasCompletionData,
    hasInProgressAchievements,
    hasCompletedAchievements,
    isAchievementsFetched,
    isRewardsLayerEnabled,
  ]);

  const handleActiveModal = () => activeModal;

  return (
    <ViewTransitionController
      views={modals}
      handleActiveView={handleActiveModal}
    />
  );
};
