import React, {
  forwardRef,
  ReactNode,
  isValidElement,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { HeadingText } from 'components/typography/heading-text/heading-text';
import type { GDSHeadingLevel } from 'components/typography/heading-text/heading-text';
import type { GDSCustomizableComponent } from '../../types';
import { styled } from '../../theme';
import type { GDSColor } from '../../theme';

export interface GDSHeadingBarProps extends GDSCustomizableComponent {
  title?: ReactNode;
  headingLevel?: GDSHeadingLevel;
  leftAction?: ReactNode;
  rightAction?: ReactNode;
  largeTitle?: boolean;
  backgroundColor?: GDSColor;
  transparency?: 'none' | 'gradient' | 'transparent' | 'withBackgrounds';
}

const HeadingBarWrapper = styled('div', {
  variants: {
    transparency: {
      none: {
        background: '$$backgroundColor',
      },
      transparent: {
        background: 'transparent',
      },
      gradient: {
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 5.8%, rgba(0, 0, 0, 0) 99.68%)',
      },
      withBackgrounds: {
        background: 'transparent',
      },
    },
  },
});

const HeadingBarBase = styled('div', {
  display: 'flex',
  position: 'relative',
  padding: '$threeQuarters',
  minHeight: '$three',
});

const ActionWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  zIndex: 1,
});

const ActionBackground = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$pill',
  variants: {
    hasBackgrounds: {
      true: {
        backgroundColor: '$surfaceBackgroundPrimary',
      },
    },
  },
});

const TitleWrapper = styled('div', {
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingTop: '$threeQuarters',
  paddingBottom: '$threeQuarters',
  paddingLeft: 'calc($$padding + $threeQuarters)',
  paddingRight: 'calc($$padding + $threeQuarters)',
});

const Title = styled(HeadingText, {
  padding: '$quarter $half',
  borderRadius: '$pill',
  textAlign: 'center',
  variants: {
    hasBackgrounds: {
      true: {
        backgroundColor: '$surfaceBackgroundPrimary',
      },
    },
    gradient: {
      true: {
        color: '$onSurfaceTextReversed',
      },
    },
  },
});

export const HeadingBar = forwardRef<HTMLDivElement, GDSHeadingBarProps>(
  (
    {
      title,
      leftAction,
      rightAction,
      headingLevel = 'display',
      largeTitle,
      backgroundColor = '$surfaceBackgroundPrimary',
      transparency = 'none',
      css,
      ...props
    },
    ref,
  ) => {
    // Find which action is wider - apply that width to side padding of title wrapper
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);
    const [sidePadding, setSidePadding] = useState<null | number>(null);

    const leftWidth = leftRef?.current?.offsetWidth ?? 0;
    const rightWidth = rightRef?.current?.offsetWidth ?? 0;

    useLayoutEffect(() => {
      if (leftWidth > rightWidth) {
        setSidePadding(leftWidth);
      } else setSidePadding(rightWidth);
    }, [leftWidth, rightWidth]);

    const hasBackgrounds = transparency === 'withBackgrounds';
    // If is a React element, pass it as is.
    // Otherwise, wrap it in a HeadingText.
    const titleComponent = isValidElement(title) ? (
      title
    ) : (
      <Title
        level={headingLevel}
        size={largeTitle ? 'xl' : 'sm'}
        hasBackgrounds={hasBackgrounds}
        gradient={transparency === 'gradient'}
        className="GDS-heading-bar-title"
      >
        {title}
      </Title>
    );

    const getJustifyContent = () => {
      if (leftAction && rightAction) return 'space-between';
      if (rightAction) return 'flex-end';
      return 'flex-start';
    };

    return (
      <HeadingBarWrapper
        className="GDS-heading-bar-wrapper"
        transparency={transparency}
        css={{
          ...css,
          $$backgroundColor: `$colors${backgroundColor}`,
        }}
      >
        <HeadingBarBase
          className="GDS-heading-bar-base"
          ref={ref}
          css={{
            justifyContent: getJustifyContent(),
          }}
          {...props}
        >
          {leftAction && (
            <ActionWrapper
              ref={leftRef}
              className="GDS-heading-bar-left-action"
            >
              <ActionBackground hasBackgrounds={hasBackgrounds}>
                {leftAction}
              </ActionBackground>
            </ActionWrapper>
          )}
          {title && sidePadding !== null && (
            <TitleWrapper
              css={{ $$padding: `${sidePadding}px` }}
              className="GDS-heading-bar-title-wrapper"
            >
              {titleComponent}
            </TitleWrapper>
          )}
          {rightAction && (
            <ActionWrapper
              ref={rightRef}
              className="GDS-heading-bar-right-action"
            >
              <ActionBackground hasBackgrounds={hasBackgrounds}>
                {rightAction}
              </ActionBackground>
            </ActionWrapper>
          )}
        </HeadingBarBase>
      </HeadingBarWrapper>
    );
  },
);

HeadingBar.displayName = 'HeadingBar';
