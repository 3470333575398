import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import { useHealthActivity } from './use-health-activity.hook';

const { BUTTON_CLICKED, SCREEN_LOADED } = EVENT_NAME;

export const useActivityStepsAnalytics = () => {
  const activity = useHealthActivity();

  const {
    activityProgress,
    contentActivityId,
    activityType,
    campaignInfo,
    cta = {},
    name: activityName,
    status: activityStatus,
  } = activity;
  const completionMethod = cta?.completion_method || {};
  const { type: completionType, steps, counter } = completionMethod;

  const totalSteps = steps?.length || null;

  // Activity meta data
  const meta = {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: SCREEN_NAMES.ACTIVITY_DETAILS,
  };

  // Activity static data
  const data = {
    health_campaign_activity_id: contentActivityId,
    health_campaign_activity_name: activityName,
    health_campaign_activity_type: activityType,
    health_campaign_activity_ux_type: completionType || null,
    health_campaign_activity_status: activityStatus,
    health_campaign_type: campaignInfo?.campaign_type || null,
  };

  // Activity stateful data
  const activityState = {
    health_campaign_activity_goal: counter?.goal || null,
    health_campaign_activity_current_progress:
      activityProgress?.count_progress || null,
  };

  const baseAnalytics = Object.assign(meta, data, activityState);

  return {
    sendViewStep(currentStep) {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...baseAnalytics,
        current_step: currentStep,
        total_steps: totalSteps,
      });
    },
    sendStartActivity() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        detail: 'start health campaign activity',
      });
    },
    sendNextStep(currentStep) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        current_step: currentStep,
        total_steps: totalSteps,
        detail: 'next step',
      });
    },
    sendPreviousStep(currentStep) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        current_step: currentStep,
        total_steps: totalSteps,
        detail: 'previous step',
      });
    },
    sendViewExitModal(currentStep) {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...baseAnalytics,
        current_step: currentStep,
        total_steps: totalSteps,
        screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
      });
    },
    sendCloseActivity(currentStep) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        current_step: currentStep,
        total_steps: totalSteps,
        detail: 'close activity',
      });
    },
    sendConfirmCloseActivity() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        detail: 'confirm leave activity',
        screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
      });
    },
    sendContinueActivity() {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...baseAnalytics,
        detail: 'cancel leave activity',
        screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
      });
    },
  };
};
