import { useMemo } from 'react';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import { useHealthActivityToolbox } from './use-health-activity-toolbox.hook';

const { BUTTON_CLICKED, SCREEN_LOADED } = EVENT_NAME;

export const useHealthActivityAnalytics = () => {
  // Activity Toolbox
  const { healthActivityData } = useHealthActivityToolbox();

  const {
    id,
    name,
    status,
    userHealthCampaign,
    frontOfTheLineActivity,
    pages = [],
  } = healthActivityData ?? {};
  const totalPages = pages.length;
  const userHealthCampaignData = userHealthCampaign?.data;

  const baseAnalytics = useMemo(() => {
    // Activity meta data
    const meta = {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: SCREEN_NAMES.ACTIVITY_DETAILS,
    };

    // Activity static data
    const data = {
      health_campaign_activity_name: name,
      health_campaign_activity_type: 'healthJourney_toolboxActivity', // TODO: wait BE send the correct value
      health_campaign_activity_id: id,
      health_campaign_activity_status: status,
      health_campaign_type: userHealthCampaignData?.campaignType || null,
      // Once we handle other types of activities, like manual multi-step, etc,
      // that has ux_types like step or counter, then we can consider changing
      // it to a value other than null.
      health_campaign_activity_ux_type: null,
    };
    return Object.assign(meta, data);
  }, [id, name, status, userHealthCampaignData?.campaignType]);

  return useMemo(
    () => ({
      sendCompleteActivity(ctaText?: string) {
        // #3.3
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          detail: 'complete health campaign activity',
          sub_detail: ctaText || null,
          health_campaign_activity_goal: null,
          health_campaign_activity_current_progress: null,
          is_fotl: frontOfTheLineActivity || null,
        });
      },
      sendNextPage(currentPage: number) {
        // #3.14
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          current_step: currentPage,
          total_steps: totalPages,
          detail: 'next step',
          health_campaign_activity_goal: null,
          health_campaign_activity_current_progress: null,
        });
      },
      sendPreviousPage(currentPage: number) {
        // #3.14
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          current_step: currentPage,
          total_steps: totalPages,
          detail: 'previous step',
          health_campaign_activity_goal: null,
          health_campaign_activity_current_progress: null,
        });
      },
      sendCloseActivity(currentPage: number) {
        // #3.15
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          current_step: currentPage,
          total_steps: totalPages,
          detail: 'close activity',
          health_campaign_activity_goal: null,
          health_campaign_activity_current_progress: null,
        });
      },
      sendViewExitModal(currentPage: number) {
        // #3.17
        trackAnalyticsEvent(SCREEN_LOADED, {
          ...baseAnalytics,
          current_step: currentPage,
          total_steps: totalPages,
          screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
          health_campaign_activity_goal: null,
          health_campaign_activity_current_progress: null,
        });
      },
      sendConfirmCloseActivity() {
        // #3.18
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          detail: 'confirm leave activity',
          screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
        });
      },
      sendContinueActivity() {
        // #3.19
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          detail: 'cancel leave activity',
          screen_name: SCREEN_NAMES.EXIT_CONFIRM_MODAL,
        });
      },
      sendViewPage(currentPage: number) {
        // #3.21 ?
        trackAnalyticsEvent(SCREEN_LOADED, {
          ...baseAnalytics,
          current_step: currentPage,
          total_steps: totalPages,
        });
      },
      sendLinkOutClick({
        title,
        url,
        detail = 'linkout clicked',
      }: {
        url?: string;
        title?: string;
        detail?: string;
      }) {
        trackAnalyticsEvent(BUTTON_CLICKED, {
          ...baseAnalytics,
          detail: detail || null,
          content_url: url || null,
          link_name: title || null,
        });
      },
    }),
    [baseAnalytics, frontOfTheLineActivity, totalPages],
  );
};

export type HealthActivityAnalyticsEvents = ReturnType<
  typeof useHealthActivityAnalytics
>;
