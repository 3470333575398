import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingTwo,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { ACTIVITY_CAMPAIGN_TYPE } from '@leagueplatform/health-journey-common';
import { ProgressActiveProgram } from './progress-active-program.component';

const { OPTIN, CHALLENGE } = ACTIVITY_CAMPAIGN_TYPE;

// Components
const ActiveCampaignListItem = genesisStyled('li')(
  css({
    borderBottomWidth: 'thin',
    borderBottomStyle: 'solid',
    borderBottomColor: 'onSurface.border.subdued',
    '&:last-child': {
      borderStyle: 'none',
    },
  }),
);

const CampaignBlock = genesisStyled(Box)(
  css({
    marginBottom: 'two',
    '&:last-of-type': {
      marginBottom: 'none',
      borderStyle: 'none',
    },
  }),
);

const CampaignList = genesisStyled(Box)(
  css({
    marginBottom: 'two',
    '&:last-of-type': {
      marginBottom: 'none',
    },
  }),
);

const ActiveCampaignViewContainer = ({ programs }) => {
  css({
    '&:last-of-type': {
      marginBottom: 'two',
      borderStyle: 'none',
    },
  });

  return (
    <Box flexDirection="column" as="ul">
      {programs?.map(
        ({
          image_url: imageUrl,
          name,
          program_id: programId,
          activity_status_counts: activityStatusCounts,
          total_activities_count: totalActivitiesCount,
          completed_activity_progress_percentage: completed,
          missed_activity_progress_percentage: missed,
          campaign_type: campaignType,
        }) => (
          <ActiveCampaignListItem key={programId}>
            <ProgressActiveProgram
              id={programId}
              image={imageUrl}
              title={name}
              total={totalActivitiesCount}
              progressPercentages={{ completed, missed }}
              activityStatusCounts={activityStatusCounts}
              campaignType={campaignType}
            />
          </ActiveCampaignListItem>
        ),
      )}
    </Box>
  );
};

ActiveCampaignViewContainer.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
      program_id: PropTypes.string,
      progress_percentage: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const ActiveCampaignList = ({ programs, heading }) => {
  const { formatMessage } = useIntl();
  return (
    <CampaignBlock>
      <HeadingTwo>
        {formatMessage({ id: heading }, { length: programs.length })}
      </HeadingTwo>
      <ActiveCampaignViewContainer programs={programs} />
    </CampaignBlock>
  );
};

ActiveCampaignList.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
      program_id: PropTypes.string,
      progress_percentage: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  heading: PropTypes.string,
};

ActiveCampaignList.defaultProps = {
  heading: '',
};

export const ActiveCampaigns = ({ programs }) => {
  const filterCampaignData = (data, filter) =>
    data?.filter(
      ({ campaign_type: campaignType }) => campaignType === filter,
    ) ?? [];

  const activeChallenges = filterCampaignData(programs, CHALLENGE);
  const activePrograms = filterCampaignData(programs, OPTIN);

  const isChallengesEmpty = activeChallenges?.length === 0;
  const isProgramsEmpty = activePrograms?.length === 0;

  return (
    <CampaignList>
      {!isChallengesEmpty && (
        <ActiveCampaignList
          programs={activeChallenges}
          heading="ACTIVE_CHALLENGE"
        />
      )}
      {!isProgramsEmpty && (
        <ActiveCampaignList
          programs={activePrograms}
          heading="ACTIVE_PROGRAM"
        />
      )}
    </CampaignList>
  );
};

ActiveCampaigns.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
      program_id: PropTypes.string,
      progress_percentage: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};
