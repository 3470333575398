import * as React from 'react';
import { HealthMetricDeserialisedDataV3 } from '@leagueplatform/dashboard-api';
import { LineGraph } from 'components/charts/core/line-graph';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { RangeGraph } from 'components/charts/core/range-graph';
import { useHealthMetricValuesV3 } from 'components/charts/core/range-graph/utils/use-health-metric-values';
import { shapes } from 'components/charts/core/xychart/primitives/Shape';
import { useIntl } from '@leagueplatform/locales';
import {
  DATE_FORMAT,
  NUMERIC_YEAR_MONTH_DAY,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { BarGraph } from 'components/charts/core/bar-graph';
import { MultipleMeasurementsTooltipV3 } from 'components/charts/core/chart-tooltip/v3/multiple-measurements-tooltipV3';
import { getHealthMetricUnitDisplayValue } from 'utils/get-health-metric-unit-display-value';
import { SingleMeasurementTooltipV3 } from 'components/charts/core/chart-tooltip/v3/single-measurement-tooltipV3';

// Example:
// Range: <May 31, 2023>, Systolic <90>-<100> <millimeters of mercury>
// Single: <May 31, 2023>, Systolic 90 <systolic>
function useDatumLabel() {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  return (datum: ReturnType<typeof useHealthMetricValuesV3>[number]) => {
    if (datum.value === null) {
      return '';
    }

    const metricName = datum.metric.data.name;
    const date = formatDateWithUserProfileLocation(
      new Date(datum.timestamp),
      DATE_FORMAT,
    );
    const value = Array.isArray(datum.value)
      ? datum.value.join('-')
      : datum.value;
    const unitName = getHealthMetricUnitDisplayValue(
      datum.value,
      datum.metric.data.unit,
    );

    return `${date}, ${metricName} ${value} ${unitName}`;
  };
}

function getHighestValueFromEnums(chartData: ChartProps['chartData']) {
  const enumOptionValues: number[] = [];
  chartData.addInputConfig.data.healthMetricInputs.forEach((input) =>
    input.valueFields.forEach((valueField) =>
      valueField.options?.forEach((option) =>
        enumOptionValues.push(option.value),
      ),
    ),
  );
  const highestEnumValue = Math.max(...enumOptionValues);
  return highestEnumValue;
}

type ChartProps = {
  chartView: CHART_VIEW;
  chartData: HealthMetricDeserialisedDataV3['data'][number];
};

export function Chart({ chartView, chartData }: ChartProps) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  const { formatMessage } = useIntl();
  const { screenReaderTitle, screenReaderDescription } = chartData;
  const axisLeftLabel = chartData.axisLeftLabel.toLocaleUpperCase();
  const axisBottomLabel = formatMessage({
    id:
      chartView === CHART_VIEWS.WEEKLY ? 'DAY_OF_THE_WEEK' : 'DAY_OF_THE_MONTH',
  });
  const generateDatumLabel = useDatumLabel();

  const data = useHealthMetricValuesV3(chartData.values.data);
  const highestEnumValue = getHighestValueFromEnums(chartData);

  switch (chartData.chartType) {
    case 'bar': {
      return (
        <BarGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) =>
              formatDateWithUserProfileLocation(
                datum.timestamp,
                NUMERIC_YEAR_MONTH_DAY,
              ),
            yAccessor: (datum) => datum.value as number,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          renderTooltip={(datum) => (
            <SingleMeasurementTooltipV3 datum={datum} />
          )}
          yMaxFromEnums={highestEnumValue}
        />
      );
    }
    case 'line': {
      return (
        <LineGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) =>
              formatDateWithUserProfileLocation(
                datum.timestamp,
                NUMERIC_YEAR_MONTH_DAY,
              ),
            yAccessor: (datum) => datum.value as number,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          renderTooltip={(datum) => (
            <SingleMeasurementTooltipV3 datum={datum} />
          )}
          yMaxFromEnums={highestEnumValue}
        />
      );
    }
    case 'range': {
      return (
        <RangeGraph
          chartView={chartView}
          data={data}
          accessors={{
            xAccessor: (datum) =>
              formatDateWithUserProfileLocation(
                datum.timestamp,
                NUMERIC_YEAR_MONTH_DAY,
              ),
            yAccessor: (datum) => datum.value,
          }}
          title={screenReaderTitle}
          description={screenReaderDescription}
          axisLeftLabel={axisLeftLabel}
          axisBottomLabel={axisBottomLabel}
          ariaDatumLabel={generateDatumLabel}
          getShape={(datum) => shapes[datum.metric.data.order]}
          renderTooltip={(datum) => (
            <MultipleMeasurementsTooltipV3 data={datum} />
          )}
          yMaxFromEnums={highestEnumValue}
        />
      );
    }
    default:
      return null;
  }
}
