import { useCallback, useContext } from 'react';
import { DEFAULT_NAMESPACE } from 'constants/constants';
import { formatEventName } from 'utils/formatEventName';
import type {
  MasonryEngineNodeOrigin,
  MasonryEngineNodeAction,
} from './types/masonry-engine-node.types';
import {
  MasonryEngineActionController,
  MasonryEngineActionHandlerAnonymousParams,
  MasonryEngineActionHandlerMap,
  MasonryEngineActionHandlerParams,
  MasonryEngineEvent,
  MasonryEngineActionHandler,
  MasonryEngineEventListener,
} from './types/masonry-engine-action.types';
import { MasonryEngineOriginContext } from './masonry-engine-origin-context';
import { useMasonryEngineConfig } from './masonry-engine-config-context';
import { MasonryEngineStateControllerContext } from './masonry-engine-state-controller-context';

type AnyMasonryEngineNodeAction = MasonryEngineNodeAction<string, any>;

type AnyMasonryNodeActionHandler =
  MasonryEngineActionHandler<AnyMasonryEngineNodeAction>;

type AnyMasonryEngineActionHandlerMap = Partial<
  Record<string, AnyMasonryNodeActionHandler>
>;

type EventListenerKey = `${string}-${MasonryEngineEvent}-${string}`;

export type EventListenerMap = Partial<
  Record<
    EventListenerKey,
    Set<MasonryEngineEventListener<AnyMasonryEngineNodeAction>>
  >
>;

const masonryEngineEventNamesExceptALL = Object.values(
  MasonryEngineEvent,
).filter((type) => type !== MasonryEngineEvent.ALL);

export const createMasonryEngineActionController = <
  /**
   * A union of known `MasonryEngineNodeAction` supported for this controller.
   */
  Action extends MasonryEngineNodeAction,
>(
  defaultHandlers: MasonryEngineActionHandlerMap<Action>,
): MasonryEngineActionController<Action> => {
  const actionRegistry: Partial<
    Record<string, AnyMasonryEngineActionHandlerMap>
  > = {
    default: defaultHandlers as unknown as AnyMasonryEngineActionHandlerMap,
  };

  const eventListenerMap: EventListenerMap = {};

  /**
   * @method registerHandler is used to register an action to the to the Masonry Engine Driver {@link actionRegistry }.
   */
  const registerHandler: MasonryEngineActionController<Action>['registerHandler'] =
    (type, namespace, handler) => {
      actionRegistry[namespace] = actionRegistry[namespace] || {};

      if (actionRegistry[namespace]![type]) {
        throw new Error(
          'Attempting to register a handler for a type at a namespace where a handler was already registered for that type!',
        );
      }

      actionRegistry[namespace]![type] = handler as AnyMasonryNodeActionHandler;
    };

  /**
   * @method overrideHandler: is used to override an action in the Masonry Engine Driver {@link actionRegistry }.
   */
  const overrideHandler: MasonryEngineActionController<Action>['overrideHandler'] =
    (type, namespace, handler) => {
      actionRegistry[namespace] = actionRegistry[namespace] || {};

      actionRegistry[namespace]![type] = handler as AnyMasonryNodeActionHandler;
    };

  const emitAction: MasonryEngineActionController<Action>['emitAction'] =
    async (params) => {
      const handler = actionRegistry[params.namespace]?.[params.type];
      if (!handler) {
        throw new Error(
          `Masonry Engine: No action - ${params.type} found for namespace - ${params.namespace}`,
        );
      }

      const startedEventName = formatEventName(
        params.namespace,
        params.type,
        MasonryEngineEvent.STARTED,
      );

      /**
       * Trigger all Masonry event listeners registered for
       * {@link MASONRY_ENGINE_EVENT_TYPE MASONRY_ENGINE_EVENT_TYPE.EVENT_STARTED} event type
       */
      eventListenerMap[startedEventName]?.forEach((listener) => {
        listener(params, undefined);
      });

      // eslint-disable-next-line no-console
      console.log('Masonry Engine Action emitted', params);

      const typedEmitAction = emitAction as Parameters<typeof handler>[1];

      const returnValue = await Promise.resolve(
        handler(params, typedEmitAction),
      );

      const endedEventName = formatEventName(
        params.namespace,
        params.type,
        MasonryEngineEvent.STARTED,
      );

      /**
       * Trigger all Masonry event listeners registered for
       * {@link MASONRY_ENGINE_EVENT_TYPE MASONRY_ENGINE_EVENT_TYPE.EVENT_ENDED} event type
       */
      eventListenerMap[endedEventName]?.forEach((listener) => {
        listener(params, returnValue);
      });

      // Trigger Masonry analytics event if action has an analytics object
      const analyticsHandler = actionRegistry[params.namespace]?.analytics;

      if (params.analytics && analyticsHandler) {
        const analyticsPayload = {
          type: 'analytics',
          // Running analytics action with default namespace always to be consistent with the analytics handling
          namespace: DEFAULT_NAMESPACE,
          payload: params.analytics,
          state: params.state,
          origin: params.origin,
        };
        typedEmitAction(analyticsPayload);
      }

      return returnValue;
    };

  /**
   * @method addEventListener is used for adding a listener for Masonry actions and events to the {@link EventListenerMap}.
   * */
  const addEventListener: MasonryEngineActionController<Action>['addEventListener'] =
    (
      actionType: string,
      namespace: string,
      eventType: MasonryEngineEvent,
      listener: (...args: any) => void,
    ) => {
      // An array of event types we should bind this listener to.
      const resolvedEventTypes =
        eventType === MasonryEngineEvent.ALL
          ? masonryEngineEventNamesExceptALL
          : [eventType];

      // Add listener for each resolved event type
      resolvedEventTypes.forEach((resolvedEventType) => {
        const formattedEventName = formatEventName(
          namespace,
          actionType,
          resolvedEventType,
        );
        eventListenerMap[formattedEventName] =
          eventListenerMap[formattedEventName] || new Set();
        eventListenerMap[formattedEventName]!.add(listener);
      });

      return () => {
        resolvedEventTypes.forEach((resolvedEventType) => {
          const formattedEventName = formatEventName(
            namespace,
            actionType,
            resolvedEventType,
          );

          eventListenerMap[formattedEventName]!.delete(listener);
        });
      };
    };

  /**
   * @method getRegisteredEventListeners: is used for getting an object that lists all the event types that have registered listeners in the Masonry Engine Listener Map {@link EventListenerMap }.
   */
  const getRegisteredEventListeners = () => {
    const record: Record<string, number> = {};

    Object.entries(eventListenerMap).forEach(([eventType, listenerSet]) => {
      if (listenerSet?.size) {
        record[eventType] = listenerSet.size;
      }
    });

    /**
     * This is an object with keys representing event types and values representing
     * how many listeners are currently registered for each.
     */
    return record;
  };

  return {
    registerHandler,
    overrideHandler,
    emitAction,
    addEventListener,
    getRegisteredEventListeners,
  };
};

/**
 * A hook that returns an emitter for {@link MasonryEngineActionHandlerAnonymousParams `MasonryEngineActionHandlerAnonymousParams`}.
 * The parameters will be automatically converted to {@link MasonryEngineActionHandlerParams `MasonryEngineActionHandlerParams`}s
 * (with origin information) and sent up to the Driver. Note: actions may be
 * intercepted if the user provided a {@link MasonryEngineNamespacedActionHandlerMap `MasonryEngineNamespacedActionHandlerMap`}
 * in the Config.
 */

// provide, fetch, react query clear cache,
export const useMasonryEngineActionEmitter = <
  Action extends MasonryEngineNodeAction,
>() => {
  const origin = useContext(MasonryEngineOriginContext);
  const {
    actionController: { emitAction },
  } = useMasonryEngineConfig();
  const { state } = useContext(MasonryEngineStateControllerContext);
  const currentNodeState = state[(origin as MasonryEngineNodeOrigin)?.nodeId];

  const invokeAction = useCallback(
    (params: MasonryEngineActionHandlerAnonymousParams<Action>) => {
      const actionWithOriginAndState = {
        ...params,
        origin,
        state: currentNodeState,
      } as MasonryEngineActionHandlerParams<Action>;

      emitAction(actionWithOriginAndState);
    },
    [origin, currentNodeState, emitAction],
  );

  return invokeAction;
};
