import React, { useMemo } from 'react';
import { GDSThemeProvider } from '../theme';
import { storybookArchetypeLightTheme } from '../theme/theme-files/storybook-themes';
import { Modal } from '../components/modal/modal';
import { Button } from '../components/button/button';
import { Box } from '../components/box/box';
import { StackLayout } from '../components/stack-layout/stack-layout';
import { StackItem } from '../components/stack-layout/stack-item/stack-item';
import { UtilityText } from '../components/typography/utility-text/utility-text';
import { HeadingText } from '../components/typography/heading-text/heading-text';

export interface UsageBoxProps {
  component: string;
  statsJSON?: ComponentUsageMap;
}

const DataModal = ({
  items,
  title,
  buttonLabel,
}: {
  items: string[];
  title: string;
  buttonLabel: string;
}) => (
  <Modal.Root>
    <Modal.Trigger>
      <Button
        icon="illustrativeDocument"
        priority="secondary"
        size="medium"
        disabled={items.length === 0}
      >
        {buttonLabel}
      </Button>
    </Modal.Trigger>
    <Modal.Content layout="fullscreen">
      <Modal.Title>
        <HeadingText size="xl" level="2">
          {title}
        </HeadingText>
      </Modal.Title>
      <Box
        as="table"
        css={{
          marginTop: '$two',
          borderCollapse: 'collapse',
          borderColor: '$onSurfaceBorderSubdued',
          borderWidth: '$thin',
          borderStyle: 'solid',
          'td, th': {
            padding: '$half',
            borderWidth: '$thin',
            borderColor: '$onSurfaceBorderSubdued',
            borderStyle: 'solid',
          },
        }}
      >
        <Box as="thead">
          <tr>
            <th>
              <UtilityText size="sm" emphasis="emphasized">
                Filepath
              </UtilityText>
            </th>
            <th>
              <UtilityText size="sm" emphasis="emphasized">
                Link
              </UtilityText>
            </th>
          </tr>
        </Box>
        <Box
          as="tbody"
          css={{
            'tr:nth-child(even)': {
              backgroundColor: '$surfaceBackgroundSecondary',
            },
            'tr:hover': { backgroundColor: '$surfaceBackgroundTertiary' },
          }}
        >
          {items.map((item) => (
            <Box as="tr" key={item} css={{ paddingTop: '$one' }}>
              <td>
                <UtilityText size="sm" css={{ marginBottom: 16 }}>
                  <code>{item}</code>
                </UtilityText>
              </td>
              <td>
                <Button
                  as="a"
                  icon="tinyExternalLink"
                  size="medium"
                  hideLabel
                  href={`https://github.com/EverlongProject/league-web/blob/main/${item}`}
                  target="_blank"
                >
                  View in github
                </Button>
              </td>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal.Content>
  </Modal.Root>
);

interface ComponentUsageMap {
  [key: string]: ComponentUsage;
}

interface ComponentUsage {
  files: string[];
  modules: string[];
}

const UsageBox = ({ component, statsJSON }: UsageBoxProps) => {
  const componentUsageStats = useMemo(() => {
    const stats: ComponentUsageMap = statsJSON as ComponentUsageMap;
    const componentStats = stats[component] as any as ComponentUsage;

    return {
      modules: componentStats?.modules ?? [],
      files: Array.from(new Set(componentStats?.files ?? [])),
    };
  }, [component, statsJSON]);

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="stretch"
      verticalAlignment="center"
      css={{ paddingTop: '$one', paddingBottom: '$two' }}
    >
      <StackItem>
        <UtilityText>
          <DataModal
            buttonLabel={`In ${componentUsageStats.modules.length} modules`}
            title="Modules List"
            items={componentUsageStats.modules}
          />
        </UtilityText>
      </StackItem>
      <StackItem>
        <UtilityText>
          <DataModal
            buttonLabel={`In ${componentUsageStats.files.length} files`}
            title="Files List"
            items={componentUsageStats.files as string[]}
          />
        </UtilityText>
      </StackItem>
    </StackLayout>
  );
};

export const UsageBase = (props: UsageBoxProps) => (
  <GDSThemeProvider theme={storybookArchetypeLightTheme}>
    <UsageBox {...props} />
  </GDSThemeProvider>
);
