import merge from 'lodash.merge';
import { isDev } from '@leagueplatform/app-environment';
import type { ErrorContext } from './sentry/sentry.types';

const ENABLE_DEV_FLAG_STRING = 'ENABLE_DEV_OBSERVABILITY';

/**
 * A list of tenants to disable observability initialization for
 * Note: This overrides the backend config
 */
const DISABLED_APP_IDS = ['presenter-app-web'];

const isAppDisabled = (appId?: string) =>
  appId ? DISABLED_APP_IDS.includes(appId) : false;

export const combineErrorContexts = (contexts: Array<ErrorContext>) =>
  contexts.reduce((previousContext, currentContext) =>
    merge(previousContext, currentContext),
  );

export const isObservabilityEnabled = (appId?: string) =>
  (!isDev() || sessionStorage.getItem(ENABLE_DEV_FLAG_STRING) === 'true') &&
  !isAppDisabled(appId);

export const getDefaultConfigValues = () => ({
  dsn: 'https://fcb8e7e793e149acacb6ac0ad1546855@o4504039554088960.ingest.sentry.io/4504243159433216',
  enabled: true,
  maxBreadcrumbs: 100,
  errorsSampleRate: 1.0,
  tracesSampleRate: 0.01,
  integrateWithLeagueRouting: true,
});
