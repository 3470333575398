import React, { FC, useMemo } from 'react';
import { Box } from '@leagueplatform/genesis-core';

import type { SubmitHandler, FieldValues } from 'react-hook-form';

import {
  FormProvider as ReactHookFormProvider,
  useForm,
} from 'react-hook-form';
import { FormProviderProps } from '../types/react-hook-form-input.type';

const defaultSubmitHandler: SubmitHandler<FieldValues> = (_, event) =>
  event?.preventDefault();

/**
 * Establish a context for all nested inputs
 * @param formMethods [Methods returned by the `useForm` hook. See documentation here.](https://react-hook-form.com/api/useform/)
 * @param formOptions [Form configuration options. See documentation here.](https://react-hook-form.com/api/useform/)
 * @param includesFormElement {boolean} Will wrap children in a form element
 * @param css Form element styles, if used
 * @param onSubmit(formValues, event) – Handle successful form submission if `includesFormElement` is true
 * @param onError(formErrors, event) – Handle failed form submission if `includesFormElement` is true
 * @returns
 */
export const FormProvider: FC<FormProviderProps> = ({
  formMethods,
  formOptions,
  includesFormElement = true,
  css,
  onSubmit = defaultSubmitHandler,
  onError,
  children,
}) => {
  const methods = useForm(formOptions ?? {});
  const derivedMethods = useMemo(
    () => formMethods ?? methods,
    [formMethods, methods],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactHookFormProvider {...derivedMethods}>
      {includesFormElement ? (
        <Box
          as="form"
          css={css}
          onSubmit={derivedMethods.handleSubmit(onSubmit, onError)}
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </ReactHookFormProvider>
  );
};
