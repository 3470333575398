import * as React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  HealthMetric,
  MetricType,
  MetricUnit,
} from '@leagueplatform/dashboard-api';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import {
  SHORT_DATE_FORMAT,
  SHORT_MONTH_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import {
  useAverageMeasurement,
  useLastUpdatedValue,
  useTodaysMeasurement,
} from '../hooks/health-metrics-chart.hooks';
import { WeekMonthViewSwitch } from './week-month-view-switch';
import { PaginationControls } from './pagination-controls';
import { AverageValuePanel } from './average-value-panel';
import { TodaysMeasurementPanel } from './todays-measurement-panel';
import { LegacyChart } from './legacy-chart';

const ariaButtonLabelMap: Record<
  CHART_VIEW,
  { previous: string; next: string }
> = {
  [CHART_VIEWS.WEEKLY]: {
    previous: 'PREVIOUS_WEEK',
    next: 'NEXT_WEEK',
  },
  [CHART_VIEWS.MONTHLY]: {
    previous: 'PREVIOUS_30_DAYS',
    next: 'NEXT_30_DAYS',
  },
};

type HealthMetricsChartProps = {
  healthMetrics: HealthMetric[];
  chartView: CHART_VIEW;
  metricsFrom: Date;
  metric: MetricType;
  unit: MetricUnit;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onViewChange: (view: CHART_VIEW) => void;
};

export function HealthMetricsChart({
  healthMetrics,
  chartView,
  metricsFrom,
  unit,
  metric,
  hasPreviousPage,
  hasNextPage,
  onPreviousPage,
  onNextPage,
  onViewChange,
}: HealthMetricsChartProps) {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  const lastUpdated = useLastUpdatedValue(healthMetrics);

  const dateRangeStart = healthMetrics[healthMetrics.length - 1]?.timestamp;
  const dateRangeEnd = healthMetrics[0]?.timestamp;

  const averageMeasurement = useAverageMeasurement(metric, unit, healthMetrics);
  const todaysMeasurement = useTodaysMeasurement(metric, unit, healthMetrics);

  return (
    <>
      <WeekMonthViewSwitch
        onChange={(view) => onViewChange(view as CHART_VIEW)}
      />
      <Flex flexDirection="column" alignItems="center" marginBottom="two">
        <PaginationControls
          title={`${formatDateWithUserProfileLocation(
            dateRangeStart,
            SHORT_MONTH_FORMAT,
          )} - ${formatDateWithUserProfileLocation(
            dateRangeEnd,
            SHORT_DATE_FORMAT,
          )}`}
          previous={{
            label: formatMessage({
              id: ariaButtonLabelMap[chartView].previous,
            }),
            enabled: hasPreviousPage,
            onClick: onPreviousPage,
          }}
          next={{
            label: formatMessage({
              id: ariaButtonLabelMap[chartView].next,
            }),
            enabled: hasNextPage,
            onClick: onNextPage,
          }}
        />
        <AverageValuePanel measurement={averageMeasurement} />
        <Flex width="100%" height="500px" marginBottom="twoAndHalf">
          <LegacyChart
            chartView={chartView}
            healthMetrics={healthMetrics}
            metric={metric}
            unit={unit}
            metricsFrom={metricsFrom}
            hasPreviousPage={hasPreviousPage}
          />
        </Flex>
        <TodaysMeasurementPanel
          lastUpdated={lastUpdated}
          measurement={todaysMeasurement}
        />
      </Flex>
    </>
  );
}
