import { useQuery } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import {
  getSuggestedUserActivities,
  GET_SUGGESTED_ACTIVITIES,
} from '@leagueplatform/health-journey-api';

export const useSuggestedActivities = () => {
  const { groupId } = useParams();
  const { data, isLoading, isError } = useQuery(
    [GET_SUGGESTED_ACTIVITIES, groupId],
    () => getSuggestedUserActivities(groupId),
  );
  const {
    name: title,
    icon_url: iconUrl,
    description,
    suggested_activity_group_id: suggestedActivityGroupId,
  } = data?.suggested_activity_group ?? {};
  const activities = data?.user_health_activities;

  return {
    ready: !isLoading,
    hasError: isError,
    activityArray: activities,
    suggestedGroup: {
      title,
      iconUrl,
      description,
      suggestedActivityGroupId,
    },
  };
};
