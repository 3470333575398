import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  HeadingThree,
  BodyOne,
  DangerButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { VeryQuietButton } from '@leagueplatform/web-common-components';
import { HealthActivityModal } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { useActivityAnalytics } from '../hooks/use-activity-details-analytics.hook';

const HeaderLine = genesisStyled(Box)(
  css({
    width: '100%',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
  }),
);
const modalStyleOverrides = {
  body: css({
    padding: 'none',
  }),
};

export const DeleteActivityModal = ({
  onClose,
  activityId,
  requestSetUserHealthActivityDelete,
}) => {
  const { formatMessage } = useIntl();
  const activityAnalytics = useActivityAnalytics();

  useEffect(() => {
    if (activityId) {
      activityAnalytics.sendViewRemoveActivityModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  const modalLabel = formatMessage({ id: 'REMOVE_ACTIVITY' });
  return (
    <HealthActivityModal
      onClose={onClose}
      aria-label={modalLabel}
      styleOverrides={modalStyleOverrides}
      isNarrow
    >
      <HeaderLine>
        <HeadingThree as="h1" margin="oneAndHalf">
          {modalLabel}
        </HeadingThree>
      </HeaderLine>
      <Box paddingX="two" paddingY="oneAndHalf">
        <BodyOne marginBottom="half">
          {formatMessage({ id: 'ARE_YOU_SURE_REMOVE_ACTIVITY' })}
        </BodyOne>
        <Flex
          flexDirection={{ _: 'column-reverse', phone: 'row' }}
          justifyContent={{ phone: 'flex-end' }}
          alignItems={{ phone: 'flex-end' }}
        >
          <VeryQuietButton
            marginRight={{ phone: 'half' }}
            marginTop={{ _: 'half', phone: 'none' }}
            onClick={() => {
              onClose();
              activityAnalytics.sendCancelActivityRemove();
            }}
          >
            {formatMessage({ id: 'CANCEL' })}
          </VeryQuietButton>
          <DangerButton
            onClick={() => {
              requestSetUserHealthActivityDelete();
              onClose();
              activityAnalytics.sendConfirmActivityRemove();
            }}
            marginTop="oneAndHalf"
          >
            {formatMessage({ id: 'REMOVE_ACTIVITY_BUTTON' })}
          </DangerButton>
        </Flex>
      </Box>
    </HealthActivityModal>
  );
};

DeleteActivityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  activityId: PropTypes.string,
  requestSetUserHealthActivityDelete: PropTypes.func.isRequired,
};

DeleteActivityModal.defaultProps = {
  activityId: '',
};
