import { useState } from 'react';
import {
  useCompleteActivity,
  ACHIEVEMENTS_ENABLED,
} from '@leagueplatform/rewards-achievements';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

export const useActivityAchievementsActions = (userActivityId) => {
  const [showDeleteActivityModal, toggleDeleteActivityModal] = useState(false);
  const [shouldFetchAchievementUpdates, toggleAchievementUpdates] =
    useState(false);
  const [showCelebrationAchievementModal, toggleCelebrationAchievementModal] =
    useState(false);

  const { data: isAchievementsFeatureFlagOn } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  const completeActivityMutation = useCompleteActivity(
    userActivityId,
    toggleAchievementUpdates,
  );

  return {
    completeActivityMutation,
    isAchievementsFeatureFlagOn,
    shouldFetchAchievementUpdates,
    showCelebrationAchievementModal,
    showDeleteActivityModal,
    toggleCelebrationAchievementModal,
    toggleDeleteActivityModal,
  };
};
