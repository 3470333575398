import React from 'react';
import { css, genesisStyled, Image } from '@leagueplatform/genesis-commons';
import {
  StackLayout,
  StackItem,
  HeadingText,
  ParagraphText,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { WidgetType } from '@leagueplatform/masonry-api';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export type MasonryXSCardNodeProperties = {
  heading: string;
  image?: string;
  body?: string;
  imageAltText?: string;
  backgroundColor?: string;
  analytics?: Record<string, any>;
};

export type MasonryXSCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryXSCardNode = MasonryEngineNode<
  WidgetType.XS_VISUAL_CARD,
  MasonryXSCardNodeProperties,
  MasonryXSCardNodeActions
>;

export type MasonryXSCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryXSCardNode>;

const StyledImage = genesisStyled(Image)(
  css({
    contentFit: 'cover',
    contentPosition: 'center',
    width: '42px',
    height: '42px',
  }),
);

export const MasonryXSCardRenderer = ({
  heading,
  image,
  body,
  imageAltText,
  backgroundColor,
  onClick,
}: MasonryXSCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(onClick?.payload.url)}
      css={{
        backgroundColor: backgroundColor || '$surfaceBackgroundPrimary',
      }}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing={image ? '$threeQuarters' : undefined}
        css={{
          padding: '$oneAndHalf',
        }}
      >
        {image && (
          <StackItem verticalAlignment="center">
            <StyledImage src={image} alt={imageAltText} />
          </StackItem>
        )}
        <StackLayout css={{ marginTop: '$quarter' }}>
          <HeadingText level="3" size="sm">
            <ConditionalLinkRenderer
              ref={primaryActionRef}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
              url={onClick?.payload.url}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>
          {body && (
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {body}
            </ParagraphText>
          )}
        </StackLayout>
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
