import { useConfigProperty } from '@leagueplatform/core';

export interface WalletConfig {
  showBenefitDetailFeedbackComponent?: boolean;
  showLandingPageHeaderBranding?: boolean;
  showBenefitStatusBannerCreated?: boolean;
  showBenefitStatusBannerPending?: boolean;
  showBenefitStatusBannerExpired?: boolean;
  showLandingPageClaimsEntry?: boolean;
  typeformIds?: {
    en: string;
    es: string;
    fr: string;
  };
}

const defaultConfig = {
  showBenefitDetailFeedbackComponent: false,
  showLandingPageHeaderBranding: false,
  // TODO: Benefit Status Banner flags should be BE feature flags instead and will be updated soon
  showBenefitStatusBannerCreated: false,
  showBenefitStatusBannerPending: false,
  showBenefitStatusBannerExpired: false,
  showLandingPageClaimsEntry: false,
};

export const useWalletConfig = () => useConfigProperty('wallet', defaultConfig);
