import React from 'react';
import { node } from 'prop-types';
import {
  BodyTwo,
  css,
  Flex,
  genesisStyled,
  useTheme,
} from '@leagueplatform/genesis-commons';

const AlertIcon = () => {
  const theme = useTheme();
  const iconColor =
    theme?.colors?.primary?.background?.critical?.default ?? '#BA1B3B';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 15.3337C12.0494 15.3337 15.3327 12.0504 15.3327 8.00033C15.3327 3.95024 12.0494 0.666992 7.99935 0.666992C3.94926 0.666992 0.666016 3.95024 0.666016 8.00033C0.666016 12.0504 3.94926 15.3337 7.99935 15.3337Z"
        stroke={iconColor}
        strokeWidth="1.5"
      />
      <path
        d="M8.39961 12.0006C8.39961 12.2215 8.22052 12.4006 7.99961 12.4006C7.7787 12.4006 7.59961 12.2215 7.59961 12.0006C7.59961 11.7797 7.7787 11.6006 7.99961 11.6006C8.22052 11.6006 8.39961 11.7797 8.39961 12.0006Z"
        stroke={iconColor}
      />
      <path
        d="M8 9.6L8 4"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const StyledAlertIcon = genesisStyled(AlertIcon)(
  css({
    path: {
      stroke: 'primary.background.critical.default',
    },
  }),
);

export const ActivityErrorMessage = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Flex justifyContent="center" alignItems="center" {...props}>
    <StyledAlertIcon />
    <BodyTwo color="onSurface.text.critical" marginLeft="half">
      {children}
    </BodyTwo>
  </Flex>
);

ActivityErrorMessage.propTypes = {
  children: node.isRequired,
};
