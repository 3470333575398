import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Overline,
  SubtitleOne,
  Link,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { Icon } from '@leagueplatform/genesis-core';
import { DynamicCoverImage } from 'components/';
import { useActivityAnalytics } from 'hooks/use-activity-details-analytics.hook';
import { useHistory } from '@leagueplatform/routing';
import { useActivityMarkAsDone } from 'hooks/use-activity-mark-as-done.hook';
import { useConfigProperty } from '@leagueplatform/core';
import { isValidUrl } from '@leagueplatform/web-common';

const StyledFlexLink = genesisStyled(Flex)`
  &:hover, &:focus {
    text-decoration: none;
    .pseudo-link {
      text-decoration: underline;
    }
  }
`;

export const ActivityHelpfulTip = ({
  id,
  type,
  title,
  image,
  url,
  isAssigned,
  redirectBackToAssigned,
  activityId,
  activityType,
}) => {
  const { formatMessage } = useIntl();
  const { sendSelectHelpfulTip } = useActivityAnalytics();
  const history = useHistory();
  const { markAsDone } = useActivityMarkAsDone(id);
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const handleClick = (e) => {
    markAsDone();

    if (redirectBackToAssigned) {
      e.preventDefault();
      history.push(url.replace(window.location.origin, ''), {
        activityId,
        activityType,
      });
    } else if (isValidUrl(url) && !!handleLinkCallback) {
      e.preventDefault();
      handleLinkCallback(url);
    }

    sendSelectHelpfulTip({ type, title, url });
  };
  return (
    <StyledFlexLink
      as={Link}
      href={url}
      target={redirectBackToAssigned ? '_self' : '_blank'}
      rel="noopener noreferrer"
      onClick={handleClick}
      flexDirection={{ _: 'column-reverse', phone: 'row' }}
      alignItems={{ _: 'flex-start', phone: 'center' }}
      justifyContent="space-between"
      padding="oneAndHalf"
      marginTop="three"
      marginBottom="oneAndHalf"
      borderRadius="medium"
      backgroundColor="surface.background.secondary"
    >
      <Flex flexDirection="column" marginRight="oneAndHalf">
        {type && (
          <Overline as="p" paddingBottom="half" color="onSurface.text.subdued">
            {type}
          </Overline>
        )}
        {title && (
          <SubtitleOne as="h3" paddingBottom="quarter">
            {title}
          </SubtitleOne>
        )}
        <SubtitleOne
          as="p"
          className="pseudo-link"
          marginRight="quarter"
          color="interactive.action.primary"
          paddingTop="one"
          display="flex"
          css={{ gap: '0.5ch' }}
          alignItems="center"
        >
          {isAssigned
            ? formatMessage({ id: 'VISIT_LINK' })
            : formatMessage({ id: 'LEARN_MORE' })}
          <Icon icon="tinyExternalLink" size="1em" />
        </SubtitleOne>
      </Flex>
      <DynamicCoverImage
        src={image}
        backgroundSize="contain"
        alt="helpful tip"
        size={{ _: 32, phone: 64 }}
        minWidth={{ _: '32px', phone: '64px' }}
        marginBottom={{ _: 'one', phone: 'none' }}
      />
    </StyledFlexLink>
  );
};

ActivityHelpfulTip.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  redirectBackToAssigned: PropTypes.bool.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
};
