import React, { forwardRef } from 'react';
import { Button } from 'components/button/button';
import { useThemeStrings } from 'hooks/use-theme';
import type {
  GDSCustomizableComponent,
  GDSInputStatus,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../../../types';
import type { GDSHTMLAutocomplete } from '../types';
import { styled } from '../../../theme';
import { InputElements } from '../input-elements/input-elements';
import {
  inputStyles,
  affixedBaseInputStyles,
  disabledInputStyles,
  readOnlyInputStyles,
} from '../input-styles';
import { focusOutlineInner } from '../../../theme/utils/focus-outline';

export type GDSBaseInputProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      inputStatus?: GDSInputStatus;
      clearable?: boolean;
      clearLabel?: string;
      ariaDescribedby?: string;
      onClear?: (event: React.SyntheticEvent) => void;
      onClick?: (event: React.SyntheticEvent) => void;
      /**
       * Identify the purpose of the input through the autocomplete attribute. This allows users to easily fill form using information stored in their browsers. It should be [a valid HTML5 input autocomplete value](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete), review the complete list understand the purpose of each of the values before applying it to a field.
       *
       */
      autoComplete?: GDSHTMLAutocomplete;
      /**
       * Indicate what action label (or icon) to present for the enter key on virtual keyboards. It should be [a valid enterkeyhint value](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint).
       *
       */
      enterkeyhint?:
        | 'enter'
        | 'done'
        | 'go'
        | 'next'
        | 'previous'
        | 'search'
        | 'send';
    }
  > &
    GDSCustomizableComponent;

export type GDSBaseInputComponent = <C extends React.ElementType = 'input'>(
  props: GDSBaseInputProps<C>,
) => React.ReactElement | null;

const Input = styled('input', {
  ...inputStyles,
  typography: '$bodyTwo',
  color: '$onSurfaceTextPrimary',
  backgroundColor: '$surfaceBackgroundPrimary',
  transition: 'box-shadow $default ease-in-out',
  '&:focus': {
    borderColor: '$interactiveFocusInner',
  },
  '&:placeholder': {
    typography: '$bodyTwo',
    color: '$onSurfaceTextSubdued',
  },
  '&:hover:not(:focus):not([disabled])': {
    borderColor: '$inputBorderHovered',
    backgroundColor: '$inputBackgroundHovered',
  },
  '&:read-only, &[aria-readonly="true"]': {
    ...readOnlyInputStyles,
  },
  '&:disabled, &[aria-disabled="true"]': {
    ...disabledInputStyles,
  },
  variants: {
    inputStatus: {
      success: {
        borderColor: '$inputBorderSuccess',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderSuccess',
        },
      },
      warning: {
        borderColor: '$inputBorderWarning',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderWarning',
        },
      },
      error: {
        borderColor: '$inputBorderCritical',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderCritical',
        },
      },
    },
  },
});

const BaseInputWrapper = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'stretch',
  width: '100%',
  ...affixedBaseInputStyles,
  '&.GDS-readonly-input': {
    ...readOnlyInputStyles,
  },
  '&.GDS-disabled-input': {
    ...disabledInputStyles,
  },
  '.GDS-clear-input-button': {
    border: 'none',
    backgroundColor: '$inputBackgroundDefault',
  },
  '[class*="GDS"]:focus': {
    ...focusOutlineInner,
  },
  '&:hover': {
    '.GDS-clear-input-button': {
      color: '$secondaryTextDefault',
      backgroundColor: '$inputBackgroundHovered',
    },
  },
});

export const BaseInput: GDSBaseInputComponent = forwardRef(
  <C extends React.ElementType = 'input'>(
    {
      as: asElement,
      css,
      id,
      name,
      autoComplete,
      required,
      type = 'text',
      placeholder,
      inputStatus,
      disabled,
      'aria-disabled': ariaDisabled,
      value,
      ariaDescribedby,
      clearable,
      clearLabel,
      onClear,
      onClick,
      onChange,
      onBlur,
      onFocus,
      readOnly,
      maxLength,
      className,
      ...props
    }: GDSBaseInputProps<C>,
    ref: PolymorphicRef<C>,
  ) => {
    const stringsMap = useThemeStrings();
    // The component is a text input by default, so if `as` is undefined, it's text
    const defaultType = !type && !asElement ? 'text' : undefined;

    return (
      <BaseInputWrapper
        className={`GDS-base-input-wrapper
        ${clearable ? 'GDS-affixed-base' : ''}
        ${disabled && clearable ? 'GDS-disabled-input' : ''}
        ${readOnly && clearable ? 'GDS-readonly-input' : ''}
        ${inputStatus && clearable ? `GDS-input-status-${inputStatus}` : ''}
        `}
        css={css}
      >
        <Input
          as={asElement}
          id={id}
          name={name}
          type={type || defaultType}
          className={`GDS-base-input ${className || ''}`}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          autoComplete={autoComplete}
          value={value}
          {...(inputStatus === 'error' && { 'aria-invalid': true })}
          aria-describedby={ariaDescribedby}
          inputStatus={inputStatus}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          ref={ref}
          {...props}
        />
        {/* add clear icon */}
        {clearable && (
          <InputElements.AffixContent
            className="GDS-clear-input"
            position="trailing"
          >
            <Button
              className="GDS-clear-input-button"
              type="button"
              icon="tinyClear"
              hideLabel
              quiet
              priority="secondary"
              size="small"
              disabled={disabled}
              onClick={onClear}
            >
              {clearLabel || stringsMap.clear}
            </Button>
          </InputElements.AffixContent>
        )}
      </BaseInputWrapper>
    );
  },
);

// @ts-ignore
BaseInput.displayName = 'BaseInput';
