import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { getEnvironmentName } from '../utils/utils';

export const sendAnalyticsToLeague = async (params: any, name: string) => {
  const payload = {
    name,
    params: {
      ...params,
      platform: 'web',
      environment: getEnvironmentName(),
      userAgent: window.navigator.userAgent,
    },
    echo: true,
  };

  SocketAsFetch.fetch({
    message_type: 'set_analytics_event',
    info: payload,
  });
};
