import { useParams } from '@leagueplatform/routing';

interface ActivityUrlParams {
  userActivityId?: string;
  campaignId?: string;
  activityId?: string;
}
/**
 * Returns the following activity parameters:
 * @param {string} userActivityId The users activity ID that is generated upon enrolment into that activity
 * @param {string} contentActivityId The generic contentful ID for the activity
 * @param {string} campaignId The campaign ID that the activity belongs to
 */
export const useActivityParams = () => {
  const {
    userActivityId,
    campaignId,
    activityId: contentActivityId,
  }: ActivityUrlParams = useParams();

  return { userActivityId, campaignId, contentActivityId };
};
