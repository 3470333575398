import { useEffect, useCallback, useState, useRef } from 'react';
import type { GDSBreakpoint } from '../types';
import type { GDSMediaQueryItems } from './types';

export function useThemeMediaQueries(media: {
  [key in GDSBreakpoint]: string;
}) {
  const queryItemsRef = useRef<GDSMediaQueryItems>({});
  const [queryItems, setQueryItems] = useState<GDSMediaQueryItems>({});

  const createHandler = useCallback(
    (breakpoint: GDSBreakpoint) => (event: any) => {
      const matches = Boolean(event.matches);
      const queryItem = queryItemsRef.current[breakpoint];
      if (queryItem && matches !== queryItem.active) {
        setQueryItems({}); // reset
      }
    },
    [queryItemsRef],
  );

  useEffect(() => {
    const newQueryItems: GDSMediaQueryItems = {};
    const hasQueryItems = Object.keys(queryItems).length > 0;
    if (!hasQueryItems) {
      Object.entries(media).forEach(([breakpoint, query]: [string, string]) => {
        const mediaQuery = window.matchMedia(query);
        const handler = createHandler(breakpoint as GDSBreakpoint);
        mediaQuery.addEventListener('change', handler);
        newQueryItems[breakpoint as GDSBreakpoint] = {
          active: mediaQuery.matches,
          mediaQuery,
          handler,
          query,
        };
      });
      queryItemsRef.current = newQueryItems;
      setQueryItems(newQueryItems);
    }

    const queryItemsCopy = queryItems;
    const onDestroy = () => {
      if (hasQueryItems) {
        Object.values(queryItemsCopy).forEach(({ mediaQuery, handler }) => {
          if (mediaQuery && handler)
            mediaQuery.removeEventListener('change', handler);
        });
      }
    };
    return onDestroy;
  }, [media, createHandler, queryItems]);

  return queryItems;
}
