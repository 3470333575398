import { CARD_STATUS } from './card-action.types';
import { SYSTEM_TYPE } from './benefit-card-action.types';
import { Dependent } from './dependents';

export interface UserInsuranceBenefitCardDetails {
  member_policy_number?: string;
  rx_bin?: string;
  rx_carrier?: string;
  rx_group?: string;
  rx_id?: string;
  rx_pcn?: string;
  provider_policy_number?: string;
}

export interface SpendingAccount {
  balance?: {
    C: string;
    F: number;
    M: number;
  };
}

export interface CardTemplateRenderer {
  front: string;
  name: string;
  options?: object;
}

// TODO: fill out these interfaces
export interface UserSpendingAccountBenefitCardDetails {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
interface DependentCards {}
interface CoverageParams {}
interface CardInfoParams {}

// TODO: merge with `card-action.constants.ts`'s ACTION_TYPES
// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum BENEFIT_CARD_ACTION {
  CONTENT = 'content',
  OPEN_URL = 'open-url',
  ORDER_CARD = 'order_card',
}
export interface FontAwesomeIconInfo {
  name: string;
  icon_path: string;
  unicode: string;
}

export interface BenefitBanners {
  [label: string]: {
    description: string;
    title: string;
    link?: { text: string; url: string };
  };
}

export interface BenefitDetailBanner {
  banner_type: 'info' | 'warning' | 'danger';
  title: string;
  description: string;
  icon?: {
    url: string;
    alt: string;
  };
  action_url?: string;
  action_label?: string;

  link?: {
    text: string;
    url: string;
  };
}

export interface UnavailableAction {
  logo_url?: string;
  title: string;
  description: string;
  phone_number?: string;
}

export interface BenefitCardAction {
  banners: BenefitBanners;
  body?: string;
  description?: string;
  disabled?: boolean;
  font_awesome_icon?: FontAwesomeIconInfo;
  id?: string;
  indicator: boolean;
  indicator_description?: string;
  name: string;
  primary?: boolean;
  system_type?: SYSTEM_TYPE;
  tag_type?: string;
  title?: string;
  type: BENEFIT_CARD_ACTION;
  url?: string;
  unavailable_action?: UnavailableAction;
  category_id?: string;
  nav_icon?: ActionNavIcon;
  icon_url?: string;
}

export interface BenefitFeaturedImage {
  type: string;
  src: string;
  description: string;
}

type CardImageType = 'html' | 'url' | 'base64';
export interface CardImage {
  type: CardImageType;
  src: string;
  description: string;
}

export interface CardImages {
  front: CardImage;
  back?: CardImage;
  actions?: BenefitCardAction[];
}

export interface BenefitContentConfiguration {
  show_policy_dates?: boolean;
  show_cost_and_coverage?: boolean;
}

export interface ActionNavIcon {
  url: string;
  alt: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum OLD_BENEFITS_TYPE {
  psa = 'LIFESTYLE_SPENDING_ACCOUNT',
  hsa = 'HEALTH_SPENDING_ACCOUNT',
  hra = 'HEALTH_REIMBURSEMENT_ARRANGEMENT',
  healthPlan = 'HEALTH_INSURANCE',
  travel = 'TRAVEL',
  drugs = 'DRUGS',
  promo_code = 'LEAGUE_CREDIT',
  medical = 'MEDICAL',
}

export type BenefitType = keyof typeof OLD_BENEFITS_TYPE;

export interface CategorizedActions {
  title: string;
  actions: BenefitCardAction[];
}

export interface PolicyInfo {
  key?: string;
  value?: string;
}

export interface WalletBenefitCard {
  title?: string;
  subtitle?: string;
  actions: BenefitCardAction[] | null;
  banners?: BenefitDetailBanner[];
  content_actions?: BenefitCardAction[];
  primary_action?: BenefitCardAction;
  categorized_actions?: CategorizedActions[];
  formatted_end_date?: string;
  formatted_start_date?: string;
  plan_name?: string;
  card_html?: string;
  card_images?: CardImages;
  policy_end_date?: string;
  policy_start_date?: string;
  activation_date?: string;
  background_url?: string;
  beneficiary_applies?: boolean;
  card_info_params?: CardInfoParams;
  class_id?: string;
  contentful_enabled?: boolean;
  content_configuration?: BenefitContentConfiguration;
  country_code?: string;
  coverage_params?: CoverageParams;
  dependent_class_id?: string;
  dependents?: Dependent[];
  description?: string;
  end_date?: string;
  full_name?: string;
  grace_period_duration_in_days?: number;
  grace_period_start_date?: string;
  id: string;
  inactive_date?: string;
  insurance?: UserInsuranceBenefitCardDetails;
  logo_url?: string;
  member_first_name?: string;
  member_last_name?: string;
  parent_benefit_type?: string;
  parent_id?: string;
  product_type: BenefitType;
  provider_id?: string;
  provider_name?: string;
  reinstated_date?: string;
  rendered_template?: CardTemplateRenderer;
  short_activation_date?: string;
  short_end_date?: string;
  short_inactive_date?: string;
  short_name?: string;
  short_start_date?: string;
  show_user_benefit_live_balances?: boolean;
  spending_account?: UserSpendingAccountBenefitCardDetails;
  start_date?: string;
  status: CARD_STATUS;
  stripe_hex_colour?: string;
  template_id?: string;
  type: string;
  vendor_id?: string;
  view_type?: string;
  featured_image?: BenefitFeaturedImage;
  icon_url?: string;
  benefit_logo?: CardImage; // alternative to `icon_url` for when we need base64 support
  benefit_source?: string;
  policy_info?: PolicyInfo[];
  use_balance_end_date?: string;
  submit_claims_end_date?: string;
  client_number?: string;
}

export interface LeagueCreditBenefitCard
  extends Omit<
      WalletBenefitCard,
      'product_type' | 'type' | 'rendered_template'
    >,
    StoreCreditBalance {
  product_type: 'promo_code';
  type: 'personal_store_credit_balance';
}

export enum CURRENCY {
  CAD,
  USD,
  EUR,
}
export interface StoreCreditBalance
  extends Pick<
    WalletBenefitCard,
    'content_actions' | 'actions' | 'categorized_actions' | 'primary_action'
  > {
  amount: number;
  currency: CURRENCY;
  policy_allocation: number;
  total_allocation: number;
  rendered_template: CardTemplateRenderer;
  wallet_item_id: 'personal_store_credit_balance';
}
