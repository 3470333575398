import React from 'react';
import { TextInput as GenesisTextInput } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ControlledFormField } from '@leagueplatform/web-common';

export interface TextInputProps {
  labelText?: string;
  name: string;
  id: string;
  required?: boolean;
  defaultValue?: string;
  maxLength?: number;
  regex?: string;
}

export const TextInput = ({
  labelText,
  defaultValue,
  id,
  name,
  required,
  regex,
  maxLength,
}: TextInputProps) => {
  const { formatMessage } = useIntl();
  const inputRules = {
    ...(required && {
      required: {
        value: true,
        message: formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' }),
      },
    }),
    ...(maxLength && {
      maxLength: {
        value: maxLength,
        message: `You are over the maximum character limit (${maxLength})`,
      },
    }),
    ...(regex && {
      pattern: {
        value: new RegExp(regex),
        message: 'Please enter a valid format',
      },
    }),
  };

  return (
    <ControlledFormField
      name={name}
      id={name}
      inputOptions={{
        ...inputRules,
        value: defaultValue,
      }}
      label={labelText}
    >
      <GenesisTextInput id={id} />
    </ControlledFormField>
  );
};
