import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { ProgramHeading } from '@leagueplatform/health-journey-common';
import { ChallengeTypes } from '@leagueplatform/health-journey-api';
import {
  Box,
  Responsive,
  StackLayout,
  UtilityText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { ProgressTracker } from 'components/progress-tracker/progress-tracker.component';
import { ChallengeDetailsContents } from 'components/challenge-details-contents/challenge-details-contents.component';
import { ChallengeDetailsCardBanner } from 'components/challenge-details-card-banner/challenge-details-card-banner.component';
import { HeadingSettingsCog } from './heading-settings-cog/heading-settings-cog.component';

export interface ChallengeHeadingProps {
  bannerText?: string;
  image?: string;
  imageAltText?: string;
  title: string;
  userIsEnrolled: boolean;
  status: string;
  challengeType?: ChallengeTypes;
}

export const ChallengeHeading = ({
  bannerText,
  image,
  imageAltText,
  title,
  userIsEnrolled,
  status,
  challengeType,
}: ChallengeHeadingProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const isChallengeLeavable =
    status === CHALLENGE_STATUS.UPCOMING || status === CHALLENGE_STATUS.ACTIVE;
  const isSoloChallenge = challengeType === 'solo';
  const badgeProps = {
    css: {
      backgroundColor: '$warningBackgroundSubdued',
      borderRadius: '$small',
    },
    iconTextProps: {
      iconProps: {
        icon: isSoloChallenge ? 'tinyStar' : 'capabilityGroupChallenges',
        tint: '$warningBorderDefault',
      },
      text: (
        <UtilityText size="eyebrow" emphasis="emphasized">
          {formatMessage({
            id: isSoloChallenge ? 'SOLO_CHALLENGE' : 'GROUP_CHALLENGE',
          })}
        </UtilityText>
      ),
    },
  } as const;
  const displaySettings = userIsEnrolled && isChallengeLeavable;
  return (
    <ProgramHeading
      bannerText={bannerText}
      image={image}
      imageAltText={imageAltText}
      title={title}
      badge={challengeType && badgeProps}
      settingsComponent={
        displaySettings && (
          <HeadingSettingsCog
            status={status}
            isSoloChallenge={isSoloChallenge}
          />
        )
      }
    >
      <Box css={{ width: '100%' }}>
        <Responsive down="laptop">
          <StackLayout
            spacing="$oneAndHalf"
            horizontalAlignment={
              isMobile && !displaySettings ? 'center' : 'stretch'
            }
          >
            <ChallengeDetailsContents />
            <ChallengeDetailsCardBanner />
            {userIsEnrolled && <ProgressTracker />}
          </StackLayout>
        </Responsive>
      </Box>
    </ProgramHeading>
  );
};
