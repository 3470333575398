import React from 'react';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/rewards-achievements';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { PointsIcon, ChallengeTokenIcon } from '@leagueplatform/rewards-common';
import { Badge } from '@leagueplatform/web-common-components';

interface EarnedOrMissedRewardCaptionProps {
  isCompleted: boolean;
  isChallenge: boolean;
  reward: number;
}

const getPointsBadgeProps = ({
  isCompleted,
  isChallenge,
}: {
  isCompleted: boolean;
  isChallenge: boolean;
}) => {
  const {
    LEAGUE_LOGO,
    LEAGUE_LOGO_SUBDUED,
    CHALLENGE_TOKEN,
    CHALLENGE_TOKEN_SUBDUED,
  } = HEALTH_JOURNEY_ASSET_KEYS;
  const leagueLogo = handleStaticAsset(
    isCompleted ? LEAGUE_LOGO : LEAGUE_LOGO_SUBDUED,
  ) as string;
  const challengeToken = handleStaticAsset(
    isCompleted ? CHALLENGE_TOKEN : CHALLENGE_TOKEN_SUBDUED,
  ) as string;

  switch (true) {
    case isCompleted && isChallenge:
      return {
        pointsIconSrc: challengeToken,
        backgroundColor: '$surfaceBackgroundSecondary',
        color: '$onSurfaceTextPrimary',
        messageId: 'TOKENS_EARNED',
      } as const;
    case !isCompleted && isChallenge:
      return {
        pointsIconSrc: challengeToken,
        backgroundColor: '$surfaceBackgroundSecondary',
        color: '$onSurfaceTextSubdued',
        messageId: 'TOKENS_MISSED',
      } as const;
    case !isCompleted && !isChallenge:
      return {
        pointsIconSrc: leagueLogo,
        backgroundColor: '$surfaceBackgroundSecondary',
        color: '$onSurfaceTextSubdued',
        messageId: 'POINTS_MISSED',
      } as const;
    case isCompleted && !isChallenge:
    default:
      return {
        pointsIconSrc: leagueLogo,
        backgroundColor: '$successBackgroundSubdued',
        color: '$onSurfaceTextPrimary',
        messageId: 'POINTS_EARNED',
      } as const;
  }
};

// TODO: Update internals of this component to utilize the new RewardsPill
// component after merge of https://github.com/EverlongProject/league-web/pull/9913
export const EarnedOrMissedRewardCaption = ({
  isCompleted,
  isChallenge,
  reward,
}: EarnedOrMissedRewardCaptionProps) => {
  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  if (!isChallenge && !isAchievementsEnabled) {
    return null;
  }
  const { backgroundColor, color, messageId } = getPointsBadgeProps({
    isCompleted,
    isChallenge,
  });

  return (
    <Badge
      css={{ backgroundColor, color, marginTop: '$half' }}
      messageId={messageId}
    >
      {isChallenge ? (
        <ChallengeTokenIcon value={reward} subdued={!isCompleted} />
      ) : (
        <PointsIcon value={reward} subdued={!isCompleted} />
      )}
    </Badge>
  );
};
