import {
  useMemo,
  Reducer,
  useReducer,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import jsonLogic from 'json-logic-js';
import { HealthActivityDeserializedData } from '@leagueplatform/health-journey-api';
import { HealthActivityContext } from '../contexts/health-activity-provider.component';
import { safeJsonParse } from '../utils/toolbox-json.util';
// Types
interface HealthActivityNavigationProps {
  healthActivityData?: HealthActivityDeserializedData;
  initialPageIndex?: number;
}

enum HealthActivityNavigationActions {
  Next = 'next',
  Prev = 'prev',
  Last = 'last',
}

interface HealthActivityNavigationState {
  pageHistory: number[];
  isLastPage: boolean;
}

type NextAction = {
  type: HealthActivityNavigationActions.Next;
  nextPage: number;
  isLastPage?: boolean;
};

type PrevAction = {
  type: HealthActivityNavigationActions.Prev;
  nextPage: number;
  isLastPage?: boolean;
};

type LastAction = {
  type: HealthActivityNavigationActions.Last;
  isLastPage: boolean;
};

type HealthActivityNavigationAction = NextAction | PrevAction | LastAction;

type OnNextPageArgs = {
  nextPage: number;
  goToNextPage(): void;
};

export type OnNextPage = (nextPageArgs: OnNextPageArgs) => void;

interface NextOptions {
  onNextPage?: OnNextPage;
  onLastPage?: (currentPage: number) => void;
}

// Constants
const ROOT_PAGE_INDEX = 0;
const { Next, Prev, Last } = HealthActivityNavigationActions;

// Utils
const buildNavigationStack = (
  currentNavigationStack: number[],
  currentPageIndex: number,
) => {
  // Ensure the next page is in the stack
  const updatedNavigationStack = [...currentNavigationStack, currentPageIndex];

  // Find the first index of the current page within the stack (not inclusive, so 1 is added)
  const stackLength = updatedNavigationStack.indexOf(currentPageIndex) + 1;

  // Build new instance of the stack up to, and including the first instance of the current page
  const amendedNavigationStack = updatedNavigationStack.slice(0, stackLength);

  // Ensure the navigation stack contains no duplicate indices
  const nextNavigationStack = Array.from(new Set(amendedNavigationStack));
  return nextNavigationStack;
};

const reducer: Reducer<
  HealthActivityNavigationState,
  HealthActivityNavigationAction
> = (state, action) => {
  const { type } = action;
  const { pageHistory } = state;

  switch (type) {
    case Next:
    case Prev:
      return {
        ...state,
        isLastPage: Boolean(action?.isLastPage),
        pageHistory: buildNavigationStack(pageHistory, action.nextPage),
      };
    case Last:
      return {
        ...state,
        isLastPage: action.isLastPage,
      };
    default:
      return state;
  }
};

export const useActivityNavigation = ({
  healthActivityData,
}: HealthActivityNavigationProps) => {
  const {
    healthAssessmentManager,
    currentPage: initialPageIndex,
    setCurrentPage,
  } = useContext(HealthActivityContext);
  const { getQuestionsForPages } = healthAssessmentManager;
  const { pages } = healthActivityData ?? {};
  const totalPages = (pages?.length || 1) - 1;
  const allPageIndices = useMemo(
    () =>
      Array(pages?.length)
        .fill(undefined)
        .map((_, pageIndex) => pageIndex),
    [pages?.length],
  );
  const [navigationState, dispatch] = useReducer(reducer, {
    pageHistory: buildNavigationStack([ROOT_PAGE_INDEX], initialPageIndex),
    isLastPage: initialPageIndex >= totalPages,
  });
  const { pageHistory, isLastPage } = navigationState;

  const [currentPage = ROOT_PAGE_INDEX] = useMemo(
    () => navigationState?.pageHistory.slice(-1),
    [navigationState?.pageHistory],
  );

  const skippedPages = useMemo(
    () =>
      allPageIndices.filter((pageIndex) => !pageHistory.includes(pageIndex)),
    [allPageIndices, pageHistory],
  );

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage, setCurrentPage]);

  const getNextPageIndex = useCallback(() => {
    // As a precaution, increment the currentPage if pages are falsy to prevent any dead-end flows
    if (!pages) return currentPage + 1;

    const { branchingLogic } = pages[currentPage];

    const nextPageIndexDefault = Math.min(currentPage + 1, totalPages);

    if (!branchingLogic) return nextPageIndexDefault;

    const { rule, ruleString, data, dataString } = branchingLogic;
    const branchingLogicRules = rule ?? safeJsonParse(ruleString);
    const branchingLogicData = data ?? safeJsonParse(dataString) ?? {};

    /** Activity branching rules assess a component dictionary, where the key is `component.id`, and the value is the component itself */
    const components = Object.fromEntries(
      getQuestionsForPages(pageHistory, {
        includeCleanFields: true,
      }).map((component) => [component.id, component]),
    );

    const jsonLogicData = { components, ...branchingLogicData };

    const nextPageIndex: number =
      jsonLogic.apply(branchingLogicRules, jsonLogicData) ??
      nextPageIndexDefault;

    return nextPageIndex;
  }, [currentPage, getQuestionsForPages, pageHistory, pages, totalPages]);

  const navigateActivity = useMemo(
    () => ({
      next: (nextOptions: NextOptions = {}) => {
        const { onNextPage, onLastPage } = nextOptions;
        const nextPage = getNextPageIndex();
        const isCurrentPageLast = nextPage === -1 || currentPage >= totalPages;

        if (isCurrentPageLast) {
          dispatch({ type: Last, isLastPage: true });
          onLastPage?.(currentPage);
          return currentPage;
        }

        const goToNextPage = () =>
          dispatch({
            type: Next,
            nextPage,
            isLastPage: nextPage >= totalPages,
          });

        if (onNextPage) {
          onNextPage({ nextPage, goToNextPage });
          return nextPage;
        }

        goToNextPage();
        return nextPage;
      },
      previous: () => {
        const [previousPage = ROOT_PAGE_INDEX] = pageHistory.slice(-2);
        dispatch({ type: Prev, nextPage: previousPage });
        return previousPage;
      },
    }),
    [getNextPageIndex, currentPage, totalPages, pageHistory],
  );

  return {
    currentPage,
    pageHistory,
    skippedPages,
    isLastPage,
    navigateActivity,
  };
};

export type ActivityLocation = ReturnType<typeof useActivityNavigation>;
