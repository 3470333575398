import { HEALTH_JOURNEY_ACTIVITY_STATUS } from '@leagueplatform/health-journey-common';

const { ACTIVE, UPCOMING, COMPLETED, EXPIRED, REMOVED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;

/**
 * Normalize an epoch timestamp such that it sets hours, minutes, seconds and milliseconds to 0 for a given date
 * @param {*} timestamp Epoch timestamp || Date object
 * @returns epoch timestamp
 */
export const normalizeEpochDate = (timestamp) =>
  new Date(timestamp).setHours(0, 0, 0, 0);

export const activityStatusByDate = {
  today: [ACTIVE, COMPLETED, REMOVED],
  past: [COMPLETED, EXPIRED, REMOVED],
  future: [UPCOMING],
};

/**
 *
 * @param {object} oldDate – Date object
 * @param {object} newDate – Date object
 * @param {string} unit "day" || "hour" || "minute"
 * @returns Difference between the old date and new date as measured by the specified units
 */
export const getDiffOfDates = (oldDate, newDate, unit = 'day') => {
  if (!oldDate || !newDate) return undefined;
  const timeDifference = newDate.getTime() - oldDate.getTime();
  const milliseconds = (() => {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    return { minute, hour, day };
  })();
  return Math.round(timeDifference / milliseconds[unit]);
};

/**
 * Given the currentDate, return the correct statuses based on the timeline difference from todays date
 * @param {*} currentDate Epoch timestamp
 * @returns returns the activity status for a date reference
 */
export const deriveActivityStatusFromDate = (
  currentDate,
  todaysDateObj = normalizeEpochDate(Date.now()),
) => {
  const dateDiff = getDiffOfDates(
    new Date(todaysDateObj),
    new Date(currentDate),
  );

  if (dateDiff === 0) return activityStatusByDate.today;
  return dateDiff > 0 ? activityStatusByDate.future : activityStatusByDate.past;
};

/**
 * Sort an array of objects by date. Provide the array, the date property accessor and optionally, the sorting order
 * @param {string} property The name of the property being sorted. Ex. `completed_date`
 * @param {string} order `asc` || `desc` – defaults to sorting in ascending order
 * @returns a copy of the original array, sorted in the order specified
 */
export const sortByDate = (array, property, order = 'asc') => {
  const newArray = Array.from(array);
  switch (order) {
    case 'desc':
      return newArray.sort(
        (a, b) => new Date(b[property]) - new Date(a[property]),
      );
    case 'asc':
    default:
      return newArray.sort(
        (a, b) => new Date(a[property]) - new Date(b[property]),
      );
  }
};
