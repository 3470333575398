import * as React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ENABLE_NEW_JOURNEY_EXPERIENCE } from '@leagueplatform/health-journey-common';
import { AllActivitiesByStatus } from 'components/configurable-journey-experience/all-activities-by-status/all-activities-by-status.component';
import { HealthJourneyPage } from './pages/main/health-journey-page.component';

export const HealthJourneyProgressSubroutes = () => {
  const { path } = useRouteMatch();
  // New Journey Experience Feature Flag
  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );

  return (
    <Switch>
      {isNewJourneyExperienceEnabled && (
        <>
          <Route path={`${path}/standalone`}>
            <AllActivitiesByStatus statusType="standalone" />
          </Route>
          <Route path={`${path}/completed-history`}>
            <AllActivitiesByStatus statusType="completed" />
          </Route>
          <Route path={`${path}/missed-history`}>
            <AllActivitiesByStatus statusType="missed" />
          </Route>
        </>
      )}
      {/*
        If the new configurable journey experience is not enabled for the user (e.g. isConfigurableJourneyExperience is a falsy value)
        we fall back to rendering the old health progress page when we reach the /health-progress route. See capabilities/health-journey/src/health-journey-routes.tsx
        for more details.

        This route should be deleted when we deprecate the old Journey Experience
      */}
      <Route path={path} exact>
        <HealthJourneyPage />
      </Route>
    </Switch>
  );
};
