import React from 'react';
import {
  Flex,
  HeadingThree,
  BodyTwoSecondary,
  BodyOne,
  Overline,
  Image,
  SubtitleOne,
  GenesisTheme,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  isAbsoluteUrl,
  formatPhoneNumber,
  normalizeUrl,
} from '@leagueplatform/web-common';
import { CardBanner } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useConfigProperty } from '@leagueplatform/core';
import { SOLUTION_TYPE } from '@leagueplatform/care-collections-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CARE_COMMON_ASSET_KEYS } from '@leagueplatform/care-common';
import { parseSolutionUrl } from '../../utils/parse-solution-url';

interface SolutionCardProps {
  title: string;
  description: string;
  url: string;
  image: string;
  isRecommended?: boolean;
  type: SOLUTION_TYPE;
  careCollectionId: string;
  topicTitle: string;
  phoneNumber?: string;
}

const getCardBannerStyles = ({ breakpoints }: GenesisTheme) => ({
  imageContainer: {
    minHeight: '56px',
    width: '56px',
    borderRadius: 'medium',
    alignSelf: 'center',
    flexShrink: '0',
    margin: 'one',
    marginRight: 'none',
    '&&': {
      [`@media screen and (min-width: ${breakpoints.phone})`]: {
        margin: 'two',
        height: '136px',
        maxWidth: '136px',
        borderLeftStyle: 'none',
      },
    },
  },
  wrapper: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
});

export const SolutionCard = ({
  title,
  description,
  url,
  image,
  type,
  isRecommended = false,
  careCollectionId,
  topicTitle,
  phoneNumber,
}: SolutionCardProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  // TODO [CDSV-1429] Remove once color tokens are fixed for all tenants
  const adjustForCardColorThemeDiscrepancy = useConfigProperty(
    '__care__adjustForCardColorThemeDiscrepancy',
  );
  const backgroundColor = (() => {
    if (adjustForCardColorThemeDiscrepancy) {
      return isRecommended ? 'surface.background.secondary' : undefined;
    }
    return isRecommended ? 'surface.card.primary' : 'surface.card.secondary';
  })();
  const isExternalUrl = isAbsoluteUrl(url);
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  return (
    <CardBanner
      url={phoneNumber ? undefined : parseSolutionUrl(normalizeUrl(url), type)}
      styles={getCardBannerStyles(theme)}
      image={image}
      backgroundColor={backgroundColor}
      onClick={(e) => {
        trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
          product_area: PRODUCT_AREA.GET_CARE,
          sub_product_area: SCREEN_NAMES.CARE_COLLECTIONS,
          screen_name: SCREEN_NAMES.CARE_COLLECTION,
          text_detail: topicTitle,
          resource_name: title.toLowerCase(),
          care_collection_id: careCollectionId,
          resource_type: type,
        });
        if (isExternalUrl) {
          e?.preventDefault();
          handleLinkCallback(url);
        }
      }}
    >
      {isRecommended && (
        <Overline
          alignSelf="flex-start"
          backgroundColor="highlight.background.subdued"
          width="fit-content"
          borderRadius="small"
          padding="quarter"
          marginBottom="threeQuarters"
        >
          {formatMessage({ id: 'RECOMMENDED_NEXT_STEP' })}
        </Overline>
      )}
      <HeadingThree marginBottom="half">{title}</HeadingThree>
      <BodyTwoSecondary>{description}</BodyTwoSecondary>
      {phoneNumber ? (
        <SubtitleOne as="h4" marginTop="one">
          {formatPhoneNumber(phoneNumber)}
        </SubtitleOne>
      ) : (
        <Flex alignItems="center" marginTop="one">
          <BodyOne color="interactive.action.primary" fontWeight="bold">
            {formatMessage({ id: 'VIEW_THIS_RESOURCE' })}
          </BodyOne>
          {/* TODO: This should be an inline SVG, using `currentColor` fill to match text */}
          <Image
            src={
              handleStaticAsset(CARE_COMMON_ASSET_KEYS.CHEVRON_RIGHT) as string
            }
            alt=""
            maxWidth="8px"
            marginLeft="one"
          />
        </Flex>
      )}
    </CardBanner>
  );
};
