import React from 'react';
import { node } from 'prop-types';
import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';

const ActivitiesGroup = genesisStyled((props) => (
  <Box
    paddingBottom={{ _: 'four', laptop: 'five' }}
    marginBottom={{ _: 'twoAndHalf', laptop: 'four' }}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
))(
  css({
    borderBottomWidth: 'thick',
    borderBottomColor: 'onSurface.border.subdued',
    borderStyle: 'solid',
    '&:last-child': {
      paddingBottom: 0,
      marginBottom: 0,
      borderBottom: 'none',
    },
  }),
);

export const ActivitiesGroupSection = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <ActivitiesGroup {...props}>{children}</ActivitiesGroup>
);

ActivitiesGroupSection.propTypes = { children: node.isRequired };
