import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { isProduction } from '@leagueplatform/app-environment';

import {
  BodyOneSecondary,
  Box,
  Flex,
  HeadingFour,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';

import { LONG_MONTH_FORMAT } from '@leagueplatform/web-common';
import { VeryQuietButton } from '@leagueplatform/web-common-components';
import { USER_CONNECTED_DEVICE_CAMPAIGN_MODES } from '@leagueplatform/health-journey-api';
import {
  HEALTH_JOURNEY_ACTIVITY_STATUS,
  COMPLETION_METHOD_TYPES,
} from '@leagueplatform/health-journey-common';

import { ActivityErrorMessage } from './activity-error-message.component';
import { DeleteActivityModal } from './delete-activity-modal.component';
import { useHealthActivityFooter } from '../hooks/use-health-activity-footer.hook';
import { useIsMarkAsDoneEnabled } from '../hooks/use-is-mark-as-done-enabled.hook';

export const { COMPLETED, UPCOMING, EXPIRED, REMOVED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;
const { STEP, BASE } = COMPLETION_METHOD_TYPES;

export const ActivityFooter = ({ isActivityDetailsView, isRemovable }) => {
  const {
    activityHasMetCompletionCriteria,
    activityId,
    campaignMode,
    canExpire,
    cta,
    endDate,
    errorMessage,
    isLoadingDelete,
    onClose,
    primaryAction,
    primaryCtaText,
    requestSetUserHealthActivityDelete,
    secondaryAction,
    showDeleteActivityModal,
    startDate,
    status,
    toggleDeleteActivityModal,
  } = useHealthActivityFooter(isActivityDetailsView);

  const { formatMessage, formatDate } = useIntl();
  const isMarkAsDoneEnabled = useIsMarkAsDoneEnabled();
  const isStepCompletionMethodType = cta?.completion_method?.type === STEP;
  const isNotActivityWithUserConnectedDevice =
    campaignMode !== USER_CONNECTED_DEVICE_CAMPAIGN_MODES.AUTOMATIC;

  const { type } = cta?.completion_method ?? {};

  const primaryCta = primaryCtaText();
  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const FooterComponents = ({ isRemovableCta }) => {
    // only disable the button on base activity types
    const isDisabled =
      (!isProduction() && type === BASE && !activityHasMetCompletionCriteria) ||
      (primaryCta.toLowerCase() ===
        formatMessage({ id: 'MARK_AS_DONE' }).toLowerCase() &&
        !isMarkAsDoneEnabled);

    return (
      <>
        {isRemovableCta && (
          <VeryQuietButton
            marginRight="one"
            onClick={secondaryAction}
            isLoading={isLoadingDelete}
            data-testid="activity-footer-remove-action"
          >
            {formatMessage({ id: 'REMOVE_FROM_JOURNEY' })}
          </VeryQuietButton>
        )}
        {isNotActivityWithUserConnectedDevice && (
          <PrimaryButton
            onClick={primaryAction}
            disabled={isDisabled}
            transition="background 0.5s ease-out, color 0.5s ease-out"
            data-testid="activity-footer-main-action"
          >
            {primaryCta}
          </PrimaryButton>
        )}
      </>
    );
  };

  FooterComponents.propTypes = {
    isRemovableCta: PropTypes.bool,
  };

  FooterComponents.defaultProps = {
    isRemovableCta: false,
  };

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const DoneFooterComponent = () => (
    <PrimaryButton onClick={onClose}>
      {formatMessage({ id: 'CLOSE' })}
    </PrimaryButton>
  );

  const formattedActivityStartDate = formatDate(startDate, LONG_MONTH_FORMAT);
  const formattedActivityEndDate = formatDate(endDate, LONG_MONTH_FORMAT);

  const getFooterText = useCallback(() => {
    switch (status) {
      case UPCOMING:
        return {
          heading: 'THIS_IS_JUST_A_SNEAK_PEAK',
          body: `COME_BACK_COMPLETE_ACTIVITY_${
            canExpire ? 'ON_DATE' : 'LATER'
          }`,
          date: formattedActivityStartDate,
        };
      case EXPIRED:
        return {
          heading: 'LOOKS_LIKE_YOU_MISSED_THIS_ONE',
          body: 'THIS_ACTIVITY_EXPIRED_ON_DATE',
          date: formattedActivityEndDate,
        };
      case REMOVED:
        return {
          heading: 'LOOKS_LIKE_THIS_WASNT_MEANT_FOR_YOU',
          body: 'YOU_SKIPPED_THIS_ACTIVITY',
        };
      default:
        return null;
    }
  }, [canExpire, formattedActivityEndDate, formattedActivityStartDate, status]);

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const TextBasedFooterComponent = useCallback(() => {
    const { heading, body, date } = getFooterText();
    return (
      <Box textAlign="center">
        <HeadingFour as="p" color="onSurface.text.subdued" marginBottom="half">
          {formatMessage({ id: heading })}
        </HeadingFour>
        <BodyOneSecondary>
          {formatMessage({ id: body }, { date })}
        </BodyOneSecondary>
      </Box>
    );
  }, [formatMessage, getFooterText]);

  // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
  const FooterView = useCallback(() => {
    switch (status) {
      case COMPLETED:
        if (isStepCompletionMethodType && isActivityDetailsView) {
          return <FooterComponents isRemovableCta={false} />;
        }
        return <DoneFooterComponent />;
      case UPCOMING:
      case EXPIRED:
      case REMOVED:
        return <TextBasedFooterComponent />;
      default:
        return <FooterComponents isRemovableCta={isRemovable} />;
    }
  }, [isActivityDetailsView, isRemovable, isStepCompletionMethodType, status]);

  const deleteActivityOnClose = async () => {
    toggleDeleteActivityModal(false);
  };

  return (
    <Box
      position="sticky"
      bottom="0"
      width="100%"
      backgroundColor="surface.background.secondary"
      padding="one"
      justifyContent="center"
    >
      {errorMessage && (
        <ActivityErrorMessage marginBottom="threeQuarters">
          {errorMessage}
        </ActivityErrorMessage>
      )}
      <Flex
        flexDirection={{ _: 'column', phone: 'row' }}
        width="100%"
        marginY="0"
        marginX="auto"
        justifyContent="center"
      >
        <FooterView />
        {showDeleteActivityModal && (
          <DeleteActivityModal
            onClose={deleteActivityOnClose}
            activityId={activityId}
            requestSetUserHealthActivityDelete={
              requestSetUserHealthActivityDelete
            }
          />
        )}
      </Flex>
    </Box>
  );
};

ActivityFooter.propTypes = {
  isActivityDetailsView: PropTypes.bool,
  isRemovable: PropTypes.bool,
};

ActivityFooter.defaultProps = {
  isActivityDetailsView: true,
  isRemovable: true,
};
