import React, { useMemo, useState } from 'react';
import { useLocation, useHistory } from '@leagueplatform/routing';
import { ProgressTab } from 'components/configurable-journey-experience/progress-tab/progress-tab.component';
import { HistoryTab } from 'components/configurable-journey-experience/history-tab/history-tab.component';
import { Tabs, Box } from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import type { JourneyTab } from '@leagueplatform/health-journey-common';
import {
  JOURNEY_ROUTES,
  TAB_PANEL_ANALYTICS_NAMES,
  TAB_DISPLAY_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  HealthProgramsPage,
  useActiveCampaignInfo,
} from '@leagueplatform/health-programs';
import { PriorityActivitiesTab } from './priority-activities-tab/priority-activities-tab.component';
import { ConfigurableJourneyExperienceHeader } from './configurable-journey-experience-header/configurable-journey-experience-header.component';
import { ConfigurableJourneyPageLayout } from './configurable-journey-page-layout.component';
import { ConfigurableJourneyExperienceTabs } from './configurable-journey-experience-tabs/configurable-journey-experience-tabs.component';
import { ActivityCompletionController } from '../activity-completion-controller.component';
import { useHealthProfileConfig } from '../../health-journey.config';

type JourneyTabConfig = {
  route: string;
  component: React.FunctionComponent;
};

export const ConfigurableJourneyExperience = () => {
  const { data: activeCampaignInfo } = useActiveCampaignInfo();

  const { tabs: healthProfileConfigTabs } = useHealthProfileConfig();

  const configuredTabs = useMemo(() => {
    const JOURNEY_TAB_MAP: Record<JourneyTab, JourneyTabConfig> = {
      activities: {
        route: JOURNEY_ROUTES.journeyHome(),
        component: PriorityActivitiesTab,
      },
      progress: {
        route: JOURNEY_ROUTES.progressHome(),
        component: ProgressTab,
      },
      history: {
        route: JOURNEY_ROUTES.journeyHistoryHome(),
        component: HistoryTab,
      },
      explore: {
        route: JOURNEY_ROUTES.programsHome(),
        component: HealthProgramsPage,
      },
    };

    const filteredConfigTabs = healthProfileConfigTabs.filter((tab) =>
      TAB_DISPLAY_NAMES.includes(tab),
    );

    const tabs =
      filteredConfigTabs.length === 0 ? TAB_DISPLAY_NAMES : filteredConfigTabs;

    return {
      names: tabs,
      routes: tabs.map((tab) => JOURNEY_TAB_MAP[tab].route),
      content: tabs.map((tab) => JOURNEY_TAB_MAP[tab].component),
    };
  }, [healthProfileConfigTabs]);

  const {
    names: tabNames,
    routes: tabRoutes,
    content: tabContent,
  } = configuredTabs;

  const history = useHistory();
  const { pathname: currentTab } = useLocation();
  const currentTabIndex = tabRoutes.indexOf(currentTab);
  const [tabIndex, setTabIndex] = useState(currentTabIndex);
  const analyticsScreenName = TAB_PANEL_ANALYTICS_NAMES[currentTabIndex];

  const handleTabsChange = (index: number) => {
    // analytics event for clicking a tab
    // screen name uses the currentTabIndex to refer to the current tab/panel
    // a user is on before they transition to the new tab/panel
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: analyticsScreenName,
      detail: tabNames[index],
    });

    setTabIndex(index);
    history.push(tabRoutes[index]);
  };

  React.useEffect(() => {
    // if the route changes to match one of the tabs, switch to that tab
    setTabIndex(currentTabIndex);
  }, [currentTabIndex]);
  React.useEffect(() => {
    // prevent double analytics tracking on initial fetch of active campaign info
    if (!activeCampaignInfo) return;

    const isProgramsLibraryPage =
      tabRoutes[currentTabIndex] === JOURNEY_ROUTES.programsHome();

    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: analyticsScreenName,
      ...(isProgramsLibraryPage && {
        num_health_campaign_active:
          activeCampaignInfo?.number_of_enrolled_programs,
        num_health_campaign_limit: activeCampaignInfo?.max_programs_limit,
      }),
    });
  }, [tabRoutes, activeCampaignInfo, currentTabIndex, analyticsScreenName]);

  return (
    <Box
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
        padding: '$none $oneAndHalf',
      }}
    >
      <ConfigurableJourneyExperienceHeader
        analyticsScreenName={analyticsScreenName}
      />
      <Tabs.Root
        defaultValue={tabRoutes[tabIndex]}
        onValueChange={(value) =>
          handleTabsChange(tabRoutes.findIndex((tab) => tab === value))
        }
      >
        <ConfigurableJourneyExperienceTabs
          tabRoutes={tabRoutes}
          tabDisplayNames={tabNames}
        />
        <ConfigurableJourneyPageLayout>
          <Box css={{ width: '100%', paddingBottom: '$twoAndHalf' }}>
            {tabContent.map((Tab, index) => (
              <Tabs.TabPanel
                value={tabRoutes[index]}
                key={`${tabRoutes[index]}`}
              >
                <Tab />
              </Tabs.TabPanel>
            ))}
          </Box>
        </ConfigurableJourneyPageLayout>
      </Tabs.Root>
      <ActivityCompletionController />
    </Box>
  );
};
