import React from 'react';
import { string, number, oneOf, bool } from 'prop-types';

import {
  Flex,
  Box,
  useTheme,
  HeadingTwo,
  BodyTwoSecondary,
  genesisStyled,
} from '@leagueplatform/genesis-commons';

import { CompoundProgressBar } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { HEALTH_JOURNEY_ACTIVITY_STATUS } from '@leagueplatform/health-journey-common';

const { COMPLETED, ACTIVE, UPCOMING, EXPIRED, REMOVED } =
  HEALTH_JOURNEY_ACTIVITY_STATUS;

const CounterTitle = genesisStyled(Flex)`
  > span:not(:last-child) {
    margin-right: 0.25em;
  }
`;

const getProgressColorsByStatus = ({
  colors: { highlight, success, interactive },
}) => ({
  [ACTIVE]: {
    // Is front of line activity
    [true]: {
      highlightColor: highlight.icon,
    },
    [false]: {
      highlightColor: highlight.icon,
    },
  },
  [UPCOMING]: {
    highlightColor: highlight.icon,
  },
  [COMPLETED]: {
    highlightColor: success.icon,
  },
  [EXPIRED]: {
    highlightColor: interactive.action.subdued,
  },
  [REMOVED]: {
    highlightColor: interactive.action.subdued,
  },
  [undefined]: {
    highlightColor: highlight.icon,
  },
});

export const ActivityCardProgress = ({
  progress,
  goal,
  unit,
  status,
  isFrontOfLine,
}) => {
  const { formatNumber } = useIntl();
  const theme = useTheme();
  const progressColorsByStatus = getProgressColorsByStatus(theme);
  const { highlightColor } =
    status === ACTIVE
      ? progressColorsByStatus[status][isFrontOfLine]
      : progressColorsByStatus[status];

  const progressBarConfig = [
    {
      key: 'activity-progress-count',
      color: highlightColor,
      percent: (progress / goal) * 100,
      transition: `width 0.5s ease-out`,
    },
  ];

  return (
    <Box marginTop="half">
      <CounterTitle as="p" marginBottom="half" alignItems="baseline">
        <HeadingTwo as="span" color={highlightColor}>
          {formatNumber(progress)}
        </HeadingTwo>
        <HeadingTwo
          as="span"
          color="onSurface.text.subdued"
          fontWeight="regular"
        >
          /
        </HeadingTwo>
        <HeadingTwo
          as="span"
          color="onSurface.text.subdued"
          fontWeight="regular"
        >
          {formatNumber(goal)}
        </HeadingTwo>
        <BodyTwoSecondary as="span">{unit}</BodyTwoSecondary>
      </CounterTitle>
      <CompoundProgressBar
        backgroundColor="interactive.action.disabled"
        segments={progressBarConfig}
      />
    </Box>
  );
};

ActivityCardProgress.propTypes = {
  progress: number.isRequired,
  goal: number.isRequired,
  unit: string.isRequired,
  status: oneOf(Object.values(HEALTH_JOURNEY_ACTIVITY_STATUS)).isRequired,
  isFrontOfLine: bool.isRequired,
};

export default ActivityCardProgress;
