import React from 'react';
import {
  CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH,
  TAB_PANEL_ANALYTICS_NAMES,
  useGetUserProfile,
} from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import {
  BackgroundImage,
  Flex,
  HeadingOne,
  HeadingTwo,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { CONFIGURABLE_JOURNEY_HEADER_BACKGROUND_IMAGE_HEIGHT } from 'constants/configurable-journey-experience.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { HealthJourneyButton } from '../configurable-journey-experience-button/configurable-journey-experience-button.component';

interface ConfigurableJourneyExperienceHeaderProps {
  analyticsScreenName: (typeof TAB_PANEL_ANALYTICS_NAMES)[number];
}

export const ConfigurableJourneyExperienceHeader = ({
  analyticsScreenName,
}: ConfigurableJourneyExperienceHeaderProps) => {
  const { formatMessage } = useIntl();
  const {
    data: userProfileData,
    isLoading: isUserNameLoading,
    isError: isUserProfileError,
  } = useGetUserProfile();

  const { firstName, preferredFirstName } = userProfileData?.userProfile || {};
  const displayName: string | undefined = preferredFirstName || firstName;

  const displayFallbackGreeting =
    isUserProfileError || isUserNameLoading || !displayName;

  return (
    <BackgroundImage
      as="header"
      flexWrap={{ _: 'wrap', laptop: 'nowrap' }}
      backgroundSize="cover"
      borderRadius="medium"
      display="flex"
      marginX={{ _: 'auto', tablet: 'auto' }}
      marginTop="twoAndHalf"
      flexDirection="column"
      maxWidth={CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH}
      minHeight={CONFIGURABLE_JOURNEY_HEADER_BACKGROUND_IMAGE_HEIGHT}
      paddingY="two"
      paddingX="one"
      width="auto"
      alt=""
      // TODO: Eventually we will give users the ability to select from a library of images (from contentful), background image will be static for now
      src={
        handleStaticAsset(
          HEALTH_JOURNEY_ASSET_KEYS.JOURNEY_HEADER_BACKGROUND_IMAGE,
        ) as string
      }
      data-testid="background-image"
    >
      <VisuallyHidden>
        <HeadingOne>{formatMessage({ id: 'HEALTH_JOURNEY_PAGE' })}</HeadingOne>
      </VisuallyHidden>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
        gap="oneAndHalf"
      >
        <HeadingTwo as="p" textAlign="center">
          {formatMessage(
            {
              id: displayFallbackGreeting
                ? 'YOUVE_GOT_THIS'
                : 'YOUVE_GOT_THIS_NAME',
            },
            { firstName: displayName },
          )}
        </HeadingTwo>
        <HealthJourneyButton analyticsScreenName={analyticsScreenName} />
      </Flex>
    </BackgroundImage>
  );
};
