import React from 'react';
import Masonry from '@leagueplatform/masonry';
import { PRODUCT_AREA } from '@leagueplatform/analytics';
import {
  ACHIEVEMENTS_WIDGET,
  CHALLENGES_LIST_WIDGET,
  COMPLETED_ACTIVITIES_CAROUSEL_WIDGET,
  HEALTH_JOURNEY_MASONRY_VERSION,
  HEALTH_JOURNEY_MODULE_KEY,
  JOURNEY_PROGRESS_TAB_APP_ID,
  MISSED_ACTIVITIES_CAROUSEL_WIDGET,
  PROGRAMS_LIST_WIDGET,
  SCREEN_NAMES,
  STANDALONE_ACTIVITIES_WIDGET,
  CHALLENGES_AND_PROGRAMS_LIST_WIDGET,
  REWARD_PROGRAM_CAROUSEL,
} from '@leagueplatform/health-journey-common';
import { ActivitiesByStatus } from '../activities-by-status/activities-by-status.component';
import { AchievementsWidget } from '../achievements-widget/achievements-widget.component';
import { CampaignsListWidget } from '../campaigns-list-widget/campaigns-list-widget.component';
import { StandaloneActivitiesWidget } from '../standalone-activities-widget/standalone-activities-widget.component';
import { CustomWidgetContainer } from '../custom-widget-container.component';
import { RewardsProgramCardCarouselWidget } from '../rewards-program-card-carousel-widget/rewards-program-card-carousel-widget.component';
import { ProgramsAndChallengesWidget } from '../programs-and-challenges-widget/programs-and-challenges-widget.component';

const ProgressTabContent = Masonry.init({
  appId: JOURNEY_PROGRESS_TAB_APP_ID,
  apiVersion: HEALTH_JOURNEY_MASONRY_VERSION,
  analytics: {
    productArea: PRODUCT_AREA.JOURNEY,
    screenName: SCREEN_NAMES.PROGRESS_PAGE,
  },
  // Add custom widget configs here
  customWidgetModules: [
    {
      moduleKey: HEALTH_JOURNEY_MODULE_KEY,
      customWidgetsUI: [
        {
          type: REWARD_PROGRAM_CAROUSEL,
          widget: ({ widget }) => (
            <RewardsProgramCardCarouselWidget widget={widget} />
          ),
        },
        {
          type: CHALLENGES_AND_PROGRAMS_LIST_WIDGET,
          widget: ({ widget }) => (
            <CustomWidgetContainer>
              <ProgramsAndChallengesWidget widget={widget} />
            </CustomWidgetContainer>
          ),
        },
        {
          type: ACHIEVEMENTS_WIDGET,
          widget: ({ widget }) => (
            <CustomWidgetContainer>
              <AchievementsWidget widget={widget} />
            </CustomWidgetContainer>
          ),
        },
        {
          type: CHALLENGES_LIST_WIDGET,
          widget: ({ widget }) => (
            <CustomWidgetContainer>
              <CampaignsListWidget widget={widget} campaignType="challenge" />
            </CustomWidgetContainer>
          ),
        },
        {
          type: COMPLETED_ACTIVITIES_CAROUSEL_WIDGET,
          widget: () => (
            <CustomWidgetContainer>
              <ActivitiesByStatus statusType="completed" />
            </CustomWidgetContainer>
          ),
        },
        {
          type: MISSED_ACTIVITIES_CAROUSEL_WIDGET,
          widget: () => (
            <CustomWidgetContainer>
              <ActivitiesByStatus statusType="missed" />
            </CustomWidgetContainer>
          ),
        },
        {
          type: PROGRAMS_LIST_WIDGET,
          widget: ({ widget }) => (
            <CustomWidgetContainer>
              <CampaignsListWidget widget={widget} campaignType="opt-in" />
            </CustomWidgetContainer>
          ),
        },
        {
          type: STANDALONE_ACTIVITIES_WIDGET,
          widget: () => (
            <CustomWidgetContainer>
              <StandaloneActivitiesWidget />
            </CustomWidgetContainer>
          ),
        },
      ],
    },
  ],
});

export const ProgressTab = () => <ProgressTabContent.Provider />;
