import React, { FC, useMemo, useState, createContext } from 'react';
import { ToolboxComponentProvider } from './toolbox-component-provider.component';
import { HealthActivityAnalyticsEvents } from '../hooks/use-health-activity-analytics.hook';
import { useActivityFormManager } from '../hooks/use-activity-form-manager.hook';
import { useActivityToolboxComponentActions } from '../hooks/use-activity-toolbox-component-actions.hook';

// Types
interface HealthActivityProviderProps {
  currentPage: number;
  healthActivityAnalytics: HealthActivityAnalyticsEvents;
}

type HealthActivityContextProps = HealthActivityProviderProps & {
  healthAssessmentManager: ReturnType<typeof useActivityFormManager>;
  setCurrentPage(pageIndex: number): void;
};

export const HealthActivityContext = createContext<HealthActivityContextProps>(
  null!,
);

export const HealthActivityProvider: FC<HealthActivityProviderProps> = ({
  children,
  currentPage: initialPage = 0,
  healthActivityAnalytics,
}) => {
  const toolboxActions = useActivityToolboxComponentActions(
    healthActivityAnalytics,
  );

  const [currentPage, setCurrentPage] = useState(initialPage);
  const healthAssessmentManager = useActivityFormManager();
  const healthActivityContextValue: HealthActivityContextProps = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      healthActivityAnalytics,
      healthAssessmentManager,
    }),
    [currentPage, healthActivityAnalytics, healthAssessmentManager],
  );

  return (
    <HealthActivityContext.Provider value={healthActivityContextValue}>
      <ToolboxComponentProvider actions={toolboxActions}>
        {children}
      </ToolboxComponentProvider>
    </HealthActivityContext.Provider>
  );
};
