import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  ParagraphText,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useVerifiableActivityProgressQuery } from 'hooks/use-verifiable-activity-progress-query.hook';
import { ProgressTrackerChart } from 'components/progress-tracker-chart.component';
import { timeAgoFromNow } from 'utils/time-ago-from-now.util';

interface ProgressTrackerProps {
  activityId: string;
}

export function ProgressTracker({ activityId }: ProgressTrackerProps) {
  const { formatRelativeTime, formatMessage } = useIntl();
  const { isLoading, isError, data } =
    useVerifiableActivityProgressQuery(activityId);

  if (isLoading || isError || !data) return null;

  const {
    unit,
    current_progress: current,
    goal_total: total,
    last_updated: lastUpdated,
  } = data;
  const progressPercentage = Math.min(current / total, 1);
  const percentage = Math.floor(progressPercentage * 100);
  const { time, suffix } = timeAgoFromNow(lastUpdated);

  const yourProgressInNumbers = formatMessage(
    { id: 'YOUR_PROGRESS_IN_NUMBERS' },
    {
      current,
      total,
      unit,
      percentage,
    },
  );

  return (
    <Box css={{ marginTop: '$two' }}>
      <UtilityText as="h2" emphasis="emphasized">
        {formatMessage({ id: 'YOUR_PROGRESS' })}
      </UtilityText>
      <VisuallyHidden>{yourProgressInNumbers}</VisuallyHidden>
      <ParagraphText size="xs">
        {formatMessage({ id: 'UPDATED' })} {formatRelativeTime(time, suffix)}
      </ParagraphText>
      <Box
        css={{
          marginTop: '$one',
          marginLeft: '$twoAndHalf',
        }}
      >
        <ProgressTrackerChart
          unit={unit}
          currentProgress={current}
          goal={total}
          progressPercentage={percentage}
        />
      </Box>
    </Box>
  );
}
