import * as React from 'react';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';
import {
  HeadingText,
  StackItem,
  StackLayout,
  Button,
  GDSColor,
} from '@leagueplatform/genesis-core';
import { InfiniteData } from 'react-query';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT, MONTH_YEAR } from '@leagueplatform/web-common';
import { ErrorState, SkeletonBox } from '@leagueplatform/web-common-components';
import {
  HealthJourneyHistoryBadgeType,
  UserHealthJourneyHistoryDeserializedData,
  UserHealthJourneyHistoryItem,
} from '@leagueplatform/health-journey-api';
import { useGetUserProfile } from '@leagueplatform/health-journey-common';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { useGetUserHealthJourneyHistory } from 'pages/health-activity/hooks/use-get-user-health-journey-history.hook';
import { HistoryActivityCard } from '../history-activity/history-activity-card';
import { ConfigurableJourneyEmptyStateCard } from '../configurable-journey-empty-state-card/configurable-journey-empty-state-card';

// Maps the badge type to a genesis token.
const badgeTypeColorMap: Record<HealthJourneyHistoryBadgeType, GDSColor> = {
  challenge: '$warningBackgroundSubdued',
  'opt-in': '$decorativeBrandPrimaryBrightest',
  reward: '$decorativeBrandSecondaryBrightest',
};

// Iterates over every page and builds a consolidated list of all the data items.
function consolidatePageData(
  pages?: InfiniteData<UserHealthJourneyHistoryDeserializedData>['pages'],
): UserHealthJourneyHistoryItem[] {
  const consolidatedHistoryItems = pages?.reduce(
    (items, page) => items.concat(page.data),
    [] as UserHealthJourneyHistoryItem[],
  );

  return consolidatedHistoryItems ?? [];
}

function groupByDate<Item>(getDate: (getDate: Item) => string, items: Item[]) {
  const groupedByDate = items?.reduce((acc, item) => {
    const date = getDate(item);

    return {
      ...acc,
      // Append the item to the date group, if doesn't already exist create a new array.
      [date]: [...(acc[date] ?? []), item],
    };
  }, {} as { [date: string]: Item[] });

  return groupedByDate;
}

// Converts a hash map to a flat array using the key for the groupedBy field.
function normalizeItems<Item>(value: { [key: string]: Item }) {
  const normalizedItems = Object.entries(value).map(([keyValue, items]) => ({
    groupedBy: keyValue,
    items,
  }));
  return normalizedItems;
}

// Sorts, groups and localizes history items
function useGroupHistoryItems(
  historyItems: UserHealthJourneyHistoryItem[],
  timeZone?: string,
) {
  const { formatDate } = useIntl();

  // Reverse chronological sort
  const sortedHistoryItems = historyItems.sort((itemA, itemB) =>
    compareDesc(parseISO(itemA.timestamp), parseISO(itemB.timestamp)),
  );

  const groupedHistoryItemsByKey = groupByDate(
    (item) =>
      formatDate(item.timestamp, {
        ...MONTH_YEAR,
        timeZone,
      }),
    sortedHistoryItems,
  );

  const historyItemsGroupedByMonth =
    // Group all items by month.
    normalizeItems(groupedHistoryItemsByKey).map((month) => {
      // Group all the items within each month by day.
      const historyItemsGroupedByDate = groupByDate(
        (item) =>
          formatDate(item.timestamp, {
            ...DATE_FORMAT,
            timeZone,
          }),
        month.items,
      );

      return {
        ...month,
        items: normalizeItems(historyItemsGroupedByDate),
      };
    });

  return historyItemsGroupedByMonth;
}

export function HistoryTab() {
  const { formatMessage } = useIntl();
  const query = useGetUserHealthJourneyHistory();

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  const historyItems = consolidatePageData(query.data?.pages);
  const groupedHistoryItems = useGroupHistoryItems(
    historyItems,
    defaultTimezone,
  );
  const hasHistoryItems = historyItems.length > 0;

  if (query.isError) {
    return (
      <ErrorState
        onButtonClick={() => {
          query.refetch();
        }}
      />
    );
  }

  if (query.isLoading) {
    return (
      <StackLayout spacing="$one">
        <SkeletonBox width="10%" height="1.5rem" />
        <SkeletonBox width="100%" height="150px" />
        <SkeletonBox width="100%" height="150px" />
        <SkeletonBox width="100%" height="150px" />
      </StackLayout>
    );
  }

  if (!hasHistoryItems) {
    const { EMPTY_STATE_HISTORY } = HEALTH_JOURNEY_ASSET_KEYS;
    const emptyStateImgUrl = handleStaticAsset(EMPTY_STATE_HISTORY) as string;

    return (
      <StackLayout spacing="$two" horizontalAlignment="stretch">
        <StackItem>
          <ConfigurableJourneyEmptyStateCard
            heading={formatMessage({
              id: 'HISTORY_EMPTY_STATE_HEADER',
            })}
            description={formatMessage({
              id: 'HISTORY_EMPTY_STATE_DESCRIPTION',
            })}
            imgSrc={emptyStateImgUrl}
            backgroundColor="primary"
            headingLevel="2"
          />
        </StackItem>
      </StackLayout>
    );
  }

  return (
    <StackLayout spacing="$two" horizontalAlignment="stretch">
      {groupedHistoryItems?.map((month) => (
        <>
          <StackItem key={month.groupedBy}>
            <HeadingText size="lg" level="3">
              {month.groupedBy}
            </HeadingText>
          </StackItem>
          {month.items.map((historyItem) => (
            <StackItem key={historyItem.groupedBy}>
              <HeadingText
                size="sm"
                level="4"
                css={{ typography: '$overline', marginBottom: '$one' }}
              >
                {historyItem.groupedBy}
              </HeadingText>
              <StackLayout spacing="$one" horizontalAlignment="stretch">
                {historyItem.items.map((item) => (
                  <StackItem>
                    <HistoryActivityCard
                      status={item.status}
                      eyebrow={item.eyebrow}
                      heading={item.heading}
                      badge={
                        item.badge
                          ? {
                              body: item.badge.body,
                              image: item.badge.image,
                              color: badgeTypeColorMap[item.badge.type],
                            }
                          : undefined
                      }
                      image={item.image}
                      imageAltText={item.imageAltText}
                      body={item.body}
                      content={item.content}
                      cta={item.cta}
                    />
                  </StackItem>
                ))}
              </StackLayout>
            </StackItem>
          ))}
        </>
      ))}
      {query.hasNextPage ? (
        <StackItem horizontalAlignment="center">
          <Button
            disabled={query.isFetchingNextPage}
            onClick={() => {
              query.fetchNextPage();
            }}
          >
            {formatMessage({ id: 'LOAD_MORE' }).toLocaleUpperCase()}
          </Button>
        </StackItem>
      ) : null}
    </StackLayout>
  );
}
