import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  CoverImage,
  genesisStyled,
  Image as Img,
} from '@leagueplatform/genesis-commons';

// Constants
export const URL_PARAMS = {
  HEIGHT: 'h',
  WIDTH: 'w',
  QUALITY: 'q',
  FORMAT: 'fm',
};

// Components
const PreloadImage = genesisStyled(Img)`
  transition: filter 0.2s ease-out;
  filter: blur(${({ ready }) => (ready ? 0 : 5)}px)
`;

const PreloadCoverImage = genesisStyled(CoverImage)`
  transition: filter 0.2s ease-out;
  filter: blur(${({ ready }) => (ready ? 0 : 5)}px)
`;

/**
 *
 * @param {string} src image src url
 * @param {function} handleSuccess callback function to handle a successful image preload
 * @returns
 */
const preloadImage = (src, handleSuccess) => {
  const image = new Image();
  image.src = src;

  // If image has been cached by the browser, exit early
  if (image.complete) {
    handleSuccess(src);
    return;
  }

  // Update the image src and ready state when the image has loaded and remove the event listener
  const handleImageLoad = () => {
    handleSuccess(src);
    image.removeEventListener('load', handleImageLoad);
  };

  image.addEventListener('load', handleImageLoad);
};

/**
 * Use native `URL` class and its `searchParams` method to update the image src's params.
 *
 * @param {string} imageSrc - Url string for the image file
 * @param {number} dynamicWidth - Calculated width (px) for the image file
 * @return {string} - Formatted Url string with updated query params
 */
export const getImageSrcWithParams = ({ src, width, quality }) => {
  let imageUrl;

  // Attempting to parse an invalid URL causes page-breaking errors,
  // so we want to treat this with appropriate caution
  try {
    imageUrl = new URL(src);
    const { searchParams } = imageUrl;

    if (width) {
      // If height is manually set, remove it so we don't have scaling issues
      if (searchParams.get(URL_PARAMS.HEIGHT)) {
        searchParams.delete(URL_PARAMS.HEIGHT);
      }

      searchParams.set(URL_PARAMS.WIDTH, width);
    }

    if (quality) {
      searchParams.set(URL_PARAMS.FORMAT, 'jpg');
      searchParams.set(URL_PARAMS.QUALITY, quality);
    }
  } catch (error) {
    imageUrl = { href: src };
  }

  return imageUrl.href;
};

/**
 * Custom hook to track changes to element ref and set sizing based on element width and device pixel ratio (retina).
 *
 * @param {object} imageRef - Reference object to DOM image element
 * @param {string} imageSrc - Url string for the image file
 * @return {string} - Formatted Url string with updated query params
 */
export const useDynamicImageSrc = (imageRef, imageSrc) => {
  const previewSrc = getImageSrcWithParams({ src: imageSrc, quality: 5 });
  const [src, setSrc] = useState(previewSrc);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      const width = Math.ceil(
        imageRef.current.clientWidth * window.devicePixelRatio,
      );
      const scaledSrc = getImageSrcWithParams({ src: imageSrc, width });
      if (!Number.isNaN(width)) {
        preloadImage(scaledSrc, (preloadedSrc) => {
          setSrc(preloadedSrc);
          setReady(true);
        });
      }
    }
  }, [imageRef, imageSrc]);

  return { src, ready };
};

/**
 * Wrap the `Image` component, passing a modified `src` for dynamic image sizing, along with all other inherited props.
 *
 * Inherit all props and default values from the `Image` component.
 */
export const DynamicImage = ({ src, ref = null, width = 'auto', ...props }) => {
  const imageRef = useRef(ref);
  const dynamicImageSrc = useDynamicImageSrc(imageRef, src);
  return (
    <PreloadImage
      ref={imageRef}
      width={width || '100%'}
      ready={dynamicImageSrc.ready}
      src={dynamicImageSrc.src}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

DynamicImage.propTypes = Img.propTypes;
DynamicImage.defaultProps = Img.defaultProps;

/**
 * Wrap the `CoverImage` component, passing a modified `src` for dynamic image sizing, along with all other inherited props.
 *
 * Inherit all props and default values from the `CoverImage` component.
 */
export const DynamicCoverImage = ({ src, ref = null, ...props }) => {
  const imageRef = useRef(ref);
  const dynamicImageSrc = useDynamicImageSrc(imageRef, src);

  return (
    <PreloadCoverImage
      ref={imageRef}
      src={dynamicImageSrc.src}
      ready={dynamicImageSrc.ready}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

DynamicCoverImage.propTypes = CoverImage.propTypes;
DynamicCoverImage.defaultProps = CoverImage.defaultProps;
