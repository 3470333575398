import React from 'react';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

interface MasonryProgressBarCardRendererProps {
  eyebrow?: string;
  heading?: string;
  currentText: string;
  linkRefState?: React.RefObject<HTMLAnchorElement>;
  progress: number;
  suffixText?: string;
  footerText?: string;
  emphasizeCurrentValue?: boolean;
  onClick?: MasonryDeeplinkAction;
}

export const MasonryProgressBarCardContentRenderer = ({
  eyebrow,
  heading,
  currentText,
  progress,
  suffixText,
  footerText,
  onClick,
  linkRefState,
  emphasizeCurrentValue = false,
}: MasonryProgressBarCardRendererProps) => {
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout
      orientation="vertical"
      spacing="$quarter"
      css={{ margin: '$oneAndHalf', alignItems: 'normal', height: 'auto' }}
    >
      {eyebrow && (
        <UtilityText as="p" size="eyebrow" css={{ marginBottom: '$quarter' }}>
          {eyebrow}
        </UtilityText>
      )}
      <StackLayout spacing="$threeQuarters" orientation="vertical">
        {heading && (
          <HeadingText level="display" size="sm">
            {heading}
          </HeadingText>
        )}
        <ParagraphText size="sm" as="h3">
          <ConditionalLinkRenderer
            ref={linkRefState}
            url={onClick?.payload.url}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            <ParagraphText
              as="span"
              size="sm"
              css={{
                fontWeight: emphasizeCurrentValue ? 'bold' : '400',
              }}
            >
              {currentText}
            </ParagraphText>{' '}
            {suffixText}
          </ConditionalLinkRenderer>
        </ParagraphText>
        <Box css={{ paddingBottom: '$half', width: '100%' }}>
          <ProgressBar
            total={100}
            current={progress}
            customBarFillColor="success.border.default"
            customBarBackgroundColor="success.background.subdued"
            screenReaderText={`${currentText} ${suffixText}`}
          />
        </Box>
        <UtilityText
          emphasis="subtle"
          size="xs"
          css={{
            alignSelf: 'flex-end',
          }}
        >
          {footerText}
        </UtilityText>
      </StackLayout>
    </StackLayout>
  );
};
