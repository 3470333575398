import React, { FC } from 'react';

// Common Dependencies
import { useIntl } from '@leagueplatform/locales';
import { Box, styled, UtilityText } from '@leagueplatform/genesis-core';

// Hooks

// Common Components
import { ActivityLocation } from 'pages/health-activity/hooks/use-activity-navigation.hook';
import {
  LeftButton,
  RightButton,
} from './health-activity-footer-buttons.component';
import { HealthActivityPageProgress } from './health-activity-page-progress.component';

// Types
import { ActivityFooterAreas } from '../../constants/health-activity-footer.constants';

const { Top, Left, Center, Right, Bottom } = ActivityFooterAreas;
// Local Types
interface HealthActivityFooterProps extends Partial<ActivityLocation> {
  totalPages: number;
  currentPage: number;
  ctaText?: string;
  disabled?: boolean;
  onNext(): void;
  onPrev(): void;
}

// Styles
const footerPlacement = {
  [Top]: { gridArea: Top, justifySelf: 'center' },
  [Left]: { gridArea: Left, justifySelf: 'start' },
  [Center]: { gridArea: Center, justifySelf: 'center' },
  [Right]: { gridArea: Right, justifySelf: 'end' },
  [Bottom]: { gridArea: Bottom, justifySelf: 'center' },
};

// Local Components
const FooterLayout = styled(Box, {
  alignItems: 'center',
  display: 'grid',
  gridTemplateAreas: `
    'top top top'
    'left center right'
    'bottom bottom bottom'`,
  gridTemplateColumns: '[left] 1fr [center] auto [right] 1fr',
  gridTemplateRows: 'auto',
  width: '100%',
  gap: '1rem',
  '> *:only-child': { gridArea: 'bottom' },
  '&:last-child': { marginTop: '16px' },
});

export const HealthActivityFooter: FC<HealthActivityFooterProps> = ({
  totalPages,
  currentPage,
  ctaText,
  disabled,
  onNext,
  onPrev,
  isLastPage,
}) => {
  const { formatMessage } = useIntl();
  const isMultiPage = totalPages > 1;

  const copy = {
    nextStepLabel: isLastPage
      ? formatMessage({ id: 'COMPLETE_ACTIVITY' })
      : formatMessage({ id: 'ACTIVITY_NEXT_STEP' }),
    prevStepLabel: formatMessage({ id: 'ACTIVITY_PREV_STEP' }),
    pageProgress: formatMessage(
      { id: 'X_OF_Y' },
      { current: currentPage + 1, total: totalPages },
    ),
  };

  return (
    <FooterLayout>
      {/* Activity Page Progress Bar */}
      {isMultiPage && (
        <HealthActivityPageProgress
          css={footerPlacement[Top]}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}

      {/* Left Action */}
      {isMultiPage && (
        <LeftButton
          currentPage={currentPage}
          disabled={disabled}
          onClick={onPrev}
          css={footerPlacement[Left]}
          aria-label={copy.prevStepLabel}
        />
      )}

      {/* Page Location Copy */}
      {isMultiPage && (
        <UtilityText
          as="p"
          emphasis="subtle"
          css={{ ...footerPlacement[Center] }}
        >
          {copy.pageProgress}
        </UtilityText>
      )}

      {/* Right Action */}
      <RightButton
        css={footerPlacement[Right]}
        currentPage={currentPage}
        isLastPage={isLastPage}
        disabled={disabled}
        onClick={onNext}
        aria-label={copy.nextStepLabel}
      >
        {ctaText}
      </RightButton>
    </FooterLayout>
  );
};
