import { useIntl } from '@leagueplatform/locales';
import { useRewardsSidebar } from '@leagueplatform/rewards-achievements';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import React from 'react';
import { Badge } from '@leagueplatform/web-common-components';
import { Link } from '@leagueplatform/routing';
import { PointsIcon } from '@leagueplatform/rewards-common';
import { Button } from '@leagueplatform/genesis-core';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ViewMyHealthDashboardButton } from '@leagueplatform/about-me';
import {
  HEALTH_JOURNEY_BUTTON,
  useConfigurableJourneyExperienceButton,
} from './use-configurable-journey-experience-button.hook';

export const HealthJourneyButton = ({
  analyticsScreenName,
}: {
  analyticsScreenName: string;
}) => {
  const { formatMessage } = useIntl();
  const enabledButton = useConfigurableJourneyExperienceButton();
  const { userRewardPoints } = useRewardsSidebar();
  const handleClickAnalytics = (detail: string) =>
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: analyticsScreenName,
      detail,
    });

  switch (enabledButton) {
    case HEALTH_JOURNEY_BUTTON.POINTS_COUNTER:
      return (
        <Badge
          as={Link}
          to="/member/get-rewarded"
          css={{
            backgroundColor: '$surfaceBackgroundPrimary',
            '&:hover': { textDecoration: 'underline' },
          }}
          onClick={() =>
            handleClickAnalytics((userRewardPoints || 0).toString())
          }
        >
          <PointsIcon value={userRewardPoints || 0} />
        </Badge>
      );

    case HEALTH_JOURNEY_BUTTON.VIEW_MY_PROGRESS:
      return (
        <ViewMyHealthDashboardButton
          handleClickAnalytics={handleClickAnalytics}
        />
      );

    case HEALTH_JOURNEY_BUTTON.VIEW_MY_PROFILE:
      return (
        <Button
          as={Link}
          to={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
          size="medium"
          onClick={() => handleClickAnalytics('view my profile')}
        >
          {formatMessage({ id: 'VIEW_MY_PROFILE' })}
        </Button>
      );

    default:
      return null;
  }
};
