import React from 'react';
import { func, number } from 'prop-types';

// Modules
import { Box } from '@leagueplatform/genesis-commons';

// Hooks
import { useActivitiesTimelineAnalytics } from 'hooks/use-activities-timeline-analytics/use-activities-timeline-analytics.hook';

// Components
import { NavigateByDate } from 'components/navigate-by-date.component';

export const JourneyPageHeader = ({
  activeDate,
  setActiveDate,
  todaysDate,
}) => {
  const { navigateToToday, navigateToDate } =
    useActivitiesTimelineAnalytics(activeDate);

  const onChangeDateNavigation = ({ date, incr }) => {
    if (incr === 0) {
      navigateToToday();
    } else {
      const direction = incr === -1 ? 'backward' : 'forward';
      navigateToDate(date, direction);
    }
    setActiveDate(date);
  };

  return (
    <Box>
      {activeDate && todaysDate && (
        <NavigateByDate
          currentDate={activeDate}
          onChangeDate={onChangeDateNavigation}
          paddingBottom="two"
          todaysDate={todaysDate}
        />
      )}
    </Box>
  );
};

JourneyPageHeader.propTypes = {
  todaysDate: number.isRequired,
  activeDate: number.isRequired,
  setActiveDate: func.isRequired,
};
