import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '@leagueplatform/web-common';
import { Box, Overline } from '@leagueplatform/genesis-commons';
import { ActivitiesByDateGrouping } from './group-activities-by-date.util';
import { ConfigurableActivityCard } from '../configurable-activity-card/configurable-activity-card.component';

interface ActivitiesByDateListProps {
  grouping: ActivitiesByDateGrouping;
}

export const ActivitiesByDateList = ({
  grouping,
}: ActivitiesByDateListProps) => {
  const { formatDate } = useIntl();

  return (
    <Box as="li">
      <Overline as="h2" color="onSurface.text.subdued" marginBottom="one">
        {formatDate(grouping.date, DATE_FORMAT)}
      </Overline>
      <Box as="ul" marginBottom="two">
        {grouping.activities.map((activity) => (
          <ConfigurableActivityCard
            activity={activity}
            key={activity.id}
            activityHeadingLevel="h3"
          />
        ))}
      </Box>
    </Box>
  );
};
