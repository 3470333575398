import { useMutation } from 'react-query';
import {
  postToolboxInteractionHub,
  PersistInputsMutationArgs,
} from '@leagueplatform/health-journey-api';
import { isProduction } from '@leagueplatform/app-environment';

export const useSetToolboxInteractionHub = (
  userActivityId: string,
  userHealthCampaignId?: string,
) => {
  const isProd = isProduction();

  return useMutation<unknown, Error, PersistInputsMutationArgs>(
    ({ components, skippedComponents, completedActivity = false }) =>
      postToolboxInteractionHub({
        userActivityId,
        userHealthCampaignId,
        components,
        skippedComponents,
        completedActivity,
      }),
    {
      onSuccess: (data) => {
        if (!isProd)
          console.log('Success on persisting hub api responses', data);
      },
      onError: (error) => {
        if (!isProd)
          console.error('Error persisting hub api user responses', error);
      },
    },
  );
};
