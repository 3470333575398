import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';

interface CustomWidgetContainerProps {
  children: JSX.Element;
}

export const CustomWidgetContainer = ({
  children,
}: CustomWidgetContainerProps) => (
  <StackLayout horizontalAlignment="stretch">{children}</StackLayout>
);
