import ReconnectingWebSocket, { UrlProvider } from 'reconnecting-websocket';
import { getWebsocketAuthMessage } from 'util/get-websocket-auth-message';

export class LeagueReconnectingSocket extends ReconnectingWebSocket {
  private currentAuthState: string = '';

  constructor(urlProvider: UrlProvider) {
    super(urlProvider, '', { startClosed: true });

    this.addEventListener('open', () => {
      this.authenticate();
    });

    this.addEventListener('message', (message: any) => {
      const unpackedMessage = JSON.parse(message.data);

      if (unpackedMessage.message_type === 'authenticated') {
        this.updateAuth();
      }
    });
  }

  private async authenticate() {
    const authMessage = await getWebsocketAuthMessage();

    if (this.currentAuthState !== JSON.stringify(authMessage.info)) {
      this.send(JSON.stringify(authMessage));
    }
  }

  private async updateAuth() {
    const authMessage = await getWebsocketAuthMessage();
    this.currentAuthState = JSON.stringify(authMessage.info);
  }
}
