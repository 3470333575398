import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Flex,
  BodyOneSecondary,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { genesisFocus } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';
import { BTN_SIZE, LEFT, RIGHT } from '../constants/activity-steps.constants';

const { ARROW_LEFT, ARROW_RIGHT } = HEALTH_JOURNEY_ASSET_KEYS;

const SingleLineCaption = genesisStyled(BodyOneSecondary)(
  css({ whiteSpace: 'nowrap' }),
);

export const ArrowButton = ({ disabled, orientation, focusRef, ...rest }) => {
  // Static Assets
  const arrowRight = handleStaticAsset(ARROW_RIGHT);
  const arrowLeft = handleStaticAsset(ARROW_LEFT);

  // Hooks
  const { formatMessage } = useIntl();
  const rightOrientation = orientation === RIGHT;
  const accessibilityText = formatMessage({
    id: orientation === LEFT ? 'PREVIOUS' : 'NEXT',
  });

  const leftStyles = {
    backgroundColor: 'decorative.brand.primary.pastel',
    hoverStyle: {
      backgroundColor: 'decorative.brand.primary.brightest',
    },
  };
  const rightStyles = {
    backgroundColor: 'primary.background.default',
    hoverStyle: {
      backgroundColor: 'primary.background.hovered',
    },
  };

  const derivedStyles = orientation === RIGHT ? rightStyles : leftStyles;

  return (
    <Flex
      as="button"
      aria-label={accessibilityText}
      alignItems="center"
      justifyContent="center"
      minWidth={BTN_SIZE}
      minHeight={BTN_SIZE}
      disabled={disabled}
      border="none"
      borderRadius="circle"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...derivedStyles}
      disabledStyle={{
        backgroundColor: 'decorative.brand.primary.pastel',
        cursor: 'not-allowed',
      }}
      transition="background 0.3s ease-out"
      focusStyle={genesisFocus}
      ref={focusRef}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...rest}
    >
      <Image
        src={orientation === RIGHT && !disabled ? arrowRight : arrowLeft}
        alt={accessibilityText}
        width="initial"
        display="block"
        transform={`scaleX(${orientation === LEFT ? '-1' : '1'})`}
      />
      {rightOrientation && (
        <SingleLineCaption marginTop="one" position="absolute" top="100%">
          {formatMessage({ id: 'PRESS_ENTER' })}
        </SingleLineCaption>
      )}
    </Flex>
  );
};

ArrowButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf([LEFT, RIGHT]).isRequired,
  focusRef: PropTypes.func,
};

ArrowButton.defaultProps = {
  focusRef: null,
};
