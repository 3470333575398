import React, {
  useRef,
  useCallback,
  useMemo,
  forwardRef,
  createElement,
  ChangeEventHandler,
} from 'react';
import { useIntl } from '@leagueplatform/locales';
import { MultipleSelectAnswerOption } from '@leagueplatform/health-journey-api';
import { UseControllerReturn } from '@leagueplatform/web-common';
import { GDSInputStatus } from '@leagueplatform/genesis-core';
import {
  CheckboxOption,
  FreeTextOption,
  RadioOption,
} from './activity-toolbox-multi-select-option.component';
import { ActivityToolboxOptionLabel } from '../../../common/activity-toolbox-option-label.component';

// Types
type MapOptionToProps = (value: string, checked: boolean) => void;

type FieldProps = UseControllerReturn<
  Record<string, (string | number)[]>
>['field'];
interface MultiSelectOptionResolverProps extends FieldProps {
  option: MultipleSelectAnswerOption;
  optionId: string;
  optionType: typeof CheckboxOption | typeof RadioOption;
  disabled?: boolean;
  inputStatus?: GDSInputStatus;
}

// Components
export const ActivityToolboxMultiSelectOptionResolver = forwardRef<
  HTMLInputElement,
  MultiSelectOptionResolverProps
>(({ optionType: Option, option, optionId, name, disabled, ...field }, ref) => {
  const { formatMessage } = useIntl();
  const {
    enableFreeText,
    exclusive,
    iconUrl,
    freeTextValidation,
    subText,
    text,
    id,
    isSelected,
    value: initialValue,
  } = option;

  // Expose react-hook-form's input ref by converting it from a ref function to a ref object
  const fieldRef = useRef<HTMLInputElement | null>(null);

  // currentState reconciles the initial option value/checked state with a potential free text value
  const currentValue = useMemo(
    () => (enableFreeText ? text : initialValue) ?? '',
    [enableFreeText, initialValue, text],
  );

  const ariaLabel = useMemo(
    () =>
      [
        enableFreeText &&
          formatMessage(
            { id: 'TOOLBOX_INPUT_OPTION_FREE_TEXT' },
            { hasValue: Boolean(currentValue), value: currentValue },
          ),
        exclusive && formatMessage({ id: 'TOOLBOX_INPUT_OPTION_EXCLUSIVE' }),
      ]
        .filter(Boolean)
        .join('; ')
        .trim(),
    [currentValue, enableFreeText, exclusive, formatMessage],
  );

  const mapOptionToProps: MapOptionToProps = useCallback(
    (value, checked = isSelected) => {
      if (fieldRef.current) {
        fieldRef.current.value = value;
        fieldRef.current.checked = checked;
      }

      if (option.enableFreeText) {
        Object.assign(option, { text: value, isSelected: checked });
      } else {
        Object.assign(option, { value, isSelected: checked });
      }
    },
    [isSelected, option],
  );

  // An onChange handler used by the radio/checkbox as well as the free text
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { target } = event;
      const { value, checked } = target;

      mapOptionToProps(value, checked);
      field.onChange(option);
    },
    [field, mapOptionToProps, option],
  );

  const commonProps = {
    ...field,
    value: currentValue,
    disabled,
    isSelected,
    onChange,
  };

  const optionProps = { id, ...commonProps };

  // Use free text input as label or a static label
  const OptionLabel = enableFreeText
    ? createElement(FreeTextOption, {
        ...commonProps,
        ariaLabel,
        validation: freeTextValidation,
        id: name, // using the radio/checkbox name as the id allows the free-text field to act as the label when clicked
        name: optionId, // using the optionId as the name for the free-text field allows us to track/validate it separately
      })
    : createElement(ActivityToolboxOptionLabel, {
        text,
        subText,
        iconUrl,
        ariaLabel,
      });

  return (
    <Option
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...optionProps}
      name={name}
      id={optionId}
      value={currentValue}
      label={OptionLabel}
      ref={(node: HTMLInputElement) => {
        fieldRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        }
      }}
    />
  );
});
