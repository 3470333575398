import React from 'react';
import { string } from 'prop-types';
import { BodyTwo } from '@leagueplatform/genesis-commons';
import { HighlightBanner } from '@leagueplatform/web-common-components';

export const ActivitySuggestionBanner = ({
  title,
  description,
  iconUrl,
  campaignMode,
}) =>
  campaignMode ? (
    <HighlightBanner title={title} icon={iconUrl}>
      {description}
    </HighlightBanner>
  ) : (
    <BodyTwo marginBottom="oneAndHalf" color="onSurface.text.subdued">
      {description}
    </BodyTwo>
  );

ActivitySuggestionBanner.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  iconUrl: string.isRequired,
  campaignMode: string,
};

ActivitySuggestionBanner.defaultProps = {
  campaignMode: undefined,
};
