import React from 'react';
import { getConfig, useConfigProperty } from '@leagueplatform/core';
import { Image } from '@leagueplatform/genesis-commons';

type AssetMap = Record<string, unknown>;

const defaultAssets: AssetMap = {};

/**
 * @name setDefaultAssets
 * @desc adds entries to the default asset map. To be used by SDK modules to register asset keys
 * which can be overridden by the host app.
 */
export function setDefaultAssets(assetMap: AssetMap) {
  Object.entries(assetMap).forEach(([key, value]) => {
    defaultAssets[key] = value;
  });
}

/**
 * @name renderComponent
 * @desc render the asset as a React Component, or within an image component
 */

function renderComponent(asset: any, props?: { [key: string]: unknown }) {
  const { alt = '' } = props as { alt?: string; [key: string]: unknown };

  if (typeof asset !== 'string') {
    const Component = asset;

    if (props) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }
    return <Component />;
  }

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      src={asset as string}
      alt={alt}
    />
  );
}

interface HandleStaticAssetOptions {
  isComponent?: boolean;
  props?: { [key: string]: unknown };
}

/**
 * @name doHandleStaticAsset
 * @desc given an asset key and a map of asset overrides, returns the corresponding asset
 * from the asset overrides map or the default asset map.
 */
function doHandleStaticAsset(
  assetKey: string,
  assetOverrides: AssetMap,
  options?: HandleStaticAssetOptions,
) {
  const asset = assetOverrides[assetKey] || defaultAssets[assetKey];

  if (!asset) {
    throw new Error(
      `It looks like the asset "${assetKey}" you are trying to render is not set in the assets map (e.g. asset-map.tsx). Please review your asset map and make sure all assets are defined.`,
    );
  }

  const { isComponent, props = {} } = options || {};

  // treat the mapping as a React component
  if (isComponent) {
    return renderComponent(asset, props);
  }

  // throw an error if is an invalid/undefined asset

  // throw an error if the consumer passes a component without including isComponent
  if (typeof asset !== 'string' && !isComponent) {
    throw new Error(
      `It looks like the asset "${assetKey}" you are trying to render is a React Component or function. Set isComponent to true in order to do so.`,
    );
  }

  return asset;
}

/**
 * @deprecated use `useStaticAsset` instead.
 * @name handleStaticAsset
 * @desc given an asset key, returns the corresponding asset
 * from the asset overrides or the default asset map.
 */
export function handleStaticAsset(
  assetKey: string,
  options?: HandleStaticAssetOptions,
) {
  return doHandleStaticAsset(
    assetKey,
    getConfig().assets?.overrides || {},
    options,
  );
}

/**
 * @name useStaticAsset
 * @desc given an asset key, returns the corresponding asset
 * from the asset overrides or the default asset map.
 */
export function useStaticAsset(
  assetKey: string,
  options?: HandleStaticAssetOptions,
) {
  return doHandleStaticAsset(
    assetKey,
    useConfigProperty('assets.overrides', {}),
    options,
  );
}
