import { getConfig } from '@leagueplatform/core';
import {
  type MasonryEngineActionHandlerMap,
  type MasonryEngineActionHandler,
} from '@leagueplatform/masonry-engine';
import { postWidgetDismiss } from '@leagueplatform/masonry-api';
import type {
  MasonryDriverAction,
  MasonryDeeplinkAction,
  MasonryDismissAction,
  MasonryAnalyticsAction,
  MasonryOnLoadAction,
} from '@leagueplatform/masonry-widgets';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';

const trackMasonryAnalytics = (event: EVENT_NAME, widgetProperties: object) =>
  trackAnalyticsEvent(event, {
    ...widgetProperties,
  });

const DeeplinkActionHandler: MasonryEngineActionHandler<
  MasonryDeeplinkAction,
  MasonryDriverAction
> = (action) => {
  const config = getConfig();
  if (config && action.payload?.url)
    config.core.customMethods.handleLink(action.payload.url);
};

const DismissActionHandler: MasonryEngineActionHandler<
  MasonryDismissAction,
  MasonryDriverAction
> = async (
  action,
  // _: any,
  // stateController: MasonryEngineStateControllerCallbacks,
) => {
  try {
    if (action.payload) {
      await postWidgetDismiss(action.payload.href, {
        widget_id: 'test',
      });
      // trigger api call completed event from here
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error Dismiss');
  }
  // stateController.setNodeState('hide', action.origin.nodeId, true);
};

const AnalyticsActionHandler: MasonryEngineActionHandler<
  MasonryAnalyticsAction,
  MasonryDriverAction
> = async (action) => {
  const { event_name: eventName, payload } = action.payload;
  if (eventName && payload) trackMasonryAnalytics(eventName, payload);
};

// Am empty action handler is required for onLoad actions as emitAction requires an action handler for every action passed to it
const OnLoadActionHandler: MasonryEngineActionHandler<
  MasonryOnLoadAction,
  MasonryDriverAction
> = async () => {};

export const masonryDriverActionHandlerMap: MasonryEngineActionHandlerMap<MasonryDriverAction> =
  {
    deeplink: DeeplinkActionHandler,
    dismiss: DismissActionHandler,
    analytics: AnalyticsActionHandler,
    onLoad: OnLoadActionHandler,
  };
