import { AuthClient } from './auth-client';
import type { AuthClientParams } from './auth-client';

export interface StandaloneAuthInitConfig extends AuthClientParams {}

export class StandaloneAuthWrapper {
  #authClient: AuthClient | null = null;

  initialize(config: StandaloneAuthInitConfig) {
    this.#authClient = new AuthClient(config);
  }

  get initialized() {
    return !!this.#authClient;
  }

  get client() {
    if (!this.#authClient) {
      throw new Error('StandaloneAuth was not initialized');
    }
    return this.#authClient;
  }
}

export const StandaloneAuth = new StandaloneAuthWrapper();
