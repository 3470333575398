import React from 'react';
import {
  GenesisRouterLink,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import {
  BodyOneSecondary,
  BodyTwoSecondary,
  Box,
  HeadingThree,
  Flex,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useGetUserStandaloneActivities } from 'hooks/use-get-user-standalone-activities.hook';
import {
  ENABLE_V3_ACTIVITY_CARDS,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { STANDALONE_ACTIVITIES_LIMIT_ONE } from 'constants/configurable-journey-experience.constants';
import { viewAllActivitiesAnalytics } from 'utils/view-all-activities-analytics.util';
import { PriorityActivityCard } from '../priority-activity-card/priority-activity-card.component';
import { ConfigurableActivityCard } from '../configurable-activity-card/configurable-activity-card.component';

export const StandaloneActivitiesWidget = () => {
  const { formatMessage } = useIntl();
  const { data: isV3ActivityCardsEnabled } = useFeatureFlagQuery(
    ENABLE_V3_ACTIVITY_CARDS,
  );

  // limit of 1 is set for the standalone activities displayed in tab 2
  const { data, isError, isLoading } = useGetUserStandaloneActivities({
    limit: STANDALONE_ACTIVITIES_LIMIT_ONE,
  });
  if (isLoading) {
    return (
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height="100px"
        data-testid="skeleton-loader"
      />
    );
  }
  const isErrorState =
    isError ||
    !data?.userHealthActivities ||
    // If there are no standalone activity, don't display anything. We don't want to display empty state here.
    data?.userHealthActivities.length <= 0;
  if (isErrorState) return null;
  const standaloneActivity = data?.userHealthActivities[0];
  return (
    <Box width="100%">
      <Box marginBottom="oneAndHalf">
        <Flex alignItems="center" justifyContent="space-between">
          <HeadingThree as="h2">
            {formatMessage({
              id: 'STANDALONE_ACTIVITIES',
            })}
          </HeadingThree>
          <GenesisRouterLink
            to={JOURNEY_ROUTES.getStandaloneActivities()}
            aria-label={formatMessage({ id: 'VIEW_ALL_STANDALONE_ACTIVITIES' })}
            onClick={() => viewAllActivitiesAnalytics('standalone')}
          >
            {formatMessage({ id: 'VIEW_ALL' })}
          </GenesisRouterLink>
        </Flex>
        <BodyOneSecondary>
          {formatMessage({
            id: 'STANDALONE_ACTIVITIES_DESCRIPTION',
          })}
        </BodyOneSecondary>
      </Box>
      <BodyTwoSecondary as="h3" marginBottom="one">
        {formatMessage({ id: 'NEXT_ACTIVITY' })}
      </BodyTwoSecondary>
      <Box marginBottom="three" marginTop="one">
        {isV3ActivityCardsEnabled ? (
          <PriorityActivityCard
            activity={standaloneActivity}
            headingLevel="4"
          />
        ) : (
          <ConfigurableActivityCard
            as="div"
            activity={standaloneActivity}
            activityHeadingLevel="h4"
          />
        )}
      </Box>
    </Box>
  );
};
