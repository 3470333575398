import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  ParagraphText,
  UtilityText,
  styled,
  StackLayout,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { ProgressTrackingAttributes } from '@leagueplatform/health-journey-api';

const STROKE_DASHARRAY = 569;

const Chart = styled('svg', {
  path: {
    strokeLinecap: 'round',
    strokeWidth: 16,

    '&.base': {
      stroke: '$onSurfaceBorderSubdued',
    },

    '&.progress': {
      stroke: '$inputBorderSuccess',
      strokeDasharray: STROKE_DASHARRAY,
      strokeDashoffset: '$$strokeDashoffset',
    },
  },
});

export interface ProgressTrackerChartProps
  extends Omit<
    ProgressTrackingAttributes,
    'inputMethod' | 'validation' | 'editButtonLabel'
  > {
  css?: GDSStyleObject;
}

export function ProgressTrackerChart({
  unit,
  currentProgress,
  goal,
  progressPercentage,
  css,
}: ProgressTrackerChartProps) {
  // Hooks
  const { formatMessage, formatNumber } = useIntl();

  const progressPercentageToDisplay = Math.min(progressPercentage, 100);
  const progressString = `${progressPercentageToDisplay}% ${formatMessage({
    id: 'COMPLETE',
  })}`;

  const strokeDashoffset =
    STROKE_DASHARRAY - STROKE_DASHARRAY * (progressPercentageToDisplay / 100);

  return (
    <Box aria-hidden="true" css={{ position: 'relative', width: 265, ...css }}>
      <Chart
        width="265"
        height="230"
        css={{
          $$strokeDashoffset: strokeDashoffset,
        }}
      >
        <path
          className="base"
          d="M 47 218 A 120 120 0 1 1 217 218"
          fill="none"
        />
        <path
          className="progress"
          d="M 47 218 A 120 120 0 1 1 217 218"
          fill="none"
        />
      </Chart>
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <UtilityText
          size="eyebrow"
          emphasis="emphasized"
          css={{ marginTop: '$quarter' }}
        >
          {unit}
        </UtilityText>
        <HeadingText
          as="p"
          size="xxl"
          level="1"
          css={{ marginTop: '$quarter' }}
        >
          {formatNumber(currentProgress)}
        </HeadingText>
        <ParagraphText
          size="sm"
          emphasis="subtle"
          css={{ marginTop: '$quarter' }}
        >
          / {formatNumber(goal)} {unit}
        </ParagraphText>
        <UtilityText
          emphasis="emphasized"
          css={{
            marginTop: '$threeQuarters',
            textTransform: 'lowercase',
            color: '$successIcon',
          }}
        >
          {progressString}
        </UtilityText>
      </StackLayout>
    </Box>
  );
}
