import React from 'react';
import {
  Card,
  useClickableCard,
  StackLayout,
  StackItem,
  Button,
  useThemeStrings,
} from '@leagueplatform/genesis-core';
import { WidgetType } from '@leagueplatform/masonry-api';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { MasonryNotificationBannerContentRenderer } from './masonry-notification-banner-content-renderer';
import {
  MasonryDeeplinkAction,
  MasonryDismissAction,
} from '../../types/masonry-driver-node-actions';

type MasonryNotificationBannerNodeProperties = {
  heading: string;
  body: string;
  image?: string;
  imageAltText?: string;
};

type MasonryNotificationBannerNodeActions = {
  onClick?: MasonryDeeplinkAction;
  onDismiss?: MasonryDismissAction;
};

export type MasonryNotificationBannerNode = MasonryEngineNode<
  WidgetType.NOTIFICATION_BANNER,
  MasonryNotificationBannerNodeProperties,
  MasonryNotificationBannerNodeActions
>;

export type MasonryNotificationBannerRendererProps =
  MasonryEngineNodeRendererProps<MasonryNotificationBannerNode>;

export const MasonryNotificationBannerRenderer = ({
  heading,
  image,
  body,
  imageAltText,
  onClick,
  onDismiss,
}: MasonryNotificationBannerRendererProps) => {
  const strings = useThemeStrings();

  const isClickable = !!onClick;
  const dismissible = Boolean(onDismiss);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Elevated
      css={{
        ' &:has(.GDS-card-click-area)': {
          border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
          boxShadow: '$card',
        },
        '&:has(.GDS-card-click-area):hover': {
          cursor: 'pointer',
          border: '$borderWidths$thin solid $colors$interactiveFocusOuter',
          'a:hover, button:hover': {
            textDecoration: 'none',
          },
        },
      }}
    >
      <StackLayout orientation="horizontal" verticalAlignment="stretch">
        {isClickable ? (
          <Card.ClickArea
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: '$one',
              padding: '$threeQuarters',
              paddingRight: '$half',
              border: 'none',
              boxShadow: 'none',
              '&:hover': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
            ref={clickAreaRef}
            onCardMouseUp={onCardMouseUp}
            onCardMouseDown={onCardMouseDown}
          >
            <MasonryNotificationBannerContentRenderer
              image={image}
              imageAltText={imageAltText}
              heading={heading}
              body={body}
              onClick={onClick}
              ref={primaryActionRef}
            />
          </Card.ClickArea>
        ) : (
          <StackLayout
            orientation="horizontal"
            verticalAlignment="center"
            spacing="$one"
            css={{
              padding: '$threeQuarters',
              paddingRight: '$half',
            }}
          >
            <MasonryNotificationBannerContentRenderer
              image={image}
              imageAltText={imageAltText}
              heading={heading}
              body={body}
            />
          </StackLayout>
        )}

        {dismissible && (
          <StackItem
            verticalAlignment="top"
            css={{ padding: '$threeQuarters', paddingLeft: '$half' }}
          >
            <Button
              hideLabel
              size="small"
              icon="tinyClose"
              priority="tertiary"
              quiet
              onClick={(event: React.MouseEvent) => {
                if (onDismiss) {
                  invokeAction({
                    event,
                    ...onDismiss,
                  });
                }
              }}
            >
              {strings.dismiss}
            </Button>
          </StackItem>
        )}
      </StackLayout>
    </Card.Elevated>
  );
};
