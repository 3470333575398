import * as React from 'react';
import { string } from 'prop-types';
import { Box, HeadingThree } from '@leagueplatform/genesis-commons';
import { HtmlToReact } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { ActivityHtmlToReactOptions } from 'common/activity-html-to-react-options';

export const ActivityReferences = ({ references, ...props }) => {
  const { formatMessage } = useIntl();

  if (!references) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Box {...props} data-testid="activity-references">
      <HeadingThree as="h2" marginBottom="half">
        {formatMessage({ id: 'REFERENCES' })}
      </HeadingThree>
      <HtmlToReact
        htmlString={references}
        options={ActivityHtmlToReactOptions}
      />
    </Box>
  );
};

ActivityReferences.propTypes = {
  references: string,
};

ActivityReferences.defaultProps = {
  references: '',
};
