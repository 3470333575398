import * as React from 'react';
import { SingleMeasurementTooltip } from 'components/charts/core/chart-tooltip';
import { useIntl } from '@leagueplatform/locales';
import {
  LONG_WEEKDAY_DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { HealthMetricChart } from 'components/charts/types/chart';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import { CHART_VIEWS } from 'components/charts/constants/chart-views';
import { MetricBarChart } from '../metric-bar-chart';

type ActiveDurationBarChartProps = HealthMetricChart;

export function ActiveDurationBarChart({
  data,
  view,
  type,
  unit,
}: ActiveDurationBarChartProps) {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();

  return (
    <MetricBarChart
      yMaxFromEnums={0}
      data={data}
      view={view}
      type={type}
      description={formatMessage({
        id:
          view === CHART_VIEWS.WEEKLY
            ? 'INSIGHT_CHART_ACTIVE_DURATION_WEEK_DESCRIPTION'
            : 'INSIGHT_CHART_ACTIVE_DURATION_MONTH_DESCRIPTION',
      })}
      axisLeftLabel={`${formatMessage({
        id: 'ACTIVE_DURATION',
      })} (${formatUnit(type, unit)})`.toLocaleUpperCase()}
      ariaDatumLabel={(datum) =>
        formatMessage(
          { id: 'INSIGHT_CHART_ACTIVE_DURATION_DATUM_LABEL' },
          {
            value: formatMetricValue(datum.value, type),
            date: formatDateWithUserProfileLocation(
              new Date(datum.timestamp),
              LONG_WEEKDAY_DATE_FORMAT,
            ),
          },
        )
      }
      renderTooltip={(datum) => (
        <SingleMeasurementTooltip
          title={formatMessage({ id: 'ACTIVE_DURATION' })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datum}
        />
      )}
    />
  );
}
