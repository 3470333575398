import * as React from 'react';
import {
  HeadingText,
  Icon,
  ParagraphText,
  StackLayout,
  styled,
  UtilityText,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

type Measurement = {
  value: string;
  unit?: string;
};

export type MasonryMetricCardNodeProperties = {
  heading: string;
  measurements?: Measurement[];
  body?: string;
  timestamp?: string;
  image?: string;
  imageAltText?: string;
};

export type MasonryMetricCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryMetricCardNode = MasonryEngineNode<
  WidgetType.METRIC_CARD,
  MasonryMetricCardNodeProperties,
  MasonryMetricCardNodeActions
>;

export type MasonryMetricCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryMetricCardNode>;

const StyledImage = styled('img', {
  contentFit: 'cover',
  contentPosition: 'center',
  width: '$three',
  height: '$three',
});

export function MasonryMetricCardRenderer({
  heading,
  measurements,
  body,
  timestamp,
  image,
  imageAltText,
  onClick,
}: MasonryMetricCardRendererProps) {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  // using double ! operator to get explicit booleans, rather than just truthy/falsy values
  const displayMeasurements = !!measurements?.length;
  // display body if: no `measurements` exist AND `body` exists
  const displayBody = !displayMeasurements && !!body;
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ConditionalLinkCardRenderer
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      hasLink={Boolean(onClick?.payload.url)}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        horizontalAlignment="spaceBetween"
        spacing="$threeQuarters"
        css={{ padding: '$oneAndHalf', flexWrap: 'wrap' }}
      >
        {image && <StyledImage src={image} alt={imageAltText} />}
        <StackLayout
          css={{
            width: '100%',
          }}
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
        >
          <StackLayout
            css={{
              width: '100%',
            }}
            spacing="$quarter"
            verticalAlignment="spaceBetween"
          >
            <HeadingText size="sm" level="3" color="$onSurfaceTextPrimary">
              <ConditionalLinkRenderer
                ref={primaryActionRef}
                onClick={(event: React.MouseEvent) => {
                  if (onClick) {
                    invokeAction({
                      event,
                      ...onClick,
                    });
                  }
                }}
                url={onClick?.payload.url}
              >
                {heading}
              </ConditionalLinkRenderer>
            </HeadingText>
            {displayMeasurements ? (
              <StackLayout orientation="horizontal">
                <ParagraphText
                  data-testid="measurement"
                  css={{
                    marginRight: '$threeQuarters',
                    width: '100%',
                    '&&': {
                      marginBlockEnd: '$none',
                    },
                  }}
                >
                  {measurements.map(({ value, unit }) => (
                    <React.Fragment key={`${value}${unit}`}>
                      <UtilityText
                        css={{
                          paddingRight: '$quarter',
                          typography: '$headingThree',
                        }}
                      >
                        {value}
                      </UtilityText>
                      {unit && (
                        <UtilityText
                          css={{
                            typography: '$label',
                            color: '$onSurfaceTextSubdued',
                            paddingRight: '$quarter',
                          }}
                        >
                          {unit}{' '}
                        </UtilityText>
                      )}
                    </React.Fragment>
                  ))}
                </ParagraphText>
              </StackLayout>
            ) : null}
            {displayBody ? (
              <UtilityText
                css={{
                  typography: '$caption',
                  color: '$onSurfaceTextSubdued',
                }}
              >
                <HtmlToReact htmlString={body} />
              </UtilityText>
            ) : null}
          </StackLayout>

          {timestamp && (
            <ParagraphText
              css={{
                typography: '$label',
                color: '$onSurfaceTextSubdued',
              }}
            >
              {timestamp}
            </ParagraphText>
          )}
        </StackLayout>
        {onClick?.payload.url && (
          <Icon
            size={24}
            icon="interfaceChevronRight"
            tint="$onSurfaceIconPrimary"
          />
        )}
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
}
