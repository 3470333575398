import {
  LeagueSocketAsPromised,
  WebsocketApiURL,
} from '@leagueplatform/league-socket';
import * as uuid from 'uuid';
import {
  captureError,
  finishSpan,
  startSpanOnActiveTransaction,
  combineErrorContexts,
  captureMessage,
} from '@leagueplatform/observability';
import type { ErrorContext } from '@leagueplatform/observability';
import { getThrownErrorMessage } from './util/get-thrown-error-message';
import { getUserConfigureConnection } from './util/get-user-configure-connection';

export interface WebsocketMessage {
  message_type: string;
  message_id?: string;
  info?: Record<string, any>;
}
export interface FetchOptions {
  errorContext?: ErrorContext;
}
/**
 * Match a League WebSocket response message with a few patterns that are known
 * to represent errors.
 */
function isErrorMessage(message: WebsocketMessage) {
  if (!message) return true;
  if (message.message_type === 'fail') return true;
  if (message.message_type === 'server_error') return true;
  if (message?.info?.code === 'invalid_request') return true;
  return false;
}

const NORMAL_CLOSURE_CODE = 1000;

// Number of times we are allowed to attempt to reopen a closed connection
const MAX_RECONNECTION_RETRIES = 2;

/**
 * Number of miliseconds of a successfully open connection after which the
 * counter above is reset to 0
 */
const RECONNECTION_COUNTER_RESET_MILIS = 30000;

type FetchAsPromiseOptions = {
  anonymous?: boolean;
};

export class FetchAsPromise extends LeagueSocketAsPromised {
  #options: FetchAsPromiseOptions;

  /**
   * This promise will be created on the first call to `fetch` by setting it to
   * the promise returned by `init`. That call to `fetch`, as well as every subsequent
   * call to it, will then await this promise before proceeding to send messages.
   */

  #initPromise: Promise<void> | undefined;

  #numReconnectionRetries = 0;

  /**
   * When the connection opens, we count back from RECONNECTION_COUNTER_RESET_MILIS
   * and then reset the reconnection retries counter. But when the connection closes
   * unexpectedly, we want to cancel this reset. So, we store the timeout
   * here.
   */
  #onOpenTimeout: number | undefined;

  constructor(
    url: WebsocketApiURL,
    options: FetchAsPromiseOptions = { anonymous: false },
  ) {
    super(url);

    this.#options = options;

    this.onOpen.addListener(() => {
      if (this.#onOpenTimeout !== undefined) {
        window.clearTimeout(this.#onOpenTimeout);
      }
      this.#onOpenTimeout = window.setTimeout(() => {
        /**
         * It's been RECONNECTION_COUNTER_RESET_MILIS since the connection
         * opened and remained open. Let's reset the "max retry" counter.
         */
        this.#numReconnectionRetries = 0;
      }, RECONNECTION_COUNTER_RESET_MILIS);
    });

    this.onClose.addListener((event) => {
      const { code, reason, wasClean } = event;
      const context = {
        code,
        reason,
        wasClean,
      };

      if (code !== NORMAL_CLOSURE_CODE) {
        /**
         * The connection closed unexpectedly. Clear the "counter reset" timeout.
         */
        window.clearTimeout(this.#onOpenTimeout);

        if (this.#numReconnectionRetries < MAX_RECONNECTION_RETRIES) {
          this.#numReconnectionRetries += 1;
          /**
           * By assinging a new `init` promise to `this.#initPromise`, we ensure that
           * any calls to `this.fetch()` that come in while we reconnect await our
           * reconnection attempt before proceeding. This is the same logic as when
           * this class is initialized in the first place.
           */
          this.#initPromise = this.#init();
        } else {
          captureMessage(
            'WebSocket connection closed unexpectedly, but we are out of retry attempts.',
            {
              severityLevel: 'warning',
              context: {
                'WS Close Event': context,
              },
            },
          );
        }
      } else {
        captureMessage('WebSocket connection closed with a normal code.', {
          severityLevel: 'info',
          context: {
            wsCloseEvent: context,
          },
        });
      }
    });

    this.onError.addListener((event) => {
      captureError(new Error('WebSocket connection error'), {
        context: {
          event,
        },
      });
    });

    if (
      typeof window !== undefined &&
      typeof window.addEventListener === 'function'
    ) {
      window.addEventListener('unload', this.unload);
    }
  }

  async #init() {
    const span = startSpanOnActiveTransaction({
      /**
       * we set the op to the same value that Sentry's
       * built-in instrumentation sets it when it creates
       * a `fetch` span. This will allow us to query for aggregate
       * data about API-related spans that incldues both WS calls and
       * REST calls.
       */
      op: 'http.client',
      description: 'Initializing WebSocket connection',
      tags: {
        isWS: true,
        isAnonymous: !!this.#options.anonymous,
      },
    });

    try {
      await this.open();

      if (!this.#options.anonymous) {
        await this.authenticate();
      }

      await this.configureConnection();
    } catch (error) {
      if (span) finishSpan(span);
      throw error;
    }
    if (span) finishSpan(span);
  }

  /**
   * Defined as a public class field arrow function instead of a public class method
   * so that we get the automatic `this`-binding, since this function is passed
   * directly to `window.addEventListener`
   */
  unload = async () => {
    await this.close(NORMAL_CLOSURE_CODE);

    if (
      typeof window !== undefined &&
      typeof window.addEventListener === 'function'
    ) {
      window.removeEventListener('unload', this.unload);
    }
  };

  async configureConnection() {
    const configMessage = getUserConfigureConnection();

    await this.sendRequest(configMessage, {
      requestId: uuid.v4(),
    });
  }

  /**
   * Extend superclass with a fetch-like interface that applies a unique message
   * ID and throws errors given in the response. It also handles waiting for
   * the socket to open so that consumers don't have to remember to do that.
   */
  async fetch(message: WebsocketMessage, fetchOptions: FetchOptions = {}) {
    if (!this.#initPromise) {
      /**
       * if we haven't done so before, assign our `initPormise` field to the promise
       * returned by the `init` method.
       *
       * This call, and future calls to `fetch` will then `await` this promise
       * to ensure they only proceed with sending messages after the `init` promise
       * is resolved.
       */

      this.#initPromise = this.#init();
    }
    const span = startSpanOnActiveTransaction({
      op: 'http.client',
      description: message.message_type,
      tags: {
        isWS: true,
      },
    });

    const { errorContext: optionalErrorContext = {} } = fetchOptions;

    let response;

    try {
      await this.#initPromise;

      if (!this.#options.anonymous) {
        await this.authenticateIfNeeded();
      }

      response = await this.sendRequest(message, {
        requestId: uuid.v4(),
      });
    } catch (error) {
      const errorMessage = getThrownErrorMessage(error);
      const fetchError = new Error(
        `{ "info": { "reason": "${errorMessage}" } }`,
      );
      const fetchErrorContext = combineErrorContexts([
        { errorName: 'Socket Fetch Error' },
        optionalErrorContext,
      ]);

      captureError(fetchError, fetchErrorContext);
      if (span) finishSpan(span);
      throw fetchError;
    }

    if (span) finishSpan(span);

    if (isErrorMessage(response)) {
      const messageType = response.info?.message?.message_type;
      const fetchErrorContext = combineErrorContexts([
        {
          errorName: `Socket Fetch Response Error: ${messageType}`,
          tags: {
            messageType,
            messageId: response.info?.message?.message_id,
          },
        },
        optionalErrorContext,
      ]);

      captureError(Error(response.info?.reason), fetchErrorContext);

      throw Error(JSON.stringify(response));
    }

    return response?.info;
  }
}
