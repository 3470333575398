import * as React from 'react';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
  UtilityText,
  Icon,
  GDSColor,
} from '@leagueplatform/genesis-core';
import {
  Badge,
  ConditionalLink,
  ConditionalLinkCard,
} from '@leagueplatform/web-common-components';
import { ProgramProgress } from '../program-progress/program-progress.component';

type BadgeProps = {
  body: string;
  image: string;
  imageTint?: GDSColor;
  color: GDSColor;
};
type Content = {
  currentText: string;
  suffixText?: string;
  image: string;
};

type HistoryActivityCardProps = {
  status: 'completed' | 'missed';
  eyebrow?: string;
  heading: string;
  badge?: BadgeProps;
  image?: string;
  imageAltText?: string;
  imageTint?: GDSColor;
  body?: string;
  content?: Content;
  cta?: string;
};

export function HistoryActivityCard({
  status,
  eyebrow,
  heading,
  badge,
  image,
  imageAltText,
  imageTint,
  body,
  content,
  cta,
}: HistoryActivityCardProps) {
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(cta)}
      cardStyle={{
        paddingY: 'one',
        paddingLeft: 'oneAndHalf',
        paddingRight: 'one',
      }}
    >
      <StackLayout orientation="horizontal" spacing="$half">
        <StackLayout spacing="$threeQuarters">
          {badge ? (
            <Badge
              css={{ backgroundColor: badge.color, borderRadius: '$small' }}
            >
              <>
                <Icon
                  icon={badge.image}
                  tint={badge.imageTint}
                  size="$threeQuarters"
                />
                <UtilityText size="eyebrow" emphasis="emphasized">
                  {badge.body}
                </UtilityText>
              </>
            </Badge>
          ) : null}
          {eyebrow ? <UtilityText size="xs">{eyebrow}</UtilityText> : null}
          <HeadingText
            size="sm"
            level="2"
            emphasis={status === 'missed' ? 'subtle' : undefined}
            css={{
              a: {
                all: 'inherit',
              },
            }}
          >
            <ConditionalLink ref={linkRef} url={cta}>
              {heading}
            </ConditionalLink>
          </HeadingText>
          {body ? (
            <ParagraphText emphasis="subtle" size="sm">
              {body}
            </ParagraphText>
          ) : null}
          {content ? (
            <div>
              <ProgramProgress
                suffixText={content.suffixText}
                currentText={content.currentText}
                pointsIcon={content.image}
              />
            </div>
          ) : null}
        </StackLayout>
        {image ? (
          <Icon icon={image} tint={imageTint} label={imageAltText} />
        ) : null}
      </StackLayout>
    </ConditionalLinkCard>
  );
}
