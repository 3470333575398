import * as React from 'react';
import { genesisStyled, css, Box } from '@leagueplatform/genesis-commons';

const StyledCard = genesisStyled(Box)(() =>
  css({
    position: 'relative',
    '&:hover::before, &:focus-within::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderWidth: 'thick',
      borderStyle: 'solid',
      borderColor: 'interactive.focus.outer',
      borderRadius: 'large',
    },
  }),
);

type CardContainerProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof Box>;

/*
 * TODO: Eventually this should be refactored and we should use
 * genesis core card component instead.
 */
export function CardContainer({ children, ...props }: CardContainerProps) {
  return (
    <StyledCard
      borderRadius="large"
      boxShadow="card"
      backgroundColor="surface.background.primary"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </StyledCard>
  );
}
