import React, { forwardRef } from 'react';
import * as RadixPopoverPrimitive from '@radix-ui/react-popover';
import { Button, GDSButtonSize } from 'components/button/button';
import { IconSource } from 'components/icon/icon';
import type { GDSCustomizableComponent } from '../../types';
import { styled } from '../../theme';
import { GDSResponsiveProp } from '../../types';

export interface GDSPopoverTriggerProps
  extends RadixPopoverPrimitive.PopoverTriggerProps,
    GDSCustomizableComponent {
  hideLabel?: boolean;
  icon?: IconSource;
  label?: string;
  priority?: 'primary' | 'secondary' | 'tertiary';
  quiet?: boolean;
  size?: GDSResponsiveProp<GDSButtonSize>;
}

const BaseTrigger = styled(RadixPopoverPrimitive.Trigger, {});

export const PopoverTrigger = forwardRef<
  HTMLButtonElement,
  GDSPopoverTriggerProps
>(
  (
    {
      children,
      css,
      hideLabel,
      icon,
      label,
      priority,
      quiet,
      size,
      className,
      ...props
    },
    ref,
  ) => {
    if (!label && !children)
      console.warn(
        'To show a Popover initiator, set the label on Popover.Trigger or provide a custom button element as a child of Popover.Trigger.',
      );
    return (
      <BaseTrigger
        className={['GDS-popover-trigger', className].join(' ')}
        css={css}
        ref={ref}
        asChild
      >
        {label ? (
          <Button
            hideLabel={hideLabel}
            icon={icon}
            priority={priority}
            quiet={quiet}
            size={size}
            {...props}
          >
            {label}
          </Button>
        ) : (
          children
        )}
      </BaseTrigger>
    );
  },
);
