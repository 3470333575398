import React, { FC, ReactElement } from 'react';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import type { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import type { GDSIconToken, GDSIcons } from '../../theme/icons/get-theme-icons';
import type { GDSColor, GDSSize } from '../../theme';
import { styled } from '../../theme';
import { useThemeIcons } from '../../hooks/use-theme';
import { isReactElement } from '../forms/utilities';

// The syntax to retain autocomplete of GDSIconToken but also allow for path strings
// This is necessary because doing icon: GDSIconToken | string; removes autocomplete
// https://github.com/microsoft/TypeScript/issues/29729#issuecomment-460346421
type FilePath = string & { what?: any };

export type IconSource = GDSIconToken | FilePath;
export interface GDSIconProps extends GDSCustomizableComponent {
  icon: IconSource | ReactElement;
  tint?: GDSColor;
  size?: GDSResponsiveProp<number | string | GDSSize>;
  label?: string;
}
const IconBase = styled('div', { display: 'inline-block' });

export const isGDSIconToken = (
  iconToken: IconSource,
  iconMap: GDSIcons,
): iconToken is GDSIconToken => iconToken in iconMap;

export const Icon: FC<GDSIconProps> = ({
  icon,
  tint,
  size = '$oneAndHalf',
  label,
  css,
  className,
  ...props
}) => {
  const iconSize = useResponsiveProp(size);
  const iconMap = useThemeIcons();
  const iconClassName = ['GDS-icon', className].join(' ');

  if (typeof icon === 'string' && isGDSIconToken(icon, iconMap)) {
    return (
      <IconBase
        as="svg"
        className={iconClassName}
        aria-hidden={!label}
        aria-label={label}
        css={{ color: tint, width: iconSize, height: iconSize, ...css }}
        data-testid="svg-icon"
        {...props}
      >
        <use href={`${iconMap[icon]}#${icon}`} />
      </IconBase>
    );
  }

  if (isReactElement(icon)) {
    return (
      <IconBase
        as="div"
        className={iconClassName}
        aria-hidden={!label}
        aria-label={label}
        css={{ color: tint, width: iconSize, height: iconSize, ...css }}
        data-testid="custom-svg-icon"
        {...props}
      >
        {icon}
      </IconBase>
    );
  }

  return (
    <IconBase
      as="img"
      className={iconClassName}
      src={icon as FilePath}
      alt={label || ''}
      css={{ width: iconSize, height: iconSize, ...css }}
      data-testid="img-icon"
      {...props}
    />
  );
};
Icon.displayName = 'Icon';
