import React, { useMemo, useState } from 'react';

// Modules
import { Box, VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  ErrorState,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';

// Utils
import {
  ViewTransitionController,
  SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { getDiffOfDates } from 'utils/user-health-activities/user-health-activities.utils';
import { journeyPageProps } from 'common/health-activities.props';

// Hooks
import { useEmptyActivitiesState } from 'hooks/use-empty-activities-state.hook';
import { useHealthJourneyPage } from 'hooks/use-health-journey-page.hook';
import { usePageViewAnalytics, PRODUCT_AREA } from '@leagueplatform/analytics';

// Components
import { SuggestedActivities } from 'components/suggested-activities.component';
import { useQueryClient } from 'react-query';
import { GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE } from '@leagueplatform/health-journey-api';
import { JourneyTimelineActivitiesList } from './journey-timeline-activities-list.component';
import { EmptyStateWithChallenges } from './journey-timeline-empty-with-challenges.component';
import { JourneyPageHeader } from './journey-page-header.component';

// Local Constants
const { ACTIVITIES, EMPTY, LOADING, ERROR } = Object.freeze({
  ACTIVITIES: 'activities',
  EMPTY: 'empty',
  ERROR: 'error',
  LOADING: 'loading',
});

// Local Components
const Loader = () => (
  <Box
    position="relative"
    minHeight="30vh"
    height="auto"
    width="100%"
    data-testid="loading-indicator"
  >
    <LoadingIndicator />
  </Box>
);

export const JourneyPageContent = ({ todaysDate }) => {
  const [activeDate, setActiveDate] = useState(todaysDate);
  const { activities, suggestedActivities } = useHealthJourneyPage(
    activeDate,
    todaysDate,
  );
  const {
    ready,
    hasError,
    hasActivities,
    allActivities,
    activitiesByCampaign,
    activitiesByStatus,
    activitiesByAssigned,
    activitiesByPriority,
    activitiesByChallenge,
    hasCompletedAllActivities,
  } = activities;

  usePageViewAnalytics(
    {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: SCREEN_NAMES.ACTIVITY_TIMELINE,
    },
    [ready],
  );

  const { formatMessage, formatDate } = useIntl();
  const queryClient = useQueryClient();
  const refetchQueries = () => {
    queryClient.invalidateQueries([
      GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
      activeDate,
    ]);
  };

  const diffOfDates = getDiffOfDates(
    new Date(todaysDate),
    new Date(activeDate),
  );

  const emptyStateContent = useEmptyActivitiesState({
    diffOfDates,
    hasActivities,
    hasCompletedAllActivities,
  });

  const statusMessage = useMemo(() => {
    if (!ready) {
      return formatMessage({
        id: 'LOADING',
      });
    }
    if (ready && activeDate) {
      return formatDate(activeDate, {
        month: 'long',
        day: 'numeric',
      });
    }
    return '';
  }, [activeDate, formatDate, formatMessage, ready]);

  const isEmptyWithNoActivities =
    emptyStateContent && !hasCompletedAllActivities;

  /**
   * Show the suggested activities gallery if all of the following conditions are true:
   * 1. The suggested activities API has returned a response
   * 2. The user has suggested activity groups that have _active_ activities
   * 3. The user is currently viewing activities for 'today', the only day that suggested activities are eligible to be completed
   */
  const showSuggestedActivities =
    suggestedActivities?.ready &&
    suggestedActivities?.groups?.length > 0 &&
    diffOfDates === 0;

  const handleActiveView = () => {
    if (!ready) return LOADING;
    if (hasError) return ERROR;
    if (isEmptyWithNoActivities) return EMPTY;
    return ACTIVITIES;
  };

  const activityViews = {
    [ACTIVITIES]: {
      view: JourneyTimelineActivitiesList,
      props: {
        hasCompletedAllActivities,
        emptyStateContent,
        activitiesByCampaign,
        activitiesByStatus,
        activitiesByAssigned,
        activitiesByPriority,
        activitiesByChallenge,
        allActivities,
        activeDate,
      },
    },
    [EMPTY]: {
      view: EmptyStateWithChallenges,
      props: {
        emptyStateContent,
        activeDate,
        allActivities,
      },
    },
    [ERROR]: {
      view: ErrorState,
      props: {
        onButtonClick: refetchQueries,
      },
    },

    [LOADING]: Loader,
  };
  return (
    <>
      <VisuallyHidden aria-live="polite" role="status">
        {statusMessage}
      </VisuallyHidden>
      {/* Date Navigation Area */}
      <JourneyPageHeader
        todaysDate={todaysDate}
        activeDate={activeDate}
        setActiveDate={setActiveDate}
      />

      {/* Main Content Area */}
      <Box paddingY="two">
        <ViewTransitionController
          handleActiveView={handleActiveView}
          views={activityViews}
        />
      </Box>

      {/* Suggested Activity Groups */}
      {showSuggestedActivities && (
        <SuggestedActivities
          description={formatMessage({
            id: 'DISCOVER_ACTIVITIES_CAPTION',
          })}
          suggestedActivities={suggestedActivities?.groups}
          title={formatMessage({ id: 'DISCOVER_ACTIVITIES' })}
          todaysDate={todaysDate}
          activeDate={activeDate}
        />
      )}
    </>
  );
};

JourneyPageContent.propTypes = journeyPageProps;
