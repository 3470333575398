import React, { FC } from 'react';
import { Box, styled, GDSStyleObject } from '@leagueplatform/genesis-core';
import { TRANSITIONS } from '../../constants/health-activity-design.constants';

// Types
interface CircleProgressProps {
  size: number;
  percent: number;
  strokeWidth?: number;
  trackColor?: string;
  strokeColor?: string;
  lineCap?: 'butt' | 'round' | 'square';
  css?: GDSStyleObject;
}

// Local Components
const SvgCircle = styled('circle', {
  transform: 'rotate(-90deg)',
  transformOrigin: 'center',
  transition: `stroke-dashoffset ${TRANSITIONS.PAGE}`,
  width: '100%',
});

const Svg = styled('svg', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// Utils
const calcPercentOfCircumference = (percent: number, circumference: number) => {
  const clampedPercent = Math.min(Math.max(percent, 0), 100);
  const percentOfCircumference = ((100 - clampedPercent) / 100) * circumference;
  return percentOfCircumference;
};

export const CircleProgress: FC<CircleProgressProps> = ({
  children,
  lineCap = 'butt',
  percent,
  size,
  strokeColor,
  strokeWidth = 3,
  trackColor,
  css,
}) => {
  const trueRadius = size / 2;
  const normalizedRadius = trueRadius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const circumferencePercent = calcPercentOfCircumference(
    percent,
    circumference,
  );
  const derivedStrokeColor = strokeColor ?? '$successIcon';
  const derivedTrackColor = trackColor ?? '$surfaceBackgroundPrimary';

  return (
    <Box
      css={{
        display: 'flex',
        width: size,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        ...css,
      }}
    >
      {children}
      <Svg
        viewBox={`0 0 ${size} ${size}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Track */}
        <SvgCircle
          r={normalizedRadius}
          cx={trueRadius}
          cy={trueRadius}
          strokeDashoffset="0"
          css={{
            stroke: `${derivedTrackColor}`,
            fill: 'transparent',
            strokeWidth: `${strokeWidth}`,
            strokeDasharray: `${circumference}`,
          }}
        />
        {/* Path */}
        <SvgCircle
          id="bar"
          r={normalizedRadius}
          cx={trueRadius}
          cy={trueRadius}
          strokeLinecap={lineCap}
          strokeDashoffset={circumferencePercent}
          css={{
            stroke: `${derivedStrokeColor}`,
            fill: 'transparent',
            strokeWidth: `${strokeWidth}`,
            strokeDasharray: `${circumference}`,
          }}
        />
      </Svg>
    </Box>
  );
};
