/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Field } from 'formik';
import { FieldWrapper } from './field-wrapper.component';
import { SelectField } from './select-field.component';
import { TextField } from './text-field.component';
import { DateField } from './date-field.component';
import { MultiSelectField } from './multiselect-field.component';
import { RadioField } from './radio-field.component';

import {
  HIDDEN,
  SELECT,
  MULTISELECT,
  TEXT,
  DATE,
  RADIO,
} from './input-field.constants';

const isGroupedField = (fieldType) => [DATE, RADIO].includes(fieldType);

export const InputField = ({
  inputType,
  name,
  label,
  value,
  required,
  tooltip,
  tooltipValues,
  getError,
  formId,
  ...otherProps
}) => {
  const { formatMessage } = useIntl();

  if (!inputType) return null;
  if (inputType === HIDDEN)
    return (
      <Field name={name}>
        {({ field }) => <input {...field} type="hidden" />}
      </Field>
    );

  const error = getError ? getError(name) : otherProps.error;
  const labelId = formId ? `${formId}_${name}_label` : `${name}_label`;
  const inputId = formId ? `${formId}_${name}` : name;

  return (
    <FieldWrapper
      {...otherProps}
      label={formatMessage({ id: label })}
      isRequired={required}
      tooltip={tooltip}
      tooltipValues={tooltipValues}
      error={error}
      htmlFor={inputId}
      labelId={labelId}
      groupedField={isGroupedField(inputType)}
    >
      <Field name={name}>
        {({ field, form }) => {
          const fieldProps = {
            ...otherProps,
            field,
            form,
            inputId,
            hasError: Boolean(error),
          };
          switch (inputType) {
            case TEXT:
              return <TextField {...fieldProps} />;
            case SELECT:
              return <SelectField {...fieldProps} />;
            case MULTISELECT:
              return <MultiSelectField {...fieldProps} />;
            case DATE:
              return <DateField {...fieldProps} />;
            case RADIO:
              return <RadioField {...fieldProps} />;
            default:
              return null;
          }
        }}
      </Field>
    </FieldWrapper>
  );
};

InputField.propTypes = {
  inputType: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  formId: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipValues: PropTypes.any, // eslint-disable-line
  getError: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any, // eslint-disable-line
};

InputField.defaultProps = {
  inputType: undefined,
  name: undefined,
  label: undefined,
  formId: undefined,
  placeholder: undefined,
  required: false,
  tooltip: null,
  tooltipValues: undefined,
  disabled: false,
  getError: undefined,
  value: null,
};

InputField.displayName = 'InputField';
