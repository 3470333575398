import React from 'react';
import {
  Button,
  HeadingText,
  UtilityText,
  ParagraphText,
  queryHelpers,
  StackLayout,
  useMediaQuery,
  Card,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNodeAction,
  useMasonryEngineActionEmitter,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { InternalOrExternalLink } from '@leagueplatform/web-common-components';
import { WidgetType } from '@leagueplatform/masonry-api';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { MasonryDeeplinkAction } from 'types/masonry-driver-node-actions';

type MasonryCTACardProperties = {
  title: string;
  eyebrow?: string;
  description: string;
  buttonText: string;
  image?: string;
  imageAltText?: string;
  isChildItem?: Boolean;
  isThinWidth?: Boolean;
  backgroundColor?: string;
};

export type MasonryCTACardNodeActions = {
  onButtonClick: MasonryEngineNodeAction;
};

export type MasonryCTACardNode = MasonryEngineNode<
  WidgetType.TEXT_WITH_CTA_CARD,
  MasonryCTACardProperties,
  MasonryCTACardNodeActions
>;

export type MasonryCTACardRendererProps =
  MasonryEngineNodeRendererProps<MasonryCTACardNode>;

export const MasonryCTACardRenderer = ({
  title,
  description,
  buttonText,
  image,
  imageAltText = '',
  isThinWidth = false,
  isChildItem = false,
  backgroundColor,
  eyebrow,
  onButtonClick,
}: MasonryCTACardRendererProps) => {
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const showSmallerCard = isMobile || isChildItem || isThinWidth;
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Elevated
      css={{
        backgroundColor: backgroundColor || '$surfaceBackgroundTertiary',
        padding: '$oneAndHalf',
      }}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing={{
          '@mobile': '$threeQuarters',
          '@initial': showSmallerCard ? '$threeQuarters' : '$oneAndHalf',
        }}
      >
        <StackLayout orientation="vertical" spacing="$quarter">
          {eyebrow && <UtilityText size="eyebrow">{eyebrow}</UtilityText>}
          <HeadingText level="3" size="sm">
            {title}
          </HeadingText>
          <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
            {description}
          </ParagraphText>

          {!showSmallerCard && (
            <Button
              css={{ padding: '$half $two', marginTop: '$one' }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...((onButtonClick as MasonryDeeplinkAction)?.payload.url && {
                as: InternalOrExternalLink,
              })}
              href={(onButtonClick as MasonryDeeplinkAction)?.payload.url}
              onClick={(event: React.MouseEvent) => {
                if (onButtonClick) {
                  invokeAction({
                    event,
                    ...onButtonClick,
                  });
                }
              }}
            >
              {buttonText}
            </Button>
          )}
        </StackLayout>
        {image && (
          <SquareImageContainer
            src={image}
            alt={imageAltText}
            maxWidth="8rem"
            maxWidthDesktop={isChildItem ? '8rem' : '11.2rem'}
          />
        )}
      </StackLayout>
      {showSmallerCard && (
        <Button
          width="fillContainer"
          css={{ padding: '$half $two', marginTop: '$one' }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...((onButtonClick as MasonryDeeplinkAction)?.payload.url && {
            as: InternalOrExternalLink,
          })}
          href={(onButtonClick as MasonryDeeplinkAction)?.payload.url}
          onClick={(event: React.MouseEvent) => {
            if (onButtonClick) {
              invokeAction({
                event,
                ...onButtonClick,
              });
            }
          }}
        >
          {buttonText}
        </Button>
      )}
    </Card.Elevated>
  );
};
