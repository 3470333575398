import React, { FC } from 'react';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import type {
  GDSCustomizableComponent,
  GDSOrientation,
  GDSOrientationValues,
  GDSSpacingValues,
} from '../../types';
import { styled } from '../../theme';

export interface GDSDividerProps
  extends GDSCustomizableComponent,
    GDSOrientation {
  /**
   * Specify margins for the divider
   */
  margin?: GDSSpacingValues;
  /**
   * Set the aria-hidden value for screen readers.
   */
  'aria-hidden'?: boolean;
}

const BaseDivider = styled('hr', {
  borderStyle: 'solid',
  borderColor: '$onSurfaceBorderSubdued',
  height: 'auto',
  variants: {
    orientation: {
      vertical: {
        margin: '0px',
        flexShrink: '0',
        borderWidth: '0px $thin 0px 0px',
        alignSelf: 'stretch',
      },
      horizontal: {
        width: '100%',
        borderWidth: '0px 0px $thin',
      },
    },
  },
});

export const getMarginCss = (
  orientation: GDSOrientationValues,
  margin: GDSSpacingValues,
) => {
  if (typeof margin === 'string') {
    return { [orientation === 'horizontal' ? 'marginX' : 'marginY']: margin };
  }

  const responsiveMargins = Object.entries(margin).reduce(
    (obj: any, [key, value]) => ({
      ...obj,
      [key]: { [orientation === 'horizontal' ? 'marginX' : 'marginY']: value },
    }),
    {},
  );

  return responsiveMargins;
};

export const Divider: FC<GDSDividerProps> = ({
  'aria-hidden': ariaHidden = true,
  className,
  css,
  margin,
  orientation = 'horizontal',
  ...props
}: GDSDividerProps) => {
  const ariaOrientation = useResponsiveProp(orientation);
  return (
    <BaseDivider
      aria-hidden={ariaHidden}
      aria-orientation={ariaOrientation}
      className={['GDS-divider', className].join(' ')}
      css={{ ...css, ...(margin && getMarginCss(orientation, margin)) }}
      data-testid="divider"
      orientation={orientation}
      {...props}
    />
  );
};

Divider.displayName = 'Divider';
