const minValue = 0;
// Due to UI constraints in some components (like inputs) we may want to set a character limit
const maxChars = 5;
// eslint-disable-next-line prefer-regex-literals -- FIXME: automatically added for existing issue
const nonDigitChars = new RegExp(/[\D]/, 'gi');

/**
 * Normalize a number value to strip non digit characters and to ensure the resulting number is no less than the min value and does not exceed the max number of chars
 * @param {string | number} value the number to be normalized
 * @param {number} minValue the minimum value the normalized number can be
 * @param {number} maxChars the maximum number of characters the normalized number can assume. This can be useful for inputs. `Infinity` will ignore any character limits
 * @returns a number representation of the count value that is neither less than the min nor greater than the max
 */

type NormalizeNumberFromStringArgs = {
  value: string | number;
  minValue?: number;
  maxChars?: number;
};

export const normalizeNumberFromString = ({
  value,
  minValue: min = minValue,
  maxChars: max = maxChars,
}: NormalizeNumberFromStringArgs) => {
  const formattedValue = value.toString().replace(nonDigitChars, '');
  const trimmedValue = formattedValue.slice(0, max);
  const normalizedValue = Math.max(min, Number(trimmedValue));
  return normalizedValue;
};
