import { useActivityParams } from 'hooks/use-activity-params.hook';
import { useGetUserHealthActivityByIdData } from './use-get-user-health-activity-by-id.hook';

export const useHealthActivityToolbox = () => {
  // Location Hooks
  const { userActivityId, campaignId, contentActivityId } = useActivityParams();

  const {
    isLoading,
    isRefetching,
    isError,
    data: healthActivityData,
  } = useGetUserHealthActivityByIdData(
    campaignId,
    contentActivityId,
    userActivityId,
  );

  return {
    hasError: isError,
    healthActivityData,
    ready: !isLoading && !isRefetching,
  };
};
