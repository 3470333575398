import React, { useState } from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  Image,
  Flex,
  Box,
  SubtitleTwo,
  Caption,
  useTheme,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import {
  Button,
  StackLayout,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import {
  CLP_DETAIL,
  TAB_NAME,
  TLP_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import {
  CHALLENGE_HIGHLIGHT_REACTION_TYPES,
  Highlight,
} from '@leagueplatform/health-journey-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { LikesModal } from './likes-modal.component';
import {
  PRIMARY_AVATAR_SIZE,
  SECONDARY_AVATAR_SIZE,
} from './constants/highlight-card.constants';
import { useGetHighlightDetails } from './hooks/use-get-highlight-details.util';
import { useDebouncedReactToHighlightMutation } from './hooks/use-debounced-react-to-highlight-mutation.hook';
import { useHighlightDescription } from './hooks/use-highlight-description.util';

export interface HighlightCardProps {
  highlight: Highlight;
  challengeTitle: string;
  userChallengeId?: string;
}

const LIKE_BUTTON_HOVER_STYLES = {
  backgroundColor: 'transparent',
  color: '$interactiveActionPrimary',
};

export const HighlightCard = ({
  highlight,
  challengeTitle,
  userChallengeId,
}: HighlightCardProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const { firstName, lastName, teamName, event } = highlight;

  const { teamId } = useParams<{ teamId: string }>();
  const {
    activityImage,
    formattedDate,
    formattedName,
    likesData,
    likeReactionId,
    numberOfLikes,
    teamPhoto,
    userPhoto,
    userLikedHighlight,
  } = useGetHighlightDetails(highlight);
  const highlightDescription = useHighlightDescription({
    event,
    teamName,
    challengeTitle,
  });

  const [isLiked, setIsLiked] = useState<boolean>(userLikedHighlight);
  const [displayedNumberOfLikes, setDisplayedNumberOfLikes] =
    useState<number>(numberOfLikes);
  const [displayLikesModal, setDisplayLikesModal] = useState(false);
  const { displayDescription, analyticsDescription } = highlightDescription;
  useDebouncedReactToHighlightMutation({
    isLiked,
    setIsLiked,
    userLikedHighlight,
    likeReactionId,
    highlightEventType: event.type,
  });
  const handleAnalyticsEvent = (sub_detail: string) => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: teamId
        ? TLP_SCREEN_NAMES.TEAM_DETAILS
        : SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail: CLP_DETAIL.INTERACT_HIGHLIGHTS,
      sub_detail,
      highlight_name: analyticsDescription.replace(/(<([^>]+)>)/gi, ''),
      ...(teamId
        ? { team_id: teamId, team_name: teamName }
        : { tab_name: TAB_NAME.HIGHLIGHTS }),
    });
  };
  const toggleLikeButton = () => {
    // Immediately update UI liked state and number of likes count based on user's action, even if API call is not yet complete
    setIsLiked(!isLiked);

    setDisplayedNumberOfLikes(
      !isLiked ? displayedNumberOfLikes + 1 : displayedNumberOfLikes - 1,
    );
    handleAnalyticsEvent(
      !isLiked
        ? CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE
        : CHALLENGE_HIGHLIGHT_REACTION_TYPES.UNLIKE,
    );
  };
  const displayLikesDividerLine = userChallengeId && displayedNumberOfLikes > 0;
  return (
    <Flex
      borderColor="onSurface.border.subdued"
      borderRadius="medium"
      borderWidth="thin"
      data-testid="highlightCard"
      flexDirection="column"
      width="100%"
    >
      <Box padding="one">
        {/* User / Team and Date header */}
        <Flex>
          <Box>
            <Box
              size={PRIMARY_AVATAR_SIZE}
              marginRight="threeQuarters"
              position="relative"
            >
              <ChallengeProfilePicture
                // Assume that if firstName does not exist or is an empty string, the highlight is for a team
                // May need to be updated when teams BE is complete
                profilePicture={firstName?.length ? userPhoto : teamPhoto}
                firstName={firstName}
                lastName={lastName}
              />
              {firstName && teamName && (
                <Box
                  size={SECONDARY_AVATAR_SIZE}
                  position="absolute"
                  bottom="0"
                  right="0"
                  borderWidth="thin"
                  borderStyle="solid"
                  borderColor="onSurface.border.subdued"
                  borderRadius="circle"
                >
                  <ChallengeProfilePicture
                    profilePicture={teamPhoto}
                    firstName={teamName}
                    lastName=""
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <SubtitleTwo as="p">{formattedName}</SubtitleTwo>
            <Caption as="p" color="onSurface.text.subdued">
              {firstName && teamName && `${teamName} • `}
              {formattedDate}
            </Caption>
          </Box>
        </Flex>

        {/* Card content */}
        <Flex marginTop="one" flexDirection="column" alignItems="center">
          {/* Activity description */}
          <BodyOne textAlign="center" data-testid="highlightDescription">
            {displayDescription}
          </BodyOne>
          {/* Activity image */}
          {activityImage && (
            <Image
              src={activityImage}
              alt=""
              marginTop="one"
              width={
                // Activity image should take the full width of the highlight card, accounting for card padding + border
                `calc(100% + ${theme.space.two}px + 2px)`
              }
            />
          )}
        </Flex>
      </Box>

      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing="$none"
      >
        {userChallengeId && (
          <Button
            onClick={toggleLikeButton}
            quiet
            priority="secondary"
            css={{
              color: '$interactiveActionPrimary',
              border: 'transparent',
              padding: '$one',
              '&:hover,&:active': LIKE_BUTTON_HOVER_STYLES,
            }}
          >
            <Flex>
              {handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_HEART_ICON, {
                isComponent: true,
                props: {
                  fillColor: isLiked ? 'interactive.action.primary' : 'inherit',
                },
              })}
            </Flex>
            <VisuallyHidden>
              {formatMessage({ id: isLiked ? 'LIKED' : 'LIKE' })}
            </VisuallyHidden>
          </Button>
        )}
        {displayLikesDividerLine && (
          <UtilityText
            css={{
              color: '$onSurfaceBorderSubdued',
            }}
            aria-hidden
          >
            |
          </UtilityText>
        )}
        {/* Likes */}
        {displayedNumberOfLikes > 0 && (
          <Button
            onClick={() => {
              setDisplayLikesModal(!displayLikesModal);
              handleAnalyticsEvent(
                CHALLENGE_HIGHLIGHT_REACTION_TYPES.VIEW_LIKES,
              );
            }}
            quiet
            priority="secondary"
            css={{
              color: '$interactiveActionPrimary',
              border: 'transparent',
              padding: '$one',
              '&:hover,&:active': {
                ...LIKE_BUTTON_HOVER_STYLES,
                textDecoration: 'underline',
              },
            }}
          >
            <Box as="span">
              {formatMessage(
                { id: 'X_LIKES' },
                { activityLikes: displayedNumberOfLikes },
              ).toLowerCase()}
            </Box>
          </Button>
        )}
      </StackLayout>
      {/* Only display the like button if the user is part of the challenge */}
      {likesData && (
        <LikesModal
          displayLikesModal={displayLikesModal}
          setDisplayLikesModal={setDisplayLikesModal}
          activityLikes={likesData}
        />
      )}
    </Flex>
  );
};
