import React, { FC } from 'react';
import { UserHealthActivityStatus } from '@leagueplatform/health-journey-common';
import { Box, HeadingText, ParagraphText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

const { UPCOMING } = UserHealthActivityStatus;

const OverlayWrapper: FC<{}> = ({ children }) => (
  <Box
    css={{
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 88.83%)',
      bottom: '0',
      alignItems: 'flex-end',
      zIndex: '1',
      paddingY: 'inherit',
      borderRadius: '$large',
      overflow: 'hidden',
    }}
  >
    {children}
  </Box>
);

const SneakPeek: FC<{}> = () => {
  const { formatMessage } = useIntl();
  return (
    <Box
      css={{
        display: 'flex',
        width: 'inherit',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '$three',
        backgroundColor: '$surfaceBackgroundTertiary',
        gap: '$half',
        textAlign: 'center',
      }}
    >
      <HeadingText level="4" size="md">
        {formatMessage({ id: 'THIS_IS_JUST_A_SNEAK_PEAK' })}
      </HeadingText>

      <ParagraphText size="xs" emphasis="subtle">
        {formatMessage({ id: 'COME_BACK_COMPLETE_ACTIVITY_LATER' })}
      </ParagraphText>
    </Box>
  );
};

const StatusOverlayBody: FC<{
  status: UserHealthActivityStatus | undefined;
}> = ({ status }) => {
  switch (status) {
    case UPCOMING:
      return <SneakPeek />;
    default:
      return null;
  }
};

export const ActivityToolboxStatusOverlay: FC<{
  status: UserHealthActivityStatus | undefined;
}> = ({ status }) => (
  <OverlayWrapper>
    <StatusOverlayBody status={status} />
  </OverlayWrapper>
);
