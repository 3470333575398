/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC,
  ReactNode,
  forwardRef,
  Ref,
  useState,
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Checkbox,
  Radio,
  Card,
  GDSStyleObject,
  TextInput,
  Icon,
  FormField,
} from '@leagueplatform/genesis-core';
import {
  MultipleSelectAnswerOption,
  FreeTextInputType,
  FreeTextValidationType,
} from '@leagueplatform/health-journey-api';
import { useControlledInput, useFormContext } from '@leagueplatform/web-common';

// Types
interface CommonOptionsProps {
  name: string;
  id: string;
  isSelected: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export interface OptionProps extends CommonOptionsProps {
  value: MultipleSelectAnswerOption['value'];
  label: ReactNode;
  ref?: Ref<HTMLInputElement>;
}

interface FreeTextOptionProps extends CommonOptionsProps {
  ariaLabel?: string;
  validation: FreeTextValidationType;
  value: string | number;
}

// Constants
const optionCardStyles: GDSStyleObject = {
  borderColor: '$onSurfaceBorderSubdued',
  borderWidth: '$thin',
  borderStyle: 'solid',
  '&:hover': { backgroundColor: '$inputBackgroundHovered' },
  '&:has(:checked)': { borderColor: '$interactiveActionPrimary' },
};

// Components
const OptionWrapper: FC = ({ children }) => (
  <Card.Elevated css={optionCardStyles}>{children}</Card.Elevated>
);

export const RadioOption = forwardRef<HTMLInputElement, OptionProps>(
  ({ isSelected, label, id, name, value, ...rest }, ref) => (
    <OptionWrapper>
      <Radio
        {...rest}
        id={id}
        name={name}
        value={value}
        layout="row"
        checked={isSelected}
        css={{ '.GDS-radio-label-hint': { padding: '$one' } }}
        label={label}
        ref={ref}
      />
    </OptionWrapper>
  ),
);

export const CheckboxOption = forwardRef<HTMLInputElement, OptionProps>(
  ({ isSelected, label, id, name, value, ...rest }, ref) => (
    <OptionWrapper>
      <Checkbox
        {...rest}
        id={id}
        name={name}
        layout="row"
        value={value}
        label={label}
        checked={isSelected}
        css={{ '.GDS-checkbox-label': { padding: '$one' } }}
        ref={ref}
      />
    </OptionWrapper>
  ),
);

export const FreeTextOption: FC<FreeTextOptionProps> = ({
  ariaLabel,
  validation = {},
  value,
  isSelected,
  onChange,
  disabled,
  id,
  name,
}) => {
  const { formatMessage } = useIntl();
  const { clearErrors, trigger } = useFormContext();
  const [workingValue, setWorkingValue] = useState(value ?? '');
  const {
    inputType,
    maximumValue,
    minimumValue = 0,
    characterLimit,
    placeholder,
  } = validation;
  const isNumber = inputType === FreeTextInputType.integer;

  const copy = {
    validationRequired: formatMessage({
      id: 'TOOLBOX_VALIDATION_FIELD_CANNOT_BE_EMPTY',
    }),
    validationMax: formatMessage(
      { id: 'TOOLBOX_VALIDATION_NUMBER_MAXIMUM' },
      { value: maximumValue },
    ),
    validationMin: formatMessage(
      { id: 'TOOLBOX_VALIDATION_NUMBER_MINIMUM' },
      { value: minimumValue },
    ),
    validationCharLimit: formatMessage(
      { id: 'TOOLBOX_VALIDATION_MAXIMUM_CHARACTERS' },
      { limit: characterLimit },
    ),
  };

  const { field, inputValidationState, fieldState } = useControlledInput({
    name,
    defaultValue: workingValue,
    rules: {
      value: workingValue,
      onChange,
      required: {
        value: isSelected,
        message: copy.validationRequired,
      },
      max: {
        value: (isSelected && maximumValue) || Infinity,
        message: copy.validationMax,
      },
      min: {
        value: Number(isSelected && minimumValue),
        message: copy.validationMin,
      },
      maxLength: {
        value: (isSelected && characterLimit) || Infinity,
        message: copy.validationCharLimit,
      },
    },
  });
  const { isDirty } = fieldState;
  const { inputStatus, statusMessage } = inputValidationState;

  useEffect(() => {
    if (!isSelected) {
      clearErrors(name);
    } else if (isSelected && isDirty) {
      trigger(name);
    }
  }, [clearErrors, isDirty, isSelected, name, trigger]);

  const setValueAs = (nextValue: string | number) => {
    setWorkingValue(nextValue);
    field.onChange(nextValue);
  };

  const computedAriaLabel = useMemo(() => {
    const errorLabel = [inputStatus, statusMessage]
      .filter(Boolean)
      .join(': ')
      .trim();
    return [ariaLabel, errorLabel].filter(Boolean).join(': ').trim();
  }, [ariaLabel, inputStatus, statusMessage]);

  return (
    <FormField
      label={null}
      hideLabel
      aria-label={computedAriaLabel}
      id={id}
      name={name}
      inputStatus={inputStatus}
      statusMessage={statusMessage}
    >
      <TextInput
        {...field}
        value={workingValue}
        type={isNumber ? 'number' : undefined}
        checked={isSelected}
        placeholder={placeholder}
        disabled={disabled}
        clearable
        autoComplete="off"
        leadingContent={<Icon size="$one" icon="interfaceEdit" />}
        inputStatus={inputStatus}
        css={{ input: { background: 'inherit' } }}
        onChange={({ target: { value: nextValue } }) => setValueAs(nextValue)}
        onClear={() => setValueAs('')}
      />
    </FormField>
  );
};
