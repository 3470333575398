import { useMemo } from 'react';
import { ComponentTypes } from '@leagueplatform/health-journey-common';
import { useConfigProperty } from '@leagueplatform/core';
import { ToolboxComponentActionMap } from '../types/toolbox.types';
import { HealthActivityAnalyticsEvents } from './use-health-activity-analytics.hook';

/**
 * This hook allows Activities to establish control over Toolbox components by specifying predetermined callbacks for each component type
 * @param healthActivityAnalytics An analytics object for activities to inject in to toolbox component actions
 * @returns `Partial<ToolboxComponentActionMap>`
 */
export const useActivityToolboxComponentActions = (
  healthActivityAnalytics: HealthActivityAnalyticsEvents,
) => {
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const actionMap = useMemo<Partial<ToolboxComponentActionMap>>(
    () => ({
      [ComponentTypes.Link]: {
        onLinkOut(event, actionAttributes) {
          healthActivityAnalytics?.sendLinkOutClick?.(actionAttributes);

          const { currentTarget } = event;
          const { href } = currentTarget;

          if (handleLinkCallback) {
            event.preventDefault();
            handleLinkCallback(href);
          }
        },
        onDeepLink(event, actionAttributes) {
          healthActivityAnalytics?.sendLinkOutClick?.({
            ...actionAttributes,
            detail: 'deep link clicked',
          });
        },
      },
      [ComponentTypes.Biometric]: {
        onLinkOut(event, actionAttributes) {
          healthActivityAnalytics?.sendLinkOutClick?.({
            ...actionAttributes,
            detail: 'biometric linkout clicked',
          });

          const { currentTarget } = event;
          const { href } = currentTarget;
          if (handleLinkCallback) {
            event.preventDefault();
            handleLinkCallback(href);
          }
        },
        onDeepLink(event, actionAttributes) {
          healthActivityAnalytics?.sendLinkOutClick?.({
            ...actionAttributes,
            detail: 'biometric deep link clicked',
          });
        },
      },
      [ComponentTypes.RichText]: {
        onLinkOut(event) {
          const { currentTarget } = event;
          const { href, innerText } = currentTarget;

          healthActivityAnalytics?.sendLinkOutClick?.({
            url: href,
            title: innerText?.trim(),
            detail: 'richtext linkout clicked',
          });

          if (handleLinkCallback) {
            event.preventDefault();
            handleLinkCallback(href);
          }
        },
      },
    }),
    [handleLinkCallback, healthActivityAnalytics],
  );

  return actionMap;
};
