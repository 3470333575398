import * as React from 'react';
import { useIntl } from 'react-intl';
import { Image } from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_JOURNEY_ASSET_KEYS } from 'types/health-journey-asset-keys.types';

export function ActivityCompletedBadge() {
  const { formatMessage } = useIntl();
  const completedIcon = handleStaticAsset(
    HEALTH_JOURNEY_ASSET_KEYS.SUCCESS_STATUS_ALTERNATIVE,
  ) as string;

  return (
    <Image
      src={completedIcon}
      alt={formatMessage({ id: 'COMPLETED' })}
      size="oneAndHalf"
    />
  );
}
