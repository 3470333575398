import React from 'react';
import { HeadingText, ParagraphText, Tabs } from '@leagueplatform/genesis-core';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  PillTab,
  PillTabList,
  PillTabs,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  NUMERIC_YEAR_MONTH_DAY,
  SHORT_DATE_AND_TIME_FORMAT,
} from '@leagueplatform/web-common';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { NetworkPlan } from './components/network-plan/network-plan.component';

import { MemberDropDown } from '../member-dropdown/member-dropdown.component';
import { useMemberDropdown } from './hooks/use-member-dropdown.hook';
import { usePlanProgressV1 } from './hooks/use-plan-progress-v1.hook';

const ACCUMULATION_PERIOD_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  ...NUMERIC_YEAR_MONTH_DAY,
  timeZone: 'UTC',
};

export const PlanProgressV1 = () => {
  const { formatDate, formatMessage } = useIntl();
  const { userId, benefitId } = useWalletParams();

  const {
    plans,
    accumulationPeriod,
    lastUpdated,
    carrierName,
    onTabClickEvent: handleTabClickEvent,
    onAccordionToggleEvent: handleAccordionToggleEvent,
    isLoading,
    isError,
    refetch,
  } = usePlanProgressV1(userId, benefitId!);

  const { showMemberSelect } = useMemberDropdown();

  return (
    <Box as="section">
      <>
        {showMemberSelect && <MemberDropDown />}
        <HeadingText
          as="h2"
          level="2"
          size="xl"
          css={{
            marginBottom: '$oneAndHalf',
            marginTop: showMemberSelect ? '$oneAndHalf' : '$none',
          }}
        >
          {formatMessage({ id: 'PLAN_PROGRESS' })}
        </HeadingText>
      </>
      {isLoading && <LoadingIndicator inline />}
      {isError && !isLoading && (
        <Flex
          as="section"
          backgroundColor="surface.background.tertiary"
          padding="one"
          minHeight={286}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <ErrorState hideImage onButtonClick={refetch} />
        </Flex>
      )}
      {!isLoading && !isError && (
        <>
          {accumulationPeriod && (
            <ParagraphText size="sm">
              {formatMessage(
                { id: 'ACCUMULATION_PERIOD' },
                {
                  start: formatDate(
                    accumulationPeriod.start,
                    ACCUMULATION_PERIOD_DATE_FORMAT,
                  ),
                  end: formatDate(
                    accumulationPeriod.end,
                    ACCUMULATION_PERIOD_DATE_FORMAT,
                  ),
                },
              )}
            </ParagraphText>
          )}
          <PillTabs
            onValueChange={(value) => {
              handleTabClickEvent(
                plans[plans.findIndex((plan) => plan.id === value)].analyticsId,
              );
            }}
            defaultValue={plans[0].id}
          >
            <Box width="fit-content" marginY="twoAndHalf">
              <PillTabList>
                {plans.map((tab) => (
                  <PillTab key={tab.id} value={tab.id}>
                    {formatMessage({ id: tab.id })}
                  </PillTab>
                ))}
              </PillTabList>
            </Box>

            {plans.map((plan) => (
              <Tabs.TabPanel key={plan.id} value={plan.id}>
                <NetworkPlan
                  networkProviders={plan.network}
                  networkName={formatMessage({ id: plan.id })}
                  lastUpdated={formatMessage(
                    { id: 'LAST_UPDATED_CARRIER_DATE' },
                    {
                      date: formatDate(lastUpdated, SHORT_DATE_AND_TIME_FORMAT),
                      carrier: carrierName,
                    },
                  )}
                  onProviderToggle={handleAccordionToggleEvent}
                />
              </Tabs.TabPanel>
            ))}
          </PillTabs>
        </>
      )}
    </Box>
  );
};
