import React, { useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingOne,
  HeadingThree,
  Link,
  PrimaryButton,
  Label,
  LabelText,
  TextInput,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  TLP_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { Formik, Form, Field } from 'formik';
import { BackButtonArrow } from '@leagueplatform/web-common-components';
import { ChooseProfilePicture } from 'components/choose-profile-picture/choose-profile-picture.component';
import { ErrorWithIcon } from 'components/error-with-icon/error-with-icon.component';
import {
  JOIN_TEAM_CONTAINER_MAX_WIDTH,
  JOIN_TEAM_PROFILE_PICTURE_SIZE,
  INPUT_MAX_WIDTH,
} from 'pages/join-team-page/constants/join-team-page.constants';
import { CONTENT_MAX_WIDTH } from 'constants/challenges.constants';
import { useCreateTeam } from 'pages/join-team-page/hooks/use-create-team.hook';
import { TeamPhotoModal } from '../team-photo-modal/team-photo-modal.component';
import { CreateTeamSuccess } from './create-team-success.component';

export interface CreateTeamProps {
  backTo: () => void;
}

interface FormValues {
  teamName: string;
}

export const CreateTeam = ({ backTo }: CreateTeamProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [teamPhotoUrl, setTeamPhotoUrl] = useState('');
  const [teamPhotoRefId, setTeamPhotoRefId] = useState('');
  const [displayTeamPhotoModal, setDisplayTeamPhotoModal] = useState(false);
  const [displayCreateTeamSuccess, setDisplayCreateTeamSuccess] =
    useState(false);
  const [teamId, setTeamId] = useState('');

  const createTeamMutation = useCreateTeam(
    setTeamId,
    setDisplayCreateTeamSuccess,
  );
  const createNewTeam = (values: FormValues) => {
    createTeamMutation.mutate({
      teamName: values.teamName,
      teamPhoto: teamPhotoRefId,
    });
  };
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  useEffect(() => {
    trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      screen_name: TLP_SCREEN_NAMES.CREATE_TEAM,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickAvatarLink = () => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.CREATE_TEAM,
      detail: teamPhotoUrl
        ? SIGN_UP_ANALYTICS_DETAILS.CHANGE_TEAM_AVATAR
        : SIGN_UP_ANALYTICS_DETAILS.SELECT_TEAM_AVATAR,
    });
    setDisplayTeamPhotoModal(true);
  };
  return (
    <Box
      marginX={{ _: 'two', tablet: 'three', desktop: 'auto' }}
      marginY={{ _: 'twoAndHalf', tablet: 'five' }}
      maxWidth={JOIN_TEAM_CONTAINER_MAX_WIDTH}
    >
      {!displayCreateTeamSuccess && (
        <Flex alignItems="center">
          <BackButtonArrow onClick={backTo} marginTop="half" />
          <HeadingThree as="p" marginLeft={{ _: 'half', tablet: 'oneAndHalf' }}>
            {formatMessage({ id: 'CREATE_A_TEAM' })}
          </HeadingThree>
        </Flex>
      )}
      <VisuallyHidden>
        <HeadingOne>{formatMessage({ id: 'CREATE_A_TEAM' })}</HeadingOne>
      </VisuallyHidden>
      {displayCreateTeamSuccess ? (
        <CreateTeamSuccess teamPhoto={teamPhotoUrl} teamId={teamId} />
      ) : (
        <>
          <Formik
            initialValues={{ teamName: '' }}
            onSubmit={(values) => createNewTeam(values)}
            validate={(values) => {
              const errors: { teamName?: string } = {};
              if (values.teamName === '') {
                errors.teamName = formatMessage({ id: 'TEAM_NAME_ERROR' });
              }
              return errors;
            }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ errors }) => (
              <Form>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  paddingY={{ _: 'five', tablet: 'four' }}
                  marginX="auto"
                  maxWidth={CONTENT_MAX_WIDTH}
                >
                  <ChooseProfilePicture
                    photoUrl={teamPhotoUrl}
                    size={JOIN_TEAM_PROFILE_PICTURE_SIZE}
                  />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    as="button"
                    type="button"
                    marginY={{ _: 'oneAndHalf', tablet: 'two' }}
                    paddingY="threeQuarters"
                    paddingX="three"
                    backgroundColor="inherit"
                    border="none"
                    onClick={handleOnClickAvatarLink}
                  >
                    {formatMessage({
                      id: teamPhotoUrl
                        ? 'CHANGE_TEAM_AVATAR'
                        : 'SELECT_TEAM_AVATAR',
                    })}
                  </Link>
                  <Label
                    marginBottom={{ tablet: 'twoAndHalf' }}
                    width="100%"
                    maxWidth={INPUT_MAX_WIDTH}
                  >
                    <LabelText textAlign="left" marginBottom="quarter">
                      {formatMessage({ id: 'TEAM_NAME_LABEL' })}
                    </LabelText>
                    <Field
                      as={TextInput}
                      name="teamName"
                      type="text"
                      error={errors.teamName}
                    />
                    {errors.teamName && (
                      <ErrorWithIcon errorMessage={errors.teamName} />
                    )}
                  </Label>
                  <PrimaryButton
                    type="submit"
                    marginTop={errors.teamName ? 'oneAndHalf' : 'five'}
                    paddingX="four"
                    isLoading={createTeamMutation.isLoading}
                  >
                    {formatMessage({ id: 'CREATE_TEAM' })}
                  </PrimaryButton>
                </Flex>
              </Form>
            )}
          </Formik>
          <TeamPhotoModal
            displayTeamPhotoModal={displayTeamPhotoModal}
            closeTeamPhotoModal={() => setDisplayTeamPhotoModal(false)}
            setTeamPhotoUrl={setTeamPhotoUrl}
            setTeamPhotoRefId={setTeamPhotoRefId}
          />
        </>
      )}
    </Box>
  );
};
