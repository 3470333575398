import { subscribeToConfig, updateServerConfig } from '@leagueplatform/config';
import { captureError } from '@leagueplatform/observability';

export function createServerConfigUpdateSubscription() {
  subscribeToConfig(
    /**
     * Notice that subscribing to only the parameters we need means that the eventual
     * call to `updateServerConfig` will only happen when those parameters change,
     * which is exactly the circumstances under which we want to get (or re-get)
     * server config values. This also prevents an infinite loop of config setting,
     * since the act of setting `serverConfig` will not in itself re-trigger this subscription,
     * because it does not involve modifying any of the client config parameters we
     * are subscribed to.
     */
    (config) => ({
      appEnvironment: config.core.appEnvironment,
      appId: config.core.appId,
      // eslint-disable-next-line no-underscore-dangle
      isNodeTestEnv: config.__internal?.isNodeTestEnv,
    }),
    (selection) => {
      if (!selection || selection.isNodeTestEnv) {
        /**
         * ...and until we have those client config properties, we would not be able
         * to ask for server-driven values.
         */
        return;
      }

      updateServerConfig(selection.appId, selection.appEnvironment)
        .then()
        .catch((error) => {
          captureError(error, { errorName: 'Get Server Config Error' });
        });
    },
  );
}
