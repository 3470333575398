import React, { FC, useMemo } from 'react';
import {
  StackLayout,
  Select,
  Radio,
  Card,
  Box,
  Fieldset,
} from '@leagueplatform/genesis-core';
import { useControlledInput } from '@leagueplatform/web-common';
import {
  DateInputAttributes,
  DateInputMethodType,
  AdditionalDateOptionUnit,
} from '@leagueplatform/health-journey-api';
import { useIntl } from '@leagueplatform/locales';
import { useDateHelpers } from 'pages/health-activity/hooks/use-date-helpers.hook';
import { useToolboxDateInputs } from 'pages/health-activity/hooks/use-toolbox-date-inputs';
import { useToolboxErrorFocus } from 'pages/health-activity/hooks/use-toolbox-error-focus.hook';
import { formatDateToParts } from '../../common/format-date.util';
import { ActivityToolboxOptionLabel } from '../../common/activity-toolbox-option-label.component';
import { ToolboxCommonInputProps } from '../../types/health-activity.types';
import {
  ActivityToolboxInputWrapper,
  MapInputValueToProps,
} from '../activity-toolbox-input-wrapper.component';

// Constants
const { day, month, year } = AdditionalDateOptionUnit;

// Styles
const additionalOptionFieldsetStyle = {
  legend: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    paddingTop: '$oneAndHalf',
    paddingBottom: '$oneAndHalf',
  },
};

const additionalOptionCardStyle = {
  border: '$borderWidths$thin solid $colors$onSurfaceBorderSubdued',
  width: '100%',
};

const additionalOptionRadioStyle = {
  label: {
    padding: '$oneAndHalf $one',
  },
};

interface DateInputProps extends ToolboxCommonInputProps, DateInputAttributes {}

export const ActivityToolboxDateInput: FC<DateInputProps> = ({
  answer,
  dateInputMethod,
  additionalDateOptions,
  altText,
  css,
  validation = {},
  componentId,
}) => {
  const { formatMessage } = useIntl();
  const { daySelectOptions, monthSelectOptions, yearSelectOptions } =
    useDateHelpers();
  const { editable = true } = validation;

  // Computed Props
  const showDayInput = dateInputMethod !== DateInputMethodType.monthYear;
  const showAdditionalOptions = Boolean(additionalDateOptions?.length);

  // Input Refs, Validation Methods, Handlers
  const { handlers, validateMethods, refs } = useToolboxDateInputs({
    componentId,
    showDayInput,
    validation,
  });
  const { onAdditionalOptionChange, onDateSelectChange } = handlers;
  const { dayInputRef, monthInputRef, yearInputRef, radioInputsRef, fieldRef } =
    refs;

  // Input Config
  const inputOptions = {
    name: componentId,
    defaultValue: answer ?? undefined,
    rules: { disabled: !editable, validate: validateMethods },
  };
  const { inputValidationState } = useControlledInput(inputOptions);

  // Initial Select Values
  const {
    day: initialDay,
    month: initialMonth,
    year: initialYear,
  } = useMemo(() => formatDateToParts(answer), [answer]);

  // Effects
  useToolboxErrorFocus({
    name: componentId,
    focusTarget:
      !fieldRef.current?.contains(document.activeElement) &&
      monthInputRef.current,
  });

  /** Apply the selected date and any selected options to the component's attributes */
  const mapValueToProps: MapInputValueToProps<string> = (value, component) => {
    const selectedOptions = additionalDateOptions?.map((option, index) => ({
      ...option,
      isSelected: radioInputsRef.current?.[index].checked,
    }));

    Object.assign(component, {
      componentAttributes: {
        ...component.componentAttributes,
        answer: value ?? answer,
        additionalDateOptions: selectedOptions,
      },
    });

    return component;
  };

  return (
    <ActivityToolboxInputWrapper
      inputName={componentId}
      mapValueToProps={mapValueToProps}
    >
      <Box css={css} ref={fieldRef} data-testid={componentId}>
        <Fieldset
          id={componentId}
          legend={altText}
          hideLegend
          inputStatus={inputValidationState.inputStatus}
          statusMessage={inputValidationState.statusMessage}
        >
          <StackLayout
            spacing="$one"
            orientation="horizontal"
            horizontalAlignment="stretch"
          >
            <Select
              className="date-input-select"
              id={`${componentId}-input-month`}
              options={monthSelectOptions}
              defaultValue={initialMonth}
              ref={monthInputRef}
              name={`${componentId}-select-${month}`}
              onChange={onDateSelectChange}
              disabled={!editable}
              aria-label={formatMessage({ id: 'MONTH' })}
            />
            {showDayInput && (
              <Select
                className="date-input-select"
                id={`${componentId}-input-day`}
                options={daySelectOptions}
                defaultValue={initialDay}
                ref={dayInputRef}
                name={`${componentId}-select-${day}`}
                onChange={onDateSelectChange}
                disabled={!editable}
                aria-label={formatMessage({ id: 'DAY' })}
              />
            )}
            <Select
              className="date-input-select"
              id={`${componentId}-input-year`}
              options={yearSelectOptions}
              defaultValue={initialYear}
              ref={yearInputRef}
              name={`${componentId}-select-${year}`}
              onChange={onDateSelectChange}
              disabled={!editable}
              aria-label={formatMessage({ id: 'YEAR' })}
            />
          </StackLayout>
        </Fieldset>

        {showAdditionalOptions && (
          <Fieldset
            legend={formatMessage({ id: 'OR_SELECT_BETWEEN_THESE' })}
            css={additionalOptionFieldsetStyle}
          >
            <StackLayout spacing="$one">
              {additionalDateOptions?.map((option, index) => (
                <Card.Elevated
                  css={additionalOptionCardStyle}
                  key={option.text}
                >
                  <Card.Section padding="$none">
                    <Radio
                      id={`${componentId}-additional-option-${index}`}
                      disabled={!editable}
                      defaultChecked={option?.isSelected}
                      name={`${componentId}-option`}
                      value={option.value.toString()}
                      label={<ActivityToolboxOptionLabel text={option.text} />}
                      layout="row"
                      css={additionalOptionRadioStyle}
                      ref={(element: HTMLInputElement) => {
                        radioInputsRef.current[index] = element;
                      }}
                      onChange={(event) =>
                        onAdditionalOptionChange(event, option)
                      }
                    />
                  </Card.Section>
                </Card.Elevated>
              ))}
            </StackLayout>
          </Fieldset>
        )}
      </Box>
    </ActivityToolboxInputWrapper>
  );
};
