import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ErrorContext } from '@leagueplatform/observability';
import { getWidgetData } from 'utils/get-widget-data';
import { getObservabilityContext } from 'utils/get-observability-context';
import { normalizeParams } from 'utils/normalize-paramters';
import { WidgetData } from '../types/widget-data';
import { ContainerWidgetItem } from '../types/container-data';
import { ContainerWidgetItemV2 } from '../types/container-data-v2';

export const GET_WIDGET_DATA = 'get_widget_data';

/**
 * Hook to get data from Masonry default API
 * This hook makes call to the Masonry - get_widget_data API if the widget
 * config will have link.
 */
export const useGetWidgetData = <WidgetDataAttributes = unknown>(
  // @TODO - remove ContainerData and rename ContainerDataV2 to ContainerData when deprecating v0 and 1
  widget: ContainerWidgetItem | ContainerWidgetItemV2,
  version: number,
  errorContext: ErrorContext = {}, // Placed error context for capability teams to extend errorContext
) => {
  const { id: widgetId, links } = widget;
  const queryClient = useQueryClient();

  /** Use only the link with rel as data to get widget data */
  const linkDetails = links.find(({ rel }: any) => rel === 'data');

  // Invalidating query when the hook is unmounted
  useEffect(
    () => () => {
      queryClient.invalidateQueries([GET_WIDGET_DATA, widgetId, version]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useQuery<WidgetData<WidgetDataAttributes>, Error>(
    [GET_WIDGET_DATA, widgetId, version],
    () =>
      getWidgetData(
        normalizeParams({ params: linkDetails?.params }),
        version,
        getObservabilityContext({
          ...errorContext,
          errorName: 'Masonry - get widget data api error',
          tags: {
            widgetId,
            apiVersion: version,
            ...(errorContext?.tags || {}),
          },
        }),
      ),
    {
      onSuccess: (data: any) => {
        if (!data?.attributes) {
          console.warn(
            `[Masonry]: No attributes returned from api get_widget_data for widget id %c'${data.id}'`,
          );
        }
      },
    },
  );
};
