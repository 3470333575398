import { useQuery } from 'react-query';
import {
  getSuggestedUserActivityGroups,
  GET_SUGGESTED_ACTIVITY_GROUPS,
} from '@leagueplatform/health-journey-api';
import { useGetUserHealthActivities } from './use-get-user-health-activities.hook';

/**
 * @returns Activities grouped by status/campaign, ready/error states, suggested activities groups
 */
export const useHealthJourneyPage = (activeDate, todaysDate) => {
  const {
    data: healthActivitiesData,
    isLoading: healthActivitiesDataLoading,
    isError: healthActivitiesDataError,
  } = useGetUserHealthActivities(activeDate, todaysDate);
  const { data, isError, isLoading } = useQuery(
    GET_SUGGESTED_ACTIVITY_GROUPS,
    () => getSuggestedUserActivityGroups(),
  );

  const suggestedGroups = data?.suggested_activities_groups ?? [];
  return {
    activities: {
      ready: !healthActivitiesDataLoading,
      hasError: healthActivitiesDataError,
      hasActivities: healthActivitiesData?.data?.length > 0,
      allActivities: healthActivitiesData?.data,
      activitiesByCampaign: healthActivitiesData?.activitiesByCampaign,
      activitiesByStatus: healthActivitiesData?.activitiesByStatus,
      activitiesByAssigned: healthActivitiesData?.activitiesByAssigned,
      activitiesByPriority: healthActivitiesData?.activitiesByPriority,
      activitiesByChallenge: healthActivitiesData?.activitiesByChallenge,
      hasCompletedAllActivities:
        healthActivitiesData?.hasCompletedAllActivities,
    },
    suggestedActivities: {
      ready: !isLoading,
      hasError: isError,
      groups: suggestedGroups,
    },
  };
};
