import React from 'react';
import { genesisStyled } from '@leagueplatform/genesis-commons';
import { plusMinusDisabledStyles } from '../common/plus-minus-icon-styles';

// TODO – JRNY-XXX: Remove/replace this component when inline svg imports are supported in modules
export const Plus = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00004 1.50002C7.00005 0.671581 7.67164 -9.8648e-06 8.50008 -2.20337e-05C9.32851 -3.42025e-05 10.0001 0.671536 10.0001 1.49997L9.99988 14.5038C9.99987 15.3323 9.32828 16.0039 8.49984 16.0039C7.67141 16.0039 6.99984 15.3323 6.99985 14.5039L7.00004 1.50002Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0002 6.60012C15.8286 6.60011 16.5002 7.27168 16.5002 8.10011C16.5001 8.92855 15.8286 9.60014 15.0001 9.60015L1.99999 9.60034C1.17156 9.60035 0.499987 8.92878 0.499999 8.10035C0.500011 7.27191 1.1716 6.60032 2.00004 6.60031L15.0002 6.60012Z"
      fill="white"
    />
  </svg>
);

export const PlusIcon = genesisStyled(Plus)(plusMinusDisabledStyles);
