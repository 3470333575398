import { useQuery } from 'react-query';
import {
  IndividualHealthActivityDeserializedData,
  getUserHealthActivityById,
  HealthActivityDeserializedData,
} from '@leagueplatform/health-journey-api';

export const GET_USER_HEALTH_ACTIVITY_DATA_KEY = 'userHealthActivityData';
export const useGetUserHealthActivityByIdData = (
  campaignId?: string,
  contentActivityId?: string,
  userHealthActivityId: string = '',
) =>
  useQuery<
    IndividualHealthActivityDeserializedData,
    Error,
    HealthActivityDeserializedData
  >(
    [
      GET_USER_HEALTH_ACTIVITY_DATA_KEY,
      userHealthActivityId,
      campaignId,
      contentActivityId,
    ],
    () =>
      getUserHealthActivityById(
        userHealthActivityId,
        campaignId,
        contentActivityId,
      ),
    {
      select(data) {
        const { data: healthActivityData } = data;
        const healthActivityDataObj =
          Array.isArray(healthActivityData) && healthActivityData.length > 0
            ? healthActivityData?.[0]
            : healthActivityData;
        return healthActivityDataObj ?? {};
      },
    },
  );
