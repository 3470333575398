import * as React from 'react';
import Masonry from '@leagueplatform/masonry';
import {
  SCREEN_NAMES,
  HEALTH_JOURNEY_MASONRY_VERSION,
  HEALTH_ASSESSMENT_REPORT,
} from '@leagueplatform/health-journey-common';
import { PRODUCT_AREA } from '@leagueplatform/analytics';

const MasonryComposer = Masonry.init({
  appId: HEALTH_ASSESSMENT_REPORT,
  apiVersion: HEALTH_JOURNEY_MASONRY_VERSION,
  analytics: {
    productArea: PRODUCT_AREA.JOURNEY,
    screenName: SCREEN_NAMES.HEALTH_ASSESSMENT_REPORT,
  },
});

export function HealthAssessmentReport() {
  return <MasonryComposer.Provider />;
}
